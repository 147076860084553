import { call, put, takeEvery } from "redux-saga/effects";
import { isNull } from "lodash";
import actionTypes, { clearUserSelection } from "./actions";
import ActionType, {
    setKeyWordInContext,
    setDomainInContext,
    setRequestIdInApp,
    setLocaleInContext,
    callToClearApp,
    setProjectNameInContext,
    setProjectTypeInContext,
    setFeatureAddOnsInContext,
    setShowLoader,
    setFileuploadRequestId,
    setHideLoader,
    setProjectDomainUrl,
    callToGetAllProjectsList,
    setProjectsListInContext,
    setProjectIdInContext,
    setDomainAndKeywordsLimitInContext
} from "../../../app/duck/actions";
import { getSimulationHistory } from "../../../beta/page-optimizer/ducks/actions";
import { AnyAction } from "redux";
import { getContext } from "../../../app/duck/context-container";
import Apis from "../../../app/apis";
import { ProjectMetaDataInResponseInterface } from "../../../app/duck/types";
import { updateCookies, storeMetaData } from "../../../app/duck/utils";
import { ALPS_LOCAL_STORAGE_KEY, HtmlUploadErrors } from "../../../app/const";

// tslint:disable-next-line:typedef
export function* clearUserSelectionFromContext() {
    yield put(setKeyWordInContext());
    yield put(setLocaleInContext());
    yield put(setDomainInContext());
    yield put(setRequestIdInApp());
    //  yield put(unsetSelectedSID());

    yield put(callToClearApp());
}
// tslint:disable-next-line:typedef
export function* fetchProjectDetails(action: AnyAction) {
    const { tenantCode = "" } = getContext();
    const { toBeClearUserSelection, ...data } = action?.payload;
    const payload = {
        ...data,
        tenantCode,
    };
    const response = yield call(Apis.getProjectDetail, payload);

    if (response.status === 200) {
        const resp: ProjectMetaDataInResponseInterface = response.data.data;
        const selectedDomain = resp.project_domain[0];
        /** store MetaData In localStorage
         * this should be removed once login is moved from Angular to React code base
         */
        storeMetaData({ ...resp, projectId: action.payload.projectId });
        updateCookies({ ...resp, projectId: action.payload.projectId });
        yield put(setProjectDomainUrl(selectedDomain));
        yield put(setProjectNameInContext());
        yield put(setProjectTypeInContext());
        yield put(callToGetAllProjectsList());
        yield put(setProjectIdInContext());
        if (toBeClearUserSelection) {
            yield put(clearUserSelection());
        } else {
            yield put(setDomainInContext(selectedDomain));
            yield put(setLocaleInContext(resp?.project_locale));
        }
    }
}

// tslint:disable-next-line: typedef
export function* fetchAllProjectsList(action?: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const projectsList = yield call(Apis.getProjectsList, parser);
    if (projectsList.status === 200) {
        yield put(setProjectsListInContext(projectsList.data));
    }
}

// tslint:disable-next-line: typedef
export function* fileUploadOnPageElements(action: AnyAction) {
    const { tenantCode } = getContext();

    const parser = {
        tenantCode: tenantCode as string
    };
    const { formData, notifyErrorMethod } = action.payload;
    const payload = {
        formData,
        isBeta: true
    };
    const optionalArgs = {
        notifyErrorMethod,
        needToHandleError: true,
        isSpecificError: true,
        errorList: HtmlUploadErrors
    };
    const fileUploadResponse = yield call(Apis.fileUploadOnPageElements, payload, parser, optionalArgs);
    if (fileUploadResponse.status === 200) {
        yield put(setFileuploadRequestId(fileUploadResponse.data.request_id));
    } else {
        yield put(setHideLoader());
    }
}
// tslint:disable-next-line:typedef
export function* fetchTenantLimits(action: AnyAction) {
    const { tenantCode = "" } = getContext();
    const payload = {
        userId: action.payload.userID,
        // tslint:disable-next-line:object-shorthand-properties-first
        tenantCode
    };
    yield put(setShowLoader());
    const response = yield call(Apis.getTenantLimits, payload);

    if (response.status === 200) {
        yield put(setFeatureAddOnsInContext(response.data.data.feature_addons));
        yield put(setDomainAndKeywordsLimitInContext(response.data.data.limits));
        const preMeta = localStorage.getItem(ALPS_LOCAL_STORAGE_KEY);
        const ls = !isNull(preMeta) ? JSON.parse(preMeta) : {};
        localStorage.setItem(ALPS_LOCAL_STORAGE_KEY, JSON.stringify({ ...ls, featureAddOns: response.data.data.feature_addons }));
        const { isCalledFromHomePage, getSimHistorypayload } = action.payload;
        // call simulation History API after getting tenant limits, if called from Home Page
        if (isCalledFromHomePage) {
            yield put(getSimulationHistory(getSimHistorypayload));
        }
    }
    yield put(setHideLoader());
}

// tslint:disable-next-line:typedef
export function* deleteProject(action: AnyAction) {
    const { tenantCode = "" } = getContext();
    const payload = {
        tenantCode,
        projectId: action.payload
    };
    yield put(setShowLoader());
    const response = yield call(Apis.deleteProjectCall, payload);
    if (response.status === 200) {
        yield put(callToGetAllProjectsList());
    }
    yield put(setHideLoader());
}

// tslint:disable-next-line:typedef
export function* watchForDeleteProject() {
    yield takeEvery(actionTypes.CALL_DELETE_PROJECT, deleteProject);
}

// tslint:disable-next-line:typedef
export function* watchForGetProjectDetails() {
    yield takeEvery(actionTypes.GET_PROJECT_DETAILS, fetchProjectDetails);
}

// tslint:disable-next-line:typedef
export function* watchForGetTenantLimits() {
    yield takeEvery(actionTypes.GET_TENANT_LIMITS, fetchTenantLimits);
}

// tslint:disable-next-line:typedef
export function* watchCallToClearUserSelection() {
    yield takeEvery(actionTypes.CLEAR_USER_SELECTION, clearUserSelectionFromContext);
}

// tslint:disable-next-line:typedef
export function* watchForCallFileUpload() {
    yield takeEvery(actionTypes.CALL_FILE_UPLOAD, fileUploadOnPageElements);
}

// tslint:disable-next-line: typedef
export function* watchFetchAllProjectsList() {
    yield takeEvery(ActionType.CALL_TO_GET_ALL_PROJECTS_LIST, fetchAllProjectsList);
}
