import { createAction } from "redux-actions";
const actionTypes = {
    SET_CONTEXTUAL_RECOMMENDATION: "SET_CONTEXTUAL_RECOMMENDATION",
    SET_CONTEXTUAL_RECOMMENDATION_MODAL_OPEN: "SET_CONTEXTUAL_RECOMMENDATION_MODAL_OPEN",
    CALL_CONTEXTUAL_RECOMMENDATION_INITIATE: "CALL_CONTEXTUAL_RECOMMENDATION_INITIATE",
    CALL_CONTEXTUAL_RECOMMENDATION: "CALL_CONTEXTUAL_RECOMMENDATION",
    SET_CONTEXTUAL_RECOMMENDATION_API_FAIL: "SET_CONTEXTUAL_RECOMMENDATION_API_FAIL"
};
export default actionTypes;
export const setContextualRecommendation = createAction(actionTypes.SET_CONTEXTUAL_RECOMMENDATION);
export const setContextualRecommendationModalOpen = createAction(actionTypes.SET_CONTEXTUAL_RECOMMENDATION_MODAL_OPEN);
export const callContextualRecommendationInitiate = createAction(actionTypes.CALL_CONTEXTUAL_RECOMMENDATION_INITIATE);
export const callContextualRecommendation = createAction(actionTypes.CALL_CONTEXTUAL_RECOMMENDATION);
export const setContextualRecommendationApiFail = createAction(actionTypes.SET_CONTEXTUAL_RECOMMENDATION_API_FAIL);
