import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */
const actionTypes = {
    CALL_FOR_SET_CONTEXT: "CALL_FOR_SET_CONTEXT_BETA",
    CALL_TO_GET_COMPETITORS_SCORE: "CALL_TO_GET_COMPETITORS_SCORE_BETA",
    CALL_TO_GET_MODEL_SCORE: "CALL_TO_GET_MODEL_SCORE_BETA",
    CALL_TO_GET_MODEL_SCORE_DETAILS: "CALL_TO_GET_MODEL_SCORE_DETAILS_BETA",
    CALL_TO_GET_KEYWORD_STUFFING_DETAILS: "CALL_TO_GET_KEYWORD_STUFFING_DETAILS_BETA",
    CALL_TO_GET_ON_PAGE_ELEMENTS: "CALL_TO_GET_ON_PAGE_ELEMENTS_BETA",
    CALL_TO_GET_PERFORMANCE_SCORE: "CALL_TO_GET_PERFORMANCE_SCORE_BETA",
    CALL_TO_GET_PRE_SIM_KEYWORD_LEVEL_DETAILS: "CALL_TO_GET_PRE_SIM_KEYWORD_LEVEL_DETAILS_BETA",
    CONTENT_SIMULATION_RESET_SLICE: "CONTENT_SIMULATION_RESET_SLICE_BETA",
    SET_CHANGED_CONTENT: "SET_CHANGED_CONTENT_BETA",
    SET_COMPETITORS_SCORE: "SET_COMPETITORS_SCORE_BETA",
    SET_INIT_PARAM: "SET_INIT_PARAM_BETA",
    SET_MODEL_SCORE: "SET_MODEL_SCORE_BETA",
    SET_MODEL_SCORE_DETAILS: "SET_MODEL_SCORE_DETAILS_BETA",
    SET_KEYWORD_STUFFING_DETAILS: "SET_KEYWORD_STUFFING_DETAILS",
    SET_ON_PAGE_ELEMENTS: "SET_ON_PAGE_ELEMENTS_BETA",
    SET_AI_GENERATED_PAGE_CONTENT: "SET_AI_GENERATED_PAGE_CONTENT",
    SET_PAGE_ZOOM_MODE: "SET_PAGE_ZOOM_MODE_BETA",
    SET_PERFORMANCE_SCORE: "SET_PERFORMANCE_SCORE_BETA",
    SET_REVOKE_LOADER: "SET_REVOKE_LOADER_BETA",
    SET_SIMULATED_SCORE: "SET_SIMULATED_SCORE_BETA",
    SET_URL: "SET_URL_BETA",
    SET_SIMULATION_NOTIFICATION: "SET_SIMULATION_NOTIFICATION_BETA",
    SET_SELECTED_CATEGORY_TYPE: "SET_SELECTED_CATEGORY_TYPE_BETA",
    SET_PRESIMULATED_DATA: "SET_PRESIMULATED_DATA",
    SET_AUTHORITY_APPLIED_FILTERS: "SET_AUTHORITY_APPLIED_FILTERS_BETA",
    SET_TECHNICAL_APPLIED_FILTERS: "SET_TECHNICAL_APPLIED_FILTERS_BETA",
    SET_DETAIL_SECTION: "SET_DETAIL_SECTION_BETA",
    SET_CONTENT_EDITOR_DIRTY: "SET_CONTENT_EDITOR_DIRTY_BETA",
    SET_INIT_HTML_EDITOR_CONTENT: "SET_INIT_HTML_EDITOR_CONTENT_BETA",
    SET_SIMULATION_VERSION_STATUS: "SET_SIMULATION_VERSION_STATUS_BETA",
    RESET_CHANGED_PARAMETER: "RESET_CHANGED_PARAMETER_BETA",
    RESET_CONTENT_CHANGED_PARAMETER: "RESET_CONTENT_CHANGED_PARAMETER_BETA",
    CALL_TO_OPEN_CLOSE_VIEW_TRAFFIC: "CALL_TO_OPEN_CLOSE_VIEW_TRAFFIC_BETA",
    SIMULATE_FOR_ALL_KEYWORDS: "SIMULATE_FOR_ALL_KEYWORDS_BETA",
    SET_FETCH_PROCESS_STATUS: "SET_FETCH_PROCESS_STATUS_BETA",
    FORCED_UPDATE_KEYWORDS: "FORCED_UPDATE_KEYWORDS_BETA",
    RESET_IS_SIMULATE_DONE: "RESET_IS_SIMULATE_DONE_BETA",
    SET_KEYWORD_LEVEL_IMPACTED_SCORES: "SET_KEYWORD_LEVEL_IMPACTED_SCORES_BETA",
    SET_KEYWORD_LEVEL_IMPACTED_PERCORMANCE_SCORES: "SET_KEYWORD_LEVEL_IMPACTED_PERCORMANCE_SCORES_BETA",
    SET_PRE_SIMULATED_KEYWORD_LEVEL_DETAILS: "SET_PRE_SIMULATED_KEYWORD_LEVEL_DETAILS_BETA",
    SETTING_ON_PAGE_STATUS_CODE: "SETTING_ON_PAGE_STATUS_CODE_BETA",
    SET_ONLY_CHANGED_CONTENT: "SET_ONLY_CHANGED_CONTENT_BETA",
    SET_ONLY_EDITOR_CONTENT_CHANGED: "SET_ONLY_EDITOR_CONTENT_CHANGED_BETA",
    SET_FETCH_PERFORMANCE_SCORE_SUCCESS: "SET_FETCH_PERFORMANCE_SCORE_SUCCESS_BETA",
    CALL_TO_GET_VERSION_LIST: "CALL_TO_GET_VERSION_LIST_BETA",
    SET_VERSION_LIST: "SET_VERSION_LIST_BETA",
    SELECTED_VERSION: "SELECTED_VERSION_BETA",
    SET_CRAWL_DATES: "SET_CRAWL_DATES_BETA",
    CALL_SCORE_API_BASED_ON_VERSION: "CALL_SCORE_API_BASED_ON_VERSION_BETA",
    SET_SCORE_BASED_ON_VERSION: "SET_SCORE_BASED_ON_VERSION_BETA",
    CALL_TO_CAPTURE_SNAPSHOT_OF_META_INFORMATION: "CALL_TO_CAPTURE_SNAPSHOT_OF_META_INFORMATION_BETA",
    CALL_TO_SET_SID_NEXT: "CALL_TO_SET_SID_NEXT_BETA",
    CALL_CONTENT_API_BASED_ON_VERSION: "CALL_CONTENT_API_BASED_ON_VERSION_BETA",
    SET_ORIGINAL_WORD_COUNT: "SET_ORIGINAL_WORD_COUNT",
    SET_ON_PAGE_ELEMENTS_BASED_ON_VERSION: "SET_ON_PAGE_ELEMENTS_BASED_ON_VERSION_BETA",
    CALL_TO_SAVE_DESCRIPTION: "CALL_TO_SAVE_DESCRIPTION_BETA",
    SET_DESCRIPTION: "SET_DESCRIPTION_BETA",
    SET_SIMULATIONS_VERSION_CHANGE_FLAG: "SET_SIMULATIONS_VERSION_CHANGE_FLAG_BETA",
    GET_SIMULATION_META_INFO: "GET_SIMULATION_META_INFO_BETA",
    GET_AUTH_TECH_PARAMS: "GET_AUTH_TECH_PARAMS_BETA",
    SET_AUTH_TECH_PARAM_VALUES: "SET_AUTH_TECH_PARAM_VALUES_BETA",
    SET_USER_SELECTION: "SET_USER_SELECTION_BETA",
    UPDATE_SID_TO_SAVE_COMPETITORS_SCORE: "UPDATE_SID_TO_SAVE_COMPETITORS_SCORE_BETA",
    GET_COMPETITORS_SCORE_BASED_ON_VERSION: "GET_COMPETITORS_SCORE_BASED_ON_VERSION_BETA",
    SET_FIRST_TIME_RENDERING_FLAG: "SET_FIRST_TIME_RENDERING_FLAG_BETA",
    SET_REFRESH_BUTTON_CLICKED: "SET_REFRESH_BUTTON_CLICKED_BETA",
    GET_SIMULATION_VERSION_STATUS: "GET_SIMULATION_VERSION_STATUS_BETA",
    SET_NOTIFICATION_ON_VERSION: "SET_NOTIFICATION_ON_VERSION_BETA",
    SET_IMPACTED_KEYWORDS: "SET_IMPACTED_KEYWORDS_BETA",
    CLEAR_OLD_SIMULATION_STATE: "CLEAR_OLD_SIMULATION_STATE_BETA",
    HIDE_REFRESH_MESSAGE: "HIDE_REFRESH_MESSAGE_BETA",
    RESET_TECH_AUTH_CHANGED_PARAMS: "RESET_TECH_AUTH_CHANGED_PARAMS_BETA",
    SET_FORMATTED_ON_PAGE_ELEMENTS: "SET_FORMATTED_ON_PAGE_ELEMENTS_BETA",
    SET_CLEANED_ORIGINAL_CONTENT: "SET_CLEANED_ORIGINAL_CONTENT",
    SET_INSIGHTS_SKIP_KW: "SET_INSIGHTS_SKIP_KW_BETA",
    SET_INSIGHTS_ORIG_KW: "SET_INSIGHTS_ORIG_KW_BETA",
    SET_IS_KLI_OPEN: "SET_IS_KLI_OPEN",
    SET_ALL_KWS_PROCESSED: "SET_ALL_KWS_PROCESSED",
    SET_TECH_DATA_FAILED: "SET_TECH_DATA_FAILED",
    // Browser mode
    SWITCH_EDITOR_MODE: "SWITCH_EDITOR_MODE_BETA",
    SELECT_TAG_TO_HIGHLIGHT: "SELECT_TAG_TO_HIGHLIGHT_BETA",
    HIGHLIGHT_COLOR_CHANGED: "HIGHLIGHT_COLOR_CHANGED_BETA",
    TAG_NOT_FOUND: "TAG_NOT_FOUND_BETA",

    // Browser content
    CALL_TO_GET_ON_PAGE_BROWSER_CONTENT: "CALL_TO_GET_ON_PAGE_BROWSER_CONTENT_BETA",
    SET_ON_PAGE_BROWSER_CONTENT: "SET_ON_PAGE_BROWSER_CONTENT_BETA",
    SET_ONLY_BROWSER_CHANGED_CONTENT: "SET_ONLY_BROWSER_CHANGED_CONTENT_BETA",
    SET_BROWSER_EDITOR_SIMULATED_SCORE: "SET_BROWSER_EDITOR_SIMULATED_SCORE_BETA",
    SET_CHANGED_BROWSER_EDITOR_CONTENT: "SET_CHANGED_BROWSER_EDITOR_CONTENT_BETA",
    SET_ON_BROWSER_EDITOR_BASED_ON_VERSION: "SET_ON_BROWSER_EDITOR_BASED_ON_VERSION_BETA",
    IFRAME_LOADED: "IFRAME_LOADED_BETA",
    SET_TOOLBAR_ACTION: "SET_TOOLBAR_ACTION",
    SET_EDITOR_MODE: "SET_EDITOR_MODE_BETA",
    SET_IS_BROWSER_MODE: "SET_IS_BROWSER_MODE",
    SET_BROWSER_CONTENT: "SET_BROWSER_CONTENT",
    SET_IS_FETCH_CONTENT_ON_SWITCH_EDITOR_MODE: "SET_IS_FETCH_CONTENT_ON_SWITCH_EDITOR_MODE",
    SET_IS_CONTENT_FETCHED: "SET_IS_CONTENT_FETCHED",
    SET_KEYWORD_STUFFING_DATA: "SET_KEYWORD_STUFFING_DATA",
    SET_REFRESH_BUTTON_CLICKED_BY_MULTI: "SET_REFRESH_BUTTON_CLICKED_BY_MULTI",
    SET_IS_CONTENT_EDITOR_DIRTY: "SET_IS_CONTENT_EDITOR_DIRTY",
    CALL_SIMULATION_DOWNLOAD_API: "CALL_SIMULATION_DOWNLOAD_API",
    CALL_SIMULATION_DOWNLOAD_CONTENT_ONLY_API: "CALL_SIMULATION_DOWNLOAD_CONTENT_ONLY_API",
    CALL_SIMULATION_DOWNLOAD_CONTENT_ONLY_INITIATE_API: "CALL_SIMULATION_DOWNLOAD_CONTENT_ONLY_INITIATE_API",
    CALL_SIMULATION_DOWNLOAD_CONTENT_ONLY_TRACK_API: "CALL_SIMULATION_DOWNLOAD_CONTENT_ONLY_TRACK_API",
    CALL_SIMULATION_DOWNLOAD_PAGE_ASSESSMENT_API: "CALL_SIMULATION_DOWNLOAD_PAGE_ASSESSMENT_API",
    SET_IS_DOWNLOAD_SIMULATION: "SET_IS_DOWNLOAD_SIMULATION",
    SET_IS_DOWNLOAD_FAILED: "SET_IS_DOWNLOAD_FAILED",
    SET_IS_DOWNLOAD_PA_FAILED: "SET_IS_DOWNLOAD_PA_FAILED",
    SET_CHANGED_URL_IN_FILTER_URL_LIST: "SET_CHANGED_URL_IN_FILTER_URL_LIST",
    SET_SIMULATION_EDITOR_OLD_URL: "SET_SIMULATION_EDITOR_OLD_URL",
    SET_USE_THIS_CONTENT: "SET_USE_THIS_CONTENT",
    SET_IS_VIEW_RECOMMENDATION_OPEN: "SET_IS_VIEW_RECOMMENDATION_OPEN",
    SET_BODY_WORD_COUNT: "SET_BODY_WORD_COUNT",
    SET_IS_FIRST_TIME_RECO_CLOSE: "SET_IS_FIRST_TIME_RECO_CLOSE",
    SET_SELECTED_TAG_VALUE: "SET_SELECTED_TAG_VALUE",
    CALL_TO_GET_COMPETITOR_SCORE_SSE_REQID: "CALL_TO_GET_COMPETITOR_SCORE_SSE_REQID",
    CALL_TO_GET_COMPETITOR_SCORE_SSE: "CALL_TO_GET_COMPETITOR_SCORE_SSE",
    SET_COMPETITORS_SCORE_SSE_LOADING: "SET_COMPETITORS_SCORE_SSE_LOADING",
    SET_COMPETITOR_SCORE_SSE_RETRIED_ATTEMPT: "SET_COMPETITOR_SCORE_SSE_RETRIED_ATTEMPT",
    SET_URL_KEYWORDS_SCORE: "SET_URL_KEYWORDS_SCORE",
    SET_ALERT_VIEW: "SET_ALERT_VIEW",
    SET_REVERT_TO_ORIGINAL_STATUS: "SET_REVERT_TO_ORIGINAL_STATUS",
    SET_DESCRIPTION_TO_ORIGINAL: "SET_DESCRIPTION_TO_ORIGINAL",
    CALL_TO_REVERT_BROWSER_MODE: "CALL_TO_REVERT_BROWSER_MODE",
    CALL_TO_GET_COMPETITOR_SCORE_NEW_KEYWORD: "CALL_TO_GET_COMPETITOR_SCORE_NEW_KEYWORD",
    SET_TOP_COMP_FETCH_STATUS: "SET_TOP_COMP_FETCH_STATUS",
    SET_NEW_SIMULATION_STATUS: "SET_NEW_SIMULATION_STATUS",
    SET_META_INFO_FETCH_STATUS: "SET_META_INFO_FETCH_STATUS",
    CALL_RECOM_BULB_SUBHEADER_SCORES: "CALL_RECOM_BULB_SUBHEADER_SCORES",
    SET_RECOM_BULB_SUBHEADER_SCORES: "SET_RECOM_BULB_SUBHEADER_SCORES",
    SET_VIEW_ORIGINAL_STATUS: "SET_VIEW_ORIGINAL_STATUS",
    SET_INFOTAINMENT_LOADING: "SET_INFOTAINMENT_LOADING",
    MARK_DOES_INPUT_KEYWORDS_EXCEED_ALLOWED_LIMIT: "MARK_DOES_INPUT_KEYWORDS_EXCEED_ALLOWED_LIMIT",
    SET_KW_LEVEL_INTENT_DATA: "SET_KW_LEVEL_INTENT_DATA",
    SET_PAGE_ASSESSMENT_DISABLED: "SET_PAGE_ASSESSMENT_DISABLED",
    PA_SELECTED_KW: "PA_SELECTED_KW_BETA",
    SET_SAVE_SIMULATION_CALLED_BEFORE_TRACK_REQ: "SET_SAVE_SIMULATION_CALLED_BEFORE_TRACK_REQ",
    SET_KEYWORDS_PROCESSING_STATUS: "SET_KEYWORDS_PROCESSING_STATUS",

    IS_META_ELEMENT_CHANGE: "IS_META_ELEMENT_CHANGE",
    SET_VERSION_KEYWORDS: "SET_VERSION_KEYWORDS",
    SET_IS_SIMULATION_VERSION_CHANGE: "SET_IS_SIMULATION_VERSION_CHANGE",
    SELECTED_TAB_RECOMMENDATION: "SELECTED_TAB_RECOMMENDATION",
    SET_PHRASE_CONTENT: "SET_PHRASE_CONTENT",
    CALL_TO_FETCH_PHRASE_CONTENT: "CALL_TO_FETCH_PHRASE_CONTENT",
    UPDATE_PHRASE_CONTENT: "UPDATE_PHRASE_CONTENT",
    CALL_FECTH_TOP_COMPETETIVE_URL: "CALL_FECTH_TOP_COMPETETIVE_URL",
    IS_SIMULATION_DONE: "IS_SIMULATION_DONE",
    SET_SELECTED_RECO_PHRASE_DD: "SET_SELECTED_RECO_PHRASE_DD",
    SET_IS_SHOW_TOP_PAGES_LOADER: "SET_IS_SHOW_TOP_PAGES_LOADER",
    INITIAL_KEYWORDS_DROPDOWN_HEAD: "INITIAL_KEYWORDS_DROPDOWN_HEAD",
    SET_SIMULATION_KEYWORDS: "SET_SIMULATION_KEYWORDS",
    SET_PHRASES_PAYLOAD_KEYWORDS: "SET_PHRASES_PAYLOAD_KEYWORDS",
    SET_IS_FIRST_TIME_RECO_OPEN: "SET_IS_FIRST_TIME_RECO_OPEN",
    SET_CONTEXTUAL_RECOMMENDATION: "SET_CONTEXTUAL_RECOMMENDATION",
    SET_CONTEXTUAL_RECOMMENDATION_MODAL_OPEN: "SET_CONTEXTUAL_RECOMMENDATION_MODAL_OPEN",
    RESET_RECOM_BULB_SUBHEADER_SCORES: "RESET_RECOM_BULB_SUBHEADER_SCORES",
    SET_IS_DOWNLOAD_PAGE_ASSESSMENT: "SET_IS_DOWNLOAD_PAGE_ASSESSMENT",
    SET_REFRESH_NOW_CLICKED: "SET_REFRESH_NOW_CLICKED"
};
export default actionTypes;

export const callToGetCompetitorsScore = createAction(actionTypes.CALL_TO_GET_COMPETITORS_SCORE);
export const callToGetPerformanceScore = createAction(actionTypes.CALL_TO_GET_PERFORMANCE_SCORE);
export const callToGetModelScore = createAction(actionTypes.CALL_TO_GET_MODEL_SCORE);
export const callToGetModelScoreDetails = createAction(actionTypes.CALL_TO_GET_MODEL_SCORE_DETAILS);
export const callToGetKeywordStuffingDetails = createAction(actionTypes.CALL_TO_GET_KEYWORD_STUFFING_DETAILS);
export const callToGetOnPageElements = createAction(actionTypes.CALL_TO_GET_ON_PAGE_ELEMENTS);
export const callToGetPreSimKeywordLevelDetails = createAction(actionTypes.CALL_TO_GET_PRE_SIM_KEYWORD_LEVEL_DETAILS);
export const setAggregatedPerformanceScore = createAction(actionTypes.SET_PERFORMANCE_SCORE);
export const setCompetitorsScore = createAction(actionTypes.SET_COMPETITORS_SCORE);
export const setUrlKeywordsScore = createAction(actionTypes.SET_URL_KEYWORDS_SCORE);
export const setOnPageElements = createAction(actionTypes.SET_ON_PAGE_ELEMENTS);
export const setAIGeneratedPageContent = createAction(actionTypes.SET_AI_GENERATED_PAGE_CONTENT);
export const setModelScore = createAction(actionTypes.SET_MODEL_SCORE);
export const setModelScoreDetails = createAction(actionTypes.SET_MODEL_SCORE_DETAILS);
export const setKeywordStuffDetails = createAction(actionTypes.SET_KEYWORD_STUFFING_DETAILS);
export const setSimulatedScore = createAction(actionTypes.SET_SIMULATED_SCORE);
export const resetSlice = createAction(actionTypes.CONTENT_SIMULATION_RESET_SLICE);
export const setChangedContent = createAction(actionTypes.SET_CHANGED_CONTENT);
export const setPageZoomMode = createAction(actionTypes.SET_PAGE_ZOOM_MODE);
export const setRevokeLoader = createAction(actionTypes.SET_REVOKE_LOADER);
export const setUrl = createAction(actionTypes.SET_URL);
export const setSimulationNotification = createAction(actionTypes.SET_SIMULATION_NOTIFICATION);
export const setSelectedCategoryType = createAction(actionTypes.SET_SELECTED_CATEGORY_TYPE);
export const setPresimulatedData = createAction(actionTypes.SET_PRESIMULATED_DATA);
export const setAuthorityAppliedFilter = createAction(actionTypes.SET_AUTHORITY_APPLIED_FILTERS);
export const setTechnicalAppliedFilter = createAction(actionTypes.SET_TECHNICAL_APPLIED_FILTERS);
export const resetChangedParameter = createAction(actionTypes.RESET_CHANGED_PARAMETER);
export const resetContentChangedParameter = createAction(actionTypes.RESET_CONTENT_CHANGED_PARAMETER);
export const setContentEditorDirty = createAction(actionTypes.SET_CONTENT_EDITOR_DIRTY);
export const setInitHTMLEditorContent = createAction(actionTypes.SET_INIT_HTML_EDITOR_CONTENT);
export const callToOpenCloseViewTraffic = createAction(actionTypes.CALL_TO_OPEN_CLOSE_VIEW_TRAFFIC);
export const callToSimulateForAllKeywords = createAction(actionTypes.SIMULATE_FOR_ALL_KEYWORDS);
export const setFetchProcessStatus = createAction(actionTypes.SET_FETCH_PROCESS_STATUS);
export const callToForcedUpdateKeywords = createAction(actionTypes.FORCED_UPDATE_KEYWORDS);
export const callToResetIsSimulateDone = createAction(actionTypes.RESET_IS_SIMULATE_DONE);
export const setKeywordLevelImpactScores = createAction(actionTypes.SET_KEYWORD_LEVEL_IMPACTED_SCORES);
export const setKeywordLevelImpactedPerformanceScore = createAction(actionTypes.SET_KEYWORD_LEVEL_IMPACTED_PERCORMANCE_SCORES);
export const setPreSimKeywordDetails = createAction(actionTypes.SET_PRE_SIMULATED_KEYWORD_LEVEL_DETAILS);
export const SettingOnPageStatusCode = createAction(actionTypes.SETTING_ON_PAGE_STATUS_CODE);
export const setOnlyChangedContent = createAction(actionTypes.SET_ONLY_CHANGED_CONTENT);
export const setOnlyEditorContentChanged = createAction(actionTypes.SET_ONLY_EDITOR_CONTENT_CHANGED);
export const setFetchPerformanceScorePassed = createAction(actionTypes.SET_FETCH_PERFORMANCE_SCORE_SUCCESS);
export const callToGetVersionList = createAction(actionTypes.CALL_TO_GET_VERSION_LIST);
export const SetVersionList = createAction(actionTypes.SET_VERSION_LIST);
export const setSelectedVersion = createAction(actionTypes.SELECTED_VERSION);
export const setCrawlDate = createAction(actionTypes.SET_CRAWL_DATES);
export const setSimulationVersionStatus = createAction(actionTypes.SET_SIMULATION_VERSION_STATUS);
export const callScoreApiBasedOnVersion = createAction(actionTypes.CALL_SCORE_API_BASED_ON_VERSION);
export const setScoreBasedOnVersion = createAction(actionTypes.SET_SCORE_BASED_ON_VERSION);
export const callToCaptureSnapshotOfMetaInformation = createAction(actionTypes.CALL_TO_CAPTURE_SNAPSHOT_OF_META_INFORMATION);
export const callToSetSidNext = createAction(actionTypes.CALL_TO_SET_SID_NEXT);
export const callContentApiBasedOnVersion = createAction(actionTypes.CALL_CONTENT_API_BASED_ON_VERSION);
export const setOriginalWordCount = createAction(actionTypes.SET_ORIGINAL_WORD_COUNT);
export const setOnPageElementsBasedOnVersion = createAction(actionTypes.SET_ON_PAGE_ELEMENTS_BASED_ON_VERSION);
export const callToSaveDescription = createAction(actionTypes.CALL_TO_SAVE_DESCRIPTION);
export const setSaveDescription = createAction(actionTypes.SET_DESCRIPTION);
export const setSimulationsVersionChangeFlag = createAction(actionTypes.SET_SIMULATIONS_VERSION_CHANGE_FLAG);
export const getSimulationMetaInfo = createAction(actionTypes.GET_SIMULATION_META_INFO);
export const getAuthTechParams = createAction(actionTypes.GET_AUTH_TECH_PARAMS);
export const setAuthTechParamValues = createAction(actionTypes.SET_AUTH_TECH_PARAM_VALUES);
export const setUserSelection = createAction(actionTypes.SET_USER_SELECTION);
export const updateSidToSaveCompetitorsScore = createAction(actionTypes.UPDATE_SID_TO_SAVE_COMPETITORS_SCORE);
export const getCompetitorsScoreBasedOnVersion = createAction(actionTypes.GET_COMPETITORS_SCORE_BASED_ON_VERSION);
export const getSimulationVersionStatus = createAction(actionTypes.GET_SIMULATION_VERSION_STATUS);
export const setNotificationOnVersion = createAction(actionTypes.SET_NOTIFICATION_ON_VERSION);
export const setFirstTimeRenderingFlag = createAction(actionTypes.SET_FIRST_TIME_RENDERING_FLAG);
export const setRefreshButtonClicked = createAction(actionTypes.SET_REFRESH_BUTTON_CLICKED);
export const hideRefreshNotification = createAction(actionTypes.HIDE_REFRESH_MESSAGE);
export const setImpactedKeywords = createAction(actionTypes.SET_IMPACTED_KEYWORDS);
export const clearOldSimulationState = createAction(actionTypes.CLEAR_OLD_SIMULATION_STATE);
export const resetTechAuthChangedParams = createAction(actionTypes.RESET_TECH_AUTH_CHANGED_PARAMS);
export const switchEditorMode = createAction(actionTypes.SWITCH_EDITOR_MODE);
export const selectTagToHighlight = createAction(actionTypes.SELECT_TAG_TO_HIGHLIGHT);
export const changeHighlightColor = createAction(actionTypes.HIGHLIGHT_COLOR_CHANGED);
export const setToolbarAction = createAction(actionTypes.SET_TOOLBAR_ACTION);
export const setCleanedOriginalContent = createAction(actionTypes.SET_CLEANED_ORIGINAL_CONTENT);
export const callToGetCompetitorsScoreSSEReqid = createAction(actionTypes.CALL_TO_GET_COMPETITOR_SCORE_SSE_REQID);
export const callToGetCometitorScoreSSE = createAction(actionTypes.CALL_TO_GET_COMPETITOR_SCORE_SSE);
export const competitorsScoreSSELoading = createAction(actionTypes.SET_COMPETITORS_SCORE_SSE_LOADING);
export const setCompetitorScoreSSeRetriedAttempt = createAction(actionTypes.SET_COMPETITOR_SCORE_SSE_RETRIED_ATTEMPT);
export const callToGetCompetitorScoreNewKeyword = createAction(actionTypes.CALL_TO_GET_COMPETITOR_SCORE_NEW_KEYWORD);
export const callRecomBulbSubHeaderScores = createAction(actionTypes.CALL_RECOM_BULB_SUBHEADER_SCORES);
export const setRecomBulbSubHeaderScores = createAction(actionTypes.SET_RECOM_BULB_SUBHEADER_SCORES);
export const setAllKWsProcessed = createAction(actionTypes.SET_ALL_KWS_PROCESSED);
export const setTechDataFailed = createAction(actionTypes.SET_TECH_DATA_FAILED);
export const setPageAssessmentDisabled = createAction(actionTypes.SET_PAGE_ASSESSMENT_DISABLED);
export const paSelectedKw = createAction(actionTypes.PA_SELECTED_KW);
export const setIsDownloadPageAssessment = createAction(actionTypes.SET_IS_DOWNLOAD_PAGE_ASSESSMENT);

// Browser content actions
export const setOnPageBrowserContent = createAction(actionTypes.SET_ON_PAGE_BROWSER_CONTENT);
export const setOnlyBrowserChangedContent = createAction(actionTypes.SET_ONLY_BROWSER_CHANGED_CONTENT);
export const setBrowserEditorSimulatedScore = createAction(actionTypes.SET_BROWSER_EDITOR_SIMULATED_SCORE);
export const setChangedBrowserEditorContent = createAction(actionTypes.SET_CHANGED_BROWSER_EDITOR_CONTENT);
export const setOnBrowserEditorBasedOnVersion = createAction(actionTypes.SET_ON_BROWSER_EDITOR_BASED_ON_VERSION);
export const setIframeLoaded = createAction(actionTypes.IFRAME_LOADED);
export const setTagNotFound = createAction(actionTypes.TAG_NOT_FOUND);
export const setFormattedOnPageElements = createAction(actionTypes.SET_FORMATTED_ON_PAGE_ELEMENTS);
export const setEditorMode = createAction(actionTypes.SET_EDITOR_MODE);
export const setIsBrowserMode = createAction(actionTypes.SET_IS_BROWSER_MODE);
export const setBrowserContent = createAction(actionTypes.SET_BROWSER_CONTENT);
export const setIsFetchContentOnSwitchEditorMode = createAction(actionTypes.SET_IS_FETCH_CONTENT_ON_SWITCH_EDITOR_MODE);
export const setIsContentFetched = createAction(actionTypes.SET_IS_CONTENT_FETCHED);
export const setKeywordStuffingData = createAction(actionTypes.SET_KEYWORD_STUFFING_DATA);
export const setRefreshButtonClickedByMulti = createAction(actionTypes.SET_REFRESH_BUTTON_CLICKED_BY_MULTI);
export const setIsEditorDirty = createAction(actionTypes.SET_IS_CONTENT_EDITOR_DIRTY);
export const setIsDownloadFailed = createAction(actionTypes.SET_IS_DOWNLOAD_FAILED);
export const setIsDownloadPAFailed = createAction(actionTypes.SET_IS_DOWNLOAD_PA_FAILED);
export const setChangedUrlInFilterUrlList = createAction(actionTypes.SET_CHANGED_URL_IN_FILTER_URL_LIST);
export const setSimulationEditorOldUrl = createAction(actionTypes.SET_SIMULATION_EDITOR_OLD_URL);
export const setUseThisContent = createAction(actionTypes.SET_USE_THIS_CONTENT);
export const setIsViewRecommendationOpen = createAction(actionTypes.SET_IS_VIEW_RECOMMENDATION_OPEN);
export const callSimulationDownloadApi = createAction(actionTypes.CALL_SIMULATION_DOWNLOAD_API);
export const callSimulationDownloadContentOnlyApi = createAction(actionTypes.CALL_SIMULATION_DOWNLOAD_CONTENT_ONLY_API);
export const callSimulationDownloadContentOnlyInitiateApi = createAction(actionTypes.CALL_SIMULATION_DOWNLOAD_CONTENT_ONLY_INITIATE_API);
export const callSimulationDownloadContentOnlyTrackApi = createAction(actionTypes.CALL_SIMULATION_DOWNLOAD_CONTENT_ONLY_TRACK_API);
export const callSimulationDownloadPageAssessmentApi = createAction(actionTypes.CALL_SIMULATION_DOWNLOAD_PAGE_ASSESSMENT_API);
export const setIsDownloadSimulation = createAction(actionTypes.SET_IS_DOWNLOAD_SIMULATION);
export const setBodyWordCount = createAction(actionTypes.SET_BODY_WORD_COUNT);
export const setIsFirstTimeRecoClose = createAction(actionTypes.SET_IS_FIRST_TIME_RECO_CLOSE);
export const setSelectedTagValue = createAction(actionTypes.SET_SELECTED_TAG_VALUE);
export const setAlertView = createAction(actionTypes.SET_ALERT_VIEW);
export const setRevertToOriginalStatus = createAction(actionTypes.SET_REVERT_TO_ORIGINAL_STATUS);
export const callToRevertBrowserMode = createAction(actionTypes.CALL_TO_REVERT_BROWSER_MODE);
export const setDescriptionToOriginal = createAction(actionTypes.SET_DESCRIPTION_TO_ORIGINAL);
export const setTopCompFetchStatus = createAction(actionTypes.SET_TOP_COMP_FETCH_STATUS);
export const setNewSimulationStatus = createAction(actionTypes.SET_NEW_SIMULATION_STATUS);
export const setMetaInfoFetchStatus = createAction(actionTypes.SET_META_INFO_FETCH_STATUS);
export const setInsightsSkipKW = createAction(actionTypes.SET_INSIGHTS_SKIP_KW);
export const setInsightsOrigKW = createAction(actionTypes.SET_INSIGHTS_ORIG_KW);
export const setIsKLIOpen = createAction(actionTypes.SET_IS_KLI_OPEN);
export const setViewOriginalStatus = createAction(actionTypes.SET_VIEW_ORIGINAL_STATUS);
export const setInfotainmentLoadingStatus = createAction(actionTypes.SET_INFOTAINMENT_LOADING);
export const markDoesInputKeywordsExceedAllowedLimit = createAction(actionTypes.MARK_DOES_INPUT_KEYWORDS_EXCEED_ALLOWED_LIMIT);
export const setKWLevelIntentData = createAction(actionTypes.SET_KW_LEVEL_INTENT_DATA);
export const setSavedSimulationCalledBeforeTrackReqComp = createAction(actionTypes.SET_SAVE_SIMULATION_CALLED_BEFORE_TRACK_REQ);
export const setKeywordsProcessingStatus = createAction(actionTypes.SET_KEYWORDS_PROCESSING_STATUS);
export const resetRecomBulbSubHeaderScores = createAction(actionTypes.RESET_RECOM_BULB_SUBHEADER_SCORES);

export const setIsMetaElementChange = createAction(actionTypes.IS_META_ELEMENT_CHANGE);
export const setVersionKeywords = createAction(actionTypes.SET_VERSION_KEYWORDS);
export const setIsSimulationVersionChange = createAction(actionTypes.SET_IS_SIMULATION_VERSION_CHANGE);
export const setSelectedTabRecommendation = createAction(actionTypes.SELECTED_TAB_RECOMMENDATION);
export const setPhraseContent = createAction(actionTypes.SET_PHRASE_CONTENT);
export const updatePhraseContent = createAction(actionTypes.UPDATE_PHRASE_CONTENT);
export const callToFetchPhraseContent = createAction(actionTypes.CALL_TO_FETCH_PHRASE_CONTENT);
export const setIsSimulationDone = createAction(actionTypes.IS_SIMULATION_DONE);
export const setSelectedRecoPhraseDD = createAction(actionTypes.SET_SELECTED_RECO_PHRASE_DD);
export const setIsShowTopPagesLoader = createAction(actionTypes.SET_IS_SHOW_TOP_PAGES_LOADER);
export const setInitialKeywordsDropdownHead = createAction(actionTypes.INITIAL_KEYWORDS_DROPDOWN_HEAD);
export const setSimulationKeywords = createAction(actionTypes.SET_SIMULATION_KEYWORDS);
export const setPhrasesPayloadKeywords = createAction(actionTypes.SET_PHRASES_PAYLOAD_KEYWORDS);
export const setIsFirstTimeRecoOpen = createAction(actionTypes.SET_IS_FIRST_TIME_RECO_OPEN);
export const setRefreshNowClicked = createAction(actionTypes.SET_REFRESH_NOW_CLICKED);
