import { ApiConfig } from "../../../../../../../../../app/config/apiConfig";
import { ApiPath, METHOD } from "../../../../../../../../../app/config/apiPath";
import { ParserInterface } from "../../../../../../../../../app/duck/types";
import { ContextualRecommendationAPIInterface } from "./types";
import { getAxiosParam } from "../../../../../../../../../app/duck/utils";
import ServiceRequest from "../../../../../../../../../app/services";

class ContextualRecommendationsApis {
    public *getContextualRecommendationInitiate(
        payload: ContextualRecommendationAPIInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            url: ApiPath.CONTEXTUAL_RECOMMENDATION_INITIATE.replace("{{tenantCode}}", parser.tenantCode),
            data: payload
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }
}
export default new ContextualRecommendationsApis();
