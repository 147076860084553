import { AnyAction } from "redux";
import { isEmpty } from "lodash";
import { call, takeEvery, put, delay, race, take, select, cancelled } from "redux-saga/effects";
import Apis from "../../app/apis";
import actionTypes, { callToGetSuggestedRelatedKW, setRequestIdAPIStatus, setKeywordSSeRetriedAttempt, setSuggestedRelatedKW, sseAPILoading } from "./actions";
import { InitiateRequestErrors, RELATED_KW_SSE_EVENT_TYPES } from "../../app/const";
import { ApiConfig } from "../../app/config/apiConfig";
import { ApiPath } from "../../app/config/apiPath";
import { listenSSEvents } from "./utils";
import { getKeywordSSeRetriedAttempts } from "./selector";

// tslint:disable-next-line: typedef
export function* getSuggestedRelatedKWReqIds(action: AnyAction) {
    const payload = {
        ...action.payload,
        needToHandleError: true,
        isSpecificError: true,
        errorList: InitiateRequestErrors
    };
    yield put(sseAPILoading(true));
    const response = yield call(Apis.getSuggestedRelatedKWRequestIds, payload);
    if (response.status === 200) {
        const params = {
            requestId: response.data.data.ref_id,
            token: response.data.data.token
        };
        yield put(setKeywordSSeRetriedAttempt(0));
        yield put(sseAPILoading(true));
        yield put(callToGetSuggestedRelatedKW(params));
        yield put(setRequestIdAPIStatus(false));
    } else {
        yield put(sseAPILoading(false));
        yield put(setRequestIdAPIStatus(true));
    }
    return response;
}

// tslint:disable-next-line: typedef
export function* getSuggestedRelatedKW(action: AnyAction) {
    const { requestId, token } = action.payload;
    const url = `${ApiConfig.rkBaseURL}${ApiPath.RELATED_KEYWORD_SSE_API}?ref_id=${requestId}&token=${token}`;
    yield put(sseAPILoading(true));
    const { channel, timeout } = yield race({
        channel: call(listenSSEvents, url),
        timeout: delay(300 * 1000)
    });

    const keywordRetriedAttempt = yield select(getKeywordSSeRetriedAttempts);

    if (timeout) {
        if (keywordRetriedAttempt < 3) {
            const parameter = {
                requestId,
                token
            };
            channel.close();
            yield put(setKeywordSSeRetriedAttempt(keywordRetriedAttempt + 1));
            yield put(callToGetSuggestedRelatedKW(parameter));
        }
        channel.close();
        yield put(setSuggestedRelatedKW());
        yield put(sseAPILoading(RELATED_KW_SSE_EVENT_TYPES.req_failed));
    }
    try {
        while (true) {
            try {
                const response = yield take(channel);
                if (response.type === RELATED_KW_SSE_EVENT_TYPES.receive_related_kwds) {
                    if (!isEmpty(response.data)) {
                        yield put(setSuggestedRelatedKW(JSON.parse(response.data)));
                        yield put(sseAPILoading(RELATED_KW_SSE_EVENT_TYPES.receive_related_kwds));
                    }
                }
                if (response.type === RELATED_KW_SSE_EVENT_TYPES.req_completed) {
                    channel.close();
                    yield put(sseAPILoading(RELATED_KW_SSE_EVENT_TYPES.req_completed));
                }

                if (response.type === RELATED_KW_SSE_EVENT_TYPES.req_failed || response.type === RELATED_KW_SSE_EVENT_TYPES.error) {
                    if (keywordRetriedAttempt < 3) {
                        const parameter = {
                            requestId,
                            token
                        };
                        channel.close();
                        yield put(setKeywordSSeRetriedAttempt(keywordRetriedAttempt + 1));
                        yield put(callToGetSuggestedRelatedKW(parameter));
                    }
                    channel.close();
                    yield put(sseAPILoading(RELATED_KW_SSE_EVENT_TYPES.req_failed));
                }
            } catch (error) {
                if (keywordRetriedAttempt < 3) {
                    const parameter = {
                        requestId,
                        token
                    };
                    channel.close();
                    yield put(setKeywordSSeRetriedAttempt(keywordRetriedAttempt + 1));
                    yield put(callToGetSuggestedRelatedKW(parameter));
                }
                channel.close();
                yield put(sseAPILoading(RELATED_KW_SSE_EVENT_TYPES.req_completed));
            }
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
        }
    }

}

// tslint:disable-next-line: typedef
export function* watchForgetSuggestedRelatedKWReqIds() {
    yield takeEvery(actionTypes.GET_SUGGESTED_RELATED_KW_REQUEST_IDS_API, getSuggestedRelatedKWReqIds);
}

// tslint:disable-next-line: typedef
export function* watchForgetSuggestedRelatedKW() {
    yield takeEvery(actionTypes.GET_SUGGESTED_RELATED_KW, getSuggestedRelatedKW);
}
