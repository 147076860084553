import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import actionTypes from "./actions";
import { RoutesReducerInterface } from "./types";

const INIT_STATE: RoutesReducerInterface = {
    activeRouteUrl: "",
    prevRouteUrl: ""
};

const Reducer = handleActions(
    {
        [actionTypes.SET_ACTIVE_ROUTE_URL]: (state, action: AnyAction) => ({
            ...state,
            activeRouteUrl: action.payload
        }),
        [actionTypes.SET_PREV_ROUTE_URL]: (state, action: AnyAction) => ({
            ...state,
            prevRouteUrl: action.payload
        })
    },
    INIT_STATE
);

export default Reducer;
