import { isUndefined, omit } from "lodash";
import {
    KeyWordUrlPayloadInterface,
    KeyWordUrlScoresPayloadInterface,
    GetAllTopUrlParamInterface,
    GetSerpDataDownloadRequestInterface,
    KeywordUrlDownloadDataRequestInterface,
    ParameterDetailInterface
} from "../beta/key-word-analysis/ducks/types";
import { UserDetailRequestPayloadInterface, PlanDetailsRequestPayloadInterface } from "../user-subscription/ducks/types";
import {
    PerformanceMetricsPayloadInterface,
    ModelScoreDetailsPayloadInterface,
    SimulationDownloadApiInterface,
} from "../beta/simulator/ducks/types";
import ServiceRequest from "./services";
import { ApiPath, METHOD } from "./config/apiPath";
import { InitiateRequestForRequestIdRequestInterface, NotifyErrorInterface, ParserInterface } from "./duck/types";
import { ApiConfig } from "./config/apiConfig";
import { SerpDataPayloadInterface, TrackRequestPayloadInterface } from "../beta/serp-result/ducks/types";
import { FileUploadInterfae } from "../beta/kw-url-search/ducks/types";
import {
    RelatedRequestIdsApiRequestInterface
} from "../beta/simulator/Components/AddModifyKeyword/ducks/types";
import { RelatedKeyWordParserInterface } from "src/kw-research/ducks/types";
import { getAxiosParam, isTrialUser, omitOptionalArgs } from "./duck/utils";
import { GetProjectDetailsPayload, GetSaveHistoryPayloadInterface, GetTenantLimitsPayload } from "../routes/ducks/types";
import { ContentBriefKeywordPayloadInterface } from "../content-brief-new/ducks/types";
import {
    DownloadFilePayloadInterface,
    GetContentBriefViewDataPayloadInterface,
    UpdateCBNameDescriptionPayloadInterface
} from "../content-brief-view/ducks/types";
import {
    RefreshProjectCachePayloadInterface,
    ReportFilterValuesInterface,
    ReportsTokenPayloadInterface
} from "../competitive-intelligence/ducks/types";
import { SuggestedRelatedKWApiRequestInterface } from "../suggested-keywords/ducks/types";
import { AutoLoginPayloadInterface } from "../auto-login/ducks/types";
import {
    CreateProjectApiPayloadInterface,
    CreateSubDomainsApiPayloadInterface,
    DeleteSubDomainsApiPayloadInterface,
    GetProjectDetailsApiPayloadInterface,
    UpdateProjectApiPayloadInterface,
    SaveProjectKeywordsInterface
} from "../project-setup/ducks/types";
import { RANK_TRACKING_COMBINED_REPORT, RANK_TRACKING_DASHBOARD_REPORT, SHARE_OF_VOICE, TOPICAL_AUTHORITY } from "../app/const";


class Apis {
    // return interface of data
    // tslint:disable-next-line:no-any
    public *getKeyWordUrl(args: KeyWordUrlPayloadInterface): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omitOptionalArgs(args)
            },
            method: METHOD.POST,
            url: args.isBeta ? ApiPath.KEYWORD_SERP_BETA : ApiPath.KEYWORD_SERP
        };
        const collectedUrls = yield ServiceRequest.invoke(axiosParam);

        return collectedUrls;
    }

    // tslint:disable-next-line:no-any
    public *getKeyWordUrlScore(args: KeyWordUrlScoresPayloadInterface): any {
        const axiosPram = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omit(args, ["isBeta"])
            },
            method: METHOD.POST,
            url: args.isBeta ? ApiPath.KEYWORD_URL_SCORE_BETA : ApiPath.KEYWORD_URL_SCORE
        };
        const collectedScores = yield ServiceRequest.invoke(axiosPram);

        return collectedScores;
    }
    // tslint:disable-next-line:no-any
    public *getAllTopCompatitors(args: GetAllTopUrlParamInterface): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omit(args, ["isBeta"])
            },
            method: METHOD.POST,
            url: args.isBeta ? ApiPath.TOP_ORGANIC_URL_BETA : ApiPath.TOP_ORGANIC_URL
        };
        const collectedUrls = yield ServiceRequest.invoke(axiosParam);
        return collectedUrls;
    }

    public *initRequest(
        args: InitiateRequestForRequestIdRequestInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omitOptionalArgs(args)
            },
            method: METHOD.POST,
            url: args.isBeta ? ApiPath.INIT_REQUEST_FOR_ID_BETA : ApiPath.INIT_REQUEST_FOR_ID
        };
        const requestId = yield ServiceRequest.invoke(getAxiosParam(axiosParam, omit(args, ["isBeta"])));
        return requestId;
    }
    // tslint:disable-next-line:typedef
    public *getSerpDataDownload(args: GetSerpDataDownloadRequestInterface) {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omit(args, ["isBeta"])
            },
            method: METHOD.POST,

            url: args.isBeta ? ApiPath.SERP_DATA_DOWNLOAD_BETA : ApiPath.SERP_DATA_DOWNLOAD
        };
        const receivedCsv = yield ServiceRequest.invoke(axiosParam);
        return receivedCsv;
    }
    // tslint:disable-next-line:typedef
    public *keywordUrlDownload(args: KeywordUrlDownloadDataRequestInterface) {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            params: {
                ...omit(args, ["isBeta"])
            },

            url: args.isBeta ? ApiPath.KEYWORD_URL_DOWNLOAD_BETA : ApiPath.KEYWORD_URL_DOWNLOAD
        };
        const receivedCsv = yield ServiceRequest.invoke(axiosParam);
        return receivedCsv;
    }
    public *getPlansDetails(
        args: PlanDetailsRequestPayloadInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            params: {
                ...args
            },

            url: ApiPath.USER_PLANS_DETAILS.replace("{{tenantCode}}", args.tenant_id)
        };
        const plans = yield ServiceRequest.invoke(axiosParam);
        return plans;
    }
    public *getUserDetails(
        args: UserDetailRequestPayloadInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            params: {
                ...args
            },
            url: ApiPath.USER_DETAILS
        };
        const plans = yield ServiceRequest.invoke(axiosParam);
        return plans;
    }

    public *fetchModelScoreDetail(
        payload: ModelScoreDetailsPayloadInterface | ModelScoreDetailsPayloadInterface,
        parser: ParserInterface
    ): // tslint:disable-next-line:no-any
        any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omitOptionalArgs(payload)
            },
            method: METHOD.POST,
            url: (payload.isBeta ? ApiPath.AGGREGATED_MODEL_SCORE_DETAILS_BETA : ApiPath.AGGREGATED_MODEL_SCORE_DETAILS).replace(
                "{{tenantCode}}",
                parser.tenantCode
            )
        };
        const scores = yield ServiceRequest.invoke(getAxiosParam(axiosParam, omit(payload, ["isBeta"])));
        return scores;
    }

    // tslint:disable-next-line:no-any
    public *getParameterDetail(args: ParameterDetailInterface): any {
        const axiosPram = {
            data: {
                ...args
            },
            method: METHOD.POST,
            url: ApiPath.KWO_DETAIL_FETCH
        };
        const collectedDetails = yield ServiceRequest.invoke(axiosPram);

        return collectedDetails;
    }

    public *fetchSerpData(
        payload: SerpDataPayloadInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omitOptionalArgs(payload)
            },
            method: METHOD.POST,
            url: payload.isBeta ? ApiPath.SERP_DATA_URL_BETA : ApiPath.SERP_DATA_URL
        };
        return yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
    }
    public *getTrackRequest(
        payload: TrackRequestPayloadInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            params: {
                req_id: payload.requestId
            },
            url: payload.isBeta ? ApiPath.TRACK_REQUEST_URL_BETA : ApiPath.TRACK_REQUEST_URL
        };
        return yield ServiceRequest.invoke(getAxiosParam(axiosParam, omit(payload, ["isBeta"])));
    }
    public *getProjectDetail(
        payload: GetProjectDetailsPayload
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            params: {
                project_id: payload.projectId,
                tenant_code: payload.tenantCode
            },
            url: ApiPath.PROJECT_DETAILS
        };
        return yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
    }
    public *getProjectsList(
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.PROJECTS_LIST.replace("{{tenantCode}}", parser.tenantCode)
        };
        return yield ServiceRequest.invoke(axiosParam);
    }
    public *getTenantLimits(
        payload: GetTenantLimitsPayload
        // tslint:disable-next-line:no-any
    ): any {
        const isTrial: boolean = isTrialUser();
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.TENANT_LIMITS.replace("{{tenantCode}}", payload.tenantCode).replace("{{userId}}", payload.userId.toString())
        };

        if (isTrial) {
            const response = yield ServiceRequest.invoke(axiosParam);
            response.data.data.feature_addons = ["dod", "prt"];
            return response;
        }
        return yield ServiceRequest.invoke(axiosParam);
    }

    public *getKeyWordUrlScoreList(
        args: KeyWordUrlScoresPayloadInterface
        // tslint:disable-next-line: no-any
    ): any {
        const axiosPram = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omit(args, ["isBeta"])
            },
            method: METHOD.POST,
            url: args.isBeta ? ApiPath.KEYWORD_URL_SCORE_BETA : ApiPath.KEYWORD_URL_SCORE
        };
        const collectedScores = yield ServiceRequest.invoke(axiosPram);

        return collectedScores;
    }


    // tslint:disable-next-line: typedef
    public *fetchTechnicalParameterScore(payload: PerformanceMetricsPayloadInterface) {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...payload
            },
            method: METHOD.POST,
            url: ApiPath.PRE_SIMULATED_TECHNICAL_PARAMETER_SCORE
        };
        return yield ServiceRequest.invoke(axiosParam);
    }
    // tslint:disable-next-line: typedef
    public *fetchAuthorityParameterScore(payload: PerformanceMetricsPayloadInterface) {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...payload
            },
            method: METHOD.POST,
            url: ApiPath.PRE_SIMULATED_AUTHORITY_PARAMETER_SCORE
        };
        return yield ServiceRequest.invoke(axiosParam);
    }

    // tslint:disable-next-line: typedef
    public *getReportsToken(
        payload: ReportsTokenPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        // For new project based sections API
        if (
            payload.reportCode === RANK_TRACKING_COMBINED_REPORT ||
            payload.reportCode === RANK_TRACKING_DASHBOARD_REPORT ||
            payload.reportCode === SHARE_OF_VOICE ||
            payload.reportCode === TOPICAL_AUTHORITY
        ) {
            const axiosParamProject = {
                baseURL: ApiConfig.baseURL,
                method: METHOD.GET,
                url: ApiPath.PROJECT_REPORTS_API_URL.replace("{{tenantCode}}", parser.tenantCode)
                    .replace("{{projectId}}", payload.projectId.toString())
                    .replace("{{reportCode}}", payload.reportCode)
            };
            return yield ServiceRequest.invoke(axiosParamProject);
        }

        // For old sections API
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            params: { locale: payload.locale },
            url: ApiPath.REPORTS_API_URL.replace("{{tenantCode}}", parser.tenantCode)
                .replace("{{product}}", payload.product)
                .replace("{{reportCode}}", payload.reportCode)
        };
        return yield ServiceRequest.invoke(axiosParam);
    }

    // tslint:disable-next-line: typedef
    public *getReportsTargetDomainFilter(
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.PRT_TARGET_DOMAIN.replace("{{tenantCode}}", parser.tenantCode)
        };
        return yield ServiceRequest.invoke(axiosParam);
    }

    // tslint:disable-next-line: typedef
    public *fileUploadOnPageElements(
        payload: FileUploadInterfae,
        parser: ParserInterface,
        optionalArgs?: NotifyErrorInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: payload.formData,
            method: METHOD.POST,
            url: (payload.isBeta ? ApiPath.FILE_UPLOAD_ON_PAGE_ELEMENTS_BETA : ApiPath.FILE_UPLOAD_ON_PAGE_ELEMENTS).replace(
                "{{tenantCode}}",
                parser.tenantCode
            )
        };
        const elements = yield ServiceRequest.invoke(getAxiosParam(axiosParam, optionalArgs));
        return elements;
    }

    public *getContentBriefList(
        payload: GetSaveHistoryPayloadInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            params: {
                page: payload.pageIndex,
                ...(!isUndefined(payload.searchTerm) && { search_term: payload.searchTerm })
            },
            url: ApiPath.GET_CONTENT_BRIEF_HISTORY.replace("{{tenantCode}}", payload.tenantCode)
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *addContentBriefKeywords(
        payload: ContentBriefKeywordPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: payload,
            method: METHOD.POST,
            url: ApiPath.GET_CONTENT_BRIEF_HISTORY.replace("{{tenantCode}}", parser.tenantCode)
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    public *updateCBNameAndDescription(
        payload: UpdateCBNameDescriptionPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: payload,
            method: METHOD.PUT,
            url: ApiPath.CONTENT_BRIEF_WITH_TYPE_API_URL.replace("{{tenantCode}}", parser.tenantCode)
                .replace("{{id}}", payload.id)
                .replace("{{type}}", payload.type)
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    // This method is used to get content brief summary,get tab keyword list
    public *getContentBriefViewData(
        payload: GetContentBriefViewDataPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.CONTENT_BRIEF_WITH_TYPE_API_URL.replace("{{tenantCode}}", parser.tenantCode)
                .replace("{{id}}", payload.id)
                .replace("{{type}}", payload.type)
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    public *getDodSubmitRequestId(
        payload: FormData,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            data: payload,
            url: ApiPath.DOD_SUBMIT_REQUEST.replace("{{tenantCode}}", parser.tenantCode)
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }


    public *createProject(
        payload: CreateProjectApiPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            data: payload,
            url: ApiPath.CREATE_PROJECT.replace("{{tenantCode}}", parser.tenantCode)
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *updateProjectDetails(
        payload: UpdateProjectApiPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.PUT,
            data: payload,
            url: ApiPath.UPDATE_PROJECT_DETAILS.replace("{{tenantCode}}", parser.tenantCode)
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    public *createSubDomains(
        payload: CreateSubDomainsApiPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const subDomPayload = {
            sub_domains: payload.sub_domains
        };
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            data: subDomPayload,
            url: ApiPath.CREATE_SUB_DOMAINS.replace("{{tenantCode}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *deleteSubDomainsCall(
        payload: DeleteSubDomainsApiPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.DELETE,
            data: { ids: payload.ids },
            url: ApiPath.DELETE_SUBDOMAINS.replace("{{tenantCode}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    public *getProjectDetails(
        payload: GetProjectDetailsApiPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.GET_PROJECT_DETAIL.replace("{{tenantCode}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *getSubDomainList(
        payload: GetProjectDetailsApiPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.GET_SUBDOMAIN_LIST.replace("{{tenantCode}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *getDefaultProjectCategory(
        payload: ReportFilterValuesInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.PRT_DEAFULT_PROJECT_CATEGORY.replace("{{tenantCode}}", parser.tenantCode).replace(
                "{{projectId}}",
                payload.projectId?.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *getDefaultProjectTADomain(
        payload: ReportFilterValuesInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.PRT_DEAFULT_PROJECT_TA_DOMAIN.replace("{{tenantCode}}", parser.tenantCode).replace(
                "{{projectId}}",
                payload.projectId?.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *getSOVTargetCompetitorDomains(
        payload: ReportFilterValuesInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.PRT_SOV_TARGET_COMPETITOR_DOMAINS.replace("{{tenantCode}}", parser.tenantCode).replace(
                "{{projectId}}",
                payload.projectId?.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *refreshProjectCache(
        payload: RefreshProjectCachePayloadInterface
        // tslint:disable-next-line:no-any
    ): any {
        if (payload.projectId) {
            const axiosParam = {
                baseURL: ApiConfig.baseURL,
                method: METHOD.GET,
                url: ApiPath.PRT_PROJECT_CACHE_REFRESH.replace("{{tenantCode}}", payload.tenantCode).replace(
                    "{{projectId}}",
                    payload.projectId?.toString()
                )
            };
            const response = yield ServiceRequest.invoke(axiosParam);
            return response;
        }
    }

    public *getSuggestedRelatedKWRequestIds(
        args: SuggestedRelatedKWApiRequestInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omitOptionalArgs(args)
            },
            method: METHOD.POST,
            url: ApiPath.SUGGESTED_RELATED_KEYWORD_INITIATE_REQUEST_URL
        };
        return yield ServiceRequest.invoke(getAxiosParam(axiosParam, args));
    }

    // This method is used to get content brief summary,get tab keyword list
    public *getContentBriefProgress(
        payload: DownloadFilePayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.GET_CONTENT_BRIEF_PROGRESS.replace("{{tenantCode}}", parser.tenantCode).replace("{{id}}", payload.id)
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    public *getKwResearchRelatedKeywordRequestIds(
        payload: RelatedRequestIdsApiRequestInterface,
        parser: RelatedKeyWordParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            data: {
                ...omitOptionalArgs(payload)
            },
            url: ApiPath.RELATED_KEYWORD_INITIATE_REQUEST.replace("{{type}}", parser.type)
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    // This method is used to get content brief summary,get tab keyword list
    public *updateContentBrief(
        payload: ContentBriefKeywordPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.PUT,
            data: payload,
            url: ApiPath.GET_CONTENT_BRIEF_PROGRESS.replace("{{tenantCode}}", parser.tenantCode).replace(
                "{{id}}",
                payload.id ? payload.id : ""
            )
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }
    public *getLoginDetails(
        payload: AutoLoginPayloadInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            url: ApiPath.AUTO_LOGIN_API_URL,
            data: {
                link_id: payload.id,
                tenant_code: payload.tenant
            }
        };
        if (isUndefined(payload.tenant)) {
            delete axiosParam.data.tenant_code;
        }
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }
    public *deleteProjectCall(
        payload: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.DELETE,
            data: payload,
            url: ApiPath.DELETE_PROJECT_URL.replace("{{tenant_code}}", payload.tenantCode).replace(
                "{{project_id}}",
                String(payload.projectId)
            )
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    public *saveProjectKeywords(
        payload: SaveProjectKeywordsInterface,
        parser: ParserInterface
        // tslint:disable-next-line: no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            url: ApiPath.ADD_KEYWORDS_API_URL.replace("{{tenantCode}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.project_id.toString()
            ),
            data: payload
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    public *mapKeywordAndCategory(
        payload: { addedKeywordsIDs: number[]; project_id: number; mappedData: number[] },
        parser: ParserInterface
        // tslint:disable-next-line: no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            url: ApiPath.CREATE_KEYWORD_AND_CATEGORY_MAP.replace("{{tenant_code}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.project_id.toString()
            ),
            data: { kw_ids: payload.addedKeywordsIDs, cat_ids: payload.mappedData }
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    public *addToOptimizationKeywords(
        payload: { projectID: number; ids: number[]; is_optimized: boolean },
        parser: ParserInterface
        // tslint:disable-next-line: no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.PUT,
            url: ApiPath.ADD_TO_OPTIMIZATION_KEYWORDS.replace("{{tenant_code}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            ),
            data: { ids: payload.ids, is_optimized: payload.is_optimized }
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    public *getKeywordDetails(
        payload: { projectID: string },
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.GET_KEYWORD_DETAILS.replace("{{tenantCode}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *getCategoryList(
        payload: { projectID: string },
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            // baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            // url: ApiPath.GET_CATEGORY_LIST
            url: ApiPath.GET_CATEGORY_LIST.replace("{{tenant_code}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *addNewCategory(
        // payload: RelatedRequestIdsApiRequestInterface,
        payload: { projectID: string; categories: string[] },
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            data: payload,
            url: ApiPath.ADD_NEW_CATEGORY.replace("{{tenant_code}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *deleteCategory(
        payload: { id: number[]; projectID: number },
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const payloadObj = {
            ids: payload.id
        };
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.DELETE,
            data: payloadObj,
            // url: ApiPath.ADD_NEW_CATEGORY
            url: ApiPath.DELETE_CATEGORY.replace("{{tenant_code}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *editCategoryName(
        payload: { id: number; projectID: number; category: string },
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.PUT,
            data: { category: payload.category },
            url: ApiPath.EDIT_CATEGORY_NAME.replace("{{tenant_code}}", parser.tenantCode)
                .replace("{{project_id}}", payload.projectID.toString())
                .replace("{{category_id}}", payload.id.toString())
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *postAssignNewCategories(
        payload: { projectID: number; kw_ids: string[]; cat_ids: string[] },
        parser: ParserInterface
        // tslint:disable-next-line: no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            url: ApiPath.ASSIGN_NEW_CATEGORY.replace("{{tenant_code}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            ),
            data: { kw_ids: payload.kw_ids, cat_ids: payload.cat_ids }
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *updateKeywordDetails(
        payload: { projectID: number; ids: number[]; target_url?: string; is_optimized?: boolean },
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.PUT,
            data: { ...omit(payload, ["projectID"]) },
            url: ApiPath.UPDATE_KEYWORD_DETAILS.replace("{{tenant_code}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *deleteKeywordsFromProject(
        payload: { id: number[]; projectID: number },
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.DELETE,
            data: { ids: payload.id },
            url: ApiPath.DELETE_KEYWORD.replace("{{tenant_code}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    public *getCompetitorsList(
        payload: { projectID: string },
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.GET_COMPETITORS_LIST.replace("{{tenant_code}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *submitCompetitorsDomains(
        payload: { competitor_urls: string[]; projectID: string },
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            data: { competitor_urls: payload.competitor_urls },
            url: ApiPath.POST_COMPETITOR_DOMAINS.replace("{{tenant_code}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *deleteCompetitorsDomains(
        payload: { ids: number[]; projectID: number },
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const payloadObj = {
            ids: payload.ids
        };
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.DELETE,
            data: payloadObj,
            url: ApiPath.DELETE_COMPETITOR_DOMAINS.replace("{{tenant_code}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *getSiteURLSList(
        payload: { projectID: string },
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            method: METHOD.GET,
            url: ApiPath.GET_SITE_URLS_LIST.replace("{{tenant_code}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *unlinkGSCAccount(
        // payload: RelatedRequestIdsApiRequestInterface,
        payload: { projectID: string },
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.DELETE,
            data: payload,
            url: ApiPath.UNLINK_GSC_AUTH.replace("{{tenant_code}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *submitSiteURL(
        payload: { projectID: string; siteurl: string },
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const payloadObj = {
            siteurl: payload.siteurl
        };
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            data: payloadObj,
            url: ApiPath.SUBMIT_SITE_URL.replace("{{tenant_code}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *getSuggestedCompetitorDomains(
        payload: { locale: string; domain_url: string },
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            url: ApiPath.GET_SUGGESTED_COMPETITOR_DOMAINS.replace("{{tenant_code}}", parser.tenantCode),
            data: payload
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *uploadFileKeyword(
        payload: { project_id: string; file: File },
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const bodyFormData = new FormData();
        bodyFormData.append("file", payload.file);
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.PUT,
            url: ApiPath.KEYWORD_FILE_UPLOAD.replace("{{tenant_code}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.project_id.toString()
            ),
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
            responseType: "blob"
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *fetchPhraseContent(
        payload: { keywords: string[]; searchUrl: string; locale: string }
        // tslint:disable-next-line:no-any
    ): any {
        const isFrequency = true;
        const { keywords, searchUrl, locale } = payload;
        const bodyFormData = {
            keywords,
            locale,
            search_url: searchUrl
        };
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            url: `${ApiPath.FETCH_PHRASE_CONTENT}?include_frequency=${isFrequency}`,
            data: bodyFormData
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *postUserEmailVerification(
        payload: SimulationDownloadApiInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            url: ApiPath.USER_EMAIL_VERIFICATION,
            data: payload
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    public *postUserEmailReVerification(
        payload: SimulationDownloadApiInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            url: ApiPath.USER_EMAIL_REVERIFICATION,
            data: payload
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, payload));
        return response;
    }

    // Custom Reporting
    // tslint:disable-next-line: typedef
    public *getTableauEmbedToken(
        payload: {
            userId: string;
            tenantCode: string;
        }
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParamProject = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.TABLEAU_EMBED_TOKEN_API_URL.replace("{{tenantCode}}", payload.tenantCode).replace("{{userId}}", payload.userId)
        };
        return yield ServiceRequest.invoke(axiosParamProject);
    }
}
export default new Apis();
