import { createAction } from "redux-actions";

const actionTypes = {
    CALL_TO_GET_TENANT_LIST: "CALL_TO_GET_TENANT_LIST",
    SAVE_TENANT_LIST: "SAVE_TENANT_LIST",
    CALL_TO_GET_LOGIN_DETAILS: "CALL_TO_GET_LOGIN_DETAILS",
    SAVE_LOGIN_DETAILS: "SAVE_LOGIN_DETAILS",
    IS_API_LOADING: "IS_API_LOADING",
    IS_SESSION_EXPIRED: "IS_SESSION_EXPIRED",
};
export default actionTypes;

export const callToGetTenantList = createAction(actionTypes.CALL_TO_GET_TENANT_LIST);
export const saveTenantList = createAction(actionTypes.SAVE_TENANT_LIST);
export const callToGetLoginDetails = createAction(actionTypes.CALL_TO_GET_LOGIN_DETAILS);
export const saveLoginDetails = createAction(actionTypes.SAVE_LOGIN_DETAILS);
export const isAPILoading = createAction(actionTypes.IS_API_LOADING);
export const isSessionExpired = createAction(actionTypes.IS_SESSION_EXPIRED);
