import React from "react";
import { InsightModalCloseIcon } from "../../../../app/icons";
import {
    StyledBodyWrapper,
    StyledCloseUpgradeNowModal,
    StyledHeaderAmtText,
    StyledHeaderBody,
    StyledHeaderText,
    StyledHeaderWrapper,
    StyledLeftPanel,
    StyledRightPanel,
    StyledSeperator,
    StyledUpgradeModalHeader
} from "../StyledSimulator";
import { callToFetchSubscriptionPlan } from "../apis";
import { getMetaData } from "../../../../app/duck/utils";
import { isUndefined } from "lodash";
import UpgradeLeftPanel from "./upgrade-left-panel";
import UpgradeRightPanel from "./upgrade-right-panel";
import { UPDATENOW } from "../const";

export interface UpgradeNowPropInterface {
    instanceId: string;
    expireUser: boolean;
    tenant_code?: string;
    current_plan?: string;
    user_id?: string;
    endDate?: string;
    upgradeModalOpen(args: boolean): void;
    successModalOpen(args: boolean): void;
}

const UpgradeNow: React.FC<UpgradeNowPropInterface> = ({
    instanceId,
    tenant_code,
    current_plan,
    endDate,
    user_id,
    expireUser,
    upgradeModalOpen,
    successModalOpen
}) => {
    const onClickPayButton = async () => {
        successModalOpen(true);
        upgradeModalOpen(false);
        const tenantCode = !isUndefined(tenant_code) ? tenant_code : getMetaData().tenant_code;
        await callToFetchSubscriptionPlan(tenantCode, "STA", current_plan);
    };
    let month = null;
    let day = null;
    let year = null;
    if (!isUndefined(endDate)) {
        const formattedDate = new Date(endDate).toLocaleDateString("en-US", { month: "short", day: "2-digit", year: "numeric" });
        [month, day, year] = formattedDate.replace(/,/g, "").split(" ");
    }

    return (
        <>
            <StyledUpgradeModalHeader id="header">
                <StyledHeaderWrapper>
                    <StyledHeaderText id="heading-text">
                        {!expireUser
                            ? `${UPDATENOW.FREE_TRIAL_END_ON} ${month}-${day}, ${year}. ${UPDATENOW.UPGRADE_TO_INDIVIDUAL_PLAN}`
                            : `${UPDATENOW.EXPIRE_USER_MSG}`}
                    </StyledHeaderText>
                    <StyledHeaderAmtText>
                        <StyledHeaderBody id="just-99">{UPDATENOW.JUST_$99}</StyledHeaderBody>
                    </StyledHeaderAmtText>
                </StyledHeaderWrapper>
                {!expireUser && (
                    <StyledCloseUpgradeNowModal
                        id="upgrade-now-close-modal-button"
                        onClick={() => upgradeModalOpen(false)}
                        src={InsightModalCloseIcon}
                    />
                )}
            </StyledUpgradeModalHeader>
            <StyledSeperator />
            <StyledBodyWrapper id="body">
                <StyledLeftPanel>
                    <UpgradeLeftPanel expireUser={expireUser} onClickPayButton={onClickPayButton} upgradeModalOpen={upgradeModalOpen} />
                </StyledLeftPanel>
                <StyledRightPanel>
                    <UpgradeRightPanel />
                </StyledRightPanel>
            </StyledBodyWrapper>
        </>
    );
};

export default UpgradeNow;
