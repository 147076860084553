import * as React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { NavListInterface } from "../ducks/types";
import { ContextReducerInterface } from "../../app/duck/types";
import { getLinkWithParams } from "../ducks/utils";

export const StyledBreadCrumbContainer = styled.div`
    height: 20px;
    background: #ebecf0;
    padding: 0 48px;
    /* padding-top: 6px; */
    box-sizing: border-box;
    top: 50px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 99;
    display: flex;
    align-items: center;
`;
export const StyledBreadCrumbUl = styled.ul``;

const StyledBreadCrumbLinkStyle = `
    font-size: 10px;
    float: left;
    color: #838793;
    &:not(:last-child):after {
        content: '/';
        padding:  0 2px;
    }
`;

export const StyledBreadCrumbNavLink = styled(NavLink)`
    ${StyledBreadCrumbLinkStyle};
    //&:hover {
    //    color: #00acce;
    //}
    cursor: pointer;
`;
export const StyledBreadCrumbLink = styled.a`
    ${StyledBreadCrumbLinkStyle};
    //&:hover {
    //    color: #00acce;
    //}
    cursor: pointer;
`;
export const StyledBreadCrumbLi = styled.li`
    ${StyledBreadCrumbLinkStyle};
    &.last {
        color: #343434;
    }
`;

interface BreadcrumbLinkPropsInterface {
    links: NavListInterface[];
    link: NavListInterface;
    index: number;
    context: ContextReducerInterface;
    handleRedirection?(to: string, isExternal?: boolean | undefined): void;
}

const BreadcrumbLink = ({ links, link, index, context, handleRedirection }: BreadcrumbLinkPropsInterface): JSX.Element => {
    const { type, id, to, title } = link;
    const currentURL = window.location.href;
    const currentPathName = new URL(currentURL);
    if (index === links.length - 1 || !to.length) {
        return (
            <StyledBreadCrumbLi key={id} id={id} className={`${index === links.length - 1 && "last"}`}>
                {title}
            </StyledBreadCrumbLi>
        );
    }

    if (type === type) {
        if (currentPathName.pathname === "/simulation" && handleRedirection) {
            return (
                <StyledBreadCrumbNavLink
                    onClick={(e) => { e.preventDefault(); handleRedirection(to, false); }}
                    key={id}
                    to={getLinkWithParams({ to, context, isExternal: false })}
                >
                    {title}
                </StyledBreadCrumbNavLink>
            );
        }
        return (
            <StyledBreadCrumbNavLink key={id} to={getLinkWithParams({ to, context, isExternal: false })}>
                {title}
            </StyledBreadCrumbNavLink>
        );
    }

    if (currentPathName.pathname === "/simulation" && handleRedirection) {
        return (
            <StyledBreadCrumbLink
                onClick={(e) => { e.preventDefault(); handleRedirection(to, false); }}
                key={id}
                href={getLinkWithParams({ to, context, isExternal: true })}
            >
                {title}
            </StyledBreadCrumbLink>
        );
    }
    return (
        <StyledBreadCrumbLink key={id} href={getLinkWithParams({ to, context, isExternal: true })}>
            {title}
        </StyledBreadCrumbLink>
    );
};

interface BreadcrumbPropsInterface {
    links: NavListInterface[];
    context: ContextReducerInterface;
    handleRedirection?(to: string, isExternal?: boolean | undefined): void;
}

const Breadcrumb: React.FunctionComponent<BreadcrumbPropsInterface> = ({ links, context, handleRedirection }: BreadcrumbPropsInterface): JSX.Element => (
    <StyledBreadCrumbContainer id="breadcrumb">
        <StyledBreadCrumbUl>
            {links.map((link: NavListInterface, index: number) => (
                <BreadcrumbLink handleRedirection={handleRedirection} key={index + link.id} links={links} link={link} index={index} context={context} />
            ))}
        </StyledBreadCrumbUl>
    </StyledBreadCrumbContainer>
);

export default Breadcrumb;
