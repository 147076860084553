import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import ActionTypes from "./actions";
import { EmailVerificationReducerInterface } from "./types";

const INIT_STATE: EmailVerificationReducerInterface = {
    userEmailVrificaitonStatus: undefined,
    userEmailID: undefined
};
const Reducer = handleActions(
    {
        [ActionTypes.SET_USER_EMAIL_VERIFIED]: (state, action: AnyAction) => ({
            ...state,
            userEmailVrificaitonStatus: action.payload
        }),

        [ActionTypes.SET_USER_EMAIL_ID]: (state, action: AnyAction) => ({
            ...state,
            userEmailID: action.payload
        })
    },
    INIT_STATE
);

export default Reducer;
