import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */
const AuthActionTypes = {
    SET_AUTHORITY_CHANGED_PARAMETER: "SET_AUTHORITY_CHANGED_PARAMETER_BETA",
    SET_TECHNICAL_CHANGED_PARAMETER: "SET_TECHNICAL_CHANGED_PARAMETER_BETA",
    SET_SELECTED_AUTH_SUBCATEGORY: "SET_SELECTED_AUTH_SUBCATEGORY_BETA",
    SET_SELECTED_TECH_SUBCATEGORY: "SET_SELECTED_TECH_SUBCATEGORY_BETA",
    SET_AUTHORITY_PARAMETER: "SET_AUTHORITY_PARAMETER_BETA",
    SET_TECHNICAL_PARAMETER: "SET_TECHNICAL_PARAMETER_BETA",
    SET_PARAMETER_VALIDATION_MESSAGES: "SET_PARAMETER_VALIDATION_MESSAGES_BETA",
    SET_IS_DESCRIPTION_UPDATE_FAILED: "SET_IS_DESCRIPTION_UPDATE_FAILED",
    SET_UPDATED_VERSION_DESCRIPTION: "SET_UPDATED_VERSION_DESCRIPTION"
};
export default AuthActionTypes;

export const setAuthorityChangedParameter = createAction(AuthActionTypes.SET_AUTHORITY_CHANGED_PARAMETER);
export const setTechnicalChangedParameter = createAction(AuthActionTypes.SET_TECHNICAL_CHANGED_PARAMETER);
export const setSelectedAuthSubCategory = createAction(AuthActionTypes.SET_SELECTED_AUTH_SUBCATEGORY);
export const setSelectedTechSubCategory = createAction(AuthActionTypes.SET_SELECTED_TECH_SUBCATEGORY);
export const setAuthorityParameter = createAction(AuthActionTypes.SET_AUTHORITY_PARAMETER);
export const setTechnicalParameter = createAction(AuthActionTypes.SET_TECHNICAL_PARAMETER);
export const setParameterValidation = createAction(AuthActionTypes.SET_PARAMETER_VALIDATION_MESSAGES);
export const setIsDescriptionUpdateFailed = createAction(AuthActionTypes.SET_IS_DESCRIPTION_UPDATE_FAILED);
export const setUpdatedVersionDescription = createAction(AuthActionTypes.SET_UPDATED_VERSION_DESCRIPTION);
