import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import actionTypes from "./actions";
import { PageContentBriefReducerInterface } from "./types";

export const INIT_STATE: PageContentBriefReducerInterface = {
    isLoading: false,
    currentPageNumber: 1,
    contentBriefList: {
        count: 0,
        prev_page: null,
        curr_page: 1,
        next_page: null,
        total_pages: 0,
        cb_list: []
    }
};

const Reducer = handleActions(
    {
        [actionTypes.SAVE_CONTENT_BRIEF_LIST]: (state, action: AnyAction) => ({
            ...state,
            contentBriefList: action.payload
        }),
        [actionTypes.SET_PAGINATION_SHOW_LOADER]: (state, action: AnyAction) => ({
            ...state,
            isLoading: true
        }),
        [actionTypes.SET_PAGINATION_HIDE_LOADER]: (state, action: AnyAction) => ({
            ...state,
            isLoading: false
        }),
        [actionTypes.SET_CURRENT_PAGE_NUMBER]: (state, action: AnyAction) => ({
            ...state,
            currentPageNumber: action.payload
        })
    },
    INIT_STATE
);

export default Reducer;
