import * as React from "react";
import styled, { keyframes } from "styled-components";
import { IS_DEV_SRC } from "../const";
// tslint:disable:no-var-requires
const LoaderImg = IS_DEV_SRC
  ? `${process.env.IMAGE_URL}/img/loading-circles.svg`
  : require(`${process.env.DEV_IMAGE_URL}/img/loading-circles.svg`);

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const StyledLoadingIcon = styled.span`
    display: inline-block;
    width: 15px;
    height: 15px;
    background: url(${LoaderImg}) no-repeat;
    background-size: contain;
    background-position: center center;
    animation: ${rotate} 0.75s linear infinite;
`;

interface LoadingIconPropsInterface {
  id?: string;
}

const LoadingIcon: React.SFC<LoadingIconPropsInterface> = ({ id }) => <StyledLoadingIcon id={id} />;

export default LoadingIcon;
