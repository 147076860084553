import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */
const actionTypes = {
    CALL_KEYWORD_SEARCH: "CALL_KEYWORD_SEARCH_BETA",
    CLEAR_USER_SELECTION: "CLEAR_USER_SELECTION_BETA",
    GET_PROJECT_DETAILS: "GET_PROJECT_DETAILS_BETA",
    GET_TENANT_LIMITS: "GET_TENANT_LIMITS_BETA",
    CALL_FILE_UPLOAD: "CALL_FILE_UPLOAD_BETA",
    CALL_DELETE_PROJECT: "CALL_DELETE_PROJECT_BETA",
};
export default actionTypes;

export const getProjectDetails = createAction(actionTypes.GET_PROJECT_DETAILS);
export const getTenantLimits = createAction(actionTypes.GET_TENANT_LIMITS);
export const callKeywordSearch = createAction(actionTypes.CALL_KEYWORD_SEARCH);
export const clearUserSelection = createAction(actionTypes.CLEAR_USER_SELECTION);

export const callFileupload = createAction(actionTypes.CALL_FILE_UPLOAD);
export const callDeleteProject = createAction(actionTypes.CALL_DELETE_PROJECT);

