import * as React from "react";
import { map } from "lodash";
import styled from "styled-components";
import { StyledComponentStylesType } from "../duck/types";

export const StyledLegend = styled.span`
    width: 11px;
    height: 11px;
    background: ${(props: { hexCode: string }) => props.hexCode}
    display: inline-block;
    border-radius: 50%;
    vertical-align: middle;
`;
export const StyledLegendLabel = styled.span`
    font-size: 12px;
    color: #343434;
    display: inline-block;
    margin: 0px 0px 0px 10px;
`;
export const StyledLegendWrapper = styled.span``;
export interface StyledLegendInterface {
    title?: string;
    hexCode: string;
}
export interface LegendPropsInterface {
    id?: string;
    legends: StyledLegendInterface[];
    WrapperLegend?: StyledComponentStylesType;
}

const Legends: React.SFC<LegendPropsInterface> = ({ id, WrapperLegend = StyledLegendWrapper, legends }) => (
    <React.Fragment>
        {map(legends, (legend: StyledLegendInterface, key: number) => (
            <WrapperLegend key={key} id={id}>
                <StyledLegend hexCode={legend.hexCode} />
                {legend.title && <StyledLegendLabel>{legend.title}</StyledLegendLabel>}
            </WrapperLegend>
        ))}
    </React.Fragment>
);

export default Legends;
