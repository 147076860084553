import * as React from "react";
import { map, kebabCase } from "lodash";
import Modal, { StyledBody } from "../styledComponents/modal";
import styled from "styled-components";
import { COPY, REPORTS_GRID_DATA } from "../const";
import { googleTagManagerDataLayer } from "../duck/utils";

/* REPORTS GRID STYLED CONSTATNS */

export const StyledReportsWrapper = styled.div`
    padding: 50px;
    padding-bottom: 0px;
`;

export const StyledReportsTitle = styled.h2`
    text-align: center;
    font-size: 24px;
    color: #343434;
    font-family: "Lato", sans-serif;
    font-weight: 600;
`;

export const StyledReportsGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const StyledReportsGridItem = styled.div`
    width: 33.3%;
    text-align: center;
    padding: 50px;
    cursor: pointer;
    box-sizing: border-box;
`;

export const StyledReportsGridThumbnail = styled.div``;

export const StyledReportsGridThumbnailImg = styled.img``;

export const StyledReportsGridTitle = styled.h3`
    font-size: 14px;
    color: #343434;
    padding: 20px 0px 20px 0px;
    font-family: "Lato", sans-serif;
    font-weight: 500;
`;

export const StyledReportsGridDesc = styled.p`
    font-size: 12px;
    color: #838693;
    font-family: "Lato", sans-serif;
    line-height: 20px;
    white-space: initial;
`;

export const StyledReportsModalBody = styled(StyledBody)`
    height: 376px;
`;

interface ReportsGridStateInterface {
    showModal: boolean;
    modalTitle: string;
    modalImg: string;
}

interface ModalBodyPropsInterface {
    imgUrl: string;
}

const ModalBody: React.SFC<ModalBodyPropsInterface> = ({ imgUrl }) => <img src={imgUrl} style={{ width: "100%" }} />;

class ReportsGrid extends React.Component<{}, ReportsGridStateInterface> {
    public constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            modalImg: "",
            modalTitle: "",
            showModal: false
        };
        this.toggleModal = this.toggleModal.bind(this);
    }
    public toggleModal(title = "", img = ""): void {
        if (this.state.showModal) {
            this.setState({ showModal: false, modalTitle: "", modalImg: "" });
        } else {
            googleTagManagerDataLayer("Report", [title]);
            this.setState({ showModal: true, modalTitle: title, modalImg: img });
        }
    }
    public render(): JSX.Element {
        return (
            <StyledReportsWrapper id="sample-reports-container">
                <StyledReportsTitle id="reports-title">{COPY.HOME_REPORTS_GRID_TITLE}</StyledReportsTitle>
                <StyledReportsGrid id="reports-grid">
                    {map(REPORTS_GRID_DATA, (data, key: number) => (
                        <StyledReportsGridItem
                            id={`reports-grid-${kebabCase(data.title)}-item`}
                            key={key}
                            onClick={() => this.toggleModal(data.title, data.modalImg)}
                        >
                            <StyledReportsGridThumbnail id="reports-grid-thumbnail">
                                <StyledReportsGridThumbnailImg src={data.img} />
                            </StyledReportsGridThumbnail>
                            <StyledReportsGridTitle id="reports-grid-title">{data.title}</StyledReportsGridTitle>
                            <StyledReportsGridDesc id="reports-grid-description">{data.description}</StyledReportsGridDesc>
                        </StyledReportsGridItem>
                    ))}
                </StyledReportsGrid>
                {this.state.showModal && (
                    <Modal
                        id="reports-grid-modal"
                        styles={{
                            Body: StyledReportsModalBody
                        }}
                        title={this.state.modalTitle}
                        onCloseHandler={this.toggleModal}
                        body={<ModalBody imgUrl={this.state.modalImg} />}
                    />
                )}
            </StyledReportsWrapper>
        );
    }
}

export default ReportsGrid;
