import { ApiConfig } from "../../app/config/apiConfig";
import { ApiPath, METHOD } from "../../app/config/apiPath";
import {
    ChangeTenantPayloadInterface,
    checkSSOLoginPayloadInterface,
    userLoginPayloadInterface,
    userLoginSSOPayloadInterface,
    userLoginWithTenantPayloadInterface,
    userChangePasswordInterface,
    userForgotPwdInterface,
    userResetPasswordInterface
} from "./types";
import ServiceRequest from "../../app/services";
import { ParserInterface } from "../../app/duck/types";

class UserAccountApis {
    public *changeTenant(
        payload: ChangeTenantPayloadInterface,
        parser: ParserInterface
    ): // tslint:disable-next-line:no-any
        any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            data: payload,
            url: ApiPath.CHANGE_TENANT_API_URL.replace("{{selectedTenant}}", parser.tenantCode)
        };
        return yield ServiceRequest.invoke(axiosParam);
    }

    public *logoutUser(
        parser: ParserInterface
    ): // tslint:disable-next-line:no-any
        any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.LOGOUT_API_URL.replace("{{tenant_code}}", parser.tenantCode)
        };
        return yield ServiceRequest.invoke(axiosParam);
    }

    public *checkSSOLogin(
        payload: checkSSOLoginPayloadInterface
    ): // tslint:disable-next-line:no-any
        any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            data: payload,
            url: ApiPath.CHECK_SSO_LOGIN
        };
        return yield ServiceRequest.invoke(axiosParam);
    }

    public *userLogin(
        payload: userLoginPayloadInterface
    ): // tslint:disable-next-line:no-any
        any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            data: payload,
            url: ApiPath.USER_LOGIN
        };
        return yield ServiceRequest.invoke(axiosParam);
    }

    public *userLoginWithTenant(
        payload: userLoginWithTenantPayloadInterface
    ): // tslint:disable-next-line:no-any
        any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            data: payload,
            url: ApiPath.USER_LOGIN_WITH_TENANT
        };
        return yield ServiceRequest.invoke(axiosParam);
    }

    public *userForgotPwd(
        payload: userForgotPwdInterface
    ): // tslint:disable-next-line:no-any
        any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            data: payload,
            url: ApiPath.FORGOT_PASSWORD
        };
        return yield ServiceRequest.invoke(axiosParam);
    }

    public *userSSOLogin(
        payload: userLoginSSOPayloadInterface
    ): // tslint:disable-next-line:no-any
        any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            data: payload,
            url: ApiPath.USER_LOGIN_SSO
        };
        return yield ServiceRequest.invoke(axiosParam);
    }

    public *userChangePwd(
        payload: userChangePasswordInterface,
        parser: ParserInterface
    ): // tslint:disable-next-line:no-any
        any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            data: payload,
            url: ApiPath.CHANGE_USER_PASSWORD.replace("{{tenant_code}}", parser.tenantCode)
        };
        return yield ServiceRequest.invoke(axiosParam);
    }

    public *userResetPwd(
        payload: userResetPasswordInterface
    ): // tslint:disable-next-line:no-any
        any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            data: payload,
            url: ApiPath.RESET_PASSWORD
        };
        return yield ServiceRequest.invoke(axiosParam);
    }
}
export default new UserAccountApis();
