import { eventChannel } from "@redux-saga/core";

/**
 * Opens an EventSource to the given URL and returns an event channel
 * that emits server-sent events from the EventSource.
 * @param {string} url - The URL to connect the EventSource to
 * @returns {EventChannel} An event channel that emits server-sent events
 */
// tslint:disable-next-line: typedef
export function listenRecommendationsSSEvents(url: string) {
    const eventChan = eventChannel((emit) => {
        const sseSource = new EventSource(url, {
            withCredentials: true
        });
        sseSource.addEventListener("receive_recommendations", (ev: Event) => emit(ev));
        sseSource.addEventListener("receive_recommendation_score", (ev: Event) => emit(ev));
        sseSource.addEventListener("req_completed", (ev: Event) => emit(ev));
        sseSource.addEventListener("req_failed", (ev: Event) => emit(ev));
        sseSource.onerror = (ev: Event) => {
            emit(ev);
        };
        return () => sseSource.close();
    });
    return eventChan;
}
