import * as React from "react";
import styled from "styled-components";
import { MessageInterface, MessageType, StyledComponentStylesType } from "../../app/duck/types";
import { closeGrey, success, warning, error, info } from "../../app/icons";
import { Clearall } from "../../app/styledComponents";

export interface ToastStylePropsInterface {
    ToastContainer?: StyledComponentStylesType;
    ToastMessage?: StyledComponentStylesType;
    ToastIcon?: StyledComponentStylesType;
    CloseIcon?: StyledComponentStylesType;
    LinkTextWrapper?: StyledComponentStylesType;
}
export interface MessagePropsInterface {
    messages: MessageInterface[];
}
export interface MessageStyledTypeProps {
    messageType: MessageType;
}
export type MessageTypeColorInterface = {
    [key in MessageType]: string;
};

export interface ToastPropsInterface extends MessageInterface {
    id: number;
    dissolveTime: number;
    showCloseIcon?: boolean;
    styles?: ToastStylePropsInterface;
    onRemove(): void;
}

export const StyledCommonToastContainer = styled.div`
    width: 350px;
    min-height: 36px;
    position: relative;
    padding: 10px 0;
    background: transparent;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid blue;
    margin-bottom: 10px;
`;
const MessageBackGroundColorCode: MessageTypeColorInterface = {
    [MessageType.SUCCESS]: `#def9ea`,
    [MessageType.WARNING]: `#fff2d9`,
    [MessageType.INFO]: `#e0eaf8`,
    [MessageType.ERROR]: `#fee4db`
};

const MessageBorderColorCode: MessageTypeColorInterface = {
    [MessageType.SUCCESS]: `#53d68a`,
    [MessageType.WARNING]: `#ffa501`,
    [MessageType.INFO]: `#3a85ef`,
    [MessageType.ERROR]: `#fb764b`
};

export const StyledToastContainer = styled(StyledCommonToastContainer)`
    background: ${(props: MessageStyledTypeProps) => MessageBackGroundColorCode[props.messageType]};
    border: 1px solid ${(props: MessageStyledTypeProps) => MessageBorderColorCode[props.messageType]};
`;

const StyledToastLeftBorder = styled.span`
    display: block;
    float: left;
    margin: 0px 6px 0px 3px;
    width: 2px;
    background: 2px;
    background: blue;
    position: absolute;
    top: 7px;
    bottom: 7px;
`;

export const StyledLeftBorder = styled(StyledToastLeftBorder)`
    background: ${(props: MessageStyledTypeProps) => MessageBorderColorCode[props.messageType]};
`;

export const StyledToastIcon = styled.i`
    width: 16px;
    height: 16px;
    float: left;
    background: #fff;
    border-radius: 50%;
    margin-left: 10px;
`;

export const StyledTypeIcon = styled(StyledToastIcon)`
    ${(props: MessageStyledTypeProps) =>
        props.messageType === MessageType.SUCCESS
            ? `background: url(${success}) no-repeat;`
            : props.messageType === MessageType.ERROR
            ? `background: url(${error}) no-repeat;`
            : props.messageType === MessageType.INFO
            ? `background: url(${info}) no-repeat;`
            : `background: url(${warning}) no-repeat;`}
`;

export const StyledToastMessageText = styled.span`
    width: 280px;
    font-size: 12px;
    color: #343434;
    float: left;
    line-height: 16px;
    margin-left: 10px;
`;

export const StyledToastClose = styled.i`
    cursor: pointer;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    top: 10px;
    background: url(${closeGrey}) no-repeat;
    background-position: top right;
`;

export const StyledToastLinkWrapper = styled.span`
    font-size: 12px;
    margin-top: 5px;
    margin-left: 10px;
    color: #00acce;
    text-decoration: underline;
    cursor: pointer;
`;

const Toast: React.SFC<ToastPropsInterface> = ({
    id,
    type,
    title,
    message,
    autoClose,
    dissolveTime,
    showCloseIcon = true,
    styles = {},
    linkText,
    onClickLinkText,
    onRemove
}) => {
    // const removeToast = React.useRef();
    // removeToast.current = onRemove;
    const {
        ToastContainer = StyledToastContainer,
        ToastMessage = StyledToastMessageText,
        CloseIcon = StyledToastClose,
        ToastIcon = StyledTypeIcon,
        LinkTextWrapper = StyledToastLinkWrapper
    } = styles;
    React.useEffect(() => {
        if (autoClose) {
            setTimeout(onRemove, dissolveTime);
        }
        return;
    }, [id]);
    return (
        <ToastContainer messageType={type} id={`${type}_toast`}>
            <StyledLeftBorder messageType={type} id={`${type}_toast_highlight`} />
            <ToastIcon messageType={type} id={`${type}_toast_icon`} />
            <ToastMessage id={`${type}_toast_message`}>{message}</ToastMessage>
            {linkText && <LinkTextWrapper onClick={onClickLinkText}>{linkText}</LinkTextWrapper>}
            {showCloseIcon &&  <CloseIcon onClick={onRemove} id={`${type}_toast_remove_icon`} />}
            <Clearall />
        </ToastContainer>
    );
};
const MemoizedToast = React.memo(Toast);

export default MemoizedToast;
