import { AnyAction } from "redux";
import { handleActions } from "redux-actions";

import actionTypes from "./actions";
import { ContentOutlineAPIStatus, PageOptimizerReducerInterface } from "./types";

const INIT_STATE: PageOptimizerReducerInterface = {
    isLoading: false,
    saveSimulationHistory: {
        count: 0,
        prev_page: null,
        curr_page: 1,
        next_page: null,
        total_pages: 0,
        sim_list: []
    },
    existingURLSimulationHistory: {
        count: 0,
        prev_page: null,
        curr_page: 1,
        next_page: null,
        total_pages: 0,
        sim_list: []
    },
    isAIAssistedContent: false,
    userPrompt: "",
    isAIContentFetchCompleted: undefined,
    initialContentOutlineAPIStatus: undefined, // denotes the status of first call to content outline API
    regenerateContentOutlineAPIStatus: undefined // denotes the status of latest call to content outline API on regenerating content
};

const Reducer = handleActions(
    {
        [actionTypes.SAVE_SIMULATION_HISTORY]: (state, action: AnyAction) => ({
            ...state,
            saveSimulationHistory: action.payload
        }),
        [actionTypes.SET_PAGINATION_SHOW_LOADER]: (state, action: AnyAction) => ({
            ...state,
            isLoading: true
        }),
        [actionTypes.SET_PAGINATION_HIDE_LOADER]: (state, action: AnyAction) => ({
            ...state,
            isLoading: false
        }),
        [actionTypes.SET_USER_PROMPT_FOR_CONTENT_OUTLINE]: (state, action: AnyAction) => ({
            ...state,
            isAIAssistedContent: action.payload.AIGeneratedFlag,
            userPrompt: action.payload.userPrompt
        }),
        [actionTypes.MARK_AI_CONTENT_FETCH_COMPLETE]: (state, action: AnyAction) => ({
            ...state,
            isAIContentFetchCompleted: true
        }),
        [actionTypes.RESET_PAGE_OPTIMIZER_SLICE]: (state, action: AnyAction) => ({
            ...INIT_STATE,
            isLoading: state.isLoading,
            saveSimulationHistory: state.saveSimulationHistory,
            existingURLSimulationHistory: state.existingURLSimulationHistory
        }),
        [actionTypes.FETCH_INITIAL_AI_GENERATED_CONTENT_OUTLINE]: (state, action: AnyAction) => ({
            ...state,
            isAIContentFetchCompleted: false,
            initialContentOutlineAPIStatus: ContentOutlineAPIStatus.STARTED
        }),
        [actionTypes.FETCH_REGENERATED_AI_CONTENT_OUTLINE]: (state, action: AnyAction) => ({
            ...state,
            isAIContentFetchCompleted: false,
            initialContentOutlineAPIStatus: undefined,
            regenerateContentOutlineAPIStatus: ContentOutlineAPIStatus.STARTED
        }),
        [actionTypes.SET_INITAL_GENERATED_CONTENT_OUTLINE_API_STATUS]: (state, action: AnyAction) => ({
            ...state,
            initialContentOutlineAPIStatus: action.payload
        }),
        [actionTypes.SET_REGENERATED_CONTENT_OUTLINE_API_STATUS]: (state, action: AnyAction) => ({
            ...state,
            regenerateContentOutlineAPIStatus: action.payload
        })
    },
    INIT_STATE
);

export default Reducer;
