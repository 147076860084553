import * as React from "react";
import { components } from "react-select";
import { ReactSelect } from "./dropdown";
import { OptionTypeInterface, StylesType, ComponentsTypes } from "./types";
import styled from "styled-components";
import { Checkbox } from "../../../app/styledComponents";
interface MultiselectDropdownPropsInterface {
    options: OptionTypeInterface[];
    value: OptionTypeInterface[];
    placeholder: string;
    styles?: StylesType;
    component?: ComponentsTypes;
    // tslint:disable-next-line:no-any
    onChange(value: any): void;
}

// These inline styles has to be removed once we update
// the components to latest ALPs design guidelines
const checkboxToLabelStyle = {
    display: "flex",
    alignItems: "center"
};

const spanStyle = {
    marginLeft: 5
};

// tslint:disable-next-line:no-any
const Option = (props: any) => (
    <components.Option {...props}>
        <div style={checkboxToLabelStyle}>
            {/* <input type="checkbox" checked={props.isSelected} />{" "} */}
            <Checkbox name="check" isChecked={props.isSelected} onChangeHandler={() => false} />
            <span style={spanStyle}>{props.label}</span>
        </div>
    </components.Option>
);

const StyledMultiValue = styled.span`
    :not(:first-child) {
        display: none;
    }
`;

// tslint:disable-next-line:no-any
const MultiValue = (props: any) => {
    const selectedOptionsCount = props.selectProps.value.length;
    let label: string = props.selectProps.placeholder;
    if (selectedOptionsCount) {
        label = `Selected (${selectedOptionsCount})`;
    }
    return <StyledMultiValue>{label}</StyledMultiValue>;
};

const IndicatorSeparator: React.SFC = () => null;

/**
 *  Multiselect dropdown:
 *
 *  Multiselect dropdown renders the dropwown with ability to select multiple option with checkbox in options
 *
 *  Props ::
 *
 *  @styles => (type: object) : style object to style the dropdown refer to https://react-select.com/styles#style-object
 *  @options => (type: array of objects) :this prop will accept array of objects with label & key as mandatory fields to show the dropdown list
 *  @component => (type: component object list): customisable components can be passed over here to override the default components provided by the
 *                react select refer to https://react-select.com/components
 *  @placeholder => (type: string): placeholder text for the dropdown label
 *  @onChange => (type: object) : callback function to trigger when the dropdown option is selected/changed
 *
 */
const MultiselectDropdown: React.SFC<MultiselectDropdownPropsInterface> = ({
    styles,
    options,
    component,
    placeholder,
    onChange,
    value
}) => (
    <ReactSelect
        styles={styles}
        options={options}
        placeholder={placeholder}
        closeMenuOnSelect={false}
        isMulti={true}
        component={{ Option, MultiValue, IndicatorSeparator, ...component }}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        onChange={onChange}
        value={value}
    />
);

export default MultiselectDropdown;
