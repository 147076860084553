import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */
const actionType = {
    SET_ACTIVE_ROUTE_URL: "SET_ACTIVE_ROUTE_URL",
    SET_PREV_ROUTE_URL: "SET_PREV_ROUTE_URL"
};
export default actionType;

export const setActiveRouteUrl = createAction(actionType.SET_ACTIVE_ROUTE_URL);
export const setPrevRouteUrl = createAction(actionType.SET_PREV_ROUTE_URL);
