import { call, put, takeEvery } from "redux-saga/effects";
import { AnyAction } from "redux";
import { setIsPhrasesLoading } from "./actions";
import apis from "../../../../../../app/apis";
import { googleTagManagerDataLayer } from "../../../../../../app/duck/utils";
import actionTypes, { setPhraseContent } from "../../../../ducks/actions";
import { RecommendationConst } from "./const";

// tslint:disable-next-line:typedef
function* fetchPhraseContent(action: AnyAction) {
    yield put(setIsPhrasesLoading(true));
    const { keywords, searchUrl, locale } = action.payload;
    const phraseApiResponse = yield call(apis.fetchPhraseContent, { keywords, searchUrl, locale });
    if (phraseApiResponse.status === 200) {
        yield put(setIsPhrasesLoading(false));
        const phraseContent = phraseApiResponse.data.phrases;
        yield put(setPhraseContent({ phraseContent, editorContent: action.payload.editorContent }));
    } else {
        yield put(setPhraseContent({ phraseContent: undefined, editorContent: action.payload.editorContent }));
        yield put(setIsPhrasesLoading(false));
        googleTagManagerDataLayer("Recommendation Load Error", [], { recomm_tab: RecommendationConst.PHRASES_TO_USE_LABEL });
    }
}

// tslint:disable-next-line:typedef
export function* watchForFetchPhraseContent() {
    yield takeEvery(actionTypes.CALL_TO_FETCH_PHRASE_CONTENT, fetchPhraseContent);
}
