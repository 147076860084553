import * as React from "react";

import { StyledVersionSaveButton, StyledPopUpButton } from "../styles";
import { Alert } from "../../../app/styledComponents";
import { BUTTON_TYPE } from "../../../app/styledComponents/button";
import { COPY_VERSION_SELECT } from "../Components/ducks/const";

export interface RefreshConsentPropsInterface {
    title: string;
    toggleModal(): void;
    onClickSaveHandler(): void;
}
const RefreshContentConsentAlert: React.SFC<RefreshConsentPropsInterface> = ({ toggleModal, title, onClickSaveHandler }) => (
    <Alert
        instanceId="version_consnt"
        title={title}
        onCloseHandler={() => {
            toggleModal();
        }}
        footer={[
            {
                styles: {
                    Button: StyledVersionSaveButton
                },
                label: COPY_VERSION_SELECT.MODAL_CONSENT_OPTION_NO,
                variant: BUTTON_TYPE.INVERT,

                onClickHandler: () => {
                    toggleModal();
                }
            },
            {
                styles: {
                    Button: StyledPopUpButton
                },
                label: COPY_VERSION_SELECT.MODAL_CONSENT_OPTION_YES,
                variant: BUTTON_TYPE.PRIMARY,
                onClickHandler: () => {
                    onClickSaveHandler();
                }
            }
        ]}
    />
);

export default RefreshContentConsentAlert;
