import { find, kebabCase, map, reduce, remove } from "lodash";
import {
    FilterConfigSectionsInterface,
    ReportRoutesListInterface,
    ReportFiltersList,
    ReportFilterValuesInterface,
    ReportSlicersList,
    GetReportFiltersArgs,
    ReportDefaultSlicersInterface,
    ProjectDetailArgs,
    ProjectFilterConfigSectionsInterface,
    ProjectFilterValueType
} from "./types";
import {
    PRS_RANK_TRACKING_KEYWORD_RANK_URL,
    CR_SHARE_OF_VOICE_CATEGORY_URL,
    CR_SHARE_OF_VOICE_OVERVIEW_URL,
    CR_TOPICAL_AUTHORITY_CATEGORY_URL,
    CR_TOPICAL_AUTHORITY_DOMAIN_URL,
    PRS_RANK_TRACKING_PAGES_REPORT_URL,
    CR_ALPS_SCORE_ANALYSIS_SCORE_TRENDS_URL,
    PR_GSC_PAGE_ANALYSIS_URL,
    PR_GSC_KEYWORD_DETAILS_URL,
    CR_ALPS_SCORE_ANALYSIS_GAP_ANALYSIS_URL,
    PRS_PROJECT_DASHBOARD_PERFORMANCE_DASHBOARD_URL,
    PR_GSC_MASTERVIEW,
    PR_GSC_KEYWORD_URL,
    PR_GSC_KEYWORD_ONLY,
    PR_GSC_URL_ONLY
} from "../../routes/ducks/routes-url-constant";
import { OptionTypeInterface, OptionValueType } from "../../app/styledComponents/drop-down/types";
import { PowerBiPayloadFilterInterface, PowerBiPayloadSlicerInterface } from "../../app/duck/types";
import {
    PERFORMANCE_REPORTS,
    SHARE_OF_VOICE,
    TOPICAL_AUTHORITY,
    PERFORMANCE_REPORTS_PAGES_REPORT,
    GSC_REPORT,
    SCORES_REPORT_SCORE_TRENDS,
    SCORES_REPORT_GAP_ANALYSIS,
    RANK_TRACKING_COMBINED_REPORT,
    RANK_TRACKING_DASHBOARD_REPORT,
    GSC_RAW_REPORT
} from "../../app/const";
import { models } from "powerbi-client";

export const REPORT_ROUTES_LIST: ReportRoutesListInterface[] = [
    {
        path: CR_SHARE_OF_VOICE_OVERVIEW_URL,
        exact: true,
        reportCode: SHARE_OF_VOICE,
        sectionCode: "overview",
        addMargin: false,
        filters: [],
        slicers: ["sovTargetCompetitorDomains"],
        externalFilters: ["DEVICE", "SEARCH_ENGINE"]
    },
    {
        path: CR_SHARE_OF_VOICE_CATEGORY_URL,
        exact: true,
        reportCode: SHARE_OF_VOICE,
        sectionCode: "categories",
        addMargin: false,
        filters: [],
        slicers: [],
        externalFilters: ["DEVICE", "SEARCH_ENGINE", "DOMAIN"]
    },
    {
        path: CR_TOPICAL_AUTHORITY_CATEGORY_URL,
        exact: true,
        reportCode: TOPICAL_AUTHORITY,
        sectionCode: "category",
        addMargin: false,
        filters: [],
        slicers: ["projectTADomain"],
        externalFilters: ["UNIVERSE", "LOCALE", "DEVICE", "SEARCH_ENGINE"]
    },
    {
        path: CR_TOPICAL_AUTHORITY_DOMAIN_URL,
        exact: true,
        reportCode: TOPICAL_AUTHORITY,
        sectionCode: "domain",
        addMargin: false,
        filters: [],
        slicers: ["projectCategory"],
        externalFilters: ["UNIVERSE", "LOCALE", "DEVICE", "SEARCH_ENGINE", "DOMAIN"]
    },
    {
        path: PRS_PROJECT_DASHBOARD_PERFORMANCE_DASHBOARD_URL,
        exact: true,
        reportCode: RANK_TRACKING_DASHBOARD_REPORT,
        sectionCode: "dashboard",
        addMargin: false,
        filters: [],
        slicers: [],
        externalFilters: ["DEVICE", "SEARCH_ENGINE"]
    },
    {
        path: PRS_RANK_TRACKING_KEYWORD_RANK_URL,
        exact: true,
        reportCode: RANK_TRACKING_COMBINED_REPORT,
        sectionCode: "keywords",
        addMargin: false,
        filters: [],
        slicers: [],
        externalFilters: ["DEVICE", "SEARCH_ENGINE", "DOMAIN"]
    },
    {
        path: PRS_RANK_TRACKING_PAGES_REPORT_URL,
        exact: true,
        reportCode: RANK_TRACKING_COMBINED_REPORT,
        sectionCode: "pages",
        addMargin: false,
        filters: [],
        slicers: [],
        externalFilters: ["DEVICE", "SEARCH_ENGINE", "DOMAIN"]
    },
    {
        path: CR_ALPS_SCORE_ANALYSIS_SCORE_TRENDS_URL,
        exact: true,
        reportCode: SCORES_REPORT_SCORE_TRENDS,
        sectionCode: "change-analysis",
        addMargin: false,
        filters: ["searchEngine", "project_id", "projectlocale", "device"],
        slicers: [],
        externalFilters: ["DEVICE", "SEARCH_ENGINE", "PROJECT_ID", "TENANT"]
    },
    {
        path: CR_ALPS_SCORE_ANALYSIS_GAP_ANALYSIS_URL,
        exact: true,
        reportCode: SCORES_REPORT_GAP_ANALYSIS,
        sectionCode: "benchmark",
        addMargin: false,
        filters: ["searchEngine", "project_id", "projectlocale", "device"],
        slicers: [],
        externalFilters: ["DEVICE", "SEARCH_ENGINE", "PROJECT_ID", "TENANT"]
    },
    {
        path: PR_GSC_PAGE_ANALYSIS_URL,
        exact: true,
        reportCode: GSC_REPORT,
        sectionCode: "page-analysis",
        addMargin: false,
        filters: ["targetDomain"],
        slicers: [],
        externalFilters: ["DOMAIN_NAME", "TENANT_CODE"]
    },
    {
        path: PR_GSC_KEYWORD_DETAILS_URL,
        exact: true,
        reportCode: GSC_REPORT,
        sectionCode: "keyword-details",
        addMargin: false,
        filters: ["targetDomain"],
        slicers: [],
        externalFilters: ["DOMAIN_NAME", "TENANT_CODE"]
    },
    {
        path: PR_GSC_MASTERVIEW,
        exact: true,
        reportCode: GSC_RAW_REPORT,
        sectionCode: "gsc-masterview",
        addMargin: false,
        filters: [],
        slicers: [],
        externalFilters: []
    },
    {
        path: PR_GSC_KEYWORD_URL,
        exact: true,
        reportCode: GSC_RAW_REPORT,
        sectionCode: "gsc-keyword-url",
        addMargin: false,
        filters: [],
        slicers: [],
        externalFilters: []
    },
    {
        path: PR_GSC_KEYWORD_ONLY,
        exact: true,
        reportCode: GSC_RAW_REPORT,
        sectionCode: "gsc-keyword-only",
        addMargin: false,
        filters: [],
        slicers: [],
        externalFilters: []
    },
    {
        path: PR_GSC_URL_ONLY,
        exact: true,
        reportCode: GSC_RAW_REPORT,
        sectionCode: "gsc-url-only",
        addMargin: false,
        filters: [],
        slicers: [],
        externalFilters: []
    },
];

// const multiselectDropdownPair = [
//     {
//         report: SHARE_OF_VOICE,
//         column: "DOMAIN",
//     },
// ];

export const getReportFilters = (
    filters: ReportFiltersList[],
    allowedFilters: string[],
    reportCode: string
): PowerBiPayloadFilterInterface[] => {
    let refinedFilters = filters;
    if (reportCode === TOPICAL_AUTHORITY) {
        refinedFilters = remove(refinedFilters, (filter: ReportFiltersList) => filter.name !== "projectlocale");
    }
    return refinedFilters.map(
        ({ table, column, value, type }: ReportFiltersList): PowerBiPayloadFilterInterface => {
            let values = [value?.label];
            if (column === "PROJECT_ID") {
                values = [value?.value];
            }
            // need to uncomment in case of multiselect dropwown
            // const isMultipleValuesSelected = Array.isArray(value);
            // if (type === "multiselectDropdown" && isMultipleValuesSelected) {
            //     values = [];
            //     for (const val of value) {
            //         values.push(val.label);
            //     }
            // }
            return {
                values,
                target: {
                    table,
                    column
                },
                $schema: "http://powerbi.com/product/schema#basic",
                operator: "In",
                filterType: models.FilterType.Basic, // pbi.models.FilterType.BasicFilter
                requireSingleSelection: type !== "multiselectDropdown" // Limits selection of values to one.
            };
        }
    );
};

export const getReportSlicers = (slicers: ReportSlicersList[], allowedSlicers: string[]): PowerBiPayloadSlicerInterface[] => {
    const refinedSlicers = slicers.filter(({ name, value }) => allowedSlicers.includes(name) && value.length > 0);
    return refinedSlicers.map(
        ({ table, column, value }): PowerBiPayloadSlicerInterface => ({
            selector: {
                target: { table, column },
                $schema: "http://powerbi.com/product/schema#slicerTargetSelector"
            },
            state: {
                filters: [
                    {
                        target: { table, column },
                        $schema: "http://powerbi.com/product/schema#basic",
                        operator: "In",
                        values: value,
                        filterType: models.FilterType.Basic,
                        requireSingleSelection: true
                    }
                ]
            }
        })
    );
};

export const getReportId = (url: string): string => {
    const query = url.slice(url.indexOf("?"));
    const urlParams = new URLSearchParams(query);
    return urlParams.get("reportId") || "";
};

export const getPageName = (sections: FilterConfigSectionsInterface[], sectionCode: string = "") => {
    let section = null;
    if (Array.isArray(sections)) {
        section = sections.find(({ section_code }) => section_code === sectionCode);
    }
    return section?.section_id || "";
};

export const getProjectPageName = (sections: ProjectFilterConfigSectionsInterface, sectionCode: string = "") => {
    let pageName = "";
    if (sections && sectionCode in sections) {
        pageName = sections[sectionCode];
    }
    return pageName;
};

export const getFilterValues = (filters: ReportFiltersList[]): ReportFilterValuesInterface => {
    return reduce(
        filters,
        (acc: { projectId: number, locale: string; product: string; productName: string }, filter: ReportFiltersList) => {
            if (filter.name === "locale") {
                acc.locale = filter.value?.value as string || "";
            }
            if (filter.name === "product") {
                acc.productName = filter.value?.label as string || "";
                acc.product = filter.value?.value as string || "";
            }
            return acc;
        },
        { locale: "", product: "", productName: "", projectId: 0 }
    );
};

// THE BELOW CODE IS NEEDED WHEN WE ENABLE DATE FILTER
//
// const getDatesList = (): string[] => {
//     const monthNamesList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
//     const dateList = [];
//     const date = new Date();
//     date.setDate(1);
//     // @ts-ignore
//     for (let i = 0; i <= 11; i += 1) {
//         dateList.push(`${monthNamesList[date.getMonth()]} ${date.getFullYear()}`);
//         date.setMonth(date.getMonth() - 1);
//     }
//     return dateList;
// };

// const datesList: string[] = getDatesList();

const searchEngineOptions = ["Google"];
const deviceOptions = ["Desktop", "Mobile"];
const deviceOptionswithDesktop = ["Desktop"];
export const reportTables = {
    AGG_SOV: "AGG_SOV",
    AGG_KW_PERFORMANCE: "AGG_KW_PERFORMANCE",
    AGG_KEYWORD_EXPLORER: "AGG_KEYWORD_EXPLORER",
    AGG_TOPICAL_AUTHORITY: "AGG_TOPICAL_AUTHORITY",
    AGG_KW_PERFORMANCE_PAGE: "AGG_KW_PERFORMANCE_PAGE",
    DIM_KW_UNI_HRCHY: "DIM_KW_UNI_HRCHY",
    DIM_MONTH_LOCALE_SE: "DIM_MONTH_LOCALE_SE",
    DIM_KW_UNI_LOC: "DIM_KW_UNI_LOC",
    SCORE_DATA: "Project",
    BENCHMARK_DATA: "BM_SCORE_DATA_BM",
    VW_DIM_DOMAIN: "VW_DIM_DOMAIN",
    DIM_TA: "DIM_TA",
    CFG_TARGET_DOMAIN: "CFG_TARGET_DOMAIN",
    DIM_TENANT: "VW_DIM_DOMAIN",
    VW_DEVICE: "VW_DEVICE",
    SCORES_CHANGE_GAP_ANALYSIS_DOMAIN_TABLE: "VW_DIM_PROJECT_URL_DOMAIN",
    SCORES_CHANGE_GAP_ANALYSIS_KEYWORD_TABLE: "VW_DIM_PROJECT_KEYWORD",
    SCORES_CHANGE_GAP_ANALYSIS_LOCALE_TABLE: "VW_DIM_PROJECT_LOC_SE",
    DIM_DEVICE_LOC_SE: "DIM_DEVICE_LOC_SE",
    DIM_URL: "DIM_URL",
    VW_AGG_PERFORMANCE: "VW_AGG_PERFORMANCE"
};

export const SCORES_CHANGE_GAP_ANALYSIS_PROJECT_COLUMN = "PROJECT_ID";
export const SCORES_CHANGE_GAP_ANALYSIS_TENANT_COLUMN = "TENANT";

export const getOptions = (options: string[]): OptionTypeInterface[] =>
    options.map((option: string): OptionTypeInterface => ({ label: option, value: kebabCase(option) }));

export const getOptionValue = (option: string): OptionTypeInterface => ({ label: option, value: kebabCase(option) });

export const getProjectOptions = (options: ProjectFilterValueType[]): OptionTypeInterface[] =>
    options.map((option: ProjectFilterValueType): OptionTypeInterface => ({ label: option?.label, value: option?.value }));

export const getProjectOptionValue = (option: ProjectFilterValueType): OptionTypeInterface => (
    { label: option?.label, value: option?.value }
);

export const getDeviceTable = (report: string): string => {
    switch (report) {
        case SHARE_OF_VOICE:
            return reportTables.AGG_SOV;
        case PERFORMANCE_REPORTS:
            return reportTables.DIM_DEVICE_LOC_SE;
        case PERFORMANCE_REPORTS_PAGES_REPORT:
            return reportTables.DIM_DEVICE_LOC_SE;
        case TOPICAL_AUTHORITY:
            return reportTables.DIM_TA;
        default:
            return reportTables.AGG_KEYWORD_EXPLORER;
    }
};

export const getProductTable = (report: string): string => {
    switch (report) {
        case TOPICAL_AUTHORITY:
            return reportTables.DIM_TA;
        case PERFORMANCE_REPORTS:
            return reportTables.AGG_KW_PERFORMANCE;
        case PERFORMANCE_REPORTS_PAGES_REPORT:
            return reportTables.DIM_KW_UNI_LOC;
        case TOPICAL_AUTHORITY:
            return reportTables.DIM_TA;
        default:
            return reportTables.DIM_KW_UNI_HRCHY;
    }
};

export const getLocaleTable = (report: string): string => {
    switch (report) {
        case TOPICAL_AUTHORITY:
            return reportTables.DIM_TA;
        case PERFORMANCE_REPORTS:
            return reportTables.AGG_KW_PERFORMANCE;
        case PERFORMANCE_REPORTS_PAGES_REPORT:
            return reportTables.AGG_KW_PERFORMANCE_PAGE;
        default:
            return reportTables.DIM_MONTH_LOCALE_SE;
    }
};

export const getProjectTable = (report: string): string => {
    switch (report) {
        case SCORES_REPORT_SCORE_TRENDS:
            return reportTables.SCORES_CHANGE_GAP_ANALYSIS_KEYWORD_TABLE;
        case SCORES_REPORT_GAP_ANALYSIS:
            return reportTables.SCORES_CHANGE_GAP_ANALYSIS_KEYWORD_TABLE;
        default:
            return reportTables.SCORE_DATA;
    }
};

export const getTenantTable = (report: string): string => {
    switch (report) {
        case SCORES_REPORT_SCORE_TRENDS:
            return reportTables.SCORES_CHANGE_GAP_ANALYSIS_KEYWORD_TABLE;
        case SCORES_REPORT_GAP_ANALYSIS:
            return reportTables.SCORES_CHANGE_GAP_ANALYSIS_KEYWORD_TABLE;
        case GSC_REPORT:
            return reportTables.VW_DIM_DOMAIN;
        default:
            return reportTables.SCORE_DATA;
    }
};

export const getDomainTable = (report: string): string => {
    switch (report) {
        case SHARE_OF_VOICE:
            return reportTables.AGG_SOV;
        case PERFORMANCE_REPORTS:
            return reportTables.DIM_URL;
        case PERFORMANCE_REPORTS_PAGES_REPORT:
            return reportTables.DIM_URL;
        case TOPICAL_AUTHORITY:
            return reportTables.DIM_TA;
        default:
            return reportTables.CFG_TARGET_DOMAIN;
    }
};

export const getSearchEngineTable = (report: string): string => {
    switch (report) {
        case PERFORMANCE_REPORTS_PAGES_REPORT:
            return reportTables.VW_AGG_PERFORMANCE;
        case PERFORMANCE_REPORTS:
            return reportTables.VW_AGG_PERFORMANCE;
        case TOPICAL_AUTHORITY:
            return reportTables.DIM_TA;
        default:
            return reportTables.DIM_MONTH_LOCALE_SE;
    }
};

export const getTenantColumn = (report: string): string => {
    switch (report) {
        case SCORES_REPORT_SCORE_TRENDS:
            return "TENANT";
        case SCORES_REPORT_GAP_ANALYSIS:
            return "TENANT";
        case GSC_REPORT:
            return "TENANT_CODE";
        default:
            return "TENANT";
    }
};

export const getDeviceOptions = (report: string): string[] => {
    switch (report) {
        case SCORES_REPORT_SCORE_TRENDS:
            return deviceOptionswithDesktop;
        case SCORES_REPORT_GAP_ANALYSIS:
            return deviceOptionswithDesktop;
        default:
            return deviceOptions;
    }
};

export const getFiltersList = ({
    products,
    locales,
    domains,
    targetDomains,
    projects,
    projectlocale,
    tenantCode,
    report,
    defaultDomain
}: GetReportFiltersArgs): ReportFiltersList[] => [
        {
            name: "searchEngine",
            label: "Search Engine",
            type: "dropdown",
            table: getSearchEngineTable(report),
            column: "SEARCH_ENGINE",
            options: getOptions(searchEngineOptions),
            value: getOptionValue(searchEngineOptions[0])
        },
        {
            name: "product",
            label: "Product",
            type: "dropdown",
            table: getProductTable(report),
            column: "UNIVERSE",
            options: products,
            value: products[0]
        },
        {
            name: "project_id",
            label: "Project",
            type: "dropdown",
            table: getProjectTable(report),
            column: "PROJECT_ID",
            options: getProjectOptions(projects),
            value: getProjectOptionValue(projects[0])
        },
        {
            name: "locale",
            label: "Locale",
            type: "dropdown",
            table: getLocaleTable(report),
            column: "LOCALE",
            options: getOptions(locales),
            value: getOptionValue(locales[0])
        },
        {
            name: "projectlocale",
            label: "Locale",
            type: "text",
            table: getLocaleTable(report),
            column: "LOCALE",
            options: getOptions(projectlocale),
            value: getOptionValue(projectlocale[0])
        },
        {
            name: "device",
            label: "Device",
            type: "dropdown",
            table: getDeviceTable(report),
            column: "DEVICE",
            options: getOptions(getDeviceOptions(report)),
            value: getOptionValue(deviceOptions[0])
        },
        {
            name: "Domain",
            label: "Domain",
            type: "dropdown",
            table: getDomainTable(report),
            column: "DOMAIN",
            options: getOptions(domains),
            value: getOptionValue(defaultDomain === undefined ? "" : defaultDomain)
        },
        {
            name: "targetDomain",
            label: "Target Domain",
            type: "dropdown",
            table: reportTables.VW_DIM_DOMAIN,
            column: "DOMAIN_NAME",
            options: getOptions(targetDomains),
            value: getOptionValue(targetDomains[0])
        },
        {
            name: "tenant",
            label: "Tenant",
            type: "dropdown",
            table: getTenantTable(report),
            column: getTenantColumn(report),
            options: getOptions([]),
            value: getOptionValue(tenantCode === undefined ? "" : tenantCode)
        }
        // THE BELOW CODE IS NEEDED WHEN WE ENABLE DATE FILTER
        // {
        //     name: "date",
        //     label: "Date",
        //     type: "multiselectDropdown",
        //     table: "DIM_MONTH_LOCALE_SE",
        //     column: "MONTH_YR",
        //     options: datesList,
        //     defaultValue: []
        // },
    ];

export const getSlicersList = (reportCode: string, defaultSlicers: ReportDefaultSlicersInterface): ReportSlicersList[] => {
    const defaultTargetDomain = defaultSlicers.targetDomain || "";
    const defaultProjectCategory = defaultSlicers.projectCategory || "";
    const defaultProjectTADomain = defaultSlicers.projectTADomain || "";
    const sovTargetCompetitorDomains = defaultSlicers.sovTargetCompetitorDomains || [""];
    return [
        {
            name: "targetDomain",
            label: "Target Domain",
            type: "direct",
            table: reportCode.indexOf(SHARE_OF_VOICE) > -1 ? "AGG_SOV" : "AGG_TOPICAL_AUTHORITY",
            column: "DOMAIN",
            value: [defaultTargetDomain]
        },
        {
            name: "projectTADomain",
            label: "Domain TA",
            type: "dropdown",
            table: "Project Domain Filter",
            column: "Domain",
            value: [defaultProjectTADomain]
        },
        {
            name: "projectCategory",
            label: "Project Category",
            type: "dropdown",
            table: "Category",
            column: "Category",
            value: [defaultProjectCategory]
        },
        {
            name: "sovTargetCompetitorDomains",
            label: "SOV Target Competitor Domains",
            type: "dropdown",
            table: "Domain",
            column: "Target / Competitor",
            value: sovTargetCompetitorDomains
        },
    ];
};

export const getPreviouslySelectedFilter = (name: string, prevFilters: ReportFiltersList[]) => {
    return prevFilters.find((filter: ReportFiltersList) => filter.name === name)?.value;
};

export const getNextTargetFilterProps = (
    prevFilters: ReportFiltersList[],
    targetDomains: string[],
    reportCode?: string,
    tenantCode?: string,
    defaultDomain?: string
) => {
    const prevlocales = find(prevFilters, (filter: ReportFiltersList) => filter.name === "locale");
    const prevproducts = find(prevFilters, (filter: ReportFiltersList) => filter.name === "product");
    const locales = map(prevlocales?.options || [], (option: OptionTypeInterface) => option.label as string);
    const products = prevproducts?.options || [];
    const prevProjectsFilter = prevFilters.find((filter: ReportFiltersList) => filter.name === "project_id");
    const projects = (prevProjectsFilter?.options || []).map((option: OptionTypeInterface) => (
        { label: option.label as OptionValueType, value: option.value as OptionValueType }
    ));

    const prevprojectlocale = find(prevFilters, (filter: ReportFiltersList) => filter.name === "projectlocale");
    const projectlocale = map(prevprojectlocale?.options || [], (option: OptionTypeInterface) => option.label as string);
    const prevdomainsFilter = find(prevFilters, (filter: ReportFiltersList) => filter.name === "Domain");
    const domains = (prevdomainsFilter?.options || []).map((option: OptionTypeInterface) => option.label as string);
    const newFilters = getFiltersList({
        products,
        locales,
        domains,
        targetDomains,
        projects,
        projectlocale,
        tenantCode,
        defaultDomain,
        report: reportCode || ""
    });
    const filters = map(newFilters, (filter: ReportFiltersList) => {
        const prevVal = getPreviouslySelectedFilter(filter.name, prevFilters);
        if (filter.name === "device" && (reportCode === SCORES_REPORT_SCORE_TRENDS || reportCode === SCORES_REPORT_GAP_ANALYSIS)) {
            return {
                ...filter,
                value: filter.value
            };
        }
        return {
            ...filter,
            value: prevVal?.label ? prevVal : filter.value
        };
    });

    return {
        filters
    };
};

export const getNextProjectsProps = (
    prevFilters: ReportFiltersList[],
    projectsObjectList: ProjectDetailArgs[],
    reportCode?: string,
    tenantCode?: string,
    defaultDomain?: string
) => {
    const prevlocales = find(prevFilters, (filter: ReportFiltersList) => filter.name === "locale");
    const prevproducts = find(prevFilters, (filter: ReportFiltersList) => filter.name === "product");
    const prevtargetDomains = find(prevFilters, (filter: ReportFiltersList) => filter.name === "targetDomain");
    const locales = map(prevlocales?.options || [], (option: OptionTypeInterface) => option.label as string);
    const products = prevproducts?.options || [];
    const targetDomains = (prevtargetDomains?.options || []).map((option: OptionTypeInterface) => option.label as string);
    const projects = [];
    for (const currentProject of projectsObjectList) {
        projects.push({ label: currentProject.name, value: currentProject.id });
    }
    const projectlocale = [projectsObjectList[0].locale];
    const prevdomainsFilter = find(prevFilters, (filter: ReportFiltersList) => filter.name === "Domain");
    const domains = (prevdomainsFilter?.options || []).map((option: OptionTypeInterface) => option.label as string);
    const newFilters = getFiltersList({
        products,
        locales,
        domains,
        targetDomains,
        projects,
        projectlocale,
        tenantCode,
        defaultDomain,
        report: reportCode || ""
    });
    const filters = map(newFilters, (filter: ReportFiltersList) => {
        const prevVal = getPreviouslySelectedFilter(filter.name, prevFilters);
        if (filter.name === "device" && (reportCode === SCORES_REPORT_SCORE_TRENDS || reportCode === SCORES_REPORT_GAP_ANALYSIS)) {
            return {
                ...filter,
                value: filter.value
            };
        }
        return {
            ...filter,
            value: prevVal?.label ? prevVal : filter.value
        };
    });

    return {
        filters
    };
};
