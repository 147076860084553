export enum ContextualRecomParamName {
    H1 = "h1",
    H2 = "h2",
    H3 = "h3",
    META_DESCRIPTION = "meta_description",
    TITLE = "title",
    URL = "url",
    STRIPPED_PAGE = "stripped_page"
}

export interface ContextualRecommendationsModalBodyPropsInterface {
    parameterName: ContextualRecomParamName;
    contextText: string | string[];
    highlightingTokens: string[];
    onCloseHandler(): void;
    onUpdateContentHandler(args: string[]): void;
}

export interface ContextKeywordsInterface {
    [id: string]: boolean;
}

export interface ContextualRecommendationAPIInterface {
    keywords: string[];
    context: string[];
    element: string;
    tenant_code: string;
}

export interface ContextualRecommendationsModalRightPanelPropsInterface {
    parameterName: ContextualRecomParamName;
    isGenerateBtnClicked: boolean;
    elemnetText: string;
    checkboxStatus: boolean[];
    onAddToEditorClick(): void;
    onChangeHandlerFunction(index: number): void;
    isAddToEditorButtonDisable(): boolean;
}

export interface ContextualRecomModalLeftPanelPropsInterface {
    parameterName: ContextualRecomParamName;
    contextText: string | string[];
    highlightingTokens: string[];
    setIsGenerateBtnClicked(args: boolean): void;
    callSelectedTargetKeyword(args: { [id: string]: boolean }): void;
}

export interface ContextualRecommEditSectionInterface {
    contents: string;
    parameterName: ContextualRecomParamName;
    updateContextValue(args: string): void;
}

export interface ContextualRecommendationsAddKwdInterface {
    keywordDict: { [id: string]: boolean };
    toggleKeywordHandler(args: string): void;
}

export interface ContextualRecommendationsInterface {
    recommendation: string;
    char_count: number;
    score: number;
}

export interface ContextualRecommendationsReducerInterface {
    contextualRecommendations: ContextualRecommendationsInterface[];
    isContextRecomModalOpen: boolean;
    isContextualRecomLoding: boolean;
    isContextualApiFail?: boolean;
}
export interface ContextRecommendationSseEventInterface {
    receive_recommendations: string;
    receive_recommendation_score: string;
    req_completed: string;
    req_failed: string;
    error: string;
}

export interface ContextualRecommendationsInterface {
    recommendation: string;
    char_count: number;
    score: number;
}
