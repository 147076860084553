import React, { useEffect, useState } from "react";
import { BUTTON_TYPE } from "../../../../app/styledComponents/button";
import { Button } from "../../../../app/styledComponents";
import { logOut } from "../../../../app/duck/utils";
import { greenTick } from "../../../../app/icons";
import {
    StyledCongratulationFooterText,
    StyledCongratulationParagraph,
    StyledCongratulationsWrapper,
    StyledCongratulationText,
    StyledLoginButton,
    StyledSuccessImage
} from "../StyledSimulator";
import { UPDATENOW } from "../const";

export interface SuccessPopUpPropInterface {
    instanceId?: string;
}

const SuccessPopUp: React.FC<SuccessPopUpPropInterface> = ({ instanceId }) => {
    const [loginClickedStatus, setLoginClickedStatus] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            logOut();
        }, 10000);
    }, []);

    return (
        <>
            <StyledCongratulationsWrapper id="success-wrapper">
                <StyledSuccessImage id="success-img" src={greenTick} />
                <StyledCongratulationText id="congratulations-text">{UPDATENOW.CONGRATULATIONS_TEXT}</StyledCongratulationText>
            </StyledCongratulationsWrapper>
            <StyledCongratulationParagraph id="success-msg">{UPDATENOW.SUCESS_MSG_1}
                <span style={{ fontWeight: "bold" }}>{UPDATENOW.SUCESS_MSG_2}</span>
                {UPDATENOW.SUCESS_MSG_3}
                <span style={{ fontWeight: "bold" }}>{UPDATENOW.SUCESS_MSG_4}</span>
            </StyledCongratulationParagraph>
            <StyledCongratulationFooterText id="success-footer-msg">
                {UPDATENOW.SUCESS_MSG_5}
                <Button
                    instanceId={"pay-button"}
                    variant={BUTTON_TYPE.LINK}
                    onClickHandler={() => setLoginClickedStatus(true)}
                    label="Login"
                    styles={{ Label: StyledLoginButton }}
                />
                {UPDATENOW.SUCESS_MSG_6}
            </StyledCongratulationFooterText>
            {loginClickedStatus && logOut()}
        </>
    );
};

export default SuccessPopUp;
