// tslint:disable
import * as React from "react";
import styled from "styled-components";
import { arrow } from "../../app/icons";
import { NavLink } from "react-router-dom";
import { NavLinkType, PrimaryNavListTabsNavLinksInterface } from "../ducks/types";
import { ContextReducerInterface } from "../../app/duck/types";
import { getLinkWithParams } from "../ducks/utils";

export const HeaderTabsContainer = styled.div`
    height: 30px;
    box-shadow: 0 1px 6px -1px rgba(0, 0, 0, 0.17);
    padding: 0 48px;
    box-sizing: border-box;
    position: fixed;
    left: 0;
    right: 0;
    top: 70px;
    background: #fff;
    z-index: 3;
`;
export const StyledHeaderTabsUl = styled.ul``;
export const StyledHeaderTabsNavlink = styled(NavLink)`
    padding: 10px 20px 9px 20px;
    font-size: 12px;
    float: left;
    cursor: pointer;
    position: relative;
    color: #4b5166;
    font-weight: 400;
    border-bottom: 0px solid #fff;
    &:after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        content: "";
        margin: auto;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
    }
    &.active {
        color: #00acce;
        font-weight: 700;
        border-bottom: 4px solid #00acce;
        padding: 9px 20px 5px 20px;
        &:after {
            border-top: 10px solid #00acce;
        }
    }
`;

export const StyledHeaderTabsAnchor = styled.a`
    padding: 10px 20px 9px 20px;
    font-size: 12px;
    float: left;
    cursor: pointer;
    position: relative;
    color: #4b5166;
    font-weight: 400;
    border-bottom: 0px solid #fff;
    &:after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        content: "";
        margin: auto;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
    }
    &.active {
        color: #00acce;
        font-weight: 700;
        border-bottom: 4px solid #00acce;
        padding: 9px 20px 5px 20px;
        &:after {
            border-top: 10px solid #00acce;
        }
    }
`;
export const StyledLabelContainer = styled.div`
    padding: 10px 0px 7px 0px;
    float: left;
    width: 185px;
    font-size: 12px;
    font-weight: 700;
    padding-left: 27px;
    background-repeat: no-repeat;
    background-position: 0 center;
    box-sizing: border-box;
    position: relative;
    margin-right: 22px;
    color: #4b5166;
    height: 30px;
    &:after {
        /* width: 0;
        height: 0;
        content: "";
        position: absolute;
        right: 0;
        top: 6px;
        border: 14px solid black;
        box-sizing: border-box;
        border-color: transparent transparent #fff #fff;
        box-shadow: -2px 2px 3px 0px rgba(0, 0, 0, 0.17);
        -webkit-transform: rotate(225deg);
        -ms-transform: rotate(225deg);
        transform: rotate(225deg); */
        background: url(${arrow}) no-repeat;
        content: "";
        height: 30px;
        width: 30px;
        display: block;
        position: absolute;
        right: -20px;
        top: 0;
    }
`;

interface TabsPropsInterface {
    links: PrimaryNavListTabsNavLinksInterface[];
    context: ContextReducerInterface;
    activeRouteUrl: string;
    title: string;
    icon: string;
}

interface LocationInterface {
    pathname: string;
}

// tslint:disable-next-line:no-any
const matchRoute = (match: any, location: LocationInterface, highlightWhen: string[], activeRouteUrl: string, to: string) => {
    if (
        match ||
        to === location.pathname ||
        to === activeRouteUrl
    ) {
        return true;
    }
    return !!(highlightWhen.includes(location.pathname) || highlightWhen.includes(activeRouteUrl));
};

const Tabs: React.FunctionComponent<TabsPropsInterface> =
    ({ links, title, context, activeRouteUrl, icon }: TabsPropsInterface): JSX.Element => (
        <HeaderTabsContainer id="tab">
            {title && <StyledLabelContainer
                style={{ backgroundImage: `url(${icon})` }}
            >{title}</StyledLabelContainer>}
            <StyledHeaderTabsUl>
                {links.map((link: PrimaryNavListTabsNavLinksInterface) => {
                    const { id = '', to = '', title = '', highlightWhen = [], type, isHidden } = link;
                    if (isHidden) {
                        return null;
                    }
                    return (
                        <React.Fragment key={id + to + title}>
                            {type === NavLinkType.INTERNAL ? <StyledHeaderTabsNavlink
                                id={id}
                                to={getLinkWithParams({ to, context, isExternal: false })}
                                isActive={(match, location) => matchRoute(match, location, highlightWhen, activeRouteUrl, to)}
                                title={title}
                            >{title}</StyledHeaderTabsNavlink> :
                                <StyledHeaderTabsAnchor
                                    id={id}
                                    href={getLinkWithParams({ to, context, isExternal: true })}
                                    className={matchRoute(undefined, location, highlightWhen, activeRouteUrl, to) ? "active" : ""}
                                >{title}</StyledHeaderTabsAnchor>}
                        </React.Fragment>
                    );
                    // if (type === NavLinkType.INTERNAL) {
                    //     return (
                    //         <StyledHeaderTabsNavlink
                    //             key={id+to+title}
                    //             id={id}
                    //             to={getLinkWithParams({ to, context, isExternal: false })}
                    //             isActive={(match, location) => matchRoute(match, location, highlightWhen, activeRouteUrl, to)}
                    //             title={title}
                    //         >{title}</StyledHeaderTabsNavlink>
                    //     )
                    // }
                    // return (
                    //     <StyledHeaderTabsAnchor
                    //         key={id+to+title}
                    //         id={id}
                    //         href={getLinkWithParams({ to, context, isExternal: true })}
                    //         className={matchRoute(undefined, location, highlightWhen, activeRouteUrl, to) ? "active" : ""}
                    //     >{title}</StyledHeaderTabsAnchor>
                    // )
                })}
            </StyledHeaderTabsUl>
        </HeaderTabsContainer>
    );

export default Tabs;
