import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */
const ActionTypes = {
    SAVE_SELECTED_CONTENT_BRIEF: "SAVE_SELECTED_CONTENT_BRIEF",
    UPDATE_CB_NAME_AND_DESCRIPTION: "UPDATE_CB_NAME_AND_DESCRIPTION",
    GET_CONTENT_BRIEF_SUMMARY: "GET_CONTENT_BRIEF_SUMMARY",
    SAVE_CONTENT_BRIEF_SUMMARY: "SAVE_CONTENT_BRIEF_SUMMARY",
    GET_CONTENT_BRIEF_TAB_HISTORY: "GET_CONTENT_BRIEF_TAB_HISTORY",
    SAVE_CONTENT_BRIEF_TAB_HISTORY: "SAVE_CONTENT_BRIEF_TAB_HISTORY",
    GET_CONTENT_BRIEF_COMPETITORS: "GET_CONTENT_BRIEF_COMPETITORS",
    SAVE_CONTENT_BRIEF_COMPETITORS: "SAVE_CONTENT_BRIEF_COMPETITORS",
    SET_PAGE_SHOW_LOADER: "SET_PAGE_SHOW_LOADER",
    SET_PAGE_HIDE_LOADER: "SET_PAGE_HIDE_LOADER",
    IS_MODIFY_KEYWORD: "IS_MODIFY_KEYWORD",
    GET_PHRASES_DETAILS: "GET_PHRASES_DETAILS",
    SET_UNIGRAMS_WORDS: "SET_UNIGRAMS_WORDS",
    SET_BIGRAMS_WORDS: "SET_BIGRAMS_WORDS",
    SET_TRIGRAMS_WORDS: "SET_TRIGRAMS_WORDS",
    SET_QUESTIONS: "SET_QUESTIONS"
};
export default ActionTypes;

// GETTERS / API CALL ACTIONS
export const saveSelectedContentBrief = createAction(ActionTypes.SAVE_SELECTED_CONTENT_BRIEF);
export const updateCBNameAndDescription = createAction(ActionTypes.UPDATE_CB_NAME_AND_DESCRIPTION);
export const getContentBriefSummary = createAction(ActionTypes.GET_CONTENT_BRIEF_SUMMARY);
export const saveContentBriefSummary = createAction(ActionTypes.SAVE_CONTENT_BRIEF_SUMMARY);
export const getContentBriefTabHistory = createAction(ActionTypes.GET_CONTENT_BRIEF_TAB_HISTORY);
export const saveContentBriefTabHistory = createAction(ActionTypes.SAVE_CONTENT_BRIEF_TAB_HISTORY);
export const getContentBriefCompetitors = createAction(ActionTypes.GET_CONTENT_BRIEF_COMPETITORS);
export const saveContentBriefCompetitors = createAction(ActionTypes.SAVE_CONTENT_BRIEF_COMPETITORS);
export const setPageShowLoader = createAction(ActionTypes.SET_PAGE_SHOW_LOADER);
export const setPageHideLoader = createAction(ActionTypes.SET_PAGE_HIDE_LOADER);
export const setIsModifyKeyword = createAction(ActionTypes.IS_MODIFY_KEYWORD);
export const getPhrasesDetails = createAction(ActionTypes.GET_PHRASES_DETAILS);
export const setUnigramsWords = createAction(ActionTypes.SET_UNIGRAMS_WORDS);
export const setBigramsWords = createAction(ActionTypes.SET_BIGRAMS_WORDS);
export const setTrigramsWords = createAction(ActionTypes.SET_TRIGRAMS_WORDS);
export const setQuestions = createAction(ActionTypes.SET_QUESTIONS);
