import React from "react";
import { alpsSvg, unlockAlps, closeGrey } from "../../app/icons";
import { COPY } from "../../app/const";

import {
    StyledGradientButtonText,
    StyledGradientButton,
    StyledReqOverlay,
    StyledReqUL,
    StyledReqTitle,
    StyledReqDIVBtn,
    StyledReqButton,
    StyledReqUnlock,
    StyledReqAlpsIcon,
    StyledReqHeader,
    StyledReqContainer,
    StyledReqLi
} from "../../beta/home/styledHome";

interface RequestDemoPupInterface {
    title?: string;
    points?: string[];
    closeHandler(arg: boolean): void;
}

const RequestDemo: React.SFC<RequestDemoPupInterface> = ({ closeHandler }) => {
    const data = [COPY.REQUEST_DEMO_DESCRIPTION1, COPY.REQUEST_DEMO_DESCRIPTION2, COPY.REQUEST_DEMO_DESCRIPTION3];
    const clickHandler = () => {
        closeHandler(false);
    };
    return (
        <StyledReqOverlay>
            <StyledReqContainer>
                <StyledReqHeader>
                    <StyledReqAlpsIcon src={alpsSvg} />
                    <StyledReqButton>
                        <img
                            src={closeGrey}
                            className="close-icon"
                            id="notification_close_icon"
                            onClick={clickHandler}
                            style={{ width: "15px" }}
                        />
                    </StyledReqButton>
                </StyledReqHeader>

                <StyledReqUnlock src={unlockAlps} />
                <StyledReqTitle>{COPY.REQUEST_DEMO_POPUP_TITLE}</StyledReqTitle>
                <StyledReqUL>
                    {data.map((el, index) => (
                        <StyledReqLi key={index}>{el}</StyledReqLi>
                    ))}
                </StyledReqUL>

                <StyledReqDIVBtn>
                    <StyledGradientButton className="popup" href={process.env.REQUEST_DEMO_URL} target="_blank">
                        <StyledGradientButtonText>{COPY.REQUEST_DEMO_TEXT}</StyledGradientButtonText>
                    </StyledGradientButton>
                </StyledReqDIVBtn>
            </StyledReqContainer>
        </StyledReqOverlay>
    );
};

export default RequestDemo;
