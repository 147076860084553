import { isUndefined, map, uniqBy, sortBy, find, filter, assign, keys } from "lodash";
import { createSelector } from "reselect";

import {
    SimulatorReducerInterface,
    ParameterInterface,
    ChangedParameterInterface,
    ParameterValidationInterface,
    ModelScoreContentResponseInterface,
    PerformanceMetricsResponseInterface,
    ModelScoreDetailsResponseInterface,
    EditorElementInterfaceBeta,
    ImpactedKeywordsResponseInterface,
    UrlKeywordsScoreResponseInterface,
    FormattedSubCategories
} from "./types";
import { CategoryType, AppliedFilterSortInterface, ApplicationStateInterface, SerpListProgressStatusType } from "../../../app/duck/types";

import { getAuthTechSortedCategory } from "./utils";

export const formatScoreSummary = (
    modelScore?: ModelScoreContentResponseInterface,
    performaceMetrix?: PerformanceMetricsResponseInterface
): ModelScoreContentResponseInterface | undefined => {
    return !isUndefined(performaceMetrix) && keys(performaceMetrix).length && !isUndefined(modelScore)
        ? assign({}, modelScore, {
              organic_rank: assign(
                  {},
                  {
                      delta_value: performaceMetrix.organic_rank.delta_value as number | null | undefined,
                      value: performaceMetrix.organic_rank.value as number | null,
                      variable_importance: null
                  }
              )
          })
        : undefined;
};
export const formatDetails = (
    modelScoreDetails?: ModelScoreDetailsResponseInterface,
    performaceMetrix?: PerformanceMetricsResponseInterface
): ModelScoreDetailsResponseInterface | undefined => {
    return !isUndefined(modelScoreDetails) && !isUndefined(performaceMetrix) && keys(performaceMetrix).length
        ? assign({}, modelScoreDetails, {
              organic_rank: assign(
                  {},
                  {
                      organic_rank_bucket: performaceMetrix.organic_rank.organic_rank_bucket
                  }
              )
          })
        : undefined;
};

export const listSubCategories = (parameters: ParameterInterface[], subcategories: FormattedSubCategories[]): FormattedSubCategories[] => {
    return uniqBy(
        sortBy(
            map(parameters, (p: ParameterInterface, key: number) => {
                const categoryCurrentState = find(subcategories, (list: FormattedSubCategories) => {
                    return list.param_sub_category === p.param_sub_category;
                });
                return {
                    id: key + 1,
                    param_sub_category: p.param_sub_category,
                    isOpen: categoryCurrentState ? categoryCurrentState.isOpen : false
                };
            }),
            ["sub_category_rank"]
        ),
        "param_sub_category"
    );
};

export const getSelectedParameters = (parameter: ParameterInterface[]): ParameterInterface[] => {
    if (parameter && parameter.length) {
        return map(parameter, (param: ParameterInterface) => ({
            isOpen: false,
            ...param
        }));
    }
    return [];
};

export const filterChangedParameters = (
    simulatorBeta: SimulatorReducerInterface,
    selectedCategory: CategoryType
): ChangedParameterInterface => {
    return selectedCategory === CategoryType.AUTHORITY
        ? simulatorBeta.authority.changed_parameters
        : selectedCategory === CategoryType.TECHNICAL
        ? simulatorBeta.technical.changed_parameters
        : {};
};

export const getParameterValidation = (simulatorBeta: SimulatorReducerInterface, selectedCategory: CategoryType) => {
    return selectedCategory === CategoryType.AUTHORITY
        ? simulatorBeta.authority.validation
        : selectedCategory === CategoryType.TECHNICAL
        ? simulatorBeta.technical.validation
        : {};
};

export const findSelectedParameter = (parameter: ParameterInterface[], selectedParameter: string): ParameterInterface => {
    return find(parameter, (p: ParameterInterface) => p.parameter === selectedParameter) as ParameterInterface;
};

export const findCategoryParameter = (simulatorBeta: SimulatorReducerInterface, selectedCategory: CategoryType): ParameterInterface[] => {
    return selectedCategory === CategoryType.AUTHORITY
        ? simulatorBeta.authority.parameters
        : selectedCategory === CategoryType.TECHNICAL
        ? simulatorBeta.technical.parameters
        : [];
};
export const filterForcedChangedParameters = (
    simulatorBeta: SimulatorReducerInterface,
    selectedCategory: CategoryType
): ChangedParameterInterface => {
    return selectedCategory === CategoryType.AUTHORITY
        ? simulatorBeta.authority.forced_changed_parameters
        : selectedCategory === CategoryType.TECHNICAL
        ? simulatorBeta.technical.forced_changed_parameters
        : {};
};

export const formatModelScoreSummary = createSelector(formatScoreSummary, (score?: ModelScoreContentResponseInterface) => score);

export const formatModelScoreDetails = createSelector(formatDetails, (details?: ModelScoreDetailsResponseInterface) => details);

export const getSubCategories = createSelector(listSubCategories, (subcategories: FormattedSubCategories[]) => subcategories);

export const getParameters = createSelector(getSelectedParameters, (parameters: ParameterInterface[]) => parameters);

export const getChangedParameters = createSelector(filterChangedParameters, (parameters: ChangedParameterInterface) => parameters);

export const getForcedChangedParameters = createSelector(
    filterForcedChangedParameters,
    (parameters: ChangedParameterInterface) => parameters
);

const getFilteredParams = (parameter: ParameterInterface[], appliedFiltersOnTechAuth: AppliedFilterSortInterface): ParameterInterface[] => {
    if (parameter.length > 0) {
        const parameterList = parameter;

        const filteredCategory: ParameterInterface[] =
            !isUndefined(appliedFiltersOnTechAuth.filter) && appliedFiltersOnTechAuth.filter.length > 0
                ? filter(parameterList, (param: ParameterInterface) => {
                      return (
                          !isUndefined(appliedFiltersOnTechAuth.filter) &&
                          appliedFiltersOnTechAuth.filter.indexOf(param.variable_importance) > -1
                      );
                  })
                : parameterList;

        const sortedParameter: ParameterInterface[] = getAuthTechSortedCategory(filteredCategory, appliedFiltersOnTechAuth.sort);
        return sortedParameter;
    }
    return [];
};

export const getFilteredParameters = createSelector(getFilteredParams, (params: ParameterInterface[]) => params);
export const getValidationStatus = createSelector(
    getParameterValidation,
    (paramValidaiton: ParameterValidationInterface) => paramValidaiton
);
export const findParameter = createSelector(findSelectedParameter, (parameter: ParameterInterface) => parameter);

export const selectedCategoryParameter = createSelector(findCategoryParameter, (parameter: ParameterInterface[]) => parameter);
export const availableappliedSortFilters = (
    appliedSortFilters: AppliedFilterSortInterface,
    availableFilters: string[]
): AppliedFilterSortInterface => {
    if (appliedSortFilters.filter.length === 0) {
        appliedSortFilters.filter = [...availableFilters];
    }
    return appliedSortFilters;
};

export const appliedSortFiltersList = createSelector(availableappliedSortFilters, (sortFilters: AppliedFilterSortInterface) => sortFilters);
// To fetch impacted keywords from state
const getImpactedKeywordsInfo = (state: ApplicationStateInterface): ImpactedKeywordsResponseInterface[] =>
    state.simulatorBeta.impactedKeywords || [];
export const getProcessedKeywords = createSelector(getImpactedKeywordsInfo, (list: ImpactedKeywordsResponseInterface[]) => list);
// To check if track request is in progress
const checkTrackRequestStatus = (state: ApplicationStateInterface): boolean =>
    state.multiKeywordBeta.enableSimulatePerformance && state.app.serpProcessedStatus === SerpListProgressStatusType.DONE;
export const getTrackRequestStatus = createSelector(checkTrackRequestStatus, (flag: boolean) => flag);

// To fetch sid to save competitors score
const fetchSidToSaveCompetitorScore = (state: ApplicationStateInterface): string => state.simulatorBeta.sidToSaveCompetitorScore;
export const getSidToSaveCompetitorScore = createSelector(fetchSidToSaveCompetitorScore, (sid: string) => sid);

// To get page content
const extractPageContent = (state: ApplicationStateInterface): EditorElementInterfaceBeta[] => state.simulatorBeta.content.pageContent;
export const getPageContent = createSelector(extractPageContent, (editorElements: EditorElementInterfaceBeta[]) => editorElements);

// To get changed page content
const extractPageChangedContent = (state: ApplicationStateInterface): EditorElementInterfaceBeta[] =>
    state.simulatorBeta.content.changedPageContent || [];
export const getPageChangedContent = createSelector(
    extractPageChangedContent,
    (editorElements: EditorElementInterfaceBeta[]) => editorElements
);

const extractTimesCompetitiorSseRetried = (state: ApplicationStateInterface): number => {
    return state.simulatorBeta.competitorScoreSSERetriedAttempt;
};
export const getCompetitorScoreSSeRetriedAttempts = createSelector(
    extractTimesCompetitiorSseRetried,
    (retriedTimes: number) => retriedTimes
);
const extractKeywordScoreResponse = (state: ApplicationStateInterface): UrlKeywordsScoreResponseInterface[] => {
    return state.simulatorBeta.content.urlKeywordsScore as UrlKeywordsScoreResponseInterface[];
};
export const getKeywordScore = createSelector(
    extractKeywordScoreResponse,
    (urlKeywordsScore: UrlKeywordsScoreResponseInterface[]) => urlKeywordsScore
);
