import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ALPS_LOCAL_STORAGE_KEY, COPY } from "../app/const";
import { LoadingIcon } from "../app/styledComponents";
import { LoadingText } from "../app/styledComponents/appLoader";
import { StyledLoadingIcon } from "../app/styledComponents/loading-icon";
import { callToGetTenantList } from "./ducks/actions";
import ExpirePopup from "./components/expiration-popup";
import { ApplicationStateInterface } from "../app/duck/types";
import { getBaseURL, getUserId } from "../app/duck/utils";
import { UserDetailsInterface } from "./ducks/types";

export const StyledAutoLoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
`;

export const StyledAutoLoginLoader = styled.div`
    ${StyledLoadingIcon} {
        display: block;
        width: 56px;
        height: 56px;
        margin: 20px auto;
    }
`;
const AutoLogin = () => {
    const dispatch = useDispatch();
    const [isSessionExpired, userDetails] = useSelector((state: ApplicationStateInterface) => [
        state.autoLogin.isSessionExpired,
        state.autoLogin.userDetails
    ]);

    React.useEffect(() => {
        localStorage.removeItem("userDetails");
        const payload = {
            id: getUserId()
        };
        dispatch(callToGetTenantList(payload));
    }, []);

    React.useEffect(() => {
        const user = localStorage.getItem("userDetails");
        if (user) {
            setCookies(JSON.parse(user));
        }
    }, [userDetails]);

    const setCookies = (user: UserDetailsInterface) => {
        const cookies = {
            search_engine: "google",
            default_project: null,
            user_id: user.user_id,
            locale: "en-us",
            has_project: null,
            tenant_code: user.tenant_code,
            user_email: user.user_email,
            project_id: null,
            isStandardProject: false,
            isMobileProject: false,
            projectName: "",
            featureAddOns: ["dod", "prt"],
            projectID: null,
            tenant_list: user.tenant_list
        };

        // setting cookies
        document.cookie = `${"__iQ_alps"} = ${JSON.stringify(cookies)}; ${"max-age"} = ${30}; ${"path"} = ${"/"};`;

        localStorage.setItem(ALPS_LOCAL_STORAGE_KEY, JSON.stringify(cookies));
        localStorage.setItem("tenant_list", JSON.stringify(user.tenant_code));
        window.location.replace(getBaseURL("content-brief"));
    };
    return (
        <>
            <StyledAutoLoginContainer>
                <StyledAutoLoginLoader>
                    <LoadingIcon id={`pagination_spinner`} />
                </StyledAutoLoginLoader>
                <LoadingText id="pagination_title" marginBottom="5px">
                    {COPY.LOADING_LABEL}
                </LoadingText>
            </StyledAutoLoginContainer>
            {isSessionExpired && <ExpirePopup />}
        </>
    );
};

export default AutoLogin;
