import { ModelScoredDropdownKeysType } from "../../../../../../app/styledComponents/drop-down/types";
import { RecommendationConstInterface } from "./types";

export const RECOMMENDATION_SCORE_TAB_ID: number = 0;
export const RECOMMENDATION_CONTENT_TOP_RANKING_PAGES_TAB_ID: number = 1;
export const RecommendationConst: RecommendationConstInterface = {
    NON_SCROES_MSG: "Please click on ‘Update Score and Predicted Rank’ to fetch the scores",
    RECOMMENDATION_NO_DATA_FOR_DROPDOWN: "No phrase recommendations for this set of keyword(s)",
    RECOMMENDATION_NO_DATA_TEXT: "We are unable to fetch recommendations right now, please try after sometime or contact us at ",
    SHOW_MORE: "Show More",
    NO_PHRASE_AVAILABLE: "No more Phrases available",
    CONTENT_RECOMMENDATION_TITLE_TAG: "Title",
    CONTENT_RECOMMENDATION_H1_TAG: "H1",
    RANK_TEXT: "Rank",
    TARGET_META_SCORE: "Meta Description Score",
    TARGET_BODY_SCORE: "Body Content Score",
    TARGET_SCORE_DYNAMIC_LABEL: "{{SELECTED_TAG}} Score",
    AVG_TOP_RANKING_SCORE: "Avg. Top 3 Ranking Score",
    VIEW_RECOMMENDATION: "View Recommendation",
    VR_SELECT_PHRASE_TYPE: "Select Phrase type",
    VR_RELEVANCE_SCORES: "Relevance Scores",
    TARGET_PAGE_TEXT: "Target Page",
    TOP_3_PAGE_TEXT: "Top 3 Page",
    FREQUENCY_MESSAGE: "This represents the average frequency of three high-ranking competitor pages.",
    RELEVANCE_TEXT: "Relevance",
    SCORE_TEXT: "Score",
    SCORES_TAB_LABEL: "Scores",
    PHRASE_USAGE: "Phrase Usage",
    RANKING_PAGES: "Ranking Pages",
    SIMULATOR_VERSION_HISTORY: "Version History",
    ALL_TEXT: "All",
    CONTENT_SIMULATOR_SUMMARY: "Summary:",
    CONTENT_SIMULATOR_AVG_RANK: "Avg. Rank:",
    CONTENT_SIMULATOR_TRAFFIC: "Traffic",
    CONTENT_SIMULATOR_RUN_SIMULATION_CONTENT: "Please edit content first",
    CONTENT_SIMULATOR_RUN_SIMULATION_AUTHORITY: "Please edit backlink details",
    CONTENT_SIMULATOR_RUN_SIMULATION_TECHNICAL: "Please edit the web vitals",
    CONTENT_SIMULATION_REFRESH_BUTTON_LABEL: "Update Score and Predicted Rank >",
    UNIGRAM_WORDS: "Unigrams",
    FREQUENCY: "Frequency",
    BIGRAM_WORDS: "Bigrams",
    TRIGRAM_WORDS: "Trigrams",
    PHRASES_TO_USE_LABEL: "Phrases to Use",
    KEYWORDS_TEXT: "Keywords",
    MULTI_KEYWORD_KEYWORD_FETCH_LOADER_TITLE: "Fetching data",
    MULTI_KEYWORD_KEYWORD_FETCH_LOADER_MESSAGE: "Keywords Processed",
};

export const viewRecommendationPhrasesHeading = [
    { label: "All", value: "1" },
    { label: "Trigrams", value: "2" },
    { label: "Bigrams", value: "3" },
    { label: "Unigrams", value: "4" }
];

export const SCORE_DROPDOWN_DETAIL: ModelScoredDropdownKeysType[] = [
    { site: "URL score", keyName: "url_score" },
    { site: "Title score", keyName: "title_score" },
    { site: "Meta Description score", keyName: "meta_description_score" },
    { site: "H1 score", keyName: "h1_score" },
    { site: "H2 score", keyName: "h2_score" },
    { site: "H3 score", keyName: "h3_score" },
    { site: "Body Content score", keyName: "visible_content_score" },
    { site: "Readability Grade", keyName: "readability_grade" },
    { site: "Content", keyName: "category_scores" },
    { site: "Rank", keyName: "organic_rank" },
    { site: "Traffic", keyName: "traffic" }
];

export const ScoreSummary = {
    Keywords: "Keywords",
    Rank: "Rank",
    ContentScore: "Content Scores",
    AllKeywords: "All Keywords (Aggregated)"
};

export const KEYWORD = "keyword";
