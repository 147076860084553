import * as React from "react";
import styled from "styled-components";
import { MessageInterface, MessageType, StyledComponentStylesType } from "../../duck/types";
import { closeGrey, success, warning, error, infoIcon } from "../../icons";
import Clearall from "../clearall";
import LoadingIcon from "../loading-icon";

export interface ToastStylePropsInterface {
    ToastContainer?: StyledComponentStylesType;
    ToastMessage?: StyledComponentStylesType;
    ToastIcon?: StyledComponentStylesType;
    CloseIcon?: StyledComponentStylesType;
}
export interface MessagePropsInterface {
    messages: MessageInterface[];
}
export interface MessageStyledTypeProps {
    messageType: MessageType;
}
export type MessageTypeColorInterface = {
    [key in MessageType]: string;
};

export interface ToastPropsInterface extends MessageInterface {
    id: number;
    dissolveTime: number;
    showProcessingLoader?: boolean;
    styles?: ToastStylePropsInterface;
    className?: string;
    onRemove(): void;
}

const StyledCommonToastContainer = styled.div`
    width: 350px;
    min-height: 36px;
    position: relative;
    padding: 10px 0;
    background: transparent;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid blue;
    margin-bottom: 10px;
`;
const MessageBackGroundColorCode: MessageTypeColorInterface = {
    [MessageType.SUCCESS]: `#def9ea`,
    [MessageType.WARNING]: `#fff2d9`,
    [MessageType.INFO]: `#e0eaf8`,
    [MessageType.ERROR]: `#fee4db`
};

const MessageBorderColorCode: MessageTypeColorInterface = {
    [MessageType.SUCCESS]: `#53d68a`,
    [MessageType.WARNING]: `#ffa501`,
    [MessageType.INFO]: `#3a85ef`,
    [MessageType.ERROR]: `#fb764b`
};

export const StyledToastContainer = styled(StyledCommonToastContainer)`
    background: ${(props: MessageStyledTypeProps) => MessageBackGroundColorCode[props.messageType]};
    border: 1px solid ${(props: MessageStyledTypeProps) => MessageBorderColorCode[props.messageType]};
    &.simulation-toast {
        background: #ffffff;
        border-radius: 3px;
        box-shadow: 0 0 6px 0 rgba(113, 112, 112, 0.38);
        border: none;
    }
    &.scoreBoard {
        width: 213px;
        margin: 3px;
        box-sizing: border-box;
        padding: 5px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

const StyledToastLeftBorder = styled.span`
    display: block;
    float: left;
    margin: 0px 6px 0px 3px;
    width: 2px;
    background: 2px;
    background: blue;
    position: absolute;
    top: 7px;
    bottom: 7px;
`;

const StyledLeftBorder = styled(StyledToastLeftBorder)`
    background: ${(props: MessageStyledTypeProps) => MessageBorderColorCode[props.messageType]};
`;

export const StyledToastIcon = styled.i`
    width: 16px;
    height: 16px;
    float: left;
    background: #fff;
    border-radius: 50%;
    margin-left: 10px;
`;

export const StyledProcessingLoaderContainer = styled.div`
    width: fit-content;
    position: absolute;
    right: 7px;
    bottom: 9px;
`;

export const StyledTypeIcon = styled(StyledToastIcon)`
    background: url(${(props: MessageStyledTypeProps) =>
        props.messageType === MessageType.SUCCESS
            ? success
            : props.messageType === MessageType.ERROR
                ? error
                : props.messageType === MessageType.INFO
                    ? infoIcon
                    : warning})
        no-repeat;
    &.scoreBoard {
        background: url(${infoIcon}) no-repeat;
        background-size: contain;
        background-position: center;
        width: 22px;
        height: 18px;
    }
    &.recommendation {
        background: url(${infoIcon}) no-repeat;
        background-size: contain;
        background-position: center;
        width: 23px;
        height: 18px;
    }
`;

export const StyledToastMessageText = styled.span`
    width: 280px;
    font-size: 12px;
    color: #343434;
    float: left;
    line-height: 16px;
    margin-left: 10px;
    word-wrap: break-word;
    &.scoreBoard {
        width: 165px;
        margin-left: 0px;
        font-size: 10px;
    }
`;

export const StyledToastClose = styled.i`
    cursor: pointer;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    top: 10px;
    background: url(${closeGrey}) no-repeat;
    background-position: top right;
    &.scoreBoard {
        right: 8px;
        top: 15px;
    }
`;
const StyledToastMessageTitle = styled.span`
    word-wrap: break-word;
    opacity: 0.9;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #394961;
    margin-left: 10px;
    word-wrap: break-word;
`;

const StyledToastMessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledToastLinkWrapper = styled.span`
    font-size: 12px;
    margin-top: 5px;
    margin-left: 10px;
    color: #00acce;
    text-decoration: underline;
    cursor: pointer;
`;

const StyledToastMessage = styled.div`
    display: flex;
    flex-direction: column;
`;

const Toast: React.SFC<ToastPropsInterface> = ({
    className = "",
    id,
    type,
    title,
    message,
    autoClose,
    showProcessingLoader,
    dissolveTime,
    styles = {},
    linkText,
    onClickLinkText,
    onRemove
}) => {
    /* const removeToast = React.useRef();
    removeToast.current = onRemove;
    const {
        ToastContainer = StyledToastContainer,
        ToastMessage = StyledToastMessageText,
        CloseIcon = StyledToastClose,
        ToastIcon = StyledTypeIcon
    } = styles; */
    React.useEffect(() => {
        if (autoClose) {
            setTimeout(onRemove, dissolveTime);
        }
        return;
    }, [id]);
    return (
        <StyledToastContainer className={className} messageType={type} id={`${type}_toast`}>
            <StyledLeftBorder messageType={type} id={`${type}_toast_highlight`} />
            <StyledTypeIcon className={className} messageType={type} id={`${type}_toast_icon`} />
            <StyledToastMessageWrapper>
                {title && <StyledToastMessageTitle>{title}</StyledToastMessageTitle>}
                <StyledToastMessage id={`${type}_toast_msg_text`}>
                    <StyledToastMessageText className={className} id={`${type}_toast_message`}>
                        {message}
                    </StyledToastMessageText>
                    {linkText && <StyledToastLinkWrapper id={`${type}_link_text`} onClick={onClickLinkText}>{linkText}</StyledToastLinkWrapper>}
                </StyledToastMessage>
            </StyledToastMessageWrapper>
            {showProcessingLoader ? (
                <StyledProcessingLoaderContainer>
                    <LoadingIcon />
                </StyledProcessingLoaderContainer>
            ) : (
                <StyledToastClose className={className} onClick={onRemove} id={`${type}_toast_remove_icon`} />
            )}
            <Clearall />
        </StyledToastContainer>
    );
};
const MemoizedToast = React.memo(Toast);

export default MemoizedToast;
