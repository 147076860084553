import { call, put, takeLatest } from "redux-saga/effects";
import actionTypes, {
    setIsEmbedTokenFetchSuccess,
    setTableauEmbedConfig
} from "./actions";
import { AnyAction } from "redux";
import { has } from "lodash";
import Apis from "../../app/apis";
import { getContext } from "../../app/duck/context-container";
import { setTableauAuthDetails } from "./utils";

// tslint:disable-next-line:no-any
export function* fetchTableauEmbedToken(action: AnyAction): any {
    const { tenantCode = "", userId = "" } = getContext();
    const response = yield call(Apis.getTableauEmbedToken, { tenantCode: tenantCode as string, userId: userId as string });

    if (response.status === 200 && !has(response.data, "error")) {
        const { jwt_token, embed_url, is_publisher } = response.data;
        yield put(
            setTableauEmbedConfig({
                accessToken: jwt_token,
                embedUrl: embed_url,
                isPublisher: is_publisher
            })
        );
        yield put(setIsEmbedTokenFetchSuccess(true));
        setTableauAuthDetails({ embedUrl: embed_url, isPublisher: is_publisher });
    } else {
        yield put(setIsEmbedTokenFetchSuccess(false));
    }
}


// tslint:disable-next-line:typedef
export function* watchFetchTableauEmbedToken() {
    yield takeLatest(actionTypes.GET_TABLEAU_EMBED_TOKEN, fetchTableauEmbedToken);
}
