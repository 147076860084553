import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */
const ActionTypes = {
    GET_PERFORMANCE_IMPACT_REPORTS_LIST: "GET_PERFORMANCE_IMPACT_REPORTS_LIST",
    SET_PERFORMANCE_IMPACT_REPORTS_LIST: "SET_PERFORMANCE_IMPACT_REPORTS_LIST",
    APPEND_TO_PERFORMANCE_IMPACT_REPORTS_LIST: "APPEND_TO_PERFORMANCE_IMPACT_REPORTS_LIST",
    SET_NEW_REPORT_FORM_DATA: "SET_NEW_REPORT_FORM_DATA",
    CLEAR_NEW_REPORT_FORM_DATA: "CLEAR_NEW_REPORT_FORM_DATA",
    SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
    SET_REPORT_ERROR_MESSAGE: "SET_REPORT_ERROR_MESSAGE",
    ADD_PERFORMANCE_IMPACT_REPORT: "ADD_PERFORMANCE_IMPACT_REPORT",
    SET_IS_ADD_REPORT_IN_PROGRESS: "SET_IS_ADD_REPORT_IN_PROGRESS",
    SET_IS_ADD_REPORT_SUCCESS: "SET_IS_ADD_REPORT_SUCCESS",
    GET_PERFORMANCE_IMPACT_REPORT_DETAILS: "GET_PERFORMANCE_IMPACT_REPORT_DETAILS",
    SET_PERFORMANCE_IMPACT_REPORT_DETAILS: "SET_PERFORMANCE_IMPACT_REPORT_DETAILS",
    GET_PERFORMANCE_IMPACT_REPORT_RANKING_KEYWORDS: "GET_PERFORMANCE_IMPACT_REPORT_RANKING_KEYWORDS",
    SET_PERFORMANCE_IMPACT_REPORT_RANKING_KEYWORDS: "SET_PERFORMANCE_IMPACT_REPORT_RANKING_KEYWORDS",
    GET_PERFORMANCE_IMPACT_REPORT_GSC_KEYWORDS: "GET_PERFORMANCE_IMPACT_REPORT_GSC_KEYWORDS",
    SET_PERFORMANCE_IMPACT_REPORT_GSC_KEYWORDS: "SET_PERFORMANCE_IMPACT_REPORT_GSC_KEYWORDS",
    GET_PERFORMANCE_IMPACT_REPORT_GSC_SUMMARY: "GET_PERFORMANCE_IMPACT_REPORT_GSC_SUMMARY",
    SET_PERFORMANCE_IMPACT_REPORT_GSC_SUMMARY: "SET_PERFORMANCE_IMPACT_REPORT_GSC_SUMMARY",
    CLEAR_PERFORMANCE_IMPACT_REPORT_DATA: "CLEAR_PERFORMANCE_IMPACT_REPORT_DATA",
};
export default ActionTypes;

// GETTERS / API CALL ACTIONS
export const getPerformanceImpactReportsList = createAction(ActionTypes.GET_PERFORMANCE_IMPACT_REPORTS_LIST);
export const setPerformanceImpactReportsList = createAction(ActionTypes.SET_PERFORMANCE_IMPACT_REPORTS_LIST);
export const appendToPerformanceImpactReportsList = createAction(ActionTypes.APPEND_TO_PERFORMANCE_IMPACT_REPORTS_LIST);
export const setNewReportFormData = createAction(ActionTypes.SET_NEW_REPORT_FORM_DATA);
export const clearNewReportFormData = createAction(ActionTypes.CLEAR_NEW_REPORT_FORM_DATA);
export const setErrorMessage = createAction(ActionTypes.SET_ERROR_MESSAGE);
export const setReportErrorMessage = createAction(ActionTypes.SET_REPORT_ERROR_MESSAGE);
export const addPerformanceImpactReport = createAction(ActionTypes.ADD_PERFORMANCE_IMPACT_REPORT);
export const setIsAddReportInProgress = createAction(ActionTypes.SET_IS_ADD_REPORT_IN_PROGRESS);
export const setIsAddReportSuccess = createAction(ActionTypes.SET_IS_ADD_REPORT_SUCCESS);
export const getPerformanceImpactReportDetails = createAction(ActionTypes.GET_PERFORMANCE_IMPACT_REPORT_DETAILS);
export const setPerformanceImpactReportDetails = createAction(ActionTypes.SET_PERFORMANCE_IMPACT_REPORT_DETAILS);
export const getPerformanceImpactReportRankingKeywords = createAction(ActionTypes.GET_PERFORMANCE_IMPACT_REPORT_RANKING_KEYWORDS);
export const setPerformanceImpactReportRankingKeywords = createAction(ActionTypes.SET_PERFORMANCE_IMPACT_REPORT_RANKING_KEYWORDS);
export const getPerformanceImpactReportGSCKeywords = createAction(ActionTypes.GET_PERFORMANCE_IMPACT_REPORT_GSC_KEYWORDS);
export const setPerformanceImpactReportGSCKeywords = createAction(ActionTypes.SET_PERFORMANCE_IMPACT_REPORT_GSC_KEYWORDS);
export const getPerformanceImpactReportGSCSummary = createAction(ActionTypes.GET_PERFORMANCE_IMPACT_REPORT_GSC_SUMMARY);
export const setPerformanceImpactReportGSCSummary = createAction(ActionTypes.SET_PERFORMANCE_IMPACT_REPORT_GSC_SUMMARY);
export const clearPerformanceImpactReportData = createAction(ActionTypes.CLEAR_PERFORMANCE_IMPACT_REPORT_DATA);

