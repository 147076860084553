import React from "react";
import { closeGrey } from "../../app/icons";
import { COPY } from "../../app/const";

import {
    StyledGradientButtonText,
    StyledGradientButton,
    StyledReqOverlay,
    StyledReqTitle,
    StyledReqDIVBtn,
    StyledReqButton,
    StyledReqHeader,
    StyledReqContainer,
    StyledErrorMsg,
    StyledReqBody
} from "../../beta/home/styledHome";
import { getBaseURL } from "../../app/duck/utils";

interface ErrorPopupInterface {
    title?: string;
    points?: string[];
    closeHandler?(arg: boolean): void;
}

const ExpirePopup: React.SFC<ErrorPopupInterface> = () => {
    return (
        <StyledReqOverlay>
            <StyledReqContainer className="error-popup">
                <StyledReqHeader className="error-popup">
                    <StyledReqButton href={getBaseURL("login")}>
                        <img src={closeGrey} className="close-icon" id="notification_close_icon" style={{ width: "15px" }} />
                    </StyledReqButton>
                </StyledReqHeader>
                <StyledReqBody>
                    <StyledReqTitle className="error-popup">{COPY.TRIAL_EXPIRED}</StyledReqTitle>
                    <StyledErrorMsg>{COPY.EXPIRED_MESSAGE}</StyledErrorMsg>
                    <StyledReqDIVBtn className="error-popup">
                        <StyledGradientButton className="popup" href={process.env.REQUEST_DEMO_URL} target="_blank">
                            <StyledGradientButtonText>{COPY.CONTACT_US}</StyledGradientButtonText>
                        </StyledGradientButton>
                    </StyledReqDIVBtn>
                </StyledReqBody>
            </StyledReqContainer>
        </StyledReqOverlay>
    );
};

export default ExpirePopup;
