import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */

const actionTypes = {
    CALL_TO_DOD_SUBMIT_API: "CALL_TO_DOD_SUBMIT_API",
    IS_DOD_REQEST_PROCESSING: "IS_DOD_REQEST_PROCESSING",
    IS_DOD_SUBMISSION_SUCCESS: "IS_DOD_SUBMISSION_DONE",
    SET_DOD_ERROR_CODE: "SET_DOD_ERROR_CODE"
};
export default actionTypes;

export const isDodReqProcessing = createAction(actionTypes.IS_DOD_REQEST_PROCESSING);
export const isDodSubmissionSuccess = createAction(actionTypes.IS_DOD_SUBMISSION_SUCCESS);
export const setDodErrorCode = createAction(actionTypes.SET_DOD_ERROR_CODE);
