import { chipReverse, humana } from "../../../app/icons";
import { DisplayCarouselInterface } from "./UpgradeNowComponent/upgrade-right-panel";

export interface UpdateNowConstInterface {
    [key: string]: string;
}

export const FEATURE_DETAILS = [
    {
        heading: "Content Optimization",
        subheading: ["Content Scoring", "AI Content Generation", "Optimize Published Pages", "New Content Optimization", "Rank and Traffic Forecast"]
    },
    {
        heading: "Page Assessment",
        subheading: ["Multi Keyword Analysis", "SEO Recommendations", "SEO Health Scores"]
    },
    {
        heading: "Keyword Research",
        subheading: ["Keyword Suggestions", "Keyword Relevance Score", "Search Volume", "Advanced Keyword Filters"]
    },
    {
        heading: "Competitive Analysis",
        subheading: ["Score Gap Analysis", "Competitior's Content Outline", "Competitor's Backlink Summary", "Competitior's Core Web Vitals"]
    },
    {
        heading: "Authority Assessment",
        subheading: ["Backlink Summary", "Anchor Text Relevance Scores", "Comparison with Top Ranking Pages", "Backlink Impact on Ranks"]
    },
    {
        heading: "Core Web Vitals Assessment",
        subheading: ["Page Core Web Vitals", "Comparison with Top 3 Scores", "Core Web Vitals Change Forecast"]
    },
    {
        heading: "Support",
        subheading: ["1 hour live orientation Session", "email Support - 24 hour SLA"]
    }
];

export const FAQ_LEFT_PANEL = [
    {
        question: "Can I cancel my subscription anytime?",
        answer: "Yes. By default, the subscription is set to recur, but once you cancel, you won't be billed for the following months. However, once billed for a month, the amount cannot be reverted."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept all major credit cards like Visa, Mastercard, American Express, etc. Also, all major payment platforms like PayPal, Apple Pay, etc."
    },
    {
        question: "What is included in the individual plan? ",
        answer: "Our individual plan is recommended to optimize about 10 pages with 5 target keywords per page in a month."
    },
    {
        question: "Do you offer professional services or implementation support? ",
        answer: "We offer professional services. Email us at support@alps.ai with your specific requirements and we will get back within 24 hours."
    }
];

export const FAQ_RIGHT_PANEL = [
    {
        question: "What is ALPS' refund policy? ",
        answer: "No refunds will be issued once a purchase has been charged. We recommend using the free trial of the product to make sure it meets your needs before committing to the purchase. However, you can cancel anytime for the following months."
    },
    {
        question: "What is the pricing currency? ",
        answer: "We accept payments in United States Dollars (USD)."
    },
    {
        question: "What happens if I exceed the usage limits of my plan? ",
        answer: "If you exceed your plan's usage limit, reach out to us at support@alps.ai to explore your options for additional usage."
    },
    {
        question: "What are the available support options?",
        answer: "For Support, you can email us at support@alps.ai, and we will get back to you within 24 hours."
    }
];


export const UPDATENOW: UpdateNowConstInterface = {
    PLAN: "Individual Plan",
    AMOUNT: "$99",
    MONTH: "/month",
    OPTIMIZE_PAGE: "(Optimizing up 10 Pages/month)",
    EXPIRE_USER_MSG: "Your 7 day Free trial has ended, Upgrade to Individual Plan",
    FREE_TRIAL_END_ON: "Free Trial Ending on",
    UPGRADE_TO_INDIVIDUAL_PLAN: "Upgrade to Individual Plan",
    JUST_$99: "JUST $99",
    FAQ: "FAQs",
    TRUSTED_BY_WORL_LEADING_BRAND: "Trusted by world’s leading brands",
    MONTHLY_RECURRING_FEE: "(Monthly Recurring Fee, Cancel Anytime)",
    PAY_$99: "Pay $99",
    NO_THANKS: "No Thanks",
    LOSE_ALPS_ACCESS: "(You will lose ALPS access post free trial period)",
    CONGRATULATIONS_TEXT: "Congratulations!",
    SUCESS_MSG_1: "As an early ALPS adopter, we are offering you a complimentary ",
    SUCESS_MSG_2: "1-month",
    SUCESS_MSG_3: " extension to our content simulation tool, which is valued at ",
    SUCESS_MSG_4: "$99",
    SUCESS_MSG_5: "Please",
    SUCESS_MSG_6: "again to continue using ALPS.",
    OUR_CLIENT_LOVE_ALPS: "Our clients love ALPS"
};

export const TESTIMONIAL: DisplayCarouselInterface[] = [
    {
        company: "Humana",
        image_src: humana ,
        description: "“ALPS has helped our organization build a stronger connection between SEO and content teams.  Using the page simulation feature to visualize impacts of content optimization, we’ve produced better performing content and increased team engagement.”",
        footer: "Molly M. Maxima | Digital Channels Search"
    },
    {
        company: "CHIPReverseMortage",
        image_src: chipReverse,
        description: "“ALPS has saved us countless hours and resources developing content that won’t move the needle from an SEO perspective. ALPS has allowed us to defend our search position in an increasingly competitive environment, as well as help us efficiently develop new content and tools that drive customer engagement”",
        footer: "Joe Heale  | CHIPReverseMortage"
    }
];
