import { ApiConfig } from "../../../app/config/apiConfig";
import { ApiPath } from "../../../app/config/apiPath";

export const callToFetchSubscriptionPlan = (tenantCode: string, subPlan: string, currPlan: string| undefined) => {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            sub_plan: subPlan,
            curr_plan: currPlan
        }),
        credentials: "include" as RequestCredentials
    };
    return fetch(ApiConfig.baseURL + ApiPath.UPDATE_SUBSCRIPTION_API_URL.replace("{{tenant_code}}", tenantCode), requestOptions)
        .then((response) => {
            return false;
        })
        .catch(() => {
            return false;
        });
};
