import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */
const actionTypes = {
    CLEAR_SELECTIVE_STATE: "CLEAR_SELECTIVE_STATE_BETA",
    GET_DOWNLOAD_KEYWORD_REPORT: "GET_DOWNLOAD_KEYWORD_REPORT_BETA",
    GET_DOWNLOAD_URL_REPORT: "GET_DOWNLOAD_URL_REPORT_BETA",
    GET_KEYWORD_URL: "GET_KEYWORD_URL_BETA",
    GET_PARAMETERDETAIL: "GET_PARAMETERDETAIL_BETA",
    GET_TOP_ORGANIC_URL: "GET_TOP_ORGANIC_URL_BETA",
    GET_URL_SCORE: "GET_URL_SCORE_BETA",
    INIT_REQUEST: "INIT_REQUEST",
    ON_INPUT_CHANGE: "ON_INPUT_CHANGE_BETA",
    RESET_KWA: "RESET_KWA_BETA",
    SELECT_NEW_CATEGORY: "SELECT_NEW_CATEGORY_BETA",
    SET_APPLIED_FILTERS: "SET_APPLIED_FILTERS_BETA",
    SET_AVERAGE_SCORE: "SET_AVERAGE_SCORE_BETA",
    SET_DEFAULT_CATEGORY: "SET_DEFAULT_CATEGORY_BETA",
    SET_DOWNLOAD_REQUEST_PENDING_STATUS: "SET_DOWNLOAD_REQUEST_PENDING_STATUS_BETA",

    SET_DOMAIN: "SET_DOMAIN_BETA",
    SET_KEYWORD: "SET_KEYWORD_BETA",
    SET_KEYWORD_FETCHED_RESPONSE: "SET_KEYWORD_FETCHED_RESPONSE_BETA",
    SET_KEYWORD_SCORE: "SET_KEYWORD_SCORE_BETA",
    SET_LOCALE: "SET_LOCALE_BETA",
    SET_PARAMETERDETAIL: "SET_PARAMETERDETAIL_BETA",
    SET_SELECTED_URL: "SET_SELECTED_URL_BETA",
    SET_SORTING_OPTION: "SET_SORTING_OPTION_BETA",
    SET_TOP_ORGANIC_URL: "SET_TOP_ORGANIC_URL_BETA",
    SET_URL: "SET_URL_BETA",
    SET_URL_SCORE: "SET_URL_SCORE_BETA",
};
export default actionTypes;

export const getKeyWordUrl = createAction(actionTypes.GET_KEYWORD_URL);
export const getTopOrganicUrl = createAction(actionTypes.GET_TOP_ORGANIC_URL);
export const onInputChange = createAction(actionTypes.ON_INPUT_CHANGE);
export const setKeyWordAverageScores = createAction(
    actionTypes.SET_AVERAGE_SCORE
);
export const setDefaultCategory = createAction(
    actionTypes.SET_DEFAULT_CATEGORY
);
export const setDomain = createAction(actionTypes.SET_DOMAIN);
export const setKeyWordScores = createAction(actionTypes.SET_KEYWORD_SCORE);
export const setLocale = createAction(actionTypes.SET_LOCALE);
export const setTopOrganicUrl = createAction(actionTypes.SET_TOP_ORGANIC_URL);
export const setSelectedUrl = createAction(actionTypes.SET_SELECTED_URL);
export const setKeywordResponse = createAction(
    actionTypes.SET_KEYWORD_FETCHED_RESPONSE
);
export const setSearchedKeyWord = createAction(actionTypes.SET_KEYWORD);
export const setUrl = createAction(actionTypes.SET_URL);
export const onCategoryChanged = createAction(actionTypes.SELECT_NEW_CATEGORY);
export const resetKWASlice = createAction(actionTypes.RESET_KWA);
export const applyFilters = createAction(actionTypes.SET_APPLIED_FILTERS);
export const applySorting = createAction(actionTypes.SET_SORTING_OPTION);
export const initRequestForId = createAction(actionTypes.INIT_REQUEST);
export const clearSelectiveState = createAction(
    actionTypes.CLEAR_SELECTIVE_STATE
);
export const getSerpReports = createAction(
    actionTypes.GET_DOWNLOAD_KEYWORD_REPORT
);
export const downloadRequestInPending = createAction(
    actionTypes.SET_DOWNLOAD_REQUEST_PENDING_STATUS
);

export const getKeyWordUrlReports = createAction(
    actionTypes.GET_DOWNLOAD_URL_REPORT
);
export const getParameterDetail = createAction(actionTypes.GET_PARAMETERDETAIL);
export const setParameterDetail = createAction(actionTypes.SET_PARAMETERDETAIL);


export const getUrlScore = createAction(actionTypes.GET_URL_SCORE);
export const setUrlScore = createAction(actionTypes.SET_URL_SCORE);
