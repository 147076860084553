import ServiceRequest from "../../app/services/index";
import { ApiPath, METHOD } from "../../app/config/apiPath";
import { ParserInterface } from "../../app/duck/types";
import { ApiConfig } from "../../app/config/apiConfig";

class Apis {
    public *getPerformanceImpactReportList(
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.GET_PERFORMANCE_IMPACT_REPORTS_LIST.replace("{{tenant_code}}", parser.tenantCode)
        };
        const scores = yield ServiceRequest.invoke(axiosParam);
        return scores;
    }

    public *addPerformanceImpactReport(
        parser: ParserInterface,
        payload: {
            url: string;
            locale: string;
            primary_keyword: string;
            secondary_keywords: string[];
        }
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...payload
            },
            method: METHOD.POST,
            url: ApiPath.GET_PERFORMANCE_IMPACT_REPORTS_LIST.replace("{{tenant_code}}", parser.tenantCode)
        };
        const scores = yield ServiceRequest.invoke(axiosParam);
        return scores;
    }

    public *getPerformanceImpactReportDetails(
        parser: ParserInterface,
        payload: { reportId: string, deviceType: string }
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.GET_PERFORMANCE_IMPACT_REPORT_DETAILS
                .replace("{{tenant_code}}", parser.tenantCode)
                .replace("{{report_id}}", payload.reportId)
                .replace("{{device_type}}", payload.deviceType)
        };
        const scores = yield ServiceRequest.invoke(axiosParam);
        return scores;
    }

    public *getPerformanceImpactReportRankingKeywords(
        parser: ParserInterface,
        payload: { reportId: string, deviceType: string }
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.GET_PERFORMANCE_IMPACT_REPORT_RANKING_KEYWORDS
                .replace("{{tenant_code}}", parser.tenantCode)
                .replace("{{report_id}}", payload.reportId)
                .replace("{{device_type}}", payload.deviceType)
        };
        const scores = yield ServiceRequest.invoke(axiosParam);
        return scores;
    }

    public *getPerformanceImpactReportGSCKeywords(
        parser: ParserInterface,
        payload: { reportId: string, deviceType: string }
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.GET_PERFORMANCE_IMPACT_REPORT_GSC_KEYWORDS
                .replace("{{tenant_code}}", parser.tenantCode)
                .replace("{{report_id}}", payload.reportId)
                .replace("{{device_type}}", payload.deviceType)
        };
        const scores = yield ServiceRequest.invoke(axiosParam);
        return scores;
    }

    public *getPerformanceImpactReportGSCSummary(
        parser: ParserInterface,
        payload: { reportId: string, deviceType: string }
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.GET_PERFORMANCE_IMPACT_REPORT_GSC_SUMMARY
                .replace("{{tenant_code}}", parser.tenantCode)
                .replace("{{report_id}}", payload.reportId)
                .replace("{{device_type}}", payload.deviceType)
        };
        const scores = yield ServiceRequest.invoke(axiosParam);
        return scores;
    }
}
export default new Apis();
