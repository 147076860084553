import { get } from "lodash";
import { GerLinkWithParamsInterface } from "./types";

interface ParamsMapperInterface {
    urlParam: string;
    mapKey: string;
}

export const getLinkWithParams = ({ to, context, isExternal }: GerLinkWithParamsInterface): string => {
    const paramsMapper: ParamsMapperInterface[] = [
        { urlParam: ":tenantCode", mapKey: "tenantCode" },
        { urlParam: ":locale", mapKey: "selectedLocale" },
        { urlParam: ":domain", mapKey: "selectedDomain" },
        { urlParam: ":keyword", mapKey: "selectedKeyword" },
    ];

    const url = paramsMapper.reduce((acc: string, param: ParamsMapperInterface): string => {
        const paramValue = get(context, param.mapKey);
        return acc.replace(param.urlParam, paramValue);
    }, to);
    if (isExternal) {
        return `${window.location.origin}${url}`;
        // return `http://alpsdev1.smallbizvoices.com${url}`;
    }
    return url;
};
