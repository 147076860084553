import { AnyAction } from "redux";
import { call, takeEvery, put } from "redux-saga/effects";
import actionTypes, { isCBProgressLoading, setCBProgress, setSaveContentBrief, setSaveContentBriefStatus } from "./actions";
import { ContentBriefKeywordPayloadInterface } from "./types";
import { getContext } from "../../app/duck/context-container";
import Apis from "../../app/apis";
import { contentBriefRequestErrors } from "../../app/const";
import { DownloadFilePayloadInterface } from "../../content-brief-view/ducks/types";

// tslint:disable-next-line: typedef
export function* addContentBriefKeywords(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const payload: ContentBriefKeywordPayloadInterface = {
        ...action.payload,
        needToHandleError: true,
        isSpecificError: true,
        errorList: contentBriefRequestErrors
    };
    const response = yield call(Apis.addContentBriefKeywords, payload, parser);
    if (response.status === 201) {
        yield put(setSaveContentBriefStatus(response.status));
        yield put(setSaveContentBrief(response.data));
    }
    return response;
}


// tslint:disable-next-line: typedef
export function* onGetContentBriefProgress(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const payload: DownloadFilePayloadInterface = {
        ...action.payload,
        needToHandleError: true,
        isSpecificError: true,
        errorList: contentBriefRequestErrors
    };
    const response = yield call(Apis.getContentBriefProgress, payload, parser);
    if (response.status === 200) {
        yield put(isCBProgressLoading(true));
        yield put(setCBProgress(response.data.progress));
    }
    return response;
}


// tslint:disable-next-line: typedef
export function* onUpdateContentBrief(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const payload: ContentBriefKeywordPayloadInterface = {
        ...action.payload,
        needToHandleError: true,
        isSpecificError: true,
        errorList: contentBriefRequestErrors
    };
    const response = yield call(Apis.updateContentBrief, payload, parser);

    if (response.status === 200) {
        yield put(setSaveContentBriefStatus(response.status));
        yield put(setSaveContentBrief(response.data));
    }
    return response;
}

// tslint:disable-next-line: typedef
export function* watchForAddContentBriefKeywords() {
    yield takeEvery(actionTypes.CALL_TO_SAVE_KEYWORDS, addContentBriefKeywords);
}

// tslint:disable-next-line: typedef
export function* watchForOnGetContentBriefProgress() {
    yield takeEvery(actionTypes.GET_CB_PROGRESS, onGetContentBriefProgress);
}

// tslint:disable-next-line: typedef
export function* watchForOnUpdateContentBrief() {
    yield takeEvery(actionTypes.UPDATE_CONTENT_BRIEF, onUpdateContentBrief);
}
