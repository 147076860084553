import React from "react";
import { components, } from "react-select";
import { OptionProps } from "react-select/lib/components/Option";

const CustomOption = ({ children, ...props }: OptionProps<object>) => {
    // eslint-disable-next-line no-unused-vars
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = { ...props, innerProps: rest };
    return (
        // @ts-ignore
        <components.Option {...newProps}>
            {children}
        </components.Option>
    );
};

export default CustomOption;
