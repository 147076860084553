import { createSelector } from "reselect";
import { ApplicationStateInterface } from "../../../app/duck/types";

const extractIsAIAssistedContent = (state: ApplicationStateInterface): boolean => {
    return state.pageOptimizerBeta.isAIAssistedContent;
};
export const getAIAssistedContentFlag = createSelector(extractIsAIAssistedContent, (flag: boolean) => flag);

const extractUserPrompt = (state: ApplicationStateInterface): string => {
    return state.pageOptimizerBeta.userPrompt;
};
export const getUserPrompt = createSelector(extractUserPrompt, (userPrompt: string) => userPrompt);
