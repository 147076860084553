import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { omit } from "lodash";
import { ApiConfig } from "../config/apiConfig";
import { NotifyErrorInterface } from "../duck/types";
import { logOut, notifyError } from "../duck/utils";

interface AxiosRequestConfigInterface extends AxiosRequestConfig {
    notifyError?: NotifyErrorInterface;
}

class ServiceRequest {
    // avoid any make generic type for data
    // tslint:disable-next-line:no-any
    public invoke(args: AxiosRequestConfigInterface): any {
        const customAxiosParams = {
            ...ApiConfig,
            ...omit(args, ["notifyError"]),
            withCredentials: true || process.env.IS_DEV === "0"
        };
        return axios(customAxiosParams)
            .then((response: AxiosResponse) => {
                return { data: response.data, status: response.status };
            })
            .catch((e: AxiosResponse) => {
                const status = e.request.status;
                if (status === 401) {
                    logOut();
                } else {
                    notifyError(status, args.notifyError);
                }
                // tslint:disable-next-line:no-any
                return { status, message: `Error:${e}`, response: (e as any)?.response?.data };
            });
    }
}
export default ServiceRequest.prototype;
