import { AnyAction } from "redux";
import { assign, isUndefined } from "lodash";
import { handleActions } from "redux-actions";
import { SubscriptionNavigationType } from "../../app/duck/types";

import actionTypes from "./actions";
import { UserSubscriptionReducerInterface } from "./types";
import { getSubscriptionDetails } from "./utils";

const INIT_STATE: UserSubscriptionReducerInterface = {
    isLoading: false,
    selectedNav: SubscriptionNavigationType.PROFILE,
    subscriptionDetails: undefined,
    userdetails: undefined
};
const Reducer = handleActions(
    {
        [actionTypes.GET_SUBSCRIPTION]: (state, action: AnyAction) => ({
            ...state,
            isLoading: true
        }),

        [actionTypes.SET_SUBSCRIPTION]: (state, action: AnyAction) => ({
            ...state,
            isLoading: false,
            subscriptionDetails: assign({}, !isUndefined(action.payload) ? getSubscriptionDetails(action.payload) : undefined)
        }),
        [actionTypes.GET_USER_DETAILS]: (state, action: AnyAction) => ({
            ...state,
            isLoading: true
        }),
        [actionTypes.SET_USER_DETAILS]: (state, action: AnyAction) => ({
            ...state,
            isLoading: false,
            userdetails: assign(
                {},
                {
                    first_name: action.payload.first_name,
                    last_name: action.payload.last_name,
                    phone_number: action.payload.phone_number,
                    user_name: action.payload.user_name
                }
            )
        }),
        [actionTypes.SET_SELECTED_OPTION]: (state, action: AnyAction) => ({
            ...state,
            selectedNav: action.payload
        })
    },
    INIT_STATE
);

export default Reducer;
