import { logOut } from "../../app/duck/utils";

const isAuthorized = () => {
    const isDevEnvironment = Boolean(Number.parseInt(process.env.IS_DEV, 10));
    if (isDevEnvironment) {
        return true
    }
    if (document.cookie.includes(process.env.COOKIE_SESSION_ID)) {
        const cookieSessionValueRegex = `${process.env.COOKIE_SESSION_ID}=([^;]+)`;
        // if the session cookie exists, increase the expiry limit to 1 hr
        document.cookie = `${process.env.COOKIE_SESSION_ID} = ${document.cookie.match(new RegExp(cookieSessionValueRegex))[1]}; expires=${new Date(((new Date()).getTime() + (3600000 * 24))).toGMTString()}; path=/`;
        if (window.location.pathname === '/login' ||
            window.location.pathname === '/' ||
            window.location.pathname === '/sso/callback/' ||
            window.location.pathname === '/forgotpassword' ||
            window.location.pathname === '/resetpassword') {
            window.location.pathname = `/page-optimizer`;
        }
        return true;
    }

    if (window.location.pathname === '/login' ||
        window.location.pathname === '/' ||
        window.location.pathname === '/sso/callback/' ||
        window.location.pathname === '/forgotpassword' ||
        window.location.pathname === '/resetpassword') {
        return true;
    }

    window.location.pathname = `/login`;
    // window.location.href = `${process.env.ROOT_BASE_URL}/login`;
    return false;
};

export default isAuthorized;
