import { call, put, takeEvery } from "redux-saga/effects";
import { AnyAction } from "redux";
import { isUndefined } from "lodash";
import ActionTypes, { setPaginationShowLoader, setPaginationHideLoader, saveContentBriefList } from "./actions";
import Apis from "../../app/apis";
import { getContext } from "../../app/duck/context-container";

// tslint:disable-next-line:typedef
export function* fetchContentBriefData(action: AnyAction) {
    const { tenantCode = "" } = getContext();
    const payload = {
        tenantCode,
        tenantId: action.payload.tenantId,
        projectId: action.payload.projectId,
        pageIndex: action.payload.pageIndex,
        isLoadingShow: action.payload.isLoadingShow,
        ...(!isUndefined(action.payload.searchTerm) && { searchTerm: action.payload.searchTerm })
    };
    if (payload.isLoadingShow) {
        yield put(setPaginationShowLoader());
    }
    const response = yield call(Apis.getContentBriefList, payload);
    if (response.status === 200) {
        const responseObj = {
            count: response.data.count,
            prev_page: response.data.prev_page,
            curr_page: response.data.curr_page,
            next_page: response.data.next_page,
            total_pages: response.data.total_pages,
            cb_list: response.data.results
        };
        yield put(setPaginationHideLoader());
        yield put(saveContentBriefList(responseObj));
    } else {
        const emptyObj = {
            count: 0,
            prev_page: null,
            curr_page: 1,
            next_page: null,
            total_pages: 0,
            cb_list: []
        };
        yield put(saveContentBriefList(emptyObj));
        yield put(setPaginationHideLoader());
    }
    return response;
}

// tslint:disable-next-line: typedef
export function* watchGetContentBriefData() {
    yield takeEvery(ActionTypes.GET_CONTENT_BRIEF_LIST, fetchContentBriefData);
}
