import { createSelector } from "reselect";
import { ApplicationStateInterface } from "../../app/duck/types";

const extractTimesFetchReportTokenRetriedAttempts = (state: ApplicationStateInterface): number => {
    return state.competitiveIntelligence.fetchReportTokenRetriedAttempts;
};

export const getFetchReportTokenRetriedAttempts = createSelector(
    extractTimesFetchReportTokenRetriedAttempts,
    (retriedtimes: number) => retriedtimes
);
