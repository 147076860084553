import { call, put, takeLatest } from "redux-saga/effects";
import actionTypes, { isDodReqProcessing, isDodSubmissionSuccess, setDodErrorCode } from "./actions";
import { AnyAction } from "redux";
import { getContext } from "../../app/duck/context-container";
import Apis from "../../app/apis";


// tslint:disable-next-line:typedef
export function* callToGetRequestIdOnSubmit(action: AnyAction) {
    const { tenantCode = "" } = getContext();
    const payload = action.payload;
    const parser = {
        tenantCode: tenantCode as string
    };
    yield put(isDodReqProcessing(true));
    const response = yield call(Apis.getDodSubmitRequestId, payload, parser);
    if (response.status === 202 || response.status === 200) {
        yield put(isDodSubmissionSuccess(response.data.req_id));
    } else if (response.status === 400) {
        yield put(setDodErrorCode(response.response.error_code));
    } else {
        yield put(setDodErrorCode(response.status));
    }
    yield put(isDodReqProcessing(false));
}

// tslint:disable-next-line:typedef
export function* watchCallToGetRequestIdOnSubmit() {
    yield takeLatest(actionTypes.CALL_TO_DOD_SUBMIT_API, callToGetRequestIdOnSubmit);
}
