export const ApiConfig = {
    // baseURL: "https://virtserver.swaggerhub.com/iQuanti-ProductTeam/alps-services/3.3-Multi-keywords/",
    rkBaseURL: process.env.RK_ROOT_BASE_URL,
    baseURL: process.env.ROOT_API_URL,
    _baseURL: "https://virtserver.swaggerhub.com/iQuanti-ProductTeam/alps-services/3.3_overall_simulation/",
    tempBaseURL: "https://virtserver.swaggerhub.com/iQuanti-ProductTeam/alps-services/3.4-oas3/"
    // headers: {
    //     "Access-Control-Allow-Origin": "*"
    // }
};
