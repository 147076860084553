import { createSelector } from "reselect";
import { isUndefined, isEqual } from "lodash";
import { ApplicationStateInterface } from "src/app/duck/types";
import { filterKeywordBasedOnRelevanceScore, filterKeywordBasedOnSearchVolume, getFilteredKeywords, getSortedKeywordList } from "./utils";
import { OptionTypeInterface } from "src/app/styledComponents/drop-down/types";

const getAllFormattedKeywordList = (state: ApplicationStateInterface): OptionTypeInterface[] => {
    const { filteredSearchKeyword, relatedKeywords = [], appliedFiltersOnRKeyword } = state.suggestedKeyword;

    let filteredKeywords: OptionTypeInterface[] = [];
    const formattedRelatedKeywordList = !isUndefined(filteredSearchKeyword) ? getFilteredKeywords(filteredSearchKeyword, relatedKeywords) : relatedKeywords;
    filteredKeywords = formattedRelatedKeywordList;

    if (!isUndefined(appliedFiltersOnRKeyword.searchvolume) && appliedFiltersOnRKeyword.searchvolume.length) {
        filteredKeywords = filterKeywordBasedOnSearchVolume(appliedFiltersOnRKeyword.searchvolume, filteredKeywords);
    }

    if (!isUndefined(appliedFiltersOnRKeyword.relevancescore) && appliedFiltersOnRKeyword.relevancescore.length) {
        filteredKeywords = filterKeywordBasedOnRelevanceScore(appliedFiltersOnRKeyword.relevancescore, filteredKeywords);
    }

    if (!isEqual(appliedFiltersOnRKeyword.sorttype, "")) {
        filteredKeywords = getSortedKeywordList(filteredKeywords, appliedFiltersOnRKeyword.sorttype);
    }

    return filteredKeywords;
};


const extractTimesSseRetried = (state: ApplicationStateInterface): number => {
    return state.suggestedKeyword.keywordSseRetriedAttempt;
};

export const formattedKeywordList = createSelector(getAllFormattedKeywordList, (list: OptionTypeInterface[]) => list);
export const getKeywordSSeRetriedAttempts = createSelector(extractTimesSseRetried, (retriedTimes: number) => retriedTimes);
