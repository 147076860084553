import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */
const ActionTypes = {
    GET_SIMULATION_HISTORY: "GET_SIMULATION_HISTORY_BETA",
    SAVE_SIMULATION_HISTORY: "SAVE_SIMULATION_HISTORY_BETA",
    SET_PAGINATION_SHOW_LOADER: "SET_PAGINATION_SHOW_LOADER_BETA",
    SET_PAGINATION_HIDE_LOADER: "SET_PAGINATION_HIDE_LOADER_BETA",
    FETCH_INITIAL_AI_GENERATED_CONTENT_OUTLINE: "FETCH_INITIAL_AI_GENERATED_CONTENT_OUTLINE",
    FETCH_REGENERATED_AI_CONTENT_OUTLINE: "FETCH_REGENERATED_AI_CONTENT_OUTLINE",
    SET_INITAL_GENERATED_CONTENT_OUTLINE_API_STATUS: "SET_INITAL_GENERATED_CONTENT_OUTLINE_API_STATUS",
    SET_REGENERATED_CONTENT_OUTLINE_API_STATUS: "SET_REGENERATED_CONTENT_OUTLINE_API_STATUS",
    MARK_AI_CONTENT_FETCH_COMPLETE: "MARK_AI_CONTENT_FETCH_COMPLETE",
    RESET_PAGE_OPTIMIZER_SLICE: "RESET_PAGE_OPTIMIZER_SLICE",
    SET_USER_PROMPT_FOR_CONTENT_OUTLINE: "SET_USER_PROMPT_FOR_CONTENT_OUTLINE"
};
export default ActionTypes;

// GETTERS / API CALL ACTIONS

export const setPaginationShowLoader = createAction(ActionTypes.SET_PAGINATION_SHOW_LOADER);
export const setPaginationHideLoader = createAction(ActionTypes.SET_PAGINATION_HIDE_LOADER);

export const getSimulationHistory = createAction(ActionTypes.GET_SIMULATION_HISTORY);
export const saveSimulationHistory = createAction(ActionTypes.SAVE_SIMULATION_HISTORY);

// action to fetch AI generated content outline for the first time
export const fetchInitialGeneratedContentOutline = createAction(ActionTypes.FETCH_INITIAL_AI_GENERATED_CONTENT_OUTLINE);

// action to regenerate AI content outline
export const fetchRegeneratedContentOutline = createAction(ActionTypes.FETCH_REGENERATED_AI_CONTENT_OUTLINE);

// action to mark the status of first API call to content outline
export const setInitialGeneratedContentOutlineAPIStatus = createAction(ActionTypes.SET_INITAL_GENERATED_CONTENT_OUTLINE_API_STATUS);

// action to mark the status of regenerate API call to content outline (called after the first time)
export const setRegenerateContentOutlineAPIStatus = createAction(ActionTypes.SET_REGENERATED_CONTENT_OUTLINE_API_STATUS);

export const markAIFetchContentComplete = createAction(ActionTypes.MARK_AI_CONTENT_FETCH_COMPLETE);
export const resetPageOptimizerSlice = createAction(ActionTypes.RESET_PAGE_OPTIMIZER_SLICE);

export const setUserPromptForContentOutline = createAction(ActionTypes.SET_USER_PROMPT_FOR_CONTENT_OUTLINE);
