import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */
const ActionTypes = {
    GET_CONTENT_BRIEF_LIST: "GET_CONTENT_BRIEF_LIST",
    SAVE_CONTENT_BRIEF_LIST: "SAVE_CONTENT_BRIEF_LIST",
    SET_PAGINATION_SHOW_LOADER: "SET_PAGINATION_SHOW_LOADER",
    SET_PAGINATION_HIDE_LOADER: "SET_PAGINATION_HIDE_LOADER",
    SET_CURRENT_PAGE_NUMBER: "SET_CURRENT_PAGE_NUMBER",
};
export default ActionTypes;

// GETTERS / API CALL ACTIONS
export const getContentBriefList = createAction(ActionTypes.GET_CONTENT_BRIEF_LIST);
export const saveContentBriefList = createAction(ActionTypes.SAVE_CONTENT_BRIEF_LIST);
export const setPaginationShowLoader = createAction(ActionTypes.SET_PAGINATION_SHOW_LOADER);
export const setPaginationHideLoader = createAction(ActionTypes.SET_PAGINATION_HIDE_LOADER);
export const setCurrentPageNumber = createAction(ActionTypes.SET_CURRENT_PAGE_NUMBER);
