import XLSX from "xlsx";
import moment from "moment";
import { filter, has, isUndefined, map, sumBy } from "lodash";
import { FlowTypeInterface, PowerBiPayloadFilterInterface } from "../../app/duck/types";
import {
    KW_RESEARCH_DOMAIN_SECTION_CODE,
    KW_RESEARCH_KEYWORD_SECTION_CODE,
    KW_RESEARCH_REPORT_CODE,
    KW_RESEARCH_REPORT_TABLE_NAMES,
    KW_RESEARCH_URL_SECTION_CODE,
    KW_RESEARCH_PAGE_SEARCH_TYPES,
    COPY,
    RELATED_KW_SSE_EVENT_TYPES,
    RELATED_KW_INITIATE_API_TYPE
} from "../../app/const";
import { isDomain, isURL } from "../../app/duck/utils";
import { OptionTypeInterface } from "../../app/styledComponents/drop-down/types";
import { isRelevanceScorePresentOrNot } from "../../suggested-keywords/ducks/utils";
import { AppliedFilter, AppliedFilterOnKeywordInterface } from "../../suggested-keywords/ducks/types";
import { selectedSearchURLsInterface } from "./types";

export const getSectionCode = (inputType: string): string => {
    switch (inputType) {
        case KW_RESEARCH_PAGE_SEARCH_TYPES.keyword:
            return KW_RESEARCH_KEYWORD_SECTION_CODE;
        case KW_RESEARCH_PAGE_SEARCH_TYPES.url:
            return KW_RESEARCH_URL_SECTION_CODE;
        case KW_RESEARCH_PAGE_SEARCH_TYPES.domain:
            return KW_RESEARCH_DOMAIN_SECTION_CODE;
        default:
            return "";
    }
};

export const getReportTokenActionPayload = (locale: string, inputType: string) => ({
    locale,
    product: "credit-cards",
    reportCode: KW_RESEARCH_REPORT_CODE,
    sectionCode: getSectionCode(inputType),
});

export const getTableName = (inputType: string) => {
    switch (inputType) {
        case KW_RESEARCH_PAGE_SEARCH_TYPES.keyword:
            return KW_RESEARCH_REPORT_TABLE_NAMES.keyword;
        case KW_RESEARCH_PAGE_SEARCH_TYPES.url:
            return KW_RESEARCH_REPORT_TABLE_NAMES.url;
        case KW_RESEARCH_PAGE_SEARCH_TYPES.domain:
            return KW_RESEARCH_REPORT_TABLE_NAMES.domain;
        default:
            return "";
    }
};

const searchEngineOptions = ["google"];
interface KRTReportFilter {
    table: string;
    column: string;
    value: string;
}
export const getFilters = (seed: string, locale: string, inputType: string): PowerBiPayloadFilterInterface[] => {
    const filters = getFiltersList(seed, locale, inputType);
    return map(filters, ({ table, column, value }: KRTReportFilter): PowerBiPayloadFilterInterface => {
        const values = [value as string];
        return {
            values,
            target: {
                table,
                column
            },
            $schema: "http://powerbi.com/product/schema#basic",
            operator: "In",
            filterType: 1, // pbi.models.FilterType.BasicFilter
            requireSingleSelection: true // Limits selection of values to one.
        };
    });
};

export const getFiltersList = (seed: string, locale: string, inputType: string): KRTReportFilter[] => ([
    {
        table: getTableName(inputType),
        column: "locale",
        value: locale,
    },
    {
        table: getTableName(inputType),
        column: inputType === KW_RESEARCH_PAGE_SEARCH_TYPES.keyword ? "seed_keyword" : (inputType === KW_RESEARCH_PAGE_SEARCH_TYPES.url ? "seed_url" : "seed"),
        value: inputType === KW_RESEARCH_PAGE_SEARCH_TYPES.keyword ? seed.toLowerCase() : seed,
    },
    {
        table: getTableName(inputType),
        column: "search_engine",
        value: searchEngineOptions[0]
    }
]);

export const getInputType = (input: string) => {
    let inputType: string = KW_RESEARCH_PAGE_SEARCH_TYPES.keyword;
    if (isURL(input)) {
        inputType = KW_RESEARCH_PAGE_SEARCH_TYPES.url;
    } else if (isDomain(input)) {
        // AL-7402 | Remove Domain KRT
        inputType = KW_RESEARCH_PAGE_SEARCH_TYPES.url;
    }
    return inputType;
};


export const getTotalRecords = (keywordList: OptionTypeInterface[]) => {
    if (keywordList && keywordList.length) {
        return (keywordList.length);
    }
    return 0;
};

export const getTotalSearchVolumeAdvanceFilters = (keywordList: OptionTypeInterface[]) => {
    if (keywordList && keywordList.length) {
        let volume = 0;
        volume = sumBy(keywordList, (keyword: OptionTypeInterface) => {
            if (keyword.searchVolume && typeof keyword.searchVolume === "number") {
                return keyword.searchVolume;
            }
            return 0;
        });
        return volume;
    }
    return 0;
};

export const relatedKeywordsList = (keywords: OptionTypeInterface[], inputType: string) => {
    const keywordList: object[] = [];
    keywords.forEach((keyword) => {
        keywordList.push({
            keyword: keyword.label,
            search_volume: keyword.searchVolume,
            ...(inputType !== KW_RESEARCH_PAGE_SEARCH_TYPES.keyword && { rank: keyword.rank }),
            ...(inputType !== KW_RESEARCH_PAGE_SEARCH_TYPES.domain && { relevance_score: keyword.relevance_score }),
            ...(inputType === KW_RESEARCH_PAGE_SEARCH_TYPES.domain && { url: keyword.url }),
        });
    });
    return keywordList;
};

export const convertArrayToCSV = (keywords: OptionTypeInterface[], inputType: string) => {
    if (keywords && keywords.length) {
        const filename = `${COPY.KEYWORD_RESEARCH_REPORT_LABEL}${moment().format("MM-DD-YY")}.xlsx`;
        const keywordList = relatedKeywordsList(keywords, inputType);
        let excelHeader;
        if (inputType === KW_RESEARCH_PAGE_SEARCH_TYPES.url) {
            excelHeader = ["Keyword", "Search Volume", "Rank", "Relevance Score"];
        } else if (inputType === KW_RESEARCH_PAGE_SEARCH_TYPES.domain) {
            excelHeader = ["Keyword", "Search Volume", "Rank", "URL"];
        } else {
            excelHeader = ["Keyword", "Search Volume", "Relevance Score"];
        }
        // Using same variables as the above answer
        const Heading = [excelHeader];
        /* convert state to workbook */
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, Heading);
        // Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, keywordList, { origin: "A2", skipHeader: true });

        const wscols = [
            { width: 40 },
            { width: 20 },
            { width: 20 },
        ];
        if (inputType === KW_RESEARCH_PAGE_SEARCH_TYPES.url) {
            wscols.push({ width: 20 });
        } else if (inputType === KW_RESEARCH_PAGE_SEARCH_TYPES.domain) {
            wscols.push({ width: 40 });
        }

        ws["!cols"] = wscols;
        XLSX.utils.book_append_sheet(wb, ws);
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, filename);
    }
};

export const isExportFileDisabled = (keywords: OptionTypeInterface[], isLoading: string | boolean) => {
    if (keywords && keywords.length) {
        const relevanceScorePresent = isRelevanceScorePresentOrNot(keywords);
        return relevanceScorePresent && isLoading === RELATED_KW_SSE_EVENT_TYPES.receive_related_kwds ? true : false;
    }
    return true;
};

export const filterKeywords = (
    args: string | undefined,
    list: OptionTypeInterface[] | undefined,
): OptionTypeInterface[] | undefined => {
    if (args) {
        return filter(list, (item: OptionTypeInterface) => {
            return (item.label as string).toLowerCase().search(args.toLowerCase()) !== -1;
        });
    }
    return undefined;
};


export const advanceFilterSearch = (
    includeKeywords: string[],
    excludeKeywords: string[],
    mode: string | undefined,
    list: OptionTypeInterface[],
    filterType: string | undefined
): OptionTypeInterface[] | undefined => {
    let filteredList;
    if (mode) {
        if (filterType === "Include") {
            filteredList = includeKeywordsFilter(includeKeywords, mode, list, excludeKeywords);
        } else {
            filteredList = excludeKeywordFilter(excludeKeywords, list, mode, includeKeywords);
        }
    }
    return filteredList;
};


export const findAdvanceSearchKeyword = (
    filterType: AppliedFilterOnKeywordInterface,
    list: OptionTypeInterface[],
    mode: string,
    includeKeywords: string[],
    excludeKeywords: string[]
): OptionTypeInterface[] | undefined => {
    if (has(filterType, AppliedFilter.KEYWORD_ADVANCE_FILTER) && !isUndefined(filterType.keyword_advance_filter)) {
        const searchKeywords = includeKeywordsFilter(includeKeywords, mode, list, excludeKeywords);
        if (filterType.keywordfilter) {
            const searchKeywordList = filterKeywords(filterType.keywordfilter, searchKeywords);
            return searchKeywordList;
        }
        return searchKeywords;
    }
    return undefined;
};

export const includeKeywordsFilter = (
    includeKeywords: string[],
    mode: string,
    list: OptionTypeInterface[],
    excludeKeywords: string[]
): OptionTypeInterface[] | undefined => {
    let filteredList;
    const includeKeywordList = includeKeywords.map((element) => {
        return element.toLowerCase();
    });
    const excludeKeywordList = excludeKeywords.map((element) => {
        return element.toLowerCase();
    });
    const keywords = setFilteredExcludeKWList(list, excludeKeywordList);
    let keywordList;
    if (excludeKeywordList.length > 0) {
        keywordList = keywords && keywords.length ? keywords : [];
    } else {
        keywordList = keywords && keywords.length ? keywords : list;
    }
    if (includeKeywordList && includeKeywordList.length) {
        filteredList = setFilteredIncludeKWList(keywordList, includeKeywordList, mode);
    }
    return isUndefined(filteredList) ? keywordList : filteredList;
};

export const excludeKeywordFilter = (
    excludeKeywords: string[],
    list: OptionTypeInterface[],
    mode: string,
    includeKeywords: string[]
): OptionTypeInterface[] | undefined => {
    let filteredList;
    const includeKeywordList = includeKeywords.map((element) => {
        return element.toLowerCase();
    });
    const excludeKeywordList = excludeKeywords.map((element) => {
        return element.toLowerCase();
    });
    const keywords = setFilteredIncludeKWList(list, includeKeywordList, mode);
    let keywordList;
    if (includeKeywordList.length > 0) {
        keywordList = keywords && keywords.length ? keywords : [];
    } else {
        keywordList = keywords && keywords.length ? keywords : list;
    }
    if (excludeKeywordList && excludeKeywordList.length) {
        filteredList = setFilteredExcludeKWList(keywordList, excludeKeywordList);
    }
    return isUndefined(filteredList) ? keywordList : filteredList;
};
export const setFilteredIncludeKWList = (keywords: OptionTypeInterface[], includeKeywords: string[], mode: string): OptionTypeInterface[] | undefined => {
    let filteredList;
    if (mode === "all") {
        filteredList = filter(keywords, (item: OptionTypeInterface) => {
            return includeKeywords.every((e) => {
                return isExactMatch((item.label as string), e);
            });
        });
    } else {
        filteredList = filter(keywords, (item: OptionTypeInterface) => {
            return new RegExp(includeKeywords.join("|")).test(item.label as string);
        });
    }
    return filteredList;
};

export const setFilteredExcludeKWList = (keywords: OptionTypeInterface[], excludeKeywords: string[]): OptionTypeInterface[] | undefined => {
    let filteredList;
    filteredList = filter(keywords, (item: OptionTypeInterface) => {
        return excludeKeywords.every((e) => {
            return !isExactMatch((item.label as string), e);
        });
    });
    return filteredList;
};

export const escapeRegExpMatch = (s: string) => {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
};

export const isExactMatch = (str: string, match: string) => {
    return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str);
};

export const isIncludeKeywordType = (flowType?: FlowTypeInterface, KWType?: string, cbType?: string, optionValue?: string) => {
    if (flowType && flowType === FlowTypeInterface.PAGE_CONTENTBRIEF) {
        return cbType === optionValue;
    }
    return KWType === optionValue;
};

export const isValidURL = (str: string) => {
    const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(str);
};

export const isValidDomain = (str: string) => {
    const regexp = /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-\.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/;
    return regexp.test(str);
};

export const getSearchInputType = (inputType: string) => {
    let searchInputType = "";
    switch (inputType) {
        case KW_RESEARCH_PAGE_SEARCH_TYPES.keyword:
            searchInputType = RELATED_KW_INITIATE_API_TYPE.keyword;
            break;
        case KW_RESEARCH_PAGE_SEARCH_TYPES.url:
            searchInputType = RELATED_KW_INITIATE_API_TYPE.url;
            break;
        case KW_RESEARCH_PAGE_SEARCH_TYPES.domain:
            searchInputType = RELATED_KW_INITIATE_API_TYPE.domain;
            break;
    }
    return searchInputType;
};

export const isURLExistInDomainURLs = (url: string, keywordList: OptionTypeInterface[]) => {
    const selectedURL = keywordList.find((item: OptionTypeInterface) => item.url === url);
    return selectedURL ? false : true;
};

export const urlCheckUnCheck = (url: string, isChecked: boolean, selectedURLs: selectedSearchURLsInterface[]) => {
    const checkedUnCheckedURLs = selectedURLs.map((item: selectedSearchURLsInterface) => {
        if (item.url === url) {
            item.isChecked = isChecked;
        }
        return item;
    });
    return checkedUnCheckedURLs;
};

export const isSelectedURLExist = (url: string, selectedURLs: selectedSearchURLsInterface[]) => {
    if (selectedURLs && selectedURLs.length) {
        const selectedURL = selectedURLs.find((item: selectedSearchURLsInterface) => item.url === url);
        return selectedURL ? true : false;
    }
    return false;
};

export const filteredCheckedURLs = (selectedURLs: selectedSearchURLsInterface[]) => {
    const checkedURLs: string[] = [];
    selectedURLs.forEach((item: selectedSearchURLsInterface) => {
        if (item.isChecked) {
            checkedURLs.push(item.url);
        }
    });
    return checkedURLs;
};

export const isKwURLTooltipShow = (elementId: string, tooltipBubbleId: string) => {
    setTimeout(() => {
        const labelId = document.getElementById(elementId);
        const tooltipId = document.getElementById(tooltipBubbleId);
        if (tooltipId) {
            if (labelId && !(labelId.scrollWidth > labelId.clientWidth)) {
                tooltipId.classList.add("hide");
            }
        }
    }, 100);
    return true;
};
