import {
    map,
    split,
    trim,
    filter,
    includes,
    differenceBy,
    upperCase,
    reject,
    sortBy,
    keys,
    reduce,
    isUndefined,
    has,
    values,
    each,
    flattenDeep,
    uniq,
    concat,
    orderBy,
    compact,
    isNull,
    kebabCase
} from "lodash";
import {
    KeywordListInterface,
    ThemeResponseInterface,
    AddRemoveKeyword,
    AppliedFilterOnKeywordInterface,
    KeywordFilterInterface,
    AppliedFilter,
    RelatedKeywordsInterface
} from "./types";
import {
    SerpListProgressStatusType,
    MultiKeywordTrackRequestApiRequestIdsResponseInterface,
    MultiKeywordTrackRequestApiRequestIdStatusResponseInterface,
    KeywordRequestIdsInterface
} from "../../../../../app/duck/types";
import { themeSelectedValue, MULTI_KW_ALLOWED_PERCENTAGE_KW_TO_PROGRESS, MKW_CONST } from "./const";
import { OptionTypeInterface } from "../../../../../app/styledComponents/drop-down/types";
import { kFormatter } from "../../../../../app/duck/utils";
import { eventChannel } from "redux-saga";

// the max keywords will be + 1 (with default keyword)
export const MAX_SELECTED_KEYWORDS = 19;
export const SIMULATION_MAX_SELECTED_KEYWORDS = 20;

// checks if the given keyword is existing or not based on below conditions
export const isUnique = (defaultKeyword: string, selectedKeywords: string[], keywords: string) => {
    // extracting default keyword and existing selected keywords & converting to uppercase for checking
    const selectedKeywordList: string[] = map([defaultKeyword, ...selectedKeywords], (key: string) => key.toUpperCase());
    // converting new keyword input to uppercase, trimming the whitespace & splitting to indivisual strings with comma(,) delimiter
    const newKeywordList = split(trim(keywords.toUpperCase()), ",");

    // CHECK BASED ON
    // - if there is only one keyword
    // - & the keyword exists in the <selectedKeywordList>
    return !(newKeywordList.length === 1 && includes(selectedKeywordList, newKeywordList[0]));
};

export const areKeywordsChanged = (selectedKeywords: string[], keywordReqIds: KeywordRequestIdsInterface) => {
    const reqIdsKeywordsList = Object.keys(keywordReqIds);
    if (selectedKeywords.length === reqIdsKeywordsList.length) {
        if (differenceBy(selectedKeywords, reqIdsKeywordsList, upperCase).length === 0) {
            return false;
        }
    }
    return true;
};

export const getFailedKeywordCount = (keywordProcessedInfo: MultiKeywordTrackRequestApiRequestIdsResponseInterface) => {
    const failedKeywordIds = reject(
        keywordProcessedInfo,
        (rId: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface) =>
            !includes([SerpListProgressStatusType.FAILED, SerpListProgressStatusType.ERROR], rId.completion_status)
    );
    return failedKeywordIds.length;
};

export const formattedThemeList = (args: ThemeResponseInterface[]): OptionTypeInterface[] => {
    const themeList = map(args, ({ id: value, name: label, ...rest }) => ({
        label,
        value,
        ...rest
    }));
    const sortedThemeLIst = [themeSelectedValue].concat(sortBy(themeList, ["label"]));
    return sortedThemeLIst;
};

export const formattedKeywordsList = (args: KeywordListInterface): OptionTypeInterface[] => {
    const formattedUrl = map(keys(args), (keyword: string) => {
        const rank = args[keyword].search_volume;
        return {
            label: keyword,
            type: args[keyword].type,
            value: args[keyword].id,
            isRanking: args[keyword].is_ranking,
            // @ts-ignore
            searchVolume: rank !== "null" ? rank : 0
        };
    });
    return formattedUrl;
};

export const filterKeywordBasedOnBrandingType = (args: string[], keywordList: OptionTypeInterface[]): OptionTypeInterface[] => {
    if (!isUndefined(args) && args.length > 0) {
        return filter(keywordList, (keyword: OptionTypeInterface) => {
            return args.some((type: string) => type === (keyword.type as string));
        });
    }
    return keywordList;
};

export const findSearchKeyword = (
    args: AppliedFilterOnKeywordInterface,
    list: OptionTypeInterface[]
): OptionTypeInterface[] | undefined => {
    if (has(args, AppliedFilter.KEYWORDFILTER) && !isUndefined(args.keywordfilter)) {
        const updatedList = filter(list, (item: OptionTypeInterface) => {
            return (item.label as string).toLowerCase().search((args.keywordfilter as string).toLowerCase()) !== -1;
        });
        return updatedList;
    }

    return undefined;
};
export const findSearchKeywordBasedOnKeyword = (
    args: string,
    list: OptionTypeInterface[] | undefined
): OptionTypeInterface[] | undefined => {
    if (args) {
        const updatedList = filter(list, (item: OptionTypeInterface) => {
            return (item.label as string).toLowerCase().search(args.toLowerCase()) !== -1;
        });
        return updatedList;
    }
    return undefined;
};

export const filterKeywordBasedOnTheme = (
    themeId: number[],
    themelist: OptionTypeInterface[],
    keywordList: OptionTypeInterface[]
): OptionTypeInterface[] => {
    const themeBasedKeyword: OptionTypeInterface[] = filter(themelist, (list: OptionTypeInterface) => {
        return themeId.includes(list.value as number);
    });
    const getKeywordList = () => {
        if (!isUndefined(themeBasedKeyword.length)) {
            if ((themeBasedKeyword.length === 1 && themeBasedKeyword[0].value === 1) || themeBasedKeyword.length > 1) {
                // @ts-ignore
                const kwList: string[][] = themeBasedKeyword.filter(({ value }) => value !== 1).map(({ keywords }) => keywords);
                return uniq(flattenDeep(kwList));
            }
            if (themeBasedKeyword.length === 1) {
                return themeBasedKeyword[0].keywords;
            }
        }
        return [];
    };
    const themekeywordList = getKeywordList();
    return filter(keywordList, (item: OptionTypeInterface) => {
        return (themekeywordList as string[]).includes(item.label as string);
    });
};

export const filterKeywordBasedOnKeywordAndBrandType = (
    brandTypes: string[],
    keywordTypes: number[],
    themelist: OptionTypeInterface[],
    keywordList: OptionTypeInterface[] | undefined,
    targetUrl: string
): OptionTypeInterface[] => {
    // keywordTypes selected value :
    // 11 == TARGET KEYWORD
    // 12 == RANKING KEYWORDS
    const themeForTargetUrl: OptionTypeInterface[] = themelist.filter((theme: OptionTypeInterface) => theme.target_url === targetUrl);
    const themeKeywordList: string[][] = themeForTargetUrl.map((theme: OptionTypeInterface): string[] => theme.keywords as string[]) || [
        []
    ];
    const unionKeywordList: string[] = uniq(flattenDeep(themeKeywordList));

    const applyBrandedTypeFilter = (keyword: OptionTypeInterface): boolean => brandTypes && brandTypes.includes(keyword.type as string);
    const applyTargetKeywordFilter = (keyword: OptionTypeInterface): boolean => {
        return keywordTypes.includes(11) && themeForTargetUrl && unionKeywordList.includes(keyword.label as string);
    };
    const applyRankingKeywordFilter = (keyword: OptionTypeInterface): boolean => keywordTypes.includes(12) && !!keyword.isRanking;

    return filter(keywordList, (keyword: OptionTypeInterface) => {
        return applyBrandedTypeFilter(keyword) || applyRankingKeywordFilter(keyword) || applyTargetKeywordFilter(keyword);
    });
};

export const fetchKeywordListToCheckUncheck = (
    selectedKeyword: string[],
    keywordList: OptionTypeInterface[] | undefined,
    type: string
): string[] => {
    // NOTE : This following snippent is just to make the matching for keywords in 'selectedKeyword' Array to be case insensitive
    const SelectedKeywordLowerCase = Array();
    selectedKeyword.forEach((element) => {
        SelectedKeywordLowerCase.push(element.toLowerCase());
    });

    const findKeywordNotInSelectedKeyword =
        type === AddRemoveKeyword.ADD
            ? filter(keywordList, (keyword: OptionTypeInterface) => {
                return SelectedKeywordLowerCase.indexOf(keyword.label as string) === -1;
            })
            : filter(keywordList, (keyword: OptionTypeInterface) => {
                return SelectedKeywordLowerCase.indexOf(keyword.label as string) !== -1;
            });
    const KeywordListItem = map(findKeywordNotInSelectedKeyword, (keyword: OptionTypeInterface) => keyword.label as string);
    return KeywordListItem;
};
export const updateValueInAppliedFilter = (
    appliedValue: KeywordFilterInterface,
    prevValue: AppliedFilterOnKeywordInterface
): AppliedFilterOnKeywordInterface => {
    const data = appliedValue.type;
    if (has(prevValue, appliedValue.type)) {
        // @ts-ignore
        prevValue[data as string] = appliedValue.value;
    }

    return prevValue;
};

export const isSomePercentKeywordProcessed = (
    percent: number = MULTI_KW_ALLOWED_PERCENTAGE_KW_TO_PROGRESS,
    keywordsStatus: MultiKeywordTrackRequestApiRequestIdsResponseInterface
): boolean => {
    const kwShouldGetProcessed = (percent / 100) * keys(keywordsStatus).length;
    const kwProcessed = filter(values(keywordsStatus), ["is_my_url_processed", true]);
    return kwProcessed.length >= kwShouldGetProcessed;
};

export const isAnyOneKwProcessed = (keywordsStatus: MultiKeywordTrackRequestApiRequestIdsResponseInterface): boolean => {
    const kwProcessed = filter(values(keywordsStatus), ["is_my_url_processed", true]);
    return kwProcessed.length > 0;
};

export const getMessageForLoader = (keywordsStatus?: MultiKeywordTrackRequestApiRequestIdsResponseInterface): string | undefined => {
    return isUndefined(keywordsStatus)
        ? keywordsStatus
        : (() => {
            const kwProcessed = filter(values(keywordsStatus), ["is_my_url_processed", true]);
            return `${kwProcessed.length}/${values(keywordsStatus).length} ${MKW_CONST.MULTI_KEYWORD_KEYWORD_FETCH_LOADER_MESSAGE}`;
        })();
};

export const markAllKeywordAsProcessed = (keywordsInfo: MultiKeywordTrackRequestApiRequestIdsResponseInterface) => {
    each(keywordsInfo, (v: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface) => {
        if (!v.is_my_url_processed) {
            v.is_my_url_processed = true;
            v.percent_completed = 100;
            v.my_url_status = SerpListProgressStatusType.FAILED;
            v.completion_status = SerpListProgressStatusType.FAILED;
        } else if (
            v.is_my_url_processed &&
            (v.completion_status === SerpListProgressStatusType.STARTED || v.completion_status === SerpListProgressStatusType.QUEUED)
        ) {
            v.percent_completed = 100;
            v.completion_status = SerpListProgressStatusType.FAILED;
        }
    });
    return keywordsInfo;
};
export const removeExtraTabs = (args: string): string => {
    return args.replace(new RegExp("\t\t", "g"), "\t");
};
export const copyPastefromExcel = (args: string): string => {
    const rows = args.split("\n");
    const data = rows;
    let newArr: string[] = [];
    if (data.length > 0) {
        // tslint:disable-next-line: forin
        for (const y in data) {
            data[y] = removeExtraTabs(rows[y]);
            const cells = data[y].split("\t");
            newArr = concat(newArr, cells);
        }
    }
    const removeExtraItem = compact(newArr);
    const keywordsName = removeExtraItem.toString();
    return keywordsName;
};
export const getSearchVolume = (selectedKeyword: string, originalKeywordList: OptionTypeInterface[] | undefined) => {
    if (originalKeywordList) {
        const keywordItem = originalKeywordList.find((keyword: OptionTypeInterface) => keyword.label === selectedKeyword);
        if (keywordItem && keywordItem.searchVolume !== null) {
            return kFormatter(keywordItem.searchVolume as number, 2, false);
        }
    }
    return "-";
};
export const getSortedKeywordList = (keywordList: OptionTypeInterface[], keywordSortType?: OptionTypeInterface): OptionTypeInterface[] => {
    const getKeywordSearchVolume = (keyword: OptionTypeInterface) => (keyword.searchVolume === null ? -1 : keyword.searchVolume);
    const getSearchVolumeAsc = (keyword: OptionTypeInterface) => (keyword.searchVolume === null ? Infinity : keyword.searchVolume);
    const getRelevanceScoreData = (keyword: OptionTypeInterface) =>
        isUndefined(keyword.relevance_score) && isNull(keyword.relevance_score) ? -1 : keyword.relevance_score;
    const getRelevanceScoreAsc = (keyword: OptionTypeInterface) =>
        isUndefined(keyword.relevance_score) && isNull(keyword.relevance_score) ? Infinity : keyword.relevance_score;
    switch (keywordSortType && keywordSortType.value) {
        case "SV_H_TO_L":
            return orderBy(keywordList, [getKeywordSearchVolume, "label"], ["desc"]);
        case "SV_L_TO_H":
            return orderBy(keywordList, [getSearchVolumeAsc, "label"]);
        case "ASC_A_TO_Z":
            return orderBy(keywordList, ["label"]);
        case "DES_Z_TO_A":
            return orderBy(keywordList, ["label"], ["desc"]);
        case "RS_H_TO_L":
            return orderBy(keywordList, [getRelevanceScoreData, "label"], ["desc"]);
        case "RS_L_TO_H":
            return orderBy(keywordList, [getRelevanceScoreAsc, "label"]);
        default:
            return orderBy(keywordList, [getKeywordSearchVolume, "label"], ["desc"]);
    }
};

export const getKWSearchVolume = (keyword: OptionTypeInterface) => {
    if (!isUndefined(keyword.searchVolume) && !isNull(keyword.searchVolume)) {
        return kFormatter(keyword.searchVolume as number, 2, false);
    }
    return "-";
};
// tslint:disable-next-line: no-any
export const filterRangeCond = (rangeCase: string, range?: number | null): any => {
    switch (rangeCase) {
        case "1":
            return isNull(range) || isUndefined(range) || range < 100;
        case "2":
            return !isNull(range) && !isUndefined(range) && range >= 101 && range <= 1000;
        case "3":
            return !isNull(range) && !isUndefined(range) && range >= 1001 && range <= 10000;
        case "4":
            return !isNull(range) && !isUndefined(range) && range >= 10001 && range <= 100000;
        case "5":
            return !isNull(range) && !isUndefined(range) && range >= 100001 && range <= 1000000;
        case "6":
            return !isNull(range) && !isUndefined(range) && range >= 1000000;
    }
};
export const filterKeywordBasedOnSearchVolume = (searchType: string[], keywordList: OptionTypeInterface[]): OptionTypeInterface[] => {
    let filteredArr: OptionTypeInterface[] = [];
    searchType.forEach((rangeCase) => {
        const filterResponse = filter(keywordList, (list: OptionTypeInterface): OptionTypeInterface[] =>
            filterRangeCond(rangeCase, list.searchVolume)
        );
        filteredArr = filteredArr.concat(filterResponse as OptionTypeInterface[]);
    });
    return filteredArr;
};
// tslint:disable-next-line: no-any
export const filterScoreCond = (rangeCase: string, range?: number | null): any => {
    switch (rangeCase) {
        case "1":
            return isNull(range) || isUndefined(range) || range <= 0.3;
        case "2":
            return !isNull(range) && !isUndefined(range) && range >= 0.3 && range <= 0.5;
        case "3":
            return !isNull(range) && !isUndefined(range) && range >= 0.5 && range <= 0.7;
        case "4":
            return !isNull(range) && !isUndefined(range) && range >= 0.7 && range <= 1;
    }
};
export const filterKeywordBasedOnRelevanceScore = (searchType: string[], keywordList: OptionTypeInterface[]): OptionTypeInterface[] => {
    let filteredArr: OptionTypeInterface[] = [];
    searchType.forEach((rangeCase) => {
        const filterResponse = filter(keywordList, (list: OptionTypeInterface): OptionTypeInterface[] =>
            filterScoreCond(rangeCase, list.relevance_score)
        );
        filteredArr = filteredArr.concat(filterResponse as OptionTypeInterface[]);
    });
    return filteredArr;
};

export const getSlicedList = (list: OptionTypeInterface[], size: number): [] => {
    // tslint:disable-next-line:no-any
    return reduce(
        list,
        // tslint:disable-next-line: no-any
        (slicedArr: any, _: any, index: number) => (index % size ? slicedArr : [...slicedArr, list.slice(index, index + size)]),
        []
    );
};

export const formattedRelatedKeywords = (relatedKeywords: RelatedKeywordsInterface[] | undefined) => {
    const formattedKwList: OptionTypeInterface[] = [];
    if (relatedKeywords) {
        relatedKeywords.forEach((item: RelatedKeywordsInterface) => {
            formattedKwList.push({
                value: 0,
                label: item.keyword,
                searchVolume: item.search_volume,
                relevance_score: item?.relevance_score,
                type: item?.type
            });
        });
    }
    return formattedKwList;
};

export const isAllKeywordSelected = (selectedKeywords: string[], pageKeywordList: OptionTypeInterface[]) => {
    const SelectedKeywordName = map(selectedKeywords, (keywordName: string) => {
        return keywordName.toLowerCase();
    });
    const selectedKeywordList = pageKeywordList?.length
        ? pageKeywordList.filter((keyword: OptionTypeInterface) => SelectedKeywordName.includes(keyword.label.toString().toLowerCase()))
        : [];
    return selectedKeywordList?.length === pageKeywordList?.length;
};

export const getRelevanceScore = (keyword: OptionTypeInterface) => {
    if (!isUndefined(keyword?.relevance_score) && !isNull(keyword?.relevance_score)) {
        return kFormatter(keyword.relevance_score as number, 2, false);
    }
    return "-";
};
export const isRelevanceScorePresentOrNot = (pageKeywordList: OptionTypeInterface[]) => {
    let found = true;
    map(pageKeywordList, (keywordName: OptionTypeInterface) => {
        if (!isUndefined(keywordName.relevance_score)) {
            found = false;
        }
    });
    return found;
};

export const isSingleRelevanceScoreUndefinedOrNull = (pageKeywordList: OptionTypeInterface[]) => {
    for (const keywordData of pageKeywordList) {
        if (isUndefined(keywordData.relevance_score) && isNull(keywordData.relevance_score)) {
            return false;
        }
    }
    return false;
};

// tslint:disable-next-line:typedef
export function listenSSEvents(url: string) {
    const eventChan = eventChannel((emit) => {
        const sseSource = new EventSource(url);
        sseSource.addEventListener("receive_related_kwds", (ev: Event) => emit(ev));
        sseSource.addEventListener("req_completed", (ev: Event) => emit(ev));
        sseSource.addEventListener("req_failed", (ev: Event) => emit(ev));
        sseSource.onerror = (ev: Event) => {
            emit(ev);
        };
        return () => sseSource.close();
    });
    return eventChan;
}
export const isTypePresentOrNot = (pageKeywordList: OptionTypeInterface[]) => {
    let found = true;
    map(pageKeywordList, (keywordName: OptionTypeInterface) => {
        if (!isUndefined(keywordName.type)) {
            found = false;
        }
    });
    return found;
};

export const getLocaleAndProjectId = (multikeywordLocale?: string, contextLocale?: string) => {
    const locale = JSON.parse(localStorage.getItem("selected_locale") as string);
    const { default_project, projectID } = JSON.parse(localStorage.getItem("ls.__iQ_alps") as string);
    return { locale, default_project, projectID };
};

/**
 * Return number: Keywords for Second Line
 *
 * @param {key} String Array
 * @returns {number} - number of Keywords count
 *
 */
export const getSecondLineKeywordCountMultiKeyword = (key: string[] | undefined): number => {
    let tp = 0;
    let numKey = 0;
    const topDivWidth = document.getElementById(`phillContainer`)?.offsetWidth || 0;
    if (key && key.length > 0) {
        map(key, (processedKw: string) => {
            const individualItemLen = document.getElementById(`kw_url_phill_${kebabCase(processedKw)}`)?.offsetWidth || 0;
            tp = tp + individualItemLen + 10;
            if (tp > topDivWidth) {
                numKey = numKey + 1;
            }
        });
    }
    return numKey;
};
