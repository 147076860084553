import { isUndefined, omit } from "lodash";
import ServiceRequest from "../../../../../app/services";
import { ApiConfig } from "../../../../../app/config/apiConfig";
import { getAxiosParam, omitOptionalArgs } from "../../../../../app/duck/utils";
import { ApiPath, METHOD } from "../../../../../app/config/apiPath";
import {
    MultiKeywordRequestIdsApiRequestInterface,
    MultiKeywordThemeKeywordFromProjectApiRequestInterface,
    MultiKeywordTrackRequestApiRequestInterface,
    RelatedRequestIdsApiRequestInterface
} from "./types";

class MultiKeywordApis {

    // INITIATE REQUEST API FOR MULTI-KEYWORD SIMULATION
    public *getMultiKeywordRequestIds(
        args: MultiKeywordRequestIdsApiRequestInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omitOptionalArgs(args)
            },
            method: METHOD.POST,
            url: args.isBeta ? ApiPath.MULTI_KEYWORD_INITIATE_REQUEST_URL_BETA : ApiPath.MULTI_KEYWORD_INITIATE_REQUEST_URL
        };
        return yield ServiceRequest.invoke(getAxiosParam(axiosParam, omit(args, ["isBeta"])));
    }

    // TRACK REQUEST API FOR MULTI-KEYWORD SIMULATION
    public *getMultiKeywordTrackRequestStatus(
        args: MultiKeywordTrackRequestApiRequestInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omitOptionalArgs(args)
            },
            method: METHOD.POST,
            url: args.isBeta ? ApiPath.MULTI_KEYWORD_TRACK_REQUEST_URL_BETA : ApiPath.MULTI_KEYWORD_TRACK_REQUEST_URL
        };
        return yield ServiceRequest.invoke(getAxiosParam(axiosParam, omit(args, ["isBeta"])));
    }

    public *getThemeKeywordList(
        args: MultiKeywordThemeKeywordFromProjectApiRequestInterface
        // tslint:disable-next-line:no-any
    ): any {
        const projectId = !isUndefined(args.projectId) && args.projectId;
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.THEME_KEYWORD_URL.replace("{{projectId}}", projectId.toString())
                .replace("{{tenantCode}}", args.tenantCode)
                .replace("{{rankingUrl}}", args.url)
                .replace("{{locale}}", args.locale)
        };
        const plans = yield ServiceRequest.invoke(getAxiosParam(axiosParam, args));
        return plans;
    }

    public *getRelatedKeywordRequestIds(
        args: RelatedRequestIdsApiRequestInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...omitOptionalArgs(args)
            },
            method: METHOD.POST,
            url: !isUndefined(args.url)
                ? ApiPath.RELATED_KEYWORD_INITIATE_REQUEST_URL.replace("{{type}}", "url")
                : ApiPath.RELATED_KEYWORD_INITIATE_REQUEST_URL.replace("{{type}}", "keyword")
        };
        return yield ServiceRequest.invoke(getAxiosParam(axiosParam, args));
    }
}
export default new MultiKeywordApis();
