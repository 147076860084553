import { createAction } from "redux-actions";

const actionTypes = {
    SET_LAZY_LOADING_IMPACTED_KEYWORDS_AND_URLS: "SET_LAZY_LOADING_IMPACTED_KEYWORDS_AND_URLS",
    CALL_TO_GET_IMPACTED_KEYWORDS_AND_URLS_LIST: "CALL_TO_GET_IMPACTED_KEYWORDS_AND_URLS_LIST",
    APPLY_FILTER_ON_KEYWORD_LEVEL_IMPACT: "APPLY_FILTER_ON_KEYWORD_LEVEL_IMPACT",
    SET_IMPACTED_KEYWORD_LIST: "SET_IMPACTED_KEYWORD_LIST",
    UPDATE_FILTERS_ON_KEYWORD_LEVEL_IMPACT: "UPDATE_FILTERS_ON_KEYWORD_LEVEL_IMPACT",
    CLEAR_COMP_ANALYSIS_DATA: "CLEAR_COMP_ANALYSIS_DATA"
};

export default actionTypes;

export const setLazyLoadingKeywordLevelImpact = createAction(actionTypes.SET_LAZY_LOADING_IMPACTED_KEYWORDS_AND_URLS);
export const getImpactedKeywordsandUrls = createAction(actionTypes.CALL_TO_GET_IMPACTED_KEYWORDS_AND_URLS_LIST);
export const applyFilterOnKeywordLevelImpact = createAction(actionTypes.APPLY_FILTER_ON_KEYWORD_LEVEL_IMPACT);
export const setImpactedKeywordList = createAction(actionTypes.SET_IMPACTED_KEYWORD_LIST);
export const updateFiltersOnKeywordLevelImpact = createAction(actionTypes.UPDATE_FILTERS_ON_KEYWORD_LEVEL_IMPACT);
export const clearCompetitiveAnalysisData = createAction(actionTypes.CLEAR_COMP_ANALYSIS_DATA);
