import { ModelScoredDropdownKeysType } from "../../../app/styledComponents/drop-down/types";
import { StyledLegendInterface } from "../../../app/styledComponents/legends";
import { ContextualRecomParamName } from "../Components/ContentOptimization/Editor/Components/ContentEditor/ContextualRecommendationsModal/ducks/types";
import {
    CompetitorScoreSseEventInterface,
    OnPageElementsResponseInterface,
    OnPageElementsTypeBeta,
    SimulatorConstInterface,
    HeaderTagMarginObj
} from "./types";

export const SIMULATOR_CONST: SimulatorConstInterface = {
    REGENERATE_LABEL: "Re-generate Outline",
    FETCH_INITIAL_OUTLINE_FAILED_LABEL:
        "We could not generate the content outline. Please edit the prompt and regenerate the content outline.",
    REGENERATE_SPAN_TXT: "In a few words, explain what you are writing about.",
    REGENERATE_ID: "regenerate-outline",
    REGENERATE_BUTTON_LABEL: "Re-generate",
    REGENERATE_MODAL_SPAN: "You want to add/modify keywords?",
    REGENERATOR_OUTLINE_ERROR: "Please provide specific details to regenerate the content outline.",
    TEXTAREA_PLACEHOLDER: "Input details here to regenerate the content outline",
    CONTENT_FROM_TOP: "Content from Top",
    CONTENT_SIMULATOR_EDITOR_ELEMENT_BODY_CONTENT: "Body Content",
    CONTENT_SIMULATOR_EDITOR_ELEMENT_URL: "URL",
    META_ELEMENT_DESCRIPTION: "Meta Description",
    CONTENT_SIMULATOR_RUN_SIMULATION_CONTENT2: " to view updated scores and predicted ranks.",
    MULTI_KEYWORD_NOTIFICATION_WHEN_ALL_KW_PROCESSED_FAILED_PROCESSED: "keywords processed",
    MULTI_KEYWORD_NOTIFICATION_WHEN_ALL_KW_PROCESSED_FAILED: "failed",
    MULTI_KEYWORD_NOTIFICATION_WHEN_ALL_KW_PROCESSED: "All keywords data processed",
    MODAL_CONTENT_SIMULATOR_VIEW_ORIGINAL_EDITOR_TITLE: "Original View",
    REVERT_TO_ORIGINAL: "Revert to Original",
    LAST_CRAWL_DATE: "Last crawl date - ",
    CONTENT_SIMULATOR_EDITOR_ELEMENT_H1: "H1",
    CONTENT_SIMULATOR_EDITOR_ELEMENT_H2: "H2",
    CONTENT_SIMULATOR_EDITOR_ELEMENT_H3: "H3",
    CONTENT_SIMULATOR_EDITOR_ELEMENT_H4: "H4",
    CONTENT_SIMULATOR_EDITOR_ELEMENT_H5: "H5",
    CONTENT_SIMULATOR_EDITOR_ELEMENT_H6: "H6",
    RED_BULB_MESSAGE:
        "{{element_name}} is not optimized for targeted keywords. Please use the Optimize link below to update the {{element_name}}.",
    YELLOW_BULB_MESSAGE:
        "While your {{element_name}} is relevant to the targeted keywords, it can be optimized further. Please use the Optimize link below to update the {{element_name}}.",
    GREEN_BULB_MESSAGE: "Your {{element_name}} is optimized for the targeted keywords.",
    WORDS_TEXT: "Words",
    WORD_TEXT: "Word",
    TOTAL_BODY_WORDS_COUNT: "This is the total number of words in the body content",
    CONTENT_SIMULATOR_H1_SCORE: "H1",
    CONTENT_SIMULATOR_H2_SCORE: "H2",
    CONTENT_SIMULATOR_H3_SCORE: "H3",
    SIMULATOR_EDITOR_INSTRUCTIONS_TITLE: "How the editor works",
    TITLE_LOWERCASE_TEXT: "title",
    META_DESCRIPTION_LOWERCASE: "meta_description",
    META_ELEMENT_URL: "URL",
    CONTENT_SIMULATOR_VALIDATION_INVALID_URL: "Please enter a valid URL",
    NO_CONTENT_BULB_MESSAGE: "Please add a {{element_name}}. You could leverage the Optimize link below to update the {{element_name}}.",
    META_DESC_CHAR_COUNT_TEXT:
        "The recommended character count for Meta Description is between 150-160 characters. Current Meta Description is {{MAX}} {{CHARACTER}} long.",
    TITLE_DESC_CHAR_COUNT_TEXT: "The recommended character count for Title is 70 characters. Current title has {{MAX}} {{CHARACTER}}",
    CONTENT_SIMULATOR_EDITOR_ELEMENT_TITLE: "Title",
    CONTENT_SIMULATOR_EDITOR_ELEMENT_META_DESCRIPTION: "Meta Description",
    CHAR_TEXT: "char",
    CHARACTER_TEXT: "Character",
    CHARACTERS_TEXT: "Characters",
    H1S_BULB_MESSAGE: "Ideally, a page should have only one H1. You could evaluate converting it to a sub-heading (H2 or H3).",
    H1_CHAR_COUNT_TEXT: "Current H1 is {{MAX}} {{CHARACTER}} long.",
    SIMULATOR_ALERT_MESSAGE_FOR_PROCESSED_KEYWORD:
        "Data for {{NO_OF_KEYWORDS}} is available. Do you want to run simulation for {{PROCESSED_KEYWORD}} keywords or wait till all keywords have data?",
    OVERALL_SIMULATOR_VALIDATION_NO_CHANGE_FROM_ORIGINAL:
        "Please make changes in your content to see the impact.",
    OVERALL_SIMULAITON_AUTHORITY_PARAMETERS_NOT_VALID:
        "You have errors in one or more 'Authority' parameters. Please review before the simulation.",
    OVERALL_SIMULAITON_CONTENT_PARAMETERS_NOT_VALID:
        "You have errors in one or more 'Content' parameters. Please review before the simulation.",
    OVERALL_SIMULAITON_CA_PARAMETERS_NOT_VALID:
        "You have errors in one or more 'Content' and 'Authority' parameters. Please review before the simulation.",
    OVERALL_SIMULATOR_VALIDATION_NO_CHANGE: "Please make changes to at least one parameter and try again.",
    CONTENT_SIMULATOR_VALIDATION_MULTI_KW_NOT_SUBMITTED:
        "Please add the selected keywords to the simulation before simulating scores and performance",
    INSIGHT_NOT_GENERATED_MESSAGE: "We were unable to generate the page assessment based on the provided keyword. Kindly modify the keyword and try again.",
    ALERT_OK_LABEL: "OK",
    SIMULATE_ON_AVAILABLE_DATA: "Simulate on Available Data",
    ERROR_TEXT: "Error!",
    SIMULATE_FOR_ALL_KEYWORDS: "Process all Data & Simulate",
    SIMULATE_WAIT: "Wait",
    SELECT_MODE_BROWSER: "Browser",
    PAGE_SIMULATION_LOADER_TITLE: "It could take about 2-3 minutes to get content, authority and other parameters."
};

export const DELTA_ELEMENT_ROUND_BY: number = 2;

export const CLS = "cls";
export const FID = "fid";
export const LCP = "lcp";
export const FETCH_TIME = "fetch_time";
export const HTML_SIZE = "html_size";

export const LEGEND_TARGET_CODE: StyledLegendInterface[] = [
    {
        hexCode: "#3a85ef"
    }
];

// tslint:disable-next-line:prefer-array-literal
export const EDITOR_ELEMENTS_DISPLAY_ORDER_BETA: OnPageElementsTypeBeta[] = [
    OnPageElementsTypeBeta.URL,
    OnPageElementsTypeBeta.TITLE,
    OnPageElementsTypeBeta.META_DESCRIPTION,
    OnPageElementsTypeBeta.MISSING_H1,
    OnPageElementsTypeBeta.MISSING_H2,
    OnPageElementsTypeBeta.MISSING_H3,
    OnPageElementsTypeBeta.STRIPPED_PAGE
];

export const viewRecommendationTagHeadings = [
    { label: "Title", value: "title" },
    { label: "Meta Description", value: "meta_description" },
    { label: "H1", value: "h1" },
    { label: "H2", value: "h2" },
    { label: "H3", value: "h3" }
];

export const RunSimulationErrors = [
    {
        id: 301,
        status: "default",
        errMsg: "Simulation failed"
    }
];

export const DEFAULT_EDITOR_CONTENT: OnPageElementsResponseInterface = {
    missing_h1: [],
    missing_h2: [],
    meta_description: [],
    title: [],
    url: "",
    stripped_page: ""
};

export const SIMULATION_DOWNLOAD_FILE_NAME = "Simulated_Parameter.docx";
export const SIMULATION_DOWNLOAD_CONTENT_FILE_NAME = "Simulated_Content.docx";
export const SIMULATION_DOWNLOAD_ASSESSMENT_FILE_NAME = "Page_Assessment.docx";
export const SIMULATION_DOWNLOAD_PERFIX = "Parameter_";
export const FIB_REQ_DELAY = [2, 3, 5, 8, 10, 12, 15];

export const COMPETITOR_SCORE_SSE_EVENT_TYPES: CompetitorScoreSseEventInterface = {
    receive_db_data: "receive_db_data",
    receive_kw_h2_h3_scores: "receive_kw_h2_h3_scores",
    receive_kw_grouped_h2: "receive_kw_grouped_h2",
    receive_kw_grouped_h3: "receive_kw_grouped_h3",
    req_completed: "req_completed",
    req_failed: "req_failed",
    error: "error"
};

export const INITIAL_SCORE_LIST = {
    "H1": {
        value: undefined,
        deltaValue: undefined
    },
    "H2": {
        value: undefined,
        deltaValue: undefined
    },
    "H3": {
        value: undefined,
        deltaValue: undefined
    },
    "Title": {
        value: undefined,
        deltaValue: undefined
    },
    "Meta Description": {
        value: undefined,
        deltaValue: undefined
    },
    "Body Content": {
        value: undefined,
        deltaValue: undefined
    }
};

export const VIEW_ORIGINAL = "view_original";

export const EditorInstructions = {
    "Copying from a Word/Google Document": [
        "Simply copy and paste content from MS Word or Google Documents.",
        "The editor will retain the formatting (e.g. header elements H1, H2 etc.) of the page."
    ],
    "Adding a New Header": [
        "Type the header copy in the editor, select the copy and then click on appropriate header button (H1, H2, etc.) in the formatting tool bar."
    ],
    "Adding / Modifying Content": [
        "Updating Headers: Select the copy and then click on appropriate button (H1, H2 etc.) in the formatting tool bar.",
        "Removing Headers: Select the concerned header and then click on 'P' (for paragraph) in the formatting tool bar."
    ],
    "Export Content from Editor": [
        "Download in .csv Format: Click on download button in the top right corner section of the simulation screen.",
        "Copy to Word: Copy the content from ALPS editor and paste it in Word or Google document."
    ]
};

export const MODEL_SCORE_DROPDOWN_KEYS: ModelScoredDropdownKeysType[] = [
    { site: "URL", keyName: "url_score" },
    { site: "Title", keyName: "title_score" },
    { site: "Meta Description", keyName: "meta_description_score" },
    { site: "H1", keyName: "h1_score" },
    { site: "H2", keyName: "h2_score" },
    { site: "H3", keyName: "h3_score" },
    { site: "Body Content", keyName: "visible_content_score" },
    { site: "Readability", keyName: "readability_grade" },
    { site: "Content Score", keyName: "category_scores" },
    { site: "Rank", keyName: "organic_rank" },
    { site: "Traffic", keyName: "traffic" }
];

export const TagMapping: { [key: string]: ContextualRecomParamName } = {
    title: ContextualRecomParamName.TITLE,
    meta_description: ContextualRecomParamName.META_DESCRIPTION,
    H1: ContextualRecomParamName.H1,
    H2: ContextualRecomParamName.H2,
    H3: ContextualRecomParamName.H3,
};

export const headerTagMargins: HeaderTagMarginObj = {
    H1: 1,
    H2: 2,
    H3: 3,
    H4: 4,
    H5: 5,
    H6: 6
};

export const headerTypes = ["H1", "H2", "H3", "H4", "H5", "H6"];
export const RUN_SIMULATOR = "alert";
export const RESET_SIMULATOR = "alert reset";
export const MULTI_KW_NOT_SUBMITTED = "alert multi_kw_check";
export const KEYWORD_NOT_PROCESSED = "alert keyword_not_processed";
export const HOW_THE_EDITOR_WORKS = "info how_the_editor_works";
export const CONSENT_TO_PROCEED_ON_NEW_SIMULATION = "alert consent_to_proceed_on_new_simulation";

export const SIMULATOR_ALERT_NEW_RUN_SIMULATION = {
    MESSAGE:
        "Some data from the previous simulation is being processed and may take a few minutes. If you {{condition}}, some of the metrics from the previous simulation will not be available in simulation history.",
    DEFAULT_CONDITION: "simulate right now",
    INITIATE_CONDITION: "update URL/keywords right now",
    REFRESH_CONDITION: "continue with this refresh action",
    SWITCH_VERSION_TEXT: "switch the version right now"
};

export const subHeadingData = [
    "Fetching keyword search volumes",

    "Fetching Ranking pages for the keywords",

    "Fetching content for the pages",

    "Fetching backlink details of the pages",

    "Fetching Core Web Vitals",

    "Generating scores",

    "Running competitive analysis",

    "Generating recommendations"
];

export const DEFAULT_LOCALE = "en-us";
