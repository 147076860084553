import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import actionTypes from "./actions";
import { SerpResultReducerInterface } from "./types";

export const INIT_STATE: SerpResultReducerInterface = {
    callToSerp: undefined,
    progressPercentage: 0,
    serpPacks: [],
    serpSearchVolume: undefined,
    serpTopRank: undefined
};

const Reducer = handleActions(
    {
        [actionTypes.SET_TRACK_REQUEST]: (state, action: AnyAction) => ({
            ...state,
            ...action.payload
        }),
        [actionTypes.CALL_TO_CLEAR_SERP_DATA]: (state, action: AnyAction) => ({
            ...INIT_STATE
        })
    },
    INIT_STATE
);

export default Reducer;
