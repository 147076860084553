import { AnyAction } from "redux";
import { put, takeEvery, call, delay, cancel, select } from "redux-saga/effects";
import { isUndefined, omit } from "lodash";
import { resetKWASlice as resetKWASliceBeta } from "../../beta/key-word-analysis/ducks/actions";
import Apis from "../apis";
import { getMetaData, getViewALPSStatus, decodeString, getAllCompetitorsStatus } from "./utils";
import ActionTypes, {
    setTenantCode,
    setUserId,
    setUserName,
    setRequestIdInApp,
    setSerpDataInApp,
    udpateSerpListInApp,
    callToClearApp,
    setDomainInContext,
    setSerpListWhenTrackStop,
    setLocaleInContext,
    setKeyWordInContext,
    setHideLoader,
    setSerpProgressStatusFromHistoryTable,
    setAllCompetitorsStatus
} from "./actions";

import { InitiateRequestForRequestIdRequestInterface, FlowTypeInterface } from "./types";
import { SerpListProgressStatusType } from "../duck/types";
import { setTrackRequestBeta, callToClearSerpDataBeta, callToGetTrackRequest, actionTypes } from "../../beta/serp-result/ducks/actions";
import Store from "../store";
import { TRACK_TIME_OUT, SERP_COMPLETE_PROGRESS_MAX_PERCENTAGE, InitiateRequestErrors } from "../const";
import {
    getSimulatePerformanceFlag as getSimulatePerformanceFlagBeta,
    getSimulationsVersionChangeFlag as getSimulationsVersionChangeFlagBeta,
    getTargetUrlFlag
} from "../../beta/simulator/Components/AddModifyKeyword/ducks/selector";
import { setEnableCompetitorApi, setSelectedKeywords } from "../../beta/simulator/Components/AddModifyKeyword/ducks/actions";
// CONTEXT SAGA APIS

// tslint:disable-next-line:typedef
function* setContext(action: AnyAction) {
    const metaData = getMetaData();
    yield put(setUserId(metaData.user_id));
    yield put(setTenantCode(metaData.tenant_code));
    yield put(setUserName(metaData.user_email));
}

// APP SAGA APIS

// tslint:disable-next-line:typedef
function* callToGetRequestId(action: AnyAction) {
    yield put(callToClearApp());
    yield put(callToClearSerpDataBeta());
    yield put(resetKWASliceBeta());
    // yield put(resetSliceBeta()); commenting as this might be required later
    // yield put(resetMultiKeywordBeta());
    // we can put this berfore action disptach of clicking GO from page optimizer
    const payload: InitiateRequestForRequestIdRequestInterface = {
        ...(action.payload.search_url && { search_url: decodeString(action.payload.search_url) }),
        ...(action.payload.keyword && { keyword: action.payload.keyword }),
        ...(action.payload.locale && { locale: action.payload.locale }),
        ...(action.payload.project_id && { project_id: action.payload.project_id || null }),
        tenant_code: action.payload.tenant_code,
        ...omit(action.payload, ["flowType"]),
        needToHandleError: true,
        isSpecificError: true,
        isBeta: true,
        errorList: InitiateRequestErrors
    };

    yield put(setLocaleInContext(payload.locale));
    yield put(setKeyWordInContext(payload.keyword));
    if (action.payload.flowType !== FlowTypeInterface.PAGE_OPTIMIZER) {
        yield put(setDomainInContext(action.payload.search_url));
        yield put(setSelectedKeywords([payload.keyword]));
    }

    // We don't allow Single Keyword Track Request for PAGE OPTIMIZER
    // Page Optimizer initiates Multiple Keyword Track Request when clicked on "Go"
    if (action.payload.flowType !== FlowTypeInterface.PAGE_OPTIMIZER) {
        const response = yield call(Apis.initRequest, payload);
        if (response.status === 200) {
            yield put(setRequestIdInApp(response.data.req_id));
            yield put(setEnableCompetitorApi(false));
            if (action.payload.flowType === FlowTypeInterface.PAGE_OPTIMIZER) {
                if (!isUndefined(response.data.normalized_url)) {
                    yield put(setDomainInContext(response.data.normalized_url));
                }
            }
        } else {
            yield put(setHideLoader());
        }
    }
}

// tslint:disable-next-line:typedef
function* getTrackRequest(action: AnyAction) {
    const store = Store.getState();
    if (!isUndefined(store.app.requestId) && store.app.requestId === action.payload.requestId) {
        const payload = { ...action.payload, needToHandleError: true, isBeta: true };
        const isTargetUrlDone = yield select(getTargetUrlFlag);
        const areTargetURLsComplete = isTargetUrlDone && action.payload.flowType === FlowTypeInterface.PAGE_OPTIMIZER;
        const isSimulationVersionChanged = yield select(getSimulationsVersionChangeFlagBeta);
        const isMultiKwTrackRequestInitiated = yield select(getSimulatePerformanceFlagBeta);
        if (areTargetURLsComplete || isSimulationVersionChanged || !isMultiKwTrackRequestInitiated) {
            if (areTargetURLsComplete) {
                yield put(setSerpProgressStatusFromHistoryTable());
            }
            yield cancel();
        }
        const response = yield call(Apis.getTrackRequest, payload);
        if (response.status === 200) {
            yield put(
                setTrackRequestBeta({
                    callToSerp: response.data.task.rank.status,
                    progressPercentage: response.data.progress_percentage
                })
            );
            yield put(
                setSerpDataInApp({
                    enableViewALPSScore: getViewALPSStatus(response.data.task.target_urls, response.data.task.competitor_urls),
                    serpProcessedStatus: response.data.status
                })
            );
            yield put(setAllCompetitorsStatus(getAllCompetitorsStatus(response)));
            yield put(
                udpateSerpListInApp({
                    competitor_urls: response.data.task.competitor_urls,
                    target_urls: response.data.task.target_urls,
                    trackRequestdata: {
                        ...response.data.task.competitor_urls,
                        ...response.data.task.target_urls
                    }
                })
            );
            const elapsedTime = Date.now() - payload.startTime;
            if (elapsedTime > TRACK_TIME_OUT) {
                yield put(setSerpListWhenTrackStop());
                yield put(
                    setTrackRequestBeta({
                        progressPercentage: SERP_COMPLETE_PROGRESS_MAX_PERCENTAGE
                    })
                );
            } else if (
                response.data.status !== SerpListProgressStatusType.DONE &&
                response.data.status !== SerpListProgressStatusType.FAILED &&
                elapsedTime <= TRACK_TIME_OUT
            ) {
                yield delay(2000);
                yield put(
                    callToGetTrackRequest({
                        ...action.payload
                    })
                );
            }
        } else {
            yield put(setHideLoader());
        }
    }
}

// CONTEXT SAGA WATCHERS

// tslint:disable-next-line:typedef
export function* watchCallSetContext() {
    yield takeEvery(ActionTypes.CALL_FOR_SET_CONTEXT, setContext);
}


// APP SAGA WATCHERS

// tslint:disable-next-line:typedef
export function* watchForCallToGetRequestIdApp() {
    yield takeEvery(ActionTypes.CALL_TO_GET_REQUEST_ID_APP, callToGetRequestId);
}
// tslint:disable-next-line: typedef
export function* watchForGetTrackRequestCall() {
    yield takeEvery(actionTypes.CALL_TO_TRACK_REQUEST_ID, getTrackRequest);
}

