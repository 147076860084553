import * as React from "react";
import styled from "styled-components";

const StyledProgressBarWrapper = styled.div`
    position: relative;
`;

const StyledProgressBarSVG = styled.svg`
    transform: rotate(-90deg);
    fill: none;
    stroke-linecap: round;
`;

const StyledProgressBarSVGBackground = styled.circle`
    stroke: #cbccd3;
`;

const StyledProgressBarSVGFill = styled.circle`
    stroke-dashoffset: 8;
    stroke: ${(props: { isShowGrayColor?: boolean }) => (props.isShowGrayColor ? "#afafaf" : "#1fd8a6")};
`;

const StyledProgressInfoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
const StyledProgressInfoTitle = styled.div`
    font-size: 10px;
    color: #484c46;
    text-align: center;
`;
const StyledProgressInfoSubTitle = styled.div`
    font-size: 26px;
    text-align: center;
    margin-top: 5px;
    color: ${(props: { isShowGrayColor?: boolean }) => (props.isShowGrayColor ? "#afafaf" : "#1fd8a6")};
`;

interface ProgressBarPropsInterface {
    progress: number;
    instanceId?: string;
    enableViewScore?: boolean;
    isShowGrayColor?: boolean;
}

interface ProgressSVGPropsInterface {
    size?: number;
    progress: number;
    isShowGrayColor?: boolean;
}

const ProgressSVG: React.SFC<ProgressSVGPropsInterface> = ({ size = 108, progress = 0, isShowGrayColor }) => {
    const center = size / 2;
    const strokeWidth = size * 0.1;
    const radius = size / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;
    const addEXtraValue = progress > 0 ? 8 : 0;
    const offset = ((100 - progress) / 100) * circumference + addEXtraValue;
    const style = {
        strokeDashoffset: offset
    };
    return (
        <StyledProgressBarSVG width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
            <StyledProgressBarSVGBackground
                className="circle__background"
                cx={center}
                cy={center}
                r={radius}
                fill="#fff"
                strokeWidth={strokeWidth}
            />
            <StyledProgressBarSVGFill
                className="circle__fill"
                style={style}
                cx={center}
                cy={center}
                r={radius}
                strokeWidth={strokeWidth}
                strokeDasharray={circumference}
                isShowGrayColor={isShowGrayColor}
            />
        </StyledProgressBarSVG>
    );
};

const ProgressInfo: React.SFC<ProgressBarPropsInterface> = ({ progress, instanceId, isShowGrayColor }) => (
    <StyledProgressInfoWrapper id={`${instanceId}_percentage_wrapper`}>
        <StyledProgressInfoTitle>Completed</StyledProgressInfoTitle>
        <StyledProgressInfoSubTitle id={`${instanceId}_percentage_text`} isShowGrayColor={isShowGrayColor}>{Math.round(progress)}%</StyledProgressInfoSubTitle>
    </StyledProgressInfoWrapper>
);

class ProgressBar extends React.Component<ProgressBarPropsInterface, ProgressBarPropsInterface> {
    constructor(props: ProgressBarPropsInterface) {
        super(props);
        this.state = {
            progress: props.progress || 0
        };
    }
    public componentWillReceiveProps(nextProps: ProgressBarPropsInterface): void {
        if (this.state.progress !== nextProps.progress) {
            const getUpdatedProgress = (progress: number) => {
                if (progress > 100) {
                    return 100;
                }
                if (progress < 0) {
                    return 0;
                }
                return progress;
            };
            this.setState({ progress: getUpdatedProgress(nextProps.progress) });
        }
    }
    public render(): JSX.Element {
        const { progress } = this.state;
        let isShowGrayColor = false;
        if (progress === 100 && !this.props.enableViewScore) {
            isShowGrayColor = true;
        }
        return (
            <StyledProgressBarWrapper>
                <ProgressSVG progress={progress} isShowGrayColor={isShowGrayColor} />
                <ProgressInfo progress={progress} instanceId={this.props.instanceId} isShowGrayColor={isShowGrayColor} />
            </StyledProgressBarWrapper>
        );
    }
}

export default ProgressBar;
