import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */
const actionTypes = {
    CALL_TO_CHANGE_TENANT: "CALL_TO_CHANGE_TENANT",
    CALL_USER_LOGOUT: "CALL_USER_LOGOUT",
    USER_LOGOUT_STATUS: "USER_LOGOUT_STATUS",
    CHECK_SSO_USER_LOGIN: "CHECK_SSO_USER_LOGIN",
    USER_LOGIN: "USER_LOGIN",
    USER_LOGIN_WITH_TENANT: "USER_LOGIN_WITH_TENANT",
    SET_SSO_USER: "SET_SSO_USER",
    SET_LOGGING_IN: "SET_LOGGING_IN",
    SET_TENANT_LIST: "SET_TENANT_LIST",
    SET_MULTI_TENANT: "SET_MULTI_TENANT",
    CALL_USER_LOGIN_SSO: "CALL_USER_LOGIN_SSO",
    SET_ERR_MSG: "SET_ERR_MSG",
    USER_FORGOT_PWD: "USER_FORGOT_PWD",
    USER_CHANGE_PWD: "USER_CHANGE_PWD",
    USER_RESET_PWD: "USER_RESET_PWD",
    RESET_PWD_STATUS_CODE: "RESET_PWD_STATUS_CODE",
    SET_TO_PAGE_NOT_FOUND: "SET_TO_PAGE_NOT_FOUND",
    CHANGE_PWD_STATUS_CODE: "CHANGE_PWD_STATUS_CODE",
    FORGOT_PWD_STATUS_CODE: "FORGOT_PWD_STATUS_CODE",
    SET_USER_ID: "SET_USER_ID",
    SET_COGNITO_CLIENT_ID: "SET_COGNITO_CLIENT_ID",
    SET_COGNITO_API: "SET_COGNITO_API",
    SET_INTMD_ID: "SET_INTMD_ID"
};

export default actionTypes;

export const callToChangeUserTenant = createAction(actionTypes.CALL_TO_CHANGE_TENANT);
export const setUserLogout = createAction(actionTypes.CALL_USER_LOGOUT);
export const setUserLogoutStatus = createAction(actionTypes.USER_LOGOUT_STATUS);
export const callToCheckSSOLogin = createAction(actionTypes.CHECK_SSO_USER_LOGIN);
export const callToUserLoginWithTenant = createAction(actionTypes.USER_LOGIN_WITH_TENANT);
export const setSSOUser = createAction(actionTypes.SET_SSO_USER);
export const setLogginIn = createAction(actionTypes.SET_LOGGING_IN);
export const callToUserLogin = createAction(actionTypes.USER_LOGIN);
export const setTenantList = createAction(actionTypes.SET_TENANT_LIST);
export const setMultiTenant = createAction(actionTypes.SET_MULTI_TENANT);
export const callUserLoginSSO = createAction(actionTypes.CALL_USER_LOGIN_SSO);
export const setErrMsg = createAction(actionTypes.SET_ERR_MSG);
export const setUserForgotPwd = createAction(actionTypes.USER_FORGOT_PWD);
export const callToChangeUserPwd = createAction(actionTypes.USER_CHANGE_PWD);
export const callToGetUserForgotPwd = createAction(actionTypes.USER_RESET_PWD);
export const callToGetResetPwdStatusCode = createAction(actionTypes.RESET_PWD_STATUS_CODE);
export const setToPageNotFound = createAction(actionTypes.SET_TO_PAGE_NOT_FOUND);
export const callToGetChangePwdStatusCode = createAction(actionTypes.CHANGE_PWD_STATUS_CODE);
export const callToGetForgotPwdStatusCode = createAction(actionTypes.FORGOT_PWD_STATUS_CODE);
export const setUserId = createAction(actionTypes.SET_USER_ID);
export const setCognitoClientID = createAction(actionTypes.SET_COGNITO_CLIENT_ID);
export const setCognitpAPI = createAction(actionTypes.SET_COGNITO_API);
export const setIntmdId = createAction(actionTypes.SET_INTMD_ID);
