import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import { CompetitiveAnalysisReducerInterface } from "./types";
import actionTypes from "./actions";
import { isEmpty, isUndefined } from "lodash";
import {
    getImpactedKeywordsandUrlsList,
    getSortedKeywordBySearchVolume,
    getTrimmedTargetURL,
    getTopCompetitorsListForHighestRankingKw,
    getImpactedKeywordUrlList,
    getUpdatedFilteredKeywords,
    getUpdatedFilteredUrls
} from "./utils";

export const INIT_STATE: CompetitiveAnalysisReducerInterface = {
    filteredKeyword: [],
    filteredUrl: [],
    impactedKeywordsandUrls: undefined,
    impactedKeywordsUrlList: undefined,
    displayImpactedKeywordsandUrls: []
};

const CompetitiveAnalysisReducer = handleActions(
    {
        [actionTypes.SET_LAZY_LOADING_IMPACTED_KEYWORDS_AND_URLS]: (state, action: AnyAction) => ({
            ...state,
            displayImpactedKeywordsandUrls: action.payload
        }),
        [actionTypes.CALL_TO_GET_IMPACTED_KEYWORDS_AND_URLS_LIST]: (state, action: AnyAction) => ({
            ...state,
            impactedKeywordsandUrls: !isUndefined(action.payload.impactedKeywordsUrlList)
                ? getImpactedKeywordsandUrlsList(action.payload.impactedKeywordsUrlList, state.filteredKeyword, state.filteredUrl)
                : undefined
        }),
        [actionTypes.APPLY_FILTER_ON_KEYWORD_LEVEL_IMPACT]: (state, action: AnyAction) => ({
            ...state,
            filteredKeyword: !isUndefined(action.payload.filtered_keyword_list)
                ? action.payload.filtered_keyword_list
                : !isUndefined(action.payload.impactedKeywords)
                ? !isUndefined(action.payload.keyword)
                    ? [action.payload.keyword]
                    : isEmpty(state.filteredKeyword)
                    ? [getSortedKeywordBySearchVolume(action.payload.impactedKeywords)[0].keyword]
                    : state.filteredKeyword
                : state.filteredKeyword,
            filteredUrl: !isUndefined(action.payload.filtered_url_list)
                ? action.payload.filtered_url_list
                : !isUndefined(action.payload.impactedKeywords) && !isUndefined(action.payload.impactedKeywordsUrlList)
                ? !isUndefined(action.payload.keyword)
                    ? [
                          getTrimmedTargetURL(
                              action.payload.impactedKeywords,
                              action.payload.impactedKeywordsUrlList,
                              action.payload.keyword
                          ),
                          ...getTopCompetitorsListForHighestRankingKw(
                              action.payload.impactedKeywords,
                              action.payload.impactedKeywordsUrlList,
                              action.payload.keyword
                          )
                      ]
                    : isEmpty(state.filteredUrl)
                    ? [
                          getTrimmedTargetURL(action.payload.impactedKeywords, action.payload.impactedKeywordsUrlList),
                          ...getTopCompetitorsListForHighestRankingKw(
                              action.payload.impactedKeywords,
                              action.payload.impactedKeywordsUrlList
                          )
                      ]
                    : state.filteredUrl
                : state.filteredUrl
        }),
        [actionTypes.SET_IMPACTED_KEYWORD_LIST]: (state, action: AnyAction) => ({
            ...state,
            impactedKeywordsUrlList:
                !isUndefined(action.payload.impactedKeywords) && !isEmpty(action.payload.impactedKeywords)
                    ? getImpactedKeywordUrlList(
                          action.payload.impactedKeywords,
                          action.payload.competitorsScore,
                          action.payload.keywordsRequested,
                          action.payload.url,
                          !isUndefined(action.payload.changedPageContent) ? action.payload.changedPageContent : action.payload.pageContent,
                          action.payload.authority,
                          action.payload.technical
                      )
                    : undefined
        }),
        [actionTypes.UPDATE_FILTERS_ON_KEYWORD_LEVEL_IMPACT]: (state, action: AnyAction) => ({
            ...state,
            filteredKeyword: !isUndefined(action.payload.selectedKeywords)
                ? getUpdatedFilteredKeywords(state, action.payload.selectedKeywords)
                : state.filteredKeyword,
            filteredUrl: !isUndefined(action.payload.selectedKeywords)
                ? getUpdatedFilteredUrls(state, action.payload.selectedKeywords)
                : state.filteredUrl
        }),
        [actionTypes.CLEAR_COMP_ANALYSIS_DATA]: (state, action: AnyAction) => ({
            ...state,
            ...INIT_STATE
        })
    },
    INIT_STATE
);

export default CompetitiveAnalysisReducer;
