import { AnyAction } from "redux";
import { assign, isUndefined } from "lodash";
import { handleActions } from "redux-actions";
import { AppliedFilter } from "../../suggested-keywords/ducks/types";
import { APPLIED_FILTERS_ON_KEYWORD, KW_RESEARCH_PAGE_SEARCH_TYPE_OPTIONS } from "../../app/const";
import { formattedRelatedKeywords, updateValueInAppliedFilter } from "../../suggested-keywords/ducks/utils";
import ActionTypes from "./actions";
import { KwResearchReducerInterface } from "./types";
import { advanceFilterSearch, findAdvanceSearchKeyword } from "./utils";

export const INIT_STATE: KwResearchReducerInterface = {
    selectedInputType: KW_RESEARCH_PAGE_SEARCH_TYPE_OPTIONS[0].value as string,
    searchInputText: "",
    requestId: undefined,
    receiveRelatedKwdsEventCount: 0,
    sseRetriedAttempt: 0,
    isRelatedKeywordFetching: false,
    isLoading: false,
    relatedKeywords: [],
    suggestedKeywords: [],
    appliedFiltersOnKW: APPLIED_FILTERS_ON_KEYWORD,
    filteredSearchKW: undefined,
    clearAllKWFilter: false,
    includeKeywordType: "all",
    searchIncludeKeywords: [],
    searchExcludeKeywords: [],
    filterType: "",
    selectedSearchURLs: []
};
const Reducer = handleActions(
    {
        [ActionTypes.SET_KW_RESEARCH_INPUT_TYPE]: (state, action: AnyAction) => ({
            ...state,
            selectedInputType: action.payload
        }),
        [ActionTypes.SET_KW_RESEARCH_INPUT_VALUE]: (state, action: AnyAction) => ({
            ...state,
            searchInputText: action.payload
        }),
        [ActionTypes.SET_KW_RESEARCH_REQUEST_ID]: (state, action: AnyAction) => ({
            ...state,
            requestId: action.payload
        }),
        [ActionTypes.INCREMENT_RECEIVE_RELATED_KWDS_EVENT_COUNT]: (state, action: AnyAction) => ({
            ...state,
            receiveRelatedKwdsEventCount: state.receiveRelatedKwdsEventCount + 1
        }),
        [ActionTypes.RESET_KW_RESEARCH]: (state, action: AnyAction) => ({
            ...INIT_STATE
        }),
        [ActionTypes.SET_RELATED_KEYWORDS_FETCHING]: (state, action: AnyAction) => ({
            ...state,
            isRelatedKeywordFetching: action.payload,
        }),
        [ActionTypes.SET_SSE_RETRIED_ATTEMPT]: (state, action: AnyAction) => ({
            ...state,
            sseRetriedAttempt: action.payload
        }),
        [ActionTypes.SET_SHOW_LOADER]: (state, action: AnyAction) => ({
            ...state,
            isLoading: true
        }),
        [ActionTypes.SET_HIDE_LOADER]: (state, action: AnyAction) => ({
            ...state,
            isLoading: false
        }),
        [ActionTypes.RESET_RECEIVE_RELATED_KWDS_EVENT_COUNT]: (state, action: AnyAction) => ({
            ...state,
            receiveRelatedKwdsEventCount: 0
        }),
        [ActionTypes.SET_RELATED_KW]: (state, action: AnyAction) => ({
            ...state,
            relatedKeywords: formattedRelatedKeywords(action.payload),
            filteredSearchKW: formattedRelatedKeywords(action.payload),
            suggestedKeywords: action.payload
        }),
        [ActionTypes.APPLIED_KW_FILTERS]: (state, action: AnyAction) => ({
            ...state,
            appliedFiltersOnKW: updateValueInAppliedFilter(assign({}, action.payload), assign({}, state.appliedFiltersOnKW)),
            filteredSearchKW: !isUndefined(action.payload)
                ? action.payload.type === AppliedFilter.KEYWORD_ADVANCE_FILTER
                    ? advanceFilterSearch(
                        state.searchIncludeKeywords,
                        state.searchExcludeKeywords,
                        state.includeKeywordType,
                        state.relatedKeywords,
                        state.filterType)
                    : findAdvanceSearchKeyword(
                        state.appliedFiltersOnKW,
                        state.relatedKeywords,
                        state.includeKeywordType,
                        state.searchIncludeKeywords,
                        state.searchExcludeKeywords,
                    )
                : undefined
        }),
        [ActionTypes.CLEAR_APPLIED_KW_FILTERS]: (state, action: AnyAction) => ({
            ...state,
            clearAllKWFilter: action.payload,
            selectedSearchURLs: [],
            appliedFiltersOnKW: action.payload ?
                {
                    ...APPLIED_FILTERS_ON_KEYWORD,
                    searchvolume: [],
                    keywordtype: [],
                    relevancescore: [],
                    search_by_urls: [],
                    rank: []
                } : state.appliedFiltersOnKW,
        }),
        [ActionTypes.KRT_INCLUDE_KEYWORD_TYPE]: (state, action: AnyAction) => ({
            ...state,
            includeKeywordType: action.payload
        }),
        [ActionTypes.SET_INCLUDE_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            searchIncludeKeywords: action.payload
        }),
        [ActionTypes.SET_EXCLUDE_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            searchExcludeKeywords: action.payload
        }),
        [ActionTypes.SET_ADVANCE_FILTER_TYPE]: (state, action: AnyAction) => ({
            ...state,
            filterType: action.payload
        }),
        [ActionTypes.SET_SELECTED_SEARCH_URL]: (state, action: AnyAction) => ({
            ...state,
            selectedSearchURLs: action.payload
        }),
    },
    INIT_STATE
);

export default Reducer;
