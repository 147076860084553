export interface EmailVerificationReducerInterface {
    userEmailVrificaitonStatus?: string;
    userEmailID?: string;
}

export enum UserEmailVerificationStatus {
    SUCCESS = "User verified successfully",
    ALREADY_VERIFIED = "Already Verified",
    INVALID_LINK = "Invalid Token",
    FALIURE = "User verification failed",
    LINK_EXPIRED = "Token Expired",
    RESEND_MAIL_SUCCESS = "Verification mail sent successfully",
    RESEND_MAIL_FAILED = "No user details found for the given User ID"
}

export enum UserEVButton {
    LOGIN = "login",
    RESEND = "resend",
    DISABLE = "disable"
}
