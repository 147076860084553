import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import { DEFAULT_CONTENT_BRIEF_LOCALE } from "../../app/const";
import actionTypes from "./actions";
import { ContentBriefNewReducerInterface } from "./types";

export const INIT_STATE: ContentBriefNewReducerInterface = {
    selectedKeywords: [],
    isLoading: false,
    saveContentBriefStatus: 0,
    contentBriefSubmitData: {
        selectedLocale: DEFAULT_CONTENT_BRIEF_LOCALE,
        topic: "",
        id: "",
        name: ""
    },
    defaultKeywords: [],
    cbProgress: 0,
    isCBProgressLoading: false,
    saveContentBrief: {
        id: "",
        name: "",
        topic: "",
        created_by: "",
        description: "",
        locale: "",
        progress: 0,
        retry_count: 0,
        keywords: []
    }
};

const Reducer = handleActions(
    {
        [actionTypes.SET_SELECTED_CB_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            selectedKeywords: action.payload
        }),
        [actionTypes.SET_SAVE_CONTENT_BRIEF_STATUS]: (state, action: AnyAction) => ({
            ...state,
            saveContentBriefStatus: action.payload
        }),
        [actionTypes.SET_SHOW_LOADER]: (state, action: AnyAction) => ({
            ...state,
            isLoading: true
        }),
        [actionTypes.SET_HIDE_LOADER]: (state, action: AnyAction) => ({
            ...state,
            isLoading: false
        }),
        [actionTypes.IS_SUBMIT_CONTENT_BRIEF]: (state, action: AnyAction) => ({
            ...state,
            contentBriefSubmitData: action.payload
        }),
        [actionTypes.SET_DEFAULT_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            defaultKeywords: action.payload
        }),
        [actionTypes.SET_SAVE_CONTENT_BRIEF]: (state, action: AnyAction) => ({
            ...state,
            saveContentBrief: action.payload
        }),
        [actionTypes.SET_CB_PROGRESS]: (state, action: AnyAction) => ({
            ...state,
            cbProgress: action.payload
        }),
        [actionTypes.IS_CB_PROGRESS_LOADING]: (state, action: AnyAction) => ({
            ...state,
            isCBProgressLoading: action.payload
        })
    },
    INIT_STATE
);

export default Reducer;
