import {
    SerpResponseInterface,
    CompetitorAndTargetURLsListInterface,
    ContextReducerInterface,
    ParserInterface,
    KeywordRequestIdsInterface,
    MultiKeywordTrackRequestApiRequestIdsResponseInterface,
    FlowTypeInterface,
    LocaleInterface,
    NotifyErrorInterface,
    CategoryType
} from "../../../../../app/duck/types";
import {
    PageNotificationInterface,
    InitialParametersInterface,
    InitialParametersforFileuploadInterface,
    EditorElementInterfaceBeta,
    VersionListInputPayloadInterface,
    MainNotificationInterface,
    KeywordStuffingInterface,
    ModelScoreDetailsResponseInterface,
    SimulateKeywordStuffingPayloadInterface
} from "../../../../simulator/ducks/types";
import { KeywordsInterface, OptionTypeInterface } from "../../../../../app/styledComponents/drop-down/types";
import { FormDataInterface } from "../../../../kw-url-search/ducks/types";

export interface MultiKeywordOnPageElementsApiRequestInterface extends NotifyErrorInterface {
    keywords: string[];
    locale: string;
    url: string;
    fetchImpactedKw?: boolean;
}

export interface MultiKeywordOnPageElementsApiFileUploadRequestInterface {
    request_id: string;
}

export type MultiKeywordPerformanceScoreApiRequestInterface = MultiKeywordOnPageElementsApiRequestInterface;

export type MultiKeywordModelScoreDetailsApiRequestInterface = MultiKeywordOnPageElementsApiRequestInterface;

export type MultiKeywordModelScoreApiRequestInterface = MultiKeywordOnPageElementsApiRequestInterface;

export interface MultiKeywordCompetitorsScoreApiRequestInterface {
    keywords: string[];
    locale: string;
    domain: string;
    project_id?: number;
}

export interface MultiKeywordThemeKeywordFromProjectApiRequestInterface extends ParserInterface, NotifyErrorInterface {
    url: string;
    locale: string;
}
export interface MultiKeywordTrackRequestApiResponseInterface {
    are_competitors_processed: boolean;
    are_my_urls_processed: boolean;
    is_request_completed: boolean;
    request_ids: MultiKeywordTrackRequestApiRequestIdsResponseInterface;
}

export interface MultiKeywordActionsInterface {
    setSelectedKeywords(keywords: string[]): void;
    setEnableSimulateScore(status: boolean): void;
    setTargetUrl(url: string): void;
    setIsSubmitted(status: boolean): void;
    setKeywordRequestIds(requestIds: KeywordRequestIdsInterface): void;
    setShowLoader(): void;
    setRevokeLoader(): void;
    setNotificationOnVersion(args: MainNotificationInterface): void;
    callToGetMultiKeywordRequestIdsApi(requestPayload: MultiKeywordRequestIdsApiRequestInterface): void;
    callToGetOnPageElements(args: InitialParametersInterface | InitialParametersforFileuploadInterface): void;
    callToGetPerformanceScore(requestPayload: MultiKeywordPerformanceScoreApiRequestInterface): void;
    callToGetModelScoreDetails(requestPayload: MultiKeywordModelScoreDetailsApiRequestInterface): void;
    callToGetKeywordStuffingDetails(args: SimulateKeywordStuffingPayloadInterface): void;
    callToGetKeywordDetails(requestPayload: MultiKeywordModelScoreDetailsApiRequestInterface): void;
    callToGetModelScore(requestPayload: MultiKeywordModelScoreApiRequestInterface): void;
    setSimulationNotification(args: PageNotificationInterface): void;
    callToGetThemeKeywordFromProject(requestPayload: MultiKeywordThemeKeywordFromProjectApiRequestInterface): void;
    setToShowProject(args: boolean): void;
    appliedFiltesOnKeyword(args: KeywordFilterInterface): void;
    resetMultiKeyword(): void;
    setPresimulatedData(args: boolean): void;
    selectedMultiKeywordLocale(args: string): void;
    callToGetLocales(args: LocaleInterface[]): void;
    setIsLiveUrlStatus(args: boolean): void;
    setKeywordUrlBoxExpandedMode(args: boolean): void;
    setIsUrlLiveInMkw(args: boolean): void;
    SettingOnPageStatusCode(args?: string): void;
    callFileuploadMkw(args: FormDataInterface): void;
    setFileNameMkw(args: string): void;
    setFileuploadRequestIdMkw(args: string): void;
    setClearFile(args: boolean): void;
    setOnlyChangedContent(args?: EditorElementInterfaceBeta[]): void;
    callToGetVersionList(args: VersionListInputPayloadInterface): void;
    setSelectedVersion(args?: string): void;
    setSaveDescription(args: string): void;
    setRefreshButtonClicked(args: boolean): void;
    callLiveNonLiveChange(args: boolean): void;
    setIsFileUploaded(args: boolean): void;
    setMultiKeywordSectionType(args: string): void;
    setCurrentPaginationKeywords(args: OptionTypeInterface[]): void;
    callToGetRelatedKeywordRequestIdsApi(args: RelatedRequestIdsApiRequestInterface): void;
    setTopicKeyword(args: string): void;
    setMkwDomainUrl(url?: string): void;
    setTopicKeywordNonLive(args: string): void;
    clearOldSimulationState(): void;
    setIncludeKeywordType(args: string): void;
    setIsViewRecommendationOpen(args: boolean): void;
    setRelatedKeywords(args?: OptionTypeInterface[]): void;
    setRelatedKeywordsResponseType(args: RelatedKeywordSSEResponseType): void;
    resetSSEResponseStatesAfterMerge(): void;
    cancelOnGoingSSEURLRequest(): void;
    callToSetSidNext(args: string): void;
    setSelectedCategoryType(args: CategoryType): void;
    setImpactedKeywords(args: { selectedKeywords?: string[] }): void;
    updateFiltersOnKeywordLevelImpact(args: { selectedKeywords?: string[] }): void;
    setKeywordsProcessingStatus(args: boolean): void;
    clearCompetitiveAnalysisData(): void;
    setRefreshNowClicked(args: boolean): void;
}

export interface MultiKeywordPropsInterface extends MultiKeywordPropsInterfaceBeta {
    actions: MultiKeywordActionsInterface;
    selectedDomain?: string;
    sseRetriedAttempt: number;
    sseNonLiveRetriedAttempt: number;
    selectedLocale?: string;
    defaultKeyword?: string;
    mkwDomainUrl?: string;
    defaultKeywordSearchVolume?: number;
    selectedTargetUrl?: string;
    targetUrls?: OptionTypeInterface[];
    keywordTypeDropdownList: OptionTypeInterface[];
    appKeywordRequestId: string;
    keywordRequestIds: KeywordRequestIdsInterface;
    serpList: SerpResponseInterface[];
    competitors: CompetitorAndTargetURLsListInterface;
    isLoading: boolean;
    isUploadInProgress: boolean;
    instanceId: string;
    context: ContextReducerInterface;
    isProjectContext: boolean;
    locales: LocaleInterface[];
    flowType?: FlowTypeInterface;
    urlPresenceStatus: boolean;
    url: string;
    changedPageContent?: EditorElementInterfaceBeta[];
    originalKeywordList: OptionTypeInterface[] | undefined;
    selectedVersion?: string;
    description: string;
    liveNonLiveFlow: boolean;
    selectedSID?: string;
    showSimulationConsent: boolean;
    isScoreTransformationDone: boolean;
    isUrlLive: boolean;
    isUrlLiveInMkw?: boolean;
    relatedKeywordResponseType?: RelatedKeywordSSEResponseType;
    keywordList: OptionTypeInterface[] | undefined;
    keywordListFromURLParam?: OptionTypeInterface[];
    keywordListFromKwParam?: OptionTypeInterface[];
    keywordStuffingDetails: KeywordStuffingInterface;
    modelScoreDetails?: ModelScoreDetailsResponseInterface;
    pageContent: EditorElementInterfaceBeta[];
    isTopbandEditClicked: boolean;
    selectedCategoryType: CategoryType;
    isAddEditKeywordVisible: boolean;
    toggleCompAnalysisLoadingNotification(args: boolean): void;
    setIsTopbandEditClicked(arg: boolean): void;
    handleProceedButtonClick?(arg: boolean): void;
    toogleAddEditKeywordModal(): void;
    clearCompetitiveAnalysisData(): void;
}

export interface MultiKeywordStateInterface {
    isExpanded: boolean;
    editorShouldReset: boolean;
    isKeywordThemeAPICalled: boolean;
    submitInLiveOrNonLive: boolean;
    enableSimulatePerformance: boolean;
    enableSimulateScore: boolean;
    showModal: boolean;
    openedModalType: string;
    localStateTargetUrl?: string;
    isMyUrlChanged: boolean;
    selectedLocale: string;
    domainInputTextError: string;
    isUrlPresenceChanged?: boolean;
    emptyKeywordTextError: string;
    selectedFile: File | null;
    fileName?: string;
    clearFile: boolean;
    keywordSortType?: OptionTypeInterface;
    journeyChange: boolean;
    showProjectKeyword: boolean;
    fetchButtonMode: boolean;
    fetchButtonModeNonLive: boolean;
    KSMessage: boolean;
}

export interface MultiKeywordReducerInterface {
    isThemeKeywordLoading: boolean;
    isRelatedKeywordFetching: string | boolean;
    isRelatedKeywordURLParamFetching: string | boolean;
    isRelatedKeywordKwParamFetching: string | boolean;
    isRelatedKeywordNonLiveFetching: string | boolean;
    brandList: string[];
    keywordTypeDropdownList: OptionTypeInterface[];
    enableSimulatePerformance: boolean;
    enableSimulateScore: boolean;
    keywordRequestIds?: KeywordRequestIdsInterface;
    keywordProcessedInfo: MultiKeywordTrackRequestApiRequestIdsResponseInterface;
    selectedKeywords: string[];
    isSubmitted: boolean;
    isLoading: boolean;
    themeList: OptionTypeInterface[];
    keywords?: KeywordsInterface;
    projectkeywordList?: OptionTypeInterface[];
    keywordList: OptionTypeInterface[];
    pageKeywordList: OptionTypeInterface[];
    keywordListFromURLParam: OptionTypeInterface[];
    keywordListFromKwParam: OptionTypeInterface[];
    isPartialKeywordReady: boolean;
    processedKeyword: string[];
    filteredSearchKeyword?: OptionTypeInterface[];
    appliedFiltersOnKeyword: AppliedFilterOnKeywordInterface;
    selectedMultiKeywordLocale?: string;
    isExpanded: boolean;
    is_url_live_in_mkw?: boolean;
    isFileUploaded: boolean;
    fileName?: string;
    sseRetriedAttempt: number;
    sseRetriedAttemptURLParam: number;
    sseRetriedAttemptKwParam: number;
    sseNonLiveRetriedAttempt: number;
    fileUpload_requestId?: string;
    clearFile: boolean;
    isRefreshButtonClicked?: boolean;
    selectedkeywordSectionType: string;
    topicKeyword?: string;
    topicKeywordNonLive?: string;
    enableCompetitorApi: boolean;
    multikeywordDomainUrl?: string;
    mkwTrackInitiated: boolean;
    liveNonLiveFlow: boolean;
    searchIncludeKeywords: string[];
    searchExcludeKeywords: string[];
    includeKeywordType?: string;
    filterType?: string;
    showLoaderBar: boolean;
    isProceedButtonClicked?: boolean;
    isTrackRequestStart: boolean;
    relatedKeywordResponseType?: RelatedKeywordSSEResponseType;
    isSSeDataFromUrlAndKeywordMerged: boolean;
    isTop3CompDataForAllKWsAvailable?: boolean;
    isPageNotCrawled?: boolean;
    isRefreshNowClicked?: boolean;
}

export interface MultiKeywordPropsInterfaceBeta {
    isThemeKeywordLoading: boolean;
    isRelatedKeywordFetching: string | boolean;
    isRelatedKeywordURLParamFetching: string | boolean;
    isRelatedKeywordKwParamFetching: string | boolean;
    isRelatedKeywordNonLiveFetching: string | boolean;
    brandList: string[];
    keywordTypeDropdownList: OptionTypeInterface[];
    enableSimulatePerformance: boolean;
    enableSimulateScore: boolean;
    keywordRequestIds?: KeywordRequestIdsInterface;
    keywordProcessedInfo: MultiKeywordTrackRequestApiRequestIdsResponseInterface;
    selectedKeywords: string[];
    isSubmitted: boolean;
    isLoading: boolean;
    themeList: OptionTypeInterface[];
    keywords?: KeywordsInterface;
    projectkeywordList?: OptionTypeInterface[];
    keywordList?: OptionTypeInterface[];
    pageKeywordList: OptionTypeInterface[];
    isPartialKeywordReady: boolean;
    processedKeyword: string[];
    filteredSearchKeyword?: OptionTypeInterface[];
    appliedFiltersOnKeyword: AppliedFilterOnKeywordInterface;
    selectedMultiKeywordLocale?: string;
    isExpanded: boolean;
    is_url_live_in_mkw?: boolean;
    isFileUploaded: boolean;
    fileName?: string;
    sseRetriedAttempt: number;
    sseRetriedAttemptURLParam: number;
    sseRetriedAttemptKwParam: number;
    sseNonLiveRetriedAttempt: number;
    fileUpload_requestId?: string;
    clearFile: boolean;
    isRefreshButtonClicked?: boolean;
    selectedkeywordSectionType: string;
    topicKeyword?: string;
    topicKeywordNonLive?: string;
    enableCompetitorApi: boolean;
    multikeywordDomainUrl?: string;
    mkwTrackInitiated: boolean;
    liveNonLiveFlow: boolean;
    searchIncludeKeywords: string[];
    searchExcludeKeywords: string[];
    includeKeywordType?: string;
    filterType?: string;
    isSSeDataFromUrlAndKeywordMerged: boolean;
    isRefreshNowClicked: boolean;
}

export interface MultiKeywordRequestIdsApiRequestInterface extends NotifyErrorInterface {
    keyword: string[];
    search_url: string[];
    locale: string;
    isBeta?: boolean;
}

export interface MultiKeywordTrackRequestApiRequestInterface extends NotifyErrorInterface {
    req_id: string[];
    domain: string[];
    isBeta?: boolean;
}

export interface ThemeResponseInterface {
    target_url: string;
    name: string;
    priority?: number;
    keywords: string[];
    purchase_cycle?: number;
    id: number;
}

export interface KeywordListInterface {
    [key: string]: KeywordListTypeInterface;
}
export interface KeywordListTypeInterface {
    type: string;
    id: number;
    is_ranking: boolean;
    search_volume: number;
}
export interface CheckedItemInterface {
    [key: string]: boolean;
}
export enum AddRemoveKeyword {
    ADD = "add",
    REMOVE = "remove"
}
export enum AppliedFilter {
    SELECTALL = "selectall",
    THEME = "theme",
    BRANDTYPE = "brandtype",
    KEYWORDFILTER = "keywordfilter",
    KEYWORDTYPE = "keywordtype",
    SEARCHVOLUME = "searchvolume",
    RELEVANCESCORE = "relevancescore",
    SORTTYPE = "sorttype",
    KEYWORD_ADVANCE_FILTER = "keyword_advance_filter",
    KEYWORD_SEARCH_ON_INPUT = "keyword_search_on_input"
}

export interface AppliedFilterOnKeywordInterface {
    [AppliedFilter.SELECTALL]: boolean;
    [AppliedFilter.THEME]: number[];
    [AppliedFilter.KEYWORDTYPE]: number[];
    [AppliedFilter.BRANDTYPE]: undefined | string[];
    [AppliedFilter.KEYWORDFILTER]: string | undefined;
    [AppliedFilter.SEARCHVOLUME]: string[];
    [AppliedFilter.RELEVANCESCORE]: string[];
    [AppliedFilter.SORTTYPE]: OptionTypeInterface;
    [AppliedFilter.KEYWORD_ADVANCE_FILTER]?: string[];
}

export interface KeywordFilterInterface {
    type: AppliedFilter;
    value: string | undefined | number | string[] | number[] | boolean | OptionTypeInterface;
}
export enum BrandingFilter {
    BRANDED = "branded",
    NON_BRANDED = "non_branded"
}
export interface DiscoverKeywordInterface {
    isOpen: boolean;
    disabled: boolean;
}

export interface LeftPanAccrodionHeadingInterface {
    isOpen: boolean;
}

export interface MultiKeywordSelectFromProjectTextInterface {
    discoverArrow: string;
}
export interface RelatedRequestIdsApiRequestInterface extends NotifyErrorInterface {
    url?: string;
    keyword?: string;
    locale?: string;
    source?: string;
}

export interface RelatedKeywordsInterface {
    keyword: string;
    search_volume: number;
    cpc: number;
    relevance_score?: number;
    type?: string;
}

export enum NotificationParams {
    DISABLED = "disabled",
    IS_MEX_LIMIT_REACHED = "isMaxLimitReached",
    IS_SSE_FAILED = "isSseFailed",
    IS_NON_LIVE_SSE_FAILED = "isSseNonLiveFailed",
    SSE_FAILED_NOTIFICATION = "sseFailedNotification",
    SSE_RETRIED_THREE_TIMES = "sseRetriedThreeTimes",
    NO_KWS_FOUND_NON_LIVE = "noKwsFoundInNonLive",
    NO_KWS_FOUND_LIVE = "noKwsFoundInLive",
    IS_REL_SCORE_NOT_PRESENT_LIVE = "isRelScoreNotPresentLive",
    IS_REL_SCORE_NOT_PRESENT_NON_LIVE = "isRelScoreNotPresentNonLive",
    SSE_RETRY_NOTIFICATION = "sseRetryNotification",
    NON_LIVE_DISABLED = "nonLiveDisabled",
    SSE_RETRIED_NON_LIVE_THREE_TIMES = "sseRetriedNonLiveThreeTimes",
    SSE_RETRY_NON_LIVE_NOTIFICATION = "sseRetryNonLiveNotification"
}
export interface SelectedKeywordInterface {
    urlPresenceStatus: boolean;
    selectedkeywordSectionType: string;
}

export interface NotificationHandlersInterface {
    sseFailedNotification: boolean;
    sseRetryNonLiveNotification: boolean;
    sseFailedNonLiveNotification: boolean;
    sseRetryNotification: boolean;
}

export enum RelatedKeywordSSEResponseType {
    KEYWORD_TYPE = "keyword_based_param_response",
    URL_TYPE = "url_based_param_response"
}

export enum RelatedKeywordMergeType {
    KEYWORD_INTO_URL = "KEYWORD_INTO_URL",
    URL_INTO_KEYWORD = "URL_INTO_KEYWORD"
}
