import { isUndefined, isEmpty, isNull, find } from "lodash";
import { MessageType, ProjectListInterface } from "../../app/duck/types";
import { ALPS_LOCAL_STORAGE_KEY, COPY, PROJECT_SETUP_TAB_LIST } from "../../app/const";
import {
    PROJECT_SETUP_ADD_KEYWORDS_URL,
    PROJECT_SETUP_COMPETITORS_URL,
    PROJECT_SETUP_DOMAIN_DETAILS_URL,
    PROJECT_SETUP_INTEGRATIONS_URL,
    PROJECT_SETUP_MANAGE_KEYWORDS_URL
} from "../../routes/ducks/routes-url-constant";
import {
    ActiveNotificationInterface,
    CategoryInterface,
    KeywordDetailsInterface,
    KeywordsDetailByThemeInterface,
    ManageKeywordNotifications,
    MgKwAppliedFiltersInterface
} from "./types";
import * as Excel from "exceljs";
import { downloadToXlsx } from "../../app/duck/utils";
import { getContext } from "../../app/duck/context-container";
import { ApiConfig } from "../../app/config/apiConfig";
import { ApiPath } from "../../app/config/apiPath";

export const setTabBasedOnRoute = (path: string): string => {
    let tabname = "";
    switch (path) {
        case PROJECT_SETUP_DOMAIN_DETAILS_URL:
            tabname = PROJECT_SETUP_TAB_LIST[0].tab_name;
            break;
        case PROJECT_SETUP_COMPETITORS_URL:
            tabname = PROJECT_SETUP_TAB_LIST[1].tab_name;
            break;
        case PROJECT_SETUP_INTEGRATIONS_URL:
            tabname = PROJECT_SETUP_TAB_LIST[2].tab_name;
            break;
        case PROJECT_SETUP_ADD_KEYWORDS_URL:
            tabname = PROJECT_SETUP_TAB_LIST[3].tab_name;
            break;
        case PROJECT_SETUP_MANAGE_KEYWORDS_URL:
            tabname = PROJECT_SETUP_TAB_LIST[4].tab_name;
            break;
    }
    return tabname;
};

export const changeRouteOnTabChange = (selectedTab: string) => {
    let route = "";
    switch (selectedTab) {
        case "Domain Details":
            route = PROJECT_SETUP_DOMAIN_DETAILS_URL;
            break;
        case "Competitors":
            route = PROJECT_SETUP_COMPETITORS_URL;
            break;
        case "Integrations":
            route = PROJECT_SETUP_INTEGRATIONS_URL;
            break;
        case "Add Keywords":
            route = PROJECT_SETUP_ADD_KEYWORDS_URL;
            break;
        case "Manage Keywords":
            route = PROJECT_SETUP_MANAGE_KEYWORDS_URL;
            break;
    }
    return route;
};

// tslint:disable-next-line: no-any
export const setKeywordDetails = (keywordDataList: any[]): KeywordDetailsInterface[] => {
    // tslint:disable-next-line: no-any
    const keywordDetails: KeywordDetailsInterface[] = keywordDataList.map((keywordData: any) => {
        return {
            id: keywordData.id,
            keyword: keywordData.keyword,
            targetURL: keywordData.target_url,
            keywordType: keywordData.keyword_type,
            theme: keywordData.theme,
            categories: keywordData.categories,
            intent: keywordData.intent
        };
    });
    return keywordDetails;
};

// tslint:disable-next-line: no-any
export const setListOfOptimizedKeywords = (keywordDataList: any[]): string[] => {
    const result: string[] = keywordDataList.reduce((accumulator, current) => {
        return current.is_optimized === true ? accumulator.concat(current.keyword) : accumulator;
    }, []);
    return result;
};

// tslint:disable-next-line: no-any
export const setAddedKeywordIDs = (addedKeywords: any[]): number[] => {
    // tslint:disable-next-line: no-any
    const addedKeywordIds: number[] = addedKeywords.map((keyword: any) => {
        return keyword.id;
    });
    return addedKeywordIds;
};

export const getCategoryList = (keyword: KeywordDetailsInterface, categoriesList: CategoryInterface[]): string[] => {
    if (!isUndefined(keyword)) {
        const categoryArry =
            keyword?.categories &&
            keyword.categories.map((id: number) => {
                return categoriesList.find((cat: CategoryInterface) => cat?.id === id)?.category;
            });
        return categoryArry?.filter(Boolean) as string[];
    }
    return [];
};

export const isAnyCategoryIDExist =
    // tslint:disable-next-line: no-any
    (selectedCate: number[], categoriesList?: any[]) => {
        return selectedCate.some((id: number) => categoriesList?.includes(id));
    };

const addUncategorizedKeywords =
    // tslint:disable-next-line: no-any
    (keywordDataList: any[]): any[] => {
        const uncategorizedKeywords = [];

        for (const data of keywordDataList) {
            // categories is empty, it means that it is uncategorized
            if (isEmpty(data.categories)) {
                uncategorizedKeywords.push(data);
            }
        }

        return uncategorizedKeywords;
    };

// tslint:disable-next-line: no-any
export const setFilterKeyword = (selectedCategoryList: any[], keywordDataList: any[]): KeywordDetailsInterface[] => {
    if (isEmpty(selectedCategoryList)) {
        return [];
    }
    let filterKeywordArray = [];
    for (const data of keywordDataList) {
        if (isAnyCategoryIDExist(data.categories, selectedCategoryList)) {
            filterKeywordArray.push(data);
        }
    }
    const isUncategorizedFilterChecked = selectedCategoryList.indexOf("Uncategorized") > -1;
    if (isUncategorizedFilterChecked) {
        // If uncategorized filter is checked then add all the Uncategorized keywords to filterKeyword Array
        filterKeywordArray = [...filterKeywordArray, ...addUncategorizedKeywords(keywordDataList)];
    }
    return filterKeywordArray;
};

// tslint:disable-next-line: no-any
export const getGroupedKeywordsByThemeName = (keywordDetails: any[]): KeywordsDetailByThemeInterface => {
    return keywordDetails.reduce((result, currentValue) => {
        if (!isNull(currentValue.theme)) {
            (result[currentValue.theme] = result[currentValue.theme] || []).push({
                keyword: currentValue.keyword,
                targetURL: currentValue.targetURL,
                keywordID: currentValue.id
            });
        }
        return result;
    }, {});
};

// tslint:disable-next-line: no-any
const filterIncludeKeywords = (includeValues: any, keyword: string) => {
    if (includeValues.values.length > 0) {
        if (includeValues.filterType === "all") {
            return includeValues.values.every((e: string) => {
                return keyword.includes(e);
            });
        }
        return includeValues.values.some((e: string) => {
            return keyword.includes(e);
        });
    }
    return true;
};

const filterExcludeKeywords = (excludeValues: string[], keyword: string) => {
    if (excludeValues.length > 0) {
        return excludeValues.every((e: string) => {
            return !keyword.includes(e);
        });
    }
    return true;
};

const filterBasedOnFunnel = (selectedFilters: string[], keyword: KeywordDetailsInterface) => {
    if (selectedFilters.length > 0) {
        return selectedFilters.includes(keyword.intent as string) || (isNull(keyword.intent) && selectedFilters.includes("All"));
    }
    return true;
};

const filterOnBrandType = (keyword: KeywordDetailsInterface, brandType?: string) => {
    if (!isUndefined(brandType)) {
        if (brandType === "all") {
            return true;
        }
        return keyword.keywordType === brandType.toLowerCase();
    }
    return true;
};

const filterOnTargetUrl = (keyword: KeywordDetailsInterface, targetUrls: string[]) => {
    if (targetUrls.length) {
        return targetUrls.includes(keyword.targetURL as string);
    }
    return true;
};

export const getFilteredKeywordDetails = (
    keywords: KeywordDetailsInterface[],
    appliedFilters: MgKwAppliedFiltersInterface
): KeywordDetailsInterface[] | undefined => {
    if (!isUndefined(keywords) && keywords.length) {
        return keywords.filter(
            (keyword: KeywordDetailsInterface) =>
                filterIncludeKeywords(appliedFilters.includeFilter, keyword.keyword) &&
                filterExcludeKeywords(appliedFilters.excludeFilter, keyword.keyword) &&
                filterOnBrandType(keyword, appliedFilters.brandType) &&
                filterBasedOnFunnel(appliedFilters.marketingFunnel, keyword) &&
                filterOnTargetUrl(keyword, appliedFilters.targetUrl)
        );
    }
    return [];
};

// tslint:disable-next-line: no-any
export const getMarketingFunnelValues = (keywords: any): string[] => {
    let intentList = [];
    if (!isUndefined(keywords) && keywords.length) {
        intentList = keywords.map((e: KeywordDetailsInterface) => e.intent);
        // tslint:disable-next-line: no-any
        intentList = intentList.filter((item: any, i: number, ar: any) => ar.indexOf(item) === i);
        intentList = intentList.filter(Boolean) as string[];
        return intentList.length ? ["All", ...intentList] : [];
    }
    return [];
};

// tslint:disable-next-line: no-any
export const getTargetUrlist = (keywords: any): string[] => {
    let targetUrls = [];
    if (!isUndefined(keywords) && keywords.length) {
        // tslint:disable-next-line: no-any
        targetUrls = keywords.map((e: any) => e.target_url);
        // tslint:disable-next-line: no-any
        targetUrls = targetUrls.filter((item: any, i: number, ar: any) => ar.indexOf(item) === i);
        return targetUrls.filter(Boolean) as string[];
    }
    return [];
};

export const updateMkNotificationList = (
    notificationList: ActiveNotificationInterface[],
    // tslint:disable-next-line: no-any
    payloadData: any
): ActiveNotificationInterface[] => {
    const {
        ADD_CATEGORY_TOAST,
        THEME_VIEW_TOAST,
        DATA_STILL_PROCESSING_TOAST,
        TARGET_URL_ASSIGN_TOAST,
        KEYWORD_TYPE_TOAST,
        DELETE_KEYWORD_TOAST,
        CLEAR_ALL,
        MAP_KWRD_AND_CATEGORY,
        ADD_TO_OPTIMIZATION_KEYWORD,
        DOWNLOAD_KEYWORD_DETAILS
    } = ManageKeywordNotifications;
    const { name: notificationName, value: state, position } = payloadData;
    let filteredNotification: ActiveNotificationInterface[] = [];
    const hasDataStillProcessingToast = notificationList.find(
        (notification: ActiveNotificationInterface) => notification.name === DATA_STILL_PROCESSING_TOAST
    );
    const hasThemeViewToast = notificationList.find((notification: ActiveNotificationInterface) => notification.name === THEME_VIEW_TOAST);

    // closing notification based on index or notification name
    if (isUndefined(state)) {
        if (!isUndefined(position)) {
            filteredNotification = notificationList.filter((notification, index: number) => index !== position);
            return filteredNotification;
        }
        filteredNotification = notificationList.filter(
            (notification: ActiveNotificationInterface) => notification.name !== notificationName
        );
        return filteredNotification;
    }
    if (notificationName === ManageKeywordNotifications.THEME_VIEW_TOAST) {
        filteredNotification = notificationList.filter(
            (notification: ActiveNotificationInterface) => notification.name !== ManageKeywordNotifications.DATA_STILL_PROCESSING_TOAST
        );
    }

    if (notificationName === ManageKeywordNotifications.DATA_STILL_PROCESSING_TOAST) {
        filteredNotification = notificationList.filter(
            (notification: ActiveNotificationInterface) => notification.name !== ManageKeywordNotifications.THEME_VIEW_TOAST
        );
    }

    // If redundant notifications are coming, there is no need to add it to notfications list
    if (notificationList.length >= 1) {
        const lastNotification = notificationList[notificationList.length - 1];
        if (lastNotification.name === notificationName) {
            return notificationList;
        }
    }

    switch (notificationName) {
        case ADD_CATEGORY_TOAST:
            return [
                ...notificationList,
                {
                    name: notificationName as string,
                    autoClose: true,
                    message: state ? COPY.KEYWORDS_ADDED_TO_SELECTED_CATEGORIES : COPY.KEYWORDS_NOT_ADDED_TO_SELECTED_CATEGORIES,
                    type: state ? MessageType.SUCCESS : MessageType.ERROR
                }
            ];
        case THEME_VIEW_TOAST:
            return isUndefined(hasThemeViewToast)
                ? [
                    {
                        name: notificationName as string,
                        autoClose: false,
                        message: COPY.PROJECT_SETUP_MANAGE_KEYWORD_THEMING_IN_PROGRESS,
                        type: MessageType.INFO
                    },
                    ...filteredNotification
                ]
                : [...filteredNotification];
        case DATA_STILL_PROCESSING_TOAST:
            return isUndefined(hasDataStillProcessingToast)
                ? [
                    {
                        name: notificationName as string,
                        autoClose: false,
                        message: COPY.PROJECT_SETUP_MANAGE_KEYWORD_DATA_STILL_PROCESSING,
                        type: MessageType.INFO
                    },
                    ...filteredNotification
                ]
                : [...filteredNotification];
        case TARGET_URL_ASSIGN_TOAST:
            return [
                ...notificationList,
                {
                    name: notificationName as string,
                    autoClose: true,
                    message: state ? COPY.TARGET_URL_ASSIGNED_SUCCESS_MSG : COPY.TARGET_URL_ASSIGNED_FAILED_MSG,
                    type: state ? MessageType.SUCCESS : MessageType.ERROR
                }
            ];
        case KEYWORD_TYPE_TOAST:
            return [
                ...notificationList,
                {
                    name: notificationName as string,
                    autoClose: true,
                    message: !state
                        ? COPY.KEYWORDS_TYPE_OPTIMIZATION_FAILED_MSG
                        : payloadData.isOptimized
                            ? COPY.KEYWORDS_ADDED_FOR_OPTIMIZATION_MSG
                            : COPY.KEYWORDS_REMOVED_FROM_OPTIMIZATION_MSG,
                    type: state ? MessageType.SUCCESS : MessageType.ERROR
                }
            ];
        case DELETE_KEYWORD_TOAST:
            return [
                ...notificationList,
                {
                    name: notificationName as string,
                    autoClose: true,
                    message: state ? COPY.KEYWORD_SUCESSFULLY_DELETED : COPY.KEYWORD_DELETION_FAILED,
                    type: state ? MessageType.SUCCESS : MessageType.ERROR
                }
            ];
        case MAP_KWRD_AND_CATEGORY:
            return [
                ...notificationList,
                {
                    name: notificationName as string,
                    autoClose: true,
                    message: state ? COPY.KEYWORDS_ADDED_TO_SELECTED_CATEGORIES : COPY.KEYWORDS_NOT_ADDED_TO_SELECTED_CATEGORIES,
                    type: state ? MessageType.SUCCESS : MessageType.ERROR
                }
            ];
        case ADD_TO_OPTIMIZATION_KEYWORD:
            return [
                ...notificationList,
                {
                    name: notificationName as string,
                    autoClose: true,
                    message: state ? COPY.KEYWORDS_OPTIMIZED : COPY.KEYWORDS_NOT_OPTIMIZED,
                    type: state ? MessageType.SUCCESS : MessageType.ERROR
                }
            ];
        case DOWNLOAD_KEYWORD_DETAILS:
            return [
                ...notificationList,
                {
                    name: notificationName as string,
                    autoClose: true,
                    message: COPY.SUCCESS_DOWNLOAD_KEYWORDS_DETAILS,
                    type: MessageType.SUCCESS
                }
            ];
        case CLEAR_ALL:
            return [];
    }
    return notificationList;
};

export const updateAddKeywordsNotificationList = (
    notificationList: ActiveNotificationInterface[],
    // tslint:disable-next-line: no-any
    payloadData: any
): ActiveNotificationInterface[] => {
    const {
        CLEAR_ALL,
        KEYWORD_MAPPED_AND_OPTIMIZED,
        MAP_KWRD_AND_CATEGORY,
        ADD_TO_OPTIMIZATION_KEYWORD,
        CALL_TO_SAVE_UPLOAD_PROJECT_KEYWORD
    } = ManageKeywordNotifications;
    const { name: notificationName, value: state, position } = payloadData;
    let filteredNotification: ActiveNotificationInterface[] = [];

    // closing notification based on index or notification name
    if (isUndefined(state)) {
        if (!isUndefined(position)) {
            filteredNotification = notificationList.filter((notification, index: number) => index !== position);
            return filteredNotification;
        }
        filteredNotification = notificationList.filter(
            (notification: ActiveNotificationInterface) => notification.name !== notificationName
        );
        return filteredNotification;
    }

    // Incase the notification array already contains SUCCESS type notification at 0th index
    // While adding another success notification, we replace it with single SUCCESS message instead of placing it at 1st index
    if (
        notificationList.length === 1 &&
        notificationList[0].name === MAP_KWRD_AND_CATEGORY &&
        notificationList[0].type === MessageType.SUCCESS
    ) {
        if (notificationName === ADD_TO_OPTIMIZATION_KEYWORD && state) {
            filteredNotification = [
                {
                    name: KEYWORD_MAPPED_AND_OPTIMIZED,
                    autoClose: true,
                    message: COPY.KEYWORD_ADDED_TO_SELECTED_CATEGORIES_AND_OPTIMIZED,
                    type: MessageType.SUCCESS
                }
            ];
            return filteredNotification;
        }
    }
    switch (notificationName) {
        case MAP_KWRD_AND_CATEGORY:
            return [
                ...notificationList,
                {
                    name: notificationName as string,
                    autoClose: true,
                    message: state ? COPY.KEYWORDS_ADDED_TO_SELECTED_CATEGORIES : COPY.KEYWORDS_NOT_ADDED_TO_SELECTED_CATEGORIES,
                    type: state ? MessageType.SUCCESS : MessageType.ERROR
                }
            ];
        case ADD_TO_OPTIMIZATION_KEYWORD:
            return [
                ...notificationList,
                {
                    name: notificationName as string,
                    autoClose: true,
                    message: state ? COPY.KEYWORDS_OPTIMIZED : COPY.KEYWORDS_NOT_OPTIMIZED,
                    type: state ? MessageType.SUCCESS : MessageType.ERROR
                }
            ];
        case CALL_TO_SAVE_UPLOAD_PROJECT_KEYWORD:
            return [
                ...notificationList,
                {
                    name: notificationName as string,
                    autoClose: true,
                    message: state ? COPY.SUCCESS_FILE_UPLOAD : COPY.FILE_UPLOAD_FAILED,
                    type: state ? MessageType.SUCCESS : MessageType.ERROR
                }
            ];
        case CLEAR_ALL:
            return [];
    }
    return notificationList;
};

// fucntion to check if URL is of valid http or https format
export const checkValidUrl = (url: string) => {
    const validURLPattern = new RegExp(/^(?:https?:\/\/)+(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/gim);
    let urlName;
    try {
        urlName = new URL(url);
    } catch (e) {
        return false;
    }
    return !url.includes("_") && !!validURLPattern.test(url) && (urlName.protocol === "http:" || urlName.protocol === "https:");
};

// tslint:disable-next-line: no-any
export const setListOfPreSavedCompUrls = (competitorListData: any[]): string[] => {
    const result: string[] = competitorListData.reduce((accumulator, current) => {
        return accumulator.concat(current.competitor_url);
    }, []);
    return result;
};


export const findColumnName = (colNum: number) => {
    let currString = "";
    let currColName = colNum;
    while (currColName > 0) {
        const rem = currColName % 26;

        if (rem === 0) {
            currString = `Z${currString}`;
            currColName = Math.floor(currColName / 26) - 1;
        } else {
            const nextCol = String.fromCharCode(rem - 1 + "A".charCodeAt(0));
            currString = `${nextCol}${currString}`;
            currColName = Math.floor(currColName / 26);
        }
    }
    return currString;
};

export const downloadManageKeywords = async (
    keywordViewDetails: KeywordDetailsInterface[],
    listOfOptimizedKeywords: string[],
    showSuccessDownloadNotification: () => void,
    projectName?: string,
    categoryList?: CategoryInterface[]
) => {
    const formatedKeywordsDetails = keywordViewDetails.map((keywordItem) => {
        const categories = keywordItem.categories?.map((keywordCategoryItem) => {
            return categoryList?.find((categoryItem: CategoryInterface) => categoryItem.id === keywordCategoryItem)?.category;
        });

        const isOptimized: string = listOfOptimizedKeywords.includes(keywordItem.keyword) ? "Y" : "";

        return {
            categories,
            keyword: keywordItem.keyword,
            optimization: isOptimized,
            url: keywordItem.targetURL ? keywordItem.targetURL : "",
            marketingIntent: keywordItem.intent ? keywordItem.intent : "",
            theme: keywordItem.theme ? keywordItem.theme : ""
        };
    });

    const response = await fetch(`${process.env.IMAGE_URL}/xlsx/Manage-keywords-template.xlsx`);
    const array = await response.arrayBuffer();

    const wb = new Excel.Workbook();
    const workbook = await wb.xlsx.load(array);

    const tableColumns = {
        keyword: "A",
        optimization: "B",
        url: "C",
        marketingIntent: "D",
        theme: "E",
        category: 6
    };

    const worksheet = workbook.getWorksheet("Sheet1");

    formatedKeywordsDetails.forEach((formatedKeyword, formatedKeywordIndex) => {
        worksheet.getCell(`${tableColumns.keyword}${formatedKeywordIndex + 2}`).value = formatedKeyword.keyword;
        worksheet.getCell(`${tableColumns.optimization}${formatedKeywordIndex + 2}`).value = formatedKeyword.optimization;
        worksheet.getCell(`${tableColumns.url}${formatedKeywordIndex + 2}`).value = formatedKeyword.url;
        worksheet.getCell(`${tableColumns.marketingIntent}${formatedKeywordIndex + 2}`).value = formatedKeyword.marketingIntent;
        worksheet.getCell(`${tableColumns.theme}${formatedKeywordIndex + 2}`).value = formatedKeyword.theme;

        let currentCategoryColNum = tableColumns.category;
        formatedKeyword.categories?.forEach((categoryItem, categoryIndex) => {
            const currentCategoryColumnName = findColumnName(currentCategoryColNum);
            worksheet.getCell(`${currentCategoryColumnName}${formatedKeywordIndex + 2}`).value = categoryItem;
            currentCategoryColNum += 1;
        });
    });

    const excelBuffer = await workbook.xlsx.writeBuffer();
    const monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const currDate = new Date();
    const date = currDate.getDate();
    const monthName = monthList[currDate.getMonth()];
    const fullYear = currDate.getFullYear();
    const keywordsDetailsFileName = `${projectName}_${date}_${monthName}_${fullYear}`.replace(".", "");

    downloadToXlsx(excelBuffer, keywordsDetailsFileName);
    showSuccessDownloadNotification();
};

export const getFormattedSampleTemplateDownloadLink = (projectID: number) => {
    const { tenantCode = "" } = getContext();
    const baseURL = ApiConfig.baseURL;
    const link =
        baseURL +
        ApiPath.DOWNLOAD_SAMPLE_XLSX_TEMPLATE.replace("{{tenant_code}}", tenantCode).replace("{{project_id}}", projectID.toString());
    return link;
};

export const isOptimizationDisabled = (initialCrawlFreq: string, locale: string, projectList?: ProjectListInterface[]) => {
    if (!window.location.pathname.startsWith("/setup")) {
        const preMeta = localStorage.getItem(ALPS_LOCAL_STORAGE_KEY);
        const ls = !isNull(preMeta) ? JSON.parse(preMeta) : {};
        const currentSelectedProjectID = ls.currentProjectIDUnderEdit || ls.projectID;
        // tslint:disable-next-line:no-any
        const currentProjectDetails: any = find(projectList, (vn: ProjectListInterface) => vn.id === currentSelectedProjectID) || "";
        const currentProjectLocale = currentProjectDetails.locale || "";
        const currentProjectCrawlFreq = currentProjectDetails.crawling_frequency;

        if (currentProjectCrawlFreq === "none" || (!currentProjectLocale.startsWith("en") && !currentProjectLocale.startsWith("de"))) {
            return true;
        }
    } else if (initialCrawlFreq === "none" || (!locale.startsWith("en") && !locale.startsWith("de"))) {
        return true;
    }
    return false;
};
