import { find, forEach, isArray, isEmpty, isUndefined, orderBy, uniq } from "lodash";
import {
    Impactedkeywordsurllistinterface,
    KeywordUrlListDataResponseType,
    ImpactedKeywordsResponseInterface,
    ParameterInterface,
    ImpactedKeywordResponseAuthAndTechInterface,
    CompetitorsScoreResponseInterface,
    CompetitiveAnalysisReducerInterface
} from "./types";
import { DESCENDING, ASCENDING, BACKLINK_RELEVENCE } from "../../../../../app/const";
import { getTrimmedURL } from "../../../../../app/duck/utils";
import { AuthorityReducerInterface, EditorElementInterfaceBeta, TechnicalReducerInterface } from "../../../ducks/types";

export const getsortedKeywordLevelImpactData = (
    keywordandUrlList: KeywordUrlListDataResponseType[],
    sortType?: string
): KeywordUrlListDataResponseType[] => {
    switch (sortType) {
        case "SV_DSC":
            return orderBy(
                keywordandUrlList,
                [
                    (data: KeywordUrlListDataResponseType) => !isUndefined(data.search_volume) && data.search_volume,
                    (data: KeywordUrlListDataResponseType) => !isUndefined(data.keyword) && data.keyword
                ],
                [DESCENDING, ASCENDING]
            );
        default:
            return keywordandUrlList;
    }
};

export const getImpactedKeywordsandUrlsList = (
    impactedKeywordsUrlList: Impactedkeywordsurllistinterface,
    filteredKeyword: string[],
    filteredUrl: string[]
): KeywordUrlListDataResponseType[] | undefined => {
    let keywordandUrlList: KeywordUrlListDataResponseType[] = [];
    forEach(filteredKeyword, (keyword: string) => {
        forEach(impactedKeywordsUrlList[keyword], (urlDetail: KeywordUrlListDataResponseType) => {
            if (filteredUrl.indexOf(urlDetail.displayUrl) > -1) {
                keywordandUrlList.push(urlDetail);
            }
        });
    });
    keywordandUrlList = getsortedKeywordLevelImpactData(keywordandUrlList, "SV_DSC");
    return keywordandUrlList;
};

export const getSortedKeywordBySearchVolume = (
    impactedKeywords: ImpactedKeywordsResponseInterface[]
): ImpactedKeywordsResponseInterface[] => {
    const sortedData = orderBy(impactedKeywords, ["search_volume", "keyword"], [DESCENDING, ASCENDING]);
    const sortedParam = [
        ...sortedData.filter((x: ImpactedKeywordsResponseInterface) => x.search_volume !== null),
        ...sortedData.filter((x: ImpactedKeywordsResponseInterface) => x.search_volume === null)
    ];
    return sortedParam;
};

/**
 * Gives Trimmed Target URL
 *
 * @param {ImpactedKeywordsResponseInterface[]} impactedKeywords - Keywords Data
 * @param {Impactedkeywordsurllistinterface} impactedKeywordsUrlList - My URL data + Top 10 Comp data for each keyword in object format
 * @returns {string} - Target URL with http(s), www. trimmed
 *
 */
export const getTrimmedTargetURL = (
    impactedKeywords: ImpactedKeywordsResponseInterface[],
    impactedKeywordsUrlList: Impactedkeywordsurllistinterface,
    kw?: string
) => {
    const highestSVKW: string = kw ? kw : getSortedKeywordBySearchVolume(impactedKeywords)[0]?.keyword;
    const impactedKeywordsUrls = impactedKeywordsUrlList[highestSVKW];
    const trimmedTargetURL: string = impactedKeywordsUrls ? impactedKeywordsUrls[0]?.displayUrl : "";
    return trimmedTargetURL;
};

/**
 * Gives list of Top Competitors for highest ranked keyword
 *
 * @param {ImpactedKeywordsResponseInterface[]} impactedKeywords - Keywords Data
 * @param {Impactedkeywordsurllistinterface} impactedKeywordsUrlList - My URL data + Top 10 Comp data for each keyword in object format
 * @returns {string[]} - Top Competitiors list with http(s), www. trimmed
 *
 */
export const getTopCompetitorsListForHighestRankingKw = (
    impactedKeywords: ImpactedKeywordsResponseInterface[],
    impactedKeywordsUrlList: Impactedkeywordsurllistinterface,
    kw?: string
): string[] => {
    const highestSVKW: string = kw ? kw : getSortedKeywordBySearchVolume(impactedKeywords)[0]?.keyword;
    const highestRankingCompURLs: string[] = [];
    for (let i = 1; i < impactedKeywordsUrlList[highestSVKW]?.length || 0; i += 1) {
        const item = impactedKeywordsUrlList[highestSVKW][i];
        // Check if the "displayUrl" property exists in the current item
        if (item.displayUrl) {
            highestRankingCompURLs.push(item.displayUrl);
        }
    }
    return highestRankingCompURLs;
};

export const getImpactedKeywordUrlList = (
    impactedKeywords: ImpactedKeywordsResponseInterface[],
    competitorsScore: CompetitorsScoreResponseInterface | undefined,
    keywordsRequested: string[],
    Url: string,
    latestPageContentFromEditor?: EditorElementInterfaceBeta[],
    authority?: AuthorityReducerInterface,
    technical?: TechnicalReducerInterface
): Impactedkeywordsurllistinterface | undefined => {
    const htmlStr = !isUndefined(latestPageContentFromEditor) ? (latestPageContentFromEditor[6].contents as string) : "";
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlStr, "text/html");
    const body = doc.body;
    const h1TagList = body.querySelectorAll("h1");
    const h2TagList = body.querySelectorAll("h2");
    const h3TagList = body.querySelectorAll("h3");
    // tslint:disable-next-line:no-any
    const h1Tags: any = [];
    // tslint:disable-next-line:no-any
    const h2Tags: any = [];
    // tslint:disable-next-line:no-any
    const h3Tags: any = [];
    h1TagList.forEach((header, key) => {
        h1Tags.push(header.innerHTML);
    });
    h2TagList.forEach((header, key) => {
        h2Tags.push(header.innerHTML);
    });
    h3TagList.forEach((header, key) => {
        h3Tags.push(header.innerHTML);
    });
    // tslint:disable-next-line:no-any
    const keywordsandurls: Impactedkeywordsurllistinterface | any = {};
    const ImpactedKeywords = impactedKeywords.map((key, index) => {
        return {
            ...key,
            url: Url,
            displayUrl: getTrimmedURL(Url) /* Remove http, https, www. from the url */,
            authority: authority?.parameters.map((param: ParameterInterface) => {
                const changedParam = param;

                // set individual keyword's Backlink Content Relevance and Keyword Occurrence in backlinks anchor texts score
                if (BACKLINK_RELEVENCE.includes(param.parameter) && key.hasOwnProperty("backlink_relevence")) {
                    const backlinkRelevenceParam = key.backlink_relevence.find(
                        (e: ImpactedKeywordResponseAuthAndTechInterface) => e.display_name === param.parameter
                    );
                    changedParam.value = backlinkRelevenceParam?.value as number | null | boolean;
                }
                // set user modified value for parameters
                if (authority.initialChangedParameters.hasOwnProperty(param.parameter)) {
                    changedParam.value = authority.initialChangedParameters[param.parameter] as number | null | boolean;
                }
                // set forced changed values for pameters
                if (authority.forced_changed_parameters.hasOwnProperty(param.parameter)) {
                    changedParam.value = authority.forced_changed_parameters[param.parameter] as number | null | boolean;
                }
                return { ...changedParam };
            }),
            technical: technical?.parameters.map((param: ParameterInterface) => {
                const changedParam = param;
                // set user modified value for parameters
                if (technical.initialChangedParameters.hasOwnProperty(param.parameter)) {
                    changedParam.value = technical.initialChangedParameters[param.parameter] as number | null | boolean;
                }
                // set forced changed values for pameters
                if (technical.forced_changed_parameters.hasOwnProperty(param.parameter)) {
                    changedParam.value = technical.forced_changed_parameters[param.parameter] as number | null | boolean;
                }
                return { ...changedParam };
            })
        };
    });
    // tslint:disable-next-line:no-any
    ImpactedKeywords.forEach((item: any) => {
        item.meta_description_score.elements =
            !isUndefined(latestPageContentFromEditor) && typeof latestPageContentFromEditor[2].contents === "string"
                ? [latestPageContentFromEditor[2].contents]
                : !isUndefined(latestPageContentFromEditor) && isArray(latestPageContentFromEditor[2].contents)
                ? [...latestPageContentFromEditor[2].contents]
                : [];
        item.title_score.elements =
            !isUndefined(latestPageContentFromEditor) && typeof latestPageContentFromEditor[1].contents === "string"
                ? [latestPageContentFromEditor[1].contents]
                : !isUndefined(latestPageContentFromEditor) && isArray(latestPageContentFromEditor[1].contents)
                ? [...latestPageContentFromEditor[1].contents]
                : [];

        if (item && item.h1_score) {
            item.h1_score.elements = !isEmpty(h1Tags) ? [...h1Tags] : [];
        }
        if (item && item.h2_score) {
            item.h2_score.elements = !isEmpty(h2Tags) ? [...h2Tags] : [];
        }
        if (item && item.h3_score) {
            item.h3_score.elements = !isEmpty(h3Tags) ? [...h3Tags] : [];
        }
    });
    forEach(keywordsRequested, (keyword: string) => {
        keywordsandurls[keyword] = [];
        // tslint:disable-next-line:no-any
        const impactedKeyword: undefined | any = find(ImpactedKeywords, (k: any) => k.keyword.toLowerCase() === keyword.toLowerCase());
        if (!isUndefined(impactedKeyword)) {
            keywordsandurls[keyword].push(impactedKeyword);
        } else {
            const dummyRecord = {
                keyword,

                search_volume: undefined,

                h1_score: {
                    score: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    phrase_count: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    common_word_count: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    is_exact_match: false
                },

                title_score: {
                    score: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    phrase_count: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    common_word_count: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    is_exact_match: false
                },

                url_score: {
                    score: {
                        info_type: "value_number",
                        info_value: undefined
                    }
                },

                h2_score: {
                    score: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    phrase_count: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    common_word_count: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    is_exact_match: false
                },

                h3_score: {
                    score: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    phrase_count: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    common_word_count: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    is_exact_match: false
                },

                meta_description_score: {
                    score: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    phrase_count: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    common_word_count: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    is_exact_match: false
                },

                visible_content_score: {
                    score: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    phrase_count: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    common_word_count: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    is_exact_match: false
                },

                category_scores: {
                    score: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    content_score: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    authority_score: {
                        info_type: "value_number",
                        info_value: undefined
                    },
                    technical_score: {
                        info_type: "value_number",
                        info_value: undefined
                    }
                },

                traffic: {
                    score: {
                        info_type: "value_number",
                        info_value: undefined
                    }
                },
                organic_rank: {
                    score: {
                        info_type: "value_number",
                        info_value: undefined
                    }
                },
                readability_grade: {
                    score: {
                        info_type: "value_number",
                        info_value: undefined
                    }
                },
                url: Url,
                displayUrl: getTrimmedURL(Url)
            };
            keywordsandurls[keyword].push(dummyRecord);
        }
    });

    if (!isUndefined(competitorsScore)) {
        const keyword = Object.keys(competitorsScore);
        const keywordList: Impactedkeywordsurllistinterface = keyword.reduce(
            (prev, curr) => ({ ...prev, [curr]: competitorsScore[curr].url_data }),
            {}
        );
        Object.keys(keywordList).map((key, index) => {
            // tslint:disable-next-line:no-any
            forEach(keywordList[key], (urlDetail: KeywordUrlListDataResponseType | any) => {
                Object.assign(urlDetail, {
                    displayUrl: getTrimmedURL(urlDetail.url)
                });
                Object.assign(urlDetail, { keyword: key });

                if (isUndefined(urlDetail.h1_score)) {
                    Object.assign(urlDetail, {
                        h1_score: {
                            score: {
                                info_type: "value_number",
                                info_value: undefined
                            }
                        }
                    });
                } else {
                    if (typeof urlDetail.h1_score.score === "number") {
                        Object.assign(urlDetail, {
                            h1_score: {
                                score: {
                                    info_type: "value_number",
                                    info_value: urlDetail.h1_score.score
                                },
                                elements: [...urlDetail.h1_score.elements]
                            }
                        });
                    }
                }

                if (isUndefined(urlDetail.h2_score)) {
                    Object.assign(urlDetail, {
                        h2_score: {
                            score: {
                                info_type: "value_number",
                                info_value: undefined
                            }
                        }
                    });
                } else {
                    if (typeof urlDetail.h2_score.score === "number") {
                        Object.assign(urlDetail, {
                            h2_score: {
                                score: {
                                    info_type: "value_number",
                                    info_value: urlDetail.h2_score.score
                                },
                                elements: [...urlDetail.h2_score.elements]
                            }
                        });
                    }
                }

                if (isUndefined(urlDetail.h3_score)) {
                    Object.assign(urlDetail, {
                        h3_score: {
                            score: {
                                info_type: "value_number",
                                info_value: undefined
                            },
                            elements: []
                        }
                    });
                } else {
                    if (typeof urlDetail.h3_score.score === "number") {
                        Object.assign(urlDetail, {
                            h3_score: {
                                score: {
                                    info_type: "value_number",
                                    info_value: urlDetail.h3_score.score
                                },
                                elements: [...urlDetail.h3_score.elements]
                            }
                        });
                    }
                }

                if (isUndefined(urlDetail.title_score)) {
                    Object.assign(urlDetail, {
                        title_score: {
                            score: {
                                info_type: "value_number",
                                info_value: undefined
                            }
                        }
                    });
                } else {
                    if (typeof urlDetail.title_score.score === "number") {
                        Object.assign(urlDetail, {
                            title_score: {
                                score: {
                                    info_type: "value_number",
                                    info_value: urlDetail.title_score.score
                                },
                                elements: [...urlDetail.title_score.elements]
                            }
                        });
                    }
                }

                if (isUndefined(urlDetail.content_score)) {
                    Object.assign(urlDetail, {
                        content_score: {
                            score: {
                                info_type: "value_number",
                                info_value: undefined
                            }
                        }
                    });
                } else {
                    if (typeof urlDetail.content_score?.score === "number") {
                        Object.assign(urlDetail, {
                            content_score: {
                                score: {
                                    info_type: "value_number",
                                    info_value: urlDetail.content_score?.score
                                }
                            }
                        });
                    }
                }

                if (isUndefined(urlDetail.authority_score)) {
                    Object.assign(urlDetail, {
                        authority_score: {
                            score: {
                                info_type: "value_number",
                                info_value: undefined
                            }
                        }
                    });
                } else {
                    if (typeof urlDetail.authority_score?.score === "number") {
                        Object.assign(urlDetail, {
                            authority_score: {
                                score: {
                                    info_type: "value_number",
                                    info_value: urlDetail.authority_score?.score
                                }
                            }
                        });
                    }
                }

                if (isUndefined(urlDetail.technical_score)) {
                    Object.assign(urlDetail, {
                        technical_score: {
                            score: {
                                info_type: "value_number",
                                info_value: undefined
                            }
                        }
                    });
                } else {
                    if (typeof urlDetail.technical_score?.score === "number") {
                        Object.assign(urlDetail, {
                            technical_score: {
                                score: {
                                    info_type: "value_number",
                                    info_value: urlDetail.technical_score?.score
                                }
                            }
                        });
                    }
                }

                if (isUndefined(urlDetail.meta_description_score)) {
                    Object.assign(urlDetail, {
                        meta_description_score: {
                            score: {
                                info_type: "value_number",
                                info_value: undefined
                            }
                        }
                    });
                } else {
                    if (typeof urlDetail.meta_description_score.score === "number") {
                        Object.assign(urlDetail, {
                            meta_description_score: {
                                score: {
                                    info_type: "value_number",
                                    info_value: urlDetail.meta_description_score.score
                                },
                                elements: [...urlDetail.meta_description_score.elements]
                            }
                        });
                    }
                }

                if (isUndefined(urlDetail.url_score)) {
                    Object.assign(urlDetail, {
                        url_score: {
                            score: {
                                info_type: "value_number",
                                info_value: undefined
                            }
                        }
                    });
                } else {
                    if (typeof urlDetail.url_score.score === "number") {
                        Object.assign(urlDetail, {
                            url_score: {
                                score: {
                                    info_type: "value_number",
                                    info_value: urlDetail.url_score.score
                                }
                            }
                        });
                    }
                }

                if (isUndefined(urlDetail.visible_content_score)) {
                    Object.assign(urlDetail, {
                        visible_content_score: {
                            score: {
                                info_type: "value_number",
                                info_value: undefined
                            }
                        }
                    });
                } else {
                    if (typeof urlDetail.visible_content_score.score === "number") {
                        Object.assign(urlDetail, {
                            visible_content_score: {
                                score: {
                                    info_type: "value_number",
                                    info_value: urlDetail.visible_content_score.score
                                }
                            }
                        });
                    }
                }

                if (isUndefined(urlDetail.organic_rank)) {
                    Object.assign(urlDetail, {
                        organic_rank: {
                            score: {
                                info_type: "value_number",
                                info_value: undefined
                            }
                        }
                    });
                } else {
                    if (typeof urlDetail.organic_rank === "number") {
                        Object.assign(urlDetail, {
                            organic_rank: {
                                score: {
                                    info_type: "value_number",
                                    info_value: urlDetail.organic_rank
                                }
                            }
                        });
                    }
                }

                if (isUndefined(urlDetail.readability_grade)) {
                    Object.assign(urlDetail, {
                        readability_grade: {
                            score: {
                                info_type: "value_number",
                                info_value: undefined
                            }
                        }
                    });
                } else {
                    if (typeof urlDetail.readability_grade.score === "number") {
                        Object.assign(urlDetail, {
                            readability_grade: {
                                score: {
                                    info_type: "value_number",
                                    info_value: urlDetail.readability_grade.score
                                }
                            }
                        });
                    }
                }

                // tslint:disable-next-line:no-any
                const obj: undefined | any = find(
                    ImpactedKeywords,
                    (k: ImpactedKeywordsResponseInterface) => k.keyword.toLowerCase() === key.toLowerCase()
                );
                Object.assign(urlDetail, { search_volume: obj?.search_volume });
            });
        });
        Object.keys(keywordList).forEach((key, index) => {
            keywordList[key].map((urlDetail, i) => {
                if (!isUndefined(keywordsandurls[key])) {
                    keywordsandurls[key].push(urlDetail);
                }
            });
        });
    }

    return keywordsandurls;
};

export const getUniqueSortedUrl = (impactedKeywordsUrlList: Impactedkeywordsurllistinterface, simulationUrl: string): string[] => {
    const urlList: string[] = [];
    Object.keys(impactedKeywordsUrlList).map((key, index) => {
        impactedKeywordsUrlList[key].map((urlDetail, i) => {
            urlList.push(urlDetail.displayUrl);
        });
    });
    const uniqueSortedUrl = uniq(orderBy(urlList, [(data: string) => data], [ASCENDING]));
    // put the simulation url top of unique sorted URL list
    const sortedUrls = [
        ...uniqueSortedUrl.filter((url: string) => url === getTrimmedURL(simulationUrl)),
        ...uniqueSortedUrl.filter((url: string) => url !== getTrimmedURL(simulationUrl))
    ];
    return sortedUrls;
};

export const getUpdatedFilteredKeywords = (state: CompetitiveAnalysisReducerInterface, selectedKeywords: string[]) => {
    const newfilteredKeywords = state.filteredKeyword.filter((kw: string) => selectedKeywords.includes(kw));
    return newfilteredKeywords;
};

export const getUpdatedFilteredUrls = (state: CompetitiveAnalysisReducerInterface, selectedKeywords: string[]) => {
    let impactedUrlList: string[] = [];
    if (state.impactedKeywordsUrlList) {
        const impactedKeywordsUrlList = state.impactedKeywordsUrlList || [];
        Object.keys(impactedKeywordsUrlList).forEach((key) => {
            impactedUrlList = selectedKeywords.includes(key)
                ? impactedUrlList.concat(impactedKeywordsUrlList[key].map((el: KeywordUrlListDataResponseType) => el.displayUrl))
                : impactedUrlList;
        });
    }
    const newfilteredUrls = state.filteredUrl.filter((url: string) => impactedUrlList.includes(url));
    return newfilteredUrls;
};
