import { createSelector } from "reselect";
import { isUndefined, find } from "lodash";
import { AppReducerInterface, SerpResponseInterface, SerpListProgressStatusType, ApplicationStateInterface } from "../duck/types";

const getTragetUrlsionList = (app: AppReducerInterface): string => {
    let finalURL = "";
    const targetValue = find(app.serpList, (serp: SerpResponseInterface) => {
        const encodedURL = encodeURI(decodeURI(serp.url));
        finalURL = app.target_urls.hasOwnProperty(encodedURL) ? encodedURL : serp.url;
        return app.target_urls.hasOwnProperty(finalURL) && serp.is_organic && serp.status === SerpListProgressStatusType.DONE;
    });
    return !isUndefined(targetValue) ? finalURL : "";
};
// return formattedTarget;
export const getTargetUrl = createSelector(getTragetUrlsionList, (list: string) => list);

const extractFeatureAddOns = (state: ApplicationStateInterface): string[] | undefined => state.context.featureAddons;

export const getFeatureAddOns = createSelector(extractFeatureAddOns, (featureAddons: string[] | undefined) => featureAddons);
