import * as React from "react";
import styled from "styled-components";
import { find, isUndefined } from "lodash";
import Toast from "./toast";
import { AppMessageContext } from "./app-message-context";
import { COPY } from "../../../app/const";

const defaultMessage: MessageInternalPropsInterface[] = [];
const defaultDissolveTime = 30000;
/** App Enum */
export enum MessageType {
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
    INFO = "INFO",
    WARNING = "WARNING"
}
export interface MessagePropsAndStateInterface {
    messages: MessageInternalPropsInterface[];
    dissolveTime: number;
}
export interface MessageInterface {
    id?: number;
    type: MessageType;
    title: string;
    message: string;
    autoClose: boolean;
    showProcessingLoader?: boolean;
}
export interface MessageInternalPropsInterface extends MessageInterface {
    id: number;
}

const StyledToastMessageContainer = styled.div`
    width: 350px;
    position: fixed;
    top: 70px;
    right: 5px;
    z-index: 1000;
    background: white;
    width: 380px;
    max-height: 356px;
    border-radius: 3px;
    box-shadow: -2px 4px 8px 1px rgba(41, 41, 41, 0.18);
    background-color: #fff;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #cdcdcd #fff;
`;
const StyledToastMessageHeader = styled.div`
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    justify-content: space-between;
    padding: 8px;
`;
const StyledToastMessageLabel = styled.span`
    height: 17px;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #343434;
`;
const StyledToastMessageClearButton = styled.button`
    height: 15px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00acce;
    background: none;
    border: none;
    text-transform: underline;
`;
const StyledToastMessagebody = styled.div`
    padding: 5px 10px;
`;

const HOCAppMessagingListner = (Wrapper: React.ComponentType) => {
    return class Notification extends React.Component<MessagePropsAndStateInterface, MessagePropsAndStateInterface> {
        constructor(props: MessagePropsAndStateInterface) {
            super(props);
            this.state = {
                messages: defaultMessage,
                dissolveTime: props.dissolveTime || defaultDissolveTime
            };
        }
        /**
         *
         *
         * Push new message to message stack
         */
        public push = (messageMetaData: MessageInterface): void => {
            this.setState((prevState) => {
                const msg = find(prevState.messages, { id: messageMetaData.id });
                if (isUndefined(msg)) {
                    return {
                        messages: [...prevState.messages, { id: Date.now(), ...messageMetaData }]
                    };
                }
                return null; // No state update needed
            });
            // tslint:disable-next-line: semicolon
        };
        /**
         *
         *
         * Remove specific message from message stack
         */
        public pop = (id: number): void => {
            this.setState((prevState) => {
                return {
                    messages: prevState.messages.filter((m: MessageInternalPropsInterface) => m.id !== id)
                };
            });
            // tslint:disable-next-line: semicolon
        };

        public reset = (): void => {
            this.setState({ messages: [] });
            // tslint:disable-next-line: semicolon
        };
        public shouldComponentUpdate(nextProps: MessagePropsAndStateInterface, nextState: MessagePropsAndStateInterface): boolean {
            const currentMessages = this.state.messages;
            const nextMessages = nextState.messages;

            // Check if the lengths are different
            if (currentMessages.length !== nextMessages.length) {
                return true;
            }

            // Check if any message content is different
            for (let i = 0; i < currentMessages.length; i += 1) {
                if (currentMessages[i].message !== nextMessages[i].message) {
                    return true;
                }
            }

            // If lengths are the same and all messages have the same content, no need to update
            return false;
        }

        public render(): JSX.Element {
            return (
                <AppMessageContext.Provider
                    value={{
                        push: this.push,
                        pop: this.pop,
                        reset: this.reset,
                        messages: this.state.messages
                    }}
                >
                    {Wrapper}
                    {this.state.messages.length > 0 ? (
                        <StyledToastMessageContainer id="toast_container">
                            <StyledToastMessageHeader>
                                <StyledToastMessageLabel>
                                    {COPY.NOTIFICATION_LABEL} ({this.state.messages.length})
                                </StyledToastMessageLabel>
                                <StyledToastMessageClearButton onClick={this.reset}>{COPY.CLEAR_ALL}</StyledToastMessageClearButton>
                            </StyledToastMessageHeader>
                            <StyledToastMessagebody>
                                {this.state.messages.map((v: MessageInternalPropsInterface, k: number) => {
                                    return (
                                        <>
                                            <Toast
                                                className="simulation-toast"
                                                {...v}
                                                key={k}
                                                onRemove={() => this.pop(v.id)}
                                                dissolveTime={this.state.dissolveTime}
                                            />
                                        </>
                                    );
                                })}
                            </StyledToastMessagebody>
                        </StyledToastMessageContainer>
                    ) : (
                        ""
                    )}
                </AppMessageContext.Provider>
            );
        }
    };
};

export default HOCAppMessagingListner;
