import { isEqual, isUndefined } from "lodash";
import { createSelector } from "reselect";
import { OptionTypeInterface } from "../../app/styledComponents/drop-down/types";
import { filterKeywordBasedOnRank, filterKeywordBasedOnRelevanceScore, filterKeywordBasedOnSearchVolume, filterKeywordBasedOnURLs, getSortedKeywordList } from "../../suggested-keywords/ducks/utils";
import { ApplicationStateInterface } from "../../app/duck/types";

const getAllFormattedKeywordList = (state: ApplicationStateInterface): OptionTypeInterface[] => {
    const { filteredSearchKW, relatedKeywords = [], appliedFiltersOnKW } = state.keyWordResearch;

    let filteredKeywords: OptionTypeInterface[] = [];

    const formattedRelatedKeywordList = !isUndefined(filteredSearchKW) ? filteredSearchKW : relatedKeywords;
    filteredKeywords = formattedRelatedKeywordList;

    if (!isUndefined(appliedFiltersOnKW.searchvolume) && appliedFiltersOnKW.searchvolume.length) {
        filteredKeywords = filterKeywordBasedOnSearchVolume(appliedFiltersOnKW.searchvolume, filteredKeywords);
    }

    if (!isUndefined(appliedFiltersOnKW.relevancescore) && appliedFiltersOnKW.relevancescore.length) {
        filteredKeywords = filterKeywordBasedOnRelevanceScore(appliedFiltersOnKW.relevancescore, filteredKeywords);
    }

    if (!isUndefined(appliedFiltersOnKW.rank) && appliedFiltersOnKW.rank.length) {
        filteredKeywords = filterKeywordBasedOnRank(appliedFiltersOnKW.rank, filteredKeywords);
    }
    if (!isUndefined(appliedFiltersOnKW.search_by_urls) && appliedFiltersOnKW.search_by_urls.length) {
        filteredKeywords = filterKeywordBasedOnURLs(appliedFiltersOnKW.search_by_urls, filteredKeywords);
    }

    if (!isEqual(appliedFiltersOnKW.sorttype, "")) {
        filteredKeywords = getSortedKeywordList(filteredKeywords, appliedFiltersOnKW.sorttype);
    }

    return filteredKeywords;
};

const extractTimesSseRetried = (state: ApplicationStateInterface): number => {
    return state.keyWordResearch.sseRetriedAttempt;
};

export const getSSeRetriedAttempts = createSelector(
    extractTimesSseRetried,
    (retriedtimes: number) => retriedtimes
);

const extractEventCount = (state: ApplicationStateInterface): number => {
    return state.keyWordResearch.receiveRelatedKwdsEventCount;
};

export const getReceivedRelatedKwdsEventCount = createSelector(
    extractEventCount,
    (count: number) => count
);

export const formattedKeywordList = createSelector(getAllFormattedKeywordList, (list: OptionTypeInterface[]) => list);
