import React, { useState } from "react";
import { logOut } from "../../../../app/duck/utils";
import Button, { BUTTON_TYPE } from "../../../../app/styledComponents/button";
import { grayTick, userImg } from "../../../../app/icons";
import { FEATURE_DETAILS, UPDATENOW } from "../const";
import {
    StyledAccordionHeadingText,
    StyledAccordionSubHeadingText,
    StyledAccordionWrapper,
    StyledAmountText,
    StyledAmountWrapper,
    StyledDownArrowImg,
    StyledFeatureWrapper,
    StyledFontSize,
    StyledIndividualPlanText,
    StyledIndividualPlanWrapper,
    StyledIPWrapper,
    StyledLeftPanelButton,
    StyledLeftPanelWrapper,
    StyledMonthText,
    StyledNoThanksButton,
    StyledNoThanksLabel,
    StyledOptimizeText,
    StyledPayNowButton,
    StyledPayNowText,
    StyledSubAccordianContainer,
    StyledSubAccordionWrapper,
    StyledUserImg
} from "../StyledSimulator";

export interface UpgradeRightLeftInterface {
    expireUser: boolean;
    onClickPayButton(): void;
    upgradeModalOpen(args: boolean): void;
}

const UpgradeLeftPanel: React.FC<UpgradeRightLeftInterface> = ({ expireUser, onClickPayButton, upgradeModalOpen }) => {
    const [expandFeature, setExpandFeature] = useState<number>(-1);

    const handleClick = (id: number) => {
        setExpandFeature(id);
    };

    return (
        <>
            <StyledIndividualPlanWrapper>
                <StyledIPWrapper>
                    <StyledIndividualPlanText id="individual-plan">{UPDATENOW.PLAN}</StyledIndividualPlanText>
                    <StyledUserImg id="user-img" src={userImg} />
                </StyledIPWrapper>
                <StyledAmountWrapper>
                    <StyledAmountText id="amt-99">{UPDATENOW.AMOUNT}</StyledAmountText>
                    <StyledMonthText id="month">{UPDATENOW.MONTH}</StyledMonthText>
                </StyledAmountWrapper>
                <StyledOptimizeText id="optimize-page">{UPDATENOW.OPTIMIZE_PAGE}</StyledOptimizeText>
            </StyledIndividualPlanWrapper>
            <StyledFeatureWrapper id="feature-wrapper">
                {FEATURE_DETAILS.map((heading, index) => {
                    return (
                        <>
                            <StyledAccordionWrapper id={`feature-accordian-${index}`}>
                                <StyledAccordionHeadingText
                                    key={index}
                                    id={`heading-${index}`}
                                    arrowDown={expandFeature === index}
                                    onClick={() => {
                                        handleClick(expandFeature === index ? -1 : index);
                                    }}
                                >
                                    {heading.heading}
                                </StyledAccordionHeadingText>
                                <StyledSubAccordianContainer>
                                    {expandFeature === index &&
                                        heading.subheading.map((subheading, key) => {
                                            return (
                                                <StyledSubAccordionWrapper key={key} id={`feature-detail-accordian-${key}`}>
                                                    <StyledDownArrowImg id={`gray-tick`} src={grayTick} />
                                                    <StyledAccordionSubHeadingText id={`subheading-${key}`}>{subheading}</StyledAccordionSubHeadingText>
                                                </StyledSubAccordionWrapper>
                                            );
                                        })}
                                </StyledSubAccordianContainer>
                            </StyledAccordionWrapper>
                        </>
                    );
                })}
            </StyledFeatureWrapper>
            <StyledLeftPanelWrapper>
                <StyledLeftPanelButton>
                    <Button
                        instanceId={`pay-button`}
                        variant={BUTTON_TYPE.DEFAULT}
                        onClickHandler={() => onClickPayButton()}
                        label={
                            <div>
                                <StyledPayNowText>{UPDATENOW.PAY_$99}</StyledPayNowText>
                                <StyledFontSize>{UPDATENOW.MONTHLY_RECURRING_FEE}</StyledFontSize>
                            </div>
                        }
                        styles={{ Button: StyledPayNowButton }}
                    />
                </StyledLeftPanelButton>
                <StyledLeftPanelButton>
                    <Button
                        instanceId={`no-thanks-button`}
                        variant={BUTTON_TYPE.DEFAULT}
                        onClickHandler={() => (expireUser ? logOut() : upgradeModalOpen(false))}
                        label={
                            <div>
                                <StyledPayNowText>{UPDATENOW.NO_THANKS}</StyledPayNowText>
                                <StyledFontSize>{UPDATENOW.LOSE_ALPS_ACCESS}</StyledFontSize>
                            </div>
                        }
                        styles={{ Button: StyledNoThanksButton, Label: StyledNoThanksLabel }}
                    />
                </StyledLeftPanelButton>
            </StyledLeftPanelWrapper>
        </>
    );
};

export default UpgradeLeftPanel;
