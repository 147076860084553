import { eventChannel } from "@redux-saga/core";
import { compact, concat, filter, has, isEqual, isNull, isUndefined, map, orderBy, slice, split, toUpper, trim, uniqBy } from "lodash";
import { RELATED_KW_SSE_EVENT_TYPES } from "../../app/const";
import { kFormatter } from "../../app/duck/utils";
import { OptionTypeInterface } from "../../app/styledComponents/drop-down/types";
import { AddRemoveSuggestedKeyword, AppliedFilter, AppliedFilterOnKeywordInterface, KeywordFilterInterface, RelatedKeywordsInterface } from "./types";

export const getKWSearchVolume = (keyword: OptionTypeInterface) => {
    if (!isUndefined(keyword.searchVolume) && !isNull(keyword.searchVolume)) {
        return kFormatter(keyword.searchVolume as number, 2, false);
    }
    return "-";
};

export const getRelevanceScore = (keyword: OptionTypeInterface) => {
    if (!isUndefined(keyword?.relevance_score) && !isNull(keyword?.relevance_score)) {
        return kFormatter(keyword.relevance_score as number, 2, false);
    }
    return "-";
};

export const formattedRelatedKeywords = (relatedKeywords: RelatedKeywordsInterface[] | undefined) => {
    const formattedKwList: OptionTypeInterface[] = [];
    if (relatedKeywords) {
        relatedKeywords.forEach((item: RelatedKeywordsInterface) => {
            formattedKwList.push({
                value: 0,
                label: item.keyword,
                searchVolume: item.search_volume,
                relevance_score: item?.relevance_score,
                rank: item?.rank,
                url: item?.url
            });
        });
    }
    return formattedKwList;
};

export const getNewSelectedKeywords = (
    defaultKeyword: string,
    selectedKeywordsList: string[],
    keywordsInput: string,
    maxAllowedLimit: number
) => {
    const formattedKeywordList = getFormattedKeywords(defaultKeyword, selectedKeywordsList, keywordsInput);

    if (formattedKeywordList.length > maxAllowedLimit) {
        // if the keywords length is exceeding then
        // extract by ignoring the additional keywords
        // the formattedKeywordList will maintain the order of existing keywords followed by new keywords
        return slice(formattedKeywordList, 0, maxAllowedLimit);
    }
    return formattedKeywordList;
};

export const getFormattedKeywords = (defaultKeyword: string, selectedKeywordsList: string[], keywordsInput: string) => {
    // spliting the comma seperated string to string[]
    const keywordsInputList: string[] = split(keywordsInput, ",");
    // trimming the whitespace in every keyword
    const trimmedKeywordsList: string[] = map(keywordsInputList, (key: string) => trim(key));
    // filtering out the emply keywords
    const filteredKeywordsList: string[] = filter(trimmedKeywordsList, (keyword: string) => keyword.length > 0);
    // this will return the unique keywords in the order: selected keywords followed by new keywords
    const uniqueKeywordList = uniqBy([defaultKeyword, ...selectedKeywordsList, ...filteredKeywordsList], toUpper);
    // removing the default keyword
    return slice(uniqueKeywordList, 1);
};

// tslint:disable-next-line:typedef
export function listenSSEvents(url: string) {
    const eventChan = eventChannel((emit) => {
        const sseSource = new EventSource(url);
        sseSource.addEventListener("receive_related_kwds", (ev: Event) => emit(ev));
        sseSource.addEventListener("req_completed", (ev: Event) => emit(ev));
        sseSource.addEventListener("req_failed", (ev: Event) => emit(ev));
        sseSource.onerror = (ev: Event) => {
            emit(ev);
        };
        return () => sseSource.close();
    });
    return eventChan;
}

export const isRelevanceLoading = (loading: string | boolean) => {
    if (!isEqual(loading, true) && !isEqual(loading, RELATED_KW_SSE_EVENT_TYPES.req_failed) && !isEqual(loading, RELATED_KW_SSE_EVENT_TYPES.req_completed)) {
        return true;
    }
    return false;
};

export const getCheckUncheckAllKeywords = (defaultKeywords: string[], keywordList: OptionTypeInterface[], type: string) => {
    let selectedKeyword: string[] = [];
    const selectedLowercaseKeywords: string[] = [];
    if (defaultKeywords.length) {
        defaultKeywords.forEach((k) => {
            selectedLowercaseKeywords.push(k.toLowerCase());
        });

        const filteredKeywords = type === AddRemoveSuggestedKeyword.ADD ?
            filter(keywordList, (keyword) => {
                return selectedLowercaseKeywords.indexOf(keyword.label as string) === -1;
            })
            :
            filter(keywordList, (keyword: OptionTypeInterface) => selectedLowercaseKeywords.indexOf(keyword.label as string) !== -1);

        selectedKeyword = map(filteredKeywords, (keyword: OptionTypeInterface) => keyword.label as string);
    } else {
        keywordList.forEach((k: OptionTypeInterface) => selectedKeyword.push(k.label as string));
    }
    return selectedKeyword;
};

export const isAllKeywordSelected = (selectedKeywords: string[], pageKeywordList: OptionTypeInterface[]) => {
    if (pageKeywordList.length) {
        const SelectedKeywordName = map(selectedKeywords, (keywordName: string) => {
            return keywordName.toLowerCase();
        });
        const selectedKeywordList =
            pageKeywordList?.length ?
                pageKeywordList.filter((keyword: OptionTypeInterface) => SelectedKeywordName.includes(keyword.label.toString().toLowerCase())) : [];
        return selectedKeywordList?.length === pageKeywordList?.length;
    }
    return false;
};

export const onRemoveKeywords = (defaultKeywords: string[], removeKeywords: string[]) => {
    const newKeywords = filter(defaultKeywords, (key: string) => {
        return removeKeywords.indexOf(key) === -1;
    });
    return newKeywords;
};


export const updateValueInAppliedFilter = (
    appliedValue: KeywordFilterInterface,
    prevValue: AppliedFilterOnKeywordInterface
): AppliedFilterOnKeywordInterface => {

    const data = appliedValue.type;
    if (has(prevValue, appliedValue.type)) {
        // @ts-ignore
        prevValue[data as string] = appliedValue.value;
    }
    return prevValue;
};

export const findSearchKeyword = (
    args: AppliedFilterOnKeywordInterface,
    list: OptionTypeInterface[],
    searchType?: string | undefined
): OptionTypeInterface[] | undefined => {
    if (has(args, AppliedFilter.KEYWORDFILTER) && !isUndefined(args.keywordfilter)) {
        let updatedList;
        if (searchType === "Exclude") {
            updatedList = filter(list, (item: OptionTypeInterface) => {
                return (item.label as string).toLowerCase().search((args.keywordfilter as string).toLowerCase()) === -1;
            });
        } else if (args.keywordfilter) {
            updatedList = filter(list, (item: OptionTypeInterface) => {
                return (item.label as string).toLowerCase().search((args.keywordfilter as string).toLowerCase()) !== -1;
            });
        }
        return updatedList;
    }

    return undefined;
};

// tslint:disable-next-line: no-any
export const filterRangeCond = (rangeCase: string, range?: number | null): any => {
    switch (rangeCase) {
        case "1":
            return isNull(range) || isUndefined(range) || range < 100;
        case "2":
            return !isNull(range) && !isUndefined(range) && range >= 101 && range <= 1000;
        case "3":
            return !isNull(range) && !isUndefined(range) && range >= 1001 && range <= 10000;
        case "4":
            return !isNull(range) && !isUndefined(range) && range >= 10001 && range <= 100000;
        case "5":
            return !isNull(range) && !isUndefined(range) && range >= 100001 && range <= 1000000;
        case "6":
            return !isNull(range) && !isUndefined(range) && range >= 1000000;
    }
};

export const filterKeywordBasedOnSearchVolume = (
    searchType: string[],
    keywordList: OptionTypeInterface[]
): OptionTypeInterface[] => {
    let filteredArr: OptionTypeInterface[] = [];
    searchType.forEach((rangeCase) => {
        const filterResponse = filter(keywordList, (list: OptionTypeInterface): OptionTypeInterface[] =>
            filterRangeCond(rangeCase, list.searchVolume));
        filteredArr = filteredArr.concat(filterResponse as OptionTypeInterface[]);
    });
    return filteredArr;
};

// tslint:disable-next-line: no-any
export const filterScoreCond = (rangeCase: string, range?: number | null): any => {
    switch (rangeCase) {
        case "1":
            return isNull(range) || isUndefined(range) || range <= 0.3;
        case "2":
            return !isNull(range) && !isUndefined(range) && range >= 0.3 && range <= 0.5;
        case "3":
            return !isNull(range) && !isUndefined(range) && range >= 0.5 && range <= 0.7;
        case "4":
            return !isNull(range) && !isUndefined(range) && range >= 0.7 && range <= 1;
    }
};

export const filterKeywordBasedOnRelevanceScore = (
    searchType: string[],
    keywordList: OptionTypeInterface[]
): OptionTypeInterface[] => {
    let filteredArr: OptionTypeInterface[] = [];
    searchType.forEach((rangeCase) => {
        const filterResponse = filter(keywordList, (list: OptionTypeInterface): OptionTypeInterface[] =>
            filterScoreCond(rangeCase, list.relevance_score));
        filteredArr = filteredArr.concat(filterResponse as OptionTypeInterface[]);
    });
    return filteredArr;

};

export const getSortedKeywordList = (keywordList: OptionTypeInterface[], keywordSortType?: OptionTypeInterface): OptionTypeInterface[] => {
    const getKeywordSearchVolume = (keyword: OptionTypeInterface) => (keyword.searchVolume === null ? -1 : keyword.searchVolume);
    const getSearchVolumeAsc = (keyword: OptionTypeInterface) => (keyword.searchVolume === null ? Infinity : keyword.searchVolume);
    const getKeywordRank = (keyword: OptionTypeInterface) => (keyword.rank === null ? -1 : keyword.rank);
    const getRankAsc = (keyword: OptionTypeInterface) => (keyword.rank === null ? Infinity : keyword.rank);
    const getRelevanceScoreData =
        (keyword: OptionTypeInterface) => (isUndefined(keyword.relevance_score) && isNull(keyword.relevance_score) ? -1 : keyword.relevance_score);
    const getRelevanceScoreAsc =
        (keyword: OptionTypeInterface) => (isUndefined(keyword.relevance_score) && isNull(keyword.relevance_score) ? Infinity : keyword.relevance_score);
    switch (keywordSortType && keywordSortType.value) {
        case "SV_H_TO_L":
            return orderBy(keywordList, [getKeywordSearchVolume, "label"], ["desc"]);
        case "SV_L_TO_H":
            return orderBy(keywordList, [getSearchVolumeAsc, "label"]);
        case "ASC_A_TO_Z":
            return orderBy(keywordList, ["label"]);
        case "DES_Z_TO_A":
            return orderBy(keywordList, ["label"], ["desc"]);
        case "RS_H_TO_L":
            return orderBy(keywordList, [getRelevanceScoreData, "label"], ["desc"]);
        case "RS_L_TO_H":
            return orderBy(keywordList, [getRelevanceScoreAsc, "label"]);
        case "RANK_H_TO_L":
            return orderBy(keywordList, [getKeywordRank, "label"], ["desc"]);
        case "RANK_L_TO_H":
            return orderBy(keywordList, [getRankAsc, "label"]);
        case "URL_ASC_A_TO_Z":
            return orderBy(keywordList, ["label"]);
        case "URL_DES_Z_TO_A":
            return orderBy(keywordList, ["label"], ["desc"]);
        default:
            return orderBy(keywordList, [getKeywordSearchVolume, "label"], ["desc"]);

    }
};

export const isRelevanceScorePresentOrNot = (pageKeywordList: OptionTypeInterface[]) => {
    let found = true;
    map(pageKeywordList, (keywordName: OptionTypeInterface) => {
        if (!isUndefined(keywordName.relevance_score)) {
            found = false;
        }
    });
    return found;
};

export const showNotFoundText = (isLoading: boolean | string, keywordList: OptionTypeInterface[]) => {
    let showNotFound = false;
    if (((isEqual(isLoading, RELATED_KW_SSE_EVENT_TYPES.req_completed))
        && keywordList && !keywordList.length)) {
        showNotFound = true;
    } else if (isEqual(isLoading, false) && keywordList && !keywordList.length) {
        showNotFound = true;
    }
    return showNotFound;
};

export const getSearchVolume = (selectedKeyword: string, originalKeywordList: OptionTypeInterface[], isNotFormattedSV?: boolean) => {
    const keywordItem = originalKeywordList.find((keyword: OptionTypeInterface) => (keyword.label as string).toLowerCase() === selectedKeyword.toLowerCase());
    if (keywordItem && keywordItem.searchVolume !== null) {
        if (isNotFormattedSV) {
            return keywordItem.searchVolume;
        }
        return kFormatter(keywordItem.searchVolume as number, 2, false);
    }
    return "-";
};

export const getSearchVolumeFromArray = (selectedKeyword: string, originalKeywordList: OptionTypeInterface[]) => {
    const keywordItem = originalKeywordList.find((keyword: OptionTypeInterface) =>
        (keyword.label as string).toString().toLowerCase() === selectedKeyword.toLowerCase());
    if (keywordItem && keywordItem.searchVolume !== null) {
        return kFormatter(keywordItem.searchVolume as number, 2, false);
    }
    return "-";
};

export const removeExtraTabs = (args: string): string => {
    return args.replace(new RegExp("\t\t", "g"), "\t");
};

export const copyPasteFromExcel = (args: string): string => {
    const rows = args.split("\n");
    const data = rows;
    let newArr: string[] = [];
    if (data.length > 0) {
        // tslint:disable-next-line: forin
        for (const y in data) {
            data[y] = removeExtraTabs(rows[y]);
            const cells = data[y].split("\t");
            newArr = concat(newArr, cells);
        }
    }
    const removeExtraItem = compact(newArr);
    const keywordsName = removeExtraItem.toString();
    return keywordsName;
};

// tslint:disable-next-line: no-any
export const filterRankCond = (rangeCase: string, range?: number | null): any => {
    switch (rangeCase) {
        case "1":
            return isNull(range) || isUndefined(range) || range === 1;
        case "2":
            return !isNull(range) && !isUndefined(range) && range >= 2 && range <= 3;
        case "3":
            return !isNull(range) && !isUndefined(range) && range >= 4 && range <= 10;
        case "4":
            return !isNull(range) && !isUndefined(range) && range >= 11 && range <= 20;
        case "5":
            return !isNull(range) && !isUndefined(range) && range >= 21 && range <= 50;
        case "6":
            return !isNull(range) && !isUndefined(range) && range >= 51 && range <= 100;
    }
};

export const filterKeywordBasedOnRank = (
    searchType: string[],
    keywordList: OptionTypeInterface[]
): OptionTypeInterface[] => {
    let filteredArr: OptionTypeInterface[] = [];
    searchType.forEach((rangeCase) => {
        const filterResponse = filter(keywordList, (list: OptionTypeInterface): OptionTypeInterface[] =>
            filterRankCond(rangeCase, list.rank));
        filteredArr = filteredArr.concat(filterResponse as OptionTypeInterface[]);
    });
    return filteredArr;

};

export const filterKeywordBasedOnURLs = (
    urlList: string[],
    keywordList: OptionTypeInterface[]
): OptionTypeInterface[] => {
    const filteredArr: OptionTypeInterface[] = [];
    urlList.forEach((url) => {
        keywordList.forEach((keyword: OptionTypeInterface) => {
            if (keyword.url === url) {
                filteredArr.push(keyword);
            }
        });
    });
    return filteredArr;
};

export const getFilteredKeywords = (filteredKeywords: OptionTypeInterface[], keywordList: OptionTypeInterface[]) => {
    if (filteredKeywords && filteredKeywords.length) {
        return filteredKeywords.filter((keyword: OptionTypeInterface) => {
            if (isUndefined(keyword.relevance_score)) {
                keywordList.find((keywordItem: OptionTypeInterface) => {
                    if (keywordItem.label === keyword.label) {
                        keyword.relevance_score = keywordItem.relevance_score;
                    }
                });
                return keyword;
            }
            return keyword;
        });
    }
    return filteredKeywords;
};
