import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import actionTypes from "./actions";
import { CustomReportingReducerInterface } from "./types";

export const INIT_STATE: CustomReportingReducerInterface = {
    embedConfig: {
        accessToken: "",
        embedUrl: "",
        isPublisher: false
    },
    isEmbedTokenFetchSuccess: true,
};

const Reducer = handleActions({
    [actionTypes.SET_TABLEAU_EMBED_CONFIG]: (state, action: AnyAction) => ({
        ...state,
        embedConfig: {
            ...state.embedConfig,
            ...action.payload
        }
    }),
    [actionTypes.RESET_TABLEAU_EMBED_CONFIG]: (state, action: AnyAction) => ({
        ...state,
        embedConfig: {
            ...state.embedConfig,
            accessToken: ""
        },
        isEmbedTokenFetchSuccess: true
    }),
    [actionTypes.SET_IS_EMBED_TOKEN_FETCH_SUCCESS]: (state, action: AnyAction) => ({
        ...state,
        isEmbedTokenFetchSuccess: action.payload,
    }),
}, INIT_STATE);

export default Reducer;
