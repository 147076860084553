import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */
const actionTypes = {
    GET_SUGGESTED_RELATED_KW_REQUEST_IDS_API: "GET_SUGGESTED_RELATED_KW_REQUEST_IDS_API",
    GET_SUGGESTED_RELATED_KW: "GET_SUGGESTED_RELATED_KW",
    SET_SUGGESTED_RELATED_KW: "SET_SUGGESTED_RELATED_KW",
    SSE_API_LOADING: "SSE_API_LOADING",
    APPLIED_KEYWORD_FILTERS: "APPLIED_KEYWORD_FILTERS",
    SET_KEYWORD_SSE_RETRIED_ATTEMPT: "SET_KEYWORD_SSE_RETRIED_ATTEMPT",
    CLEAR_ALL_FILTERS: "CLEAR_ALL_FILTERS",
    REQUEST_ID_API_STATUS: "REQUEST_ID_API_STATUS",
    CURRENT_TOPIC_OF_NEW_CB: "CURRENT_TOPIC_OF_NEW_CB",
    SET_CB_INCLUDE_KEYWORDS: "SET_CB_INCLUDE_KEYWORDS",
    SET_CB_EXCLUDE_KEYWORDS: "SET_CB_EXCLUDE_KEYWORDS",
    SET_CB_ADVANCE_FILTER_TYPE: "SET_CB_ADVANCE_FILTER_TYPE",
    MULTIKEYWORD_INCLUDE_KEYWORD_TYPE: "MULTIKEYWORD_INCLUDE_KEYWORD_TYPE",
};
export default actionTypes;

export const callToGetSuggestedRelatedKWReqId = createAction(actionTypes.GET_SUGGESTED_RELATED_KW_REQUEST_IDS_API);
export const callToGetSuggestedRelatedKW = createAction(actionTypes.GET_SUGGESTED_RELATED_KW);
export const setSuggestedRelatedKW = createAction(actionTypes.SET_SUGGESTED_RELATED_KW);
export const sseAPILoading = createAction(actionTypes.SSE_API_LOADING);
export const appliedKeywordFilters = createAction(actionTypes.APPLIED_KEYWORD_FILTERS);
export const setKeywordSSeRetriedAttempt = createAction(actionTypes.SET_KEYWORD_SSE_RETRIED_ATTEMPT);
export const onClearAllFilters = createAction(actionTypes.CLEAR_ALL_FILTERS);
export const setRequestIdAPIStatus = createAction(actionTypes.REQUEST_ID_API_STATUS);
export const currentTopicOfNewCB = createAction(actionTypes.CURRENT_TOPIC_OF_NEW_CB);
export const setIncludeKeywordType = createAction(actionTypes.MULTIKEYWORD_INCLUDE_KEYWORD_TYPE);
export const setCBIncludeKeywords = createAction(actionTypes.SET_CB_INCLUDE_KEYWORDS);
export const setCBExcludeKeywords = createAction(actionTypes.SET_CB_EXCLUDE_KEYWORDS);
export const setCBAdvanceFilterType = createAction(actionTypes.SET_CB_ADVANCE_FILTER_TYPE);
