import * as React from "react";
import styled from "styled-components";
import { ReactSelect } from "./dropdown";
import { OptionTypeInterface, FormatOptionTypeInterface, ComponentsTypes, StylesType, LocaleSearchOptionTypeInterface } from "./types";
import Button, { StyledButton, StyledLabel, BUTTON_TYPE } from "../button";
import { IS_DEV_SRC, PAGENOTCRAWL } from "../../const";
import { FlowTypeInterface, StyledComponentStylesType } from "../../duck/types";
// tslint:disable-next-line:no-var-requires
const searchImg = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/search.svg` : require(`${process.env.DEV_IMAGE_URL}/img/search.svg`);

export const StyledSearchableDropdownButton = styled(StyledButton)`
    background: #fff;
    align-items: center;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    display: flex;
    height: 30px;
    justify-content: space-between;
    max-width: 300px;
    padding: 0 10px 1px;
    text-align: left;
    width: 120px;
    border: 1px solid #afafaf;
`;

export const StyledSearchableDropdownButtonLabel = styled(StyledLabel)`
    text-transform: uppercase;
    color: #343434;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    width: 100%;
`;

export const StyledSearchableDropdownButtonWithRadius = styled(StyledButton)`
    background: #fff;
    align-items: center;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    border-radius:3px;
    display: flex;
    height: 30px;
    justify-content: space-between;
    max-width: 300px;
    padding: 0 10px 1px;
    text-align: left;
    width: 120px;
    border: 1px solid #afafaf;
`;
interface SearchableDropdownPropsInterface {
    styles?: StylesType;
    options: OptionTypeInterface[];
    component?: ComponentsTypes;
    placeholder: string;
    value?: OptionTypeInterface;
    instanceId?: string;
    optionsId?: string;
    buttonStyles?: StyledComponentStylesType;
    flowType?: FlowTypeInterface;
    disabled?: boolean;
    // tslint:disable-next-line:no-any
    onChange(value: any): void;
    formatOptionLabel?(args: FormatOptionTypeInterface): JSX.Element;
    formatButtonLabel?(args: FormatOptionTypeInterface): JSX.Element;
    customFilter?(option: LocaleSearchOptionTypeInterface, searchText: string): boolean;
}

interface SearchableDropdownStateInterface {
    isOpen: boolean;
    value?: OptionTypeInterface;
}

const DropdownIndicator: React.SFC = () => {
    return (
        <div
            style={{
                position: "absolute",
                right: "10px"
            }}
        >
            <img src={searchImg} />
        </div>
    );
};

interface DropdownButtonLabelPropsInterface {
    placeholder: string;
    value?: OptionTypeInterface;
    formatOptionLabel?(args: FormatOptionTypeInterface): JSX.Element;
}

const DropdownButtonLabel: React.SFC<DropdownButtonLabelPropsInterface> = ({ formatOptionLabel, value, placeholder }) => (
    <React.Fragment>
        {formatOptionLabel && value ? formatOptionLabel(value) : placeholder}
        <ChevronDown />
    </React.Fragment>
);

/**
 *  Searchable dropdown:
 *
 *  Multiselect dropdown renders the dropdown with searchbox in the options list
 *
 *  Props ::
 *
 *  @styles => (type: object) : style object to style the dropdown refer to https://react-select.com/styles#style-object
 *  @options => (type: array of objects) :this prop will accept array of objects with label & key as mandatory fields to show the dropdown list
 *  @component => (type: component object list): customisable components can be passed over here to override the default components provided by the
 *                react select refer to https://react-select.com/components
 *  @placeholder => (type: string): placeholder text for the dropdown label
 *  @onChange => (type: object) : callback function to trigger when the dropdown option is selected/changed
 *  @value => (type: object) : this accepts the default select value for the dropdown which can be used as controlled component
 *  @formatOptionLabel => (type: component) : this is generic formatting option to change the label and option of the dropdown
 *  @instanceId => the string passed to this prop will be applied as id to container component of dropdown
 *  @optionsId => the string passed to this prop will be appened to the options tag of the dropdown
 *
 */

export default class SearchableDropdown extends React.Component<SearchableDropdownPropsInterface, SearchableDropdownStateInterface> {
    public constructor(props: SearchableDropdownPropsInterface) {
        super(props);
        this.state = {
            isOpen: false,
            value: props.value || undefined
        };
        this.toggleOpen = this.toggleOpen.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
    }
    public componentWillReceiveProps(nextProps: SearchableDropdownPropsInterface): void {
        if ((!this.state.value && nextProps.value) || (this.state.value !== nextProps.value)) {
            this.setState({ value: nextProps.value });
        }
    }
    public render(): JSX.Element {
        const { isOpen, value } = this.state;
        const {
            options,
            placeholder,
            styles,
            formatOptionLabel,
            formatButtonLabel,
            customFilter,
            component,
            instanceId = "",
            optionsId = "",
            buttonStyles,
            flowType = "",
            disabled = false
        } = this.props;
        return (
            <DropdownWrapper
                id={instanceId}
                isOpen={isOpen}
                onClose={this.toggleOpen}
                target={
                    <Button
                        disabled={disabled}
                        instanceId={instanceId}
                        variant={BUTTON_TYPE.DEFAULT}
                        styles={{
                            Button: buttonStyles ?
                                buttonStyles :
                                (flowType && (flowType === FlowTypeInterface.PAGE_CONTENTBRIEF || flowType === FlowTypeInterface.PAGE_OPTIMIZER)
                                    ? StyledSearchableDropdownButtonWithRadius : StyledSearchableDropdownButton),
                            Label: StyledSearchableDropdownButtonLabel,
                        }}
                        onClickHandler={() => this.toggleOpen()}
                        label={<DropdownButtonLabel formatOptionLabel={formatButtonLabel} placeholder={placeholder} value={value} />}
                    />
                }
            >
                <ReactSelect
                    optionsId={optionsId}
                    styles={styles}
                    options={options}
                    placeholder={placeholder}
                    onChange={this.onSelectChange}
                    component={{ DropdownIndicator, ...component }}
                    autoFocus={true}
                    backspaceRemovesValue={false}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    isSearchable={true}
                    menuIsOpen={true}
                    tabSelectsValue={false}
                    value={value}
                    formatOptionLabel={formatOptionLabel}
                    customFilter={customFilter}
                />
            </DropdownWrapper>
        );
    }
    public toggleOpen(): void {
        this.setState({ isOpen: !this.state.isOpen });
    }
    // tslint:disable-next-line:no-any
    public onSelectChange(value: any): void {
        this.toggleOpen();
        this.setState({ value });
        this.props.onChange(value);
    }
}

// tslint:disable-next-line:no-any
const DropdownWrapper = (props: any) => (
    <div id={props.id || ""} style={props.id === `${PAGENOTCRAWL.INSTANCE_ID}_locale_dropdown` ? { position: "absolute", zIndex: 2 } : {}}>
        {props.target}
        {props.isOpen ? (
            // wrap in styledComponent
            <div style={{ position: "relative", zIndex: 2 }}>{props.children}</div>
        ) : null}
        {props.isOpen ? <Blanket onClick={props.onClose} /> : null}
    </div>
);

// tslint:disable-next-line:no-any
const Blanket = (props: any) => (
    <div
        style={{
            bottom: 0,
            left: 0,
            position: "fixed",
            right: 0,
            top: 0,
            zIndex: 0
        }}
        {...props}
    />
);

/** Anujay: import it from img/*.svg */
// tslint:disable-next-line:no-any
const Svg = (p: any) => <svg width="35" height="35" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />;

/** Anujay: import it from img/*.svg */
const ChevronDown = () => (
    <Svg height="16" width="16" viewBox="0 0 20 20" style={{ marginRight: -5 }}>
        <path
            // tslint:disable-next-line:max-line-length
            // d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
            d="M14.136 4c.311-.004.6.146.757.392.154.258.141.57-.035.814L8.704 11.67c-.17.208-.437.33-.72.33-.282 0-.55-.122-.719-.33L1.13 5.206c-.091-.122-.137-.27-.128-.42 0-.136.035-.27.1-.394.159-.234.433-.38.733-.392h12.302z"
            fill="#343434"
            fillRule="evenodd"
        />
    </Svg>
);
