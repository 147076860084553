import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import AuthActionTypes from "./actions";
import { SimulatorAuthorityTechnicalInterface } from "./types";

export const INIT_AUTH_TECH_STATE: SimulatorAuthorityTechnicalInterface = {
    isDescriptionUpdateFailed: false
};

const AuthTechReducer = handleActions(
    {
        [AuthActionTypes.SET_IS_DESCRIPTION_UPDATE_FAILED]: (state, action: AnyAction) => ({
            ...state,
            isDescriptionUpdateFailed: action.payload
        })
    },
    INIT_AUTH_TECH_STATE
);

export default AuthTechReducer;
