import { AnyAction } from "redux";
import { call, cancelled, delay, put, race, select, takeEvery, takeLatest } from "redux-saga/effects";
import { isEmpty } from "lodash";
import Apis from "../../app/apis";
import { setLocaleInContext } from "../../app/duck/actions";
import ActionTypes, {
    callToGetRelatedKeywordFromSseApi,
    incrementReceiveRelatedKwdsEventCount,
    setKeywordResearchRequestId,
    setRelatedKeywordsFetching,
    setSearchInputValue,
    setSelectedInputType,
    setSSeRetriedAttempt,
    setHideLoader,
    setShowLoader,
    resetReceiveRelatedKwdsEventCount,
    setRelatedKeywords
} from "./actions";
import { RelatedRequestIdsApiRequestInterface } from "./types";
import { eventChannel } from "redux-saga";
import { take } from "@redux-saga/core/effects";
import { KwResearchInitiateRequestErrors, KwResearchSSEErrors, KW_RESEARCH_PAGE_SEARCH_TYPES, RELATED_KW_SSE_EVENT_TYPES } from "../../app/const";
import { getReceivedRelatedKwdsEventCount, getSSeRetriedAttempts } from "./selector";
import { ApiConfig } from "../../app/config/apiConfig";
import { ApiPath } from "../../app/config/apiPath";
import { notifyError } from "../../app/duck/utils";
import { getSearchInputType } from "./utils";

// tslint:disable-next-line:typedef
function* callToGetKwResearchequestId(action: AnyAction) {
    const { payload } = action;
    yield put(setSelectedInputType(payload.inputType));
    yield put(setLocaleInContext(payload.locale));
    yield put(setSearchInputValue(payload.input));
    const requestPayload: RelatedRequestIdsApiRequestInterface = {
        ...(payload.inputType === KW_RESEARCH_PAGE_SEARCH_TYPES.keyword && { keyword: (payload.input || "").toLowerCase() }),
        ...(payload.inputType === KW_RESEARCH_PAGE_SEARCH_TYPES.url && { url: payload.input }),
        ...(payload.inputType === KW_RESEARCH_PAGE_SEARCH_TYPES.domain && { domain: payload.input }),
        locale: payload.locale,
        source: payload.source,
        needToHandleError: true,
        isSpecificError: true,
        errorList: KwResearchInitiateRequestErrors,
        notifyErrorMethod: payload.notifyErrorMethod
    };
    if (payload.inputType !== KW_RESEARCH_PAGE_SEARCH_TYPES.keyword) {
        delete requestPayload.source;
    }

    yield put(setShowLoader());
    yield put(setRelatedKeywordsFetching(true));

    const parser = {
        type: getSearchInputType(payload.inputType)
    };
    const response = yield call(Apis.getKwResearchRelatedKeywordRequestIds, requestPayload, parser);

    if (response.status === 200) {
        const { ref_id: referenceId, token } = response.data.data;
        yield put(setRelatedKeywordsFetching(true));

        yield put(setSSeRetriedAttempt(0));
        yield put(resetReceiveRelatedKwdsEventCount());
        yield put(setKeywordResearchRequestId(referenceId));
        yield put(callToGetRelatedKeywordFromSseApi({
            token,
            requestId: referenceId,
            notifyErrorMethod: payload.notifyErrorMethod
        }));
    } else {
        yield put(setHideLoader());
        yield put(setRelatedKeywordsFetching(false));
    }
}

// tslint:disable-next-line:typedef
function listenSSEvents(url: string) {
    const eventChan = eventChannel((emit) => {
        const sseSource = new EventSource(url);
        sseSource.addEventListener(RELATED_KW_SSE_EVENT_TYPES.receive_related_kwds, (ev: Event) => emit(ev));
        sseSource.addEventListener(RELATED_KW_SSE_EVENT_TYPES.req_completed, (ev: Event) => emit(ev));
        sseSource.addEventListener(RELATED_KW_SSE_EVENT_TYPES.req_failed, (ev: Event) => emit(ev));
        sseSource.onerror = (ev: Event) => {
            emit(ev);
        };
        return () => sseSource.close();
    });
    return eventChan;
}

// tslint:disable-next-line:typedef
function* callToGetKwResearchRequestStatus(action: AnyAction) {
    const { requestId, notifyErrorMethod, token } = action.payload;
    const url = `${ApiConfig.rkBaseURL}${ApiPath.RELATED_KEYWORD_SSE_API}?ref_id=${requestId}&token=${token}`;
    const { channel, timeout } = yield race({
        channel: call(listenSSEvents, url),
        timeout: delay(300 * 1000)
    });

    const retriedAttempt = yield select(getSSeRetriedAttempts);

    const parameter = {
        requestId,
        notifyErrorMethod,
        token
    };

    if (timeout) {
        if (retriedAttempt < 3) {
            channel.close();
            yield put(setSSeRetriedAttempt(retriedAttempt + 1));
            yield put(callToGetRelatedKeywordFromSseApi(parameter));
        }
        channel.close();

        yield put(setRelatedKeywordsFetching(RELATED_KW_SSE_EVENT_TYPES.req_failed));
        yield put(setHideLoader());
        yield put(setRelatedKeywords());

        notifyError(400, {
            notifyErrorMethod,
            needToHandleError: true,
            errorList: KwResearchSSEErrors,
            isSpecificError: true
        });
    }


    try {
        while (true) {
            try {
                const response = yield take(channel);
                if (response.type === RELATED_KW_SSE_EVENT_TYPES.receive_related_kwds) {
                    if (!isEmpty(response.data) && JSON.parse(response.data).length > 0) {
                        yield put(setRelatedKeywordsFetching(RELATED_KW_SSE_EVENT_TYPES.receive_related_kwds));
                        yield put(incrementReceiveRelatedKwdsEventCount());
                        yield put(setRelatedKeywords(JSON.parse(response.data)));
                    }
                }
                if (response.type === RELATED_KW_SSE_EVENT_TYPES.req_completed) {
                    const count = yield select(getReceivedRelatedKwdsEventCount);
                    if (count >= 1) {
                        yield put(incrementReceiveRelatedKwdsEventCount());
                    } else {
                        yield put(setHideLoader());
                    }
                    yield put(setRelatedKeywordsFetching(RELATED_KW_SSE_EVENT_TYPES.req_completed));
                    channel.close();
                }
                if (response.type === RELATED_KW_SSE_EVENT_TYPES.req_failed || response.type === RELATED_KW_SSE_EVENT_TYPES.error) {
                    // notify the error
                    if (retriedAttempt < 3) {
                        channel.close();
                        yield put(setSSeRetriedAttempt(retriedAttempt + 1));
                        yield put(callToGetRelatedKeywordFromSseApi(parameter));
                    }

                    channel.close();
                    yield put(setHideLoader());
                    yield put(setRelatedKeywordsFetching(RELATED_KW_SSE_EVENT_TYPES.req_failed));
                    if (isEmpty(response.data) || !JSON.parse(response.data).length) {
                        notifyError(400, {
                            notifyErrorMethod,
                            needToHandleError: true,
                            errorList: KwResearchSSEErrors,
                            isSpecificError: true
                        });
                    }
                }

            } catch (error) {
                if (retriedAttempt < 3) {
                    channel.close();
                    yield put(setSSeRetriedAttempt(retriedAttempt + 1));
                    yield put(callToGetRelatedKeywordFromSseApi(parameter));
                }
                channel.close();
                yield put(setHideLoader());
                yield put(setRelatedKeywordsFetching(RELATED_KW_SSE_EVENT_TYPES.req_failed));
            }
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
        }
    }
}

// tslint:disable-next-line:typedef
export function* watchForKwResearchGetRequestId() {
    yield takeEvery(ActionTypes.CALL_TO_GET_KW_RESEARCH_REQUEST_ID, callToGetKwResearchequestId);
}

// tslint:disable-next-line:typedef
export function* watchForCallToGetRelatedKeywordTrackRequestStatus() {
    yield takeLatest(ActionTypes.CALL_TO_GET_RELATED_KEYWORD_FROM_SSE_API, callToGetKwResearchRequestStatus);
}
