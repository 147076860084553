import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import actionTypes from "./actions";
import { ContextualRecommendationsReducerInterface } from "./types";

const INIT_STATE: ContextualRecommendationsReducerInterface = {
    contextualRecommendations: [],
    isContextRecomModalOpen: false,
    isContextualRecomLoding: false,
    isContextualApiFail: undefined
};

const ContextualRecommendationReducer = handleActions(
    {

        [actionTypes.SET_CONTEXTUAL_RECOMMENDATION]: (state, action: AnyAction) => ({
            ...state,
            contextualRecommendations: action.payload,
            isContextualRecomLoding: false
        }),
        [actionTypes.SET_CONTEXTUAL_RECOMMENDATION_MODAL_OPEN]: (state, action: AnyAction) => ({
            ...state,
            isContextRecomModalOpen: action.payload
        }),
        [actionTypes.CALL_CONTEXTUAL_RECOMMENDATION]: (state, action: AnyAction) => ({
            ...state,
            isContextualRecomLoding: true,
            isContextualApiFail: undefined
        }),
        [actionTypes.SET_CONTEXTUAL_RECOMMENDATION_API_FAIL]: (state, action: AnyAction) => ({
            ...state,
            isContextualApiFail: action.payload
        })
    },
    INIT_STATE);

export default ContextualRecommendationReducer;
