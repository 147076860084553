import * as React from "react";
import styled from "styled-components";
import { StyledComponentStylesType } from "../duck/types";
import { lockWhiteAlps } from "../icons";

/*
    default = without any styles
    primary = blue filled,
    secondary = green filled,
    inverted = blue bordered,
    link = button with label and underline
*/
export enum BUTTON_TYPE {
    DEFAULT = "DEFAULT",
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY",
    INVERT = "INVERT",
    LINK = "LINK"
}

interface ButtonStylesPropInterface {
    Button?: StyledComponentStylesType;
    Label?: StyledComponentStylesType;
}

export interface ButtonPropsInterface {
    label: string | JSX.Element;
    variant?: BUTTON_TYPE;
    instanceId?: string;
    disabled?: boolean;
    isEllipsis?: boolean;
    maxWidth?: string;
    styles?: ButtonStylesPropInterface;
    isLocked?: boolean;
    onClickHandler(): void;
    lockedPopUpHandler?(args: boolean): void;
}

// configure button styles based on variant prop types defined below

export const StyledLabel = styled.span`
    color: #fff;
    color: ${(props: ButtonPropsInterface) => props.variant === BUTTON_TYPE.INVERT && "#00acce"};
    font-size: 12px;
    font-family: Lato;
    font-size: ${(props: ButtonPropsInterface) => props.variant === BUTTON_TYPE.INVERT && "12px"};
    font-weight: 700;
    white-space: nowrap;
    font-size: ${(props: ButtonPropsInterface) => props.variant === BUTTON_TYPE.SECONDARY && "18px"};

    ${(props: ButtonPropsInterface) =>
        props.isEllipsis &&
        `
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: ${props.maxWidth ? props.maxWidth : "35vw"};
        display: block;
    `}
`;

export const StyledButton = styled.button`
    cursor: ${(props: { disabled: boolean }) => (props.disabled ? "not-allowed" : "pointer")};
    border: none;
`;

// Primary button starts here

export const StyledPrimaryButton = styled.button`
    height: 30px;
    border-radius: 3px;
    padding: 0px 20px;
    background-color: #00acce;
    border-radius: 3px;
    cursor: ${(props: { disabled: boolean }) => (props.disabled ? "not-allowed" : "pointer")};
    border: none;
    &:hover {
        background-color: #0198b6;
    }
    &:active {
        background-color: #0287a1;
    }
    &:disabled {
        background-color: #afafaf;
        cursor: default;
    }
`;

// Invert button starts here

export const StyledInvertButton = styled.button`
    height: 28px;
    border: 1px solid #00acce;
    padding: 0px 20px;
    background-color: #fff;
    border-radius: 3px;
    cursor: ${(props: { disabled: boolean }) => (props.disabled ? "not-allowed" : "pointer")};
    color: #00acce;
    &:hover {
        border: 1px solid #0198b6;
        ${StyledLabel} {
            color: #0198b6;
        }
    }
    &:disabled {
        cursor: default;
        border: 1px solid #838793;
        ${StyledLabel} {
            color: #838793;
        }
    }
`;

// Secondary button starts here

export const StyledSecondaryButton = styled.button`
    height: 30px;
    background-color: ${(props: { disabled: boolean }) => (props.disabled ? "#afafaf" : "#1fd8a6")};
    border: none;
    cursor: ${(props: { disabled: boolean }) => (props.disabled ? "not-allowed" : "pointer")};
    border-radius: 3px;
    /* box-shadow: 0 2px 1px 0 rgba(175, 173, 173, 0.24); */
    &:hover {
        background-color: ${(props: { disabled: boolean }) => (props.disabled ? "#afafaf" : "#0acd98")};
    }
`;

// Button link starts here

export const StyledLinkButton = styled.button`
    cursor: ${(props: { disabled: boolean }) => (props.disabled ? "not-allowed" : "pointer")};
    background: transparent;
    border: none;
    ${StyledLabel} {
        color: ${(props: { variant: BUTTON_TYPE; disabled: boolean }) =>
        props.variant === BUTTON_TYPE.LINK && props.disabled ? "#afafaf" : "#00acce"};
        font-size: 12px;
        font-weight: 500;
        text-decoration: underline;
        &:hover {
            color: ${(props: { variant: BUTTON_TYPE; disabled: boolean }) =>
        props.variant === BUTTON_TYPE.LINK && props.disabled ? "#afafaf" : "#0198b6"};
        }
    }
`;
// ! locked icon on button---->>
export const StyledLockIcon = styled.img`
    margin-left: 5px;
    margin-bottom: -3px;
`;

const ButtonComponent: React.SFC<ButtonPropsInterface> = ({
    label = "Button",
    instanceId = "",
    variant = BUTTON_TYPE.DEFAULT,
    styles = {},
    disabled = false,
    isEllipsis = false,
    maxWidth = "",
    isLocked = false,
    lockedPopUpHandler,
    onClickHandler
}): JSX.Element => {
    const getButtonStyle = (buttonVariant: string) => {
        if (buttonVariant === BUTTON_TYPE.PRIMARY) {
            return StyledPrimaryButton;
        }
        if (buttonVariant === BUTTON_TYPE.SECONDARY) {
            return StyledSecondaryButton;
        }
        if (buttonVariant === BUTTON_TYPE.INVERT) {
            return StyledInvertButton;
        }
        if (buttonVariant === BUTTON_TYPE.LINK) {
            return StyledLinkButton;
        }
        return StyledButton;
    };
    const { Button = getButtonStyle(variant), Label = StyledLabel } = styles;

    const clickHandler = () => {
        if (lockedPopUpHandler) {
            lockedPopUpHandler(true);
        }
    };
    return (
        <Button
            variant={variant}
            disabled={disabled}
            {...{
                ...(!disabled && { onClick: onClickHandler }),
                ...(isLocked && instanceId === "global_header_projectSetup" && { onClick: clickHandler }),
                ...(instanceId && { id: `${instanceId}_button` })
            }}
        >
            {label && <Label id={instanceId} isEllipsis={isEllipsis} maxWidth={maxWidth} variant={variant}>{label}</Label>}
            {isLocked && instanceId === "global_header_projectSetup" && <StyledLockIcon src={lockWhiteAlps} />}
        </Button>
    );
};

export default ButtonComponent;
