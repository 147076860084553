import { call, delay, put, race, select, takeLatest } from "redux-saga/effects";
import actionTypes, {
    setEmbedConfig,
    setFilterConfig,
    setFilterProjectLocaleList,
    setProjectsList,
    setIsGSCAccountConfigured,
    setLoader,
    setTargetDomainFiltersList,
    getReportToken,
    setFetchReportTokenRetriedAttempt,
    setIsProjectsArrayEmpty,
    setIsProjectDomainsPresent,
    setDefaultProjectTADomain,
    setSOVTargetCompetitorDomains,
    setDefaultProjectCategory
} from "./actions";
import { AnyAction } from "redux";
import {
    RANK_TRACKING_COMBINED_REPORT,
    RANK_TRACKING_DASHBOARD_REPORT,
    SHARE_OF_VOICE,
    TOPICAL_AUTHORITY
} from "../../app/const";

import { has, isNull } from "lodash";
import Apis from "../../app/apis";
import { getContext } from "../../app/duck/context-container";
import { getPageName, getProjectPageName, getReportId } from "./utils";
import { getFetchReportTokenRetriedAttempts } from "./selector";

// tslint:disable-next-line:typedef
export function* fetchTargetDomainFilterDetails(action: AnyAction) {
    yield put(setLoader(true));
    const { tenantCode = "" } = getContext();
    const payload = {
        tenantCode,
        needToHandleError: true
    };
    const response = yield call(Apis.getReportsTargetDomainFilter, payload);
    if (response === undefined || response.status === 204) {
        yield put(setIsProjectDomainsPresent(false));
    }
    if (response.status === 200) {
        const actionPayload = {
            targetDomains: response.data.target_domains,
            tenantCode: payload.tenantCode
        };
        yield put(setTargetDomainFiltersList(actionPayload));
        yield put(setIsProjectDomainsPresent(true));
    }
}

// tslint:disable-next-line:typedef
export function* fetchProjectsListForGSCAccountCheck(action: AnyAction) {
    yield put(setLoader(true));
    const { tenantCode = "" } = getContext();
    const payload = {
        tenantCode,
        needToHandleError: true
    };
    const response = yield call(Apis.getProjectsList, payload);
    yield put(setIsGSCAccountConfigured(false));
    if (response.status === 200) {
        const projectsData = response.data;
        let isGSCConfg = false;
        for (const currentProject of projectsData) {
            if (currentProject.valid_webmaster) {
                isGSCConfg = true;
                break;
            }
        }
        yield put(setIsGSCAccountConfigured(isGSCConfg));
    }
}

// tslint:disable-next-line:typedef
export function* fetchReportToken(action: AnyAction) {
    yield put(setLoader(true));
    const { tenantCode = "" } = getContext();
    const { response, timeout } = yield race({
        response: call(Apis.getReportsToken, action.payload, { tenantCode: tenantCode as string }),
        timeout: delay(12 * 1000)
    });

    if (timeout) {
        const retriedAttempt = yield select(getFetchReportTokenRetriedAttempts);
        if (retriedAttempt < 2) {
            yield put(setFetchReportTokenRetriedAttempt(retriedAttempt + 1));
            yield put(getReportToken({ ...action.payload }));
        } else {
            yield put(setLoader(false));
        }
    } else {
        if (response.status === 200 && !has(response.data, "error")) {
            if (
                action.payload.reportCode === RANK_TRACKING_COMBINED_REPORT
                || action.payload.reportCode === RANK_TRACKING_DASHBOARD_REPORT
                || action.payload.reportCode === SHARE_OF_VOICE
                || action.payload.reportCode === TOPICAL_AUTHORITY
            ) {
                const { dataset_id, report_id, embed_url, embed_token } = response.data.embed_details;
                yield put(
                    setEmbedConfig({
                        accessToken: embed_token,
                        embedUrl: embed_url,
                        id: report_id,
                        pageName: getProjectPageName(response.data.sections, action.payload.sectionCode),
                        datasetId: dataset_id
                    })
                );
            } else {
                const { access_token, embed_url } = response.data.embed_details;
                yield put(
                    setEmbedConfig({
                        accessToken: access_token,
                        embedUrl: embed_url,
                        id: getReportId(embed_url),
                        pageName: getPageName(response.data.sections, action.payload.sectionCode)
                    })
                );
            }
            yield put(
                setFilterConfig({
                    sections: response.data.sections
                })
            );
            yield put(setLoader(false));
        } else {
            const retriedAttempt = yield select(getFetchReportTokenRetriedAttempts);
            if (retriedAttempt < 2) {
                yield put(setFetchReportTokenRetriedAttempt(retriedAttempt + 1));
                yield put(getReportToken({ ...action.payload }));
            } else {
                yield put(setLoader(false));
            }
        }
    }
}

// tslint:disable-next-line:typedef
export function* fetchDefaultProjectCategory(action: AnyAction) {
    const { tenantCode = "" } = getContext();
    const response = yield call(Apis.getDefaultProjectCategory, action.payload, { tenantCode: tenantCode as string });

    if (response.status === 200 || response.status === 204) {
        const projectCategory = response.data.project_default_category || "";
        yield put(setDefaultProjectCategory(projectCategory));
    }
}

// tslint:disable-next-line:typedef
export function* fetchSOVTargetCompetitorDomains(action: AnyAction) {
    const { tenantCode = "" } = getContext();
    const response = yield call(Apis.getSOVTargetCompetitorDomains, action.payload, { tenantCode: tenantCode as string });

    if (response.status === 200 || response.status === 204) {
        const sovTargetCompetitorDomains = response.data.project_sov_target_competitor_domains || [""];
        yield put(setSOVTargetCompetitorDomains(sovTargetCompetitorDomains));
    }
}

// tslint:disable-next-line:typedef
export function* fetchDefaultProjectTADomain(action: AnyAction) {
    const { tenantCode = "" } = getContext();
    const response = yield call(Apis.getDefaultProjectTADomain, action.payload, { tenantCode: tenantCode as string });

    if (response.status === 200 || response.status === 204) {
        const projectTADomain = response.data.project_default_ta_domain || "";
        yield put(setDefaultProjectTADomain(projectTADomain));
    }
}

// tslint:disable-next-line:typedef
export function* callRefreshProjectCache(action: AnyAction) {
    const { tenantCode = "", projectId = "" } = getContext();
    const preMeta = localStorage.getItem("ls.__iQ_alps");
    const ls = !isNull(preMeta) ? JSON.parse(preMeta) : {};
    const activeProjectId = ls.currentProjectIDUnderEdit || projectId;
    yield call(Apis.refreshProjectCache, { tenantCode: tenantCode as string, projectId: activeProjectId });
}

// tslint:disable-next-line:typedef
export function* fetchProjectsList(action: AnyAction) {
    yield put(setLoader(true));
    const { tenantCode = "" } = getContext();
    const payload = {
        tenantCode,
        needToHandleError: true
    };
    const response = yield call(Apis.getProjectsList, payload);

    if (response.status === 200) {
        const projectsArray = [];
        const projectsData = response.data;
        if (!projectsData.length) {
            yield put(setIsProjectsArrayEmpty(true));
            return;
        }
        // tslint:disable-next-line:no-any
        const filterProjectLocaleList: any = {};
        for (const currentProject of projectsData) {
            const currentProjectDetails = {
                id: currentProject.id,
                name: currentProject.name,
                locale: currentProject.locale
            };
            filterProjectLocaleList[currentProject.name] = currentProject.locale;
            projectsArray.push(currentProjectDetails);
        }
        projectsArray.sort((projectA, projectB) => (projectA.name > projectB.name ? 1 : -1));
        const actionPayload = {
            projectsArray,
            tenantCode: payload.tenantCode
        };
        yield put(setProjectsList(actionPayload));
        yield put(setFilterProjectLocaleList(filterProjectLocaleList));
    }
}

// tslint:disable-next-line:typedef
export function* watchfetchProjectsList() {
    yield takeLatest(actionTypes.GET_PROJECTS_LIST_FOR_GSC_ACCOUNT_CHECK, fetchProjectsListForGSCAccountCheck);
}

// tslint:disable-next-line:typedef
export function* watchFetchTargetDomainFilterDetails() {
    yield takeLatest(actionTypes.GET_TARGET_DOMAIN_FILTERS_LIST, fetchTargetDomainFilterDetails);
}

// tslint:disable-next-line:typedef
export function* watchFetchReportToken() {
    yield takeLatest(actionTypes.GET_REPORT_TOKEN, fetchReportToken);
}

// tslint:disable-next-line:typedef
export function* watchFetchDefaultProjectCategory() {
    yield takeLatest(actionTypes.GET_PRT_DEAFULT_PROJECT_CATEGORY, fetchDefaultProjectCategory);
}

// tslint:disable-next-line:typedef
export function* watchFetchSOVTargetCompetitorDomains() {
    yield takeLatest(actionTypes.GET_PRT_SOV_TARGET_COMPETITOR_DOMAINS, fetchSOVTargetCompetitorDomains);
}

// tslint:disable-next-line:typedef
export function* watchFetchDefaultProjectTADomain() {
    yield takeLatest(actionTypes.GET_PRT_DEAFULT_PROJECT_TA_DOMAIN, fetchDefaultProjectTADomain);
}

// tslint:disable-next-line:typedef
export function* watchFetchProjectList() {
    yield takeLatest(actionTypes.GET_PROJECTS_LIST, fetchProjectsList);
}

// tslint:disable-next-line:typedef
export function* watchCallRefreshProjectCache() {
    yield takeLatest(actionTypes.REFRESH_PROJECT_CACHE, callRefreshProjectCache);
}
