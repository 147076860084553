import { handleActions } from "redux-actions";
import { AnyAction } from "redux";

import actionTypes from "./actions";
import { PerformanceImpactReducerInterface } from "./types";

const INIT_STATE: PerformanceImpactReducerInterface = {
    reportsList: [],
    newReportFormData: {
        page: "",
        locale: { label: "us", language: "en", name: "English/United States (EN-US)", value: "en-us" },
        primary_keyword: "",
        secondary_keywords: ""
    },
    errorMessage: "",
    reportErrorMessage: "",
    isAddReportInProgress: false,
    isAddReportSuccess: false,
    reportDetails: {
        isLoading: false,
        data: null,
        error: ""
    },
    reportRankingKeywords: {
        isLoading: false,
        data: null,
        error: ""
    },
    reportGSCKeywords: {
        isLoading: false,
        data: null,
        error: ""
    },
    reportGSCSummary: {
        isLoading: false,
        data: null,
        error: ""
    }
};

const Reducer = handleActions(
    {
        [actionTypes.SET_PERFORMANCE_IMPACT_REPORTS_LIST]: (state, action: AnyAction) => ({
            ...state,
            reportsList: action.payload
        }),
        [actionTypes.APPEND_TO_PERFORMANCE_IMPACT_REPORTS_LIST]: (state, action: AnyAction) => ({
            ...state,
            reportsList: [action.payload, ...state.reportsList]
        }),
        [actionTypes.SET_NEW_REPORT_FORM_DATA]: (state, action: AnyAction) => ({
            ...state,
            newReportFormData: {
                ...state.newReportFormData,
                [action.payload.name]: action.payload.value
            }
        }),
        [actionTypes.CLEAR_NEW_REPORT_FORM_DATA]: (state, action: AnyAction) => ({
            ...state,
            newReportFormData: {
                page: "",
                locale: { label: "us", language: "en", name: "English/United States (EN-US)", value: "en-us" },
                primary_keyword: "",
                secondary_keywords: ""
            }
        }),
        [actionTypes.SET_ERROR_MESSAGE]: (state, action: AnyAction) => ({
            ...state,
            errorMessage: action.payload
        }),
        [actionTypes.SET_REPORT_ERROR_MESSAGE]: (state, action: AnyAction) => ({
            ...state,
            reportErrorMessage: action.payload
        }),
        [actionTypes.SET_IS_ADD_REPORT_IN_PROGRESS]: (state, action: AnyAction) => ({
            ...state,
            isAddReportInProgress: action.payload
        }),
        [actionTypes.SET_IS_ADD_REPORT_SUCCESS]: (state, action: AnyAction) => ({
            ...state,
            isAddReportSuccess: action.payload
        }),
        [actionTypes.SET_PERFORMANCE_IMPACT_REPORT_DETAILS]: (state, action: AnyAction) => ({
            ...state,
            reportDetails: action.payload
        }),
        [actionTypes.SET_PERFORMANCE_IMPACT_REPORT_RANKING_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            reportRankingKeywords: action.payload
        }),
        [actionTypes.SET_PERFORMANCE_IMPACT_REPORT_GSC_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            reportGSCKeywords: action.payload
        }),
        [actionTypes.SET_PERFORMANCE_IMPACT_REPORT_GSC_SUMMARY]: (state, action: AnyAction) => ({
            ...state,
            reportGSCSummary: action.payload
        }),
        [actionTypes.CLEAR_PERFORMANCE_IMPACT_REPORT_DATA]: (state, action: AnyAction) => ({
            ...state,
            reportDetails: INIT_STATE.reportDetails,
            reportRankingKeywords: INIT_STATE.reportRankingKeywords,
            reportGSCSummary: INIT_STATE.reportGSCSummary,
            reportGSCKeywords: INIT_STATE.reportGSCKeywords,
        }),
    },
    INIT_STATE
);

export default Reducer;
