import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */
const ActionTypes = {
    SET_ENABLE_SIMULATE_PERFORMANCE: "SET_ENABLE_SIMULATE_PERFORMANCE_BETA",
    SET_ENABLE_SIMULATE_SCORE: "SET_ENABLE_SIMULATE_SCORE_BETA",
    SET_KEYWORD_REQUEST_IDS: "SET_KEYWORD_REQUEST_IDS_BETA",
    SET_SELECTED_KEYWORDS: "SET_SELECTED_KEYWORDS_BETA",
    SET_IS_SUBMITTED: "SET_IS_SUBMITTED_BETA",
    SET_SHOW_LOADER: "SET_SHOW_LOADER_BETA",
    SET_HIDE_LOADER: "SET_HIDE_LOADER_BETA",
    SET_KEYWORD_PROCESSED_INFO: "SET_KEYWORD_PROCESSED_INFO_BETA",
    CALL_TO_GET_MULTI_KEYWORD_REQUEST_IDS_API: "CALL_TO_GET_MULTI_KEYWORD_REQUEST_IDS_API_BETA",
    CALL_TO_GET_MULTI_KEYWORD_TRACK_REQUEST_API: "CALL_TO_GET_MULTI_KEYWORD_TRACK_REQUEST_API_BETA",
    CALL_TO_GET_THEME_KEYWORD_FROM_PROJECT: "CALL_TO_GET_THEME_KEYWORD_FROM_PROJECT_BETA",
    SET_THEME_KEYWORD_FROM_PROJECT: "SET_THEME_KEYWORD_FROM_PROJECT_BETA",
    IS_THEME_KEYWORD_FROM_PROJECT_LOADING: "IS_THEME_KEYWORD_FROM_PROJECT_LOADING_BETA",
    SET_TO_SHOW_PROJECT: "SET_TO_SHOW_PROJECT_BETA",
    RESET_MKW: "RESET_MKW_BETA",
    APPLIED_FILETRS_ON_KEYWORD: "APPLIED_FILETRS_ON_KEYWORD_BETA",
    SET_PARTIAL_KEYWORD_FETCHED: "SET_PARTIAL_KEYWORD_FETCHED_BETA",
    RESET_PARTIAL_KEYWORD_FETCHED: "RESET_PARTIAL_KEYWORD_FETCHED_BETA",
    UPDATE_PROCESSED_KEYWORDS: "UPDATE_PROCESSED_KEYWORDS_BETA",
    SELECTED_MULTI_KEYWORD_LOCALE: "SELECTED_MULTI_KEYWORD_LOCALE_BETA",
    SET_KEYWORD_URL_BOX_EXPANDED_MODE: "SET_KEYWORD_URL_BOX_EXPANDED_MODE_BETA",
    SET_IS_URL_LIVE_IN_MKW: "SET_IS_URL_LIVE_IN_MKW_BETA",
    SET_FILE_NAME_MKW: "SET_FILE_NAME_MKW_BETA",
    SET_FILE_UPLOAD_REQUEST_ID_MKW: "SET_FILE_UPLOAD_REQUEST_ID_MKW_BETA",
    CALL_FILE_UPLOAD_MKW: "CALL_FILE_UPLOAD_MKW_BETA",
    CLEAR_FILE: "CLEAR_FILE_BETA",
    UPDATE_PROCESSED_KEYWORDS_ON_VERSION_CHANGE: "UPDATE_PROCESSED_KEYWORDS_ON_VERSION_CHANGE_BETA",
    UPDATE_KEYWORD_DETAILS_BASED_ON_VERSION: "UPDATE_KEYWORD_DETAILS_BASED_ON_VERSION_BETA",
    CLEAR_KEYWORD_DETAILS_FROM_OLD_VERSION: "CLEAR_KEYWORD_DETAILS_FROM_OLD_VERSION_BETA",
    SET_CURRENT_PAGINATION_KEYWORDS: "SET_CURRENT_PAGINATION_KEYWORDS_BETA",
    SET_MULTI_KEYWORD_SECTION_TYPE: "SET_MULTI_KEYWORD_SECTION_TYPE_BETA",
    CALL_TO_GET_RELATED_KEYWORD_REQUEST_IDS_API: "CALL_TO_GET_RELATED_KEYWORD_REQUEST_IDS_API_BETA",
    CALL_RELATED_KEYWORDS_SERVICE: "CALL_TO_GET_RELATED_KEYWORD_REQUEST_API_STATUS_BETA",
    CALL_RELATED_KEYWORDS_SERVICE_VIA_KEYWORD: "CALL_RELATED_KEYWORDS_SERVICE_VIA_KEYWORD",
    CALL_RELATED_KEYWORDS_SERVICE_FOR_NON_LIVE: "CALL_TO_GET_RELATED_KEYWORD_NON_LIVE_REQUEST_API_STATUS_BETA",
    SET_RELATED_KEYWORDS: "SET_RELATED_KEYWORDS_BETA",
    SET_RELATED_NON_LIVE_KEYWORDS: "SET_RELATED_NON_LIVE_KEYWORDS_BETA",
    SET_RELATED_KEYWORDS_FETCHING: "SET_RELATED_KEYWORDS_FETCHING",
    SET_RELATED_KEYWORDS_FETCHING_URL_PARAM: "SET_RELATED_KEYWORDS_FETCHING_URL_PARAM",
    SET_RELATED_KEYWORDS_FETCHING_KEYWORD_PARAM: "SET_RELATED_KEYWORDS_FETCHING_KEYWORD_PARAM",
    SET_RELATED_KEYWORDS_NON_LIVE_FETCHING: "SET_RELATED_KEYWORDS_NON_LIVE_FETCHING_BETA",
    SET_SSE_RETRIED_ATTEMPT: "SET_SSE_RETRIED_ATTEMPT_BETA",
    SET_SSE_RETRIED_ATTEMPT_URL_PARAM: "SET_SSE_RETRIED_ATTEMPT_URL_PARAM",
    SET_SSE_RETRIED_ATTEMPT_KEYWORD_PARAM: "SET_SSE_RETRIED_ATTEMPT_KEYWORD_PARAM",
    SET_RELATED_KEYWORDS_URL_PARAM: "SET_RELATED_KEYWORDS_URL_PARAM",
    SET_RELATED_KEYWORDS_KEYWORD_PARAM: "SET_RELATED_KEYWORDS_KEYWORD_PARAM",
    SET_RELATED_KEYWORDS_RESPONSE_TYPE: "SET_RELATED_KEYWORDS_RESPONSE_TYPE",
    RESET_SSE_RESPONSE_STATES_AFTER_MERGE: "RESET_SSE_RESPONSE_STATES_AFTER_MERGE",
    CANCEL_ON_GOING_SSE_URL_REQUEST: "CANCEL_ON_GOING_SSE_URL_REQUEST",
    SET_SSE_NON_LIVE_RETRIED_ATTEMPT: "SET_SSE_NON_LIVE_RETRIED_ATTEMPT_BETA",
    SET_RELATED_KEYWORDS_API_STATUS: "SET_RELATED_KEYWORDS_API_STATUS_BETA",
    LIVE_NONLIVE_CHNAGE: "LIVE_NONLIVE_CHNAGE_BETA",
    SET_TOPIC_KEYWORD: "SET_TOPIC_KEYWORD_BETA",
    SET_TOPIC_KEYWORD_NON_LIVE: "SET_TOPIC_KEYWORD_NON_LIVE",
    SET_ENABLE_COMPETITOR_API: "SET_ENABLE_COMPETITOR_API_BETA",
    CALL_TO_GET_COMPETITORS_SCORE_API: "CALL_TO_GET_COMPETITORS_SCORE_API_BETA",
    SET_MKW_DOMAIN_URL: "SET_MKW_DOMAIN_URL_BETA",
    SET_IS_FILE_UPLOADED: "SET_IS_FILE_UPLOADED",
    MKW_TRACK_INITIATED: "MKW_TRACK_INITIATED",

    SET_INCLUDE_KEYWORDS: "SET_INCLUDE_KEYWORDS",
    SET_EXCLUDE_KEYWORDS: "SET_EXCLUDE_KEYWORDS",
    SET_ADVANCE_FILTER_TYPE: "SET_ADVANCE_FILTER_TYPE",
    MULTIKEYWORD_INCLUDE_KEYWORD_TYPE: "MULTIKEYWORD_INCLUDE_KEYWORD_TYPE",
    SET_SHOW_LOADER_BAR: "SET_SHOW_LOADER_BAR",
    SET_IS_PROCEED_BUTTON_CLICKED: " SET_IS_PROCEED_BUTTON_CLICKED",
    SET_IS_TRACK_REQUEST_START: "SET_IS_TRACK_REQUEST_START",
    MARK_STATUS_FOR_TOP_3_COMP_DATA_PROCESSING_FOR_ALL_KWS: "MARK_STATUS_FOR_TOP_3_COMP_DATA_PROCESSING_FOR_ALL_KWS",
    SET_PAGE_CRAWLED_STATUS: "SET_PAGE_CRAWLED_STATUS",
    SET_SSERETRIEDATTEMPTKWPARAM: "SET_SSERETRIEDATTEMPTKWPARAM"
};
export default ActionTypes;

// GETTERS / API CALL ACTIONS

export const callToGetMultiKeywordRequestIdsApi = createAction(ActionTypes.CALL_TO_GET_MULTI_KEYWORD_REQUEST_IDS_API);
export const callToGetMultiKeywordTrackRequestStatusApi = createAction(ActionTypes.CALL_TO_GET_MULTI_KEYWORD_TRACK_REQUEST_API);

// SETTERS / REDUX STORE ACTIONS

export const setEnableSimulatePerformance = createAction(ActionTypes.SET_ENABLE_SIMULATE_PERFORMANCE);
export const setEnableSimulateScore = createAction(ActionTypes.SET_ENABLE_SIMULATE_SCORE);
export const setKeywordRequestIds = createAction(ActionTypes.SET_KEYWORD_REQUEST_IDS);
export const setSelectedKeywords = createAction(ActionTypes.SET_SELECTED_KEYWORDS);
export const setIsSubmitted = createAction(ActionTypes.SET_IS_SUBMITTED);
export const setShowLoader = createAction(ActionTypes.SET_SHOW_LOADER);
export const setHideLoader = createAction(ActionTypes.SET_HIDE_LOADER);
export const setKeywordProcessedInfo = createAction(ActionTypes.SET_KEYWORD_PROCESSED_INFO);
export const callToGetThemeKeywordFromProject = createAction(ActionTypes.CALL_TO_GET_THEME_KEYWORD_FROM_PROJECT);
export const setThemeKeywordFromProject = createAction(ActionTypes.SET_THEME_KEYWORD_FROM_PROJECT);
export const isThemeKeywordFromProjectLoading = createAction(ActionTypes.IS_THEME_KEYWORD_FROM_PROJECT_LOADING);
export const setToShowProject = createAction(ActionTypes.SET_TO_SHOW_PROJECT);
export const resetMultiKeyword = createAction(ActionTypes.RESET_MKW);
export const appliedFiltesOnKeyword = createAction(ActionTypes.APPLIED_FILETRS_ON_KEYWORD);
export const setPartialKeywordFetched = createAction(ActionTypes.SET_PARTIAL_KEYWORD_FETCHED);
export const reSetPartialKeywordFetched = createAction(ActionTypes.RESET_PARTIAL_KEYWORD_FETCHED);
export const updateProcessedKeywords = createAction(ActionTypes.UPDATE_PROCESSED_KEYWORDS);
export const selectedMultiKeywordLocale = createAction(ActionTypes.SELECTED_MULTI_KEYWORD_LOCALE);
export const setKeywordUrlBoxExpandedMode = createAction(ActionTypes.SET_KEYWORD_URL_BOX_EXPANDED_MODE);
export const setIsUrlLiveInMkw = createAction(ActionTypes.SET_IS_URL_LIVE_IN_MKW);
export const callFileuploadMkw = createAction(ActionTypes.CALL_FILE_UPLOAD_MKW);
export const setFileNameMkw = createAction(ActionTypes.SET_FILE_NAME_MKW);
export const setFileuploadRequestIdMkw = createAction(ActionTypes.SET_FILE_UPLOAD_REQUEST_ID_MKW);
export const setClearFile = createAction(ActionTypes.CLEAR_FILE);
export const updateProcessedKeywordsOnVersionChange = createAction(ActionTypes.UPDATE_PROCESSED_KEYWORDS_ON_VERSION_CHANGE);
export const updateKeywordDetailsBasedOnVersion = createAction(ActionTypes.UPDATE_KEYWORD_DETAILS_BASED_ON_VERSION);
export const clearKeywordDetailsFromOldVersion = createAction(ActionTypes.CLEAR_KEYWORD_DETAILS_FROM_OLD_VERSION);
export const setCurrentPaginationKeywords = createAction(ActionTypes.SET_CURRENT_PAGINATION_KEYWORDS);
export const setMultiKeywordSectionType = createAction(ActionTypes.SET_MULTI_KEYWORD_SECTION_TYPE);
export const callToGetRelatedKeywordRequestIdsApi = createAction(ActionTypes.CALL_TO_GET_RELATED_KEYWORD_REQUEST_IDS_API);
export const setRelatedKeywords = createAction(ActionTypes.SET_RELATED_KEYWORDS);
export const setRelatedNonLiveKeywords = createAction(ActionTypes.SET_RELATED_NON_LIVE_KEYWORDS);
export const callRelatedKeywordsServiceSSE = createAction(ActionTypes.CALL_RELATED_KEYWORDS_SERVICE);
export const callRelatedKeywordsServiceSSEViaKeywordParam = createAction(ActionTypes.CALL_RELATED_KEYWORDS_SERVICE_VIA_KEYWORD);
export const callRelatedKeywordsServiceNonLiveSSE = createAction(ActionTypes.CALL_RELATED_KEYWORDS_SERVICE_FOR_NON_LIVE);
export const setRelatedKeywordsApiStatus = createAction(ActionTypes.SET_RELATED_KEYWORDS_API_STATUS);
export const setRelatedKeywordsFetching = createAction(ActionTypes.SET_RELATED_KEYWORDS_FETCHING);
export const setRelatedKeywordsFetchingURLParam = createAction(ActionTypes.SET_RELATED_KEYWORDS_FETCHING_URL_PARAM);
export const setRelatedKeywordsFetchingViaKeywordParam = createAction(ActionTypes.SET_RELATED_KEYWORDS_FETCHING_KEYWORD_PARAM);
export const setRelatedKeywordsNonLiveFetching = createAction(ActionTypes.SET_RELATED_KEYWORDS_NON_LIVE_FETCHING);
export const setSSeRetriedAttempt = createAction(ActionTypes.SET_SSE_RETRIED_ATTEMPT);
export const setSSeRetriedAttemptForURLParam = createAction(ActionTypes.SET_SSE_RETRIED_ATTEMPT_URL_PARAM);
export const setSSeRetriedAttemptForKeywordParam = createAction(ActionTypes.SET_SSE_RETRIED_ATTEMPT_KEYWORD_PARAM);
export const setRelatedKeywordsURLParam = createAction(ActionTypes.SET_RELATED_KEYWORDS_URL_PARAM);
export const setRelatedKeywordsKwParam = createAction(ActionTypes.SET_RELATED_KEYWORDS_KEYWORD_PARAM);
export const setRelatedKeywordsResponseType = createAction(ActionTypes.SET_RELATED_KEYWORDS_RESPONSE_TYPE);
export const resetSSEResponseStatesAfterMerge = createAction(ActionTypes.RESET_SSE_RESPONSE_STATES_AFTER_MERGE);
export const cancelOnGoingSSEURLRequest = createAction(ActionTypes.CANCEL_ON_GOING_SSE_URL_REQUEST);
export const setSSeNonLiveRetriedAttempt = createAction(ActionTypes.SET_SSE_NON_LIVE_RETRIED_ATTEMPT);
export const callLiveNonLiveChange = createAction(ActionTypes.LIVE_NONLIVE_CHNAGE);
export const setIsFileUploaded = createAction(ActionTypes.SET_IS_FILE_UPLOADED);
export const setTopicKeyword = createAction(ActionTypes.SET_TOPIC_KEYWORD);
export const setTopicKeywordNonLive = createAction(ActionTypes.SET_TOPIC_KEYWORD_NON_LIVE);
export const setEnableCompetitorApi = createAction(ActionTypes.SET_ENABLE_COMPETITOR_API);
export const callToGetCompetitorsScoreApi = createAction(ActionTypes.CALL_TO_GET_COMPETITORS_SCORE_API);
export const setMkwDomainUrl = createAction(ActionTypes.SET_MKW_DOMAIN_URL);
export const setMkwTrackInitiated = createAction(ActionTypes.MKW_TRACK_INITIATED);

export const setIncludeKeywordType = createAction(ActionTypes.MULTIKEYWORD_INCLUDE_KEYWORD_TYPE);
export const setIncludeKeywords = createAction(ActionTypes.SET_INCLUDE_KEYWORDS);
export const setExcludeKeywords = createAction(ActionTypes.SET_EXCLUDE_KEYWORDS);
export const setAdvanceFilterType = createAction(ActionTypes.SET_ADVANCE_FILTER_TYPE);
export const setShowLoaderBar = createAction(ActionTypes.SET_SHOW_LOADER_BAR);
export const setIsProceedButtonClicked = createAction(ActionTypes.SET_IS_PROCEED_BUTTON_CLICKED);
export const setIsTrackRequestStart = createAction(ActionTypes.SET_IS_TRACK_REQUEST_START);
export const markTop3CompDataProcessingStatusForAllKws = createAction(ActionTypes.MARK_STATUS_FOR_TOP_3_COMP_DATA_PROCESSING_FOR_ALL_KWS);
export const setPageCrawledStatus = createAction(ActionTypes.SET_PAGE_CRAWLED_STATUS);
export const setSseRetriedAttemptKwParam = createAction(ActionTypes.SET_SSERETRIEDATTEMPTKWPARAM);
