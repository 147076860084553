import { createAction } from "redux-actions";
export const ActionType = {
    // EMAIL-VERIFICATION ACTION TYPES
    CALL_TO_VERIFY_USER_EMAIL: "CALL_TO_VERIFY_USER_EMAIL",
    SET_USER_EMAIL_VERIFIED: "SET_USER_EMAIL_VERIFIED",
    CALL_TO_REVERIFY_EMAIL: "CALL_TO_REVERIFY_EMAIL",
    SET_USER_EMAIL_ID: "SET_USER_EMAIL_ID"
};
export default ActionType;

// EMAIL-VERIFICATION ACTIONS

export const callToVerifyUserEmail = createAction(ActionType.CALL_TO_VERIFY_USER_EMAIL);
export const setUserEmailVerified = createAction(ActionType.SET_USER_EMAIL_VERIFIED);
export const callToReverifyEmail = createAction(ActionType.CALL_TO_REVERIFY_EMAIL);
export const setUserEmailID = createAction(ActionType.SET_USER_EMAIL_ID);
