import { call, put, takeEvery, select } from "redux-saga/effects";
import { AnyAction } from "redux";
import { isUndefined } from "lodash";
import { getFeatureAddOns } from "../../../app/duck/selector";
import Apis from "./apis";
import { getContext } from "../../../app/duck/context-container";
import { formatOnPageElements, getModifiedPageContent, getUpdatedPageContent } from "../../simulator/ducks/utils";
import {
    resetTechAuthChangedParams,
    setAIGeneratedPageContent,
    setFirstTimeRenderingFlag,
    setFormattedOnPageElements,
    setOnPageElements
} from "../../simulator/ducks/actions";
import { ContentOutlineAPIStatus } from "../components/NewSimulation/ducks/types";
import { DEFAULT_EDITOR_CONTENT_BETA } from "../../../app/const";
import ActionTypes, { markAIFetchContentComplete, saveSimulationHistory, setInitialGeneratedContentOutlineAPIStatus,
    setPaginationHideLoader, setPaginationShowLoader, setRegenerateContentOutlineAPIStatus } from "./actions";
import { setHideLoader } from "../../../app/duck/actions";
import { GetSaveHistoryPayloadInterface } from "../../../routes/ducks/types";

/* Function call generate Content Outline for the first time */
// tslint:disable-next-line:typedef
function* fetchInitialAIGeneratedContentOutline(action: AnyAction) {
    const { tenantCode } = getContext();
    const { keywords, url } = action.payload;
    const parser = {
        tenantCode: tenantCode as string
    };
    const fetchAIGeneratedContentPayload = {
        keywords,
        user_prompt: action.payload.user_prompt
    };
    const aiGeneratedContent = yield call(Apis.fetchAIGeneratedContent, fetchAIGeneratedContentPayload, parser);
    const formattedOnPageElements = getModifiedPageContent(formatOnPageElements(aiGeneratedContent.data), url);
    yield put(setFormattedOnPageElements(formattedOnPageElements));
    yield put(setAIGeneratedPageContent(getUpdatedPageContent(formattedOnPageElements)));
    const defaultValuesForViewOriginalEditor = getModifiedPageContent(formatOnPageElements(DEFAULT_EDITOR_CONTENT_BETA), url);
    yield put(setOnPageElements(getUpdatedPageContent(defaultValuesForViewOriginalEditor)));
    yield put(setFirstTimeRenderingFlag(true));
    yield put(resetTechAuthChangedParams());
    if (aiGeneratedContent.status === 200) {
        yield put(setInitialGeneratedContentOutlineAPIStatus(ContentOutlineAPIStatus.SUCCESS));
        yield put(markAIFetchContentComplete());
    } else {
        yield put(setInitialGeneratedContentOutlineAPIStatus(ContentOutlineAPIStatus.FAILED));
    }
}

// tslint:disable-next-line:typedef
export function* fetchSavesimulationHistory(action: AnyAction) {
    const { tenantCode = "" } = getContext();
    const featureAddons = yield select(getFeatureAddOns);
    const hasContentOutlineFeatureAccess = featureAddons.includes("create-new-content");
    const payload: GetSaveHistoryPayloadInterface = {
        tenantCode,
        hasContentOutlineFeatureAccess: hasContentOutlineFeatureAccess ? "1" : "0",
        tenantId: action.payload.tenantId,
        projectId: action.payload.projectId,
        pageIndex: action.payload.pageIndex,
        isBeta: true,
        ...(!isUndefined(action.payload.searchTerm) && { searchTerm: action.payload.searchTerm })
    };
    yield put(setPaginationShowLoader());
    const response = yield call(Apis.getSaveSimulationHistory, payload);
    if (response.status === 200) {
        yield put(setPaginationHideLoader());
        yield put(saveSimulationHistory(response.data));
    } else {
        yield put(
            saveSimulationHistory({
                count: 0,
                prev_page: null,
                curr_page: 1,
                next_page: null,
                total_pages: 0,
                sim_list: []
            })
        );
        yield put(setPaginationHideLoader());
        yield put(setHideLoader());
    }
    return response;
}


/* Function call to regenerate Content Outline */
// tslint:disable-next-line:typedef
function* fetchRegeneratedAIContentOutline(action: AnyAction) {
    const { tenantCode } = getContext();
    const { keywords, url } = action.payload;
    const parser = {
        tenantCode: tenantCode as string
    };
    const fetchAIGeneratedContentPayload = {
        keywords,
        user_prompt: action.payload.user_prompt
    };
    const aiGeneratedContent = yield call(Apis.fetchAIGeneratedContent, fetchAIGeneratedContentPayload, parser);
    const formattedOnPageElements = getModifiedPageContent(formatOnPageElements(aiGeneratedContent.data), url);
    yield put(setFormattedOnPageElements(formattedOnPageElements));
    yield put(setAIGeneratedPageContent(getUpdatedPageContent(formattedOnPageElements)));
    if (aiGeneratedContent.status === 200) {
        yield put(setRegenerateContentOutlineAPIStatus(ContentOutlineAPIStatus.SUCCESS));
        yield put(markAIFetchContentComplete());
    } else {
        yield put(setRegenerateContentOutlineAPIStatus(ContentOutlineAPIStatus.FAILED));
    }
}


// tslint:disable-next-line:typedef
export function* watchForInitialGeneratedContentOutlineCall() {
    yield takeEvery(ActionTypes.FETCH_INITIAL_AI_GENERATED_CONTENT_OUTLINE, fetchInitialAIGeneratedContentOutline);
}

// tslint:disable-next-line:typedef
export function* watchForReGeneratedContentOutlineCall() {
    yield takeEvery(ActionTypes.FETCH_REGENERATED_AI_CONTENT_OUTLINE, fetchRegeneratedAIContentOutline);
}

// tslint:disable-next-line: typedef
export function* watchGetsaveSimulationHistory() {
    yield takeEvery(ActionTypes.GET_SIMULATION_HISTORY, fetchSavesimulationHistory);
}

