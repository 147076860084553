import { call, put, takeLatest, cancelled, take, delay, race } from "redux-saga/effects";
import { AnyAction } from "redux";
import actionTypes, { callContextualRecommendation, setContextualRecommendation, setContextualRecommendationApiFail } from "./actions";
import { getContext } from "../../../../../../../../../app/duck/context-container";
import ContextualRecommendationsApis from "./apis";
import { ApiConfig } from "../../../../../../../../../app/config/apiConfig";
import { ApiPath } from "../../../../../../../../../app/config/apiPath";
import { CONTEXT_RECOMMENDATION_SSE_EVENT_TYPES } from "./const";
import { listenRecommendationsSSEvents } from "./utils";

// tslint:disable-next-line: typedef
export function* callToGetContextualRecommendationInitiate(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(ContextualRecommendationsApis.getContextualRecommendationInitiate, action.payload, parser);
    if (response.status === 200) {
        const parameter = {
            requestId: response.data.ref_id,
            token: response.data.token
        };

        yield put(callContextualRecommendation(parameter));
    }
    return response;
}

// tslint:disable-next-line:typedef
export function* callToGetContextualRecommendationList(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const { requestId, token } = action.payload;
    const url = `${ApiConfig.baseURL}/${ApiPath.CONTEXTUAL_RECOMMENDATION.replace(
        "{{tenantCode}}",
        parser.tenantCode
    )}?ref_id=${requestId}&token=${token}`;

    const { channel, timeout } = yield race({
        channel: call(listenRecommendationsSSEvents, url),
        timeout: delay(600 * 1000)
    });
    if (timeout) {
        channel.close();
        yield put(setContextualRecommendationApiFail(true));
    }
    try {
        while (true) {
            try {
                const response = yield take(channel);
                let responseData = null;
                switch (response.type) {
                    case CONTEXT_RECOMMENDATION_SSE_EVENT_TYPES.receive_recommendations:
                        responseData = JSON.parse(response.data);
                        yield put(setContextualRecommendation(responseData.recommendations));
                        break;
                    case CONTEXT_RECOMMENDATION_SSE_EVENT_TYPES.receive_recommendation_score:
                        responseData = JSON.parse(response.data);
                        yield put(setContextualRecommendation(responseData.recommendations));
                        break;
                    case CONTEXT_RECOMMENDATION_SSE_EVENT_TYPES.req_completed:
                        channel.close();
                        yield put(setContextualRecommendationApiFail(false));
                        break;
                    case CONTEXT_RECOMMENDATION_SSE_EVENT_TYPES.req_failed:
                        channel.close();
                        yield put(setContextualRecommendationApiFail(true));
                        break;
                }
            } catch (error) {
                channel.close();
                yield put(setContextualRecommendationApiFail(true));
            }
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
        }
    }
}

// tslint:disable-next-line:typedef
export function* watchGetContextualRecommendationInitiate() {
    yield takeLatest(actionTypes.CALL_CONTEXTUAL_RECOMMENDATION_INITIATE, callToGetContextualRecommendationInitiate);
}

// tslint:disable-next-line:typedef
export function* watchGetContextualRecommendationSSE() {
    yield takeLatest(actionTypes.CALL_CONTEXTUAL_RECOMMENDATION, callToGetContextualRecommendationList);
}
