import { createAction } from "redux-actions";
export const ActionType = {
    // CONTEXT ACTION TYPES
    CALL_FOR_SET_CONTEXT: "CALL_FOR_SET_CONTEXT",
    CALL_TO_CLEAR_APP: "CALL_TO_CLEAR_APP",
    CALL_TO_GET_REQUEST_ID_APP: "CALL_TO_GET_REQUEST_ID_APP",
    CALL_TO_GET_LOCALES_LIST_APP: "CALL_TO_GET_LOCALES_LIST_APP",
    SET_DOMAIN_IN_CONTEXT: "SET_DOMAIN_IN_CONTEXT",
    SET_KEYWORD_IN_CONTEXT: "SET_KEYWORD_IN_CONTEXT",
    SET_LOCALES_LIST_IN_APP: "SET_LOCALES_LIST_IN_APP",
    SET_LOCALE_IN_CONTEXT: "SET_LOCALE",
    SET_PROJECT_ID_IN_CONTEXT: "SET_PROJECT_ID",
    SET_PROJECT_TYPE_IN_CONTEXT: "SET_PROJECT_TYPE_IN_CONTEXT",
    SET_PROJECT_NAME_IN_CONTEXT: "SET_PROJECT_NAME_IN_CONTEXT",
    SET_REQUEST_ID_IN_APP: "SET_REQUEST_ID_IN_APP",
    SET_SERP_DATA_IN_APP: "SET_SERP_DATA_IN_APP",
    SET_SERP_DETAILS_IN_APP: "SET_SERP_DETAILS_IN_APP",
    SET_SERP_LIST_WHEN_TRACK_STOP: "SET_SERP_LIST_WHEN_TRACK_STOP",
    SET_TENANT_CODE_IN_CONTEXT: "SET_TENANT_CODE",

    SET_USER_ID_IN_CONTEXT: "SET_USER_ID",
    SET_USER_NAME_IN_CONTEXT: "SET_USER_NAME",

    UNSET_DOMAIN_FROM_CONTEXT: "UNSET_DOMAIN_IN_CONTEXT",
    UNSET_KEYWORD_FROM_CONTEXT: "UNSET_KEYWORD_IN_CONTEXT",
    SET_FEATURE_ADDONS_IN_CONTEXT: "SET_FEATURE_ADDONS_IN_CONTEXT",
    UNSET_FEATURE_ADDONS_IN_CONTEXT: "UNSET_FEATURE_ADDONS_IN_CONTEXT",
    UNSET_LOCALE_FROM_CONTEXT: "UNSET_LOCALE",
    UNSET_PROJECT_ID_FROM_CONTEXT: "UNSET_PROJECT_ID",
    UNSET_TENANT_CODE_FROM_CONTEXT: "UNSET_TENANT_CODE",
    UNSET_USER_ID_FROM_CONTEXT: "UNSET_USER_ID",
    UNSET_USER_NAME_FROM_CONTEXT: "UNSET_USER_NAME",
    UPDATE_SERP_LIST_IN_APP: "UPDATE_SERP_LIST_IN_APP",
    SET_SELECTED_TARGET_URL: "SET_SELECTED_TARGET_URL",
    SET_FLOWTYPE: "SET_FLOWTYPE",
    SET_SHOW_LOADER: "SET_SHOW_LOADER",
    SET_HIDE_LOADER: "SET_HIDE_LOADER",
    SET_IS_URL_LIVE: "SET_IS_URL_LIVE",
    SET_FILE_UPLOAD_REQUEST_ID: "SET_FILE_UPLOAD_REQUEST_ID",
    SET_FILE_NAME: "SET_FILE_NAME",
    SET_SELECTED_SID: "SET_SELECTED_SID",
    SET_ALL_COMPETITORS_STATUS: "SET_ALL_COMPETITORS_STATUS",
    UNSET_SELECTED_SID: "UNSET_SELECTED_SID",
    SERP_PROGRESS_STATUS_FROM_HISTORY_TABLE: "SERP_PROGRESS_STATUS_FROM_HISTORY_TABLE",
    SET_PROJECT_DOMAIN_URL: "SET_PROJECT_DOMAIN_URL",
    SET_UNIQUE_DOMAIN_URL: "SET_UNIQUE_DOMAIN_URL",
    CALL_TO_GET_ALL_PROJECTS_LIST: "CALL_TO_GET_ALL_PROJECTS_LIST",
    SET_PROJECTS_LIST_IN_CONTEXT: "SET_PROJECTS_LIST_IN_CONTEXT",
    SET_DOMAIN_AND_KEYWORDS_LIMIT_IN_CONTEXT: "SET_DOMAIN_AND_KEYWORDS_LIMIT_IN_CONTEXT",
    IS_ENABLED_RUN_SIMULATION: "IS_ENABLED_RUN_SIMULATION",
    SET_INSIGHT_OPEN_MODAL_STATUS: "SET_INSIGHT_OPEN_MODAL_STATUS"
};
export default ActionType;

// CONTEXT ACTIONS
export const callSetContext = createAction(ActionType.CALL_FOR_SET_CONTEXT);
export const setUserId = createAction(ActionType.SET_USER_ID_IN_CONTEXT);
export const unsetUserId = createAction(ActionType.UNSET_USER_ID_FROM_CONTEXT);
export const setTenantCode = createAction(ActionType.SET_TENANT_CODE_IN_CONTEXT);
export const setUserName = createAction(ActionType.SET_USER_NAME_IN_CONTEXT);
export const unsetTenantCode = createAction(ActionType.UNSET_TENANT_CODE_FROM_CONTEXT);
export const unsetUserName = createAction(ActionType.UNSET_USER_NAME_FROM_CONTEXT);
export const setProjectIdInContext = createAction(ActionType.SET_PROJECT_ID_IN_CONTEXT);
export const setProjectNameInContext = createAction(ActionType.SET_PROJECT_NAME_IN_CONTEXT);
export const setProjectTypeInContext = createAction(ActionType.SET_PROJECT_TYPE_IN_CONTEXT);
export const unsetProjectId = createAction(ActionType.UNSET_PROJECT_ID_FROM_CONTEXT);
export const setLocaleInContext = createAction(ActionType.SET_LOCALE_IN_CONTEXT);
export const unsetLocale = createAction(ActionType.UNSET_LOCALE_FROM_CONTEXT);
export const setKeyWordInContext = createAction(ActionType.SET_KEYWORD_IN_CONTEXT);
export const unsetKeyWordInContext = createAction(ActionType.UNSET_KEYWORD_FROM_CONTEXT);
export const setDomainInContext = createAction(ActionType.SET_DOMAIN_IN_CONTEXT);
export const unsetDomainInContext = createAction(ActionType.UNSET_DOMAIN_FROM_CONTEXT);
export const setFeatureAddOnsInContext = createAction(ActionType.SET_FEATURE_ADDONS_IN_CONTEXT);
export const unsetFeatureAddOnsInContext = createAction(ActionType.UNSET_FEATURE_ADDONS_IN_CONTEXT);
export const setDomainAndKeywordsLimitInContext = createAction(ActionType.SET_DOMAIN_AND_KEYWORDS_LIMIT_IN_CONTEXT);

export const callToGetLocalesListApp = createAction(ActionType.CALL_TO_GET_LOCALES_LIST_APP);
export const callToGetAllProjectsList = createAction(ActionType.CALL_TO_GET_ALL_PROJECTS_LIST);

export const callToGetRequestId = createAction(ActionType.CALL_TO_GET_REQUEST_ID_APP);
export const setRequestIdInApp = createAction(ActionType.SET_REQUEST_ID_IN_APP);

export const setSerpDataInApp = createAction(ActionType.SET_SERP_DATA_IN_APP);
export const setSerpDetailsInApp = createAction(ActionType.SET_SERP_DETAILS_IN_APP);

export const udpateSerpListInApp = createAction(ActionType.UPDATE_SERP_LIST_IN_APP);

export const callToClearApp = createAction(ActionType.CALL_TO_CLEAR_APP);
export const setSerpListWhenTrackStop = createAction(ActionType.SET_SERP_LIST_WHEN_TRACK_STOP);

export const setSelectedTargetUrl = createAction(ActionType.SET_SELECTED_TARGET_URL);
export const setFlowType = createAction(ActionType.SET_FLOWTYPE);

export const setShowLoader = createAction(ActionType.SET_SHOW_LOADER);
export const setHideLoader = createAction(ActionType.SET_HIDE_LOADER);
export const setIsUrlLive = createAction(ActionType.SET_IS_URL_LIVE);
export const setFileuploadRequestId = createAction(ActionType.SET_FILE_UPLOAD_REQUEST_ID);
export const setFileName = createAction(ActionType.SET_FILE_NAME);
export const setSelectedSID = createAction(ActionType.SET_SELECTED_SID);
export const unsetSelectedSID = createAction(ActionType.UNSET_SELECTED_SID);
export const setSerpProgressStatusFromHistoryTable = createAction(ActionType.SERP_PROGRESS_STATUS_FROM_HISTORY_TABLE);
export const setAllCompetitorsStatus = createAction(ActionType.SET_ALL_COMPETITORS_STATUS);
export const setProjectDomainUrl = createAction(ActionType.SET_PROJECT_DOMAIN_URL);
export const setUniqueDomainUrl = createAction(ActionType.SET_UNIQUE_DOMAIN_URL);
export const setProjectsListInContext = createAction(ActionType.SET_PROJECTS_LIST_IN_CONTEXT);
export const setIsEnableRunSimulation = createAction(ActionType.IS_ENABLED_RUN_SIMULATION);
export const setInsightOpenModalStatus = createAction(ActionType.SET_INSIGHT_OPEN_MODAL_STATUS);

