import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import actionTypes from "./actions";
import { CompetitiveIntelligenceReducerInterface, ReportFiltersList } from "./types";
import {
    getOptions, getNextTargetFilterProps, getNextProjectsProps
} from "./utils";

export const INIT_STATE: CompetitiveIntelligenceReducerInterface = {
    isLoading: true,
    reportCode: "",
    sectionCode: "",
    filterConfig: {
        filters: [],
        sections: [],
        filterProductLocaleList: {},
        filterProjectLocaleList: {},
    },
    embedConfig: {
        accessToken: "",
        embedUrl: "",
        id: "",
        datasetId: "",
        filters: [],
    },
    defaultSlicers: {
        targetDomain: "",
        projectTADomain: "",
        projectCategory: "",
        sovTargetCompetitorDomains: [""],
    },
    isGSCConfigure: true,
    isProjectDomainsPresent: true,
    isProjectsArrayEmpty: false,
    fetchReportTokenRetriedAttempts: 0,
};

const updateFilterState = (state: CompetitiveIntelligenceReducerInterface, action: AnyAction) => {
    let filters = state.filterConfig.filters.map((filter: ReportFiltersList) => {
        if (action.payload.name === filter.name) {
            return {
                ...filter,
                value: action.payload.selected
            };
        }
        return { ...filter };
    });
    if (action.payload.name === "product") {
        filters = filters.map((filter: ReportFiltersList) => {
            if (filter.name === "locale") {
                const options = getOptions(state.filterConfig.filterProductLocaleList[action.payload.selected.label]);
                const value = options[0];
                return { ...filter, options, value };
            }
            return { ...filter };
        });
    }
    if (["product", "locale"].includes(action.payload.name)) {
        filters = filters.map((filter: ReportFiltersList) => {
            if (filter.name === "device") {
                return { ...filter, value: filter.options[0] };
            }
            return { ...filter };
        });
    }
    if (action.payload.name === "project") {
        filters = filters.map((filter: ReportFiltersList) => {
            if (filter.name === "projectlocale") {
                const options = getOptions([state.filterConfig.filterProjectLocaleList[action.payload.selected.label]]);
                const value = options[0];
                return { ...filter, options, value };
            }
            return { ...filter };
        });
    }
    return {
        ...state,
        filterConfig: {
            ...state.filterConfig,
            filters
        }
    };
};
const Reducer = handleActions({
    [actionTypes.SET_LOADER]: (state, action: AnyAction) => ({
        ...state,
        isLoading: action.payload,
    }),
    [actionTypes.SET_PROJECTS_LIST]: (state, action: AnyAction) => {
        return {
            ...state,
            filterConfig: {
                ...state.filterConfig,
                ...getNextProjectsProps(state.filterConfig?.filters || [], action.payload.projectsArray, state.reportCode, action.payload.tenantCode
                    , state.defaultSlicers.targetDomain)
            }
        };
    },
    [actionTypes.SET_IS_PROJECTS_ARRAY_EMPTY]: (state, action: AnyAction) => ({
        ...state,
        isProjectsArrayEmpty: action.payload
    }),
    [actionTypes.SET_TARGET_DOMAIN_FILTERS_LIST]: (state, action: AnyAction) => {
        return {
            ...state,
            filterConfig: {
                ...state.filterConfig,
                ...getNextTargetFilterProps(state.filterConfig?.filters || [], action.payload.targetDomains, state.reportCode, action.payload.tenantCode,
                    state.defaultSlicers.targetDomain)
            }
        };
    },
    [actionTypes.SET_FILTER_PROJECT_FOR_LOCALE_LIST]: (state, action: AnyAction) => {
        return {
            ...state,
            filterConfig: {
                ...state.filterConfig,
                filterProjectLocaleList: action.payload,
            }
        };
    },
    [actionTypes.SET_IS_PROJECTS_ARRAY_EMPTY]: (state, action: AnyAction) => ({
        ...state,
        isProjectsArrayEmpty: action.payload
    }),
    [actionTypes.UPDATE_FILTER]: (state, action: AnyAction) => updateFilterState(state, action),
    [actionTypes.SET_FILTER_CONFIG]: (state, action: AnyAction) => ({
        ...state,
        filterConfig: {
            ...state.filterConfig,
            ...action.payload
        }
    }),
    [actionTypes.SET_EMBED_CONFIG]: (state, action: AnyAction) => ({
        ...state,
        embedConfig: {
            ...state.embedConfig,
            ...action.payload
        }
    }),
    [actionTypes.SET_REPORT_CODE]: (state, action: AnyAction) => ({
        ...state,
        reportCode: action.payload
    }),
    [actionTypes.GET_REPORT_TOKEN]: (state, action: AnyAction) => ({
        ...state,
        embedConfig: {
            ...INIT_STATE.embedConfig
        }
    }),
    [actionTypes.SET_SECTION_CODE]: (state, action: AnyAction) => ({
        ...state,
        sectionCode: action.payload
    }),
    [actionTypes.CLEAR_COMPETITIVE_INTELLIGENCE]: () => ({
        ...INIT_STATE
    }),
    [actionTypes.SET_PRT_SOV_TARGET_COMPETITOR_DOMAINS]: (state, action: AnyAction) => ({
        ...state,
        defaultSlicers: {
            ...state.defaultSlicers,
            sovTargetCompetitorDomains: action.payload
        }
    }),
    [actionTypes.SET_PRT_DEAFULT_PROJECT_TA_DOMAIN]: (state, action: AnyAction) => ({
        ...state,
        defaultSlicers: {
            ...state.defaultSlicers,
            projectTADomain: action.payload
        }
    }),
    [actionTypes.SET_PRT_DEAFULT_PROJECT_CATEGORY]: (state, action: AnyAction) => ({
        ...state,
        defaultSlicers: {
            ...state.defaultSlicers,
            projectCategory: action.payload
        }
    }),
    [actionTypes.RESET_DEFAULT_SLICERS]: (state, action: AnyAction) => ({
        ...state,
        defaultSlicers: {
            ...INIT_STATE.defaultSlicers
        }
    }),
    [actionTypes.SET_GSC_ACCOUNT_CONFIGURED]: (state, action: AnyAction) => ({
        ...state,
        isGSCConfigure: action.payload
    }),
    [actionTypes.SET_IS_PROJECT_DOMAINS_PRESENT]: (state, action: AnyAction) => ({
        ...state,
        isProjectDomainsPresent: action.payload
    }),
    [actionTypes.SET_FETCH_REPORT_TOKEN_RETRIED_ATTEMPT]: (state, action: AnyAction) => ({
        ...state,
        fetchReportTokenRetriedAttempts: action.payload
    }),
    [actionTypes.RESET_FETCH_REPORT_TOKEN_RETRIED_ATTEMPT]: (state, action: AnyAction) => ({
        ...state,
        fetchReportTokenRetriedAttempts: 0
    }),
}, INIT_STATE);

export default Reducer;
