import { FlowTypeInterface } from "src/app/duck/types";
import { OptionTypeInterface, OptionValueType } from "src/app/styledComponents/drop-down/types";

export interface SuggestedKeywordPropsInterface {
    defaultKeywords: string[];
    topicName?: string;
    selectedLocale?: OptionValueType;
    source?: string;
    saveRelatedKW(keywords: string): void;
}
export interface SuggestedKeywordReducerInterface {
    relatedKeywords: OptionTypeInterface[];
    suggestedKeywords: OptionTypeInterface[];
    sseAPIloading: string | boolean;
    keywordSseRetriedAttempt: number;
    appliedFiltersOnRKeyword: AppliedFilterOnKeywordInterface;
    filteredSearchKeyword?: OptionTypeInterface[];
    clearAllFilters: boolean;
    requestIdAPIStatus: boolean;
    currentTopicOfCB: string;
    searchCBExcludeKeywords: string[];
    searchCBIncludeKeywords: string[];
    filterType: string;
    CBIncludeKeywordType: string;
    isSearchThroughInput: boolean;
}
export interface AccordionHeadingInterface {
    isOpen: boolean;
    flowType?: FlowTypeInterface;
}
export interface RelatedKeywordsInterface {
    keyword: string;
    search_volume: number;
    relevance_score?: number;
    rank?: number;
    url?: string;
}

export enum AppliedFilter {
    SELECTALL = "selectall",
    THEME = "theme",
    BRANDTYPE = "brandtype",
    KEYWORDFILTER = "keywordfilter",
    KEYWORD_ADVANCE_FILTER = "keyword_advance_filter",
    KEYWORDTYPE = "keywordtype",
    SEARCHVOLUME = "searchvolume",
    RELEVANCE_SCORE = "relevancescore",
    RANK = "rank",
    SORTTYPE = "sorttype",
    SEARCH_BY_INPUT = "search_by_input",
    SEARCH_BY_URLS = "search_by_urls",
}

export interface AppliedFilterOnKeywordInterface {
    [AppliedFilter.SELECTALL]: boolean;
    [AppliedFilter.THEME]: number[];
    [AppliedFilter.KEYWORDTYPE]: number[];
    [AppliedFilter.BRANDTYPE]: undefined | string[];
    [AppliedFilter.KEYWORDFILTER]: string | undefined;
    [AppliedFilter.KEYWORD_ADVANCE_FILTER]: string[];
    [AppliedFilter.SEARCHVOLUME]: string[];
    [AppliedFilter.RELEVANCE_SCORE]: string[];
    [AppliedFilter.RANK]: string[];
    [AppliedFilter.SEARCH_BY_URLS]: string[];
    [AppliedFilter.SORTTYPE]: OptionTypeInterface;
}

export interface KeywordFilterInterface {
    type: AppliedFilter;
    searchType?: string | undefined;
    value: string | undefined | number | string[] | number[] | boolean | OptionTypeInterface;
}

export enum NotificationParams {
    DISABLED = "disabled",
    IS_MEX_LIMIT_REACHED = "isMaxLimitReached",
    IS_SSE_FAILED = "isSseFailed",
    IS_NON_LIVE_SSE_FAILED = "isSseNonLiveFailed",
    SSE_FAILED_NOTIFICATION = "sseFailedNotification",
    SSE_RETRIED_THREE_TIMES = "sseRetriedThreeTimes",
    NO_KWS_FOUND_NON_LIVE = "noKwsFoundInNonLive",
    NO_KWS_FOUND_LIVE = "noKwsFoundInLive",
    IS_REL_SCORE_PRESENT_LIVE = "isRelScorePresentLive",
    IS_REL_SCORE_PRESENT_NON_LIVE = "isRelScorePresentNonLive",
    SSE_RETRY_NOTIFICATION = "sseRetryNotification",
    NON_LIVE_DISABLED = "nonLiveDisabled",
    SSE_RETRIED_NON_LIVE_THREE_TIMES = "sseRetriedNonLiveThreeTimes",
    SSE_RETRY_NON_LIVE_NOTIFICATION = "sseRetryNonLiveNotification"
}

export enum AddRemoveSuggestedKeyword {
    ADD = "add",
    REMOVE = "remove"
}

export interface RelatedKWPropsInterface {
    defaultKeywords: string[];
    appliedFiltersOnRKeyword: AppliedFilterOnKeywordInterface;
    relatedKeywords: OptionTypeInterface[];
    suggestedKeywords: OptionTypeInterface[];
    isLoading: string | boolean;
    selectedLocale?: OptionValueType;
    keywordSseRetriedAttempt: number;
    requestIdAPIStatus: boolean;
    isPageReload: boolean;
    onAddKeywordClicked: boolean;
    infotainmentLoaderData?: object;
    selectAllKeywords(keyword: string): void;
    setIsPageReload(keyword: boolean): void;
    onCheckUnCheckKW(keyword: string, isChecked: boolean): void;
}
export interface SuggestedRelatedKWApiRequestInterface {
    keyword: string;
    locale: OptionValueType;
}

export interface NewTopicSearchInterface {
    existingKeyword: string;
    selectedKeywords: string[];
    isLoading: string | boolean;
    instanceId: string;
    flowType?: FlowTypeInterface;
    onAddKeyword(keyword: string): void;
}

