import * as React from "react";
import { Redirect } from "react-router-dom";
import { isNull, isUndefined } from "lodash";
import Header from "./header";

import Breadcrumb from "./breadcrumb";
import HEADER_NAV_LIST from "../ducks/header-nav-list";
import PRIMARY_NAV_LIST from "../ducks/primary-nav-list";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
    HeaderNavListInterface,
    NavLinkType,
    NavListInterface,
    PrimaryNavListInterface,
    PrimaryNavListTabsInterface,
    RoutesReducerInterface
} from "../ducks/types";
import { ApplicationStateInterface, AppReducerInterface, ContextReducerInterface, GetTenantLimitInterface } from "../../app/duck/types";
import { setActiveRouteUrl, setPrevRouteUrl } from "../ducks/actions";
import Tabs from "./tabs";
import { clearUserSelection, getProjectDetails, getTenantLimits, callDeleteProject } from "../../beta/home/ducks/actions";
import { AppMessageContext } from "../../app/styledComponents/general-message/app-message-context";
import { GetProjectDetailsInterface } from "../../beta/home/ducks/types";
import { PAGE_OPTIMIZER_URL, PROJECT_SETUP_DOMAIN_DETAILS_URL, SIMULATOR_URL } from "../../routes/ducks/routes-url-constant";
import RefreshContentConsentAlert from "../../beta/simulator/components/refresh-consent";
import { ALPS_LOCAL_STORAGE_KEY, COOKIE_LFT_USER, COOKIE_SUB_PLAN, COPY } from "../../../src/app/const";
import { getCookie, onLeavePage } from "../../app/duck/utils";
import { projectIcon } from "../../app/icons";
import { setProjectSetupHeaderText } from "../../project-setup/ducks/actions";
import { refreshProjectCache } from "../../competitive-intelligence/ducks/actions";
import { setAlertView } from "../../beta/simulator/ducks/actions";

interface NavigationPropsActionInterface {
    clearUserSelection(): void;
    setPrevRouteUrl(path: string): void;
    getProjectDetails(args: GetProjectDetailsInterface): void;
    getTenantLimits(args: GetTenantLimitInterface): void;
    callDeleteProject(args: number): void;
    setProjectSetupHeaderText(args: string): void;
    refreshProjectCache(): void;
    setAlertView(args: boolean): void;
}

interface NavigationPropsInterface {
    location: {
        pathname: string;
    };
    actions: NavigationPropsActionInterface;
    app: AppReducerInterface;
    routes: RoutesReducerInterface;
    context: ContextReducerInterface;
    isContentEditorDirty: boolean;
    isAuthorityEditorDirty: boolean;
    isTechnicalEditorDirty: boolean;
    selectedVersion?: string;
    headerText?: string;
    PageNotFoundVisible?: boolean;
}

interface NavigationStateInterface {
    breadcrumbList: NavListInterface[];
    headerTabsList: PrimaryNavListTabsInterface;
    activeDropdownId?: string;
    isOpen: boolean;
    onClickBackButton: boolean;
    isRedirect: boolean;
    redirectTo: string;
    isExternalLink?: boolean;
    isHeaderVisible?: boolean;
}

class Navigation extends React.Component<NavigationPropsInterface, NavigationStateInterface> {
    constructor(props: NavigationPropsInterface) {
        super(props);
        this.state = {
            breadcrumbList: [],
            headerTabsList: {
                title: "",
                icon: "",
                navLinks: []
            },
            activeDropdownId: undefined,
            isOpen: false,
            onClickBackButton: false,
            isRedirect: false,
            redirectTo: "",
            isExternalLink: false,
            isHeaderVisible: !(
                window.location.pathname.endsWith("/change-account") ||
                window.location.pathname.endsWith("/logout") ||
                window.location.pathname.endsWith("/changepassword") ||
                window.location.pathname.includes("/resetpassword")
            )
        };
        this.setNavigation = this.setNavigation.bind(this);
        this.initNavigation = this.initNavigation.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.handleDeleteProject = this.handleDeleteProject.bind(this);
    }

    public componentWillMount(): void {
        this.context.reset();
    }

    public componentDidMount(): void {
        this.initNavigation();
        document.addEventListener("click", () => {
            this.setState({ activeDropdownId: undefined });
        });
    }

    public componentWillReceiveProps(nextProps: NavigationPropsInterface): void {
        if (
            (this.props.routes.activeRouteUrl === SIMULATOR_URL &&
                isUndefined(this.props.selectedVersion) &&
                nextProps.isContentEditorDirty) ||
            nextProps.isAuthorityEditorDirty ||
            nextProps.isTechnicalEditorDirty
        ) {
            window.addEventListener("popstate", this.onBackButtonEvent);
            this.setState({ isRedirect: false });
        }
    }

    public componentWillUnmount(): void {
        window.removeEventListener("popstate", this.onBackButtonEvent);
    }

    public toggleDropdown(link: HeaderNavListInterface | PrimaryNavListInterface, event: React.MouseEvent): void {
        this.setState({ activeDropdownId: link.id === this.state.activeDropdownId ? undefined : link.id });
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    }

    public initHeaderScroll(): void {
        let newScrollPosition = 0;
        let lastScrollPosition;
        const scrollableHeaderHeight = 40;
        const tab = document.getElementById("tab");

        if (tab) {
            window.addEventListener("scroll", () => {
                lastScrollPosition = window.scrollY;

                // Scrolling down
                if (newScrollPosition < lastScrollPosition && lastScrollPosition > scrollableHeaderHeight) {
                    tab.classList.remove("tabSlideDown");
                    tab.classList.add("tabSlideUp");

                    // Scrolling up
                } else if (newScrollPosition > lastScrollPosition) {
                    tab.classList.remove("tabSlideUp");
                    tab.classList.add("tabSlideDown");
                }

                newScrollPosition = lastScrollPosition;
            });
        }
    }

    public componentDidUpdate(prevProps: NavigationPropsInterface): void {
        if (
            this.props.location.pathname !== prevProps.location.pathname ||
            this.props.routes.activeRouteUrl !== prevProps.routes.activeRouteUrl
        ) {
            this.setHeaderVisibility();
            this.props.actions.setPrevRouteUrl(prevProps.location.pathname);
            this.initNavigation();
            this.setState({ activeDropdownId: undefined });
        }
        // enable below if tabs animation is needed on scroll
        // this.initHeaderScroll();
    }

    public handleSimulationNavigation = (to: string, isExternal?: boolean, id?: string, title?: string) => {
        if (title !== "Add Project") {
            const projectName = this.props.context.projectName;
            const preMeta = localStorage.getItem(ALPS_LOCAL_STORAGE_KEY);
            const ls = !isNull(preMeta) ? JSON.parse(preMeta) : {};
            this.props.actions.setProjectSetupHeaderText(ls.currentProjectNameUnderEdit || projectName || "");
        } else {
            this.props.actions.setProjectSetupHeaderText("Add Project");
        }
        if (!isUndefined(id) && title !== "Add Project") {
            const userId = JSON.parse(localStorage.getItem(ALPS_LOCAL_STORAGE_KEY) || "{}").user_id;
            this.getProjectAndTenantDetails(Number(id), userId);
        }
        if (
            (this.props.routes.activeRouteUrl === SIMULATOR_URL &&
                isUndefined(this.props.selectedVersion) &&
                this.props.isContentEditorDirty) ||
            this.props.isAuthorityEditorDirty ||
            this.props.isTechnicalEditorDirty
        ) {
            this.setState({
                isOpen: true,
                redirectTo: to,
                isExternalLink: isExternal
            });
            this.props.actions.setAlertView(true);
        } else {
            if (isUndefined(isExternal)) {
                window.open(to, "_blank");
            } else {
                this.setState(
                    {
                        isRedirect: true,
                        redirectTo: to,
                        isExternalLink: isExternal
                    },
                    () => {
                        this.setState({
                            isRedirect: false,
                            redirectTo: to,
                            isExternalLink: isExternal
                        });
                    }
                );
            }
        }
    }

    public toggleModal = () => {
        this.setState({
            isOpen: false,
            onClickBackButton: false
        });
        window.history.pushState("", "", window.location.pathname);
        this.props.actions.setAlertView(false);
    }

    public onClickHandler = () => {
        window.removeEventListener("popstate", this.onBackButtonEvent);
        if (isUndefined(this.state.isExternalLink)) {
            this.setState(
                {
                    isOpen: false
                },
                () => {
                    window.open(this.state.redirectTo, "_blank");
                }
            );
            this.props.actions.setAlertView(false);
        } else {
            this.setState({
                onClickBackButton: true,
                isOpen: false,
                isRedirect: true
            });
            this.props.actions.setAlertView(false);
        }
    }

    public onBackButtonEvent = (e: PopStateEvent) => {
        e.preventDefault();
        if (!this.state.onClickBackButton) {
            this.setState({
                // isOpen: true
                isOpen: false
            });
            this.props.actions.setAlertView(false);
        }
    }

    public redirectTo = (redirectTo: string) => {
        window.removeEventListener("beforeunload", onLeavePage);
        window.location.replace(redirectTo);
    }

    // tslint:disable-next-line: no-any
    public createProjectList = (projects: any) => {
        const projectList: HeaderNavListInterface = {
            id: "add-project",
            to: PROJECT_SETUP_DOMAIN_DETAILS_URL,
            title: COPY.ADD_PROJECT,
            type: NavLinkType.INTERNAL,
            icon: projectIcon,
            child: projects.length
                ? [
                    {
                        id: "add-project",
                        to: PROJECT_SETUP_DOMAIN_DETAILS_URL,
                        title: COPY.ADD_PROJECT,
                        type: NavLinkType.INTERNAL,
                        showEditIcon: false,
                        keywordCount: 0
                    },
                    // tslint:disable-next-line: no-any
                    ...projects.map((item: any) => {
                        return {
                            id: item.id,
                            to: "/home",
                            title: item.name,
                            showEditIcon: true,
                            type: NavLinkType.INTERNAL,
                            keywordCount: item.keyword_count
                        };
                    })
                ]
                : []
        };
        return projectList;
    }

    public setHeaderVisibility = (): void => {
        const { pathname } = window.location;
        const isHeaderVisible = !(
            pathname.endsWith("/change-account") ||
            pathname.endsWith("/logout") ||
            pathname.endsWith("/changepassword") ||
            pathname.includes("/resetpassword")
        );
        this.setState({ isHeaderVisible });
    }

    public render(): JSX.Element {
        const { breadcrumbList, activeDropdownId, headerTabsList, isRedirect, redirectTo, isExternalLink } = this.state;
        const { context } = this.props;
        const projectList = this.createProjectList(context.projectsList || []);

        return (
            <React.Fragment>
                <Header
                    activeRouteUrl={this.props.routes.activeRouteUrl}
                    links={HEADER_NAV_LIST}
                    projectList={projectList}
                    context={context}
                    isHeaderVisible={this.state.isHeaderVisible}
                    toggleDropdown={this.toggleDropdown}
                    activeDropdownId={activeDropdownId}
                    handleSimulationNavigation={() => this.handleSimulationNavigation(`${PAGE_OPTIMIZER_URL}`, false)}
                    handleRedirection={this.handleSimulationNavigation}
                    handleDeleteProject={this.handleDeleteProject}
                />
                {/* {isRedirect && <Redirect to={redirectTo} />} */}
                {isRedirect && (isExternalLink ? this.redirectTo(redirectTo) : <Redirect to={redirectTo} />)}
                {/*<PrimaryNavigation*/}
                {/*    activeRouteUrl={this.props.routes.activeRouteUrl}*/}
                {/*    links={PRIMARY_NAV_LIST}*/}
                {/*    context={context}*/}
                {/*    toggleDropdown={this.toggleDropdown}*/}
                {/*    activeDropdownId={activeDropdownId}*/}
                {/*/>*/}

                {getCookie(COOKIE_SUB_PLAN) ||
                    getCookie(COOKIE_LFT_USER) ||
                    !this.state.isHeaderVisible ||
                    this.props.PageNotFoundVisible ? null : (
                    <Breadcrumb handleRedirection={this.handleSimulationNavigation} links={breadcrumbList} context={context} />
                )}

                {headerTabsList && !!headerTabsList.navLinks.length && this.state.isHeaderVisible && (
                    <Tabs
                        title={headerTabsList.title}
                        icon={headerTabsList.icon}
                        activeRouteUrl={this.props.routes.activeRouteUrl}
                        links={headerTabsList.navLinks}
                        context={context}
                    />
                )}
                {this.state.isOpen && (
                    <RefreshContentConsentAlert
                        toggleModal={this.toggleModal}
                        title={COPY.LEAVE_PAGE_TEXT}
                        onClickSaveHandler={this.onClickHandler}
                    />
                )}
            </React.Fragment>
        );
    }

    public handleDeleteProject(projectId: number): void {
        if (projectId) {
            this.props.actions.callDeleteProject(projectId);
        }
    }

    public getProjectAndTenantDetails(projectId: number, userId: string): void {
        if (projectId) {
            this.props.actions.getProjectDetails({
                projectId,
                notifyErrorMethod: this.context.push
            });
            // Call refreshProjectCache from Competitive-intelligence to update reports cache for new project
            this.props.actions.refreshProjectCache();
        }
        if (userId) {
            this.props.actions.getTenantLimits({ userID: userId, isCalledFromHomePage: false });
        }
    }

    private setNavigation({ breadcrumbs, tabs }: { breadcrumbs: NavListInterface[]; tabs: PrimaryNavListTabsInterface }): void {
        this.setState({
            breadcrumbList: breadcrumbs ? breadcrumbs : this.state.breadcrumbList,
            headerTabsList: tabs && tabs.navLinks ? tabs : this.state.headerTabsList
        });
    }

    private initNavigation(): void {
        const pathname = this.props.location.pathname;
        const { activeRouteUrl } = this.props.routes;
        let breadcrumbs: NavListInterface[] = [];
        let tabs: PrimaryNavListTabsInterface = {
            title: "",
            icon: "",
            navLinks: []
        };
        // search though main navigation
        for (const nav of PRIMARY_NAV_LIST) {
            if (nav.to === pathname || nav.to === activeRouteUrl) {
                breadcrumbs = nav.breadcrumbs || breadcrumbs;
                tabs = nav.tabs || tabs;
            }

            if (
                pathname.startsWith(nav.to) ||
                (nav.highlightWhen && !!(nav.highlightWhen.includes(pathname) || nav.highlightWhen.includes(activeRouteUrl)))
            ) {
                // search through tabs menu
                for (const subNav of nav.tabs.navLinks) {
                    if (subNav.to === pathname) {
                        breadcrumbs = subNav.breadcrumbs || breadcrumbs;
                        tabs = nav.tabs || tabs;
                    }
                }

                // search through children tabs menu
                for (const childNav of nav.children) {
                    if (
                        childNav.to === pathname ||
                        (childNav.highlightWhen &&
                            !!(childNav.highlightWhen.includes(pathname) || childNav.highlightWhen.includes(activeRouteUrl)))
                    ) {
                        breadcrumbs = childNav.breadcrumbs || breadcrumbs;
                        tabs = childNav.tabs || tabs;
                    }
                }

                // search through child route(nested nav)
                for (const subNav of nav.children) {
                    if (subNav.to === activeRouteUrl || (subNav.highlightWhen && subNav.highlightWhen.includes(activeRouteUrl))) {
                        breadcrumbs = subNav.breadcrumbs || breadcrumbs;
                        tabs = subNav.tabs || tabs;
                    }
                }
            }
        }
        this.setNavigation({ breadcrumbs, tabs });
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: {
        clearUserSelection: bindActionCreators(clearUserSelection, dispatch),
        setActiveRouteUrl: bindActionCreators(setActiveRouteUrl, dispatch),
        setPrevRouteUrl: bindActionCreators(setPrevRouteUrl, dispatch),
        getProjectDetails: bindActionCreators(getProjectDetails, dispatch),
        getTenantLimits: bindActionCreators(getTenantLimits, dispatch),
        callDeleteProject: bindActionCreators(callDeleteProject, dispatch),
        setProjectSetupHeaderText: bindActionCreators(setProjectSetupHeaderText, dispatch),
        refreshProjectCache: bindActionCreators(refreshProjectCache, dispatch),
        setAlertView: bindActionCreators(setAlertView, dispatch)
    }
});

const mapStateToProps = (state: ApplicationStateInterface) => ({
    app: state.app,
    routes: state.routes,
    context: state.context,
    isContentEditorDirty: state.simulatorBeta.content.isEditorDirty,
    isAuthorityEditorDirty: state.simulatorBeta.authority.isDirty,
    isTechnicalEditorDirty: state.simulatorBeta.technical.isDirty,
    selectedVersion: state.simulatorBeta.selectedVersion,
    headerText: state.projectSetup?.headerText,
    PageNotFoundVisible: state.userAccount.PageNotFoundVisible
});

Navigation.contextType = AppMessageContext;

// @ts-ignore
const NavigationSec = connect(mapStateToProps, mapDispatchToProps)(Navigation);

export default NavigationSec;
