import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { callToVerifyUserEmail, callToReverifyEmail } from "./ducks/actions";
import ButtonOrange, { StyledButton } from "../app/styledComponents/button-orange";
import { ApplicationStateInterface } from "../app/duck/types";
import { UserEmailVerificationStatus, UserEVButton } from "./ducks/types";
import {
    USER_EV_FALIURE,
    USER_EV_INVALID_LINK,
    USER_EV_LINK_EXPIRED,
    USER_EV_RESEND_MAIL_SUCCESS,
    USER_EV_RESEND_MAIL_FAILURE,
    USER_EV_VERIFYING,
    USER_EV_REVERIFYING,
    USER_EV_ALPSEMAIL
} from "../app/const";

export const StyledEmailVerificationWrapper = styled.div`
    width: 100vw;
    height: 100vh;
`;

export const StyledEmailVerificationContainer = styled.div`
    font-family: Poppins;
    text-align: center;
    position: relative;
    top: 50%;
    transform: translate(0%, -50%);
`;

export const StyledEmailVerificationText = styled.h1`
    font-size: 18px;
    margin-bottom: 20px;
`;

export const StyledEVButtonWrapper = styled.div`
    text-align: center;
`;

export const StyledEVButton = styled(StyledButton)`
    width: 144px;
`;

const EmailVerification = () => {
    const dispatch = useDispatch();
    const { userEmailVrificaitonStatus, userEmailID } = useSelector(
        (state: ApplicationStateInterface) => state.emailVreification,
        shallowEqual
    );
    const [visibleMessage, setVisibleMessage] = useState(USER_EV_VERIFYING);
    const [showButton, setShowButton] = useState(UserEVButton.DISABLE);
    const [visibleEMMessage, setVisibleEMMessage] = useState("");

    useEffect(() => {
        switch (userEmailVrificaitonStatus) {
            case UserEmailVerificationStatus.SUCCESS:
                setVisibleEMMessage("");
                setShowButton(UserEVButton.DISABLE);
                // redirect to login screen with verified now message
                redirectToLogin(1);
                break;
            case UserEmailVerificationStatus.ALREADY_VERIFIED:
                setVisibleEMMessage("");
                setShowButton(UserEVButton.DISABLE);
                // redirect to login screen with verified already message
                redirectToLogin(2);
                break;
            case UserEmailVerificationStatus.INVALID_LINK:
                setVisibleMessage(USER_EV_INVALID_LINK);
                setVisibleEMMessage("");
                setShowButton(UserEVButton.DISABLE);
                break;
            case UserEmailVerificationStatus.LINK_EXPIRED:
                setVisibleMessage(USER_EV_LINK_EXPIRED);
                setVisibleEMMessage("");
                setShowButton(UserEVButton.RESEND);
                break;
            case UserEmailVerificationStatus.RESEND_MAIL_SUCCESS:
                setVisibleMessage(USER_EV_RESEND_MAIL_SUCCESS);
                setVisibleEMMessage("");
                setShowButton(UserEVButton.DISABLE);
                break;
            case UserEmailVerificationStatus.RESEND_MAIL_FAILED:
                setVisibleMessage(USER_EV_RESEND_MAIL_FAILURE);
                setVisibleEMMessage(USER_EV_ALPSEMAIL);
                setShowButton(UserEVButton.DISABLE);
                break;
            case UserEmailVerificationStatus.FALIURE:
                setVisibleMessage(USER_EV_FALIURE);
                setVisibleEMMessage(USER_EV_ALPSEMAIL);
                setShowButton(UserEVButton.DISABLE);
                break;
            default:
                setVisibleMessage(USER_EV_VERIFYING);
                setVisibleEMMessage("");
                setShowButton(UserEVButton.DISABLE);
        }
    }, [userEmailVrificaitonStatus]);

    useEffect(() => {
        const linkItems = window.location.href.split("/");
        const userToken = linkItems[linkItems.indexOf("user") + 1];
        dispatch(callToVerifyUserEmail({ token: userToken }));
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.GOOGLE_CAPTCHA_SITE_KEY}`;
        document.body.appendChild(script);
    }, []);


    const redirectToLogin = (verifiedState: number) => {
        let getParam = "";
        if (verifiedState === 1) {
            getParam = "?m=v_n";
        } else if (verifiedState === 2) {
            getParam = "?m=v_a";
        }
        window.location.href = `${process.env.ROOT_BASE_URL}/login${getParam}`;
    };

    const onResendClickHandler = () => {
        setVisibleMessage(USER_EV_REVERIFYING);
        setShowButton(UserEVButton.DISABLE);

        // @ts-ignore
        window.grecaptcha.ready(() => {
            // @ts-ignore
            window.grecaptcha.execute(`${process.env.GOOGLE_CAPTCHA_SITE_KEY}`, { action: "homepage" }).then((captchaToken) => {
                dispatch(callToReverifyEmail({ user_id: userEmailID, token: captchaToken }));
            });
        });
    };

    return (
        <>
            <StyledEmailVerificationWrapper>
                <StyledEmailVerificationContainer>
                    <StyledEmailVerificationText>
                        {visibleMessage}
                        <u>{visibleEMMessage}</u>
                    </StyledEmailVerificationText>
                    {showButton === UserEVButton.RESEND && (
                        <StyledEVButtonWrapper>
                            <ButtonOrange label="Resend" styles={{ Button: StyledEVButton }} onClickHandler={onResendClickHandler} />
                        </StyledEVButtonWrapper>
                    )}
                </StyledEmailVerificationContainer>
            </StyledEmailVerificationWrapper>
        </>
    );
};

export default EmailVerification;
