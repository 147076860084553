import { isUndefined } from "lodash";
import { getCookie } from "../../../src/app/duck/utils";
import { contactIcon, profileIcon } from "../../app/icons";
import { HeaderNavListInterface, NavLinkType } from "./types";

const previousCookies = getCookie(`${process.env.COOKIE_SESSION_ID}`);
const cookie = (!isUndefined(previousCookies) &&
    previousCookies !== "" &&
    JSON.parse(previousCookies).hasOwnProperty("sso_enabled") &&
    JSON.parse(previousCookies).sso_enabled === true) ? true : false;

const child = cookie ? [
    { id: "project-account-nav", to: "/:tenantCode/change-account", title: "Change Account", type: NavLinkType.INTERNAL },
    { id: "profile-logout-nav", to: "/:tenantCode/logout", title: "Logout", type: NavLinkType.INTERNAL },
] : [
    { id: "project-account-nav", to: "/:tenantCode/change-account", title: "Change Account", type: NavLinkType.INTERNAL },
    { id: "profile-change-password-nav", to: "/:tenantCode/changepassword", title: "Change Password", type: NavLinkType.INTERNAL },
    { id: "profile-logout-nav", to: "/:tenantCode/logout", title: "Logout", type: NavLinkType.INTERNAL },
];

const HEADER_NAV_LIST: HeaderNavListInterface[] = [
    { id: "support-nav", to: "javascript: zE('webWidget', 'show'); zE('webWidget', 'open');", title: "", icon: contactIcon, type: NavLinkType.SCRIPT },
    {
        child, id: "profile-nav", to: "/profile", title: "", icon: profileIcon, type: NavLinkType.INTERNAL
    },
];

export default HEADER_NAV_LIST;
