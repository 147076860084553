import * as React from "react";
import styled from "styled-components";
import LoadingIcon, { StyledLoadingIcon } from "../styledComponents/loading-icon";

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 9999;
    display: block;
    background: rgba(235, 236, 240, 0.9);
`;

export const SpinnerOverlayContainer = styled.div`
    width: 200px;
    height: 130px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    ${StyledLoadingIcon} {
        display: block;
        width: 56px;
        height: 56px;
        margin: 20px auto;
    }
`;

export const LoadingText = styled.div`
    font-weight: 700;
    font-size: 16px;
    color: #00acce;
    text-align: center;
    margin-bottom:${(props: { marginBottom?: string }) => (props.marginBottom || "0px")};
`;

export interface AppLoader {
    title?: string;
    message?: string;
    instanceId?: string;
}

const AppLoader: React.SFC<AppLoader> = ({ title, message, instanceId }) => (
    <ModalOverlay id={`${instanceId}_loader-overlay`}>
        <SpinnerOverlayContainer id={`${instanceId}_loader`}>
            {title && <LoadingText id={`${instanceId}_title`}>{title}</LoadingText>}
            <LoadingIcon id={`${instanceId}_spinner`} />
            {message && <LoadingText id={`${instanceId}_message`}>{message}</LoadingText>}
        </SpinnerOverlayContainer>
    </ModalOverlay>
);

export default AppLoader;
