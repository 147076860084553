// @ts-nocheck
import * as React from "react";
import styled from "styled-components";
import Select, { components, createFilter } from "react-select";
import { OptionTypeInterface, StylesType, ComponentsTypes, DROPDOWN_CUSTOM_STYLES, FormatOptionTypeInterface, LocaleSearchOptionTypeInterface } from "./types";
import CustomOption from "../../../beta/simulator/components/dropDownCustomOption";
const SingleValueTitle = styled.span``;

interface DropdownPropsInterface {
    isDisabled?: boolean;
    styles?: StylesType;
    options: OptionTypeInterface[] | GroupedOption[];
    placeholder: string | React.node;
    component?: ComponentsTypes;
    value?: OptionTypeInterface | string;
    instanceId?: string;
    optionsId?: string;
    isOptionEnable?: boolean;
    classNamePrefix?: string;
    formatOptionLabel?(args: FormatOptionTypeInterface): JSX.Element;
    // tslint:disable-next-line:no-any
    onChange(value: any): void;
}

const IndicatorSeparator: React.SFC = () => null;

// tslint:disable-next-line:no-any
const SingleValue: React.SFC = (props: any) => (
    <components.SingleValue {...props}>
        <SingleValueTitle>{props.selectProps.placeholder}</SingleValueTitle>
    </components.SingleValue>
);

/**
 *  DROPDOWN:
 *
 *  Dropdown is a simple component with minimal props to render the basic dropdown
 *  Props
 *
 *  @styles => style object to style the dropdown refer to https://react-select.com/styles#style-object
 *  @options => this prop will accept array of objects with label & key as mandatory fields to show the dropdown list
 *  @component => customisable components can be passed over here to override the default components provided by the
 *                react select refer to https://react-select.com/components
 *  @placeholder => placeholder text for the dropdown label
 *  @onChange => callback function to trigger when the dropdown option is selected/changed
 *  @value => this accepts the default select value for the dropdown which can be used as controlled component
 *  @formatOptionLabel => this is generic formatting option to change the label and option of the dropdown
 *  @instanceId => the string passed to this prop will be applied as id to container component of dropdown
 *  @optionsId => the string passed to this prop will be appened to the options tag of the dropdown
 *
 */
const Dropdown: React.SFC<DropdownPropsInterface> = ({
    styles,
    options,
    component,
    placeholder = "",
    onChange,
    value,
    formatOptionLabel,
    instanceId = "",
    optionsId = "",
    isOptionEnable,
    isDisabled,
    classNamePrefix
}) => (
    <ReactSelect
        instanceId={instanceId}
        optionsId={optionsId}
        styles={styles}
        options={options}
        isOptionEnable={isOptionEnable}
        component={{
            IndicatorSeparator,
            SingleValue,
            ...component
        }}
        /** Think about how can we handle IsSearchable props */
        isSearchable={false}
        isClearable={false}
        isDisabled={isDisabled}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        formatOptionLabel={formatOptionLabel}
        classNamePrefix={classNamePrefix || ""}
    />
);

interface ReactSelectPropsInterface {
    options: OptionTypeInterface[];
    component?: ComponentsTypes;
    styles?: StylesType;
    placeholder: string | React.node;
    isSearchable?: boolean;
    isClearable?: boolean;
    hideSelectedOptions?: boolean;
    backspaceRemovesValue?: boolean;
    closeMenuOnSelect?: boolean;
    isMulti?: boolean;
    autoFocus?: boolean;
    controlShouldRenderValue?: boolean;
    menuIsOpen?: boolean;
    tabSelectsValue?: boolean;
    value?: OptionTypeInterface | OptionTypeInterface[];
    instanceId?: string;
    optionsId?: string;
    CustomOption?: React.ReactNode;
    classNamePrefix?: string;
    // tslint:disable-next-line:no-any
    onChange(value: any): void;
    formatOptionLabel?(args: FormatOptionTypeInterface): JSX.Element;
    customFilter?(option: LocaleSearchOptionTypeInterface, searchText: string): boolean;
}

const getComposedStyles = (defaultStyles?: StylesType, styles?: StylesType) => ({
    ...defaultStyles,
    ...styles
});

const StyledDropdownSVG = styled.svg``;

// tslint:disable-next-line: no-any
const Svg = (p: any) => <StyledDropdownSVG focusable="false" role="presentation" {...p} />;

const StyledDropdownIndicatorSVGPath = styled.path``;

const EnabledDropdownIndicator: React.SFC = () => {
    return (
        <Svg height="16" width="16" viewBox="0 0 20 20" style={{ marginRight: 6, marginTop: 3 }}>
            <StyledDropdownIndicatorSVGPath
                d="M14.136 4c.311-.004.6.146.757.392.154.258.141.57-.035.814L8.704 11.67c-.17.208-.437.33-.72.33-.282 0-.55-.122-.719-.33L1.13 5.206c-.091-.122-.137-.27-.128-.42 0-.136.035-.27.1-.394.159-.234.433-.38.733-.392h12.302z"
                fill="#343434"
                fillRule="evenodd"
            />
        </Svg>
    );
};

const DisabledDropdownIndicator: React.SFC = () => {
    return (
        <Svg height="15" width="15" viewBox="0 0 15 15" style={{ marginRight: 8, marginTop: 0 }}>
            <StyledDropdownIndicatorSVGPath
                d="M13.253 3.75a.826.826 0 0 1 .709.367.685.685 0 0 1-.033.763L8.16 10.94a.87.87 0 0 1-.674.31.87.87 0 0 1-.675-.31L1.059 4.88a.598.598 0 0 1-.12-.393c0-.128.032-.253.093-.37a.869.869 0 0 1 .687-.367h11.534z"
                fill="#343434"
                fill-rule="evenodd"
                opacity=".2"
            />
        </Svg>
    );
};

/**
 *  ReactSelect dropdown:
 *
 *  ReactSelect is the master component of Single select(Dropdown), MultiselectDropdown and SearchableDropdown with all
 *    controls defined at one place with all possible props
 *
 *  @styles => (type: object) : style object to style the dropdown refer to https://react-select.com/styles#style-object
 *  @options => (type: array of objects) :this prop will accept array of objects with label & key as mandatory fields to show the dropdown list
 *  @component => (type: component object list): customisable components can be passed over here to override the default components provided by the
 *                react select refer to https://react-select.com/components
 *  @placeholder => (type: string): placeholder text for the dropdown label
 *  @onChange => (type: object) : callback function to trigger when the dropdown option is selected/changed
 *  @value => (type: object) : this accepts the default select value for the dropdown which can be used as controlled component
 *  @formatOptionLabel => (type: component) : this is generic formatting option to change the label and option of the dropdown
 *  @isSearchable => (type: boolean) : this is used to show the search box in the dropdown list
 *  @isClearable => (type: boolean) : this is to show the clear icon in drodown search
 *  @hideSelectedOptions => (type: boolean) : to hide/show the selected options in the dropdown list
 *  @backspaceRemovesValue => (type: boolean) : to remove the value on pressing the backspace
 *  @closeMenuOnSelect => (type: boolean) : boolean to show/hide on selecting the option
 *  @isMulti => (type: boolean) : to make the drodpwon single select or multiselect
 *  @autoFocus => (type: boolean) : to set focus on load
 *  @controlShouldRenderValue => (type: boolean) : to show/hide select value in the control component
 *  @menuIsOpen => (type: boolean) => to show/hide the menu options
 *  @tabSelectsValue => (type: boolean) => to select the current focused option on pressing the tab button
 *  @instanceId => the string passed to this prop will be applied as id to container component of dropdown
 *  @optionsId => the string passed to this prop will be appened to the options tag of the dropdown
 *
 */

export const ReactSelect: React.SFC<ReactSelectPropsInterface> = ({
    styles,
    options,
    component,
    placeholder,
    onChange,
    value,
    formatOptionLabel = null,
    isSearchable = false,
    isClearable = false,
    isDisabled = false,
    hideSelectedOptions = false,
    backspaceRemovesValue = true,
    closeMenuOnSelect = true,
    isMulti = false,
    autoFocus = false,
    controlShouldRenderValue = true,
    menuIsOpen = false,
    tabSelectsValue = true,
    instanceId = "",
    optionsId = "",
    isOptionEnable,
    classNamePrefix,
    customFilter = null
}) => {

    const DropdownIndicator: React.SFC = isDisabled ? DisabledDropdownIndicator : EnabledDropdownIndicator;

    return (
        <Select
            filterOption={customFilter ? customFilter : createFilter({ ignoreAccents: false })} // this makes all the difference!
            id={instanceId}
            instanceId={optionsId}
            styles={styles ? getComposedStyles(DROPDOWN_CUSTOM_STYLES, styles) : DROPDOWN_CUSTOM_STYLES}
            options={options}
            components={isOptionEnable ? {
                DropdownIndicator,
                IndicatorSeparator,
                SingleValue,
                ...component,
                Option: CustomOption,
            } : {
                DropdownIndicator,
                IndicatorSeparator,
                SingleValue,
                ...component
            }}
            /** Think about how can we handle IsSearchable props */
            isSearchable={isSearchable}
            isClearable={isClearable}
            placeholder={placeholder}
            hideSelectedOptions={hideSelectedOptions}
            backspaceRemovesValue={backspaceRemovesValue}
            closeMenuOnSelect={closeMenuOnSelect}
            isMulti={isMulti}
            onChange={onChange}
            autoFocus={autoFocus}
            controlShouldRenderValue={controlShouldRenderValue}
            tabSelectsValue={tabSelectsValue}
            value={value}
            {...(() => {
                // handle the props which is optional & dosen't have same default vlaue
                let returnProps = {};
                if (menuIsOpen) {
                    returnProps = {
                        ...returnProps,
                        menuIsOpen
                    };
                }
                if (formatOptionLabel) {
                    returnProps = {
                        ...returnProps,
                        formatOptionLabel
                    };
                }
                return returnProps;
            })()}
            classNamePrefix={classNamePrefix || ""}
        />
    );
};

export default Dropdown;
