import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import actionTypes from "./actions";
import { ContentBriefViewReducerInterface } from "./types";

export const INIT_STATE: ContentBriefViewReducerInterface = {
    tableLoading: false,
    contentBriefDetails: {
        id: "",
        name: "",
        topic: "",
        description: "",
        locale: ""
    },
    contentBriefSummary: {
        avg_search_volume: 0,
        avg_article_length: 0,
        avg_content_score: 0,
        avg_topic_difficulty: 0,
        serp_elements: []
    },
    tableHistory: [],
    competitorList: [],
    isModifyKeyword: false,
    unigrams: [],
    bigrams: [],
    trigrams: [],
    questionList: {}
};

const Reducer = handleActions(
    {
        [actionTypes.SAVE_SELECTED_CONTENT_BRIEF]: (state, action: AnyAction) => ({
            ...state,
            contentBriefDetails: action.payload
        }),
        [actionTypes.SAVE_CONTENT_BRIEF_SUMMARY]: (state, action: AnyAction) => ({
            ...state,
            contentBriefSummary: action.payload
        }),
        [actionTypes.SAVE_CONTENT_BRIEF_TAB_HISTORY]: (state, action: AnyAction) => ({
            ...state,
            tableHistory: action.payload
        }),
        [actionTypes.SAVE_CONTENT_BRIEF_COMPETITORS]: (state, action: AnyAction) => ({
            ...state,
            competitorList: action.payload
        }),
        [actionTypes.SET_PAGE_SHOW_LOADER]: (state, action: AnyAction) => ({
            ...state,
            tableLoading: true
        }),
        [actionTypes.SET_PAGE_HIDE_LOADER]: (state, action: AnyAction) => ({
            ...state,
            tableLoading: false
        }),
        [actionTypes.IS_MODIFY_KEYWORD]: (state, action: AnyAction) => ({
            ...state,
            isModifyKeyword: action.payload
        }),
        [actionTypes.SET_UNIGRAMS_WORDS]: (state, action: AnyAction) => ({
            ...state,
            unigrams: action.payload
        }),
        [actionTypes.SET_BIGRAMS_WORDS]: (state, action: AnyAction) => ({
            ...state,
            bigrams: action.payload
        }),
        [actionTypes.SET_TRIGRAMS_WORDS]: (state, action: AnyAction) => ({
            ...state,
            trigrams: action.payload
        }),
        [actionTypes.SET_QUESTIONS]: (state, action: AnyAction) => ({
            ...state,
            questionList: action.payload
        })
    },
    INIT_STATE
);

export default Reducer;
