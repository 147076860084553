import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import ActionTypes from "./actions";
import { viewRecommendationPhrasesHeading } from "./const";
import { RecommendationReducerInterface } from "./types";
import { getFormattedPhraseContent } from "./utils";
import actionTypes from "../../../../ducks/actions";
import ContextualRecommendationReducer from "../../Editor/Components/ContentEditor/ContextualRecommendationsModal/ducks/reducer";
import { composeReducers } from "../../../../../../app/duck/utils";

const INIT_STATE: RecommendationReducerInterface = {
    isMetaElementChange: false,
    versionKeywords: [],
    isRefreshRecommendation: false,
    isSimulationVersionChanged: false,
    selectedTabRecommendation: 0,
    phraseContent: undefined,
    isPhrasesLoading: true,
    isSimulationDone: false,
    selectedRecoPhraseDD: viewRecommendationPhrasesHeading[0],
    isShowTopPagesLoader: false,
    initialKeywordsDropDownHead: undefined,
    isTopPageKwDropdownChange: false,
    recoSSERetriedAttempt: 0,
    recoSSELoading: false,
    simulationKeywords: [],
    phrasesPayloadKeywords: [],
    isFirstTimeRecoOpen: false,
    contextualRecommendations: [],
    isContextualApiFail: undefined,
    isContextualRecomLoding: false,
    isContextRecomModalOpen: false
};

const SummaryReducer = handleActions(
    {
        [actionTypes.IS_META_ELEMENT_CHANGE]: (state, action: AnyAction) => ({
            ...state,
            isMetaElementChange: action.payload
        }),
        [actionTypes.SET_VERSION_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            versionKeywords: action.payload
        }),
        [actionTypes.SET_IS_SIMULATION_VERSION_CHANGE]: (state, action: AnyAction) => ({
            ...state,
            isSimulationVersionChanged: action.payload
        }),
        [ActionTypes.SET_IS_RECOMMENDATION_REFRESH]: (state, action: AnyAction) => ({
            ...state,
            isRefreshRecommendation: action.payload
        }),
        [actionTypes.SELECTED_TAB_RECOMMENDATION]: (state, action: AnyAction) => ({
            ...state,
            selectedTabRecommendation: action.payload
        }),
        [actionTypes.SET_PHRASE_CONTENT]: (state, action: AnyAction) => ({
            ...state,
            phraseContent: action.payload.editorContent && action.payload.phraseContent ?
                getFormattedPhraseContent(action.payload.phraseContent, action.payload.editorContent)
                : action.payload.phraseContent
        }),
        [actionTypes.UPDATE_PHRASE_CONTENT]: (state, action: AnyAction) => ({
            ...state,
            phraseContent: action.payload.editorContent ?
                getFormattedPhraseContent(state.phraseContent, action.payload.editorContent)
                : action.payload.phraseContent
        }),
        [ActionTypes.SET_IS_PHRASES_LOADING]: (state, action: AnyAction) => ({
            ...state,
            isPhrasesLoading: action.payload
        }),
        [actionTypes.IS_SIMULATION_DONE]: (state, action: AnyAction) => ({
            ...state,
            isSimulationDone: action.payload
        }),
        [actionTypes.SET_SELECTED_RECO_PHRASE_DD]: (state, action: AnyAction) => ({
            ...state,
            selectedRecoPhraseDD: action.payload
        }),
        [actionTypes.SET_IS_SHOW_TOP_PAGES_LOADER]: (state, action: AnyAction) => ({
            ...state,
            isShowTopPagesLoader: action.payload
        }),
        [actionTypes.INITIAL_KEYWORDS_DROPDOWN_HEAD]: (state, action: AnyAction) => ({
            ...state,
            initialKeywordsDropDownHead: action.payload
        }),
        [ActionTypes.IS_TOP_PAGE_KW_DROPDOWN_CHANGE]: (state, action: AnyAction) => ({
            ...state,
            isTopPageKwDropdownChange: action.payload
        }),
        [ActionTypes.SET_RECO_SSE_RETRIED_ATTEMPT]: (state, action: AnyAction) => ({
            ...state,
            recoSSERetriedAttempt: action.payload
        }),
        [ActionTypes.SET_RECO_SSE_LOADER]: (state, action: AnyAction) => ({
            ...state,
            recoSSELoading: action.payload
        }),
        [actionTypes.SET_SIMULATION_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            simulationKeywords: action.payload
        }),
        [actionTypes.SET_PHRASES_PAYLOAD_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            phrasesPayloadKeywords: action.payload
        }),
        [actionTypes.SET_IS_FIRST_TIME_RECO_OPEN]: (state, action: AnyAction) => ({
            ...state,
            isFirstTimeRecoOpen: action.payload
        })
    },
    INIT_STATE
);

const Reducer = composeReducers(SummaryReducer, ContextualRecommendationReducer);
export default Reducer;
