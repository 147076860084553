import { ALPS_LOCAL_STORAGE_KEY, IQ_ALPS_COOKIE_EXPIRY_TIME, IS_DEV_SRC, TENANT_LIST_LOCAL_STORAGE_KEY } from "./app/const";
import { LocaleInterface } from "./app/duck/types";
import { TenantListInterface } from "./user_account/ducks/types";

export const getLocaleListData = async () => {
    const response = await fetch(`${process.env.IMAGE_URL}/img/locale.json`);
    const responseJson = response.json();
    if (response.status === 200) {
        return responseJson;
    }
    return [];
};

export const fetchLocaleList = async () => {
    const localeData = IS_DEV_SRC ? await getLocaleListData() : require(`${process.env.DEV_IMAGE_URL}/data/locale.json`);
    return localeData as LocaleInterface[];
};

/**
 * Set AI Assisted Flag in Local Storage
 *
 * @param {boolean} isAIAssisted - isAIAssisted value
 * @returns {void}
 */
export const updateAIAssistedInLocalStorage = (isAIAssisted: boolean) => {
    const LS_IQ_ALPS = JSON.parse(localStorage.getItem(ALPS_LOCAL_STORAGE_KEY) || "{}");
    LS_IQ_ALPS.is_AI_assisted_content = isAIAssisted;
    localStorage.setItem(ALPS_LOCAL_STORAGE_KEY, JSON.stringify(LS_IQ_ALPS));
};

/**
 * Set User Prompt in Local Storage
 *
 * @param {string} userPrompt - userPrompt
 * @returns {void}
 */
export const updateContentOutlineUserPromptInLocalStorage = (userPrompt: string) => {
    const LS_IQ_ALPS = JSON.parse(localStorage.getItem(ALPS_LOCAL_STORAGE_KEY) || "{}");
    LS_IQ_ALPS.userPrompt = userPrompt;
    localStorage.setItem(ALPS_LOCAL_STORAGE_KEY, JSON.stringify(LS_IQ_ALPS));
};

/**
 * Set Tenant List in Local Storage
 *
 * @param {string} tenantList - tenant_list
 * @returns {void}
 */
export const updateTenantListInLocalStorage = (tenantList: TenantListInterface[]) => {
    const TENANT_LIST = getTenantIds(tenantList).join();
    localStorage.setItem(TENANT_LIST_LOCAL_STORAGE_KEY, TENANT_LIST);
};

export const getTenantIds = (params: TenantListInterface[]): string[] => {
    const tenantList: string[] = [];
    params.forEach((item: TenantListInterface) => {
        tenantList.push(item.id);
    });
    return tenantList;
};

// tslint:disable-next-line:no-any
export const storeUserDetailsInLocalStorage = (userDetails: any) => {
    const LS_IQ_ALPS = JSON.parse(localStorage.getItem(ALPS_LOCAL_STORAGE_KEY) || "{}");
    const USER_DETAILS = formattedUserDetails(userDetails);
    const tenant_list = LS_IQ_ALPS.tenant_list;
    if (tenant_list) {
        USER_DETAILS.tenant_list = tenant_list;
    }
    localStorage.setItem(ALPS_LOCAL_STORAGE_KEY, JSON.stringify(USER_DETAILS));
};

// tslint:disable-next-line:no-any
export const storeUserDetailsInSessionCookie = (userDetails: any) => {
    const USER_DETAILS = formattedUserDetails(userDetails);
    delete USER_DETAILS.tenant_list;

    document.cookie = `${process.env.COOKIE_SESSION_ID} = ${JSON.stringify(USER_DETAILS)}; expires=${new Date(
        new Date().getTime() + IQ_ALPS_COOKIE_EXPIRY_TIME
    ).toUTCString()}; path=/`;
};

// tslint:disable-next-line:no-any
export const formattedUserDetails = (userDetails: any): any => {
    userDetails.isStandardProject = userDetails.is_standard;
    userDetails.isMobileProject = userDetails.is_mobile_project;
    userDetails.projectName = userDetails.name;
    userDetails.locale = userDetails.locale;
    userDetails.projectID = userDetails.default_project;
    userDetails.project_id = userDetails.default_project;

    delete userDetails.is_standard;
    delete userDetails.is_mobile_project;
    delete userDetails.name;
    return userDetails;
};
