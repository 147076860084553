import { AnyAction } from "redux";
import { put, takeEvery, call } from "redux-saga/effects";
import { assign, isEmpty, compact, isUndefined } from "lodash";
import Apis from "../../../app/apis";
import { CSV_WITH_KEYWORD, CSV_WITH_DOMAIN } from "../../../app/const";
import { downloadCSVFile } from "../../../app/duck/utils";

import actionTypes, {
    setKeyWordScores,
    setKeywordResponse,
    setUrl,
    setTopOrganicUrl,
    downloadRequestInPending,
    setParameterDetail,
    setUrlScore
} from "./actions";

import {
    KeyWordUrlPayloadInterface,
    SelectedUrlType,
    DefaultSelectedUrlTypesInterface,
    GetSerpDataDownloadRequestInterface,
    KeywordUrlDownloadDataRequestInterface,
    KeyWordUrlScoresRequestPayloadInterface,
    ParameterDetailInterface,
    KeyWordUrlScoresListRequestPayloadInterface
} from "./types";
import { getDefaultUrlToCompare } from "./utils";


// delete it

// tslint:disable-next-line:typedef
function* generateApiGetKeyWordUrl(action: AnyAction) {
    const queryParams: KeyWordUrlPayloadInterface = assign(
        {},
        {
            keyword: action.payload.keyword,
            locale: action.payload.locale,
            isBeta: true
            // request_id: getRequestId() // !isUndefined(requestId) ? requestId.request_id : ""
        },
        !isEmpty(action.payload.domain) ? { domain: action.payload.domain } : null
    );

    const keyWordUrls = yield call(yield Apis.getKeyWordUrl, queryParams);
    if (keyWordUrls.status === 200) {
        const formatedSerp: DefaultSelectedUrlTypesInterface = getDefaultUrlToCompare(
            keyWordUrls.data.data.serp,
            keyWordUrls.data.data.domain
        );
        yield put(setKeywordResponse(keyWordUrls.data));

        yield put(
            setUrl({
                type: SelectedUrlType.COMPATITOR,
                value: formatedSerp.compatitorUrl
            })
        );
        yield put(
            setUrl({
                type: SelectedUrlType.TARGET,
                value: formatedSerp.targetUrl
            })
        );
        const scoresPayload = {
            compatitor: formatedSerp.compatitorUrl,
            keyword: action.payload.keyword,
            locale: action.payload.locale,
            //  request_id: getRequestId(),
            target: formatedSerp.targetUrl,
            // urls: [formatedSerp.compatitorUrl]
            isBeta: true,
            urls: compact([formatedSerp.targetUrl, formatedSerp.compatitorUrl])
        };
        yield call(yield getKeywordScore, { payload: scoresPayload });
    }
}

// tslint:disable-next-line:typedef
function* getKeywordScore(action: AnyAction) {
    const scoresPayload: KeyWordUrlScoresRequestPayloadInterface = {
        keyword: action.payload.keyword,
        locale: action.payload.locale,
        //  request_id: getRequestId(),
        urls: action.payload.urls,
        isBeta: true
    };
    const keyWordScore = yield call(yield Apis.getKeyWordUrlScore, scoresPayload);
    if (keyWordScore.status === 200) {
        const collectedScore = keyWordScore.data;
        const scores = {
            compatitorParam: collectedScore[action.payload.compatitor].parameters,
            compatitorScores: collectedScore[action.payload.compatitor].scores,
            parameterGapGroupScore:
                !isEmpty(action.payload.target) && action.payload.target
                    ? collectedScore[action.payload.target].parameter_gap_group_score
                    : !isEmpty(action.payload.compatitor) && action.payload.compatitor
                        ? collectedScore[action.payload.compatitor].parameter_gap_group_score
                        : [],
            targetParam: !isEmpty(action.payload.target) && action.payload.target ? collectedScore[action.payload.target].parameters : [],
            targetScores: !isEmpty(action.payload.target) && action.payload.target ? collectedScore[action.payload.target].scores : []
        };
        yield put(setKeyWordScores(scores));
    }
}

// tslint:disable-next-line:typedef
function* getAllTopOrganicUrl(action: AnyAction) {
    const param = assign(
        {},
        {
            keyword: action.payload.keyword,
            locale: action.payload.locale,
            urls_list: action.payload.urlList,
            isBeta: true
            // request_id: getRequestId()
        },
        !isEmpty(action.payload.domain) || !isUndefined(action.payload.domain) ? { domain: action.payload.domain } : null
    );
    const compatitors = yield call(Apis.getAllTopCompatitors, param);
    if (compatitors.status === 200) {
        yield put(setTopOrganicUrl(compatitors.data));
    }
}
// tslint:disable-next-line:typedef
function* getReports(action: AnyAction) {
    const payload: GetSerpDataDownloadRequestInterface = {
        search_url: action.payload.search_url,
        keyword: action.payload.keyword,
        locale: action.payload.locale,

        project_id: action.payload.project_id,
        tenant_code: action.payload.tenant_code,
        isBeta: true
        //  request_id: getRequestId()
    };
    yield put(downloadRequestInPending(true));
    const receivedCsv = yield call(Apis.getSerpDataDownload, payload);
    yield put(downloadRequestInPending(false));
    if (action.payload.keyword) {
        if (receivedCsv.status === 200) {
            downloadCSVFile(receivedCsv.data, CSV_WITH_KEYWORD);
        }
    }
}
// tslint:disable-next-line:typedef
function* getUrlReports(action: AnyAction) {
    const payload: KeywordUrlDownloadDataRequestInterface = {
        keyword: action.payload.keyword,
        locale: action.payload.locale,
        //  request_id: getRequestId(),
        isBeta: true,
        urls: JSON.stringify(action.payload.urls)
    };

    yield put(downloadRequestInPending(true));

    const receivedCsv = yield call(Apis.keywordUrlDownload, payload);

    yield put(downloadRequestInPending(false));

    if (receivedCsv.status === 200) {
        downloadCSVFile(receivedCsv.data, CSV_WITH_DOMAIN);
    }
}
// tslint:disable-next-line:typedef
function* getParameterDetail(action: AnyAction) {
    const queryParams: ParameterDetailInterface = assign(
        {},
        {
            keyword: action.payload.keyword,
            locale: action.payload.locale,
            url: action.payload.url
        }
    );
    const parameterDetail = yield call(yield Apis.getParameterDetail, queryParams);
    if (parameterDetail.status === 200) {
        yield put(setParameterDetail(parameterDetail.data));
    }
}
// tslint:disable-next-line:typedef
function* getUrlScore(action: AnyAction) {
    const scoresPayload: KeyWordUrlScoresListRequestPayloadInterface = {
        keyword: action.payload.keyword,
        locale: action.payload.locale,
        //  request_id: getRequestId(),
        urls: action.payload.urls,
        isBeta: true
    };
    const keyWordScore = yield call(yield Apis.getKeyWordUrlScoreList, scoresPayload);
    if (keyWordScore.status === 200) {
        const collectedScore = keyWordScore.data;
        const scores = {
            compatitorParam: collectedScore[action.payload.compatitor].parameters,
            compatitorScores: collectedScore[action.payload.compatitor].scores,
            parameterGapGroupScore:
                !isEmpty(action.payload.target) && action.payload.target
                    ? collectedScore[action.payload.target].parameter_gap_group_score
                    : !isEmpty(action.payload.compatitor) && action.payload.compatitor
                        ? collectedScore[action.payload.compatitor].parameter_gap_group_score
                        : [],
            targetParam: !isEmpty(action.payload.target) && action.payload.target ? collectedScore[action.payload.target].parameters : [],
            targetScores: !isEmpty(action.payload.target) && action.payload.target ? collectedScore[action.payload.target].scores : []
        };
        yield put(setUrlScore(scores));
    }
}
// tslint:disable-next-line:typedef
export function* watchForGetKeywordUrl() {
    yield takeEvery(actionTypes.GET_KEYWORD_URL, generateApiGetKeyWordUrl);
}
// tslint:disable-next-line:typedef
// export function* watchForComparisionUrlChange() {
//     yield takeEvery(actionTypes.SET_SELECTED_URL, getKeywordScore);
// }

// tslint:disable-next-line:typedef
export function* watchForViewAllTopCompatitor() {
    yield takeEvery(actionTypes.GET_TOP_ORGANIC_URL, getAllTopOrganicUrl);
}

// tslint:disable-next-line:typedef
export function* watchForDownloadSelect() {
    yield takeEvery(actionTypes.GET_DOWNLOAD_KEYWORD_REPORT, getReports);
}
// tslint:disable-next-line:typedef
export function* watchForDownloadUrlReport() {
    yield takeEvery(actionTypes.GET_DOWNLOAD_URL_REPORT, getUrlReports);
}
// tslint:disable-next-line: typedef
export function* watchForGetParameterDetail() {
    yield takeEvery(actionTypes.GET_PARAMETERDETAIL, getParameterDetail);
}
// tslint:disable-next-line: typedef
export function* watchForGetUrlScore() {
    yield takeEvery(actionTypes.GET_URL_SCORE, getUrlScore);
}
