import { call, put, takeEvery } from "redux-saga/effects";
import { AnyAction } from "redux";
import ActionTypes, { callToGetLoginDetails, isAPILoading, isSessionExpired, saveLoginDetails, saveTenantList } from "./actions";
import Apis from "../../app/apis";

// tslint:disable-next-line:typedef
export function* fetchLoginTenant(action: AnyAction) {
    const payload = {
        ...action.payload,
    };
    yield put(isAPILoading(true));
    const response = yield call(Apis.getLoginDetails, payload);
    if (response.status === 200) {
        yield put(saveTenantList(response.data));
        const requestPayload = {
            id: payload.id,
            tenant: response.data.tenant_code
        };
        yield put(callToGetLoginDetails(requestPayload));
        yield put(isAPILoading(false));
        yield put(isSessionExpired(false));
    } else {
        yield put(isAPILoading(false));
        yield put(isSessionExpired(true));
    }
    return response;
}

// tslint:disable-next-line: typedef
export function* fetchLoginDetails(action: AnyAction) {
    const payload = {
        ...action.payload,
    };
    yield put(isAPILoading(true));
    const response = yield call(Apis.getLoginDetails, payload);
    if (response.status === 200) {
        yield put(saveLoginDetails(response.data));
        localStorage.setItem("userDetails", JSON.stringify(response.data));
        yield put(isAPILoading(false));
        yield put(isSessionExpired(false));
    } else {
        yield put(isSessionExpired(true));
        yield put(isAPILoading(false));
    }
    return response;
}

// tslint:disable-next-line: typedef
export function* watchGetTenant() {
    yield takeEvery(ActionTypes.CALL_TO_GET_TENANT_LIST, fetchLoginTenant);
}

// tslint:disable-next-line: typedef
export function* watchGetLoginDetails() {
    yield takeEvery(ActionTypes.CALL_TO_GET_LOGIN_DETAILS, fetchLoginDetails);
}
