import React from "react";
import styled, { css } from "styled-components";

export interface ButtonPropsInterface {
    label: string;
    disabled?: boolean;
    // tslint:disable-next-line:no-any
    styles?: any;
    onClickHandler?(): void;
}

export const StyledButton = styled.button`
    color: #fff;
    height: 2.5rem;
    font-size: 0.875rem;
    font-weight: 700;
    text-decoration: none;
    background: ${(props: { disabled?: boolean }) => (props.disabled ? "#afafaf" : "linear-gradient(270deg, #f8ad49 0, #f1744c 100%)")};
    border: none;
    border-radius: 4px;
    transition: 0.6s;
    cursor: ${(props: { disabled?: boolean }) => (props.disabled ? "not-allowed" : "pointer")};
    padding: 0 1.25rem;
    ${(props: { disabled?: boolean }) =>
        !props.disabled &&
        css`
            &:hover {
                background: linear-gradient(270deg, #f8ad49 0, #f1744c 100%);
                box-shadow: 0 10px 20px 0 rgb(0 0 0 / 16%);
                border: 0;
            }
        `}
`;

const ButtonOrange: React.FC<ButtonPropsInterface> = ({ label, styles = {}, disabled = false, onClickHandler }) => {
    const { Button = StyledButton } = styles;
    return (
        <Button id={"button_component"} disabled={disabled} {...(!disabled && onClickHandler && { onClick: onClickHandler })}>
            {label}
        </Button>
    );
};

export default ButtonOrange;
