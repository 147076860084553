import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */
export const actionTypes = {
    CALL_TO_CLEAR_SERP_DATA: "CALL_TO_CLEAR_SERP_DATA_BETA",

    CALL_TO_TRACK_REQUEST_ID: "CALL_TO_TRACK_REQUEST_ID",
    SET_TRACK_REQUEST: "SET_TRACK_REQUEST_BETA",

    CALL_TO_GET_SERP_DATA: "CALL_TO_GET_SERP_DATA_BETA",
    SET_SERP_DATA: "SET_SERP_DATA",

};
export default actionTypes;

export const callToGetTrackRequest = createAction(
    actionTypes.CALL_TO_TRACK_REQUEST_ID
);
export const setTrackRequestBeta = createAction(actionTypes.SET_TRACK_REQUEST);

export const callToGetSerpData = createAction(
    actionTypes.CALL_TO_GET_SERP_DATA
);
export const setSerpData = createAction(actionTypes.SET_SERP_DATA);
export const callToClearSerpDataBeta = createAction(actionTypes.CALL_TO_CLEAR_SERP_DATA);
