import { History } from "history";
import {
    CategoryType,
    ImportanceLevelType,
    ContextReducerInterface,
    InitiateRequestForRequestIdRequestInterface,
    KeywordRequestIdsInterface,
    MultiKeywordTrackRequestApiRequestIdsResponseInterface
} from "../../../app/duck/types";
import { OptionTypeInterface } from "../../../app/styledComponents/drop-down/types";

/** enums for kwa page */

export enum SelectedUrlType {
    TARGET = "target",
    COMPATITOR = "compatitor"
}

/** enums ends */

/**
 * Interface for API Payload Request
 */

export interface GetSerpDataDownloadRequestInterface {
    keyword: string;
    locale: string;
    request_id?: string;
    search_term?: string;
    device_type?: string;
    project_id?: number;

    search_url?: string;
    isBeta?: boolean;
    tenant_code?: string | null;
}
export interface KeywordUrlDownloadDataRequestInterface extends GetSerpDataDownloadRequestInterface {
    urls?: string[] | string;
    isBeta?: boolean;
}
export interface KeyWordUrlPayloadInterface {
    domain?: string;
    keyword: string;
    locale: string;
    isBeta?: boolean;
    request_id?: string;
    project_id?: number;
}
export interface KeyWordUrlRequestPayloadInterface extends KeyWordUrlPayloadInterface {
    request_id: string;
}
export interface KeyWordUrlScoresPayloadInterface {
    keyword: string;
    urls: string[];
    locale: string;
    isBeta?: boolean;
    request_id?: string;
    project_id?: number;
}
export interface KeyWordUrlScoresRequestPayloadInterface extends KeyWordUrlScoresPayloadInterface {
    request_id?: string;
    project_id?: number;
}

export interface KeyWordAverageScoreParamInterface {
    keyword: string;
    search_engine?: string;
    locale?: string;
    device_type?: string;
    request_id?: string;
    project_id?: number;
}

export interface GetAllTopUrlParamInterface {
    keyword: string;
    locale: string;
    request_id?: string;
    project_id?: number;
    domain?: string;
    isBeta?: boolean;
    urlList?: CompatitorTopTenUrlList[];
}
/**
 * Interface for API Payload Response
 */
export interface KeyWordScoreParameterInterface {
    parameter: string;
    param_name_db: string;
    has_positive_correlation: boolean;
    category: CategoryType | string;
    importance: ImportanceLevelType;
    param_sub_category: string;
    param_disp_name: string;
    param_info: string;
    Correlation: string;
    is_average_compatible: boolean;
    value: number | boolean | null;
    scale?: string;
    isOpen?: boolean;
    has_details?: boolean;
    has_content?: boolean;
    details_value?: string[] | null;
    gap_value: number | null;
    unit: string | null;
}
export interface SelectedSubCategoryWithParamInterface extends KeyWordScoreParameterInterface {
    associatedParameter: KeyWordScoreParameterInterface[];
    count?: number;
}

export interface KeyWordUrlScoresResponseInterface {
    score: [];
}

/** Api Payload ends */

/** Interface for API Response */
export interface KeyWordSerpResponseInterface {
    serp_date: string;
    serp: SerpResponseInterface[];
    search_volume?: number;
    themes?: string[];
    keyword: string;
    keyword_packs: string[];
    domain?: string;
    is_branded?: string;
    project_id?: number;
}
export interface SerpResponseInterface {
    ctr: number;
    pack_type: string;
    url: string;
    organic_rank: number;
    blended_rank: number;
    traffic: number;
    is_organic: boolean;
    is_my_domain: boolean;
    status: string;
}

export interface GetAllTopUrlResponseInterface {
    rank: number;
    url: string | null;
    alps_score: number;
    is_target: boolean;
    domain: string | null;
    content_score: number | null;
    technical_score: number | null;
    authority_score: number | null;
}
/** Interface for api response ends */
/** Interface for reducer
 *
 * Interface for Reducer should extends that for Props, its dangerous to create seperate interfaces for reducer and props
 */

export interface KeyWordAnalysisReducerInterface {
    appliedFilters: string[];
    appliedSorting: string;
    keyword: string;
    target: string;
    targetParam: KeyWordScoreParameterInterface[];
    targetScores: ScoresInterface[];
    selectedCategory: CategoryType;
    compatitor: string;
    compatitorParam: KeyWordScoreParameterInterface[];
    compatitorScores: ScoresInterface[];
    category?: CategoryInterface;
    domain?: string;
    isDownloadRequestInPending: boolean;
    locale: string;
    request_id: string;
    search_volume?: number;
    serp_date: string;
    serp: SerpResponseInterface[];
    allTopTenCompatitors: GetAllTopUrlResponseInterface[];
    keyword_packs: string[];
    themes?: string[];
    brand_value?: string;
    parameterDetailContent?: string[] | null;
    parameterGapGroupScore: ParameterGroupScoreInterface[];
}
export interface KeyWordAnalysisPropsInterface {
    allTopTenCompatitors: GetAllTopUrlResponseInterface[];
    appliedFilters: string[];
    appliedSorting?: string;
    keyword: string;
    domain?: string;
    isDownloadRequestInPending: boolean;
    compatitor: string;
    target: string;
    targetAlpsScore?: number;
    targetScores: ScoresInterface[];
    targetParams: KeyWordScoreParameterInterface[];
    selectedParamSubCategories: SelectedSubCategoryWithParamInterface[];
    serp: SerpResponseInterface[];
    serpDate: string;
    selectedCategory: CategoryType;
    compatitorAlpsScore?: number;
    compatitorParams: KeyWordScoreParameterInterface[];
    compatitorScores: ScoresInterface[];
    locale: string;
    request_id?: string;
    keywordPacks: string[];
    highestRank: number;
    searchVolume?: number;
    topUrl: number | string;
    traffic: number;
    ctr: number;
    isEmbedded?: boolean;
    action: KeyWordAnalysisActionsInterface;
    context: ContextReducerInterface;
    themes?: string[];
    brand_value?: string;
    parameterDetailContent?: string[] | null;
    parameterGapGroupScore: ParameterGroupScoreInterface[];
    serpProcessedStatus?: string;
    history?: History;
    targetUrls: OptionTypeInterface[];
    urlList?: OptionTypeInterface[];
    competiotorUrls: OptionTypeInterface[];
    requestId?: string;
}
export interface AverageScoreInterface {
    [key: string]: number;
}
interface CategoryInterface {
    type: CategoryType;
    targetScore: number;
    compatitorScore: number;
    averageScore: number;
    subCategory?: KeyWordScoreParameterInterface;
}
export interface CollectedParamScoreInterface {
    targetParam?: KeyWordScoreParameterInterface[];
    targetScore?: ScoresInterface[];

    compatitorParam?: KeyWordScoreParameterInterface[];
    compatitorScore?: ScoresInterface[];
}
export interface SelectedUrlTypeInterface {
    type: SelectedUrlType;
    value: string;
    compatitor?: string;
    target?: string;
}
export interface UrlChangePayloadInterface extends KeyWordUrlScoresPayloadInterface {
    type: SelectedUrlType;
    value: string;
    compatitor?: string;
    target?: string;
}

// export interface UrlDetailsInterface {
//     parameters: KeyWordScoreParameterInterface[];
//     scores: ScoresInterface[];
// }
export interface ScoresInterface {
    name: string;
    type: CategoryType;
    displayText: string;
    value: number;
}

export interface KeyWordAnalysisActionsInterface {
    unsetProjectId(): void;
    applyFilters(args: string[]): void;
    applySorting(args: string | number): void;
    getKeyWordUrl(args: KeyWordUrlPayloadInterface): void;
    getTopOrganicUrl(args: GetAllTopUrlParamInterface): void;
    getSerpReports(args: GetSerpDataDownloadRequestInterface): void;
    getKeyWordUrlReports(args: KeywordUrlDownloadDataRequestInterface): void;
    initRequestForId(args: InitiateRequestForRequestIdRequestInterface): void;
    onCategoryChanged(args: CategoryType): void;
    onInputChange(args: string): void;
    resetSlice(): void;
    setLocale(args: string): void;
    setSelectedUrl(args: SelectedUrlTypeInterface): void;
    setSearchedKeyWord(args: string): void;
    setDomain(args?: string): void;
    getParameterDetail(args: BodyContentRequestPayload): void;
    setUrl(url: string): void;
    // setUrl(args: SelectedUrlTypeInterface): void;
    getUrlScore(args: KeyWordUrlScoresListRequestPayloadInterface): void;
    setActiveRouteUrl(url: string): void;
    setSelectedTargetUrl(url: string): void;
    setIsEnableRunSimulation(isEnabled: boolean): void;
    setPartialKeywordFetched(args?: string): void;
    setKeywordRequestIds(requestIds: KeywordRequestIdsInterface): void;
    setSelectedKeywords(args?: string[]): void;
    setKeywordProcessedInfo(args?: MultiKeywordTrackRequestApiRequestIdsResponseInterface): void;
}

export interface KeyWordAnalysisStateInterface {
    selectedCategoryScore: CategoryScorePercentInterface;
    viewTopCompatitorIsOpen: boolean;
    viewParameterDetailIsOpen: boolean;
    parameterDetailLabel: string;
    parameterDetails: string[];
}

export interface CategoryScorePercentInterface {
    compatitor: number;
    target: number;
    // average: number;
}

export interface DefaultSelectedUrlTypesInterface {
    targetUrl: string;
    compatitorUrl: string;
}

interface CalculatedScoreInterface {
    actualScore: number | boolean | null;
    calculatedScore: number;
}
export interface ParameterScoreComparisionInterface {
    target: CalculatedScoreInterface;
    compatitor: CalculatedScoreInterface;
    unit: string | null;
    // average: CalculatedScoreInterface;
}
export interface ParameterDetailInterface {
    url: string;
    request_id?: string;
    project_id?: number;
}
export interface ParameterOnclickDetail {
    data?: KeyWordScoreParameterInterface;
    type?: string;
}
export interface ParameterGroupScoreInterface {
    parameter_group_gap: number | null;
    param_sub_category: string;
    value: number | null;
}
export interface CATScorePercentInterface {
    compatitor: number;
    target: number;
}

export interface KeyWordUrlScoresListPayloadInterface {
    keyword: string;
    urls: string[];
    locale: string;
    target?: string;
    compatitor?: string;
    request_id?: string;
    project_id?: number;
}
export interface KeyWordUrlScoresListRequestPayloadInterface extends KeyWordUrlScoresListPayloadInterface {
    request_id?: string;
    project_id?: number;
    isBeta?: boolean;
}
export interface CompatitorTopTenUrlList {
    rank: number;
    url: string;
}
export interface BodyContentRequestPayload {
    keyword: string;
    locale: string;
    url: string;
}
