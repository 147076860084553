import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import { concat, isEmpty } from "lodash";
import { AppReducerInterface } from "./types";
import actionTypes from "./actions";
import { SerpListProgressStatusType } from "../duck/types";
import { updateSerpList, updateSerpListWhenTrackStop } from "./utils";

export const INIT_STATE: AppReducerInterface = {
    clubbedUrl: {},
    competitor_urls: {},
    enableViewALPSScore: false,
    requestId: undefined,

    serpList: [],
    serpPacks: [],
    serpProcessedStatus: SerpListProgressStatusType.STARTED,
    allCompetitorStatus: SerpListProgressStatusType.STARTED,
    serpSearchVolume: undefined,
    serpTopRank: undefined,
    serp_date: "",
    target_urls: {},
    selectedTargetUrl: undefined,
    isProjectContext: false,
    isLoading: false,
    is_url_live: true,
    fileUpload_requestId: undefined,
    locales: [],
    fileName: undefined,
    insightOpenModalStatus: true
};

const Reducer = handleActions(
    {
        [actionTypes.SET_REQUEST_ID_IN_APP]: (state, action: AnyAction) => ({
            ...state,
            requestId: action.payload
        }),
        [actionTypes.SET_SERP_DATA_IN_APP]: (state, action: AnyAction) => ({
            ...state,
            ...action.payload
        }),
        [actionTypes.SET_ALL_COMPETITORS_STATUS]: (state, action: AnyAction) => ({
            ...state,
            allCompetitorStatus: action.payload
        }),
        [actionTypes.UPDATE_SERP_LIST_IN_APP]: (state, action: AnyAction) => {
            return {
                ...state,
                ...action.payload,
                clubbedUrl: action.payload.trackRequestdata,
                competitor_urls: action.payload.competitor_urls,
                serpList: concat([], updateSerpList(action.payload.trackRequestdata, state.serpList)),
                target_urls: action.payload.target_urls
            };
        },
        [actionTypes.SET_SERP_DETAILS_IN_APP]: (state, action: AnyAction) => {
            return {
                ...state,
                ...action.payload,
                serpList:
                    state.serpProcessedStatus === SerpListProgressStatusType.FAILED
                        ? concat([], updateSerpListWhenTrackStop(action.payload.serpList))
                        : concat([], updateSerpList(state.clubbedUrl, action.payload.serpList)),
                isProjectContext: action.payload.isProjectContext
            };
        },
        [actionTypes.SET_SERP_LIST_WHEN_TRACK_STOP]: (state, action: AnyAction) => {
            return {
                ...state,
                serpList: concat([], updateSerpListWhenTrackStop(state.serpList)),
                serpProcessedStatus: SerpListProgressStatusType.DONE
            };
        },
        [actionTypes.CALL_TO_CLEAR_APP]: (state, action: AnyAction) => ({
            ...INIT_STATE
        }),

        [actionTypes.SET_FLOWTYPE]: (state, action: AnyAction) => ({
            ...state,
            flowType: action.payload
        }),
        [actionTypes.SET_SELECTED_TARGET_URL]: (state, action: AnyAction) => ({
            ...state,
            selectedTargetUrl: action.payload
        }),
        [actionTypes.SET_SHOW_LOADER]: (state, action: AnyAction) => ({
            ...state,
            isLoading: true
        }),
        [actionTypes.SET_HIDE_LOADER]: (state, action: AnyAction) => ({
            ...state,
            isLoading: false
        }),
        [actionTypes.SET_IS_URL_LIVE]: (state, action: AnyAction) => ({
            ...state,
            is_url_live: action.payload === true ? true : false
        }),
        [actionTypes.SET_FILE_UPLOAD_REQUEST_ID]: (state, action: AnyAction) => ({
            ...state,
            fileUpload_requestId: isEmpty(action.payload) ? undefined : action.payload
        }),
        [actionTypes.SET_FILE_NAME]: (state, action: AnyAction) => ({
            ...state,
            fileName: isEmpty(action.payload) ? undefined : action.payload
        }),
        [actionTypes.SERP_PROGRESS_STATUS_FROM_HISTORY_TABLE]: (state, action: AnyAction) => ({
            ...state,
            serpProcessedStatus: SerpListProgressStatusType.DONE
        }),
        [actionTypes.SET_INSIGHT_OPEN_MODAL_STATUS]: (state, action: AnyAction) => ({
            ...state,
            insightOpenModalStatus: action.payload
        }),
        [actionTypes.CALL_TO_GET_LOCALES_LIST_APP]: (state, action: AnyAction) => ({
            ...state,
            locales: action.payload
        })
    },
    INIT_STATE
);

export default Reducer;
