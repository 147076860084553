import { handleActions } from "redux-actions";
import actionTypes from "./actions";
import { AnyAction } from "redux";
import { UserAccountReducerInterface } from "./types";


export const INIT_STATE: UserAccountReducerInterface = {
    userLogoutStatus: false,
    ssoUser: undefined,
    loggingIn: false,
    tenantList: undefined,
    multiTenant: false,
    errMsg: undefined,
    resetPwdApiStatusCode: undefined,
    PageNotFoundVisible: false,
    changePwdApiStatusCode: undefined,
    forgotPwdApiStatusCode: undefined,
    userId: undefined,
    cogClientID: undefined,
    cogAPI: undefined,
    intmdID: undefined
};

const Reducer = handleActions(
    {
        [actionTypes.USER_LOGOUT_STATUS]: (state, action: AnyAction) => ({
            ...state,
            userLogoutStatus: action.payload
        }),
        [actionTypes.SET_SSO_USER]: (state, action: AnyAction) => ({
            ...state,
            ssoUser: action.payload
        }),
        [actionTypes.SET_LOGGING_IN]: (state, action: AnyAction) => ({
            ...state,
            loggingIn: action.payload
        }),
        [actionTypes.SET_TENANT_LIST]: (state, action: AnyAction) => ({
            ...state,
            tenantList: action.payload
        }),
        [actionTypes.SET_MULTI_TENANT]: (state, action: AnyAction) => ({
            ...state,
            multiTenant: action.payload
        }),
        [actionTypes.SET_ERR_MSG]: (state, action: AnyAction) => ({
            ...state,
            errMsg: action.payload
        }),
        [actionTypes.RESET_PWD_STATUS_CODE]: (state, action: AnyAction) => ({
            ...state,
            resetPwdApiStatusCode: action.payload
        }),
        [actionTypes.SET_TO_PAGE_NOT_FOUND]: (state, action: AnyAction) => ({
            ...state,
            PageNotFoundVisible: action.payload
        }),
        [actionTypes.CHANGE_PWD_STATUS_CODE]: (state, action: AnyAction) => ({
            ...state,
            changePwdApiStatusCode: action.payload
        }),
        [actionTypes.FORGOT_PWD_STATUS_CODE]: (state, action: AnyAction) => ({
            ...state,
            forgotPwdApiStatusCode: action.payload
        }),
        [actionTypes.SET_USER_ID]: (state, action: AnyAction) => ({
            ...state,
            userId: action.payload
        }),
        [actionTypes.SET_COGNITO_CLIENT_ID]: (state, action: AnyAction) => ({
            ...state,
            cogClientID: action.payload
        }),
        [actionTypes.SET_COGNITO_API]: (state, action: AnyAction) => ({
            ...state,
            cogAPI: action.payload
        }),
        [actionTypes.SET_INTMD_ID]: (state, action: AnyAction) => ({
            ...state,
            intmdID: action.payload
        })
    }, INIT_STATE
);

export default Reducer;
