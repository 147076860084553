import { StyledRunSimulationButton } from "../../../beta/simulator/styles";
import styled from "styled-components";
import { domainDetailsModalLeftArrowIcon, menuArrowDownGray } from "../../../../src/app/icons";
import {
    StyledToastLinkWrapper,
    StyledToastMessageText,
    StyledToastContainer
} from "../../../app/styledComponents/toastCommon";

export const StyledUpgradeNowModal = styled.div`
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;
`;

export const StyledUpgradeNowContainer = styled.div`
    width: 82%;
    height: 84%;
    min-height: 580px;
    margin: 50px auto;
    border-radius: 10px;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;

    @media (max-width: 1440px) {
        width: 1000px;
    }
`;

export const StyledUpgradeModalHeader = styled.div`
    display: flex;
    cursor: pointer;
    user-select: none;
`;

export const StyledCloseUpgradeNowModal = styled.img`
    width: 24px;
    height: 24px;
    margin: 20px 12px 0 0;
    cursor: pointer;
`;

export const StyledSuccessPopUpWrapper = styled.div`
    width: 40%;
    height: 20%;
    margin: 250px auto;
    padding: 50px 40px;
    border-radius: 10px;
    border: solid 1px #707070;
    background-color: #fff;
    min-width: 770px;
    min-height: 200px;

    @media (max-width: 1440px) {
        margin: 180px auto;
    }
`;

export const StyledUpgradeNowExpiryModal = styled.div`
    background: rgba(0, 0, 0);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;
`;

export const StyledCongratulationsWrapper = styled.div`
    display: flex;
`;

export const StyledSuccessImage = styled.img`
    width: 41px;
    height: 41px;
    margin: 0 0 20px 0;
    object-fit: contain;
`;

export const StyledCongratulationText = styled.div`
    width: 192px;
    height: 31px;
    margin: 5px 421px 25px 10px;
    font-family: Lato;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #394961;
`;
export const StyledCongratulationParagraph = styled.div`
    width: 100%;
    margin: 10px 0 10px;
    font-family: Lato;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #394961;
`;

export const StyledCongratulationFooterText = styled.div`
    width: 100%;
    height: 25px;
    margin: 0 154px 0 0;
    font-family: Lato;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #394961;
`;

export const StyledLoginButton = styled(StyledCongratulationFooterText)`
    color: #f1744c;
    font-weight: 600;
    width: fit-content;
    text-decoration: underline;
    height: 25px;
    margin: 0;
    font-family: Lato;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
`;

export const StyledBoldText = styled.span`
    font-weight: 600;
`;

export const StyledHeaderWrapper = styled.div`
    display: flex;
    height: 10%;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    justify-content: center;
`;
export const StyledSeperator = styled.div`
    width: 100%;
    height: 1px;
    background-color: rgba(156, 164, 176, 0.48);
`;

export const StyledHeaderText = styled.span`
    height: 28px;
    margin: 4px 4px 4px 15%;
    font-family: Lato;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #394961;

    @media (max-width: 1440px) {
        margin: 4px 4px 4px 5%;
    }
`;

export const StyledHeaderAmtText = styled.div`
    width: fit-content;
    height: 23px;
    margin: 0 15% 0 10px;
    padding: 5px 19px 3px;
    border-radius: 18px;
    border: solid 2px #394961;
    background-color: #f3f7fd;

    @media (max-width: 1440px) {
        margin: 0 5% 0 10px;
    }
`;

export const StyledHeaderBody = styled.div`
    width: max-content;
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #394961;
`;

export const StyledIndividualPlanText = styled.span`
    height: 25px;
    margin: 0 18px 5px 30px;
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: #394961;
    float: left;
`;

export const StyledMonthText = styled.span`
    height: 33px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.21;
    letter-spacing: normal;
    text-align: left;
    color: #394961;
`;

export const StyledAmountText = styled.span`
    height: 33px;
    margin: 0 3px 0 30px;
    font-family: Lato;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
    color: #394961;
`;

export const StyledUserImg = styled.img`
    width: auto;
    height: 30px;
    margin: 5px 15px 0 20%;
    object-fit: contain;
    float: right;
`;

export const StyledOptimizeText = styled.div`
    height: 17px;
    margin: 0 0 6px 30px;
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    text-align: left;
    color: #9ca4b0;
`;

export const StyledAmountWrapper = styled.div`
    display: flex;
`;

export const StyledIPWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledAccordionWrapper = styled.div`
    padding: 7px 0 7px 7%;
    border-bottom: 1px solid #d7e7f8;
    @media (max-width: 1440px) {
        padding: 0 0 0 7%;
    }
`;

export const StyledSubAccordionWrapper = styled.div`
    display: flex;
    padding: 2px 0 2px 10%;
    height: 15px;
    &:last-child {
        margin-bottom: 10px;
    }
`;

export const StyledAccordionHeadingText = styled.span`
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.36;
    letter-spacing: normal;
    text-align: left;
    color: #394961;
    background: url(${(props: { arrowDown: boolean }) => (props.arrowDown ? menuArrowDownGray : domainDetailsModalLeftArrowIcon)}) no-repeat
        0px 4px;
    padding-left: 25px;
    cursor: pointer;
    user-select: none;
`;

export const StyledHorizontalArrowImg = styled.img`
    width: 16px;
    height: 16px;
    margin: 9px 8px 0 0;
    object-fit: contain;
    cursor: pointer;
`;

export const StyledDownArrowImg = styled.img`
    width: 12px;
    height: 12px;
    margin: 2px 5px 0 0;
    object-fit: contain;
`;

export const StyledAccordionSubHeadingText = styled.span`
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #394961;
    margin-bottom: 10px;
`;

export const StyledLeftPanelButton = styled.div`
    margin: 2% 0 2% 30px;

    @media (max-width: 1440px) {
        margin: 0 0 0 30px;
    }
`;

export const StyledBodyWrapper = styled.div`
    width: 100%;
    height: 87%;
    display: flex;
`;

export const StyledLeftPanel = styled.div`
    position: relative;
    width: 30%;
    height: 90%;
    margin: 30px 31px 16px 20px;
    padding: 10px 0 10px;
    object-fit: contain;
    border-radius: 0 20px;
    box-shadow: 0 3px 15px 0 rgba(28, 44, 67, 0.2);
    background-color: #fff;
`;

export const StyledPayNowButton = styled(StyledRunSimulationButton)`
    width: 92%;
    height: 40px;
    margin: 10px 10px 0 0;
    padding: 2px 40px 3px;
    font-weight: 400px;
`;

export const StyledNoThanksButton = styled.button`
    width: 92%;
    height: 40px;
    margin: 10px 23px 0 0;
    border: solid 1px #fb764b;
    border-radius: 3px;
    padding: 0px 20px;
    background-color: #fff;
    cursor: pointer;
`;

export const StyledNoThanksLabel = styled.span`
    color: #fb764b;
`;

export const StyledButtonWrapper = styled.div`
    height: 110px;
`;

export const StyledFAQHeading = styled.div`
    width: 46px;
    height: 25px;
    margin: 31px 280px 20px 45%;
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: center;
    color: #394961;
`;
export const StyledFAQBox = styled.div`
    width: 90%;
    height: fit-content;
    margin: 0 0 5% 0;
    border-radius: 0 10px;
    border: solid 1px #d3dfee;
    background-color: #fff;
`;

export const StyledQuestion = styled.div`
    width: 100%;
    height: fit-content;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #2363ac;
    padding: 18px 0 18px 21px;
`;

export const StyledAnswer = styled.div`
    width: 90%;
    height: 100%;
    margin: 10px 5px 10px 20px;
    font-family: Lato;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #838793;
`;

export const StyledFAQPanel = styled.div`
    width: 50%;
`;

export const StyledDropDownImg = styled.img`
    width: 10px;
    height: 10px;
    margin: 23px 20px 0 5px;
    object-fit: contain;
`;

export const StyledFooterText = styled.div`
    height: 25px;
    margin: 20px 20px 20px 0;
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: center;
    color: #394961;

    @media (max-width: 1440px) {
        margin: 20px 0 20px 0;
    }
`;

export const StyledImg1 = styled.img`
    width: 144px;
    height: 30px;
    margin: 0 6% 0 10%;
    object-fit: contain;
    float: left;

    @media (max-width: 1440px) {
        width: 130;
    }
`;
export const StyledImg2 = styled.img`
    width: 163px;
    height: 30px;
    margin: 0 6% 0 6%;
    object-fit: contain;

    @media (max-width: 1440px) {
        width: 150px;
    }
`;
export const StyledImg3 = styled.img`
    width: 118px;
    height: 30px;
    margin: 0 10% 0 6%;
    object-fit: contain;
    float: right;

    @media (max-width: 1440px) {
        width: 110px;
    }
`;
export const StyledImg4 = styled.img`
    width: 70px;
    height: 34px;
    margin: 5px 18% 5px 25%;
    object-fit: contain;
    float: left;
`;
export const StyledImg5 = styled.img`
    width: 221px;
    height: 36px;
    margin: 0 20% 3px 0;
    object-fit: contain;
    float: right;
`;

export const StyledFooterWrapper = styled.div`
    height: 25%;
    width: 100%;

    @media (max-width: 1440px) {
        width: 550px;
    }
`;

export const StyledImgWrapper = styled.div`
    display: flex;
    margin-top: 10px;
`;

export const StyledFeatureWrapper = styled.div`
    width: 100%;
    height: calc(100% - 214px);
    overflow-y: auto;
    @media (max-width: 1440px) {
        height: calc(100% - 194px);
    }
`;
export const StyledLeftPanelWrapper = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 10px;
    box-shadow: 0px -3px 3px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
`;

export const StyledPayNowText = styled.div`
    font-weight: 600;
    font-size: 14px;
    @media (max-width: 1440px) {
        font-size: 12px;
    }
`;

export const StyledFontSize = styled.div`
    font-size: 10px;

    @media (max-width: 1440px) {
        font-size: 8px;
    }
`;

export const StyledRightPanel = styled.div`
    height: 100%;
    overflow-y: scroll;
    width: 65%;
    position: relative;
`;

export const StyledSubAccordianContainer = styled.div``;

export const StyledIndividualPlanWrapper = styled.div`
    border-bottom: 1px solid #d7e7f8;
`;

export const StyledTestimonialWrapper = styled.div`
    margin: 40px 44px 20px 44px;

    @media (max-width: 1440px) {
        margin-top: 50px;
    }
`;

export const StyledTestimonialHeader = styled.div`
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: center;
    color: #394961;
`;

export const StyledCarouselImgContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 30px auto 20px auto;
    height: 33px;
`;

export const StyledCarouselFooter = styled.div`
    display: flex;
    justify-content: center;
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #9ca4b0;
    margin-top: 10px;
`;

export const StyledCarouselText = styled.div`
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    margin: 20px;
    width: auto;
    color: #394961;
    min-height: 90px;

    @media (max-width: 1280px) {
        min-height: 150px;
    }
`;

export const StyledCarouselWrapper = styled.div`
    display: flex;
`;

export const StyledCarouselArrowLeft = styled.img`
    cursor: ${(props: { disable: boolean }) => `${props.disable ? "auto" : "pointer"}`};
`;

export const StyledCarouselArrowRight = styled.img`
    cursor: ${(props: { disable: boolean }) => `${props.disable ? "auto" : "pointer"}`};
`;

export const StyledTrialExpirationMsgContainer = styled(StyledToastContainer)`
    margin: 7px 0;
    width: fit-content;
    padding-right: 15px;
    background: #fff;
`;

export const StyledTrialExpirationLink = styled(StyledToastLinkWrapper)`
    color: #fb764b;
`;

export const StyledTrialExpirationMessage = styled(StyledToastMessageText)`
    float: none;
`;
