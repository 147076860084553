import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { isEmpty, isNull, pick, isUndefined } from "lodash";
import reducers from "./duck/combinedReducer";
import rootSaga from "./duck/rootSaga";
import { getMetaData } from "./duck/utils";
import { ContextReducerInterface, ApplicationStateInterface } from "./duck/types";

const whitelistSlice = ["context", "app", "projectSetup"];
const sagaMiddleware = createSagaMiddleware();

const metaData = getMetaData();

const persistStore = (state: ApplicationStateInterface) => {
    try {
        const serializedState = JSON.stringify(pick(state, whitelistSlice));
        sessionStorage.setItem("state", serializedState);
    } catch (error) {
        throw new Error("some problem in persisting state");
    }
};
const loadPersistedStore = () => {
    try {
        const serializedState = sessionStorage.getItem("state");
        if (!isNull(serializedState)) {
            const { context } = JSON.parse(serializedState);
            if (metaData.projectID === context.projectId && metaData.tenant_code === context.tenantCode) {
                const newStore = JSON.parse(serializedState);
                if (isUndefined(newStore.context.selectedLocale)) {
                    newStore.context.selectedLocale = "en-us";
                }
                return newStore;
            }
            sessionStorage.removeItem("state");
        }
        return undefined;
    } catch (error) {
        return undefined;
    }
};
let initialContext: { context: ContextReducerInterface } = {
    context: {}
};
if (!isEmpty(metaData)) {
    initialContext = {
        context: {
            projectId: metaData.projectID || metaData.default_project,
            projectName: metaData.project_name,
            selectedLocale: isUndefined(metaData.locale) || isNull(metaData.locale) ? "en-us" : metaData.locale,
            tenantCode: metaData.tenant_code,
            userId: metaData.user_id,
            user_email: metaData.user_email,
        }
    };
}
const persistedState = loadPersistedStore();
const initialState = isUndefined(persistedState) || isEmpty(persistedState.context) ? initialContext : persistedState;

const store = createStore(reducers, initialState, composeWithDevTools(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);
store.subscribe(() => persistStore(store.getState()));
export default store;
