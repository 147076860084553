import styled from "styled-components";
import { IS_DEV_SRC } from "../../app/const";
import { StyledInput } from "../../app/styledComponents/input";
import {
    downloadDisabled,
    downloadIcon,
    infoRed,
    menuArrowDownGray,
    paginationActiveRightIcon,
    paginationDeactivateLeftIcon,
    searchIcon
} from "../../app/icons";
import { StyledLabel, StyledLinkButton } from "../../app/styledComponents/button";
import { StyledPropsInterface } from "./ducks/types";

// tslint:disable-next-line: no-var-requires
const bannerImg = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/banner.png` : require(`${process.env.DEV_IMAGE_URL}/img/banner.png`);
/**
 *  Prefix = Styled
 *  Followed by = Section SubSection
 *                eg. StyledSectionSubSection
 */

export const StyledHomeWrapper = styled.div`
    margin-top: 50px;
    min-height: 93.5vh;
`;

/* BANNER STYLED CONSTANTS */

export const StyledBannerSection = styled.section`
    height: 270px;
    background: url(${bannerImg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const StyledBannerTitleContainer = styled.div`
    text-align: center;
    font-family: "Lato", sans-serif;
    padding: 40px 0px;
    padding-top: 50px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const StyledBannerTitle = styled.h3`
    color: #fff;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledBannerTitleHighlight = styled.span`
    color: #1fd8a6;
    font-weight: 600;
`;

export const StyledBannerSubTitleWrapper = styled.div`
    display: flex;
    margin-top: 30px;
`;

export const StyledBannerSubTitle = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    margin-left: 55px;
    &:first-child {
        margin-left: 0;
    }
`;

export const StyledBannerSubTitleKey = styled.span`
    font-size: 30px;
    padding-right: 10px;
    color: #1fd8a6;
`;

export const StyledBannerSubTitleValue = styled.span`
    position: relative;
    padding-left: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        height: calc(100% - 8px);
        border-left: 2px solid #1fd8a6;
    }
`;

export const StyledBannerSimulationSubTitle = styled.h3`
    font-size: 18px;
    color: #fff;
    text-align: center;
    margin-top: 15px;
`;

export const StyledBannerBreakTag = styled.br``;

export const StyledHeadingSearchboxContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const StyledHeadingLeft = styled.h1`
    font-size: 14px;
    font-weight: 700;
    color: #343434;
    padding-top: 7px;
`;
export const StyledInputBoxContainer = styled.div`
    display: flex;
    ${StyledInput} {
        width: 250px;
        background: url(${searchIcon}) no-repeat;
        background-position: right 10px center;
        padding-right: 30px;
    }
`;

export const StyledSimultaitonHistoryContainer = styled.div`
    width: ${(props: { fillWidth?: boolean }) => (props.fillWidth ? "1205px" : "1015px")};
    margin: auto;
    margin-top: 20px;
    margin-bottom: 15px;
`;

export const StyledTableMainContainer = styled.div`
    width: ${(props: { fillWidth?: boolean }) => (props.fillWidth ? "1205px" : "1015px")};
    margin: 10px auto;
    box-shadow: 0 0 5px 0 #dfe0e1;
`;

export const StyledTableHeader = styled.div`
    display: flex;
    height: 45px;
    background: #ebecf0;
    align-items: center;
`;
export const StyledTableCol1 = styled.div`
    width: 390px;
    padding-left: 20px;
    font-size: 12px;
    font-weight: 700;
`;

export const StyledTableCol2 = styled.div`
    width: 450px;
    font-size: 12px;
    font-weight: 700;
`;

export const StyledTableCol3 = styled.div`
    width: 223px;
    font-size: 12px;
    font-weight: 700;
`;

export const StyledTableCol4 = styled.div`
    width: 87px;
    font-size: 12px;
    font-weight: 700;
    box-sizing: border-box;
`;

export const StyledBodyAccordionContainer = styled.div``;

export const StyledSimulationHeaderAcc = styled.div`
    height: 35px;
    background: #fff;
    display: flex;
    align-items: center;
    cursor: default;
    /* background: #f4f3f3; */
    //
`;

interface StyledSimulationBodyAccInterface {
    openSelectedAccordian?: boolean;
    width?: number;
}

export const StyledSimulationBodyAcc = styled.div`
    /* background: yellow; */
    cursor: default;
    background: #f4f3f3;
    padding: 2px 0;
    display: ${(props: StyledSimulationBodyAccInterface) => (props.openSelectedAccordian ? "block" : "none")};
`;

export const StyledSimlationHeaderCol1 = styled.div`
    width: 410px;
    padding-left: 20px;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: initial;
    box-sizing: border-box;
    padding-right: 15px;
`;

export const StyledSimlationHeaderCol2 = styled.div`
    width: 450px;
    font-size: 12px;
    box-sizing: border-box;
    line-height: 16px;
    display: flex;
`;

export const StyledSimlationHeaderCol3 = styled.div`
    width: 220px;
    font-size: 12px;
`;

export const StyledSimlationHeaderCol4 = styled.div`
    width: 37px;
`;

export const StyledURLTable = styled.span`
    ${StyledLinkButton} {
        padding: 0px;
    }
    ${StyledLabel} {
        text-decoration: none;
        color: #025d92;
        padding: 0px;
    }
`;

export const StyledViewURLTable = styled.span``;

export const StyledTableChevronContainer = styled.i`
    background: url(${menuArrowDownGray}) no-repeat;
    background-size: 16px;
    height: 9px;
    width: 16px;
    cursor: pointer;
    background-position: 0 -2px;

    &.keyword-accordion-icon {
        display: block;
        margin-left: 45px;
    }
    &.active {
        transform: rotateX(180deg);
    }
    &.simulation_history_Acc {
        margin-left: 10px;
    }
`;

export const StyledTableBody = styled.div`
    /* max-height: 350px;
    overflow: initial; */
    &.active {
        ${StyledSimulationHeaderAcc} {
            background: #f4f3f3;
            border-bottom: none;
        }
        ${StyledTableChevronContainer} {
            background: url(${menuArrowDownGray}) no-repeat;
            background-size: 16px;
            height: 9px;
            width: 16px;
            cursor: pointer;
            transform: rotate(180deg);
            background-position: 0 -4px;
        }
    }
`;

export const StyledBodyTopContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 17px;
`;

export const StyledBodyCol1 = styled.div`
    width: 410px;
    padding-left: 20px;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing: border-box;
    padding-right: 15px;
`;

export const StyledBodyLabelContainer = styled.span`
    font-weight: 600;
    color: #343434;
    line-height: 16px;
    font-size: 12px;
    display: block;
`;

export const StyledBodyContentContainer = styled.span`
    font-size: 12px;
    line-height: 16px;
`;

export const StyledBodyCol2 = styled.div`
    width: 450px;
    font-size: 12px;
    box-sizing: border-box;
    display: flex;
`;

export const StyledBodyCol2ContentContainer = styled.div`
    margin-right: 33px;
`;

export const StyledBodyCol2ModifiedContainer = styled.div`
    width: 250px;
`;
export const StyledBodyNameContainer = styled.span`
    width: 240px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing: border-box;
    padding-right: 15px;
    font-size: 12px;
    line-height: 16px;
    display: block;
`;
export const StyledBodyCol3 = styled.div`
    /* width: 230px;
    overflow: hidden;
    height: 17px; */
`;

export const StyledDescriptionMainContainer = styled.div`
    display: block;
    padding: 0 20px;
`;
export const StyledLabelContainer = styled.div`
    display: flex;
    width: 55px;
    color: #343434;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
`;

export const StyledContentContainer = styled.div`
    width: calc(100% - 55px);
    padding-right: 15px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 20px;
`;
export const StyledScrollableContainer = styled.div`
    height: auto;
`;

export const StyledPaginationContainer = styled.div`
    background: #fff;
    height: 45px;
    display: flex;
`;

export const StyledLeftPaginationContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0 20px;
`;

export const StyledPageNumerContainer = styled.div`
    font-size: 12px;
    color: #343434;
    font-weight: 500;
`;

export const StyledLeftDeactivatedArrow = styled.i`
    background: url(${paginationDeactivateLeftIcon}) no-repeat;
    width: 11px;
    height: 16px;
    background-size: 16px;
    background-position: center;
    margin-left: 10px;
    cursor: pointer;
    pointer-events: ${(props: StyledLeftActivatedArrowInterface) =>
        props.prev_page == null || props.paginationLoading ? "none" : "initial"};
`;
export const StyledRightDeactivatedArrow = styled.i`
    background: url(${paginationDeactivateLeftIcon}) no-repeat;
    width: 11px;
    height: 16px;
    background-size: 16px;
    background-position: center;
    transform: rotate(180deg);
    pointer-events: ${(props: StyledRightActivatedArrowInterface) =>
        props.next_page == null || props.paginationLoading ? "none" : "initial"};
`;
export const StyledPaginationNumber = styled.div`
    font-size: 12px;
    font-weight: 500;
    margin: 0 15px;
    cursor: pointer;
`;

interface StyledRightActivatedArrowInterface {
    next_page?: number | null;
    paginationLoading?: boolean;
}

export const StyledRightActivatedArrow = styled.i`
    background: url(${paginationActiveRightIcon}) no-repeat;
    width: 11px;
    height: 16px;
    background-size: 16px;
    background-position: center;
    margin-left: 0px;
    cursor: pointer;
    pointer-events: ${(props: StyledRightActivatedArrowInterface) =>
        props.next_page == null || props.paginationLoading ? "none" : "initial"};
`;

interface StyledLeftActivatedArrowInterface {
    prev_page?: number | null;
    paginationLoading?: boolean;
}

export const StyledLeftActivatedArrow = styled.i`
    background: url(${paginationActiveRightIcon}) no-repeat;
    width: 11px;
    height: 16px;
    background-size: 16px;
    background-position: center;
    margin-left: 0px;
    cursor: pointer;
    transform: rotate(180deg);
    margin-left: 10px;
    pointer-events: ${(props: StyledLeftActivatedArrowInterface) =>
        props.prev_page == null || props.paginationLoading ? "none" : "initial"};
`;

export const StyledPaginationAllNumberContainer = styled.div`
    font-size: 12px;
    color: #838793;
    margin-left: 20px;
`;

export const StyledValue = styled.div`
    position: relative;
    z-index: 9;
    font-size: 12px;
    color: #333;
    left: 0px;
    white-space: nowrap;
    overflow: initial;
    text-overflow: ellipsis;
    top: 0px;
`;

export const StyledNoSavedSimulations = styled.div`
    height: 20px;
    margin: 38px 0px 0;
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: #838693;
    padding: 0 14px 48px;
`;

export const StyledNoMatchResult = styled.div`
    word-break: break-all;
`;

export const StyledTableCol300 = styled.div`
    width: ${(props: { width?: string }) => (props.width ? props.width : "300px")};
    height: 15px;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #343434;
    padding-left: 20px;
`;

export const StyledStatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #53d68a;
`;

export const StyledTblItem = styled.div`
    width: 300px;
    font-size: 12px;
    margin: 10px 0 6px 20px;
    color: #343434;
`;

export const StyleTblRow = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e2e2e2;
    cursor: default;
    &:hover {
        box-shadow: 0 0 8px 0 #d1d6e3;
    }
`;

export const StyledTblText = styled.p`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 235px;
    padding-bottom: 1px;
`;

export const StyledFailedStatus = styled.div`
    margin-right: 25px;
    margin-left: 5px;
    color: #fb764b;
    text-transform: capitalize;
`;

export const StyledReTryButton = styled.div`
    color: #343434;
`;

export const StyledPaginationLoading = styled.div`
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const StyledProgressBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
`;

export const StyledDownloadLink = styled.a`
    pointer-events: ${(props: StyledPropsInterface) => (props.disabled ? "none" : "auto")};
    cursor: ${(props: StyledPropsInterface) => (props.disabled ? "not-allowed" : "pointer")};
`;

export const StyledDownloadIcon = styled.div`
    background: url(${(props: StyledPropsInterface) => (props.disabled ? downloadDisabled : downloadIcon)}) no-repeat;
    width: 16px;
    height: 16px;
    background-size: cover;
    border: none;
    object-fit: contain;
    margin: 0px 20px 0px;
`;

export const StyledProcessedStatusLabel = styled.span`
    margin-right: 22px;
`;

export const StyledViewURLButtonContainer = styled.div`
    margin-left: 19px;
`;

export const StyledFailedStatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const StyledInfoIcon = styled.i`
    background: url(${infoRed}) no-repeat;
    float: left;
    width: 15px;
    height: 15px;
    flex-shrink: 0;
`;

export const StyledTblActions = styled.div`
    width: 218px;
    font-size: 12px;
    margin: 10px 0 6px 20px;
    color: #343434;
`;

export const StyledSimlationHeaderCol5 = styled.div`
    width: 360px;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: hidden;
    overflow: hidden;
    box-sizing: border-box;
    padding-right: 15px;
    line-height: 16px;
`;
export const StyledSimlationHeaderMoreButtonDiv = styled.div`
    width: 37px;
    line-height: 16px;
    margin-top: -3px;
    margin-left: 5px;
`;

// !more button modal-------->>

export const StyledMoreKeyWordModal = styled.div`
    width: 250px;
    max-height: 236px;
    margin: 0 0 0 1px;
    padding: 1px 10px 0px;
    border-radius: 3px;
    box-shadow: 0 0 11px 0 #b1b4be;
    background-color: #fff;
    position: absolute;
    z-index: 9;
    right: -5px;
    top: 22px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const StyledMoreKeyWordModalHeader = styled.div`
    height: 15px;
    display: flex;
    justify-content: space-between;
`;

export const StyledMoreKeyWordModalLabel = styled.span`
    color: #343434;
    width: 55px;
    height: 15px;
    margin: 0 0px 11px 0;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
`;
export const StyledGradientButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    position: relative;
    margin: -5px 30px 10px 10px;
    border-radius: 5px;
    background-image: linear-gradient(to left, #f8ad49, #f1744c);
    box-shadow: 0 2px 4px 0 rgba(69, 67, 67, 0.5);
    border: none;
    &.popup {
        margin: 0;
    }
`;
export const StyledGradientButtonText = styled.span`
    width: 81px;
    height: 15px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ebecf0;
`;

export const StyledMoreKeyWordModalLabelLength = styled.span`
    width: 21px;
    height: 15px;
    margin: 0 0px 11px -10px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #838793;
`;
export const StyledMoreKeyWordModalLabelListItem = styled.span`
    font-weight: 400;
    font-family: Lato;
    font-size: 12px;
    color: #343434;
    display: block;
    padding-left: 5px;
    width: 200px;
`;

export const StyledCrossButton = styled.button`
    background: none;
    border: none;
    width: 16px;
    height: 16px;
    margin: 2px 0 10px 120px;
    object-fit: contain;
`;

export const StyledUL = styled.ul`
    width: 245px;
    list-style-type: initial;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #cdcdcd #fff;
    margin: 8px 0px 0px -10px;
    padding: 0px 0px 0px 5px;
    font-family: Lato;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: normal;
    color: #343434;
    border-top: solid 1px #ebecf0;
`;

export const StyledLI = styled.li`
    margin: 0px 15px 5px 15px;
`;

// !----------Request-demo-popup--->>>
export const StyledReqOverlay = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const StyledReqContainer = styled.div`
    width: 600px;
    height: 460px;
    /* margin: 170px 0 0 695px; */
    padding: 0 0 50px;
    border-radius: 3px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    background-color: #fff;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.error-popup {
        width: 450px;
        height: 260px;
    }
`;
export const StyledReqHeader = styled.div`
    width: 96%;
    margin-top: 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    &.error-popup {
        justify-content: flex-end;
    }
`;
export const StyledReqAlpsIcon = styled.img`
    width: 100px;
    height: 28px;
`;
export const StyledReqUnlock = styled.img`
    display: block;
    width: 144px;
    height: 131px;
    border: none;
    margin-top: 10%;
    margin-bottom: 6%;
`;
export const StyledReqButton = styled.a`
    background: none;
    border: none;
`;
export const StyledReqDIVBtn = styled.div`
    &.error-popup {
        margin-top: 20px;
    }
`;
export const StyledReqTitle = styled.span`
    height: 19px;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f6a8d;
    padding-top: 10px;
    display: inline-block;
    text-transform: uppercase;
    &.error-popup {
        font-size: 24px;
        margin-bottom: 30px;
    }
`;
export const StyledReqBody = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;
export const StyledReqUL = styled.ul`
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: left;
    margin-left: 42px;
    list-style: initial;
`;
export const StyledReqLi = styled.li`
    margin: 10px;
`;
export const StyledErrorMsg = styled.p``;
