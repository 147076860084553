import * as React from "react";
import { map, kebabCase } from "lodash";
import styled from "styled-components";
import { StyledComponentStylesType } from "../duck/types";
import { success, warning, info, cross, closeBlack } from "../icons";
import Button, { ButtonPropsInterface } from "./button";

export enum ALERT_STATUS_TYPE {
    INFO = "INFO",
    SUCCESS = "SUCCESS",
    WARNING = "WARNING",
    ERROR = "ERROR"
}

export interface AlertStylesPropInterface {
    Overlay?: StyledComponentStylesType;
    Alert?: StyledComponentStylesType;
    Close?: StyledComponentStylesType;
    Status?: StyledComponentStylesType;
    Title?: StyledComponentStylesType;
    Message?: StyledComponentStylesType;
    Footer?: StyledComponentStylesType;
}

export interface AlertPropsInterface {
    title: string | JSX.Element;
    title2?: string | JSX.Element;
    status?: ALERT_STATUS_TYPE;
    message?: string;
    body?: JSX.Element;
    footer?: ButtonPropsInterface[] | JSX.Element;
    styles?: AlertStylesPropInterface;
    instanceId?: string;
    isMidSizePopup?: boolean;
    onCloseHandler(): void;
}

export const StyledOverlay = styled.div`
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
`;

export const StyledAlert = styled.div`
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: ${(props: { isMidSizePopup: boolean }) => (props.isMidSizePopup ? "405px" : "180px")};
    border-radius: 3px;
    box-shadow: 0 1px 7px 0 #a3a3a3;
    padding: 30px;
    text-align: center;
    width: ${(props: { isMidSizePopup: boolean }) => (props.isMidSizePopup ? "525px" : "550px")};
    margin: auto;
    box-sizing: border-box;
`;

export const StyledClose = styled.div`
    background: url(${closeBlack}) no-repeat;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-position: center;
    cursor: pointer;
`;

export const StyledStatus = styled.i`
    display: inline-block;
    height: 15px;
    width: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: ${(props: AlertPropsInterface) => {
        if (props.status === ALERT_STATUS_TYPE.INFO) {
            return `url(${info})`;
        }
        if (props.status === ALERT_STATUS_TYPE.SUCCESS) {
            return `url(${success})`;
        }
        if (props.status === ALERT_STATUS_TYPE.WARNING) {
            return `url(${warning})`;
        }
        if (props.status === ALERT_STATUS_TYPE.ERROR) {
            return `url(${cross})`;
        }
        return "";
    }};
`;

export const StyledTitle = styled.h4`
    color: #343434;
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    margin-top: ${(props: { isMidSizePopup: boolean }) => (props.isMidSizePopup ? "-15px" : "10px")};
`;

export const StyledMessage = styled.p`
    font-size: 12px;
    color: #343434;
`;

export const StyledFooter = styled.div`
    margin-top: ${(props: { isMidSizePopup: boolean; footerMarginTop: number }) => `${props.footerMarginTop}px`};
    margin-bottom: ${(props: { isMidSizePopup: boolean }) => (props.isMidSizePopup ? "-30px" : "0px")};
`;

const AlertComponent: React.SFC<AlertPropsInterface> = ({
    footer,
    status,
    body,
    title,
    title2 = "",
    message,
    onCloseHandler,
    instanceId,
    styles = {},
    isMidSizePopup = false
}) => {
    const {
        Overlay = StyledOverlay,
        Alert = StyledAlert,
        Close = StyledClose,
        Status = StyledStatus,
        Title = StyledTitle,
        Message = StyledMessage,
        Footer = StyledFooter
    } = styles;

    let minHeight = 180;
    let footerMarginTop = 20;
    if (isMidSizePopup) {
        minHeight = 151;
        footerMarginTop = 0;
        if (title2) {
            minHeight = 170;
            footerMarginTop = -15;
        }
    }
    return (
        <Overlay onClick={onCloseHandler} id={`${instanceId}_alert_overlay`}>
            <Alert
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                }}
                id={`${instanceId}_alert`}
                isMidSizePopup={isMidSizePopup}
                minHeight={minHeight}
            >
                <Close onClick={onCloseHandler} id={`${instanceId}_alert_close`} />

                <React.Fragment>
                    {status && <Status status={status} id={`${instanceId}_alert_status`} />}
                    <Title id={`${instanceId}_alert_title`} isMidSizePopup={isMidSizePopup}>
                        {title}
                    </Title>
                    {title2 && <Title id={`${instanceId}_alert_title_second`}>{title2}</Title>}
                    {body && body}
                    <Message id={`${instanceId}_alert_message`}>{message}</Message>
                </React.Fragment>

                {footer &&
                    (Array.isArray(footer) ? (
                        <Footer id={`${instanceId}_alert_footer`} isMidSizePopup={isMidSizePopup} footerMarginTop={footerMarginTop}>
                            {map(footer, (buttonProps: ButtonPropsInterface, index: number) => (
                                <Button
                                    {...buttonProps}
                                    instanceId={`${instanceId}_alert_footer_${kebabCase(buttonProps.label as string)}_button`}
                                    key={`${instanceId}_alert_footer_${index}_button`}
                                />
                            ))}
                        </Footer>
                    ) : (
                        { footer }
                    ))}
            </Alert>
        </Overlay>
    );
};

export default AlertComponent;
