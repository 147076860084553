import { AnyAction } from "redux";
import { call, put, takeEvery } from "redux-saga/effects";
import actionTypes, { setAuthTechParamValues, setTopCompFetchStatus, setTechDataFailed } from "../../ducks/actions";
import { getContext } from "../../../../app/duck/context-container";
import Apis from "../../../simulator/Components/ducks/apis";

// tslint:disable-next-line: typedef
function* fetchAuthTechParamsBasedOnVersion(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.getAuthTechParams, action.payload, parser);
    if (response.status === 200) {
        yield put(setAuthTechParamValues(response.data));
        yield put(setTopCompFetchStatus(true));
    } else {
        yield put(setTechDataFailed(true));
    }
}

// tslint:disable-next-line: typedef
export function* watchForAuthTechParamsBasedOnVersionCall() {
    yield takeEvery(actionTypes.GET_AUTH_TECH_PARAMS, fetchAuthTechParamsBasedOnVersion);
}
