import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import ActionTypes from "./actions";
import { ContextReducerInterface, FlowTypeInterface } from "./types";
import { getMetaData, getSelectedLocaleData } from "./utils";
import { DEFAULT_LOCALE } from "../const";

const INIT_STATE: ContextReducerInterface = {
    user_email: undefined,
    selectedDomain: undefined,
    projectDomain: undefined,
    selectedKeyword: undefined,
    selectedLocale: undefined,
    domainAliasUrl: undefined,
    projectId: undefined,
    projectName: "",
    isMobileProject: undefined,
    isStandardProject: undefined,
    userId: undefined,
    tenantCode: undefined,
    flowType: FlowTypeInterface.RANK_SIMULATOR,
    featureAddons: [],
    selectedSID: undefined,
    uniqueDomainUrl: undefined,
    projectsList: [],
    keywordLimit: undefined,
    domainLimit: undefined,
    isEnableRunSimulation: false
};
const Reducer = handleActions(
    {
        [ActionTypes.SET_PROJECTS_LIST_IN_CONTEXT] : (state, action: AnyAction) => ({
            ...state,
            projectsList: action.payload
        }),
        [ActionTypes.SET_UNIQUE_DOMAIN_URL]: (state, action: AnyAction) => ({
            ...state,
            uniqueDomainUrl: action.payload
        }),
        [ActionTypes.SET_PROJECT_DOMAIN_URL]: (state, action: AnyAction) => ({
            ...state,
            projectDomain: action.payload
        }),
        [ActionTypes.SET_USER_ID_IN_CONTEXT]: (state, action: AnyAction) => ({
            ...state,
            userId: action.payload
        }),
        [ActionTypes.UNSET_USER_ID_FROM_CONTEXT]: (state, action: AnyAction) => ({
            ...state,
            userId: undefined
        }),
        [ActionTypes.SET_USER_NAME_IN_CONTEXT]: (state, action: AnyAction) => ({
            ...state,
            username: action.payload
        }),
        [ActionTypes.UNSET_USER_NAME_FROM_CONTEXT]: (state, action: AnyAction) => ({
            ...state,
            username: undefined
        }),
        [ActionTypes.SET_TENANT_CODE_IN_CONTEXT]: (state, action: AnyAction) => ({
            ...state,
            tenantCode: action.payload
        }),
        [ActionTypes.UNSET_TENANT_CODE_FROM_CONTEXT]: (state, action: AnyAction) => ({
            ...state,
            tenantCode: undefined
        }),
        [ActionTypes.SET_PROJECT_NAME_IN_CONTEXT]: (state, action: AnyAction) => ({
            ...state,
            projectName: getMetaData().projectName
            // projectName: action.payload
        }),
        [ActionTypes.SET_PROJECT_TYPE_IN_CONTEXT]: (state, action: AnyAction) => ({
            ...state,
            isMobileProject: getMetaData().isMobileProject,
            isStandardProject: getMetaData().isStandardProject
        }),
        [ActionTypes.SET_PROJECT_ID_IN_CONTEXT]: (state, action: AnyAction) => ({
            ...state,
            projectId: getMetaData().projectID
        }),
        [ActionTypes.UNSET_PROJECT_ID_FROM_CONTEXT]: (state, action: AnyAction) => ({
            ...state,
            projectId: undefined
        }),
        [ActionTypes.SET_LOCALE_IN_CONTEXT]: (state, action: AnyAction) => ({
            ...state,
            selectedLocale: action.payload ? action.payload : getMetaData().locale || getSelectedLocaleData().value || DEFAULT_LOCALE
        }),
        [ActionTypes.UNSET_LOCALE_FROM_CONTEXT]: (state, action: AnyAction) => ({
            ...state,
            selectedLocale: undefined
        }),
        [ActionTypes.SET_KEYWORD_IN_CONTEXT]: (state, action: AnyAction) => ({
            ...state,
            selectedKeyword: action.payload
        }),

        [ActionTypes.UNSET_KEYWORD_FROM_CONTEXT]: (state, action: AnyAction) => ({
            ...state,
            selectedKeyword: undefined
        }),
        [ActionTypes.SET_DOMAIN_IN_CONTEXT]: (state, action: AnyAction) => ({
            ...state,
            selectedDomain: typeof (action.payload) === "string" ? action.payload : action.payload?.sim_url,
            domainAliasUrl: typeof (action.payload) === "string" ? action.payload : action.payload?.alias_url,
        }),
        [ActionTypes.SET_FEATURE_ADDONS_IN_CONTEXT]: (state, action: AnyAction) => ({
            ...state,
            featureAddons: action.payload
        }),
        [ActionTypes.SET_DOMAIN_AND_KEYWORDS_LIMIT_IN_CONTEXT]: (state, action: AnyAction) => ({
            ...state,
            keywordLimit: action.payload.keywords,
            domainLimit: action.payload.domains
        }),
        [ActionTypes.UNSET_DOMAIN_FROM_CONTEXT]: (state, action: AnyAction) => ({
            ...state,
            selectedDomain: undefined
        }),
        [ActionTypes.SET_FLOWTYPE]: (state, action: AnyAction) => ({
            ...state,
            flowType: action.payload
        }),
        [ActionTypes.SET_SELECTED_SID]: (state, action: AnyAction) => ({
            ...state,
            selectedSID: action.payload
        }),
        [ActionTypes.UNSET_SELECTED_SID]: (state, action: AnyAction) => ({
            ...state,
            selectedSID: undefined
        }),
        [ActionTypes.IS_ENABLED_RUN_SIMULATION]: (state, action: AnyAction) => ({
            ...state,
            isEnableRunSimulation: action.payload
        })
    },
    INIT_STATE
);

export default Reducer;
