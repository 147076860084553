import { filter, forEach, isEqual, isNull, isUndefined, keys, orderBy, values } from "lodash";
import { eventChannel } from "@redux-saga/core";
import {
    PhraseTabDataInterface,
    ScoreListInterface,
    KeywordsDropdownInterface,
    UrlkeywordsScoreInterface
} from "./types";
import { ReactText } from "react";
import { MultiKeywordTrackRequestApiRequestIdsResponseInterface, SerpListProgressStatusType } from "../../../../../../app/duck/types";
import { ModelScoreContentResponseInterface, CompetitorsScoreResponseInterface, KeywordInterface } from "../../../../../../beta/simulator/ducks/types";
import { ImpactedKeywordsResponseInterface } from "../../../ducks/types";
import { RecommendationConst, SCORE_DROPDOWN_DETAIL } from "./const";
import { SIMULATOR_CONST } from "../../../../ducks/const";


export const getTargetScoreKey = (givenSelectedTag: string) => {
    const selected = givenSelectedTag.toLowerCase().replace(" ", "_");
    return `${selected}_score`;
};

export const getValue = (value: number | null | undefined) => {
    return isNull(value) || isUndefined(value) || isNaN(value) ? 0 : value;
};

export const getFormattedModelScore = (modelScore: ModelScoreContentResponseInterface) => {
    let scoreListValue: ScoreListInterface | undefined;
    if (modelScore) {
        scoreListValue = {
            "H1": {
                value: modelScore.h1_score && modelScore.h1_score.value ? getValue(modelScore.h1_score.value) : undefined,
                deltaValue: modelScore.h1_score && modelScore.h1_score.delta_value ? getValue(modelScore.h1_score.delta_value) : undefined
            },
            "H2": {
                value: modelScore.h2_score && modelScore.h2_score.value ? getValue(modelScore.h2_score.value) : undefined,
                deltaValue: modelScore.h2_score && modelScore.h2_score.delta_value ? getValue(modelScore.h2_score.delta_value) : undefined
            },
            "H3": {
                value: modelScore.h3_score && modelScore.h3_score.value ? getValue(modelScore.h3_score.value) : undefined,
                deltaValue: modelScore.h3_score && modelScore.h3_score.delta_value ? getValue(modelScore.h3_score.delta_value) : undefined
            },
            "Title": {
                value: modelScore.title_score && modelScore.title_score.value ? getValue(modelScore.title_score.value) : undefined,
                deltaValue:
                    modelScore.title_score && modelScore.title_score.delta_value ? getValue(modelScore.title_score.delta_value) : undefined
            },
            "Meta Description": {
                value:
                    modelScore.meta_description_score && modelScore.meta_description_score.value
                        ? getValue(modelScore.meta_description_score.value)
                        : undefined,
                deltaValue:
                    modelScore.meta_description_score && modelScore.meta_description_score.delta_value
                        ? getValue(modelScore.meta_description_score.delta_value)
                        : undefined
            },
            "Body Content": {
                value:
                    modelScore.visible_content_score && modelScore.visible_content_score.value
                        ? getValue(modelScore.visible_content_score.value)
                        : undefined,
                deltaValue:
                    modelScore.visible_content_score && modelScore.visible_content_score.delta_value
                        ? getValue(modelScore.visible_content_score.delta_value)
                        : undefined
            }
        };
    }
    return scoreListValue;
};

export const getFormattedPhraseContent = (
    phraseContent: PhraseTabDataInterface[] | undefined,
    editorContent: string
): PhraseTabDataInterface[] | undefined => {
    let formattedPhraseContent;
    if (phraseContent && !isUndefined(editorContent)) {
        formattedPhraseContent = phraseContent.map((phrase) => {
            const regX = new RegExp(phrase.name, "gi");
            const frequencyCount = editorContent.match(regX)?.length || 0;
            return { ...phrase, frequencyCount };
        });
    }
    return formattedPhraseContent || phraseContent;
};

export const getMessageForNotificationBarLoader = (
    keywordsStatus?: MultiKeywordTrackRequestApiRequestIdsResponseInterface
): string | undefined => {
    return isUndefined(keywordsStatus)
        ? keywordsStatus
        : (() => {
            const keywordsProcessed = filter(values(keywordsStatus), ["is_my_url_processed", true]);
            const kwSuccessfullyProcessed = filter(values(keywordsStatus), ["my_url_status", SerpListProgressStatusType.DONE]);
            const failedKeywords = filter(values(keywordsStatus), ["my_url_status", SerpListProgressStatusType.FAILED]);

            return keys(keywordsStatus).length > keywordsProcessed.length
                ? `${RecommendationConst.MULTI_KEYWORD_KEYWORD_FETCH_LOADER_TITLE} ${kwSuccessfullyProcessed.length}/${values(keywordsStatus).length} ${RecommendationConst.MULTI_KEYWORD_KEYWORD_FETCH_LOADER_MESSAGE
                }`
                : failedKeywords.length > 0 && kwSuccessfullyProcessed.length + failedKeywords.length === keys(keywordsStatus).length
                    ? `${kwSuccessfullyProcessed.length}/${values(keywordsStatus).length} ${SIMULATOR_CONST.MULTI_KEYWORD_NOTIFICATION_WHEN_ALL_KW_PROCESSED_FAILED_PROCESSED
                    }. ${failedKeywords.length} ${SIMULATOR_CONST.MULTI_KEYWORD_NOTIFICATION_WHEN_ALL_KW_PROCESSED_FAILED}`
                    : SIMULATOR_CONST.MULTI_KEYWORD_NOTIFICATION_WHEN_ALL_KW_PROCESSED;
        })();
};

export const isAllKeywordsProceed = (
    keywordProcessedInfo: MultiKeywordTrackRequestApiRequestIdsResponseInterface,
    isUrlLive: boolean,
    onPageElementStatusCode?: number,
    selectedSID?: string
) => {
    const msg = getMessageForNotificationBarLoader(keywordProcessedInfo);
    const failedMsg = msg ? msg.split(" ").pop() : "";
    if (isUndefined(selectedSID) && isUrlLive && isUndefined(onPageElementStatusCode)) {
        return false;
    }
    if (msg === SIMULATOR_CONST.MULTI_KEYWORD_NOTIFICATION_WHEN_ALL_KW_PROCESSED || failedMsg === "failed") {
        return true;
    }
    return false;
};

export const getValidArea = (label: string, value: number) => {
    if (label === "width" && value <= 411) {
        return true;
    }
    if (label === "height" && value <= 441) {
        return true;
    }
    return false;
};

export const getTopAVGRankScore = (
    selectedTag: string,
    competitorData: CompetitorsScoreResponseInterface,
    keywords: KeywordInterface[]
) => {
    let totalSV = 0;
    let totalScore = 0;
    const selectedElement = selectedTag.split(" ").length < 2 ? selectedTag : selectedTag.replace(" ", "_");
    const selectedTagFormatted = selectedElement === "Body_Content" ? "visible_content" : selectedElement;
    if (keywords && keywords.length) {
        keywords.forEach((kw) => {
            if (competitorData && competitorData.hasOwnProperty(kw.keyword)) {
                const compData = competitorData[kw.keyword];
                if (compData && compData.url_data && compData.url_data.length) {
                    let totalKwScore = 0;
                    // tslint:disable-next-line:no-any
                    compData.url_data.forEach((compItem: any, index) => {
                        if (compItem && index <= 2) {
                            const elementItem = compItem[`${selectedTagFormatted.toLowerCase()}_score`];
                            const tagScore = elementItem
                                ? isNaN(elementItem.score)
                                    ? elementItem.score.info_value
                                    : elementItem.score
                                : 0;
                            totalKwScore += tagScore;
                        }
                    });
                    const score = (totalKwScore / 3) * kw.search_volume;
                    totalScore += score;
                    totalSV += kw.search_volume;
                }
            }
        });
    }
    const avgRankScore = totalScore / totalSV;
    return typeof avgRankScore ? Number(avgRankScore.toFixed(2)) : avgRankScore;
};

// tslint:disable-next-line:typedef
export function listenRecommendationSSEvents(url: string) {
    const eventChan = eventChannel((emit) => {
        const sseSource = new EventSource(url);
        sseSource.addEventListener("receive_h1_text", (ev: Event) => emit(ev));
        sseSource.addEventListener("receive_h1_scores", (ev: Event) => emit(ev));
        sseSource.addEventListener("receive_title_text", (ev: Event) => emit(ev));
        sseSource.addEventListener("receive_title_scores", (ev: Event) => emit(ev));
        sseSource.addEventListener("receive_h2_text", (ev: Event) => emit(ev));
        sseSource.addEventListener("receive_h2_scores", (ev: Event) => emit(ev));
        sseSource.addEventListener("req_completed", (ev: Event) => emit(ev));
        sseSource.addEventListener("req_failed", (ev: Event) => emit(ev));
        sseSource.onerror = (ev: Event) => {
            emit(ev);
        };
        return () => sseSource.close();
    });
    return eventChan;
}


// tslint:disable-next-line:no-any
export const getDropdownValue = (selectedValue: string, dropdownOptions: KeywordsDropdownInterface[]): any => {
    let selectedDropdownVal;
    dropdownOptions.forEach((option: KeywordsDropdownInterface) => {
        if (selectedValue === option.label) {
            selectedDropdownVal = option;
        }
    });
    return selectedDropdownVal;
};
export const isKeywordsAreNotMatched = (simKeywords: KeywordInterface[], payloadKeywords: KeywordInterface[]) => {
    const sortedSimulationKeywords = orderBy(simKeywords, ["search_volume"], ["asc"]);
    const sortedPhrasesPayloadKeywords = orderBy(payloadKeywords as KeywordInterface[], ["search_volume"], ["asc"]);
    if (simKeywords && simKeywords.length && payloadKeywords && !payloadKeywords.length) {
        return true;
    }
    if (
        sortedSimulationKeywords &&
        sortedSimulationKeywords.length &&
        sortedPhrasesPayloadKeywords &&
        sortedPhrasesPayloadKeywords.length &&
        !isEqual(sortedSimulationKeywords, sortedPhrasesPayloadKeywords)
    ) {
        return true;
    }
    return false;
};

export const getKeywordsWithScore = (url: string, selectedTag: string, keywordScore?: UrlkeywordsScoreInterface[]): ReactText[] | [] => {
    const selectedKeywordScores = keywordScore?.filter((item: UrlkeywordsScoreInterface) => item?.url === url);
    return selectedKeywordScores ? selectedKeywordScores[0]?.[`${selectedTag?.toLowerCase()}_score` as keyof UrlkeywordsScoreInterface] as ReactText[]
        : [];
};


export const getScoreValue = (value: number) => {
    return isNaN(value) ? null : value;
};

/**
 * Gives list of Formatted Keyword along with its score
 *
 * @param {ImpactedKeywordsResponseInterface} eachKwData - Each Keyword Data
 * @returns {ModelScoredDropdownKeysType[]} - Each keyword formatted Data
 *
 */
export const calculateEachKwData = (eachKwData: ImpactedKeywordsResponseInterface) => {
    // @ts-ignore
    const keywordDetailData: ModelScoredDropdownKeysType[] = SCORE_DROPDOWN_DETAIL.map((item, index) => {
        return {
            ...item,
            // @ts-ignore
            data1: eachKwData && eachKwData[item.keyName] ? getScoreValue(eachKwData[item.keyName].score.info_value) : null,
            // @ts-ignore
            data2: eachKwData && eachKwData[item.keyName] ? getScoreValue(eachKwData[item.keyName].score.delta_info_value) : null
        };
    });
    return keywordDetailData.slice(0, 7);
};


export const getKwListFromImpactedKeywords = (impactedKeyowrds: ImpactedKeywordsResponseInterface[]) => {
    const kwList: string[] = [];
    forEach(impactedKeyowrds as ImpactedKeywordsResponseInterface[], (eachKWDetails: ImpactedKeywordsResponseInterface) => {
        const contentScore = eachKWDetails.category_scores.content_score.info_value;
        const authScore = eachKWDetails.category_scores.authority_score.info_value;
        const keyword: string = eachKWDetails.keyword;
        if (contentScore || authScore) {
            kwList.push(keyword);
        }
    });
    return kwList;
};
