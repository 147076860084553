import { call, put, takeEvery } from "redux-saga/effects";
import { AnyAction } from "redux";
import Apis from "./apis";
import ActionTypes, {
    setPerformanceImpactReportsList,
    appendToPerformanceImpactReportsList,
    setErrorMessage,
    clearNewReportFormData,
    setIsAddReportInProgress,
    setPerformanceImpactReportDetails,
    setPerformanceImpactReportRankingKeywords,
    setIsAddReportSuccess,
    setPerformanceImpactReportGSCKeywords,
    setPerformanceImpactReportGSCSummary
} from "./actions";
import { getContext } from "../../app/duck/context-container";

// tslint:disable-next-line:no-any
export function* fetchPerformanceImpactReportsList(action: AnyAction): any {
    const { tenantCode = "" } = getContext();
    const response = yield call(Apis.getPerformanceImpactReportList, { tenantCode });
    if (response.status === 200) {
        yield put(setPerformanceImpactReportsList(response.data));
    }
}

// tslint:disable-next-line:no-any
export function* addPerformanceImpactReport(action: AnyAction): any {
    yield put(setIsAddReportInProgress(true));
    const { tenantCode = "" } = getContext();
    const response = yield call(
        Apis.addPerformanceImpactReport,
        { tenantCode },
        {
            url: action.payload.url,
            locale: action.payload.locale,
            primary_keyword: action.payload.primary_keyword,
            secondary_keywords: action.payload.secondary_keywords
        }
    );

    switch (response.status) {
        case 201:
            yield put(appendToPerformanceImpactReportsList(response.data));
            yield put(clearNewReportFormData());
            yield put(setIsAddReportSuccess(true));
            yield put(setErrorMessage(""));
            break;
        case 400:
            yield put(setErrorMessage("Invalid input"));
            yield put(setIsAddReportSuccess(false));
            break;
        case 409:
            yield put(setErrorMessage("Record already exists"));
            yield put(setIsAddReportSuccess(false));
            break;
        default:
            break;
    }
    yield put(setIsAddReportInProgress(false));
}

// tslint:disable-next-line:no-any
export function* fetchPerformanceImpactReportDetails(action: AnyAction): any {
    yield put(setPerformanceImpactReportDetails({
        isLoading: true,
        data: null,
        error: ""
    }));
    const { tenantCode = "" } = getContext();
    const response = yield call(
        Apis.getPerformanceImpactReportDetails,
        { tenantCode },
        { reportId: action.payload.reportId, deviceType: action.payload.deviceType }
    );

    switch (response.status) {
        case 200:
            yield put(setPerformanceImpactReportDetails({
                isLoading: false,
                data: response.data,
                error: ""
            }));
            break;
        case 204:
            yield put(setPerformanceImpactReportDetails({
                isLoading: false,
                data: null,
                error: "Please check back in a few hours for the latest data. Thank you for your patience!"
            }));
            break;
        case 400:
            yield put(setPerformanceImpactReportDetails({
                isLoading: false,
                data: null,
                error: "Something went wrong!"
            }));
            break;
        default:
            break;
    }
}

// tslint:disable-next-line:no-any
export function* fetchPerformanceImpactReportRankingKeywords(action: AnyAction): any {
    yield put(setPerformanceImpactReportRankingKeywords({
        isLoading: true,
        data: null,
        error: ""
    }));
    const { tenantCode = "" } = getContext();
    const response = yield call(
        Apis.getPerformanceImpactReportRankingKeywords,
        { tenantCode },
        {
            reportId: action.payload.reportId,
            deviceType: action.payload.deviceType,
        }
    );

    switch (response.status) {
        case 200:
            yield put(setPerformanceImpactReportRankingKeywords({
                isLoading: false,
                data: response.data,
                error: ""
            }));
            break;
        case 204:
            yield put(setPerformanceImpactReportRankingKeywords({
                isLoading: false,
                data: null,
                error: "Ranking Keywrods data not found"
            }));
            break;
        case 400:
            yield put(setPerformanceImpactReportRankingKeywords({
                isLoading: false,
                data: null,
                error: "Something went wrong!"
            }));
            break;
        default:
            break;
    }
}

// tslint:disable-next-line:no-any
export function* fetchPerformanceImpactReportGSCKeywords(action: AnyAction): any {
    yield put(setPerformanceImpactReportGSCKeywords({
        isLoading: true,
        data: null,
        error: ""
    }));
    const { tenantCode = "" } = getContext();
    const response = yield call(
        Apis.getPerformanceImpactReportGSCKeywords,
        { tenantCode },
        {
            reportId: action.payload.reportId,
            deviceType: action.payload.deviceType,
        }
    );

    switch (response.status) {
        case 200:
            yield put(setPerformanceImpactReportGSCKeywords({
                isLoading: false,
                data: response.data,
                error: ""
            }));
            break;
        case 204:
            yield put(setPerformanceImpactReportGSCKeywords({
                isLoading: false,
                data: null,
                error: "GSC Keywrods data not found"
            }));
            break;
        case 400:
            yield put(setPerformanceImpactReportGSCKeywords({
                isLoading: false,
                data: null,
                error: "Something went wrong!"
            }));
            break;
        default:
            break;
    }
}

// tslint:disable-next-line:no-any
export function* fetchPerformanceImpactReportGSCSummary(action: AnyAction): any {
    yield put(setPerformanceImpactReportGSCSummary({
        isLoading: true,
        data: null,
        error: ""
    }));
    const { tenantCode = "" } = getContext();
    const response = yield call(
        Apis.getPerformanceImpactReportGSCSummary,
        { tenantCode },
        {
            reportId: action.payload.reportId,
            deviceType: action.payload.deviceType,
        }
    );

    switch (response.status) {
        case 200:
            yield put(setPerformanceImpactReportGSCSummary({
                isLoading: false,
                data: response.data,
                error: ""
            }));
            break;
        case 204:
            yield put(setPerformanceImpactReportGSCSummary({
                isLoading: false,
                data: null,
                error: "GSC Summary data not found"
            }));
            break;
        case 400:
            yield put(setPerformanceImpactReportGSCSummary({
                isLoading: false,
                data: null,
                error: "Something went wrong!"
            }));
            break;
        default:
            break;
    }
}

// tslint:disable-next-line: typedef
export function* watchGetPerformanceImpactReportsList() {
    yield takeEvery(ActionTypes.GET_PERFORMANCE_IMPACT_REPORTS_LIST, fetchPerformanceImpactReportsList);
}

// tslint:disable-next-line: typedef
export function* watchAddPerformanceImpactReport() {
    yield takeEvery(ActionTypes.ADD_PERFORMANCE_IMPACT_REPORT, addPerformanceImpactReport);
}

// tslint:disable-next-line: typedef
export function* watchAGetPerformanceImpactReportDetails() {
    yield takeEvery(ActionTypes.GET_PERFORMANCE_IMPACT_REPORT_DETAILS, fetchPerformanceImpactReportDetails);
}

// tslint:disable-next-line: typedef
export function* watchAGetPerformanceImpactReportRankingKeywords() {
    yield takeEvery(ActionTypes.GET_PERFORMANCE_IMPACT_REPORT_RANKING_KEYWORDS, fetchPerformanceImpactReportRankingKeywords);
}

// tslint:disable-next-line: typedef
export function* watchAGetPerformanceImpactReportGSCKeywords() {
    yield takeEvery(ActionTypes.GET_PERFORMANCE_IMPACT_REPORT_GSC_KEYWORDS, fetchPerformanceImpactReportGSCKeywords);
}

// tslint:disable-next-line: typedef
export function* watchAGetPerformanceImpactReportGSCSummary() {
    yield takeEvery(ActionTypes.GET_PERFORMANCE_IMPACT_REPORT_GSC_SUMMARY, fetchPerformanceImpactReportGSCSummary);
}
