import { AnyAction } from "redux";
import { call, takeEvery, put } from "redux-saga/effects";
import actionTypes, {
    saveContentBriefCompetitors,
    saveContentBriefSummary,
    saveContentBriefTabHistory,
    setBigramsWords,
    setPageHideLoader,
    setPageShowLoader,
    setTrigramsWords,
    setUnigramsWords
} from "./actions";
import { GetContentBriefViewDataPayloadInterface, UpdateCBNameDescriptionPayloadInterface } from "./types";
import { getContext } from "../../app/duck/context-container";
import Apis from "../../app/apis";
import { contentBriefRequestErrors, COPY } from "../../app/const";

// tslint:disable-next-line: typedef
export function* updateCBfNameAndDescription(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const payload: UpdateCBNameDescriptionPayloadInterface = {
        ...action.payload,
        needToHandleError: true,
        isSpecificError: true,
        errorList: contentBriefRequestErrors
    };
    const response = yield call(Apis.updateCBNameAndDescription, payload, parser);
    return response;
}

// tslint:disable-next-line: typedef
export function* fetchContentBriefSummary(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const payload: GetContentBriefViewDataPayloadInterface = {
        ...action.payload,
        needToHandleError: true,
        isSpecificError: true,
        errorList: contentBriefRequestErrors
    };
    const response = yield call(Apis.getContentBriefViewData, payload, parser);
    if (response.status === 200) {
        yield put(saveContentBriefSummary(response.data));
    } else {
        const emptyObj = {
            avg_search_volume: 0,
            avg_article_length: 0,
            avg_content_score: 0,
            avg_topic_difficulty: 0,
            serp_elements: []
        };
        yield put(saveContentBriefSummary(emptyObj));
    }
    return response;
}

// tslint:disable-next-line: typedef
export function* fetchContentBriefTabHistory(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const payload: GetContentBriefViewDataPayloadInterface = {
        ...action.payload,
        needToHandleError: true,
        isSpecificError: true,
        errorList: contentBriefRequestErrors
    };
    yield put(setPageShowLoader());
    const response = yield call(Apis.getContentBriefViewData, payload, parser);
    if (response.status === 200) {
        yield put(saveContentBriefTabHistory(response.data));
        yield put(setPageHideLoader());
    } else {
        yield put(saveContentBriefTabHistory([]));
        yield put(setPageHideLoader());
    }
    return response;
}

// tslint:disable-next-line: typedef
export function* fetchContentBriefCompetitors(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const payload: GetContentBriefViewDataPayloadInterface = {
        ...action.payload,
        needToHandleError: true,
        isSpecificError: true,
        errorList: contentBriefRequestErrors
    };
    yield put(setPageShowLoader());
    const response = yield call(Apis.getContentBriefViewData, payload, parser);
    if (response.status === 200) {
        yield put(saveContentBriefCompetitors(response.data));
        yield put(setPageHideLoader());
    } else {
        yield put(saveContentBriefCompetitors([]));
        yield put(setPageHideLoader());
    }
    return response;
}

// tslint:disable-next-line: typedef
export function* fetchPhrasesToUseData(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const payload: GetContentBriefViewDataPayloadInterface = {
        ...action.payload,
        needToHandleError: true,
        isSpecificError: true,
        errorList: contentBriefRequestErrors
    };
    yield put(setPageShowLoader());
    const response = yield call(Apis.getContentBriefViewData, payload, parser);
    if (response.status === 200) {
        if (payload.type.includes(COPY.UNIGRAM_URL)) {
            yield put(setUnigramsWords(response.data));
        } else if (payload.type.includes(COPY.BIGRAM_URL)) {
            yield put(setBigramsWords(response.data));
        } else if (payload.type.includes(COPY.TRIGRAM_URL)) {
            yield put(setTrigramsWords(response.data));
        } else {
            yield put(saveContentBriefTabHistory(response.data));
        }
        yield put(setPageHideLoader());
    } else {
        if (payload.type.includes(COPY.UNIGRAM_URL)) {
            yield put(setUnigramsWords([]));
        } else if (payload.type.includes(COPY.BIGRAM_URL)) {
            yield put(setBigramsWords([]));
        } else if (payload.type.includes(COPY.TRIGRAM_URL)) {
            yield put(setTrigramsWords([]));
        } else {
            yield put(saveContentBriefTabHistory([]));
        }
        yield put(setPageHideLoader());
    }
    return response;
}

// tslint:disable-next-line: typedef
export function* watchForUpdateCBNameAndDescription() {
    yield takeEvery(actionTypes.UPDATE_CB_NAME_AND_DESCRIPTION, updateCBfNameAndDescription);
}

// tslint:disable-next-line: typedef
export function* watchForGetContentBriefSummary() {
    yield takeEvery(actionTypes.GET_CONTENT_BRIEF_SUMMARY, fetchContentBriefSummary);
}

// tslint:disable-next-line: typedef
export function* watchForGetContentBriefTabHistory() {
    yield takeEvery(actionTypes.GET_CONTENT_BRIEF_TAB_HISTORY, fetchContentBriefTabHistory);
}

// tslint:disable-next-line: typedef
export function* watchForGetContentBriefCompetitors() {
    yield takeEvery(actionTypes.GET_CONTENT_BRIEF_COMPETITORS, fetchContentBriefCompetitors);
}

// tslint:disable-next-line: typedef
export function* watchForPhrasesToUse() {
    yield takeEvery(actionTypes.GET_PHRASES_DETAILS, fetchPhrasesToUseData);
}
