export type OptionValueType = string | boolean | number;
export type OptionValueOptinalType = string | boolean | number | string[] | undefined;

export interface OptionTypeInterface extends AdditionalOptionTypeInterface {
    label: OptionValueType;
    value: OptionValueType;
    isRanking?: boolean;
    searchVolume?: number;
    relevance_score?: number;
    is_browser_mode?: boolean;
    rank?: number;
    url?: string;
}
export interface RelatedKeywordTypeInterface extends AdditionalOptionTypeInterface {

    keyword?: string;
    searchVolume?: number;
    relevance_score?: number;
}
export type AdditionalOptionTypeInterface = {
    [key in string]?: OptionValueOptinalType;
};

export interface KeywordsInterface {
    projectKeywords: OptionTypeInterface[];
    relatedKeywords: OptionTypeInterface[];
    nonLiveRelatedKeywords: OptionTypeInterface[];
}

export interface FormatOptionTypeInterface extends AdditionalOptionTypeInterface {
    label: OptionValueType;
    value: OptionValueType;
}

export interface LocaleSearchOptionTypeInterface {
    label: string;
    value: string;
    data: {
        label: string;
        value: string;
        name: string;
        language: string;
    };
}

export interface StylesTypeFunctionReturnTypeInterface {
    [key: string]: string | number;
}

enum stylesKeyTypes {
    // types reference :: https://react-select.com/styles#style-object
    CLEAR_INDICATOR = "clearIndicator",
    CONTAINER = "container",
    CONTROL = "control",
    DROPDOWN_INDICATOR = "dropdownIndicator",
    GROUP = "group",
    GROUP_HEADING = "groupHeading",
    INDICATORS_CONTAINER = "indicatorsContainer",
    INDICATOR_SEPARATOR = "indicatorSeparator",
    INPUT = "input",
    LOADING_INDICATOR = "loadingIndicator",
    LOADING_MESSAGE = "loadingMessage",
    MENU = "menu",
    MENU_LIST = "menuList",
    MENU_PORTAL = "menuPortal",
    MULTI_VALUE = "multiValue",
    MULTI_VALUE_LABEL = "multiValueLabel",
    MULTI_VALUE_REMOVE = "multiValueRemove",
    NO_OPTIONS_MESSAGE = "noOptionsMessage",
    OPTION = "option",
    PLACEHOLDER = "placeholder",
    SINGLE_VALUE = "singleValue",
    VALUE_CONTAINER = "valueContainer"
}

enum componentsKeyTypes {
    CLEAR_INDICATOR = "ClearIndicator",
    CONTROL = "Control",
    DROPDOWN_INDICATOR = "DropdownIndicator",
    DOWN_CHEVRON = "DownChevron",
    CROSS_ICON = "CrossIcon",
    GROUP = "Group",
    GROUP_HEADING = "GroupHeading",
    INDICATORS_CONTAINER = "IndicatorsContainer",
    INDICATOR_SEPARATOR = "IndicatorSeparator",
    INPUT = "Input",
    LOADING_INDICATOR = "LoadingIndicator",
    MENU = "Menu",
    MENU_LIST = "MenuList",
    MENU_PORTAL = "MenuPortal",
    LOADING_MESSAGE = "LoadingMessage",
    NO_OPTIONS_MESSAGE = "NoOptionsMessage",
    MULTI_VALUE = "MultiValue",
    MULTI_VALUE_CONTAINER = "MultiValueContainer",
    MULTI_VALUE_LABEL = "MultiValueLabel",
    MULTI_VALUE_REMOVE = "MultiValueRemove",
    OPTION = "Option",
    PLACEHOLDER = "Placeholder",
    SELECT_CONTAINER = "SelectContainer",
    SINGLE_VALUE = "SingleValue",
    VALUE_CONTAINER = "ValueContainer"
}

enum stylesTypeStateTypes {
    // state type reference :: https://react-select.com/styles#provided-styles-and-state
    IS_SELECTED = "isSelected",
    IS_FOCUSED = "isFocused",
    IS_DISABLED = "isDisabled"
}

export interface StylesTypeDefaultStylesInterface {
    [key: string]: string;
}

export type StylesTypeStateInterface = {
    [key in stylesTypeStateTypes]: boolean;
};

export type StylesType = {
    [key in stylesKeyTypes]?: (
        defaultStyles: StylesTypeDefaultStylesInterface,
        state: StylesTypeStateInterface
    ) => StylesTypeFunctionReturnTypeInterface;
};

export type ComponentsTypes = {
    // tslint:disable-next-line:no-any
    [key in componentsKeyTypes]?: (props: any) => JSX.Element | null;
};

/** Anujay: Handle/merge styles passed by calling component */
export const DROPDOWN_CUSTOM_STYLES: StylesType = {
    control: (control, state) => ({
        ...control,
        border: state.isFocused ? "1px solid #00acce !important" : "1px solid #e2e2e2 !important",
        boxShadow: "none",
        width: "300px"
    }),
    menu: (menu, state) => ({
        ...menu,
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.18)",
        marginTop: 0,
        width: "300px"
    }),
    menuList: (menuList, state) => ({
        ...menuList,
        padding: "0"
    }),
    option: (option, state) => ({
        ...option,
        backgroundColor: state.isSelected ? "#00acce" : "#ffffff"
    })
};

export interface ModelScoredDropdownKeysType {
    site: string;
    keyName?: string;
    data1?: string | number | undefined;
    data2?: string | number | undefined;
}

export enum DOWNLOAD_OPTIONS {
    CONTENT = "1",
    CAT = "2"
}
