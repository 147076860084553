import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */
const ActionTypes = {
    SET_SELECTED_CB_KEYWORDS: "SET_SELECTED_CB_KEYWORDS",
    CALL_TO_SAVE_KEYWORDS: "CALL_TO_SAVE_KEYWORDS",
    SET_SAVE_CONTENT_BRIEF_STATUS: "SET_SAVE_CONTENT_BRIEF_STATUS",
    SET_SHOW_LOADER: "SET_SHOW_LOADER",
    SET_HIDE_LOADER: "SET_HIDE_LOADER",
    IS_SUBMIT_CONTENT_BRIEF: "IS_SUBMIT_CONTENT_BRIEF",
    SET_DEFAULT_KEYWORDS: "SET_DEFAULT_KEYWORDS",
    SET_SAVE_CONTENT_BRIEF: "SET_SAVE_CONTENT_BRIEF",
    GET_CB_PROGRESS: "GET_CB_PROGRESS",
    SET_CB_PROGRESS: "SET_CB_PROGRESS",
    IS_CB_PROGRESS_LOADING: "IS_CB_PROGRESS_LOADING",
    IS_CB_RETRY: "IS_CB_RETRY",
    UPDATE_CONTENT_BRIEF: "UPDATE_CONTENT_BRIEF",
};
export default ActionTypes;

// GETTERS / API CALL ACTIONS
export const setSelectedCBKeywords = createAction(ActionTypes.SET_SELECTED_CB_KEYWORDS);
export const callToSaveKeywords = createAction(ActionTypes.CALL_TO_SAVE_KEYWORDS);
export const setSaveContentBriefStatus = createAction(ActionTypes.SET_SAVE_CONTENT_BRIEF_STATUS);
export const setShowLoader = createAction(ActionTypes.SET_SHOW_LOADER);
export const setHideLoader = createAction(ActionTypes.SET_HIDE_LOADER);
export const isSubmitContentBrief = createAction(ActionTypes.IS_SUBMIT_CONTENT_BRIEF);
export const setDefaultKeywords = createAction(ActionTypes.SET_DEFAULT_KEYWORDS);
export const setSaveContentBrief = createAction(ActionTypes.SET_SAVE_CONTENT_BRIEF);
export const getCBProgress = createAction(ActionTypes.GET_CB_PROGRESS);
export const setCBProgress = createAction(ActionTypes.SET_CB_PROGRESS);
export const isCBProgressLoading = createAction(ActionTypes.IS_CB_PROGRESS_LOADING);
export const isCBRetry = createAction(ActionTypes.IS_CB_RETRY);
export const onUpdateContentBrief = createAction(ActionTypes.UPDATE_CONTENT_BRIEF);

