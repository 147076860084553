import { find, isUndefined, filter, map, isNull, max, isNumber, sortBy, orderBy, isBoolean } from "lodash";
import { ASCENDING, DESCENDING, IMPORTANCE_COLOR_SCHEME, SORTING_OPTIONS, HIGH_TO_LOW, LOW_TO_HIGH } from "../../../app/const";
import { round } from "../../../app/duck/utils";
import {
    SelectedUrlType,
    SelectedUrlTypeInterface,
    ScoresInterface,
    CategoryScorePercentInterface,
    KeyWordScoreParameterInterface,
    SerpResponseInterface,
    DefaultSelectedUrlTypesInterface,
    ParameterScoreComparisionInterface,
    CATScorePercentInterface,
    CompatitorTopTenUrlList
} from "./types";
import { CategoryType, ImportanceColorSchemeInterface, SerpListProgressStatusType } from "../../../app/duck/types";
import { getContext } from "../../../app/duck/context-container";
import { OptionTypeInterface } from "../../../app/styledComponents/drop-down/types";

export const setSelectedUrlType = (args: SelectedUrlTypeInterface) => {
    if (args.type === SelectedUrlType.TARGET) {
        return {
            [SelectedUrlType.TARGET]: args.value
        };
    }
    return {
        [SelectedUrlType.COMPATITOR]: args.value
    };
};

export const getGapScore = (target: number, compatitor: number): number => {
    return round(target - compatitor, 1);
};

export const getCategoryInfo = (
    targetScore: ScoresInterface[],
    compatitorScore: ScoresInterface[],
    selectedCategory: CategoryType
): CategoryScorePercentInterface => {
    /** for more better performance, we should move all these calculation in selector  */
    const selectedCompatitorScore = find(compatitorScore, (score: ScoresInterface) => {
        return score.type === selectedCategory;
    });
    const selectedTargetScore = find(targetScore, (score: ScoresInterface) => {
        return score.type === selectedCategory;
    });
    /**
     * ALPS/Total is to be calculated out of 100 ie. (score*100)/100 = score
     * Authority,Content & Technical shoud be calculated out of 10 ie. (score*100)/10 = score*10
     */
    const compatitor = !isUndefined(selectedCompatitorScore)
        ? !isNull(selectedCompatitorScore.value)
            ? round(selectedCompatitorScore.value)
            : -1
        : -1;

    const target = !isUndefined(selectedTargetScore) ? (!isNull(selectedTargetScore.value) ? round(selectedTargetScore.value) : -1) : -1;

    return {
        compatitor,
        target
    };
};

export const getComparisionHealthCount = (
    allTargetParam: KeyWordScoreParameterInterface[],
    allCompatitorParam: KeyWordScoreParameterInterface[],
    selectedCategory: string
) => {
    let selectedTargetCategory = allTargetParam;
    if (selectedCategory !== CategoryType.ALPS) {
        selectedTargetCategory = filter(allTargetParam, (param: KeyWordScoreParameterInterface) => {
            return param.category.toLowerCase() === selectedCategory;
        });
    }
    // let betterCompatitorParamCount: number = -1;
    // let betterTargetParamCount: number = -1;
    let betterCompatitorParamCount: number = 0;
    let betterTargetParamCount: number = 0;

    map(selectedTargetCategory, (cat: KeyWordScoreParameterInterface) => {
        if (isNumber(cat.gap_value)) {
            const sameParmOfCompatitor = find(allCompatitorParam, (param: KeyWordScoreParameterInterface) => {
                return param.parameter === cat.parameter;
            });
            if (
                !isUndefined(sameParmOfCompatitor) &&
                !isNull(sameParmOfCompatitor.gap_value) &&
                isNumber(sameParmOfCompatitor.gap_value) &&
                cat.gap_value >= 0
            ) {
                betterTargetParamCount += betterTargetParamCount === -1 ? 2 : 1;
            } else if (!isUndefined(sameParmOfCompatitor) && !isNull(sameParmOfCompatitor.gap_value)) {
                betterCompatitorParamCount += betterCompatitorParamCount === -1 ? 2 : 1;
            }
        } else {
            betterCompatitorParamCount += betterCompatitorParamCount === -1 ? 1 : 0;
            betterTargetParamCount += betterTargetParamCount === -1 ? 1 : 0;
        }
    });

    return {
        betterCompatitorParamCount,
        betterTargetParamCount
    };
};
export const getTargetTopRank = (serpList: SerpResponseInterface[]): number => {
    const rank = find(serpList, (list: SerpResponseInterface) => {
        return list.is_organic && list.is_my_domain;
    });

    return !isUndefined(rank) ? rank.organic_rank : -1;
};
export const filterOrganicSerpList = (serpList: SerpResponseInterface[]): SerpResponseInterface[] => {
    const list: SerpResponseInterface[] = [];
    map(serpList, (serp: SerpResponseInterface) => {
        if (serp.is_organic) {
            list.push(serp);
        }
    });
    return list;
};

export const formatSerpList = (serpList: SerpResponseInterface[]): OptionTypeInterface[] => {
    const list: OptionTypeInterface[] = [];

    map(serpList, (serp: SerpResponseInterface) => {
        if (serp.is_organic) {
            list.push({
                // label = `(Rank ${
                //     serp.organic_rank <= 120 ? serp.organic_rank : `> 120`
                // }) ${rankingUrl.url}`,
                isDisabled: serp.status === SerpListProgressStatusType.DONE ? false : true,
                // label: `(Rank ${
                //     serp.organic_rank <= 120 ? serp.organic_rank : `> 120`
                // }) ${serp.url}`,
                label: serp.url,
                rank: serp.organic_rank,
                status: serp.status,
                value: serp.url
            });
        }
    });
    return list;
};

export const getDefaultUrlToCompare = (serpList: SerpResponseInterface[], hasDomain?: null | string): DefaultSelectedUrlTypesInterface => {
    const { projectId } = getContext();

    let targetUrl: string = "";
    let compatitorUrl: string = "";

    const myDomain = find(serpList, (serp: SerpResponseInterface) => {
        return serp.is_my_domain && serp.is_organic;
    });
    if (!isUndefined(hasDomain) && isUndefined(myDomain) && !isUndefined(projectId)) {
        const myRankedDomain = filter(serpList, {
            is_my_domain: true
        });
        targetUrl = myRankedDomain.length > 0 ? myRankedDomain[0].url : "";
        compatitorUrl = filter(serpList, {
            is_organic: true
        })[1].url;
    } else if (!isUndefined(hasDomain) && isUndefined(myDomain) && isUndefined(projectId)) {
        targetUrl = filter(serpList, { is_organic: true })[0].url;
        compatitorUrl = filter(serpList, { is_organic: true })[1].url;
    } else if (!isNull(hasDomain) && !isUndefined(hasDomain) && !isUndefined(myDomain)) {
        const compatitor: SerpResponseInterface[] = filter(serpList, (serp: SerpResponseInterface) => {
            return serp.is_organic && serp.organic_rank !== myDomain.organic_rank;
        });
        targetUrl = !isUndefined(myDomain) ? myDomain.url : filter(serpList, { is_organic: true })[0].url;
        compatitorUrl = compatitor.length > 0 ? compatitor[0].url : filter(serpList, { is_organic: true })[1].url;
    } else {
        const list = filter(serpList, { is_organic: true });
        targetUrl = list[0].url;
        compatitorUrl = list[1].url;
    }
    return {
        compatitorUrl,
        targetUrl
    };
};

export const filterTopTenUrl = (serpList: SerpResponseInterface[]): SerpResponseInterface[] => {
    return filter(serpList, (serp: SerpResponseInterface) => {
        return serp.organic_rank <= 10;
    });
};

export const clubMyDomain = (serpList: SerpResponseInterface[]): SerpResponseInterface[] => {
    const serpWithOrganicRank = filter(serpList, (serp: SerpResponseInterface) => {
        return serp.is_organic;
    });

    return orderBy(serpWithOrganicRank, ["is_my_domain", "organic_rank"], ["desc", "asc"]);
};

export const ClubList = (serpList: SerpResponseInterface[], list: string[]): SerpResponseInterface[] => {
    const formattedTarrget = filter(
        serpList,
        // tslint:disable-next-line: brace-style
        (serp: SerpResponseInterface) => {
            return list.indexOf(serp.url) !== -1;
            // if (targetList.indexOf(serp.url) !== -1) {
            //      return (serp);
            // };
        }
    );
    return formattedTarrget;
};

export const clubMyProjectFlowDomain = (serpList: SerpResponseInterface[]): SerpResponseInterface[] => {
    const serpWithOrganicRank = filter(serpList, (serp: SerpResponseInterface) => {
        return serp.is_my_domain;
    });

    return orderBy(serpWithOrganicRank, ["is_my_domain", "organic_rank"], ["desc", "asc"]);
};
export const getImportanceColor = (args: string): string => {
    const foundColor = find(IMPORTANCE_COLOR_SCHEME, (color: ImportanceColorSchemeInterface) => {
        return color.importance === args;
    });
    return !isUndefined(foundColor) ? foundColor.color : "";
};

export const getParamScoreV2 = (
    targetValue: number | boolean | null,
    parameter: string,
    allCompatitorParam: KeyWordScoreParameterInterface[],
    unit: string | null
): ParameterScoreComparisionInterface => {
    let scores: ParameterScoreComparisionInterface = {
        compatitor: {
            actualScore: -1,
            calculatedScore: -1
        },
        target: {
            actualScore: -1,
            calculatedScore: -1
        },
        unit: unit ? unit : ""
    };

    const paramInCompatitor = find(allCompatitorParam, (param: KeyWordScoreParameterInterface) => {
        return param.parameter === parameter;
    });

    const compatitorValue = !isUndefined(paramInCompatitor) ? paramInCompatitor.value : -1;

    if (isBoolean(targetValue) || isBoolean(compatitorValue)) {
        scores = {
            compatitor: {
                actualScore: compatitorValue,
                calculatedScore: -1
            },
            target: {
                actualScore: targetValue,
                calculatedScore: -1
            },
            unit: unit ? unit : ""
        };
    } else {
        const mx = max([isNull(targetValue) ? 0 : targetValue, isNull(compatitorValue) ? 0 : compatitorValue]);
        const calculatedMax = !isUndefined(mx) ? mx + (10 * mx) / 100 : 0;
        scores = {
            compatitor: {
                actualScore: compatitorValue,
                calculatedScore: mx === 0 || isNull(compatitorValue) ? 0 : (compatitorValue / calculatedMax) * 100
            },
            target: {
                actualScore: targetValue,
                calculatedScore: mx === 0 || isNull(targetValue) ? 0 : (targetValue / calculatedMax) * 100
            },
            unit: unit ? unit : ""
        };
    }
    return scores;
};

export const getScoreGap = (
    p: KeyWordScoreParameterInterface,
    allCompatitorParam: KeyWordScoreParameterInterface[]
): number | null | boolean => {
    let gap: number | null | boolean = 0;
    if (isNull(p.value) || allCompatitorParam.length === 0) {
        gap = null;
    } else if (isBoolean(p.value)) {
        gap = true;
    } else if (isNumber(p.value)) {
        const paramOfCompatitor = find(allCompatitorParam, (param: KeyWordScoreParameterInterface) => {
            return param.parameter === p.parameter;
        });
        if (!isUndefined(paramOfCompatitor) && isNumber(paramOfCompatitor.value)) {
            gap = round(p.value - paramOfCompatitor.value) * parseInt(p.Correlation, 10);
        } else if (!isUndefined(paramOfCompatitor) && isNull(paramOfCompatitor.value)) {
            gap = null;
        }
    }
    return gap;
};

export const kFormatter = (num: number): string | number => {
    let formattedNumber: number | string = num;
    if (num < 1e3) {
        formattedNumber = round(num, 1);
    }
    if (num >= 1e3 && num < 1e6) {
        formattedNumber = `${round(+(num / 1e3), 1)}K`;
    }
    if (num >= 1e6 && num < 1e9) {
        formattedNumber = `${round(+(num / 1e6), 1)}M`;
    }

    if (num >= 1e9 && num < 1e12) {
        formattedNumber = `${round(+(num / 1e9), 1)}B`;
    }

    if (num >= 1e12) {
        formattedNumber = `${round(+(num / 1e12), 1)}T`;
    }
    return formattedNumber;
};

export const getDefaultSorting = (): string | number => {
    const defaultOption = find(SORTING_OPTIONS, (option: OptionTypeInterface) => {
        return option.default === true;
    });
    return isUndefined(defaultOption) ? (SORTING_OPTIONS[0].value as string) : (defaultOption.value as string);
};

export const getSortedCategory = (
    filteredParam: KeyWordScoreParameterInterface[],
    appliedSorting: string
): KeyWordScoreParameterInterface[] => {
    const sortOrder: OptionTypeInterface | undefined = find(
        SORTING_OPTIONS,
        (options: OptionTypeInterface) => options.value === appliedSorting
    );
    let sortedParam = filteredParam;

    if (appliedSorting === ASCENDING) {
        sortedParam = orderBy(filteredParam, ["sub_category_rank", "param_disp_name"], [ASCENDING, ASCENDING]);
    } else if (appliedSorting === DESCENDING) {
        sortedParam = orderBy(filteredParam, ["sub_category_rank", "param_disp_name"], [ASCENDING, DESCENDING]);
    } else if (appliedSorting === LOW_TO_HIGH) {
        const data = orderBy(filteredParam, ["sub_category_rank", "gap_value"], [ASCENDING, DESCENDING]);
        sortedParam = [
            ...data.filter((x: KeyWordScoreParameterInterface) => x.gap_value !== null),
            ...data.filter((x: KeyWordScoreParameterInterface) => x.gap_value === null)
        ];
    } else if (appliedSorting === HIGH_TO_LOW) {
        sortedParam = orderBy(filteredParam, ["sub_category_rank", "gap_value"], [ASCENDING]);
    } else {
        sortedParam = !isUndefined(sortOrder)
            ? sortBy(
                  filteredParam,
                  // tslint:disable-next-line:ter-indent
                  [
                      // tslint:disable-next-line:ter-indent
                      "sub_category_rank",
                      // tslint:disable-next-line:ter-indent
                      (param: KeyWordScoreParameterInterface) => {
                          return (sortOrder.order as string[]).indexOf(
                              // param.parameter
                              param.importance
                          );
                      }
                      // tslint:disable-next-line:ter-indent
                  ],
                  [ASCENDING]
              )
            : filteredParam;
    }
    return sortedParam;
};
export const getCATInfo = (
    targetScore: ScoresInterface[],
    compatitorScore: ScoresInterface[],
    selectedCategory: CategoryType
): CATScorePercentInterface => {
    /** for more better performance, we should move all these calculation in selector  */
    const selectedCompatitorScore = find(compatitorScore, (score: ScoresInterface) => {
        return score.type === selectedCategory;
    });
    const selectedTargetScore = find(targetScore, (score: ScoresInterface) => {
        return score.type === selectedCategory;
    });
    /**
     * ALPS/Total is to be calculated out of 100 ie. (score*100)/100 = score
     * Authority,Content & Technical shoud be calculated out of 10 ie. (score*100)/10 = score*10
     */
    const compatitor = !isUndefined(selectedCompatitorScore)
        ? !isNull(selectedCompatitorScore.value)
            ? round(selectedCompatitorScore.value)
            : -1
        : -1;

    const target = !isUndefined(selectedTargetScore) ? (!isNull(selectedTargetScore.value) ? round(selectedTargetScore.value) : -1) : -1;

    return {
        compatitor,
        target
    };
};
export const getTopTenCompatitorUrlList = (compatitorList: OptionTypeInterface[]): CompatitorTopTenUrlList[] => {
    const list: CompatitorTopTenUrlList[] = map(compatitorList, (compatitor: OptionTypeInterface) => {
        return {
            rank: compatitor.rank as number,
            url: compatitor.value as string
        };
    });
    return list;
};
