import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */
const actionTypes = {
    SET_KW_RESEARCH_INPUT_TYPE: "SET_KW_SEARCH_INPUT_TYPE",
    CALL_TO_GET_KW_RESEARCH_REQUEST_ID: "CALL_TO_GET_KW_RESEARCH_REQUEST_ID",
    SET_KW_RESEARCH_INPUT_VALUE: "SET_KW_RESEARCH_INPUT_VALUE",
    SET_KW_RESEARCH_REQUEST_ID: "SET_KW_RESEARCH_REQUEST_ID",
    CALL_TO_GET_RELATED_KEYWORD_FROM_SSE_API: "CALL_TO_GET_RELATED_KEYWORD_FROM_SSE_API",
    INCREMENT_RECEIVE_RELATED_KWDS_EVENT_COUNT: "INCREMENT_RECEIVE_RELATED_KWDS_EVENT_COUNT",
    RESET_KW_RESEARCH: "RESET_KW_RESEARCH",
    SET_RELATED_KEYWORDS_FETCHING: "SET_RELATED_KEYWORDS_FETCHING",
    SET_SSE_RETRIED_ATTEMPT: "SET_SSE_RETRIED_ATTEMPT",
    SET_HIDE_LOADER: "SET_HIDE_LOADER",
    SET_SHOW_LOADER: "SET_SHOW_LOADER",
    RESET_RECEIVE_RELATED_KWDS_EVENT_COUNT: "RESET_RECEIVE_RELATED_KWDS_EVENT_COUNT",
    SET_RELATED_KW: "SET_RELATED_KW",
    APPLIED_KW_FILTERS: "APPLIED_KW_FILTERS",
    CLEAR_APPLIED_KW_FILTERS: "CLEAR_APPLIED_KW_FILTERS",
    KRT_INCLUDE_KEYWORD_TYPE: "KRT_INCLUDE_KEYWORD_TYPE",
    SET_INCLUDE_KEYWORDS: "SET_INCLUDE_KEYWORDS",
    SET_EXCLUDE_KEYWORDS: "SET_EXCLUDE_KEYWORDS",
    SET_ADVANCE_FILTER_TYPE: "SET_ADVANCE_FILTER_TYPE",
    SET_SELECTED_SEARCH_URL: "SET_SELECTED_SEARCH_URL",
};
export default actionTypes;

export const setSelectedInputType = createAction(actionTypes.SET_KW_RESEARCH_INPUT_TYPE);
export const resetKwResearch = createAction(actionTypes.RESET_KW_RESEARCH);
export const setSearchInputValue = createAction(actionTypes.SET_KW_RESEARCH_INPUT_VALUE);
export const setKeywordResearchRequestId = createAction(actionTypes.SET_KW_RESEARCH_REQUEST_ID);
export const callToGetKwResearchRequestId = createAction(actionTypes.CALL_TO_GET_KW_RESEARCH_REQUEST_ID);
export const callToGetRelatedKeywordFromSseApi = createAction(actionTypes.CALL_TO_GET_RELATED_KEYWORD_FROM_SSE_API);
export const incrementReceiveRelatedKwdsEventCount = createAction(actionTypes.INCREMENT_RECEIVE_RELATED_KWDS_EVENT_COUNT);
export const setRelatedKeywordsFetching = createAction(actionTypes.SET_RELATED_KEYWORDS_FETCHING);
export const setSSeRetriedAttempt = createAction(actionTypes.SET_SSE_RETRIED_ATTEMPT);
export const setShowLoader = createAction(actionTypes.SET_SHOW_LOADER);
export const setHideLoader = createAction(actionTypes.SET_HIDE_LOADER);
export const resetReceiveRelatedKwdsEventCount = createAction(actionTypes.RESET_RECEIVE_RELATED_KWDS_EVENT_COUNT);
export const setRelatedKeywords = createAction(actionTypes.SET_RELATED_KW);
export const appliedKWFilters = createAction(actionTypes.APPLIED_KW_FILTERS);
export const clearAppliedKWFilters = createAction(actionTypes.CLEAR_APPLIED_KW_FILTERS);
export const setIncludeKeywordType = createAction(actionTypes.KRT_INCLUDE_KEYWORD_TYPE);
export const setIncludeKeywords = createAction(actionTypes.SET_INCLUDE_KEYWORDS);
export const setExcludeKeywords = createAction(actionTypes.SET_EXCLUDE_KEYWORDS);
export const setAdvanceFilterType = createAction(actionTypes.SET_ADVANCE_FILTER_TYPE);
export const setSelectedSearchURL = createAction(actionTypes.SET_SELECTED_SEARCH_URL);
