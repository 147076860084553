import { createSelector } from "reselect";
import {
    ApplicationStateInterface,
    FlowTypeInterface,
    KeywordRequestIdsInterface,
    SerpListProgressStatusType
} from "../../../../../app/duck/types";
import { isUndefined, isEqual } from "lodash";
import { OptionTypeInterface } from "../../../../../app/styledComponents/drop-down/types";
import { getFormattedUrlList } from "../../../../../app/duck/utils";
import {
    filterKeywordBasedOnKeywordAndBrandType,
    filterKeywordBasedOnSearchVolume,
    filterKeywordBasedOnRelevanceScore,
    filterKeywordBasedOnTheme,
    getSortedKeywordList
} from "./utils";
import { advanceFilterSearch } from "../../../../../kw-research/ducks/utils";

const getAllTargetList = (state: ApplicationStateInterface): OptionTypeInterface[] => {
    const formattedTarget = getFormattedUrlList(state.app.target_urls);
    return formattedTarget;
};
export const filterTargetUrl = createSelector(getAllTargetList, (list: OptionTypeInterface[]) => list);
const getAllFormattedKeywordList = (state: ApplicationStateInterface): OptionTypeInterface[] | undefined => {
    const {
        filterType,
        includeKeywordType,
        searchExcludeKeywords,
        searchIncludeKeywords,
        filteredSearchKeyword,
        keywordList = [],
        appliedFiltersOnKeyword,
        themeList = []
    } = state.multiKeywordBeta;
    const { keywordtype = [], brandtype = [] } = appliedFiltersOnKeyword;
    const formattedkeywordList = !isUndefined(filteredSearchKeyword) ? filteredSearchKeyword : keywordList;
    const targetUrl: string =
        state.context.flowType === FlowTypeInterface.RANK_SIMULATOR
            ? (state.app.selectedTargetUrl as string)
            : (state.context.selectedDomain as string);

    let filteredKeywords: OptionTypeInterface[] | undefined = [];

    if (!isUndefined(appliedFiltersOnKeyword.theme) && appliedFiltersOnKeyword.theme.length) {
        filteredKeywords = filterKeywordBasedOnTheme(appliedFiltersOnKeyword.theme, themeList, formattedkeywordList);
    } else {
        filteredKeywords = formattedkeywordList;
    }
    if (!isUndefined(appliedFiltersOnKeyword.searchvolume) && appliedFiltersOnKeyword.searchvolume.length) {
        filteredKeywords = filterKeywordBasedOnSearchVolume(appliedFiltersOnKeyword.searchvolume, filteredKeywords);
    }
    if (!isUndefined(appliedFiltersOnKeyword.relevancescore) && appliedFiltersOnKeyword.relevancescore.length) {
        filteredKeywords = filterKeywordBasedOnRelevanceScore(appliedFiltersOnKeyword.relevancescore, filteredKeywords);
    }
    if (!isEqual(appliedFiltersOnKeyword.sorttype, "")) {
        filteredKeywords = getSortedKeywordList(filteredKeywords, appliedFiltersOnKeyword.sorttype);
        filteredKeywords = advanceFilterSearch(
            searchIncludeKeywords,
            searchExcludeKeywords,
            includeKeywordType,
            filteredKeywords,
            filterType
        );
    }

    if (keywordtype.length || brandtype.length) {
        filteredKeywords = filterKeywordBasedOnKeywordAndBrandType(
            brandtype as string[],
            keywordtype as number[],
            themeList,
            filteredKeywords,
            targetUrl
        );
    }

    return filteredKeywords;
};

export const formattedKeywordList = createSelector(getAllFormattedKeywordList, (list: OptionTypeInterface[] | undefined) => list);
const extractSimVersionChangeInitFlag = (state: ApplicationStateInterface): boolean => {
    return state.simulatorBeta.simulationsVersionChangeFlag;
};
export const getSimulationsVersionChangeFlag = createSelector(extractSimVersionChangeInitFlag, (flag: boolean) => flag);

const extractTargetUrlStatus = (state: ApplicationStateInterface): boolean => {
    return Object.values(state.app?.target_urls)[0]?.status === SerpListProgressStatusType.DONE;
};
export const getTargetUrlFlag = createSelector(extractTargetUrlStatus, (flag: boolean) => flag);

const extractSimulatePerformanceFlag = (state: ApplicationStateInterface): boolean => {
    return state.multiKeywordBeta.enableSimulatePerformance;
};

export const getSimulatePerformanceFlag = createSelector(extractSimulatePerformanceFlag, (flag: boolean) => flag);

const extractTimesSseRetried = (state: ApplicationStateInterface): number => {
    return state.multiKeywordBeta.sseRetriedAttempt;
};

const extractTimesSseRetriedForURLParam = (state: ApplicationStateInterface): number => {
    return state.multiKeywordBeta.sseRetriedAttemptURLParam;
};

const extractTimesSseRetriedForKeywordParam = (state: ApplicationStateInterface): number => {
    return state.multiKeywordBeta.sseRetriedAttemptKwParam;
};

const extractTimesNonLiveSseRetried = (state: ApplicationStateInterface): number => {
    return state.multiKeywordBeta.sseNonLiveRetriedAttempt;
};

const getCompetitorProcessedStatus = (state: ApplicationStateInterface): boolean => {
    return state.multiKeywordBeta.enableCompetitorApi;
};
const multiKeywordDomainUrl = (state: ApplicationStateInterface): string | undefined => {
    return state.context.domainAliasUrl;
};
const multiKeywordLocale = (state: ApplicationStateInterface): string | undefined => {
    return state.multiKeywordBeta.selectedMultiKeywordLocale;
};
const selectedKeywords = (state: ApplicationStateInterface): string[] => {
    return state.multiKeywordBeta.selectedKeywords;
};
const contextLocale = (state: ApplicationStateInterface): string | undefined => {
    return state.context.selectedLocale;
};

const enableSimulateScore = (state: ApplicationStateInterface): boolean => {
    return state.multiKeywordBeta.enableSimulateScore;
};

const allKeywords = (state: ApplicationStateInterface): KeywordRequestIdsInterface | undefined => {
    return state.multiKeywordBeta.keywordRequestIds;
};

export const getSSeRetriedAttempts = createSelector(extractTimesSseRetried, (retriedtimes: number) => retriedtimes);

export const getSSeRetriedAttemptsForURLParam = createSelector(extractTimesSseRetriedForURLParam, (retriedtimes: number) => retriedtimes);

export const getSSeRetriedAttemptsForKeywordParam = createSelector(
    extractTimesSseRetriedForKeywordParam,
    (retriedtimes: number) => retriedtimes
);

export const getNonLiveSSeRetriedAttempts = createSelector(extractTimesNonLiveSseRetried, (retriedtimes: number) => retriedtimes);

export const getCompetitorStatus = createSelector(getCompetitorProcessedStatus, (flag: boolean) => flag);

export const getMultiKeywordDomainUrl = createSelector(multiKeywordDomainUrl, (url: string | undefined) => url);
export const getMultiKeywordLocale = createSelector(multiKeywordLocale, (locale: string | undefined) => locale);
export const getSelectedKeywords = createSelector(selectedKeywords, (keywords: string[]) => keywords);
export const getContextLocale = createSelector(contextLocale, (locale: string | undefined) => locale);
export const getEnableSimulateScore = createSelector(enableSimulateScore, (enableSimulateScoreFlag: boolean) => enableSimulateScoreFlag);
export const getAllKeywords = createSelector(allKeywords, (keywordRequestIds: KeywordRequestIdsInterface | undefined) => keywordRequestIds);
