import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import actionTypes from "./actions";
import { AutoLoginReducerInterface } from "./types";

export const INIT_STATE: AutoLoginReducerInterface = {
    userDetails: {
        search_engine: "",
        default_project: 0,
        user_id: "",
        name: "",
        locale: "",
        tenant_list: [],
        has_project: false,
        is_standard: false,
        is_mobile_project: false,
        tenant_code: "",
        user_email: "",
    },
    tenantList: {
        display_name: "",
        id: ""
    },
    isAPILoading: false,
    isSessionExpired: false,
};

const Reducer = handleActions(
    {
        [actionTypes.SAVE_LOGIN_DETAILS]: (state, action: AnyAction) => ({
            ...state,
            userDetails: action.payload
        }),
        [actionTypes.SAVE_TENANT_LIST]: (state, action: AnyAction) => ({
            ...state,
            tenantList: action.payload
        }),
        [actionTypes.IS_API_LOADING]: (state, action: AnyAction) => ({
            ...state,
            isAPILoading: action.payload
        }),
        [actionTypes.IS_SESSION_EXPIRED]: (state, action: AnyAction) => ({
            ...state,
            isSessionExpired: action.payload
        })
    },
    INIT_STATE
);

export default Reducer;

