import { isUndefined } from "lodash";
import * as React from "react";
import styled, { css } from "styled-components";
import { StyledComponentStylesType } from "../duck/types";

export interface CheckboxStylesPropsInterface {
    Wrapper?: StyledComponentStylesType;
    Checkbox?: StyledComponentStylesType;
    Label?: StyledComponentStylesType;
    UrlLabel?: StyledComponentStylesType;
}

export interface CheckboxPropsInterface {
    name: string;
    label?: string | JSX.Element | React.SFC;
    disabled?: boolean;
    isChecked: boolean;
    styles?: CheckboxStylesPropsInterface;
    instanceId?: string;
    reference?: string;
    hasUrl?: boolean;
    onChangeHandler?(e: React.ChangeEvent<HTMLInputElement>): void;
    onFocus?(e: React.FocusEvent<HTMLInputElement>): void;
    onKeyDownHandler?(e: React.KeyboardEvent<HTMLInputElement>): void;
    showToolTip?(val: boolean): void;
}

export const StyledWrapper = styled.label`
    position: relative;
    float: left;
    ${(props: { reference?: string }) =>
        (props.reference === "kw_level_impact_sidebar_filter" || props.reference === "kw_level_impact_simulation_url") &&
        css`
            float: none;
            display: inline-block;
            vertical-align: middle;
            left: 20px;
            width: 210px;
            margin-bottom: 6px;
        `}
`;

export const StyledLabel = styled.span`
    color: ${(props: { disabled: boolean }) => (props.disabled ? "#afafaf" : "#343434")};
    font-size: 12px;
    font-weight: 400;
    float: left;
    position: relative;
    cursor: ${(props: { disabled: boolean }) => (props.disabled ? "default" : "pointer")};
    padding: 0;
    display: flex;
    align-items: center;
    &::before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: ${(props: { reference?: string; disabled?: boolean }) => ((props.reference === "category_list_checkbox" || props.reference === "manage-keywords-checkbox") ? "14px" : "16px")};
        height: ${(props: { reference?: string; disabled?: boolean }) => ((props.reference === "category_list_checkbox" || props.reference === "manage-keywords-checkbox") ? "14px" : "16px")};
        background: #fff;
        border: ${(props: { reference?: string; disabled?: boolean }) => (props.disabled ? "1px solid #afafaf" : "1px solid #343434")};
        margin-top: ${(props: { disabled: boolean; reference?: string }) => (props?.reference === "manage-keywords-checkbox" ? "1px" : "2px")};
        border-radius: 2px;
    }
    ${(props: { reference?: string; disabled?: boolean }) =>
        props.reference === "kw_level_impact_sidebar_filter" &&
        css`
            white-space: nowrap;
        `}

    ${(props: { reference?: string; disabled?: boolean }) =>
        props.reference === "kw_level_impact_simulation_url" &&
        css`
            white-space: nowrap;
            font-weight: bold;
        `}
`;

export const StyledCheckbox = styled.input`
    left: 0px;
    float: left;
    margin-right: 10px;
    position: absolute;
    opacity: 0;
    &:checked + ${StyledLabel} {
        color: ${(props: { disabled: boolean }) => (props.disabled ? "#afafaf" : "#343434")};
        &::before {
            /* background: #00acce; */
            background: ${(props: { disabled: boolean }) => (props.disabled ? "#fff" : "#00acce")};
            /* border: 1px solid #00acce; */
            border: ${(props: { disabled: boolean }) => (props.disabled ? "1px solid #afafaf" : "1px solid #00acce")};
        }
        ${(prop: { disabled: boolean }) =>
        !prop.disabled &&
        css`
                &::after {
                    content: "";
                    position: absolute;
                    left: ${(props: { disabled: boolean; reference?: string }) => (props?.reference === "manage-keywords-checkbox" ? "3px" : "4px")};
                    top: ${(props: { disabled: boolean; reference?: string }) => (props?.reference === "manage-keywords-checkbox" ? "8px" : "9px")};
                    background: ${(props: { disabled: boolean }) => (props.disabled ? "#afafaf" : "#fff")};
                    width: 2px;
                    height: 2px;
                    box-shadow: 2px 0 0 ${(props: { disabled: boolean }) => (props.disabled ? "#afafaf" : "#fff")},
                        4px 0 0 ${(props: { disabled: boolean }) => (props.disabled ? "#afafaf" : "#fff")},
                        4px -2px 0 ${(props: { disabled: boolean }) => (props.disabled ? "#afafaf" : "#fff")},
                        4px -4px 0 ${(props: { disabled: boolean }) => (props.disabled ? "#afafaf" : "#fff")},
                        4px -6px 0 ${(props: { disabled: boolean }) => (props.disabled ? "#afafaf" : "#fff")},
                        4px -8px 0 ${(props: { disabled: boolean }) => (props.disabled ? "#afafaf" : "#fff")};
                    transform: rotate(45deg);
                }
            `}
    }
`;

export const StyledUrlLabel = styled.a`
    width: 181px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${(props: { disabled: boolean }) => (props.disabled ? "#afafaf" : "#025d92")};
    font-size: 12px;
    font-weight: 400;
    float: left;
    position: relative;
    cursor: pointer;
    padding: 0;
    display: inline;
    align-items: center;
    margin-top: 4px;
    &:visited {
        color: #025d92;
    }
`;

const CheckboxComponent: React.SFC<CheckboxPropsInterface> = ({
    label = "",
    isChecked = false,
    disabled = false,
    styles = {},
    reference,
    onChangeHandler,
    onFocus,
    showToolTip,
    onKeyDownHandler,
    instanceId,
    hasUrl,
    name
}) => {
    const { Wrapper = StyledWrapper, Checkbox = StyledCheckbox, Label = StyledLabel, UrlLabel = StyledUrlLabel } = styles;
    // tslint:disable-next-line:no-any
    const ref = React.useRef<any>();

    React.useEffect(() => {
        if (!isUndefined(showToolTip)) {
            if (ref.current.scrollWidth > ref.current.clientWidth) {
                showToolTip(true);
            } else {
                showToolTip(false);
            }
        }
    }, [ref]);

    const labelUrl = "http://" + `${name}`;
    return (
        <Wrapper reference={reference}>
            <Checkbox
                className="kli_checkbox"
                type="checkbox"
                disabled={disabled}
                checked={isChecked}
                onFocus={onFocus}
                onKeyDown={onKeyDownHandler}
                name={name}
                {...{
                    ...(!disabled && { onChange: onChangeHandler }),
                    ...(instanceId && { id: instanceId }),
                    ...(reference && { reference })
                }}
            />
            {hasUrl ?
                <>
                    <Label reference={reference} disabled={disabled} innerRef={ref} />
                    <UrlLabel href={labelUrl} target="_blank">{label}</UrlLabel>
                </>
                :
                <Label reference={reference} disabled={disabled} innerRef={ref}>
                    {label}
                </Label>
            }
        </Wrapper>
    );
};

export default CheckboxComponent;
