import { AnyAction } from "redux";
import { put, takeEvery, call } from "redux-saga/effects";
import { map } from "lodash";
import Apis from "../../../app/apis";
import actionTypes from "./actions";
import { setSerpDetailsInApp } from "../../../app/duck/actions";
import { SerpResponseInterface, SerpListResponseInterface } from "../../../app/duck/types";

// FETCH CALL DEFINATIONS

// tslint:disable-next-line:typedef
function* fetchSerpData(action: AnyAction) {
    const getUpdatedSerpList = (serpList: SerpResponseInterface[]) =>
        map(
            serpList,
            (row: SerpListResponseInterface): SerpResponseInterface => ({
                ...row,
                status: "QUEUED"
            })
        );
    const payload = {
        ...action.payload,
        needToHandleError: true,
        isBeta: true
    };
    const response = yield call(Apis.fetchSerpData, payload);

    if (response.status === 200) {
        // yield put(setSerpData());
        yield put(
            setSerpDetailsInApp({
                serpList: getUpdatedSerpList(response.data.data.serp),
                serpPacks: response.data.data.keyword_packs,
                serpSearchVolume: response.data.data.search_volume,
                serpTopRank: response.data.data.top_rank,
                serp_date: response.data.data.serp_date,
                isProjectContext: response.data.data.is_project_context
            })
        );
    }
}

// FETCH WATCHERS

// tslint:disable-next-line:typedef
export function* watchForGetSerpDataCall() {
    yield takeEvery(actionTypes.CALL_TO_GET_SERP_DATA, fetchSerpData);
}
