import { StyledLegendInterface } from "../../../../app/styledComponents/legends";
import { COPYInterface } from "../../../../app/duck/types";

export const COPY_VERSION_SELECT: COPYInterface = {
    TARGET: "Target",
    DOWNLOAD_BUTTON_DISABLED: "Preparing data for download. Please try after some time.",
    CONTENT_SIMULATOR_EDITOR_TITLE: "Simulator",
    SIMULATOR_EDITOR_ARTICLE_VIEW: "Article view",
    SIMULATOR_EDITOR_TAG_VIEW: "Tag view (Read only)",
    OVERALL_SIMULATION_RESET_ALL: "Reset",
    REFRESH_CONTENT_BUTTON: "Refresh Content",
    FULL_SCREEN_MODE_BUTTON: "Full Screen Mode",
    ADD_DESCRIPTION: "Add Description",
    SIMULATOR_EDITOR_MISSING_HEADERS_WARNING:
        "The editor displays only the page's main body content. It removes extra text, such as navigation and footer. Occasionally some H1s/H2s are cleaned as part of the process. That missing text is retained at the top of the editor.",
    CONTENT_SIMULATION_EDIT_DESCRIPTION: "Content Simulation Edit Description",
    CONTENT_SIMULATION_SAVE_DESCRIPTION: "Content Simulation Save Description",
    SIMULATOR_CURRENT_VERSION: "Current version",
    CONTENT_SIMULATION_VERSION: "Content Simulation Version",
    CONTENT_SIMULATION_USER: "Content Simulation User",
    DATE: "Date",
    ORIGINAL: "Original",
    VERSION_DESCRIPTION_PLACEHOLDER: "Add Version Description",
    USER_TEXT: "User",
    SELECT_MODE_EDITOR: "Editor",
    SIMULATOR_VERSION_CHANGE_CONSENT_MESSAGE: "You will lose any pending/un-saved edits. Do you wish to proceed?",
    DO_YOU_WISH_TO_PROCEED_TEXT: " Do you wish to proceed?",
    MODAL_CONSENT_OPTION_YES: "Yes",
    MODAL_CONSENT_OPTION_NO: "No"
};

export const LEGEND_TARGET: StyledLegendInterface[] = [
    {
        title: COPY_VERSION_SELECT.TARGET,
        hexCode: "#3a85ef"
    }
];

export const SIMULATOR_ALERT_NEW_RUN_SIMULATION = {
    MESSAGE:
        "Some data from the previous simulation is being processed and may take a few minutes. If you {{condition}}, some of the metrics from the previous simulation will not be available in simulation history.",
    DEFAULT_CONDITION: "simulate right now",
    INITIATE_CONDITION: "update URL/keywords right now",
    REFRESH_CONDITION: "continue with this refresh action",
    SWITCH_VERSION_TEXT: "switch the version right now"
};

export const EDITOR_MODES = {
    browser: "browser",
    editor: "editor"
};

export const RIGHT_PANEL_METRICS = {
    Content: "Content",
    Authority: "Authority",
    Technical: "Technical"
};

export const COPY_SCORE_ACCORDIAN: COPYInterface = {
    SAVE_SIMULATION_ACTION: "You are about to close/refresh the tab. Do you want to save the current simulation",
    HTTPS_ON_PAGE: "HTTPS on page",
    DEFINITION: "Definition:",
    CORRELATION_TEXT: " Lower the value of the parameter, more optimized it is"
};

export const COPY_AUTH_TECH: COPYInterface = {
    PARAMETER_GROUPS: "Parameter Groups",
    RELATED_KEYWORD_SEARCH_VOLUME_MSG:
        "Please ignore any search volume mismatch between Related and Project Keywords tabs as they could be pulled at different times. Latest search volume will be used in the simulation to ensure accurate results.",
    TECHNICAL_PARAMS_FETCHNIG: "Processing Technical parameters…",
    AUTHORITY_PARAMS_FETCHING: "Processing Backlink details…",
    AVG_OF_TOP_3_URLS: "Avg. of Top 3 URLs",
    METRIC_NAME: "Metric Name"
};

export const visibleContentInitial = [
    { contents: "", title: "url" },
    { contents: [], title: "title" },
    { contents: [], title: "meta_description" },
    { contents: [], title: "missing_h1" },
    { contents: [], title: "missing_h2" },
    { contents: [], title: "missing_h3" },
    { contents: "", title: "stripped_page" }
];

export const REFRESH_NOW_NOTIFICATION_COPY: COPYInterface = {
    REFRESH_NOW_TITLE: "Attention!",
    REFRESH_NOW_MESSAGE: "Our scoring algorithm was recently updated. The scores displayed on this page are from the previous version. Please refresh the data to view the latest scores.",
    REFRESH_NOW_LINK_TEXT: "Refresh Now"
};
