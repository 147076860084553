import {
    map,
    keys,
    has,
    assign,
    isEmpty,
    mapValues,
    filter,
    keyBy,
    find,
    isUndefined,
    isArray,
    isEqual,
    isString,
    forEach,
    uniqBy,
    forIn,
    orderBy,
    sortBy,
    values,
    intersection,
    pickBy,
    isNumber,
    invert,
    isNull,
    uniq,
    each,
    reduce,
    isBoolean
} from "lodash";
import { eventChannel } from "@redux-saga/core";
import {
    OnPageElementsResponseInterface,
    EditorElementInterfaceBeta,
    SimulatorReducerInterface,
    ParameterInterface,
    ChangedParameterInterface,
    ParameterValidationInterface,
    CategoryParamterInterface,
    ForcedChangedParameterInterface,
    ImpactedKeywordsResponseInterface,
    DownloadResponseMapInterface,
    ImpactedKeywordResponseDetailsInterface,
    ImpactedKeywordResponseScoreInterface,
    VersionListResponseInterface,
    CompetitorsScoreResponseInterface,
    Impactedkeywordsurllistinterface,
    KeywordProgressInformationFormatedWithNameInterface,
    KeywordUrlListDataResponseType,
    MetaDetailsInterface,
    KeywordStuffingInterface,
    StuffedKeywordObjectInterface,
    StuffedKeywordListInterface,
    ImpactedKeywordResponseAuthAndTechInterface,
    RecomBulbSubHeaderScoresPayloadInterface,
    KeywordProcessingStatus,
    KeywordProcessingNotificationInterface,
    FormattedSubCategories,
    OnPageElementsTypeBeta
} from "./types";

import {
    OnPageElementsType,
    SerpResponseInterface,
    SerpListProgressStatusType,
    CompetitorAndTargetURLsListInterface,
    FilterSortInterface,
    AppliedFilterSortInterface,
    AppliedFilterSort,
    MultiKeywordTrackRequestApiRequestIdsResponseInterface,
    MultiKeywordTrackRequestApiRequestIdStatusResponseInterface,
    MultiKeywordRequestIdsApiResponseInterface,
    UrlDomainType,
    KeywordRequestIdsInterface,
    SaveSimulationHistory
} from "../../../app/duck/types";
import {
    OVERALL_SORTING_OPTIONS,
    ASCENDING,
    DESCENDING,
    DEPENDENT_PARAMETERS,
    COPY,
    DEFAULT_FOR_DATA_FETCH_FAIL,
    KW_IMPACT_CSV_HEADER_BETA,
    SIMULATION_REPORT_KW_IMPACT_FILE_NAME,
    DEFAULT_SELECTED_USER,
    SIMULATION_REPORT_KW_IMPACT_XLSX_FILE_NAME,
    RETURN_CLASS
} from "../../../app/const";
import { downloadToCsv, downloadToXlsx, getTrimmedURL, isValidDomain } from "../../../app/duck/utils";
import { OptionTypeInterface } from "../../../app/styledComponents/drop-down/types";
import { findParameter } from "./selector";
import * as Excel from "exceljs";
import { AnyAction } from "redux";
import { EDITOR_ELEMENTS_DISPLAY_ORDER_BETA, SIMULATOR_CONST } from "./const";
import { EDITOR_MODES } from "../Components/ducks/const";

export const formatOnPageElements = (pageElements: OnPageElementsResponseInterface): EditorElementInterfaceBeta[] => {
    // @ts-ignore {<check for this return type >}
    return map(EDITOR_ELEMENTS_DISPLAY_ORDER_BETA, (element: OnPageElementsTypeBeta) => ({
        contents: pageElements && pageElements[element] ? pageElements[element] : "",
        title: element
    }));
};

export const formatOnPageChangedElement = (args: EditorElementInterfaceBeta[]): OnPageElementsResponseInterface =>
    mapValues(keyBy(args, "title"), "contents");

export const copyChangedContentToPageContent = (
    state: SimulatorReducerInterface,
    editorKey: string = "content"
): EditorElementInterfaceBeta[] => {
    const stateContent = state.content;
    const copiedContent = map(stateContent.pageContent, (content: EditorElementInterfaceBeta) => {
        const changedContent = find(stateContent.changedPageContent, {
            title: content.title
        });
        if (!isUndefined(changedContent) && !isUndefined(changedContent.contents)) {
            return {
                contents: changedContent.contents,
                title: changedContent.title
            };
        }
        return content;
    });
    return copiedContent;
};

export const isEditorValid = (editorContent?: OnPageElementsResponseInterface): boolean => {
    return isUndefined(editorContent) ? true : isUndefined(editorContent.url) ? false : isValidDomain(editorContent.url as string);
};

export const getChangedData = (
    original: EditorElementInterfaceBeta[],
    changed: EditorElementInterfaceBeta[]
): EditorElementInterfaceBeta[] => {
    const originalObj = formatOnPageChangedElement(original);
    const changedDataArray: EditorElementInterfaceBeta[] = [];

    forEach(changed, (changedElem: EditorElementInterfaceBeta) => {
        if (isArray(changedElem.contents) && !isEqual(changedElem.contents, originalObj[changedElem.title])) {
            changedDataArray.push(changedElem);
        } else if (isString(changedElem.contents) && changedElem.contents !== originalObj[changedElem.title]) {
            const newContent = {
                ...changedElem,
                contents: changedElem.contents.replace(/\s+/g, " ")
            };
            changedDataArray.push(newContent);
        }
    });
    return changedDataArray;
};

export const getUrlDetails = (serpList: SerpResponseInterface[], urls: CompetitorAndTargetURLsListInterface): SerpResponseInterface[] => {
    let formattedList;

    formattedList = filter(serpList, (serp: SerpResponseInterface) => {
        return urls.hasOwnProperty(serp.url) && serp.status === SerpListProgressStatusType.DONE;
    });

    return formattedList;
};

export const getParamsInSubCategory = (subCategory: string, parameters: ParameterInterface[]): ParameterInterface[] =>
    filter(parameters, (p: ParameterInterface) => p.param_sub_category === subCategory);

export const collapseAll = (subcategories: FormattedSubCategories[]): FormattedSubCategories[] => {
    return map(subcategories, (subcateogry: FormattedSubCategories) => ({
        ...subcateogry,

        isOpen:
            subcateogry.param_sub_category === "Backlink Quantity" || subcateogry.param_sub_category === "Core Web Vitals" ? true : false
    }));
};

export const isTechAuthParameterBackToOriginal = (
    changedParameter: ChangedParameterInterface,
    originalParameter: ParameterInterface[]
): boolean => {
    let isBackToOriginal = false;
    if (isEmpty(changedParameter)) {
        isBackToOriginal = true;
    } else {
        const equalToOriginal = filter(keys(changedParameter), (param: string) => {
            const paramInOriginal = find(originalParameter, ["parameter", param]) as ParameterInterface;
            return paramInOriginal.value === Number(changedParameter[param]);
        });

        isBackToOriginal = keys(changedParameter).length === equalToOriginal.length;
    }
    return isBackToOriginal;
};
export const collapseDetailSection = (parameter: ParameterInterface[]): ParameterInterface[] => {
    return map(parameter, (param: ParameterInterface) => ({
        ...param,
        isDetailOpen: false
    }));
};
export const postSimulationForcedChangedParam = (paramName: ForcedChangedParameterInterface): ChangedParameterInterface => {
    const newobj: ChangedParameterInterface = {};
    forIn(paramName, (value: CategoryParamterInterface, key: string) => {
        newobj[key] = value.value;
    });
    const newStateChangeParam = assign(
        {},
        {
            ...newobj
        }
    );
    return newStateChangeParam;
};
export const updateValueIntechAuthAppliedFilter = (
    appliedValue: FilterSortInterface,
    prevValue: AppliedFilterSortInterface
): AppliedFilterSortInterface => {
    const data = appliedValue.type;
    if (has(prevValue, appliedValue.type)) {
        // @ts-ignore
        prevValue[data as string] = appliedValue.value;
    }
    return prevValue;
};
export const getAuthTechSortedCategory = (filteredParam: ParameterInterface[], appliedSorting: string): ParameterInterface[] => {
    const sortOrder: OptionTypeInterface | undefined = find(
        OVERALL_SORTING_OPTIONS,
        (options: OptionTypeInterface) => options.value === appliedSorting
    );
    let sortedParam = filteredParam;

    if (appliedSorting === ASCENDING) {
        sortedParam = orderBy(filteredParam, ["sub_category_rank", "param_disp_name"], [ASCENDING, ASCENDING]);
    } else if (appliedSorting === DESCENDING) {
        sortedParam = orderBy(filteredParam, ["sub_category_rank", "param_disp_name"], [ASCENDING, DESCENDING]);
    } else {
        sortedParam = !isUndefined(sortOrder)
            ? sortBy(
                filteredParam,
                [
                    "sub_category_rank",
                    (param: ParameterInterface) => {
                        return (sortOrder.order as string[]).indexOf(param.variable_importance);
                    }
                ],
                [ASCENDING]
            )
            : filteredParam;
    }
    return sortedParam;
};
export const validateParameters = (
    changedParameter: ChangedParameterInterface,
    originalParameter: ParameterInterface[]
): ParameterValidationInterface => {
    let validation: ParameterValidationInterface = {};
    /**
     * truthyChangedValue : this is to avoid undefined value in changed param
     * eg: write something then remove it, on removing it sets falsy value in state
     */
    const truthyChangedValue = pickBy(changedParameter, isNumber);
    forEach(keys(truthyChangedValue), (currentParameter: string) => {
        if (values(DEPENDENT_PARAMETERS).indexOf(currentParameter) > -1) {
            const backLinksCount = isNumber(changedParameter[DEPENDENT_PARAMETERS.BACK_LINK_COUNT])
                ? (changedParameter[DEPENDENT_PARAMETERS.BACK_LINK_COUNT] as number)
                : (findParameter(originalParameter, DEPENDENT_PARAMETERS.BACK_LINK_COUNT).value as number);

            const backLinksWithGenericAText = isNumber(changedParameter[DEPENDENT_PARAMETERS.BACK_LINKS_WITH_GENERIC_ANCHOR_TEXT])
                ? (changedParameter[DEPENDENT_PARAMETERS.BACK_LINKS_WITH_GENERIC_ANCHOR_TEXT] as number)
                : (findParameter(originalParameter, DEPENDENT_PARAMETERS.BACK_LINKS_WITH_GENERIC_ANCHOR_TEXT).value as number);

            const backlinksWithUrlAText = isNumber(changedParameter[DEPENDENT_PARAMETERS.BACK_LINKS_WITH_URL_ANCHOR_TEXT])
                ? (changedParameter[DEPENDENT_PARAMETERS.BACK_LINKS_WITH_URL_ANCHOR_TEXT] as number)
                : (findParameter(originalParameter, DEPENDENT_PARAMETERS.BACK_LINKS_WITH_URL_ANCHOR_TEXT).value as number);

            const backLinksWithNonEmptyAText = isNumber(changedParameter[DEPENDENT_PARAMETERS.BACK_LINK_WITH_NON_EMPTY_ANCHOR_TEXT])
                ? (changedParameter[DEPENDENT_PARAMETERS.BACK_LINK_WITH_NON_EMPTY_ANCHOR_TEXT] as number)
                : (findParameter(originalParameter, DEPENDENT_PARAMETERS.BACK_LINK_WITH_NON_EMPTY_ANCHOR_TEXT).value as number);

            const numberOfRefDomain = isNumber(changedParameter[DEPENDENT_PARAMETERS.REF_DOMAINS_COUNT])
                ? (changedParameter[DEPENDENT_PARAMETERS.REF_DOMAINS_COUNT] as number)
                : (findParameter(originalParameter, DEPENDENT_PARAMETERS.REF_DOMAINS_COUNT).value as number);

            const backLinsWithRedirect = isNumber(changedParameter[DEPENDENT_PARAMETERS.BACK_LINKS_WITH_REDIRECT])
                ? (changedParameter[DEPENDENT_PARAMETERS.BACK_LINKS_WITH_REDIRECT] as number)
                : (findParameter(originalParameter, DEPENDENT_PARAMETERS.BACK_LINKS_WITH_REDIRECT).value as number);

            if (
                [
                    DEPENDENT_PARAMETERS.REF_DOMAINS_COUNT,
                    DEPENDENT_PARAMETERS.BACK_LINKS_WITH_REDIRECT,
                    DEPENDENT_PARAMETERS.BACK_LINK_COUNT
                ].indexOf(currentParameter) > -1
            ) {
                if (numberOfRefDomain > backLinksCount) {
                    validation = {
                        ...validation,
                        [DEPENDENT_PARAMETERS.BACK_LINK_COUNT]: {
                            isValid: false,
                            message: COPY.OVERALL_SIMULAITON_VALIDATION_BACK_LINK_COUNT
                        },
                        [DEPENDENT_PARAMETERS.REF_DOMAINS_COUNT]: {
                            isValid: false,
                            message: COPY.OVERALL_SIMULAITON_VALIDATION_CAN_NOT_EXCEED.replace(
                                "{param_1}",
                                findParameter(originalParameter, DEPENDENT_PARAMETERS.REF_DOMAINS_COUNT).param_disp_name
                            ).replace("{param_2}", findParameter(originalParameter, DEPENDENT_PARAMETERS.BACK_LINK_COUNT).param_disp_name)
                        }
                    };
                }
                if (backLinsWithRedirect > backLinksCount) {
                    validation = {
                        ...validation,
                        [DEPENDENT_PARAMETERS.BACK_LINK_COUNT]: {
                            isValid: false,
                            message: COPY.OVERALL_SIMULAITON_VALIDATION_BACK_LINK_COUNT
                        },
                        [DEPENDENT_PARAMETERS.BACK_LINKS_WITH_REDIRECT]: {
                            isValid: false,
                            message: COPY.OVERALL_SIMULAITON_VALIDATION_CAN_NOT_EXCEED.replace(
                                "{param_1}",
                                findParameter(originalParameter, DEPENDENT_PARAMETERS.BACK_LINKS_WITH_REDIRECT).param_disp_name
                            ).replace("{param_2}", findParameter(originalParameter, DEPENDENT_PARAMETERS.BACK_LINK_COUNT).param_disp_name)
                        }
                    };
                }
            }
            /**
             * using intersection to check if either value of 3 param is changed,
             * if not then return to original don't do validation with initial value
             */
            if (
                [DEPENDENT_PARAMETERS.BACK_LINKS_WITH_URL_ANCHOR_TEXT, DEPENDENT_PARAMETERS.BACK_LINKS_WITH_GENERIC_ANCHOR_TEXT].indexOf(
                    currentParameter
                ) > -1 &&
                intersection(keys(changedParameter), [
                    DEPENDENT_PARAMETERS.BACK_LINKS_WITH_URL_ANCHOR_TEXT,
                    DEPENDENT_PARAMETERS.BACK_LINKS_WITH_GENERIC_ANCHOR_TEXT,
                    DEPENDENT_PARAMETERS.BACK_LINK_WITH_NON_EMPTY_ANCHOR_TEXT
                ]).length > 0 &&
                backLinksWithGenericAText + backlinksWithUrlAText > backLinksWithNonEmptyAText
            ) {
                const urlAsAnchor = findParameter(originalParameter, DEPENDENT_PARAMETERS.BACK_LINKS_WITH_URL_ANCHOR_TEXT).param_disp_name;
                const genericAnchor = findParameter(originalParameter, DEPENDENT_PARAMETERS.BACK_LINKS_WITH_GENERIC_ANCHOR_TEXT)
                    .param_disp_name;
                const nonEmpty = findParameter(originalParameter, DEPENDENT_PARAMETERS.BACK_LINK_WITH_NON_EMPTY_ANCHOR_TEXT)
                    .param_disp_name;

                validation = {
                    ...validation,
                    [DEPENDENT_PARAMETERS.BACK_LINKS_WITH_URL_ANCHOR_TEXT]: {
                        isValid: false,
                        message: `${urlAsAnchor} and ${genericAnchor} ${COPY.OVERALL_SIMULATION_VALIDATION_TEXT_CAN_NOT_EXCEED} ${nonEmpty} ${COPY.OVERALL_SIMULATION_VALIDATION_TEXT_TEXTUAL}`
                    },
                    [DEPENDENT_PARAMETERS.BACK_LINKS_WITH_GENERIC_ANCHOR_TEXT]: {
                        isValid: false,
                        message: `${urlAsAnchor} and ${genericAnchor} ${COPY.OVERALL_SIMULATION_VALIDATION_TEXT_CAN_NOT_EXCEED} ${nonEmpty} ${COPY.OVERALL_SIMULATION_VALIDATION_TEXT_TEXTUAL}`
                    },
                    [DEPENDENT_PARAMETERS.BACK_LINK_WITH_NON_EMPTY_ANCHOR_TEXT]: {
                        isValid: false,
                        message: COPY.OVERALL_SIMULATION_VALIDATION_SUM_CAN_NOT_EXCEED_2.replace("{param_1}", nonEmpty)
                            .replace("{param_2}", urlAsAnchor)
                            .replace("{param_3}", genericAnchor)
                    }
                };
            }
        }
    });

    return validation;
};

export const updateChangedParameter = (changedParameter: ChangedParameterInterface, newChangedParameter: ChangedParameterInterface) => {
    const param: string[] = keys(newChangedParameter);
    let reducedParameter = changedParameter;
    if (isUndefined(newChangedParameter[param[0]])) {
        // @ts-ignore
        delete reducedParameter[param[0]];
    } else {
        reducedParameter = {
            ...reducedParameter,
            ...newChangedParameter
        };
    }

    return reducedParameter;
};

export const getUniqueImportance = (parameter: ParameterInterface[]): string[] => {
    const uniqueVariableImportance = uniqBy(parameter, "variable_importance");
    const uniqueImportance = map(uniqueVariableImportance, (el: ParameterInterface) => {
        return el.variable_importance;
    });
    return uniqueImportance;
};
export const resetAppliedSortFilter = (appliedSortFilter: AppliedFilterSortInterface): AppliedFilterSortInterface => {
    const resetedFilter = appliedSortFilter;
    if (resetedFilter[AppliedFilterSort.SORT].length > 0) {
        resetedFilter[AppliedFilterSort.FILTER] = [];
    }
    if (resetedFilter[AppliedFilterSort.SORT] !== "VH-VL") {
        resetedFilter[AppliedFilterSort.SORT] = "VH-VL";
    }
    return appliedSortFilter;
};

/**
 * Gives Notification msg to show according to Keywords processed status
 *
 * @param {MultiKeywordTrackRequestApiRequestIdsResponseInterface[]} keywordsStatus - Multikeyword Track Response
 * @returns {KeywordProcessingNotificationInterface} - Status and message to be shown in reference to that status
 *
 */
export const getMessageForNotificationBar = (
    keywordsStatus?: MultiKeywordTrackRequestApiRequestIdsResponseInterface
): KeywordProcessingNotificationInterface => {
    if (isUndefined(keywordsStatus)) {
        return {
            message: undefined,
            status: undefined
        };
    }

    const keywordsProcessed = filter(values(keywordsStatus), ["is_my_url_processed", true]); // is Processed done
    const kwSuccessfullyProcessed = filter(values(keywordsStatus), ["my_url_status", SerpListProgressStatusType.DONE]); // is Status done
    const failedKeywords = filter(values(keywordsStatus), ["my_url_status", SerpListProgressStatusType.FAILED]);

    if (keys(keywordsStatus).length > keywordsProcessed.length) {
        // partial data available, some still processing
        return {
            message: `${COPY.MULTI_KEYWORD_KEYWORD_FETCH_NOTIFICATION_BAR_1} ${values(keywordsStatus).length -
                kwSuccessfullyProcessed.length}/${values(keywordsStatus).length} ${COPY.MULTI_KEYWORD_KEYWORD_FETCH_NOTIFICATION_BAR_2}`,
            status: KeywordProcessingStatus.STILL_PROCESSING
        };
    }

    if (failedKeywords.length > 0 && kwSuccessfullyProcessed.length + failedKeywords.length === keys(keywordsStatus).length) {
        // some processed, some failed
        return {
            message: `${kwSuccessfullyProcessed.length}/${values(keywordsStatus).length} ${SIMULATOR_CONST.MULTI_KEYWORD_NOTIFICATION_WHEN_ALL_KW_PROCESSED_FAILED_PROCESSED
                }. ${failedKeywords.length} ${SIMULATOR_CONST.MULTI_KEYWORD_NOTIFICATION_WHEN_ALL_KW_PROCESSED_FAILED}`,
            status: KeywordProcessingStatus.COMPLETE
        };
    }

    return {
        message: SIMULATOR_CONST.MULTI_KEYWORD_NOTIFICATION_WHEN_ALL_KW_PROCESSED, // all keywords data processed
        status: KeywordProcessingStatus.COMPLETE
    };
};

export const getKeywordStuffingMessage = (keywordStuffingObject?: KeywordStuffingInterface): string | undefined => {
    return isUndefined(keywordStuffingObject)
        ? keywordStuffingObject
        : (() => {
            let result: string[] = [];
            let newResult: string[] = [];
            for (const key in keywordStuffingObject) {
                if (keywordStuffingObject.hasOwnProperty(key)) {
                    if (key !== "url" && key !== "changed_dict") {
                        // @ts-ignore
                        for (const el of keywordStuffingObject[key]) {
                            // @ts-ignore
                            if (el.is_stuffed === true) {
                                result.push(key);
                                break;
                            }
                        }
                    }
                }
            }

            if (result.length === 0) {
                return `${COPY.KEYWORD_STUFF_NO_ERROR}`;
            }

            if (result.includes("title")) {
                newResult.push("title");
                // tslint:disable-next-line: no-any
                result = result.filter((item: any) => item !== "title");
            }
            newResult = [...newResult, ...result];

            const resultant = newResult.map((item) => {
                const upperCaseItem = item.split("_");
                const message = upperCaseItem.map((element, index) => element.slice(0, 1).toUpperCase() + element.slice(1).toLowerCase());
                return message.join(" ");
            });
            return `${COPY.KEYWORD_STUFF_ERROR_ONE}${resultant.join(", ")}${COPY.KEYWORD_STUFF_ERROR_TWO}`;
        })();
};

export const setKeywordStuffingFormat = (keywordStuffingDetails?: KeywordStuffingInterface): StuffedKeywordListInterface => {
    const stuffedKeywordObject: StuffedKeywordObjectInterface = { title: {}, h1: {}, h2: {}, h3: {}, visible_content: {} };
    const stuffedKeywordList: StuffedKeywordListInterface = [];
    for (const key in keywordStuffingDetails) {
        if (keywordStuffingDetails.hasOwnProperty(key) && key !== "meta_description") {
            // @ts-ignore
            for (const el of keywordStuffingDetails[key]) {
                // @ts-ignore
                if (el.is_stuffed === true) {
                    // @ts-ignore
                    for (const phrase in el.stuffed_phrases) {
                        // @ts-ignore
                        if (el.stuffed_phrases.hasOwnProperty(phrase)) {
                            // @ts-ignore
                            stuffedKeywordObject[key][phrase] =
                                // @ts-ignore
                                (stuffedKeywordObject[key][phrase] || 0) + el.stuffed_phrases[phrase];
                        }
                    }
                }
            }

            const phrasesList = [];
            // @ts-ignore
            for (const phrase in stuffedKeywordObject[key]) {
                if (keywordStuffingDetails.hasOwnProperty(key)) {
                    // @ts-ignore
                    phrasesList.push(`${phrase} (${stuffedKeywordObject[key][phrase]} times)`);
                }
            }

            const upperCaseItem = key.split("_");
            const message = upperCaseItem.map((element, index) => element.slice(0, 1).toUpperCase() + element.slice(1).toLowerCase());

            const obj = {
                title: message.join(" "),
                phrases: phrasesList
            };

            if (obj.phrases.length > 0) {
                stuffedKeywordList.push(obj);
            }
        }
    }
    return stuffedKeywordList;
};

export const newKeywordsProcessedForMyUrl = (
    oldKeywordStatus: MultiKeywordTrackRequestApiRequestIdsResponseInterface,
    newKeywordStatus: MultiKeywordTrackRequestApiRequestIdsResponseInterface
): MultiKeywordTrackRequestApiRequestIdStatusResponseInterface[] => {
    const oldKeywordsWithId = map(oldKeywordStatus, (k: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface, id: string) => ({
        ...k,
        id
    }));

    const processedOldKeywords = filter(oldKeywordsWithId, (keywordStatus: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface) => {
        return (
            (keywordStatus.is_my_url_processed && keywordStatus.my_url_status === SerpListProgressStatusType.DONE) ||
            (keywordStatus.is_my_url_processed && keywordStatus.my_url_status === SerpListProgressStatusType.FAILED)
        );
    });

    const newKeywordsWithId = map(newKeywordStatus, (k: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface, id: string) => ({
        ...k,
        id
    }));

    const processedNewKeywords = filter(newKeywordsWithId, (keywordStatus: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface) => {
        return (
            (keywordStatus.is_my_url_processed && keywordStatus.my_url_status === SerpListProgressStatusType.DONE) ||
            (keywordStatus.is_my_url_processed && keywordStatus.my_url_status === SerpListProgressStatusType.FAILED)
        );
    });
    return JSON.stringify(processedOldKeywords) !== JSON.stringify(processedNewKeywords) &&
        processedOldKeywords.length !== processedNewKeywords.length
        ? processedNewKeywords
        : [];
};

// export const newKeywordsProcessedForMyUrl = (
//     oldKeywordStatus: MultiKeywordTrackRequestApiRequestIdsResponseInterface,
//     newKeywordStatus: MultiKeywordTrackRequestApiRequestIdsResponseInterface
// ): MultiKeywordTrackRequestApiRequestIdStatusResponseInterface[] => {
//     const oldKeywordsWithId = map(oldKeywordStatus, (k: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface, id: string) => ({
//         ...k,
//         id
//     }));

//     const processedOldKeywords = filter(oldKeywordsWithId, (keywordStatus: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface) => {
//         return (
//             (keywordStatus.is_my_url_processed)
//         );
//     });

//     const newKeywordsWithId = map(newKeywordStatus, (k: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface, id: string) => ({
//         ...k,
//         id
//     }));

//     const processedNewKeywords = filter(newKeywordsWithId, (keywordStatus: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface) => {
//         return (
//             (keywordStatus.is_my_url_processed)
//         );
//     });
//     return JSON.stringify(processedOldKeywords) !== JSON.stringify(processedNewKeywords)
//         ? processedNewKeywords
//         : [];
// };
export const newKeywordsCompletedForAllUrl = (
    oldKeywordStatus: MultiKeywordTrackRequestApiRequestIdsResponseInterface,
    newKeywordStatus: MultiKeywordTrackRequestApiRequestIdsResponseInterface
) => {
    const oldKeywordsWithId = map(oldKeywordStatus, (k: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface, id: string) => ({
        ...k,
        id
    }));
    const processedOldKeywords = filter(oldKeywordsWithId, (keywordStatus: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface) => {
        return (
            keywordStatus.completion_status === SerpListProgressStatusType.DONE ||
            keywordStatus.completion_status === SerpListProgressStatusType.FAILED
        );
    });

    const newKeywordsWithId = map(newKeywordStatus, (k: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface, id: string) => ({
        ...k,
        id
    }));
    const processedNewKeywords = filter(newKeywordsWithId, (keywordStatus: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface) => {
        return (
            keywordStatus.completion_status === SerpListProgressStatusType.DONE ||
            keywordStatus.completion_status === SerpListProgressStatusType.FAILED
        );
    });
    return processedOldKeywords.length !== processedNewKeywords.length ? [] : [];
};

export const getKeywordsNameFromId = (
    keywords: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface[],
    allKeywords: MultiKeywordRequestIdsApiResponseInterface
): string[] => {
    const kwWithId = invert(allKeywords);
    const processedKeywordName = map(keywords, (v: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface) => {
        return kwWithId[v.id as string];
    });

    return processedKeywordName;
};

export const exportKwImpactToCsv = (kwData: ImpactedKeywordsResponseInterface[], url: string) => {
    const POST_SIM_DATA: DownloadResponseMapInterface[] = map(kwData, (row: ImpactedKeywordsResponseInterface) => ({
        " ": row.keyword,
        // tslint:disable-next-line: object-literal-key-quotes
        SV: postSimSV(row.search_volume),
        "Existing Rank": preSimRankValues(row.organic_rank),
        "Existing Traffic": preSimMetricValues(row.traffic),
        "Existing Content Score": preSimMetricCatVal(row.category_scores.content_score),
        "Existing URL Score": preSimMetricValues(row.url_score),
        "Existing Title Score": preSimMetricValues(row.title_score),
        "Existing H1 Score": preSimMetricValues(row.h1_score),
        "Existing H2 Score": preSimMetricValues(row.h2_score),
        "Existing H3 Score": preSimMetricValues(row.h3_score),
        "Existing Meta Desc Score": preSimMetricValues(row.meta_description_score),
        "Existing Body Content Score": preSimMetricValues(row.visible_content_score),
        "Existing Authority Score": preSimMetricCatVal(row.category_scores.authority_score),
        "Existing Technical Score": preSimMetricCatVal(row.category_scores.technical_score),
        "New Rank": postSimMetricVal(row.organic_rank),
        "New Traffic": postSimMetricVal(row.traffic),
        "New Content Score": postSimMetricCatVal(row.category_scores.content_score),
        "New URL Score": postSimMetricVal(row.url_score),
        "New Title Score": postSimMetricVal(row.title_score),
        "New H1 Score": postSimMetricVal(row.h1_score),
        "New H2 Score": postSimMetricVal(row.h2_score),
        "New H3 Score": postSimMetricVal(row.h3_score),
        "New Meta Desc Score": postSimMetricVal(row.meta_description_score),
        "New Body Content Score": postSimMetricVal(row.visible_content_score),
        "New Authority Score": postSimMetricCatVal(row.category_scores.authority_score),
        "New Technical Score": postSimMetricCatVal(row.category_scores.technical_score)
    }));

    const CSV_ROWS = [];
    const URL_HEADER = ["URL to be modified", url];
    CSV_ROWS.push(URL_HEADER.join(","));
    CSV_ROWS.push(KW_IMPACT_CSV_HEADER_BETA.join(","));

    for (const row of POST_SIM_DATA) {
        const value = KW_IMPACT_CSV_HEADER_BETA.map((header: string) => {
            const escaped = `${row[header]}`;
            const escapedsequence = escaped.replace(/"/g, '\\"');
            return `"${escapedsequence}"`;
        });
        CSV_ROWS.push(value.join(","));
    }
    downloadToCsv(CSV_ROWS.join("\n"), SIMULATION_REPORT_KW_IMPACT_FILE_NAME);
};

export const preSimMetricValues = (postSimMetric: ImpactedKeywordResponseDetailsInterface) => {
    let preSimVal: string | number | null = DEFAULT_FOR_DATA_FETCH_FAIL;
    const postSimVal = postSimMetric.score.info_value;
    if (!postSimMetric.score.hasOwnProperty("delta_info_value")) {
        if (postSimVal !== null) {
            preSimVal = postSimVal;
        }
    } else {
        const deltaVal = postSimMetric.score.delta_info_value;
        if (deltaVal !== null && postSimVal !== null) {
            preSimVal = postSimVal - deltaVal;
        }
    }
    return preSimVal;
};

export const preSimRankValues = (postSimScore: ImpactedKeywordResponseDetailsInterface) => {
    let deltaVal: number | null;
    let preSimRankVal: string | number | null = 121;
    const postSimVal = postSimScore.score.info_value;
    if (!postSimScore.score.hasOwnProperty("delta_info_value")) {
        if (postSimVal !== null) {
            preSimRankVal = postSimVal;
        } else {
            preSimRankVal = "-";
        }
    } else {
        deltaVal = postSimScore.score.delta_info_value;
        if (deltaVal !== null && postSimVal !== null) {
            preSimRankVal = postSimVal + deltaVal;
        }
        if ((deltaVal === null && postSimVal === null) || (deltaVal === null && postSimVal !== null)) {
            preSimRankVal = "-";
        }
    }
    return preSimRankVal;
};

export const postSimMetricVal = (postSimMetric: ImpactedKeywordResponseDetailsInterface) => {
    let postSimValue: string | null | number = DEFAULT_FOR_DATA_FETCH_FAIL;
    if (!postSimMetric.score.hasOwnProperty("delta_info_value")) {
        postSimValue = " ";
    } else {
        const postSimMetricValue = postSimMetric.score.info_value;
        if (postSimMetricValue !== null) {
            postSimValue = postSimMetricValue;
        }
    }
    return postSimValue;
};

export const postSimMetricCatVal = (postSimMetric: ImpactedKeywordResponseScoreInterface) => {
    let postSimValue: string | null | number = DEFAULT_FOR_DATA_FETCH_FAIL;
    if (!postSimMetric.hasOwnProperty("delta_info_value")) {
        postSimValue = " ";
    } else {
        const postSimMetricValue = postSimMetric.info_value;
        if (postSimMetricValue !== null) {
            postSimValue = postSimMetricValue;
        }
    }
    return postSimValue;
};

export const preSimMetricCatVal = (postSimMetric: ImpactedKeywordResponseScoreInterface) => {
    let preSimVal: string | number | null = DEFAULT_FOR_DATA_FETCH_FAIL;
    const postSimVal = postSimMetric.info_value;
    if (!postSimMetric.hasOwnProperty("delta_info_value")) {
        if (postSimVal !== null) {
            preSimVal = postSimVal;
        }
    } else {
        const deltaVal = postSimMetric.delta_info_value;
        if (deltaVal !== null && postSimVal !== null) {
            preSimVal = postSimVal - deltaVal;
        }
    }
    return preSimVal;
};

export const postSimSV = (postSimMetricValue: number | null) => {
    let postSimValue: string | null | number = DEFAULT_FOR_DATA_FETCH_FAIL;
    if (postSimMetricValue !== null) {
        postSimValue = postSimMetricValue;
    }
    return postSimValue;
};

// need to see if pagecontent or changecontent wont work
export const getModifiedPageContent = (pageContent: EditorElementInterfaceBeta[], url: string): EditorElementInterfaceBeta[] => {
    const modifiedPageContent = map(pageContent, (p: EditorElementInterfaceBeta, key: number) => {
        return {
            contents: p.title === "url" ? url : p.contents,
            title: p.title
        };
    });
    return modifiedPageContent;
};

export const getURLDomainType = (type: UrlDomainType): string => {
    if (type === UrlDomainType.DOMAIN) {
        return UrlDomainType.DOMAIN;
    }
    if (type === UrlDomainType.URL) {
        return UrlDomainType.URL.toUpperCase();
    }
    return "";
};

export const getTextFromHTML = (str: string): string => {
    const parser = new DOMParser();
    const htmlObject = parser.parseFromString(str, "text/html");
    return htmlObject.body.textContent || "";
};
export const getTextFromHTMLBody = (str: string): string => {
    const parser = new DOMParser();
    const htmlObject = parser.parseFromString(str, "text/html");
    if (htmlObject) {
        const script = htmlObject.getElementsByTagName("script");
        Array.from(script).forEach((item, id) => item.remove());
        const style = htmlObject.getElementsByTagName("style");
        Array.from(style).forEach((item, id) => item.remove());
    }
    const body = htmlObject.body.innerText || "";
    const regX = /(<([^>]+)>)/gi;
    return body.replace(regX, "");
};

export const getKeywordUrlList = (impactedKeywordsUrlList: Impactedkeywordsurllistinterface): KeywordUrlListDataResponseType[] => {
    const keywordUrlList: KeywordUrlListDataResponseType[] = [];
    Object.keys(impactedKeywordsUrlList).map((key, index) => {
        impactedKeywordsUrlList[key].map((urlDetail, i) => {
            keywordUrlList.push(urlDetail);
        });
    });
    return keywordUrlList;
};

export const getsortedKeywordLevelImpactData = (
    keywordandUrlList: KeywordUrlListDataResponseType[],
    sortType?: string
): KeywordUrlListDataResponseType[] => {
    switch (sortType) {
        case "SV_DSC":
            return orderBy(
                keywordandUrlList,
                [
                    (data: KeywordUrlListDataResponseType) => !isUndefined(data.search_volume) && data.search_volume,
                    (data: KeywordUrlListDataResponseType) => !isUndefined(data.keyword) && data.keyword
                ],
                [DESCENDING, ASCENDING]
            );
        default:
            return keywordandUrlList;
    }
};

export const getSortedKeywordBySearchVolume = (
    impactedKeywords: ImpactedKeywordsResponseInterface[]
): ImpactedKeywordsResponseInterface[] => {
    const sortedData = orderBy(impactedKeywords, ["search_volume", "keyword"], [DESCENDING, ASCENDING]);
    const sortedParam = [
        ...sortedData.filter((x: ImpactedKeywordsResponseInterface) => x.search_volume !== null),
        ...sortedData.filter((x: ImpactedKeywordsResponseInterface) => x.search_volume === null)
    ];
    return sortedParam;
};

/**
 * Gives Trimmed Target URL
 *
 * @param {ImpactedKeywordsResponseInterface[]} impactedKeywords - Keywords Data
 * @param {Impactedkeywordsurllistinterface} impactedKeywordsUrlList - My URL data + Top 10 Comp data for each keyword in object format
 * @returns {string} - Target URL with http(s), www. trimmed
 *
 */
export const getTrimmedTargetURL = (
    impactedKeywords: ImpactedKeywordsResponseInterface[],
    impactedKeywordsUrlList: Impactedkeywordsurllistinterface,
    kw?: string
) => {
    const highestSVKW: string = kw ? kw : getSortedKeywordBySearchVolume(impactedKeywords)[0]?.keyword;
    const impactedKeywordsUrls = impactedKeywordsUrlList[highestSVKW];
    const trimmedTargetURL: string = impactedKeywordsUrls ? impactedKeywordsUrls[0]?.displayUrl : "";
    return trimmedTargetURL;
};

/**
 * Gives list of Top Competitors for highest ranked keyword
 *
 * @param {ImpactedKeywordsResponseInterface[]} impactedKeywords - Keywords Data
 * @param {Impactedkeywordsurllistinterface} impactedKeywordsUrlList - My URL data + Top 10 Comp data for each keyword in object format
 * @returns {string[]} - Top Competitiors list with http(s), www. trimmed
 *
 */
export const getTopCompetitorsListForHighestRankingKw = (
    impactedKeywords: ImpactedKeywordsResponseInterface[],
    impactedKeywordsUrlList: Impactedkeywordsurllistinterface,
    kw?: string
): string[] => {
    const highestSVKW: string = kw ? kw : getSortedKeywordBySearchVolume(impactedKeywords)[0]?.keyword;
    const highestRankingCompURLs: string[] = [];
    for (let i = 1; i < impactedKeywordsUrlList[highestSVKW]?.length || 0; i += 1) {
        const item = impactedKeywordsUrlList[highestSVKW][i];
        // Check if the "displayUrl" property exists in the current item
        if (item.displayUrl) {
            highestRankingCompURLs.push(item.displayUrl);
        }
    }
    return highestRankingCompURLs;
};

export const getUniqueSortedUrl = (impactedKeywordsUrlList: Impactedkeywordsurllistinterface, simulationUrl: string): string[] => {
    const urlList: string[] = [];
    Object.keys(impactedKeywordsUrlList).map((key, index) => {
        impactedKeywordsUrlList[key].map((urlDetail, i) => {
            urlList.push(urlDetail.displayUrl);
        });
    });
    const uniqueSortedUrl = uniq(orderBy(urlList, [(data: string) => data], [ASCENDING]));
    // put the simulation url top of unique sorted URL list
    const sortedUrls = [
        ...uniqueSortedUrl.filter((url: string) => url === getTrimmedURL(simulationUrl)),
        ...uniqueSortedUrl.filter((url: string) => url !== getTrimmedURL(simulationUrl))
    ];
    return sortedUrls;
};

export const getInitHTMLEditorContent = (content: EditorElementInterfaceBeta[], str: string): EditorElementInterfaceBeta[] => {
    const pageContent: EditorElementInterfaceBeta[] = [];
    if (content.length > 0 && !isEmpty(str)) {
        // tslint:disable-next-line: no-any
        content.forEach((item: any) => {
            if (item.title === OnPageElementsTypeBeta.STRIPPED_PAGE) {
                item.contents = str;
            }
            pageContent.push(item);
        });
    }
    return pageContent;
};

export const getFormattedVersionList = (versionList: VersionListResponseInterface[]): OptionTypeInterface[] => {
    const formattedUrl = map(versionList, (el: VersionListResponseInterface) => {
        return {
            label: el.version_name,
            value: el.sid,
            modelVersion: el.model_version,
            useremail: el.user_email,
            description: el.description,
            scores: JSON.parse(JSON.stringify(el.scores)),
            is_browser_mode: isUndefined(el.is_browser_mode) ? false : el.is_browser_mode
        };
    });

    return formattedUrl;
};
export const uuidv4 = (): string => {
    // tslint:disable-next-line: no-any
    return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
        // tslint:disable-next-line: no-bitwise
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
};

export const getChangedDataBasedOnVersion = (
    original: OnPageElementsResponseInterface,
    changedDict: OnPageElementsResponseInterface
): EditorElementInterfaceBeta[] => {
    const newobj: OnPageElementsResponseInterface = {};
    // tslint:disable-next-line: no-any
    forIn(original, (value: any, key: any) => {
        newobj[key as OnPageElementsType] = !isUndefined(changedDict[key as OnPageElementsType])
            ? changedDict[key as OnPageElementsType]
            : value;
    });
    const changeDict = formatOnPageElements({ ...newobj });
    return changeDict;
};
export const getKeywordRequestIds = (keywords: string[]): KeywordRequestIdsInterface => {
    const idsMap: KeywordRequestIdsInterface = {};
    for (const keyword of keywords) {
        idsMap[keyword] = uuidv4();
    }
    return idsMap;
};

export const getKeywordProcessedInfo = (
    idsMap: KeywordRequestIdsInterface,
    keywordsInfo: ImpactedKeywordsResponseInterface[]
): MultiKeywordTrackRequestApiRequestIdsResponseInterface => {
    const processedKeywords: MultiKeywordTrackRequestApiRequestIdsResponseInterface = {};
    for (const keyword of Object.keys(idsMap)) {
        const keywordInfo = filter(keywordsInfo, { keyword });
        const infoObj = {
            keywordName: keyword,
            is_my_url_processed: true,
            percent_completed: 100,
            my_url_status: SerpListProgressStatusType.DONE,
            completion_status: SerpListProgressStatusType.FAILED
        };
        if (keywordInfo.length === 1) {
            infoObj.completion_status = SerpListProgressStatusType.DONE;
        }
        processedKeywords[idsMap[keyword]] = infoObj;
    }
    return processedKeywords;
};

export const getChangedParameters = (parameters: ParameterInterface[]): ChangedParameterInterface => {
    const result: ChangedParameterInterface = {};
    for (const item of parameters) {
        const { changed_value, parameter } = item;
        if (!isUndefined(changed_value) && !isNull(changed_value)) {
            result[parameter] = changed_value;
        }
    }
    return result;
};
export const formattedUserList = (versionList: VersionListResponseInterface[]): OptionTypeInterface[] => {
    const formattedUrl = map(versionList, (el: VersionListResponseInterface) => {
        return {
            label: el.user_email,
            value: el.sid as string
        };
    });

    const userList = [DEFAULT_SELECTED_USER].concat(formattedUrl);
    const UniqueList = uniqBy(userList, "label");
    return UniqueList;
};
export const filteredVersionList = (
    versionList: OptionTypeInterface[],
    payload: OptionTypeInterface
): OptionTypeInterface[] | undefined => {
    if (payload.value !== "1") {
        return filter(versionList, (item: OptionTypeInterface) => {
            return item.useremail === payload.label;
        });
    }
    return undefined;
};
export const selectedVersionBasedOnfilter = (
    payload: OptionTypeInterface,
    versionList?: OptionTypeInterface[],
    selectedVersion?: string
): string | undefined => {
    let versionSelected: string | undefined = selectedVersion;
    if (payload.value === "1") {
        versionSelected = selectedVersion;
    } else if (
        (!isUndefined(selectedVersion) && !isUndefined(versionList) && payload.value !== 1 && selectedVersion !== payload.value) ||
        (isUndefined(selectedVersion) && !isUndefined(versionList))
    ) {
        versionSelected = versionList[0].value as string;
    } else {
        versionSelected = selectedVersion;
    }
    return versionSelected;
};

export const getMissingHeaders = (content: EditorElementInterfaceBeta[]): string[] => {
    let missingHeadersH1 = "";
    let missingHeadersH2H3 = "";
    const h1s = filter(content, { title: OnPageElementsTypeBeta.MISSING_H1 });
    const h2s = filter(content, { title: OnPageElementsTypeBeta.MISSING_H2 });
    const h3s = filter(content, { title: OnPageElementsTypeBeta.MISSING_H3 });
    const h1Arr: string[] = [];
    const h2Arr: string[] = [];
    const h3Arr: string[] = [];
    if (h1s.length > 0 && !isUndefined(h1s[0].contents) && typeof h1s[0].contents !== "string") {
        for (let i = 0; i < h1s[0].contents.length; i = i + 1) {
            h1Arr[i] = `<h1>${h1s[0].contents[i]}</h1>`;
        }
    }
    if (h2s.length > 0 && !isUndefined(h2s[0].contents) && typeof h2s[0].contents !== "string") {
        for (let i = 0; i < h2s[0].contents.length; i = i + 1) {
            h2Arr[i] = `<h2>${h2s[0].contents[i]}</h2>`;
        }
    }
    if (h3s.length > 0 && !isUndefined(h3s[0].contents) && typeof h3s[0].contents !== "string") {
        for (let i = 0; i < h3s[0].contents.length; i = i + 1) {
            h3Arr[i] = `<h3>${h3s[0].contents[i]}</h3>`;
        }
    }
    missingHeadersH1 = `${h1Arr.join("")}`;
    missingHeadersH2H3 = `${h2Arr.join("")}${h3Arr.join("")}`;
    return [missingHeadersH1, missingHeadersH2H3];
};

export const getUpdatedPageContent = (
    content: EditorElementInterfaceBeta[],
    isBrowserMode: boolean = false
): EditorElementInterfaceBeta[] => {
    if (isBrowserMode) {
        return content;
    }
    const [missingHeadersH1, missingHeadersH2H3] = getMissingHeaders(content);
    return content.map((item) => {
        if (item.title === OnPageElementsTypeBeta.STRIPPED_PAGE && isString(item.contents)) {
            if (item.contents.indexOf(`<body`) > -1 || item.contents.indexOf(`<BODY`) > -1) {
                item.contents = item.contents.replace(/<body*[^>]*>/gi, `<body>${missingHeadersH1}`);
            }
            if (item.contents.indexOf(`</body`) > -1 || item.contents.indexOf(`</BODY`) > -1) {
                item.contents = item.contents.replace(/<\/body*[^>]*>/gi, `${missingHeadersH2H3}</body>`);
            }
        }
        return item;
    });
};

export const formatKeywordInformationWithName = (
    keywordProcessedInformation: KeywordProgressInformationFormatedWithNameInterface,
    keywordIds: MultiKeywordRequestIdsApiResponseInterface = {}
): KeywordProgressInformationFormatedWithNameInterface => {
    if (!isEmpty(keywordIds)) {
        const invertedKeywordId = invert(keywordIds);
        const formattedKywords = each(
            keywordProcessedInformation,
            (v: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface, id: string) => {
                v.keywordName = invertedKeywordId[id];
            }
        );
        return formattedKywords;
    }
    return keywordProcessedInformation;
};

export const getDaysDifferece = (selectedVersionDate: string) => {
    const versionMonth = new Date(selectedVersionDate?.toString()).getMonth();
    const versionDay = new Date(selectedVersionDate?.toString()).getDate();
    const versionYear = new Date(selectedVersionDate?.toString()).getFullYear();
    const formatedVersionDate = `${versionMonth + 1}/${versionDay}/${versionYear}`;
    const nowDate = new Date();
    const formatedCurrentDate = `${nowDate.getMonth() + 1}/${nowDate.getDate()}/${nowDate.getFullYear()}`;
    const currentAndVersionDate = {
        versionDate: new Date(formatedVersionDate).getTime(),
        currentDate: new Date(formatedCurrentDate).getTime()
    };
    const differenceOfTime = currentAndVersionDate.currentDate - currentAndVersionDate.versionDate;
    const differenceOfDays = differenceOfTime / (1000 * 3600 * 24);
    return differenceOfDays;
};

export const getUpdatedVersionWithDescription = (desc: string, versionList: OptionTypeInterface[], selectedVersion?: string) => {
    if (!isUndefined(selectedVersion)) {
        return map(versionList, (el: OptionTypeInterface) => {
            return {
                description: el.value === selectedVersion ? desc : el.description,
                label: el.label,
                modelVersion: el.modelVersion,
                scores: JSON.parse(JSON.stringify(el.scores)),
                useremail: el.useremail,
                value: el.value,
                is_browser_mode: isUndefined(el.is_browser_mode) ? false : el.is_browser_mode
            };
        });
    }
    return versionList;
};
export const setIsSimulateDoneFlag = (isSimulateDone: boolean): boolean => {
    if (isSimulateDone) {
        setTimeout(() => {
            return false;
        }, 1);
    }
    return true;
};

export const getSlicedList = (list: ImpactedKeywordsResponseInterface[], size: number): [] => {
    // tslint:disable-next-line:no-any
    return reduce(
        list,
        // tslint:disable-next-line:no-any
        (slicedArr: any, _: any, index: number) => (index % size ? slicedArr : [...slicedArr, list.slice(index, index + size)]),
        []
    );
};

export const isCompetetorScoreAvailableForAllKeyword = (
    keywords: string[],
    competitorsScore: CompetitorsScoreResponseInterface | undefined
) => {
    if (isUndefined(competitorsScore) || isNull(competitorsScore)) {
        return false;
    }
    return filter(keywords, (kw: string) => !!competitorsScore[kw.toLocaleLowerCase()]).length === keywords.length;
};

const tableCellData = {
    SimulatedURL: "B1",
    Locale: "B2",
    Keywords: "B3",
    PageType: "B4",
    Version: "B5",

    tableStartRow: 8,
    tableMaxNumRows: 220,
    tableColumns: [
        "Simulation & Competitor URLs",
        "Keyword",
        "Search Volume",
        "Rank",
        "Traffic",
        "Content Score",
        "Title Score",
        "Title",
        "Meta Desc Score",
        "Meta Description",
        "H1 Score",
        "H1s",
        "H2 Score",
        "H2s",
        "H3 Score",
        "H3s",
        "Body Content Score",
        "Authority Score",
        "No. of Backlinks",
        "No. of Ref Domains",
        "No. of Ref Pages",
        "URL Rating",
        "% Non Empty Anchor Text",
        "Dofollow",
        "Ref. Class C Ips",
        "Ref. IPs",
        "URL as Anchor Text",
        "Generic Anchor Text",
        "Backlinks with Redirects",
        "Internal Links on Page",
        "Backlinks from .edu Domains",
        "Backlinks from .gov Domains",
        "Domain Rating",
        "HTTPS on Page",
        "Backlink Content Relevance",
        "KW Occurrence in Anchor Text",
        "Technical Score",
        "LCP",
        "FID",
        "INP",
        "CLS",
        "TTFB",
        "FCP",
        "TBT",
        "TTI"
    ]
};

const SimulationImpactTableData = {
    SimulationUrl: "B1",
    tableStartRow: 4,
    tableColumns: [
        "Keywords",
        "Search Volume",
        "Existing Rank",
        "New Rank",
        "Existing Traffic",
        "New Traffic",
        "Existing Content Score",
        "New Content Score",
        "Existing URL Score",
        "New URL Score",
        "Existing Title Score",
        "New Title Score",
        "Existing H1 Score",
        "New H1 Score",
        "Existing H2 Score",
        "New H2 Score",
        "Existing H3 Score",
        "New H3 Score",
        "Existing Meta Desc Score",
        "New Meta Desc Score",
        "Existing Body Content Score",
        "New Body Content Score",
        "Existing Authority Score",
        "New Authority Score",
        "Existing No. of Backlinks",
        "Updated No. of Backlinks",
        "Existing No. of Ref Domains",
        "Updated No. of Ref Domains",
        "Existing No. of Ref Pages",
        "Updated No. of Ref Pages",
        "URL Rating",
        "Existing % Non Empty Anchor Text",
        "Updated % Non Empty Anchor Text",
        "Existing Dofollow Backlinks",
        "Updated Dofollow Backlinks",
        "Existing Ref. Class C Ips",
        "Updated Ref. Class C Ips",
        "Existing No. of Ref. IPs",
        "Updated No. of Ref. IPs",
        "No. of URL Anchor Texts",
        "Updated No. of URL Anchor Texts",
        "No. of Generic Anchor Text",
        "Updated No. of Generic Anchor Text",
        "Existing No. Backlinks with Redirects",
        "Updated No. Backlinks with Redirects",
        "Existing No. of Internal Links on Page",
        "Updated No. of Internal Links on Page",
        "Existing No. of Backlinks from .edu Domains",
        "Updated No. of Backlinks from .edu Domains",
        "Existing No. of Backlinks from .gov Domains",
        "Updated No. of Backlinks from .gov Domains",
        "Domain Rating",
        "Existing HTTPS Status",
        "Updated HTTPS Status",
        "Existing Backlink Content Relevance",
        "Updated Backlink Content Relevance",
        "Existing Percentage KW Occurrence in Anchor Text",
        "Updated Percentage KW Occurrence in Anchor Text",
        "Existing Technical Score",
        "New Technical Score",
        "Existing LCP",
        "Updated LCP",
        "Existing FID",
        "Updated FID",
        "Existing INP",
        "Updated INP",
        "Existing CLS",
        "Updated CLS",
        "Existing TTFB",
        "Updated TTFb",
        "Existing FCP",
        "Updated FCP",
        "Existing TBT",
        "Updated TBT",
        "Existing Tti",
        "New TTI"
    ]
};

const columnNames: string[] = "ACDEFGHIJKLMNOPQRSTUVWXYZ".split("");
const simulatedDataColumnNames: string[] = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
function scoreInfoValue(score: ImpactedKeywordResponseScoreInterface | undefined): string | number {
    if (score?.info_value === null) {
        return "-";
    }
    return score?.info_value || 0;
}

const numberToCol = (val: number) => {
    let num = val;
    let str = "";
    let q;
    let r;
    while (num > 0) {
        q = (num - 1) / 26;
        r = (num - 1) % 26;
        num = Math.floor(q);
        str = String.fromCharCode(65 + r) + str;
    }
    return str;
};

const colToNumber = (col: string) =>
    // @ts-ignore
    col.split("").reduce((code, chr, index) => code + (chr.charCodeAt() - 64) * 26 ** (col.length - index - 1), 0);

// tslint:disable-next-line:typedef
const range = function*(start = 0, end = Infinity, step = 1) {
    for (let i = start; i <= end; i += step) {
        yield i;
    }
};
// tslint:disable-next-line:no-any
const checkNumIsInteger = (num: number | any) => {
    return Number.isInteger(num) || isBoolean(num) || num === null ? num : num === undefined ? "-" : num.toFixed(2);
};

const coldata = [...columnNames, Array.from(range(colToNumber("AA"), colToNumber("AZ"))).map(numberToCol)].flat(1);
const simulatedColData = [...simulatedDataColumnNames, Array.from(range(colToNumber("AA"), colToNumber("BZ"))).map(numberToCol)].flat(1);

export const exportKwImpactToXlsx = async (
    keywordsandUrls: Impactedkeywordsurllistinterface,
    impactedKeywords: ImpactedKeywordsResponseInterface[],
    url: string,
    locale: string,
    isLiveUrl: boolean,
    version: string
) => {
    const impactedKeywordsandUrls = { ...keywordsandUrls }; // lets not mutate the data
    const keywords = Object.keys(impactedKeywordsandUrls);
    keywords.sort((a, b) => a.localeCompare(b));
    const simulatedUrlDataGroup: KeywordUrlListDataResponseType[] = [];
    let competitorUrlDataGroup: KeywordUrlListDataResponseType[] = [];

    keywords.forEach((kw: string) => {
        const urls = [...impactedKeywordsandUrls[kw]]; // lets not mutate the data
        simulatedUrlDataGroup.push(urls[0]);
        urls.splice(0, 1);
        impactedKeywordsandUrls[kw] = urls;
    });

    keywords.forEach((kw: string) => {
        const urls = [...impactedKeywordsandUrls[kw]];
        competitorUrlDataGroup = [...competitorUrlDataGroup, ...urls];
    });

    const getValue = (data: ImpactedKeywordResponseAuthAndTechInterface[], num: number) => {
        const arr = [];
        for (let i = 0; i < num; i += 1) {
            arr.push(checkNumIsInteger(data[i].value));
        }
        return arr;
    };

    const getPreAndPostData = (data: ImpactedKeywordResponseAuthAndTechInterface[]) => {
        const arr: number | boolean | null[] = [];
        // tslint:disable-next-line:no-any
        data.forEach((item: KeywordUrlListDataResponseType | any) => {
            const fixVal = checkNumIsInteger(item?.value);
            const fixChangeVal = checkNumIsInteger(item?.changed_value);
            if (item.changed_value !== null && item.changed_value !== undefined) {
                arr.push(fixChangeVal, fixVal);
            } else if (item.parameter === "url_rating" || item.parameter === "domain_rating") {
                arr.push(fixVal);
            } else {
                arr.push(fixVal, fixVal);
            }
        });
        return arr;
    };
    // tslint:disable-next-line:no-any
    const tableData = simulatedUrlDataGroup.concat(competitorUrlDataGroup).map((urlData: KeywordUrlListDataResponseType | any) => {
        const authScore = getValue(urlData.authority, urlData.authority?.length);
        const techScore = getValue(urlData.technical, urlData.technical?.length);
        return [
            urlData.url,
            urlData.keyword,
            isNull(urlData.search_volume) ? "-" : urlData.search_volume,
            scoreInfoValue(urlData.organic_rank.score),
            isUndefined(urlData.traffic) ? "-" : scoreInfoValue(urlData.traffic.score),
            scoreInfoValue(urlData.content_score?.score || urlData.category_scores?.content_score), // content score
            scoreInfoValue(urlData.title_score.score),
            urlData.title_score.elements,
            scoreInfoValue(urlData.meta_description_score.score),
            urlData.meta_description_score.elements,
            scoreInfoValue(urlData.h1_score.score),
            urlData.h1_score.elements,
            scoreInfoValue(urlData.h2_score.score),
            urlData.h2_score.elements,
            scoreInfoValue(urlData.h3_score.score),
            urlData.h3_score.elements,
            scoreInfoValue(urlData.visible_content_score.score),
            scoreInfoValue(urlData.authority_score?.score || urlData.category_scores?.authority_score),
            ...authScore,
            scoreInfoValue(urlData.technical_score?.score || urlData.category_scores?.technical_score), // tech score
            ...techScore
        ];
    });
    // tslint:disable-next-line:no-any
    const simulatedDataForComparison = simulatedUrlDataGroup.map((keywordData: KeywordUrlListDataResponseType | any) => {
        const prePostAuthScore = getPreAndPostData(keywordData.authority);
        const prePostTechScore = getPreAndPostData(keywordData.technical);
        return [
            keywordData.keyword,
            postSimSV(keywordData.search_volume), // Search Volume
            preSimRankValues(keywordData.organic_rank), // Existing Rank
            postSimMetricVal(keywordData.organic_rank), // New Rank
            preSimMetricValues(keywordData.traffic), // Existing Traffic
            postSimMetricVal(keywordData.traffic), // New Traffic
            preSimMetricCatVal(keywordData.category_scores.content_score), // Existing Content Score
            postSimMetricCatVal(keywordData.category_scores.content_score), // New Content Score
            preSimMetricValues(keywordData.url_score), // Existing URL score
            postSimMetricVal(keywordData.url_score), // New URL Score
            preSimMetricValues(keywordData.title_score), // Existing Title Score
            postSimMetricVal(keywordData.title_score), // New Title Score
            preSimMetricValues(keywordData.h1_score), // Existing H1 Score
            postSimMetricVal(keywordData.h1_score), // New H1 Score
            preSimMetricValues(keywordData.h2_score), // Existing H2 Score
            postSimMetricVal(keywordData.h2_score), // New H2 Score
            isUndefined(keywordData.h3_score) ? "-" : preSimMetricValues(keywordData.h3_score), // Existing H3 Score,
            isUndefined(keywordData.h3_score) ? "-" : postSimMetricVal(keywordData.h3_score), // New H3 Score
            preSimMetricValues(keywordData.meta_description_score), // Existing Meta Desc Score
            postSimMetricVal(keywordData.meta_description_score), // New Meta Desc Score
            preSimMetricValues(keywordData.visible_content_score), // Existing Meta Desc Score
            postSimMetricVal(keywordData.visible_content_score), // New Meta Desc Score
            preSimMetricCatVal(keywordData.category_scores.authority_score), // Existing Authority Score
            postSimMetricCatVal(keywordData.category_scores.authority_score), // New Authority Score
            ...prePostAuthScore,
            preSimMetricCatVal(keywordData.category_scores.technical_score), // Existing Technical Score
            postSimMetricCatVal(keywordData.category_scores.technical_score), // New Technical Score
            ...prePostTechScore
        ];
    });
    const response = await fetch(`${process.env.IMAGE_URL}/xlsx/keyword-level-impact-template-beta.xlsx`);
    const array = await response.arrayBuffer();

    const wb = new Excel.Workbook();
    const workbook = await wb.xlsx.load(array);

    const worksheet = workbook.getWorksheet("Competitive Comparison");
    const simulatedDataWorksheet = workbook.getWorksheet("Simulation-Impact-on-Keywords");

    const alignmentStyle: Partial<Excel.Alignment> = {
        horizontal: "left",
        vertical: "bottom",
        wrapText: false,
        shrinkToFit: false,
        indent: 0,
        textRotation: 0
    };
    worksheet.getCell(tableCellData.SimulatedURL).value = url;
    simulatedDataWorksheet.getCell(SimulationImpactTableData.SimulationUrl).value = url;

    worksheet.getCell(tableCellData.SimulatedURL).alignment = alignmentStyle;
    simulatedDataWorksheet.getCell(SimulationImpactTableData.SimulationUrl).alignment = alignmentStyle;

    worksheet.getCell(tableCellData.Keywords).value = keywords.join(", ");
    worksheet.getCell(tableCellData.Keywords).alignment = alignmentStyle;

    worksheet.getCell(tableCellData.Locale).value = locale;
    worksheet.getCell(tableCellData.PageType).value = isLiveUrl ? "Existing Page" : "New Page";
    worksheet.getCell(tableCellData.Version).value = version;

    tableData.forEach((rowData, dataIndex) => {
        const rowIndex = tableCellData.tableStartRow + dataIndex;
        for (let i = 0; i < tableCellData.tableColumns.length; i += 1) {
            const colName = coldata[i];
            const cell = worksheet.getCell(`${colName}${rowIndex}`);
            if (i === 7 || i === 9 || i === 11 || i === 13 || i === 15) {
                if (!isEmpty(rowData[i])) {
                    cell.value = rowData[i].join("\r\n");
                    const cellAlignment: Partial<Excel.Alignment> = {
                        wrapText: true
                    };
                    cell.alignment = cellAlignment;
                }
            } else {
                cell.value = rowData[i];
            }

            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" }
            };
        }
        worksheet.mergeCells(`A${rowIndex}:B${rowIndex}`);
    });

    simulatedDataForComparison.forEach((rowData, dataIndex) => {
        const rowIndex = SimulationImpactTableData.tableStartRow + dataIndex;
        for (let i = 0; i < SimulationImpactTableData.tableColumns.length; i += 1) {
            const colName = simulatedColData[i];
            const cell = simulatedDataWorksheet.getCell(`${colName}${rowIndex}`);

            cell.value = rowData[i];
        }
    });

    const excelBuffer = await workbook.xlsx.writeBuffer();
    downloadToXlsx(excelBuffer, SIMULATION_REPORT_KW_IMPACT_XLSX_FILE_NAME);
};

export const checkIsOneKeywordDone = (processedKeywords: MultiKeywordTrackRequestApiRequestIdsResponseInterface) => {
    return Object.values(processedKeywords).some(
        (value: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface) => value.my_url_status === SerpListProgressStatusType.DONE
    );
};

export const checkAllKeywordsFailed = (processedKeywords: MultiKeywordTrackRequestApiRequestIdsResponseInterface) => {
    if (Object.values(processedKeywords).length) {
        return Object.values(processedKeywords).every(
            (value: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface) =>
                value.my_url_status === SerpListProgressStatusType.FAILED
        );
    }
    return false;
};

const ON_TOP_ZINDEX = "101";
let ELEMENT_RECOMMENDATION: HTMLElement | null = null;
let ELEMENT_SCOREBOARD: HTMLElement | null = null;
let INITIAL_FLAG = true;
export const dragElement = (
    parentId: string,
    id: string,
    collaps?: boolean,
    launch?: boolean,
    hideInfo?: boolean,
    IsRecommendationOpen?: boolean
): void => {
    const element = document.getElementById(parentId);
    const header = document.getElementById(id);
    let positionX = 0;
    let positionRelativeX = 0;
    let positionY = 0;
    let positionRelativeY = 0;
    const HEIGHT_OFFSET = collaps ? (hideInfo ? 430 : 420) : 40;
    const WIDTH_OFFSET = 235;
    const INITIAL_POSITION_TOP = 56;
    const INITIAL_POSITION = 0;
    let winWidth = window.innerWidth;
    let winHeight = window.innerHeight;
    ELEMENT_SCOREBOARD = element;

    if (!launch) {
        INITIAL_FLAG = true;
    }
    if (element && launch && IsRecommendationOpen && INITIAL_FLAG) {
        INITIAL_FLAG = false;
    }
    if (collaps && element && !launch) {
        if (parseInt(element.style.top, 10) > winHeight - HEIGHT_OFFSET || isEmpty(element.style.top)) {
            element.style.top = `${winHeight - HEIGHT_OFFSET}px`;
        }
    }
    if (header) {
        // if present, the header is where you move the DIV from:
        header.onmousedown = dragMouseDown;
    } else if (element) {
        // otherwise, move the DIV from anywhere inside the DIV:
        element.onmousedown = dragMouseDown;
    }

    // tslint:disable-next-line:no-any
    function dragMouseDown(ele: any): void {
        ele.preventDefault();
        // get the mouse cursor position at startup:
        positionRelativeX = ele.clientX;
        positionRelativeY = ele.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
    }

    // tslint:disable-next-line:no-any
    function elementDrag(e: any): void {
        // e = e || window.event;
        e.preventDefault();
        winWidth = window.innerWidth;
        winHeight = window.innerHeight;
        // calculate the new cursor position:
        positionX = positionRelativeX - e.clientX;
        positionY = positionRelativeY - e.clientY;
        positionRelativeX = e.clientX;
        positionRelativeY = e.clientY;
        // set the element's new position:
        if (element) {
            getTriggeredElement(element);
            parseInt(element.style.top, 10) < 55
                ? (element.style.top = `${INITIAL_POSITION_TOP}px`)
                : parseInt(element.style.top, 10) > winHeight - HEIGHT_OFFSET
                    ? (element.style.top = `${winHeight - HEIGHT_OFFSET}px`)
                    : (element.style.top = `${element.offsetTop - positionY}px`);
            parseInt(element.style.left, 10) < 0
                ? (element.style.left = `${INITIAL_POSITION}px`)
                : parseInt(element.style.left, 10) > winWidth - WIDTH_OFFSET
                    ? (element.style.left = `${winWidth - WIDTH_OFFSET}px`)
                    : (element.style.left = `${element.offsetLeft - positionX}px`);
        }
    }

    function closeDragElement(): void {
        // stop moving when mouse button is released:
        if (element) {
            element.style.zIndex = "100";
        }
        document.onmouseup = null;
        document.onmousemove = null;
    }
};

export const dragRecommendation = (parentId: string) => {
    const parent = document.getElementById(parentId);
    let element: HTMLElement | null = null;
    if (parent) {
        element = parent;
    }
    let positionX = 0;
    let positionRelativeX = 0;
    let positionY = 0;
    let positionRelativeY = 0;
    const WIDTH_OFFSET = 97;
    const INITIAL_POSITION_TOP = 5;
    const INITIAL_POSITION = 0;
    const HEIGHT_OFFSET = 10;
    let winWidth = window.innerWidth;
    let winHeight = window.innerHeight;

    if (element) {
        ELEMENT_RECOMMENDATION = element;
        // otherwise, move the DIV from anywhere inside the DIV:
        element.onmouseenter = resetfun;
        element.onmousedown = dragMouseDown;
    }

    function resetfun(): void {
        document.onmouseup = null;
        document.onmousemove = null;
    }
    // tslint:disable-next-line:no-any
    function dragMouseDown(node: any): void {
        const ele = node.target.offsetParent;
        const parentWithClass = findAncestor(node.target as HTMLElement, "scroll-recommendation-container");
        if (parentWithClass) {
            return;
        }

        const resizerWithClass = RETURN_CLASS.find((item, id) => findAncestor(node.target as HTMLElement, item));
        if (resizerWithClass) {
            return;
        }

        if (ele) {
            positionRelativeX = ele.clientX;
            positionRelativeY = ele.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }
        // get the mouse cursor position at startup:
    }

    // tslint:disable-next-line:no-any
    function elementDrag(e: any): void {
        e.preventDefault();
        if (e && e.classList && e.classList.contains("se")) {
            return;
        }
        winWidth = window.innerWidth;
        winHeight = window.innerHeight;
        // calculate the new cursor position:
        positionX = positionRelativeX - e.clientX;
        positionY = positionRelativeY - e.clientY;
        positionRelativeX = e.clientX;
        positionRelativeY = e.clientY;
        // set the element's new position:
        if (element) {
            const WIDTH = element.getBoundingClientRect().width;
            const HEIGHT = element.getBoundingClientRect().height;
            getTriggeredElement(element);
            parseInt(element.style.top, 10) < INITIAL_POSITION_TOP
                ? (element.style.top = `${INITIAL_POSITION_TOP}px`)
                : parseInt(element.style.top, 10) > winHeight - HEIGHT - HEIGHT_OFFSET
                    ? (element.style.top = `${winHeight - HEIGHT - HEIGHT_OFFSET}px`)
                    : (element.style.top = `${element.offsetTop - positionY}px`);
            parseInt(element.style.left, 10) < 0
                ? (element.style.left = `${INITIAL_POSITION}px`)
                : parseInt(element.style.left, 10) > winWidth - WIDTH - WIDTH_OFFSET
                    ? (element.style.left = `${winWidth - WIDTH - WIDTH_OFFSET}px`)
                    : (element.style.left = `${element.offsetLeft - positionX}px`);
        }
    }

    function closeDragElement(): void {
        // stop moving when mouse button is released:
        if (element) {
            element.style.zIndex = "100";
        }
        document.onmouseup = null;
        document.onmousemove = null;
    }
};

function findAncestor(el: HTMLElement | null | undefined, cls: string): HTMLElement | undefined | null {
    let element = el || null;
    while (element && !element.classList.contains(cls)) {
        // @ts-ignore
        element = element.parentElement; // eslint-disable-line
        continue;
    }
    return element;
}

export const getTriggeredElement = (ele: HTMLElement) => {
    const clickedID = ele.getAttribute("id");
    if (clickedID === "mydiv" && ELEMENT_SCOREBOARD && ELEMENT_RECOMMENDATION) {
        ELEMENT_SCOREBOARD.style.zIndex = ON_TOP_ZINDEX;
        ELEMENT_RECOMMENDATION.style.zIndex = "99";
    } else if (clickedID === "recommendation-wrapper" && ELEMENT_SCOREBOARD && ELEMENT_RECOMMENDATION) {
        ELEMENT_SCOREBOARD.style.zIndex = "100";
        ELEMENT_RECOMMENDATION.style.zIndex = ON_TOP_ZINDEX;
    }
    return;
};

export const getURL = (pageContent: EditorElementInterfaceBeta[]) => {
    let url: string = "";

    map(pageContent, (item) => {
        if (item.title === "url" && typeof item.contents === "string") {
            url = item.contents;
        }
    });
    return url;
};

// tslint:disable-next-line:no-any
export const isBrowserModeExist = (data: SaveSimulationHistory | MetaDetailsInterface) => {
    const simKeys = Object.keys(data);
    if (simKeys.includes("is_browser_mode")) {
        return data.is_browser_mode;
    }
    return false;
};

export const getLatestVersion = (editorMode: string, versionList: OptionTypeInterface[]) => {
    let versionId = "";
    if (versionList && versionList.length) {
        forEach(versionList, (v: OptionTypeInterface) => {
            if (isEmpty(versionId) && editorMode === EDITOR_MODES.browser && v.is_browser_mode) {
                versionId = v.value as string;
            } else if (isEmpty(versionId) && editorMode === EDITOR_MODES.editor && !v.is_browser_mode) {
                versionId = v.value as string;
            }
        });
        return versionId;
    }
    return undefined;
};
export const getSelectedUrlFilterList = (newUrl: string, filteredUrlList: string[], editorOldUrl?: string): string[] => {
    const formatedOldUrl = !isUndefined(editorOldUrl) ? getTrimmedURL(editorOldUrl) : "";
    if (filteredUrlList.includes(newUrl) && !filteredUrlList.includes(formatedOldUrl)) {
        return filteredUrlList;
    }
    const listAfterRemovedOldUrl = filteredUrlList.filter((url: string) => url !== formatedOldUrl);
    return [...listAfterRemovedOldUrl, getTrimmedURL(newUrl)];
};

export const downloadSimulationContent = (content: ArrayBuffer, filename: string) => {
    const blob = new Blob([content], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
export const setToolBar = (isOpenViewRecommendation: boolean) => {
    const toolBarElement = document.querySelector(".stickyWithSummary")
        ? (document.querySelector(".stickyWithSummary") as HTMLElement)
        : undefined;
    const quillContainerElem = document.querySelector(".quill-container") as HTMLElement;
    if (toolBarElement) {
        toolBarElement.style.width = "89%";
        toolBarElement.style.maxWidth = "680px";
        toolBarElement.style.top = "90px";
        if (!toolBarElement.classList.contains("fixed-sticky-top")) {
            quillContainerElem.style.marginTop = "0px";
        } else {
            quillContainerElem.style.marginTop = "55px";
        }
    }
};

export const setCurrentScrollPos = (isOpenViewRecommendation: boolean) => {
    window.addEventListener("scroll", () => {
        const toolBarElement = document.querySelector(".stickyWithSummary")
            ? (document.querySelector(".stickyWithSummary") as HTMLElement)
            : undefined;
        const horizontalTabsList = document.querySelector("#horizontal-tabs-list")
            ? (document.querySelector("#horizontal-tabs-list") as HTMLElement)
            : undefined;
        const textEditorWrapper = document.querySelector("#text_editor_wrapper")
            ? (document.querySelector("#text_editor_wrapper") as HTMLElement)
            : undefined;
        const styledHtmlEditorWrapper = document.querySelector("#styled-html-editor-wrapper")
            ? (document.querySelector("#styled-html-editor-wrapper") as HTMLElement)
            : undefined;

        if (horizontalTabsList && textEditorWrapper && toolBarElement && styledHtmlEditorWrapper) {
            const horizontalTabsListBottom = horizontalTabsList.getBoundingClientRect().bottom;
            const styledHtmlEditorWrapperTop = styledHtmlEditorWrapper.getBoundingClientRect().top;
            const toolBarElementRectTop = toolBarElement.getBoundingClientRect().top;
            const textEditorWrapperTop = textEditorWrapper.getBoundingClientRect().top;

            if (toolBarElementRectTop < horizontalTabsListBottom) {
                toolBarElement.classList.add("fixed-sticky-top");
                toolBarElement.style.left = "184px";
                toolBarElement.style.top = `${horizontalTabsListBottom}px`;
            }
            if (horizontalTabsListBottom < styledHtmlEditorWrapperTop || horizontalTabsListBottom < textEditorWrapperTop) {
                toolBarElement.classList.remove("fixed-sticky-top");
                toolBarElement.style.left = "136px";
                toolBarElement.style.top = `0px`;
            }
            setToolBar(isOpenViewRecommendation);
        }
    });
};
export const setCurrentScrollUpdate = (isOpenViewRecommendation: boolean) => {
    const toolBarElement = document.querySelector(".stickyWithSummary")
        ? (document.querySelector(".stickyWithSummary") as HTMLElement)
        : undefined;
    const horizontalTabsList = document.querySelector("#horizontal-tabs-list")
        ? (document.querySelector("#horizontal-tabs-list") as HTMLElement)
        : undefined;
    const textEditorWrapper = document.querySelector("#text_editor_wrapper")
        ? (document.querySelector("#text_editor_wrapper") as HTMLElement)
        : undefined;
    const styledHtmlEditorWrapper = document.querySelector("#styled-html-editor-wrapper")
        ? (document.querySelector("#styled-html-editor-wrapper") as HTMLElement)
        : undefined;

    if (horizontalTabsList && textEditorWrapper && toolBarElement && styledHtmlEditorWrapper) {
        const horizontalTabsListBottom = horizontalTabsList.getBoundingClientRect().bottom;
        const styledHtmlEditorWrapperTop = styledHtmlEditorWrapper.getBoundingClientRect().top;
        const toolBarElementRectTop = toolBarElement.getBoundingClientRect().top;
        const textEditorWrapperTop = textEditorWrapper.getBoundingClientRect().top;

        if (toolBarElementRectTop < horizontalTabsListBottom) {
            toolBarElement.classList.add("fixed-sticky-top");
            toolBarElement.style.left = "184px";
            toolBarElement.style.top = `${horizontalTabsListBottom}px`;
        }
        if (horizontalTabsListBottom < styledHtmlEditorWrapperTop || horizontalTabsListBottom < textEditorWrapperTop) {
            toolBarElement.classList.remove("fixed-sticky-top");
            toolBarElement.style.left = "136px";
            toolBarElement.style.top = `0px`;
        }
        setToolBar(isOpenViewRecommendation);
    }
};

export const checkIsFileUploaded = (fileUploaded: boolean, fileRequestId?: string) => {
    return !isUndefined(fileRequestId) && fileUploaded;
};

// tslint:disable-next-line: typedef
export function listenCompetitorScoreSSEvents(url: string) {
    const eventChan = eventChannel((emit) => {
        const sseSource = new EventSource(url, {
            withCredentials: true
        });
        sseSource.addEventListener("receive_db_data", (ev: Event) => emit(ev));
        sseSource.addEventListener("receive_kw_h2_h3_scores", (ev: Event) => emit(ev));
        sseSource.addEventListener("receive_kw-grouped-h2", (ev: Event) => emit(ev));
        sseSource.addEventListener("receive_kw-grouped-h3", (ev: Event) => emit(ev));
        sseSource.addEventListener("req_completed", (ev: Event) => emit(ev));
        sseSource.addEventListener("req_failed", (ev: Event) => emit(ev));
        sseSource.onerror = (ev: Event) => {
            emit(ev);
        };
        return () => sseSource.close();
    });
    return eventChan;
}

// util function that creates random alphanumeric ID of 5 characters
export const createRandomID = (): string => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 5) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
};

export const getFormatedKeywordList = (keywords?: ImpactedKeywordsResponseInterface[]) => {
    const formatedKeywords = [];
    if (!isUndefined(keywords)) {
        for (const ele of keywords) {
            formatedKeywords.push({ keyword: ele.keyword, sv: ele.search_volume });
        }
    }
    return formatedKeywords;
};

export const getSubHeadersFromEditor = () => {
    //  get view original modal
    const h2arr: string[] = [];
    const h3arr: string[] = [];
    const container = document.querySelectorAll(`#content-editor_html-editor-container-wrapper div.ql-editor > *`);

    container.forEach((elem, index) => {
        const elemName = elem.nodeName;
        if (!isNull(elem.textContent) && elemName === SIMULATOR_CONST.CONTENT_SIMULATOR_H2_SCORE) {
            h2arr.push(elem.textContent);
        }
        if (!isNull(elem.textContent) && elemName === SIMULATOR_CONST.CONTENT_SIMULATOR_H3_SCORE) {
            h3arr.push(elem.textContent);
        }
    });

    return { h2_list: h2arr, h3_list: h3arr };
};

export const validateRecomBulbSubHeaderScoresPayload = (params: RecomBulbSubHeaderScoresPayloadInterface): boolean => {
    // validate keyword
    if (!params.keywords || (params.keywords && params.keywords.length === 0)) {
        return false;
    }

    // validate locale
    if (!params.locale || (params.locale && params.keywords.length === 0)) {
        return false;
    }

    // validate subheaders
    if (
        !params.sub_header ||
        !params.sub_header.h2_list ||
        !params.sub_header.h3_list ||
        (!params.sub_header.h2_list.length && !params.sub_header.h3_list.length)
    ) {
        return false;
    }

    return true;
};

export const getKWProgressStatusForSingleKWFlow = (requestId: string): MultiKeywordTrackRequestApiRequestIdsResponseInterface => {
    const kwProgressStatusSingleFlowData: MultiKeywordTrackRequestApiRequestIdsResponseInterface = {
        [requestId]: {
            is_my_url_processed: true,
            percent_completed: 100,
            my_url_status: SerpListProgressStatusType.DONE,
            completion_status: SerpListProgressStatusType.DONE
        }
    };
    return kwProgressStatusSingleFlowData;
};

export const getFilteredImpactedKeywords = (state: SimulatorReducerInterface, action: AnyAction) => {
    const res = action.payload.selectedKeywords
        ? state.impactedKeywords?.filter((kw: ImpactedKeywordsResponseInterface) => action.payload.selectedKeywords.includes(kw.keyword))
        : state.impactedKeywords;
    return res;
};

export const staticSubHeading = "While we compute scores...";

export const featuresInfoLive = [
    {
        mainText: "Don't forget to check out <b>Competitive Analysis</b>,<br>where you can get comparison data of top ranking competitors.",
        featuresHighlights: [
            "Select the top-ranking URLs to compare scores",
            "Compare your pages' content, authority, and technical score with top competitors",
            "Top performing competitor pages are automatically updated in ALPS"
        ]
    },
    {
        mainText: "Don't forget to checkout <b>Page Assessment</b>,<br>where you can get insights into competitive gaps and key takeaways.",
        featuresHighlights: [
            "Gives an insight on how the target page is performing at a glance",
            "Has summarized details on KW-Page Intent Match, Content, Authority and Technical parameters",
            "Provides the data at an individual keyword level"
        ]
    },
    {
        mainText: "Don't forget to checkout <b>Optimize</b>,<br>which uses AI-powered content recommendations tailored to your page.",
        featuresHighlights: [
            "You can get an element-level performance report for the page content in the Editor",
            "You can optimize the underperforming elements",
            "You can generate contextual recommendations and add them to the Editor section"
        ]
    },
    {
        mainText:
            "Don't forget to checkout <b>Update Score and Predicted Rank</b>,<br>where you can modify your page content and simulate to instantly get performance impact.",
        featuresHighlights: [
            "You can edit the content, backlinks and core web vitals",
            "You can get updated scores based on your changes",
            "You can get predicted ranks based on your changes"
        ]
    }
];

export const featuresInfoNonLive = [
    {
        mainText: "Don't forget to check out <b>Competitive Analysis</b>,<br>where you can get comparison data of top ranking competitors.",
        featuresHighlights: [
            "Select the top-ranking URLs to compare scores",
            "Compare your pages' content, authority, and technical score with top competitors",
            "Top performing competitor pages are automatically updated in ALPS"
        ]
    },
    {
        mainText: "Don't forget to checkout <b>Optimize</b>,<br>which uses AI-powered content recommendations tailored to your page.",
        featuresHighlights: [
            "You can get an element-level performance report for the page content in the Editor",
            "You can optimize the underperforming elements",
            "You can generate contextual recommendations and add them to the Editor section"
        ]
    },
    {
        mainText:
            "Don't forget to checkout <b>Update Score and Predicted Rank</b>,<br>where you can modify your page content and simulate to instantly get performance impact.",
        featuresHighlights: [
            "You can edit the content, backlinks and core web vitals",
            "You can get updated scores based on your changes",
            "You can get predicted ranks based on your changes"
        ]
    }
];

export const getKeywords = (keywordDetails: ImpactedKeywordsResponseInterface[]) => {
    if (keywordDetails && keywordDetails.length) {
        return keywordDetails.map((kw) => {
            return {
                keyword: kw.keyword,
                search_volume: kw.search_volume
            };
        });
    }
    return [];
};
