import React, { Component } from "react";
import PropTypes from "prop-types";

if (process.env.IS_DEV === "1") {
    // const { whyDidYouUpdate } = require('why-did-you-update'); // eslint-disable-line
    const { whyDidYouUpdate } = { whyDidYouUpdate: () => { } }; // eslint-disable-line
    whyDidYouUpdate(React);
}

class AlpsInterface extends Component {
    static propTypes = {
        render: PropTypes.func.isRequired
    };
    state = {};

    componentWillMount() {
        const meta = this.getMeta();
        this.setState(() => ({ meta }));
        // TODO: attach inactivity listener and hit logout
    }

    /**
     * Events for user interactions. Use this for button clicks
     * @param category String. Required. A top level category for these events. E.g. 'User', 'Navigation', 'app Editing', etc.
     * @param action String. Required. A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
     * @param Int. Optional. A means of recording a numerical value against an event. E.g. a rating, a score, etc.
     * @param label String. Optional. More precise labelling of the related action. E.g. alongside the 'Added a component' action, we could add the name of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc.
     * @param nonInteraction Boolean. Optional. If an event is not triggered by a user interaction, but instead by our code (e.g. on page load, it should be flagged as a nonInteraction event to avoid skewing bounce rate data.
     * @param transport String. Optional. This specifies the transport mechanism with which hits will be sent. Valid values include 'beacon', 'xhr', or 'image'.
     */

    /**
     * Tracking links out to external URLs (including id.webmaker.org for OAuth 2.0 login flow).
     * @param label String. Required. Description of where the outbound link points to. Either as a URL, or a string.
     * @param hitCallBack function. The react-ga implementation accounts for the possibility that GA servers are down, or GA is blocked, by using a fallback 250ms timeout.
     */

    getMeta() {
        // eslint-disable-line
        let meta;
        if (window.localStorage["ls.__iQ_alps"]) {
            // eslint-disable-line
            meta = Object.assign({}, JSON.parse(window.localStorage["ls.__iQ_alps"])); // eslint-disable-line
        } else {
            meta = {};
        }
        return {
            projectName: meta.projectName,
            tenantCode: meta.tenant_code,
            userID: meta.user_id,
            isStandardProject: meta.isStandardProject,
            isMobileProject: meta.isMobileProject,
            default_project_id: meta.default_project || meta.projectID,
            has_project: meta.has_project,
            hasMultipleTenants: Array.isArray(meta.tenant_list) ? meta.tenant_list.length > 1 : true,
            hasMultipleProjects: Array.isArray(meta.project_list) ? meta.project_list.length > 1 : true,
            baseUrl: process.env.ROOT_BASE_URL,
            projectID: meta.projectID,
            searchEngine: meta.searchEngine,
            userEmail: meta.user_email,
            locale: meta.locale,
            rootRoute: {
                techAudit: process.env.ROOT_TECH_AUDIT_ROUTE_URL,
                keywordOptimization: process.env.ROOT_KEYWORD_OPTIMIZATION_ROUTE_URL,
                homeSec: process.env.ROOT_HOME_SEC_ROUTE_URL,
                serpResult: process.env.ROOT_SERP_RESULT,
                // userSubscription: process.env.ROOT_USER_SUBSCRIPTION,
                keyWordAnalysis: process.env.ROOT_KEYWORD_ANALYSIS,
                simulator: process.env.ROOT_SIMULATION,
                competitiveIntelligence: process.env.ROOT_COMPETITIVE_INTELLIGENCE,
                ciSearchDemand: process.env.CI_SEARCH_DEMAND,
                ciShareOfVoice: process.env.CI_SHARE_OF_VOICE,
                pageOptimizer: process.env.PAGE_OPTIMIZER,
                kwResearch: process.env.KRT_HOME,
                kwResearchTool: process.env.KRT_TOOL,
            },
            routes: {
                activeRouteUrl: ""
            }
        };
    }
    render() {
        return this.props.render({
            meta: this.state.meta,
        });
    }
}

export default AlpsInterface;
