import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */
const ActionTypes = {
    SET_IS_PHRASES_LOADING: "SET_IS_PHRASES_LOADING",
    SET_IS_RECOMMENDATION_REFRESH: "SET_IS_RECOMMENDATION_REFRESH",
    IS_TOP_PAGE_KW_DROPDOWN_CHANGE: "IS_TOP_PAGE_KW_DROPDOWN_CHANGE",
    SET_RECO_SSE_RETRIED_ATTEMPT: "SET_RECO_SSE_RETRIED_ATTEMPT",
    SET_RECO_SSE_LOADER: "SET_RECO_SSE_LOADER"
};
export default ActionTypes;

// GETTERS / API CALL ACTIONS
export const setIsRecommendationRefresh = createAction(ActionTypes.SET_IS_RECOMMENDATION_REFRESH);
export const setIsTopPageKWDropdownChange = createAction(ActionTypes.IS_TOP_PAGE_KW_DROPDOWN_CHANGE);
export const setRecoSSeRetriedAttempt = createAction(ActionTypes.SET_RECO_SSE_RETRIED_ATTEMPT);
export const setRecoSSeLoader = createAction(ActionTypes.SET_RECO_SSE_LOADER);
export const setIsPhrasesLoading = createAction(ActionTypes.SET_IS_PHRASES_LOADING);
