import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import { isUndefined, assign, isEmpty, camelCase, isEqual, includes } from "lodash";
import actionTypes from "./actions";
import { MultiKeywordReducerInterface, AppliedFilter, RelatedKeywordMergeType, RelatedKeywordSSEResponseType } from "./types";
import {
    brandTypeList,
    APPLIED_FILTERS_ON_KEYWORD,
    keywordTypeDropdownList,
    RELATED_KW_SSE_EVENT_TYPES
} from "../../../../../app/const";
import { MULTIKEYWORDS_SECTION_KEYWORDS, MULTI_KEYWORD_SECTION_TYPES } from "./const";
import {
    formattedThemeList,
    formattedKeywordsList,
    updateValueInAppliedFilter,
    findSearchKeywordBasedOnKeyword,
    formattedRelatedKeywords,
    findSearchKeyword
} from "./utils";
import { advanceFilterSearch } from "../../../../../kw-research/ducks/utils";
import { getProcessedKeywordsName } from "../../../../../app/duck/utils";
import { OptionTypeInterface } from "src/app/styledComponents/drop-down/types";

export const INIT_STATE: MultiKeywordReducerInterface = {
    keywordTypeDropdownList,
    brandList: brandTypeList,
    enableSimulatePerformance: true,
    enableSimulateScore: true,
    keywordRequestIds: {},
    keywordProcessedInfo: {},
    selectedKeywords: [],
    isSubmitted: true,
    isLoading: false,
    keywords: MULTIKEYWORDS_SECTION_KEYWORDS,
    themeList: [],
    keywordList: [],
    pageKeywordList: [],
    keywordListFromURLParam: [],
    keywordListFromKwParam: [],
    filteredSearchKeyword: undefined,
    appliedFiltersOnKeyword: APPLIED_FILTERS_ON_KEYWORD,
    isPartialKeywordReady: false,
    processedKeyword: [],
    selectedMultiKeywordLocale: undefined,
    isExpanded: false,
    is_url_live_in_mkw: undefined,
    fileName: undefined,
    liveNonLiveFlow: true,
    isFileUploaded: false,
    fileUpload_requestId: undefined,
    clearFile: false,
    isThemeKeywordLoading: false,
    isRelatedKeywordFetching: false,
    isRelatedKeywordURLParamFetching: false, // marks status of Related Keyowrds fetched by giving URL as param for SSE service
    isRelatedKeywordKwParamFetching: false, // marks status of Related Keyowrds fetched by giving Keyword as param for SSE service
    isRelatedKeywordNonLiveFetching: false,
    sseRetriedAttempt: 0,
    sseRetriedAttemptURLParam: 0,
    sseRetriedAttemptKwParam: 0,
    sseNonLiveRetriedAttempt: 0,
    selectedkeywordSectionType: MULTI_KEYWORD_SECTION_TYPES[0],
    topicKeyword: undefined,
    topicKeywordNonLive: undefined,
    enableCompetitorApi: true,
    multikeywordDomainUrl: undefined,
    mkwTrackInitiated: false,
    searchIncludeKeywords: [],
    searchExcludeKeywords: [],
    filterType: "",
    includeKeywordType: "all",
    showLoaderBar: false,
    isProceedButtonClicked: false,
    isTrackRequestStart: false,
    /* marks which type of Related keyword Response got stored first, we use this to first populate ONLY that response type data in reducer variable
    Once the data from other param is available too, we combine the data on User's click into reducer variable */
    relatedKeywordResponseType: undefined,
    isSSeDataFromUrlAndKeywordMerged: false,
    isTop3CompDataForAllKWsAvailable: undefined,
    isPageNotCrawled: true
};

const Reducer = handleActions(
    {
        [actionTypes.MKW_TRACK_INITIATED]: (state, action: AnyAction) => ({
            ...state,
            mkwTrackInitiated: action.payload
        }),
        [actionTypes.SET_MKW_DOMAIN_URL]: (state, action: AnyAction) => ({
            ...state,
            multikeywordDomainUrl: action.payload
        }),
        [actionTypes.SET_SSE_RETRIED_ATTEMPT]: (state, action: AnyAction) => ({
            ...state,
            sseRetriedAttempt: action.payload,
            keywordList: state.sseRetriedAttempt > 0 && action.payload === 0 ? [] : state.keywordList,
            pageKeywordList: state.sseRetriedAttempt > 0 && action.payload === 0 ? [] : state.pageKeywordList
        }),
        [actionTypes.SET_SSE_RETRIED_ATTEMPT_URL_PARAM]: (state, action: AnyAction) => ({
            ...state,
            sseRetriedAttemptURLParam: action.payload,
            keywordListFromURLParam: state.sseRetriedAttemptURLParam > 0 && action.payload === 0 ? [] : state.keywordListFromURLParam
        }),
        [actionTypes.SET_SSE_RETRIED_ATTEMPT_KEYWORD_PARAM]: (state, action: AnyAction) => ({
            ...state,
            sseRetriedAttemptKwParam: action.payload,
            keywordListFromKwParam: state.sseRetriedAttemptKwParam > 0 && action.payload === 0 ? [] : state.keywordListFromKwParam
        }),
        [actionTypes.SET_SSE_NON_LIVE_RETRIED_ATTEMPT]: (state, action: AnyAction) => ({
            ...state,
            sseNonLiveRetriedAttempt: action.payload,
            keywordList: state.sseNonLiveRetriedAttempt > 0 && action.payload === 0 ? [] : state.keywordList,
            pageKeywordList: state.sseNonLiveRetriedAttempt > 0 && action.payload === 0 ? [] : state.pageKeywordList
        }),
        [actionTypes.SET_RELATED_KEYWORDS_FETCHING]: (state, action: AnyAction) => ({
            ...state,
            isRelatedKeywordFetching: action.payload
        }),
        // Action to check status of Related Keyword call via URL PARAM
        [actionTypes.SET_RELATED_KEYWORDS_FETCHING_URL_PARAM]: (state, action: AnyAction) => ({
            ...state,
            isRelatedKeywordURLParamFetching: action.payload
        }),
        // Action to check status of Related Keyword call via Keyword PARAM
        [actionTypes.SET_RELATED_KEYWORDS_FETCHING_KEYWORD_PARAM]: (state, action: AnyAction) => ({
            ...state,
            isRelatedKeywordKwParamFetching: action.payload
        }),
        [actionTypes.SET_RELATED_KEYWORDS_URL_PARAM]: (state, action: AnyAction) => ({
            ...state,
            keywordListFromURLParam: action.payload
        }),
        [actionTypes.SET_RELATED_KEYWORDS_KEYWORD_PARAM]: (state, action: AnyAction) => ({
            ...state,
            keywordListFromKwParam: action.payload
        }),
        // Action to mark which type of Related Keyword response (URL or Keyword), which got stored first
        [actionTypes.SET_RELATED_KEYWORDS_RESPONSE_TYPE]: (state, action: AnyAction) => ({
            ...state,
            relatedKeywordResponseType: action.payload
        }),
        // Action to Reset SSE response status for both Keyword and URL param after the User cicks on merge in Multikeyword module
        [actionTypes.RESET_SSE_RESPONSE_STATES_AFTER_MERGE]: (state, action: AnyAction) => ({
            ...state,
            isRelatedKeywordURLParamFetching: RELATED_KW_SSE_EVENT_TYPES.req_completed,
            isRelatedKeywordKwParamFetching: RELATED_KW_SSE_EVENT_TYPES.req_completed,
            relatedKeywordResponseType: isEqual(action.payload, RelatedKeywordMergeType.KEYWORD_INTO_URL)
                ? RelatedKeywordSSEResponseType.KEYWORD_TYPE
                : isEqual(action.payload, RelatedKeywordMergeType.URL_INTO_KEYWORD)
                    ? RelatedKeywordSSEResponseType.URL_TYPE
                    : undefined,
            isSSeDataFromUrlAndKeywordMerged: true
        }),
        // Action to cancel on-going SSE URL request and reset SSE URL states
        [actionTypes.CANCEL_ON_GOING_SSE_URL_REQUEST]: (state, action: AnyAction) => ({
            ...state,
            isRelatedKeywordURLParamFetching: false,
            sseRetriedAttemptURLParam: 0,
            keywordListFromURLParam: [],
            relatedKeywordResponseType: undefined,
            isSSeDataFromUrlAndKeywordMerged: false
        }),
        [actionTypes.SET_RELATED_KEYWORDS_NON_LIVE_FETCHING]: (state, action: AnyAction) => ({
            ...state,
            isRelatedKeywordNonLiveFetching: action.payload // change
        }),
        [actionTypes.SET_RELATED_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            keywords: {
                ...state.keywords,
                relatedKeywords: formattedRelatedKeywords(action.payload)
            },
            keywordList: isEqual(state.selectedkeywordSectionType, MULTI_KEYWORD_SECTION_TYPES[0])
                ? formattedRelatedKeywords(action.payload)
                : [...state.keywordList]
        }),
        [actionTypes.SET_RELATED_NON_LIVE_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            keywords: {
                ...state.keywords,
                nonLiveRelatedKeywords: formattedRelatedKeywords(action.payload)
            },
            keywordList: !includes(MULTI_KEYWORD_SECTION_TYPES, state.selectedkeywordSectionType) // check if section type is not inlcuded
                ? formattedRelatedKeywords(action.payload)
                : [...state.keywordList]
        }),
        [actionTypes.SET_CURRENT_PAGINATION_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            pageKeywordList: action.payload
        }),
        [actionTypes.SET_IS_FILE_UPLOADED]: (state, action: AnyAction) => ({
            ...state,
            isFileUploaded: action.payload
        }),
        [actionTypes.SET_ENABLE_SIMULATE_PERFORMANCE]: (state, action: AnyAction) => ({
            ...state,
            enableSimulatePerformance: action.payload
        }),
        [actionTypes.SET_ENABLE_SIMULATE_SCORE]: (state, action: AnyAction) => ({
            ...state,
            enableSimulateScore: action.payload
        }),
        [actionTypes.SET_KEYWORD_REQUEST_IDS]: (state, action: AnyAction) => ({
            ...state,
            keywordRequestIds: action.payload
        }),
        [actionTypes.SET_SELECTED_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            selectedKeywords: action.payload
        }),
        [actionTypes.SET_IS_SUBMITTED]: (state, action: AnyAction) => ({
            ...state,
            isSubmitted: action.payload
        }),
        [actionTypes.SET_SHOW_LOADER]: (state, action: AnyAction) => ({
            ...state,
            isLoading: true
        }),
        [actionTypes.SET_HIDE_LOADER]: (state, action: AnyAction) => ({
            ...state,
            isLoading: false
        }),
        [actionTypes.SET_KEYWORD_PROCESSED_INFO]: (state, action: AnyAction) => ({
            ...state,
            keywordProcessedInfo: action.payload
        }),
        [actionTypes.SET_THEME_KEYWORD_FROM_PROJECT]: (state, action: AnyAction) => ({
            ...state,
            themeList: formattedThemeList(action.payload.themeList),
            keywords: {
                ...state.keywords,
                projectKeywords: formattedKeywordsList(action.payload.keywordList)
            }
            // keywordList: formattedKeywordsList(action.payload.keywordList)
        }),
        [actionTypes.IS_THEME_KEYWORD_FROM_PROJECT_LOADING]: (state, action: AnyAction) => ({
            ...state,
            isThemeKeywordLoading: action.payload,
            pageKeywordList: isEqual(state.selectedkeywordSectionType, "Project Keywords")
                ? state.keywords?.projectKeywords
                : state.pageKeywordList
        }),
        [actionTypes.SET_TO_SHOW_PROJECT]: (state, action: AnyAction) => ({
            ...state,
            showProjectKeyword: action.payload
        }),
        [actionTypes.APPLIED_FILETRS_ON_KEYWORD]: (state, action: AnyAction) => {
            function getFilteredSearchKeyword(): OptionTypeInterface[] | undefined {
                if (action.payload.type === AppliedFilter.KEYWORD_ADVANCE_FILTER) {
                    return advanceFilterSearch(
                        state.searchIncludeKeywords,
                        state.searchExcludeKeywords,
                        state.includeKeywordType,
                        state.keywordList,
                        state.filterType
                    );
                }
                if (action.payload.type === AppliedFilter.KEYWORDFILTER) {
                    if (state.searchExcludeKeywords.length || state.searchIncludeKeywords.length) {
                        const filteredSearchKeyword = advanceFilterSearch(
                            state.searchIncludeKeywords,
                            state.searchExcludeKeywords,
                            state.includeKeywordType,
                            state.keywordList,
                            state.filterType
                        );
                        return !isUndefined(action.payload)
                            ? findSearchKeywordBasedOnKeyword(action.payload.value, filteredSearchKeyword)
                            : undefined;
                    }
                    return !isUndefined(action.payload)
                        ? findSearchKeywordBasedOnKeyword(action.payload.value, state.keywordList)
                        : undefined;
                }
                return !isUndefined(action.payload) ? findSearchKeyword(state.appliedFiltersOnKeyword, state.keywordList) : undefined;
            }
            return {
                ...state,
                appliedFiltersOnKeyword: updateValueInAppliedFilter(assign({}, action.payload), assign({}, state.appliedFiltersOnKeyword)),
                filteredSearchKeyword: getFilteredSearchKeyword()
            };
        },
        [actionTypes.CALL_TO_GET_MULTI_KEYWORD_REQUEST_IDS_API]: (state, action: AnyAction) => ({
            ...state,
            isLoading: true,
            isSubmitted: true,
            enableSimulatePerformance: false,
            enableSimulateScore: false,
            enableCompetitorApi: false,
            isTop3CompDataForAllKWsAvailable: false,
            showLoaderBar: true
        }),

        [actionTypes.SET_SHOW_LOADER_BAR]: (state, action: AnyAction) => ({
            ...state,
            showLoaderBar: false
        }),

        [actionTypes.SET_PARTIAL_KEYWORD_FETCHED]: (state, action: AnyAction) => ({
            ...state,
            isPartialKeywordReady: true,
            processedKeyword: !isUndefined(state.keywordRequestIds)
                ? [...getProcessedKeywordsName(state.keywordProcessedInfo, state.keywordRequestIds)]
                : []
        }),
        [actionTypes.UPDATE_PROCESSED_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            isPartialKeywordReady: true,
            processedKeyword: !isUndefined(state.keywordRequestIds)
                ? [...getProcessedKeywordsName(state.keywordProcessedInfo, state.keywordRequestIds)]
                : []
        }),
        [actionTypes.RESET_PARTIAL_KEYWORD_FETCHED]: (state, action: AnyAction) => ({
            ...state,
            isPartialKeywordReady: false,
            processedKeyword: []
        }),
        [actionTypes.RESET_MKW]: (state, action: AnyAction) => ({
            ...INIT_STATE
        }),
        [actionTypes.SELECTED_MULTI_KEYWORD_LOCALE]: (state, action: AnyAction) => ({
            ...state,
            selectedMultiKeywordLocale: action.payload
        }),
        [actionTypes.SET_KEYWORD_URL_BOX_EXPANDED_MODE]: (state, action: AnyAction) => ({
            ...state,
            isExpanded: action.payload,
            filteredSearchKeyword: undefined
        }),
        [actionTypes.SET_IS_URL_LIVE_IN_MKW]: (state, action: AnyAction) => ({
            ...state,
            is_url_live_in_mkw: action.payload === true ? true : false
        }),
        [actionTypes.SET_FILE_NAME_MKW]: (state, action: AnyAction) => ({
            ...state,
            fileName: isEmpty(action.payload) ? undefined : action.payload
        }),
        [actionTypes.SET_FILE_UPLOAD_REQUEST_ID_MKW]: (state, action: AnyAction) => ({
            ...state,
            fileUpload_requestId: isEmpty(action.payload) ? undefined : action.payload
        }),
        [actionTypes.CLEAR_FILE]: (state, action: AnyAction) => ({
            ...state,
            clearFile: action.payload
        }),
        [actionTypes.UPDATE_PROCESSED_KEYWORDS_ON_VERSION_CHANGE]: (state, action: AnyAction) => ({
            ...state,
            processedKeyword: !isUndefined(state.keywordRequestIds)
                ? [...getProcessedKeywordsName(state.keywordProcessedInfo, state.keywordRequestIds)]
                : []
        }),
        [actionTypes.UPDATE_KEYWORD_DETAILS_BASED_ON_VERSION]: (state, action: AnyAction) => ({
            ...state,
            ...action.payload
        }),
        [actionTypes.CLEAR_KEYWORD_DETAILS_FROM_OLD_VERSION]: (state, action: AnyAction) => ({
            ...state,
            keywordRequestIds: {},
            keywordProcessedInfo: {},
            selectedKeywords: []
        }),

        // tslint:disable-next-line:no-any
        [actionTypes.SET_MULTI_KEYWORD_SECTION_TYPE]: (state: any, action: AnyAction) => ({
            ...state,
            selectedkeywordSectionType: action.payload,
            keywordList: !isEqual(action.payload, "Input Keywords")
                ? [...state.keywords[camelCase(action.payload as string)]]
                : state.keywordList,
            filteredSearchKeyword: undefined,
            appliedFiltersOnKeyword: { ...APPLIED_FILTERS_ON_KEYWORD, searchvolume: [], keywordtype: [], theme: [], relevancescore: [] }
        }),
        // tslint:disable-next-line:no-any
        [actionTypes.LIVE_NONLIVE_CHNAGE]: (state: any, action: AnyAction) => ({
            ...state,
            liveNonLiveFlow: action.payload,
            selectedkeywordSectionType: action.payload ? MULTI_KEYWORD_SECTION_TYPES[0] : "NonLive Related Keywords",
            keywordList: action.payload
                ? [...state.keywords[camelCase(MULTI_KEYWORD_SECTION_TYPES[0] as string)]]
                : state.keywords.nonLiveRelatedKeywords,
            filteredSearchKeyword: undefined,
            sseRetriedAttempt: 0,
            sseNonLiveRetriedAttempt: 0,
            appliedFiltersOnKeyword: { ...APPLIED_FILTERS_ON_KEYWORD, searchvolume: [], keywordtype: [], theme: [] }
        }),
        [actionTypes.SET_TOPIC_KEYWORD]: (state, action: AnyAction) => ({
            ...state,
            topicKeyword: action.payload
        }),
        [actionTypes.SET_TOPIC_KEYWORD_NON_LIVE]: (state, action: AnyAction) => ({
            ...state,
            topicKeywordNonLive: action.payload
        }),
        [actionTypes.SET_ENABLE_COMPETITOR_API]: (state, action: AnyAction) => ({
            ...state,
            enableCompetitorApi: action.payload
        }),

        [actionTypes.SET_INCLUDE_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            searchIncludeKeywords: action.payload
        }),
        [actionTypes.SET_EXCLUDE_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            searchExcludeKeywords: action.payload
        }),
        [actionTypes.SET_ADVANCE_FILTER_TYPE]: (state, action: AnyAction) => ({
            ...state,
            filterType: action.payload
        }),
        [actionTypes.MULTIKEYWORD_INCLUDE_KEYWORD_TYPE]: (state, action: AnyAction) => ({
            ...state,
            includeKeywordType: action.payload
        }),
        [actionTypes.SET_IS_PROCEED_BUTTON_CLICKED]: (state, action: AnyAction) => ({
            ...state,
            isProceedButtonClicked: action.payload
        }),
        [actionTypes.SET_IS_TRACK_REQUEST_START]: (state, action: AnyAction) => ({
            ...state,
            isTrackRequestStart: action.payload
        }),
        [actionTypes.MARK_STATUS_FOR_TOP_3_COMP_DATA_PROCESSING_FOR_ALL_KWS]: (state, action: AnyAction) => ({
            ...state,
            isTop3CompDataForAllKWsAvailable: action.payload
        }),
        [actionTypes.SET_PAGE_CRAWLED_STATUS]: (state, action: AnyAction) => ({
            ...state,
            isPageNotCrawled: action.payload
        }),
        [actionTypes.SET_SSERETRIEDATTEMPTKWPARAM]: (state, action: AnyAction) => ({
            ...state,
            sseRetriedAttempt: action.payload,
            sseRetriedAttemptKwParam: action.payload,
            sseRetriedAttemptURLParam: action.payload
        })
    },
    INIT_STATE
);

export default Reducer;
