import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */
const actionTypes = {
    CALL_FOR_SET_CONTEXT: "CALL_FOR_SET_CONTEXT",
    GET_SUBSCRIPTION: "GET_SUBSCRIPTION",
    GET_USER_DETAILS: "GET_USER_DETAILS",
    SET_SELECTED_OPTION: "SET_SELECTED_OPTION",
    SET_SUBSCRIPTION: "SET_SUBSCRIPTION",
    SET_USER_DETAILS: "SET_USER_DETAILS"
};
export default actionTypes;

export const getUserDetails = createAction(actionTypes.GET_USER_DETAILS);
export const setUserDetails = createAction(actionTypes.SET_USER_DETAILS);
export const getSubscription = createAction(actionTypes.GET_SUBSCRIPTION);
export const setSubscription = createAction(actionTypes.SET_SUBSCRIPTION);
export const setSelectedOption = createAction(actionTypes.SET_SELECTED_OPTION);
