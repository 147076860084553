import { History } from "history";
import { OptionTypeInterface } from "../../../app/styledComponents/drop-down/types";
import { FlowTypeInterface, SimulationHistoryResponseInterface } from "../../../app/duck/types";
import { MessageInternalPropsInterface } from "../../../app/styledComponents/general-message/messaging-provider-hoc";

export enum ContentOutlineAPIStatus {
    STARTED = "started",
    SUCCESS = "success",
    FAILED = "failed"
}

export type OptionValueType = string | boolean | number;
export type OptionValueOptinalType = string | boolean | number | string[] | undefined;

export interface PageOptimizerReducerInterface {
    isLoading: boolean;
    saveSimulationHistory: SimulationHistoryResponseInterface;
    existingURLSimulationHistory: SimulationHistoryResponseInterface;
    isAIAssistedContent: boolean;
    userPrompt: string;
    isAIContentFetchCompleted?: boolean;
    initialContentOutlineAPIStatus?: ContentOutlineAPIStatus;
    regenerateContentOutlineAPIStatus?: ContentOutlineAPIStatus;
}

export interface PageOptimizerPropsInterface extends KeywordSearchBoxReducerInterface {
    requestId?: string;
    history?: History;
    progressPercentage: number;
    actions: PageOptimizerActionsInterface;
    match: {
        path: string;
    };
    context: ContextReducerInterface;
    saveSimulationHistory: SimulationHistoryResponseInterface;
    paginationApiLoading: boolean;
    featureAddons?: string[];
    fileUpload_requestId?: string;
    existingUrlData: SimulationHistoryResponseInterface;
    selectedMultiKeywordLocale: string;
}
export interface PageOptimizerStateInterface {
    searchValue?: string;
    tabOption: number;
    keywordError: string;
    keywordInput: string;
    domainInputText: string;
    domainInputTextError: string;
    domainProtocolOption: OptionTypeInterface;
    locales: LocaleInterface[];
    selectedLocale?: OptionTypeInterface;
    isAccordionOpen: boolean;
    showSimulationData: boolean;
    selectedContentOption: number;
    fileUploadUrl?: string;
}
export interface PageOptimizerActionsInterface {
    setUniqueDomainUrl(url?: string): void;
    setActiveRouteUrl(url: string): void;
    setHideLoader(): void;
    getSimulationHistory(args: SaveSimulationHistoryInterface): void;
    setEditorMode(args: string): void;
    setIsBrowserMode(args: boolean): void;
    setUserPromptForContentOutline(args: UserPromptInterface): void;
    setSerpProgressStatusFromHistoryTable(): void;
    setSelectedSID(args: string): void;
    setDomainInContext(args: SetDomainInterface | string): void;
    setIsUrlLive(args: boolean | undefined): void;
    setLocaleInContext(args?: string): void;
    selectedMultiKeywordLocale(args?: string): void;
    setNewSimulationStatus(args: boolean): void;
    callToGetMultiKeywordRequestIdsApi(args: MultiKeywordRequestIdsApiRequestInterface): void;
    setSelectedTabRecommendation(args: number): void;
    setIsViewRecommendationOpen(args: boolean): void;
    markDoesInputKeywordsExceedAllowedLimit(args: boolean): void;
    setSelectedKeywords(args?: string[]): void;
    // tslint:disable-next-line: no-any
    callToGetLocales(args: any): void;
    setSerpListWhenTrackStop(): void;
    unsetSelectedSID(): void;
    callToGetAllProjectsList(): void;
    callToClearApp(): void;
    setFlowType(args: FlowTypeInterface): void;
    clearUserSelection(): void;
    getTenantLimits(args: GetTenantLimitInterface): void;
    onDomainUrlSearch(args: KeywordSearchPayloadInterface): void;
}

export interface SaveSimulationHistoryInterface {
    tenantId?: number;
    projectId: number;
    tenantCode: string;
    pageIndex: number;
    searchTerm?: string;
}

export interface MetaDetailsInterface {
    keywords: string[];
    url: string;
    locale: string;
    is_url_live: boolean;
    project_id?: number;
    description?: string;
    is_browser_mode?: boolean;
    sid: string;
}

export interface UserPromptInterface {
    AIGeneratedFlag?: boolean;
    userPrompt?: string;
}

interface SetDomainInterface {
    sim_url: string;
    alias_url: string;
}

export interface StyledTabOptionContainerPropsInterface {
    selectedTabOption?: number;
}

export interface StyledTabOptionPropsInterface {
    isSelectedTabOption?: boolean;
    selectedContentOption?: number;
}

export interface StyledPropsInterface {
    hasFillWidth: boolean;
    fillWidth?: boolean;
    align?: string;
    flowType?: string;
}

export interface LocaleInterface {
    code: string;
    country: string;
    language: string;
    name: string;
}

export type AdditionalOptionTypeInterface = {
    [key in string]?: OptionValueOptinalType;
};

export interface KeywordSearchPayloadInterface extends NotifyErrorInterface {
    project_id?: number | null;
    keyword?: string;
    locale?: string;
    tenant_code?: string;
    flowType?: FlowTypeInterface;
    search_url?: string | null;
}

export interface NotifyErrorInterface {
    needToHandleError?: boolean;
    isSpecificError?: boolean;
    errorList?: ErrorType[];
    notifyErrorMethod?(args: MessageInternalPropsInterface): void;
}

export interface ErrorType {
    id: number;
    status: number | string;
    errMsg: string;
}

export interface KeywordSearchBoxReducerInterface {
    locales: LocaleInterface[];
}

export interface MultiKeywordRequestIdsApiRequestInterface extends NotifyErrorInterface {
    keyword: string[];
    search_url?: string[];
    locale?: string;
    is_url_live: boolean;
    force_refresh_url_content?: boolean;
    project_id?: number | null;
    tenant_code: string;
}

export interface GetTenantLimitInterface {
    userID: string;
    isCalledFromHomePage: boolean;
    getSimHistorypayload?: GetSaveHistoryPayloadInterface;
}

export interface GetSaveHistoryPayloadInterface {
    tenantId: number;
    hasContentOutlineFeatureAccess?: string;
    projectId: number;
    tenantCode: string;
    pageIndex: number;
    searchTerm?: string;
    isBeta?: boolean;
    isLoadingShow?: boolean;
}

export interface ContextReducerInterface {
    user_email?: string;
    uniqueDomainUrl?: string;
    selectedDomain?: string;
    projectDomain?: string;
    domainAliasUrl?: string;
    selectedKeyword?: string;
    selectedLocale?: string;
    projectId?: number;
    projectName?: string;
    isMobileProject?: boolean;
    isStandardProject?: boolean;
    userId?: string;
    tenantCode?: string;
    username?: string;
    flowType?: FlowTypeInterface;
    featureAddons?: string[];
    selectedSID?: string;
    isEnableRunSimulation?: boolean;
    // tslint:disable-next-line: no-any
    projectsList?: ProjectListInterface[];
    keywordLimit?: number;
    domainLimit?: number;
}

export interface ProjectListInterface {
    [key: string]: OptionalValueInterface | null;
}

export interface OptionalValueInterface {
    [key: string]: string | undefined | number | boolean;
}

export interface StyledCreateContentOptionPropsInterface {
    isSelectedContentOption?: boolean;
    selectedContentOption?: number;
}

export interface StyledPropsErrorInterface {
    highlightError: boolean;
}
export interface StyledPropsFlowTypeInterface {
    flowType?: FlowTypeInterface;
}
export interface KeywordSearchAccordionHeaderInterface {
    isOpen: boolean;
    instanceId: string;
    toggleHandler(): void;
}

export interface PageOptimizationConstInterface {
    [key: string]: string;
}

export interface StyledSimulationBodyAccInterface {
    openSelectedAccordian?: boolean;
    width?: number;
}

