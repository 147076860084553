import ServiceRequest from "../../../app/services/index";
import { ApiPath, METHOD } from "../../../app/config/apiPath";
import { ParserInterface } from "../../../app/duck/types";
import { ApiConfig } from "../../../app/config/apiConfig";
import { GenerateContentOutlinePayloadInterface } from "../components/NewSimulation/ducks/types";
import { GetSaveHistoryPayloadInterface } from "./types";
import { isUndefined } from "lodash";

class Apis {
    public *fetchAIGeneratedContent(
        payload: GenerateContentOutlinePayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            data: {
                ...payload
            },
            method: METHOD.POST,
            url: ApiPath.GENERATE_CONTENT_OUTLINE.replace("{{tenant_code}}", parser.tenantCode)
        };
        const scores = yield ServiceRequest.invoke(axiosParam);
        return scores;
    }

    public *getSaveSimulationHistory(
        payload: GetSaveHistoryPayloadInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            params: {
                page_index: payload.pageIndex,
                ...(!isUndefined(payload.searchTerm) && { search_term: payload.searchTerm }),
                has_content_outline_access: payload.hasContentOutlineFeatureAccess
            },
            url: (payload.isBeta ? ApiPath.SAVE_SIMULATION_HISTORY_BETA : ApiPath.SAVE_SIMULATION_HISTORY).replace(
                "{{tenantCode}}",
                payload.tenantCode
            )
        };
        return yield ServiceRequest.invoke(axiosParam);
    }
}
export default new Apis();
