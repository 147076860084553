import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import { assign, isUndefined } from "lodash";
import actionTypes from "./actions";
import { APPLIED_FILTERS_ON_KEYWORD, KRT_INCLUDE_KW_OPTION } from "../../app/const";
import { AppliedFilter, SuggestedKeywordReducerInterface } from "./types";
import {
    formattedRelatedKeywords, updateValueInAppliedFilter
} from "./utils";
import { advanceFilterSearch, findAdvanceSearchKeyword } from "../../kw-research/ducks/utils";

export const INIT_STATE: SuggestedKeywordReducerInterface = {
    relatedKeywords: [],
    suggestedKeywords: [],
    sseAPIloading: false,
    appliedFiltersOnRKeyword: APPLIED_FILTERS_ON_KEYWORD,
    filteredSearchKeyword: undefined,
    keywordSseRetriedAttempt: 0,
    clearAllFilters: false,
    requestIdAPIStatus: false,
    currentTopicOfCB: "",
    searchCBExcludeKeywords: [],
    searchCBIncludeKeywords: [],
    filterType: "",
    CBIncludeKeywordType: KRT_INCLUDE_KW_OPTION[0].value as string,
    isSearchThroughInput: true
};

const Reducer = handleActions(
    {
        [actionTypes.SET_SUGGESTED_RELATED_KW]: (state, action: AnyAction) => ({
            ...state,
            relatedKeywords: formattedRelatedKeywords(action.payload),
            suggestedKeywords: action.payload
        }),
        [actionTypes.SSE_API_LOADING]: (state, action: AnyAction) => ({
            ...state,
            sseAPIloading: action.payload
        }),
        [actionTypes.APPLIED_KEYWORD_FILTERS]: (state, action: AnyAction) => ({
            ...state,
            appliedFiltersOnRKeyword: updateValueInAppliedFilter(assign({}, action.payload), assign({}, state.appliedFiltersOnRKeyword)),
            filteredSearchKeyword: !isUndefined(action.payload)
                ? action.payload.type === AppliedFilter.KEYWORD_ADVANCE_FILTER
                    ? advanceFilterSearch(
                        state.searchCBIncludeKeywords,
                        state.searchCBExcludeKeywords,
                        state.CBIncludeKeywordType,
                        state.relatedKeywords,
                        state.filterType)
                    : findAdvanceSearchKeyword(
                        updateValueInAppliedFilter(assign({}, action.payload), assign({}, state.appliedFiltersOnRKeyword)),
                        state.relatedKeywords,
                        state.CBIncludeKeywordType,
                        state.searchCBIncludeKeywords,
                        state.searchCBExcludeKeywords,
                    )
                : undefined
        }),

        [actionTypes.SET_KEYWORD_SSE_RETRIED_ATTEMPT]: (state, action: AnyAction) => ({
            ...state,
            keywordSseRetriedAttempt: action.payload,
            keywordList: state.keywordSseRetriedAttempt > 0 && action.payload === 0 ? [] : state.relatedKeywords,
        }),
        [actionTypes.CLEAR_ALL_FILTERS]: (state, action: AnyAction) => ({
            ...state,
            clearAllFilters: action.payload,
            CBIncludeKeywordType: KRT_INCLUDE_KW_OPTION[0].value as string,
            searchCBExcludeKeywords: [],
            searchCBIncludeKeywords: [],
            appliedFiltersOnRKeyword: action.payload ?
                { ...APPLIED_FILTERS_ON_KEYWORD, searchvolume: [], keywordtype: [], relevancescore: [] } : state.appliedFiltersOnRKeyword,
            filteredSearchKeyword: undefined
        }),
        [actionTypes.REQUEST_ID_API_STATUS]: (state, action: AnyAction) => ({
            ...state,
            requestIdAPIStatus: action.payload,
        }),
        [actionTypes.CURRENT_TOPIC_OF_NEW_CB]: (state, action: AnyAction) => ({
            ...state,
            currentTopicOfCB: action.payload,
        }),
        [actionTypes.SET_CB_INCLUDE_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            searchCBIncludeKeywords: action.payload
        }),
        [actionTypes.SET_CB_EXCLUDE_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            searchCBExcludeKeywords: action.payload
        }),
        [actionTypes.SET_CB_ADVANCE_FILTER_TYPE]: (state, action: AnyAction) => ({
            ...state,
            filterType: action.payload
        }),
        [actionTypes.MULTIKEYWORD_INCLUDE_KEYWORD_TYPE]: (state, action: AnyAction) => ({
            ...state,
            CBIncludeKeywordType: action.payload
        })
    },
    INIT_STATE
);

export default Reducer;
