import React from "react";
import { Provider } from "react-redux";
import { render } from "react-dom";
import { injectGlobal } from "styled-components";

// @ts-ignore
import store from "./app/store.tsx";
import AlpsInterface from "./DEPRICATED__App/AlpsInterface/Index";
import { BODY_BACKGROUND_COLOR } from "./DEPRICATED__App/CONSTANT";
/**
 * Need to remove explicit imports for css
 */
import "./assets/reset.css";
import isAuthorized from "./DEPRICATED__App/service/isAuthorized";
import Routes from "./routes";
import "../assets/styles/style.css";
import HOCAppMessagingListner from "./app/styledComponents/general-message/messaging-provider-hoc";
import AutoLogin from "./auto-login";
import { isValidUser, isUnAuthorized, isExpireUser } from "./app/duck/utils";
import EmailVerification from "./email-verification/index";
import ExpiryUserUpgradePage from "./routes/components/Upgrade-now/UpgradeNowComponent/expiryUserUpgradePage";

// eslint-disable-next-line
injectGlobal`
    body {
        margin:0;
        padding:0;
        background-color: ${BODY_BACKGROUND_COLOR};
        font-family: 'Lato', sans-serif;
        font-size: 16px; 
    } 
`;

const ApplicationMessageListner = HOCAppMessagingListner(<AlpsInterface render={(props) => <Routes {...props} />} />);

if (isValidUser()) {
    render(<Provider store={store}>{<AutoLogin />}</Provider>, document.getElementById("root"));
} else if (isUnAuthorized()) {
    render(<Provider store={store}>{<EmailVerification />}</Provider>, document.getElementById("root"));
} else if (isExpireUser()) {
    const linkItems = window.location.href.split("/");
    render(<Provider store={store}>{<ExpiryUserUpgradePage tenant_code={linkItems[4]} current_plan={linkItems[5]} user_id={linkItems[6]} />}</Provider>, document.getElementById("root"))
} else if (isAuthorized()) {
    render(<Provider store={store}>{<ApplicationMessageListner dissolveTime={5000} />}</Provider>, document.getElementById("root"));
}