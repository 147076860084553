import { IS_DEV_SRC } from "./const";
// tslint:disable: no-var-requires
const upArrow = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/arrow-down-black.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/arrow-down-black.svg`);
const downArrow = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/arrow-down-blue.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/arrow-down-blue.svg`);
const add = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/add.svg` : require(`${process.env.DEV_IMAGE_URL}/img/add.svg`);
const addWhite = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/add-white.svg` : require(`${process.env.DEV_IMAGE_URL}/img/add-white.svg`);
const close = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/close-white.svg` : require(`${process.env.DEV_IMAGE_URL}/img/close-white.svg`);
const closeBlack = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/black-close-icon.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/black-close-icon.svg`);
const closeGrey = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/close.svg` : require(`${process.env.DEV_IMAGE_URL}/img/close.svg`);
const closeCross = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/close-cross.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/close-cross.svg`);
const paginationDeactivateLeftIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/cl.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/cl.svg`);
const paginationActiveRightIcon = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/cr.svg` : require(`${process.env.DEV_IMAGE_URL}/img/cr.svg`);
const info = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/info.png` : require(`${process.env.DEV_IMAGE_URL}/img/info.png`);
const warning = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/warning.svg` : require(`${process.env.DEV_IMAGE_URL}/img/warning.svg`);
const cross = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/cross-error-icon.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/cross-error-icon.svg`);
const removeIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/remove-icon.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/remove-icon.svg`);
const success = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/point-green.svg` : require(`${process.env.DEV_IMAGE_URL}/img/point-green.svg`);
const expandFill = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/expand.svg` : require(`${process.env.DEV_IMAGE_URL}/img/expand.svg`);
const collapseFill = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/collapse.svg` : require(`${process.env.DEV_IMAGE_URL}/img/collapse.svg`);
const expandIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/expand-icon.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/expand-icon.svg`);
const collapseIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/collapse-icon-new.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/collapse-icon-new.svg`);
const rightArrow = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/arrow-right-blue.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/arrow-right-blue.svg`);
const searchIcon = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/search.svg` : require(`${process.env.DEV_IMAGE_URL}/img/search.svg`);
const uparrowfill = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/arrow-fill-right.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/arrow-fill-right.svg`);
const downarrowfill = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/arrow-fill-down.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/arrow-fill-down.svg`);
const infoinvert = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/info.svg` : require(`${process.env.DEV_IMAGE_URL}/img/info.svg`);
const arrowPointedRight = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/chevron-right-white.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/chevron-right-white.svg`);
const arrowPointedLeft = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/chevron-left-white.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/chevron-left-white.svg`);
const disabledUpArrow = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/disabled-down-arrow.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/disabled-down-arrow.svg`);
const logo = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/alps-ai-logo.svg` : require(`${process.env.DEV_IMAGE_URL}/img/alps-ai-logo.svg`);
const alpsLogo = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/alps-ai-logo.png`
    : require(`${process.env.DEV_IMAGE_URL}/img/alps-ai-logo.png`);
const projectIcon = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/project.svg` : require(`${process.env.DEV_IMAGE_URL}/img/project.svg`);
const helpIcon = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/help.svg` : require(`${process.env.DEV_IMAGE_URL}/img/help.svg`);
const contactIcon = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/contact.svg` : require(`${process.env.DEV_IMAGE_URL}/img/contact.svg`);
const profileIcon = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/profile.svg` : require(`${process.env.DEV_IMAGE_URL}/img/profile.svg`);
const menuArrowDownGray = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/arrow-down-gray.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/arrow-down-gray.svg`);
const menuArrowDownBlue = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/arrow-down-blue.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/arrow-down-blue.svg`);
const documentIcon = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/document.svg` : require(`${process.env.DEV_IMAGE_URL}/img/document.svg`);
const downArrowWhite = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/chevron-down-white.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/chevron-down-white.svg`);
const upArrowWhite = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/white-icon-chevron-down-for-menu.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/white-icon-chevron-down-for-menu.svg`);
const arrowDownGrayFill = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/down-arrow.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/down-arrow.svg`);
const arrowDownBlueFill = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/blue-caret-down.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/blue-caret-down.svg`);
const arrowTab = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/Arrow-bg.png` : require(`${process.env.DEV_IMAGE_URL}/img/Arrow-bg.png`);
const arrow = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/arrow.png` : require(`${process.env.DEV_IMAGE_URL}/img/arrow.png`);
const auditIcon = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/audits-icon.svg` : require(`${process.env.DEV_IMAGE_URL}/img/audits-icon.svg`);
const errorComputer = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/computer-error.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/computer-error.svg`);
const errorGSCNoAccount = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/view-report.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/view-report.svg`);
const downloadButton = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/download-square-button.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/download-square-button.svg`);
const error = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/error-red.svg` : require(`${process.env.DEV_IMAGE_URL}/img/error-red.svg`);
const errorSmallSize = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/error-small-size.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/error-small-size.svg`);
const editorViewToggleIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/2-2-action-general-toggle-on.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/2-2-action-general-toggle-on.svg`);
const StyledEditIcon = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/edit.svg` : require(`${process.env.DEV_IMAGE_URL}/img/edit.svg`);
const StyledEditIconBlue = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/edit-blue.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/edit-blue.svg`);
const saveRefreshIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/refresh-gray.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/refresh-gray.svg`);
const refreshBorderIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/refresh-border-gray.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/refresh-border-gray.svg`);
const copyrightIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/copy-footer.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/copy-footer.svg`);
const infoIcon = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/info-2.svg` : require(`${process.env.DEV_IMAGE_URL}/img/info-2.svg`);
const utilityCloseIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/2-1-utility-close.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/2-1-utility-close.svg`);
const refreshIcon = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/refresh.svg` : require(`${process.env.DEV_IMAGE_URL}/img/refresh.svg`);
const whiteDownFillArrow = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/down-white-fill-arrow.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/down-white-fill-arrow.svg`);
const whiteUpFillArrow = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/up-white-fill-arrow.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/up-white-fill-arrow.svg`);
const blueCloseArrowFill = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/blue-fill-arrow-close.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/blue-fill-arrow-close.svg`);
const leftBackArrowButton = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/left-gray-arrow.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/left-gray-arrow.svg`);
const sortupArrow = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/sort.svg` : require(`${process.env.DEV_IMAGE_URL}/img/sort.svg`);
const downloadDisabledButton = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/download-disabled.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/download-disabled.svg`);
const downloadInProgressButton = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/download-loading.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/download-loading.svg`);
const downloadHoverButton = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/download-hover.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/download-hover.svg`);
const arrowDownBlue = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/arrow-blue.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/arrow-blue.svg`);
const dropdownArrorw = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/arrow-down-black-fill.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/arrow-down-black-fill.svg`);
const flagsImg = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/flags.png` : require(`${process.env.DEV_IMAGE_URL}/img/flags.png`);
const downloadIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/download-arrow.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/download-arrow.svg`);
const downloadDisabled = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/download-sm-disabled.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/download-sm-disabled.svg`);
const infoRed = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/info-red.svg` : require(`${process.env.DEV_IMAGE_URL}/img/info-red.svg`);
const dropDownArrowGary = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/arrow-down-black-fill.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/arrow-down-black-fill.svg`);
const sortUpArrow = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/sort.svg` : require(`${process.env.DEV_IMAGE_URL}/img/sort.svg`);
const disableUpArrow = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/disabled-sort.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/disabled-sort.svg`);
const dropDownArrowBlue = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/arrow-fill-down-1.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/arrow-fill-down-1.svg`);
const exportIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/action-export.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/action-export.svg`);
const exportDisabledIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/export-disabled.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/export-disabled.svg`);
const plusQuestionsUi = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/add-copy.svg` : require(`${process.env.DEV_IMAGE_URL}/img/add-copy.svg`);
const minusQuestionsUi = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/close-questions-ui-skyblue.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/close-questions-ui-skyblue.svg`);
const toggleDisable = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/toggle-disable.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/toggle-disable.svg`);
const disableZoomMode = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/disable-zoom-mode.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/disable-zoom-mode.svg`);
const contentBriefIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/sticky-notes.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/sticky-notes.svg`);
const redoIcon = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/redo.svg` : require(`${process.env.DEV_IMAGE_URL}/img/redo.svg`);
const undoIcon = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/undo.svg` : require(`${process.env.DEV_IMAGE_URL}/img/undo.svg`);
const closeMinusWhite = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/close-copy-3.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/close-copy-3.svg`);
const scoreBoardIcon = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/monitor.svg` : require(`${process.env.DEV_IMAGE_URL}/img/monitor.svg`);
const alpsSvg = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/gray-alps.svg` : require(`${process.env.DEV_IMAGE_URL}/img/gray-alps.svg`);
const unlockAlps = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/unlock-alps.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/unlock-alps.svg`);
const lockIconAlps = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/lock.svg` : require(`${process.env.DEV_IMAGE_URL}/img/lock.svg`);
const lockWhiteAlps = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/lock-white.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/lock-white.svg`);
const resizeIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/2-2-action-general-collapse-grey.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/2-2-action-general-collapse-grey.svg`);
const tickIconAlps = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/2-1-utility-tick-blue.png`
    : require(`${process.env.DEV_IMAGE_URL}/img/2-1-utility-tick-blue.png`);
const TickBlue = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/tick-blue.svg` : require(`${process.env.DEV_IMAGE_URL}/img/tick-blue.svg`);
const CloseSmall = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/close-small.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/close-small.svg`);
const noKeywordsAdded = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/no-keywords-added.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/no-keywords-added.svg`);
const googleLogo = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/google-logo.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/google-logo.svg`);
const closePlusBlackIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/close-plus-black-icon.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/close-plus-black-icon.svg`);
const openBlueIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/open-blue-icon.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/open-blue-icon.svg`);
const disableThemeViewToggle = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/disableThemeViewToggle.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/disableThemeViewToggle.svg`);
const calculateScores = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/score-calculate.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/score-calculate.svg`);
const deleteDisabledIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/delete-disabled-button.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/delete-disabled-button.svg`);
const deleteEnabledIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/delete-enabled.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/delete-enabled.svg`);
const domainDetailsModalLeftArrowIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/arrow-left-copy-4.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/arrow-left-copy-4.svg`);
const navShareOfVoiceIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/2-3-product-24-px-share-of-voice.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/2-3-product-24-px-share-of-voice.svg`);
const navTropicalAuthorityIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/2-3-product-24-px-tropical-authority.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/2-3-product-24-px-tropical-authority.svg`);
const navALPSScoreAnalysisIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/2-3-product-24-px-score-report.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/2-3-product-24-px-score-report.svg`);
const navThemePrioritizationIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/2-3-product-24-px-opportunity-plan.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/2-3-product-24-px-opportunity-plan.svg`);

const navKeywordResearchIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/2-3-product-24-px-keyword-research.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/2-3-product-24-px-keyword-research.svg`);
const navKeywordGapAnalysisIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/2-3-product-24-px-keyword-gap-analysis.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/2-3-product-24-px-keyword-gap-analysis.svg`);
const navContentBriefIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/2-3-product-24-px-content-brief.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/2-3-product-24-px-content-brief.svg`);
const navPageSimulationIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/2-3-product-24-px-page-simulation.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/2-3-product-24-px-page-simulation.svg`);
const navProjectDashboardIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/2-3-product-24-px-dashboard.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/2-3-product-24-px-dashboard.svg`);
const navRankTrackingIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/2-3-product-24-px-rank-tracking.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/2-3-product-24-px-rank-tracking.svg`);
const navGSCAnalyticsIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/2-3-product-24-px-google-search-analytics.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/2-3-product-24-px-google-search-analytics.svg`);
const navContentAuditIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/2-3-product-24-px-audits.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/2-3-product-24-px-audits.svg`);
const navBacklinkAuditIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/2-3-product-24-px-backlink-audit-blue.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/2-3-product-24-px-backlink-audit-blue.svg`);
const navTechnicalAuditIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/2-3-product-24-px-reports-icon.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/2-3-product-24-px-reports-icon.svg`);
const whiteInfoIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/white-info-icon.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/white-info-icon.svg`);
const closeBlueIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/close-blue-copy-3.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/close-blue-copy-3.svg`);
const redBulbIcon = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/red-bulb.svg` : require(`${process.env.DEV_IMAGE_URL}/img/red-bulb.svg`);
const greenBulbIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/green-bulb.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/green-bulb.svg`);
const yellowBulbIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/yellow-bulb.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/yellow-bulb.svg`);
const orangeBulbIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/orange-Bulb.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/orange-Bulb.svg`);
const greyBulbIcon = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/grey-Bulb.svg` : require(`${process.env.DEV_IMAGE_URL}/img/grey-Bulb.svg`);
const leftArrowKLI = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/left-arrow-kli.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/left-arrow-kli.svg`);
const rightArrowKLI = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/right-arrow-kli.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/right-arrow-kli.svg`);
const InsightModalCloseIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/insight-modal-utility-close.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/insight-modal-utility-close.svg`);
const GreenThumbsUpIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/green-thumbs-up.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/green-thumbs-up.svg`);
const RedThumbsDownIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/red-thumbs-down.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/red-thumbs-down.svg`);
const GreenThumbsUpIconFilled = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/fill-like.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/fill-like.svg`);
const RedThumbsDownIconFilled = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/fill-unlike.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/fill-unlike.svg`);
const NotificationIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/bell-gray.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/bell-gray.svg`);
const greenDeltaIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/green-delta.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/green-delta.svg`);
const redDeltaIcon = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/red-delta.svg` : require(`${process.env.DEV_IMAGE_URL}/img/red-delta.svg`);
const upArrowDarkBlue = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/up-arrow-dark-blue.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/up-arrow-dark-blue.svg`);
const downArrowDarkBlue = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/down-arrow-dark-blue.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/down-arrow-dark-blue.svg`);
const userImg = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/User.svg` : require(`${process.env.DEV_IMAGE_URL}/img/User.svg`);
const grayTick = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/gray-tick.svg` : require(`${process.env.DEV_IMAGE_URL}/img/gray-tick.svg`);
const orangeLine = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/orange-line.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/orange-line.svg`);
const orangePlus = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/orange-plus.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/orange-plus.svg`);
const chipReverse = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/CHIP_Reverse.png`
    : require(`${process.env.DEV_IMAGE_URL}/img/CHIP_Reverse.png`);
const freshWorks = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/Freshworks_img.png`
    : require(`${process.env.DEV_IMAGE_URL}/img/Freshworks_img.png`);
const humana = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/Humana_img.png` : require(`${process.env.DEV_IMAGE_URL}/img/Humana_img.png`);
const gmt = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/GMT_img.png` : require(`${process.env.DEV_IMAGE_URL}/img/GMT_img.png`);
const mimeCast = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/Mimecast_img.png`
    : require(`${process.env.DEV_IMAGE_URL}/img/Mimecast_img.png`);
const greenTick = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/Green-tick.svg` : require(`${process.env.DEV_IMAGE_URL}/img/Green-tick.svg`);
const downArrowFillBlack = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/arrow-down-black-fill.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/arrow-down-black-fill.svg`);
const upArrowFillBlack = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/arrow-up-black.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/arrow-up-black.svg`);
const lightArrowLeft = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/light_arrow_left.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/light_arrow_left.svg`);
const darkArrowRight = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/dark_arrow_right.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/dark_arrow_right.svg`);
const darkArrowLeft = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/dark_arrow_left.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/dark_arrow_left.svg`);
const lightArrowRight = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/light_arrow_right.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/light_arrow_right.svg`);
const handIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/icon-hand-cursor.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/icon-hand-cursor.svg`);
const copyIcon = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/Copy.svg` : require(`${process.env.DEV_IMAGE_URL}/img/Copy.svg`);
const dataPrivacyPolicyIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/data-privacy-policy.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/data-privacy-policy.svg`);
const correlationImg = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/bulb-new.svg` : require(`${process.env.DEV_IMAGE_URL}/img/bulb-new.svg`);
const arrowUp = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/up-arrow.svg` : require(`${process.env.DEV_IMAGE_URL}/img/up-arrow.svg`);
const arrowDown = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/arrow-down.svg` : require(`${process.env.DEV_IMAGE_URL}/img/arrow-down.svg`);
const chevronLeft = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/chevron-left.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/chevron-left.svg`);
const chevronRight = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/chevron-right.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/chevron-right.svg`);
const alpsLogoLogin = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/alps-ai-logo-login.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/alps-ai-logo-login.svg`);
const circularLoader = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/circular-loader.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/circular-loader.svg`);
const searchAwardEurope = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/european-2021-search-award.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/european-2021-search-award.svg`);
const bestSoftwareAward = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/European-2021-best-software.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/European-2021-best-software.svg`);
const searchSoftwareAward = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/best-search-software-award.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/best-search-software-award.svg`);
const searchAwardUS = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/US-search-award.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/US-search-award.svg`);
const multiLayerIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/multiple_layer_icon.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/multiple_layer_icon.svg`);
const dropDownArrowWhite = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/dropdown_arrow_white.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/dropdown_arrow_white.svg`);
const forgotLockBg = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/forgot_lock_bg.png`
    : require(`${process.env.DEV_IMAGE_URL}/img/forgot_lock_bg.png`);
const loginPagePwdIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/loginPage_pwd_icon.png`
    : require(`${process.env.DEV_IMAGE_URL}/img/loginPage_pwd_icon.png`);
const successIconWhite = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/success_icon_white.png`
    : require(`${process.env.DEV_IMAGE_URL}/img/success_icon_white.png`);
const mainLoginBg = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/main_login_bg.jpg`
    : require(`${process.env.DEV_IMAGE_URL}/img/main_login_bg.jpg`);
const loginPageErrCross1 = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/loginPage_error_cross1.jpg`
    : require(`${process.env.DEV_IMAGE_URL}/img/loginPage_error_cross1.jpg`);
const alertIcon = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/404_alert_icon.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/404_alert_icon.svg`);
const ssoButton = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/button_SSO.png`
    : require(`${process.env.DEV_IMAGE_URL}/img/button_SSO.png`);
const LoaderImg = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/loading-circles.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/loading-circles.svg`);
const eye = IS_DEV_SRC
    ? `${process.env.IMAGE_URL}/img/eye.svg`
    : require(`${process.env.DEV_IMAGE_URL}/img/eye.svg`);
export {
    StyledEditIcon,
    saveRefreshIcon,
    upArrow,
    downArrow,
    add,
    addWhite,
    close,
    expandFill,
    collapseFill,
    expandIcon,
    collapseIcon,
    rightArrow,
    closeGrey,
    closeCross,
    searchIcon,
    uparrowfill,
    downarrowfill,
    infoinvert,
    arrowPointedRight,
    arrowPointedLeft,
    disabledUpArrow,
    logo,
    projectIcon,
    helpIcon,
    contactIcon,
    profileIcon,
    menuArrowDownGray,
    menuArrowDownBlue,
    documentIcon,
    downArrowWhite,
    upArrowWhite,
    arrowDownGrayFill,
    arrowDownBlueFill,
    arrowTab,
    alpsLogo,
    auditIcon,
    errorComputer,
    arrow,
    downloadButton,
    cross,
    removeIcon,
    success,
    warning,
    info,
    error,
    editorViewToggleIcon,
    refreshBorderIcon,
    paginationActiveRightIcon,
    paginationDeactivateLeftIcon,
    copyrightIcon,
    infoIcon,
    utilityCloseIcon,
    refreshIcon,
    StyledEditIconBlue,
    whiteDownFillArrow,
    whiteUpFillArrow,
    blueCloseArrowFill,
    closeBlack,
    sortupArrow,
    disableUpArrow,
    downloadDisabledButton,
    downloadInProgressButton,
    leftBackArrowButton,
    arrowDownBlue,
    dropdownArrorw,
    flagsImg,
    downloadIcon,
    downloadDisabled,
    infoRed,
    dropDownArrowGary,
    sortUpArrow,
    dropDownArrowBlue,
    exportIcon,
    exportDisabledIcon,
    errorGSCNoAccount,
    toggleDisable,
    disableZoomMode,
    contentBriefIcon,
    plusQuestionsUi,
    minusQuestionsUi,
    redoIcon,
    undoIcon,
    closeMinusWhite,
    alpsSvg,
    unlockAlps,
    lockIconAlps,
    lockWhiteAlps,
    scoreBoardIcon,
    resizeIcon,
    errorSmallSize,
    TickBlue,
    CloseSmall,
    noKeywordsAdded,
    googleLogo,
    closePlusBlackIcon,
    openBlueIcon,
    disableThemeViewToggle,
    domainDetailsModalLeftArrowIcon,
    calculateScores,
    deleteDisabledIcon,
    deleteEnabledIcon,
    tickIconAlps,
    navShareOfVoiceIcon,
    navTropicalAuthorityIcon,
    navALPSScoreAnalysisIcon,
    navThemePrioritizationIcon,
    navKeywordResearchIcon,
    navKeywordGapAnalysisIcon,
    navContentBriefIcon,
    navPageSimulationIcon,
    navProjectDashboardIcon,
    navRankTrackingIcon,
    navGSCAnalyticsIcon,
    navContentAuditIcon,
    navBacklinkAuditIcon,
    navTechnicalAuditIcon,
    downloadHoverButton,
    whiteInfoIcon,
    closeBlueIcon,
    redBulbIcon,
    greenBulbIcon,
    yellowBulbIcon,
    orangeBulbIcon,
    greyBulbIcon,
    leftArrowKLI,
    rightArrowKLI,
    InsightModalCloseIcon,
    GreenThumbsUpIcon,
    GreenThumbsUpIconFilled,
    RedThumbsDownIcon,
    RedThumbsDownIconFilled,
    NotificationIcon,
    redDeltaIcon,
    greenDeltaIcon,
    upArrowDarkBlue,
    downArrowDarkBlue,
    userImg,
    grayTick,
    orangeLine,
    orangePlus,
    chipReverse,
    freshWorks,
    humana,
    gmt,
    mimeCast,
    greenTick,
    downArrowFillBlack,
    upArrowFillBlack,
    lightArrowLeft,
    darkArrowRight,
    darkArrowLeft,
    lightArrowRight,
    handIcon,
    copyIcon,
    correlationImg,
    dataPrivacyPolicyIcon,
    arrowUp,
    arrowDown,
    chevronLeft,
    chevronRight,
    alpsLogoLogin,
    circularLoader,
    searchAwardEurope,
    searchAwardUS,
    bestSoftwareAward,
    searchSoftwareAward,
    multiLayerIcon,
    dropDownArrowWhite,
    forgotLockBg,
    loginPagePwdIcon,
    successIconWhite,
    mainLoginBg,
    loginPageErrCross1,
    alertIcon,
    ssoButton,
    LoaderImg,
    eye
};
