import { AnyAction } from "redux";
import { put, takeEvery, call } from "redux-saga/effects";
import actionTypes, { setUserDetails, setSubscription } from "./actions";
import Apis from "../../app/apis";

// tslint:disable-next-line:typedef
function* fetchUserDetails(action: AnyAction) {
    const userDetails = yield call(Apis.getUserDetails, action.payload);
    if (userDetails.status === 200) {
        yield put(setUserDetails(userDetails.data));
    }
}

// tslint:disable-next-line:typedef
function* fetchSubscriptions(action: AnyAction) {
    const plans = yield call(Apis.getPlansDetails, action.payload);

    if (plans.status === 200) {
        const payload = plans.data.is_subscribed_user ? plans.data : undefined;
        yield put(setSubscription(payload));
    }
}
// tslint:disable-next-line:typedef
export function* watchForUserDetailsRequest() {
    yield takeEvery(actionTypes.GET_USER_DETAILS, fetchUserDetails);
}
// tslint:disable-next-line:typedef
export function* watchForSubscriptionRequest() {
    yield takeEvery(actionTypes.GET_SUBSCRIPTION, fetchSubscriptions);
}
