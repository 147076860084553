import { KeywordsInterface, OptionTypeInterface } from "../../../../../app/styledComponents/drop-down/types";

export interface COPYInterface {
    [key: string]: string;
}

export const ALERT_MULTI_KEYWORD_ALERT_DUPLICATE_KEYWORD = "alert multi_keyword_alert_duplicate_keyword";
export const CONSENT_TO_PROCEED_ON_NEW_SIMULATION_ON_KW_URL_UPDATE = "alert consent_to_proceed_on_new_simulation_on_kw_url_update";

export const MULTI_KEYWORD_SECTION_TYPES = ["Related Keywords", "Project Keywords", "Input Keywords"];

export const MULTIKEYWORDS_MIN_KEYWORD_COUNT = 1;
export const MULTI_KW_ALLOWED_PERCENTAGE_KW_TO_PROGRESS = 75;
export const MULTI_KEYWORD_TRACK_TIME_IN_SEC = 30;
export const MULTI_KEYWORD_MAX_TRACKING_TIME_IN_MIN = 15;
export const TRACK_REQUEST_DELAY = 2 * 1000;
export const COMPETITOR_REQUEST_DELAY = 20 * 1000;

/** Copy content ends */
export const themeSelectedValue = {
    label: "All Themes",
    value: 1
};

export const MULTIKEYWORDS_SECTION_KEYWORDS: KeywordsInterface = {
    projectKeywords: [],
    relatedKeywords: [],
    nonLiveRelatedKeywords: []
};

export const SIMULATOR_ALERT_NEW_RUN_SIMULATION = {
    MESSAGE:
        "Some data from the previous simulation is being processed and may take a few minutes. If you {{condition}}, some of the metrics from the previous simulation will not be available in simulation history.",
    DEFAULT_CONDITION: "simulate right now",
    INITIATE_CONDITION: "update URL/keywords right now",
    REFRESH_CONDITION: "continue with this refresh action",
    SWITCH_VERSION_TEXT: "switch the version right now"
};

export const KEYWORD_SORT_TYPES: OptionTypeInterface[] = [
    { value: "SV_H_TO_L", label: "Search Volume(High-Low)" },
    { value: "SV_L_TO_H", label: "Search Volume(Low-High)" },
    { value: "ASC_A_TO_Z", label: "Ascending (A - Z)" },
    { value: "DES_Z_TO_A", label: "Descending (Z - A)" },
    { value: "RS_H_TO_L", label: "Relevance Score(High-Low)" },
    { value: "RS_L_TO_H", label: "Relevance Score(Low-High)" },
    { value: "RANK_H_TO_L", label: "Rank(High-Low)" },
    { value: "RANK_L_TO_H", label: "Rank(Low-High)" },
    { value: "URL_ASC_A_TO_Z", label: "URL Ascending (A - Z)" },
    { value: "URL_DES_Z_TO_A", label: "URL Descending (Z - A)" }
];

export const MKW_CONST: COPYInterface = {
    KEYWORD: "Keyword",
    MULTI_KEYWORD_WARNING_POPUP_UPDATED_KEYWORDS_MESSAGE: `You are trying to close the window without initiating a simulation. To continue optimizing your page for the target keywords click on 'Go to Simulation'`,
    MULTI_KEYWORD_WARNING_POPUP_NO_KEYWORDS_MESSAGE: `We see you haven’t added any keywords. To optimize your content please add a keyword and click on ‘Go To Simulation’.`,
    FILE_UPPLOAD_NON_HTML_ERROR_TEXT: "Please upload a valid .html file",
    FILE_UPPLOAD_SIZE_ERROR_TEXT: " The file size should be less than 500 KB. ",
    FILE_UPPLOAD_EMPTY_FILE_ERROR_TEXT: "The file is empty. Please upload a valid .html file",
    FILE_UPLOAD_WARNING: " Please upload the file to view the content",
    UPLOAD_TEXT: "Upload",
    FILE_UPPLOAD_ERROR_TEXT: " Please select a file to upload",
    MULTI_KEYWORD_WARNING_POPUP_LEAVE_LABEL: "I Want to Leave",
    MULTI_KEYWORD_WARNING_POPUP_ADD_KEYWORDS_LABEL: "I Want to Add Keywords",
    MULTI_KEYWORD_WARNING_POPUP_GO_TO_SIMULATION_LABEL: "Go to Simulation",
    MULTIKEYWORD_KEYWORD_REQUIRED_ERROR: "At least 1 keyword is mandatory for Optimization.",
    MULTIKEYWORD_NO_KEYWORDS_SELECTED_ERROR: "Select or add keywords to proceed to simulation.",
    MULTI_KEYWORD_ALERT_MAX_KEYWORDS: "Unable to add keyword as the limit is {{MAX}} keyword. Please try again by reducing the keyword(s)",
    PROCEED_LABEL: "Go to Simulation",
    KW_SELECT_URL: "kw_select_url",
    LIVE_JOURNEY_CHANGE: "The journey has been updated as live URL journey",
    NON_LIVE_JOURNEY_CHANGE: "The journey has been updated as Non live URL journey",
    MULTI_KEYWORD_KEYWORD_FETCH_LOADER_MESSAGE: "Keywords Processed",
    CONTENT_BRIEF_SELECTED_KEYWORD_LABEL: "Add/Modify Keywords",
    MULTI_KEYWORD_FETCHING_MORE_KEYWORDS: "Fetching keywords...",
    NON_LIVE_TOPIC_TEXT: "Enter a topic in the above textbox to get keyword suggestions.",
    RELATED_KW_SSE_FAILED_MESSAGE_NON_LIVE: "No keyword suggestions found. Please click on the 'Fetch Keywords' button to try again.",
    FETCH_NON_LIVE_RELATED_KEYWORDS_NOTIFICATION_MESSAGE:
        "As there is change in topic or  locale click on the “Fetch keywords” to get the updated keyword suggestions.",
    MKW_BRANDED: "Branded",
    MKW_NON_BRANDED: "Non-Branded",
    MKW_TARGETED_KEYWORD_LABEL: "Targeted keywords",
    MKW_RANKING_KEYWORD_LABEL: "Ranking keywords",
    MKW_HEADING: "You can assign categories and target URLs to added keywords",
    KEYWORD_SUGGESTIONS: "Keyword Suggestions",
    FILTERS_TEXT: "FILTERS",
    FETCH_KEYWORDS_LABEL: "Fetch Keywords",
    UPLOAD_FILE_TEXT: "Upload File",
    MULTI_KEYWORD_FILE_UPLOAD_PLACEHOLER: "Select a *.html file upto 500KB",
    MULTI_KEYWORD_BODY_INPUT_TITLE: "Add Keyword",
    MULTI_KEYWORD_BODY_INPUT_PLACEHOLDER: "Enter keyword (separated by comma)",
    MULTI_KEYWORD_ALERT_INVALID_INPUT: "Please enter the valid keyword",
    MULTI_KEYWORD_ALERT_DUPLICATE_KEYWORD: "One or more keyword(s) is already present",
    ADD_AT_MAX_x_KEYWORD: "The keyword limit is {{KEYWORD_COUNT}}. Please modify keywords if necessary.",
    ADD_UPTO_x_KEYWORD: "* Add only upto {{KEYWORD_COUNT}} keywords",
    MULTI_KEYWORD_LOCALE_LABEL: "Locale",
    PROTOCOL_OPTIONSID: "protocol",
    ADD_KEYWORD: "Add Keyword",
    ADD_PROJECT: "Add Project",
    REMOVE_KEYWORD: "Remove Keyword",
    SEARCH_URL_ERROR_TEXT_PAGE_OPTIMIZER: "Please enter a valid URL (e.g. https://www.example.com/page1)",
    MULTI_KEYWORD_NOTIFICATION_MESSAGE_POSTFIX: " Any parameter changes that you have made are retained.",
    MULTI_KEYWORD_NOTIFICATION_MESSAGE: "{{COUNT}} keywords have been added to the Simulation with their original scores.",
    MULTI_KEYWORD_TITLE_BUTTON_LABEL: "Proceed to Simulation",
    SIMULATE_WAIT: "Wait",
    SIMULATE_CONTINUE: "Continue",
    ALERT_OK_LABEL: "OK",
    FRAGMENTED_URL_ERROR_TEXT:
        "For the purpose of scoring and simulation, the system considers the URL without the # values when submitting",
    MULTI_KEYWORD_LOCALE_DROPDOWN_PLACEHOLDER: "Enter language/country",
    MULTI_KEYWORD_LOCALE_CHANGE_MESSAGE: " Locale has been updated for the simulation",
    SORT_LABEL: "Sort by",
    SERP_RESULT_META_INFO_CONTENT_TITLE: "Search Volume",
    MULTI_KEYWORD_BODY_URL_TITLE: "URL",
    NO_KEYWORD_FOUND: "No Keywords Found",
    ADD_PROJECT_KEYWORDS_OR_RELATED_KEYWORDS: "Add Project Keywords / Related Keywords",
    SELECT_FROM_PROJECT: "Add/Select a coherent set of keywords that you want to rank for this page *",
    OR: "OR",
    MULTI_KEYWORD_TAGS_TITLE: "Selected Keywords:",
    MULTI_KEYWORD_BODY_INPUT_BUTTON_LABEL: "Remove all",
    MULTI_KEYWORD_BODY_INPUT_SHOW_LESS: "Show less",
    MULTI_KEYWORD_BODY_INPUT_SHOW_ALL: "Show all",
    RELATED_KW_SSE_RETRIED_3_TIMES_MESSAGE: "Unable to fetch keyword suggestions. Please contact alps@iquanti.com",
    RELEVANCE_SCORE_ERROR_TEXT: "Unable to calculate relevance score for some/all keywords.",
    MULTI_KEYWORD_TITLE_TEXT: "Simulation URL - ",
    SEARCH_URL_PLACEHOLDER_TEXT_PAGE_OPTIMIZER: "e.g. https://www.example.com/page1",
    SIMULATOR_NOTIFICATION_WHEN_URL_CHANGED: "As the URL has been updated, the scores might be impacted",
    RELATED_KW_SSE_RETRIED_3_TIMES_MESSAGE_LIVE: "Unable to fetch keywords for this URL. Please enter a topic and try again.",
    RELATED_KEYWORD_SEARCH_VOLUME_MSG:
        "Please ignore any search volume mismatch between Related and Project Keywords tabs as they could be pulled at different times. Latest search volume will be used in the simulation to ensure accurate results.",
    SELECT_KEYWORDS_TYPE_PLACEHOLDER: "Keyword Type",
    CONTENT_SIMULATOR_SEARCH_VOLUME: "Search Volume",
    CONTENT_SIMULATOR_RELEVANCE_SCORE: "Relevance Score",
    MULTI_KEYWORD_LIMIT_LEGEND_TEXT: "Add only upto {{MAX}} keywords",
};
