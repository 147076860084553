import { ApplicationStateInterface, ContextReducerInterface, NotifyErrorInterface } from "../../app/duck/types";

export interface RoutesPropsInterface extends ApplicationStateInterface {
    analytics?: {
        trackEvents(): void;
        trackModalView(): void;
        trackOutbountLink(): void;
    };
    meta: {
        baseUrl: "http://alpsdev3.smallbizvoices.com"
        default_project_id?: string
        hasMultipleProjects: boolean
        hasMultipleTenants: boolean
        has_project?: string
        isMobileProject?: string
        isStandardProject?: string
        locale?: string
        projectID?: string
        projectName?: string
        rootRoute: {
            homeSec: string,
            keyWordAnalysis: string,
            keywordOptimization: string,
            serpResult: string,
            simulator: string,
            techAudit: string,
        },
        searchEngine?: string
        tenantCode?: string
        userEmail?: string
        userID?: string
        routes: {
            activeRouteUrl: string;
            prevRouteUrl: string;
        }
    };
    actions: {
        refreshProjectCache(): void;
    };
    showProjectSetupNavbar: boolean;
    isTableauAuthRetryDone: boolean;
}

export interface RoutesReducerInterface {
    activeRouteUrl: string;
    prevRouteUrl: string;
}

export enum RouteType {
    ROUTE = "ROUTE",
    REDIRECT = "REDIRECT",
}

export interface RoutesInterface {
    id: string;
    from?: string;
    to: string;
    exact?: boolean;
    type: RouteType;
    Component?: JSX.Element;
}

export enum NavLinkType {
    INTERNAL = "INTERNAL",
    EXTERNAL = "EXTERNAL",
    SCRIPT = "SCRIPT",
}

export interface NavListInterface {
    id: string;
    to: string;
    title: string;
    highlightWhen?: string[];
    type: NavLinkType;
    showOnlyForAdvancedProjectType?: boolean;
    showOnlyForMobileType?: boolean;
    isProjectUrl?: boolean;
    isBeta?: boolean;
    isNew?: boolean;
    keywordCount?: number;
    showEditIcon?: boolean;
}

export interface HeaderNavListInterface extends NavListInterface {
    icon: string;
    child?: NavListInterface[];
}

export interface PrimaryNavListChildrenInterface extends NavListInterface {
    description: string;
    icon: SVGImageElement;
    isHidden?: boolean;
    isLocked?: boolean;
    breadcrumbs: NavListInterface[];
    tabs: PrimaryNavListTabsInterface;
}

export interface PrimaryNavListTabsNavLinksInterface extends NavListInterface {
    isHidden?: boolean;
    breadcrumbs: NavListInterface[];
}

export interface PrimaryNavListTabsInterface {
    title: string;
    icon: string;
    navLinks: PrimaryNavListTabsNavLinksInterface[];
}

export interface PrimaryNavListInterface extends NavListInterface {
    isHidden?: boolean;
    isLocked?: boolean;
    isNew?: boolean;
    isProjectUrl?: boolean;
    children: PrimaryNavListChildrenInterface[];
    breadcrumbs: NavListInterface[];
    tabs: PrimaryNavListTabsInterface;
}

export interface GetProjectDetailsPayload extends NotifyErrorInterface {
    projectId: number;
    tenantCode: string;
}

export interface GetTenantLimitsPayload {
    userId: number;
    tenantCode: string;
}
export interface GetSaveSimulationPayloadInterface {
    tenantId: number;
    projectId: number;
    tenantCode: string;
    pageIndex: number;
    searchTerm?: string;
    isBeta?: boolean;
}
export interface GerLinkWithParamsInterface {
    to: string;
    context: ContextReducerInterface;
    isExternal?: boolean;
}
export interface GetSaveHistoryPayloadInterface {
    tenantId: number;
    hasContentOutlineFeatureAccess?: string;
    projectId: number;
    tenantCode: string;
    pageIndex: number;
    searchTerm?: string;
    isBeta?: boolean;
    isLoadingShow?: boolean;
}
export interface GerLinkWithParamsInterface {
    to: string;
    context: ContextReducerInterface;
    isExternal?: boolean;
    isLoadingShow?: boolean;
}
