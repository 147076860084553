import * as React from "react";
import styled from "styled-components";

const StyledClearall = styled.div`
    clear: both;
`;

const Clearall: React.SFC = () => (
        <StyledClearall />
);

export default Clearall;
