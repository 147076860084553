import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import { concat } from "lodash";
import { CategoryType } from "../../../app/duck/types";
import { HIGH_TO_LOW } from "../../../app/const";
import actionTypes from "./actions";
import { KeyWordAnalysisReducerInterface } from "./types";
import { setSelectedUrlType } from "./utils";

const INIT_STATE: KeyWordAnalysisReducerInterface = {
    allTopTenCompatitors: [],
    appliedFilters: [],
    appliedSorting: HIGH_TO_LOW,
    category: undefined,
    compatitor: "",
    compatitorParam: [],
    compatitorScores: [],
    domain: undefined,
    isDownloadRequestInPending: false,
    keyword: "",
    keyword_packs: [],
    locale: "",
    parameterDetailContent: [],
    parameterGapGroupScore: [],
    request_id: "",
    search_volume: undefined,
    selectedCategory: CategoryType.DEFAULT,
    serp: [],
    serp_date: "",
    target: "",
    targetParam: [],
    targetScores: []
};
const Reducer = handleActions(
    {
        [actionTypes.SET_KEYWORD]: (state, action: AnyAction) => ({
            ...state,
            keyword: action.payload
        }),
        [actionTypes.SET_LOCALE]: (state, action: AnyAction) => ({
            ...state,
            locale: action.payload
        }),
        [actionTypes.SET_DOMAIN]: (state, action: AnyAction) => ({
            ...state,
            domain: action.payload
        }),
        [actionTypes.SET_KEYWORD_FETCHED_RESPONSE]: (state, action: AnyAction) => ({
            ...state,
            brand_value: action.payload.is_branded,
            domain: action.payload.data.domain,
            keyword_packs: concat([], action.payload.keyword_packs),
            search_volume: action.payload.search_volume,
            serp: action.payload.data.serp,
            serp_date: action.payload.serp_date,
            themes: action.payload.themes
        }),
        [actionTypes.SET_KEYWORD_SCORE]: (state, action: AnyAction) => ({
            ...state,
            compatitorParam: action.payload.compatitorParam,
            compatitorScores: action.payload.compatitorScores,
            parameterGapGroupScore: action.payload.parameterGapGroupScore,
            targetParam: action.payload.targetParam,
            targetScores: action.payload.targetScores
        }),
        [actionTypes.SET_SELECTED_URL]: (state, action: AnyAction) => ({
            ...state,
            ...setSelectedUrlType(action.payload)
        }),
        [actionTypes.SET_URL]: (state, action: AnyAction) => ({
            ...state,
            ...setSelectedUrlType(action.payload)
        }),
        [actionTypes.SELECT_NEW_CATEGORY]: (state, action: AnyAction) => ({
            ...state,
            selectedCategory: action.payload
        }),
        [actionTypes.SET_DEFAULT_CATEGORY]: (state, action: AnyAction) => ({
            ...state,
            selectedCategory: action.payload
        }),
        [actionTypes.RESET_KWA]: (state, action: AnyAction) => ({
            ...INIT_STATE
        }),
        [actionTypes.SET_APPLIED_FILTERS]: (state, action: AnyAction) => ({
            ...state,
            appliedFilters: action.payload
        }),
        [actionTypes.SET_SORTING_OPTION]: (state, action: AnyAction) => ({
            ...state,
            appliedSorting: action.payload
        }),
        [actionTypes.SET_TOP_ORGANIC_URL]: (state, action: AnyAction) => ({
            ...state,
            allTopTenCompatitors: action.payload
        }),
        [actionTypes.SET_DOWNLOAD_REQUEST_PENDING_STATUS]: (state, action: AnyAction) => ({
            ...state,
            isDownloadRequestInPending: action.payload
        }),
        [actionTypes.GET_PARAMETERDETAIL]: (state, action: AnyAction) => ({
            ...state,
            isLoading: true
        }),

        [actionTypes.SET_PARAMETERDETAIL]: (state, action: AnyAction) => ({
            ...state,
            isLoading: false,
            parameterDetailContent: action.payload.content
        }),
        [actionTypes.GET_URL_SCORE]: (state, action: AnyAction) => ({
            ...state,
            isLoading: true
        }),
        [actionTypes.SET_URL_SCORE]: (state, action: AnyAction) => ({
            ...state,
            compatitorParam: action.payload.compatitorParam,
            compatitorScores: action.payload.compatitorScores,
            parameterGapGroupScore: action.payload.parameterGapGroupScore,
            targetParam: action.payload.targetParam,
            targetScores: action.payload.targetScores
        })
    },
    INIT_STATE
);

export default Reducer;
