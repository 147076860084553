export interface GenerateContentOutlinePayloadInterface {
    keywords: string[];
    user_prompt: string;
}

export interface KeywordSearchBoxReducerInterface {
    locales: LocaleInterface[];
}

export interface LocaleInterface {
    code: string;
    country: string;
    language: string;
    name: string;
}

export enum ContentOutlineAPIStatus {
    STARTED = "started",
    SUCCESS = "success",
    FAILED = "failed"
}
