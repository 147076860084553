export const BASE_URL_WITHOUT_TRAILING_SLASH = `${process.env.ROOT_API_URL}`;
export const BASE_URL = process.env.ROOT_BASE_URL; // for wiring api with real backend
export const TECH_AUDIT_API_VERSION = process.env.TECH_AUDIT_API_VERSION; // eslint-disable-line
// export const KEYWORD_OPTIMIZATION_API_BASE_URL= process.env.KEYWORD_OPTIMIZATION_BASE_URL;
export const KEYWORD_OPTIMIZATION_API_BASE_URL= `${process.env.ROOT_API_URL}/ko-api`;
export const KEYWORD_OPTIMIZATION_COMPARE_CATEGORIES = ["content", "technical", "authority"];
export const LEGEND_NON_HEALTHY_COLOR = '#fb764b';
export const LEGEND_HEALTHY_COLOR = '#53d68a';
export const LEGEND_CRITICAL_COLOR = '#fb764b';
export const LEGEND_WARNING_COLOR = '#fec830';
export const LEGEND_MYURL_COLOR = '#3a85ef';
export const LEGEND_COMPETITOR_COLOR = '#102542';
export const LEGEND_AVERAGE_COLOR = '#ffbc42';
export const BORDER_COLOR = '#f6f6f6';
export const PANEL_BORDER_COLOR = '#dfdfdf';
export const MENU_HOVER_BACKGROUND = '#f6f6f6';
export const BODY_BACKGROUND_COLOR = '#f3f3f3';
export const DARK_GREY = '#898989';
export const LIGHT_GREY = '#e2e2e2';
export const LIGHT_GREY_ALTERNATE = '#efefef';
export const LIGHT_GREY_FAFAFA = '#fafafa';
export const LIGHT_GREY_F4F4F4 = '#f4f4f4';
export const LIGHT_GREY_F8F8F8 = '#f8f8f8';
export const LIGHT_GREY_CBCBCB = '#cbcbcb';
export const LIGHT_GREY_A49FA4 = '#a49fa4';
export const LIGHT_GREY_F9F9F9 = '#f9f9f9';
export const LIGHT_GREY_D7D7D7 = '#d7d7d7';
export const LIGHT_GREY_EBEBEB = '#ebebeb';
export const LIGHT_GREY_E1E1E1 = '#e1e1e1';
export const SHADES_PURPLE_4D2539 = '#4d2539';
export const SHADES_PURPLE_806B7C = '#806b7c';
export const SHADES_PURPLE_9D8398 = '#9d8398';
export const SHADES_PURPLE_B25685 = '#b25685';
export const SHADES_PURPLE_E9C3E1 = '#e9c3e1';
export const SHADES_PURPLE_AD8BD4 = '#ad8bd4';
export const SUBTLE_GREY = '#F6F6F6';
export const THEME_COLOR = '#00acce';
export const SHADES_BLUE_CAECF3 = '#caecf3';
export const BLACK = '#3d3d3d';
export const ELEVEVATED_BACKGROUND_COLOR = '#fff';
export const LIGHT_THEME_COLOR = '#ebf5f759';
export const DOWNLOADER_NEUTRAL_BACKGROUND = 'transparent';
export const DOWNLOADER_DOWNLOADING_BORDER_COLOR = '#00acce';
export const DOWNLOADER_DOWNLOADING_ICON_COLOR = '#00acce';
export const DOWNLOADER_SUCCESS_COLOR = 'seagreen';
export const DOWNLOADER_FAIL_COLOR = 'indianred';
export const HIGHCHART_COLOR_COMBINTION = ['#4d5361', '#645c95', '#9e94c8', '#a5bad9', '#949fb1', '#9187d9'];
export const HIGHCHART_DATALABEL_COLOR = '#4d5361';
export const PURE_BLACK = '#000000';
export const PURE_WHITE = '#ffffff';
export const POPUP_OVERLAY_OPACITY = 'rgba(0,0,0,0.5)';
export const LINE_HIGHCHART_COLOR_COMBINTION = ['#00ADCE', '#FF5B3F', '#00E993'];
export const LINE_HIGHCHART_COLOR_COMBINTION_FOR_ISSUE = ['#00ADCE', '#FF5B3F', '#fec830'];
export const WIDTH_30_PER = '30%';
export const WIDTH_40_EM = '40em';
export const DISPLAY_BLOCK = 'inline-block';
export const DARK_BLACK = '#343434';
export const ANALYTICS = {
    CATEGORY_USER: "User",
    CATEGORY_RESULT_LOG: "Result Log",
    ACTION_SELECT_DATE: 'Selected New Date',
    ACTION_SELECT_DOMAIN: 'Selected New Domain',
    ACTION_TOGGLE_HIGH_TRAFFIC: 'Opened/Closed High Traffic',
    ACTION_DOWNLOAD_REPORT: 'Requested to Download Report',
    STATUS_DOWNLOAD_DELAYED: 'Download link sent to email',
    STATUS_DOWNLOAD_SUCCESS: 'Download was Successful',
    STATUS_DOWNLOAD_FAILED: 'Download was Failed',
    MODAL_VIEW_CRAWLED_HISTORY: 'Viewed Crawled History',
    MODAL_VIEW_ISSUED_HISTORY: 'Viewed Issued History'
};
export const NUMBER_FORMAT_DECIMALS = 1;
