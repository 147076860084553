import { createAction } from "redux-actions";

const actionTypes = {
    GET_PROJECTS_LIST: "GET_PROJECTS_LIST",
    SET_PROJECTS_LIST: "SET_PROJECTS_LIST",
    SET_FILTER_PROJECT_FOR_LOCALE_LIST: "SET_FILTER_PROJECT_FOR_LOCALE_LIST",
    GET_TARGET_DOMAIN_FILTERS_LIST: "GET_TARGET_DOMAIN_FILTERS_LIST",
    SET_TARGET_DOMAIN_FILTERS_LIST: "SET_TARGET_DOMAIN_FILTERS_LIST",
    GET_PROJECTS_LIST_FOR_GSC_ACCOUNT_CHECK: "GET_PROJECTS_LIST_FOR_GSC_ACCOUNT_CHECK",
    SET_IS_PROJECT_DOMAINS_PRESENT: "SET_IS_PROJECT_DOMAINS_PRESENT",
    GET_REPORT_TOKEN: "GET_REPORT_TOKEN",
    SET_LOADER: "SET_LOADER",
    UPDATE_FILTER: "UPDATE_FILTER",
    SET_FILTER_CONFIG: "SET_FILTER_CONFIG",
    SET_EMBED_CONFIG: "SET_EMBED_CONFIG",
    SET_REPORT_CODE: "SET_REPORT_CODE",
    SET_SECTION_CODE: "SET_SECTION_CODE",
    CLEAR_COMPETITIVE_INTELLIGENCE: "CLEAR_COMPETITIVE_INTELLIGENCE",
    GET_PRT_DEAFULT_PROJECT_CATEGORY: "GET_PRT_DEAFULT_PROJECT_CATEGORY",
    GET_PRT_DEAFULT_PROJECT_TA_DOMAIN: "GET_PRT_DEAFULT_PROJECT_TA_DOMAIN",
    GET_PRT_SOV_TARGET_COMPETITOR_DOMAINS: "GET_PRT_SOV_TARGET_COMPETITOR_DOMAINS",
    SET_PRT_DEAFULT_PROJECT_CATEGORY: "SET_PRT_DEAFULT_PROJECT_CATEGORY",
    SET_PRT_DEAFULT_PROJECT_TA_DOMAIN: "SET_PRT_DEAFULT_PROJECT_TA_DOMAIN",
    SET_PRT_SOV_TARGET_COMPETITOR_DOMAINS: "SET_PRT_SOV_TARGET_COMPETITOR_DOMAINS",
    RESET_DEFAULT_SLICERS: "RESET_DEFAULT_SLICERS",
    SET_GSC_ACCOUNT_CONFIGURED: "SET_GSC_ACCOUNT_CONFIGURED",
    SET_FETCH_REPORT_TOKEN_RETRIED_ATTEMPT: "SET_FETCH_REPORT_TOKEN_RETRIED_ATTEMPT",
    RESET_FETCH_REPORT_TOKEN_RETRIED_ATTEMPT: "RESET_FETCH_REPORT_TOKEN_RETRIED_ATTEMPT",
    SET_IS_PROJECTS_ARRAY_EMPTY: "SET_IS_PROJECTS_ARRAY_EMPTY",
    REFRESH_PROJECT_CACHE: "REFRESH_PROJECT_CACHE"
};
export default actionTypes;

export const getProjectsList = createAction(actionTypes.GET_PROJECTS_LIST);
export const setProjectsList = createAction(actionTypes.SET_PROJECTS_LIST);
export const getTargetDomainFiltersList = createAction(actionTypes.GET_TARGET_DOMAIN_FILTERS_LIST);
export const setTargetDomainFiltersList = createAction(actionTypes.SET_TARGET_DOMAIN_FILTERS_LIST);
export const getProjectsForGSCAccountCheck = createAction(actionTypes.GET_PROJECTS_LIST_FOR_GSC_ACCOUNT_CHECK);
export const setIsProjectDomainsPresent = createAction(actionTypes.SET_IS_PROJECT_DOMAINS_PRESENT);
export const getReportToken = createAction(actionTypes.GET_REPORT_TOKEN);
export const setLoader = createAction(actionTypes.SET_LOADER);
export const updateFilter = createAction(actionTypes.UPDATE_FILTER);
export const setEmbedConfig = createAction(actionTypes.SET_EMBED_CONFIG);
export const setFilterConfig = createAction(actionTypes.SET_FILTER_CONFIG);
export const setReportCode = createAction(actionTypes.SET_REPORT_CODE);
export const setSectionCode = createAction(actionTypes.SET_SECTION_CODE);
export const clearCompetitiveIntelligence = createAction(actionTypes.CLEAR_COMPETITIVE_INTELLIGENCE);
export const getDefaultProjectTADomain = createAction(actionTypes.GET_PRT_DEAFULT_PROJECT_TA_DOMAIN);
export const getSOVTargetCompetitorDomains = createAction(actionTypes.GET_PRT_SOV_TARGET_COMPETITOR_DOMAINS);
export const getDefaultProjectCategory = createAction(actionTypes.GET_PRT_DEAFULT_PROJECT_CATEGORY);
export const setDefaultProjectTADomain = createAction(actionTypes.SET_PRT_DEAFULT_PROJECT_TA_DOMAIN);
export const setSOVTargetCompetitorDomains = createAction(actionTypes.SET_PRT_SOV_TARGET_COMPETITOR_DOMAINS);
export const setDefaultProjectCategory = createAction(actionTypes.SET_PRT_DEAFULT_PROJECT_CATEGORY);
export const resetDefaultSlicers = createAction(actionTypes.RESET_DEFAULT_SLICERS);
export const setIsGSCAccountConfigured = createAction(actionTypes.SET_GSC_ACCOUNT_CONFIGURED);
export const setFetchReportTokenRetriedAttempt = createAction(actionTypes.SET_FETCH_REPORT_TOKEN_RETRIED_ATTEMPT);
export const resetFetchReportTokenRetriedAttempt = createAction(actionTypes.RESET_FETCH_REPORT_TOKEN_RETRIED_ATTEMPT);
export const setFilterProjectLocaleList = createAction(actionTypes.SET_FILTER_PROJECT_FOR_LOCALE_LIST);
export const setIsProjectsArrayEmpty = createAction(actionTypes.SET_IS_PROJECTS_ARRAY_EMPTY);
export const refreshProjectCache = createAction(actionTypes.REFRESH_PROJECT_CACHE);
