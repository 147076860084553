import { ApiConfig } from "../../../../app/config/apiConfig";
import { ApiPath, METHOD } from "../../../../app/config/apiPath";
import { ParserInterface } from "../../../../app/duck/types";
import { getAxiosParam } from "../../../../app/duck/utils";
import { SidPayloadInterface } from "../../../../beta/simulator/ducks/types";
import ServiceRequest from "../../../../app/services";

class Apis {
    public *getAuthTechParams(
        args: SidPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            params: {
                sid: args.sid
            },
            url: (args.isBeta
                ? ApiPath.SAVE_SIMULATION_AUTHORITY_TECHNICAL_PARAMETER_BETA
                : ApiPath.SAVE_SIMULATION_AUTHORITY_TECHNICAL_PARAMETER
            ).replace("{{tenantCode}}", parser.tenantCode)
        };
        const response = yield ServiceRequest.invoke(getAxiosParam(axiosParam, args));
        return response;
    }
}
export default new Apis();
