import React, { useState } from "react";
import {
    orangeLine,
    orangePlus,
    chipReverse,
    freshWorks,
    humana,
    gmt,
    mimeCast,
    lightArrowLeft,
    darkArrowRight,
    darkArrowLeft,
    lightArrowRight
} from "../../../../app/icons";
import { FAQ_LEFT_PANEL, FAQ_RIGHT_PANEL, TESTIMONIAL, UPDATENOW } from "../const";
import {
    StyledAnswer,
    StyledCarouselArrowLeft,
    StyledCarouselArrowRight,
    StyledCarouselFooter,
    StyledCarouselImgContainer,
    StyledCarouselText,
    StyledCarouselWrapper,
    StyledDropDownImg,
    StyledFAQBox,
    StyledFAQHeading,
    StyledFAQPanel,
    StyledFooterText,
    StyledFooterWrapper,
    StyledImg1,
    StyledImg2,
    StyledImg3,
    StyledImg4,
    StyledImg5,
    StyledImgWrapper,
    StyledQuestion,
    StyledSeperator,
    StyledTestimonialHeader,
    StyledTestimonialWrapper,
    StyledUpgradeModalHeader
} from "../StyledSimulator";

export interface DisplayFAQInterface {
    question: string;
    answer: string;
    index: number;
    expandFAQ: number;
    handleClick(id: number): void;
}

export interface DisplayCarouselInterface {
    company: string;
    image_src: string;
    description: string;
    footer: string;
}

export interface UpgradeCarouselInterface {
    item: DisplayCarouselInterface;
}

export const DisplayFAQ: React.FC<DisplayFAQInterface> = ({ question, answer, expandFAQ, index, handleClick }) => {
    return (
        <>
            <StyledFAQBox id={`FAQ-box-${index}`}>
                <StyledUpgradeModalHeader
                    onClick={() => {
                        handleClick(expandFAQ === index ? -1 : index);
                    }}
                >
                    <StyledQuestion id={`question-${index}`}>{question}</StyledQuestion>
                    <StyledDropDownImg
                        id={expandFAQ === index ? `expand-box-${index}` : `collapse-box-${index}`}
                        src={expandFAQ === index ? orangeLine : orangePlus}
                    />
                </StyledUpgradeModalHeader>
                {expandFAQ === index && (
                    <>
                        <StyledSeperator />
                        <StyledAnswer id={`answer-${index}`}>{answer}</StyledAnswer>
                    </>
                )}
            </StyledFAQBox>
        </>
    );
};

export const UpgradeCarousel: React.FC<UpgradeCarouselInterface> = ({ item }) => {
    return (
        <>
            <StyledCarouselImgContainer>
                <img src={item.image_src} />
            </StyledCarouselImgContainer>
            <StyledCarouselText>{item.description}
                <StyledCarouselFooter>{item.footer}</StyledCarouselFooter>
            </StyledCarouselText>
        </>
    );
};

const UpgradeRightPanel: React.FC<{}> = ({ }) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [expandFAQ, setExpandFAQ] = useState<number>(-1);
    const handleClick = (id: number) => {
        setExpandFAQ(id);
    };
    const onArrowClickHandler = (index: number) => {
        const newIndex = index === 0 ? 1 : 0;
        setActiveIndex(newIndex);
    };
    return (
        <>
            <StyledFAQHeading id="FAQ">{UPDATENOW.FAQ}</StyledFAQHeading>
            <StyledUpgradeModalHeader id="right-header">
                <StyledFAQPanel id="FAQ-box-left">
                    {FAQ_LEFT_PANEL.map((item, index) => {
                        return (
                            <DisplayFAQ
                                key={index}
                                question={item.question}
                                answer={item.answer}
                                expandFAQ={expandFAQ}
                                index={index}
                                handleClick={handleClick}
                            />
                        );
                    })}
                </StyledFAQPanel>
                <StyledFAQPanel id="FAQ-box-right">
                    {FAQ_RIGHT_PANEL.map((item, index) => {
                        return (
                            <DisplayFAQ
                                key={index}
                                question={item.question}
                                answer={item.answer}
                                expandFAQ={expandFAQ}
                                index={index + 4}
                                handleClick={handleClick}
                            />
                        );
                    })}
                </StyledFAQPanel>
            </StyledUpgradeModalHeader>
            <StyledFooterWrapper id="right-footer">
                <StyledFooterText id="right-footer-heading">{UPDATENOW.TRUSTED_BY_WORL_LEADING_BRAND}</StyledFooterText>
                <StyledImgWrapper>
                    <StyledImg1 id="freahworks-img" src={freshWorks} />
                    <StyledImg2 id="mimecast-img" src={mimeCast} />
                    <StyledImg3 id="humana-img" src={humana} />
                </StyledImgWrapper>
                <StyledImgWrapper>
                    <StyledImg4 id="gmt-img" src={gmt} />
                    <StyledImg5 id="chipreverse-img" src={chipReverse} />
                </StyledImgWrapper>
            </StyledFooterWrapper>
            <StyledTestimonialWrapper>
                <StyledTestimonialHeader>{UPDATENOW.OUR_CLIENT_LOVE_ALPS}</StyledTestimonialHeader>
                <StyledCarouselWrapper>
                    {activeIndex === 0 ? (
                        <StyledCarouselArrowLeft disable={true} src={lightArrowLeft} />
                    ) : (
                        <StyledCarouselArrowLeft disable={false} src={darkArrowLeft} onClick={() => onArrowClickHandler(1)} />
                    )}
                    <div>
                        <UpgradeCarousel item={TESTIMONIAL[activeIndex]} />
                    </div>
                    {activeIndex === 0 ? (
                        <StyledCarouselArrowRight disable={false} src={darkArrowRight} onClick={() => onArrowClickHandler(0)} />
                    ) : (
                        <StyledCarouselArrowRight disable={true} src={lightArrowRight} />
                    )}
                </StyledCarouselWrapper>
            </StyledTestimonialWrapper>
        </>
    );
};

export default UpgradeRightPanel;
