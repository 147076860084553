import * as React from "react";
import styled, { css } from "styled-components";
import { error, searchIcon } from "../icons";
import { StyledComponentStylesType, FlowTypeInterface } from "../duck/types";

export const StyledWrapper = styled.div`
    width: auto;
`;
export const StyledInput = styled.input`
    border: 1px solid #afafaf;
    padding: 8px 10px;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 400;
    color: #343434;
    border-radius: 3px;
    &:focus {
        border: 1px solid #00acce;
    }
    &:disabled {
        border: 1px solid #afafaf;
        background: #efefef;
        color: #afafaf;
    }
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    ::placeholder {
        color: #838793;
    }
    ${(props: InputPropsInterface) =>
        props.reference === "category_search_box"
            ? css`
                            width:240px;
                            font-size: 11px;
                            padding: 7px 10px;
                            padding-right: 24px;
                            background: url(${searchIcon}) no-repeat;
                            background-position-x: 98%;
                            background-position-y: 46%;
                            background-color: white;
                            }
                        `
            : css``}
    ${(props: InputPropsInterface) =>
        props.reference === "target_url_filter_search_box"
            ? css`
                            width:100%;
                            font-size: 11px;
                            padding: 7px 10px;
                            padding-right: 24px;
                            background-position-x: 98%;
                            background-position-y: 46%;
                            background-color: white;
                            }
                        `
            : css``}
`;

export const StyledErrorWrapper = styled.div`
    margin-top: ${(props: InputStylesPropsInterface) => props.flowType === FlowTypeInterface.PAGE_OPTIMIZER ? "" : "5px"};
    width: ${(props: InputStylesPropsInterface) => props.flowType === FlowTypeInterface.PAGE_OPTIMIZER ? "auto" : "400px"};
    margin-left: ${(props: InputStylesPropsInterface) => props.flowType === FlowTypeInterface.PAGE_CONTENTBRIEF && "20px"};
`;

export const StyledTextLabelWrapper = styled.div`
    margin-top: 5px;
    width: 400px;
`;

export const StyledErrorIcon = styled.i`
    background: url(${error}) no-repeat;
    float: left;
    width: 15px;
    height: 15px;
`;

export const StyledErrorText = styled.div`
    color: #fb764b;
    font-size: 12px;
    font-weight: 500;
    float: none;
    margin: 2px 0 0 5px;
    padding-top: ${(props: InputStylesPropsInterface) => props.flowType === FlowTypeInterface.PAGE_OPTIMIZER ? "" : "2px"};
    padding-left: 15px;
    &.${FlowTypeInterface.PAGE_OPTIMIZER}{
        color: #343434;
        padding-left: 20px;
        margin: 0px;
        font-weight: normal;
        line-height: 1.33;
        opacity: 0.9;
        font-family: Lato;
    }
`;

export const StyledTextLabel = styled.div`
    color: #838793;
    height: 12px;
    margin: 0 0 0 3px;
    font-family: Lato;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
`;

interface InputStylesPropsInterface {
    Wrapper?: StyledComponentStylesType;
    Input?: StyledComponentStylesType;
    ErrorWrapper?: StyledComponentStylesType;
    TextLabelWrapper?: StyledComponentStylesType;
    ErrorIcon?: StyledComponentStylesType;
    ErrorText?: StyledComponentStylesType;
    TextLabel?: StyledComponentStylesType;
    flowType?: StyledComponentStylesType;
}

interface InputPropsInterface {
    name?: string;
    type?: string;
    placeholder?: string;
    value?: string | number;
    errorText?: string;
    textLabel?: string;
    flowType?: FlowTypeInterface;
    disabled?: boolean;
    styles?: InputStylesPropsInterface;
    reference?: string;
    instanceId: string;
    min?: number;
    max?: number;
    highlightError?: boolean;
    onChangeHandler?(e: React.ChangeEvent<HTMLInputElement>): void;
    onFocus?(e: React.FocusEvent<HTMLInputElement>): void;
    onKeyDownHandler?(e: React.KeyboardEvent<HTMLInputElement>): void;
    onBlurHandler?(e: React.FocusEvent<HTMLInputElement>): void;
    onPasteHandler?(e: React.ClipboardEvent<HTMLInputElement>): void;
}

const InputComponent: React.SFC<InputPropsInterface> = ({
    name = "",
    type = "text",
    placeholder = "",
    value = "",
    min = 0,
    disabled = false,
    reference,
    onChangeHandler,
    onFocus,
    onKeyDownHandler,
    onBlurHandler,
    onPasteHandler,
    errorText = "",
    textLabel = "",
    styles = {},
    highlightError = false,
    instanceId = "",
    flowType
}) => {
    const {
        Wrapper = StyledWrapper,
        Input = StyledInput,
        ErrorWrapper = StyledErrorWrapper,
        TextLabelWrapper = StyledTextLabelWrapper,
        ErrorIcon = StyledErrorIcon,
        ErrorText = StyledErrorText,
        TextLabel = StyledTextLabel
    } = styles;
    return (
        <Wrapper className={`${instanceId}_container`}>
            <Input
                type={type}
                placeholder={placeholder}
                name={name}
                value={value}
                disabled={disabled}
                reference={reference}
                autoComplete="off"
                min={min}
                highlightError={highlightError}
                {...{
                    ...(!disabled && onChangeHandler && { onChange: onChangeHandler }),
                    ...(!disabled && onFocus && { onFocus }),
                    ...(!disabled && onKeyDownHandler && { onKeyDown: onKeyDownHandler }),
                    ...(!disabled && onBlurHandler && { onBlur: onBlurHandler }),
                    ...(!disabled && onPasteHandler && { onPaste: onPasteHandler }),

                    ...(instanceId && { id: instanceId })
                }}
            />
            {errorText.length > 0 && (
                <ErrorWrapper flowType={flowType} id={`${instanceId}_inputError`}>
                    <ErrorIcon />
                    <ErrorText>{errorText}</ErrorText>
                </ErrorWrapper>
            )}
            {textLabel.length > 0 && errorText.length === 0 && (
                <TextLabelWrapper id={`${instanceId}-text-label`}>
                    <TextLabel>{textLabel}</TextLabel>
                </TextLabelWrapper>
            )}
        </Wrapper>
    );
};

export default InputComponent;
