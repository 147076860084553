import { createAction } from "redux-actions";

const actionTypes = {
    GET_TABLEAU_EMBED_TOKEN: "GET_TABLEAU_EMBED_TOKEN",
    SET_TABLEAU_EMBED_CONFIG: "SET_TABLEAU_EMBED_CONFIG",
    RESET_TABLEAU_EMBED_CONFIG: "RESET_TABLEAU_EMBED_CONFIG",
    SET_IS_EMBED_TOKEN_FETCH_SUCCESS: "SET_IS_EMBED_TOKEN_FETCH_SUCCESS",
};
export default actionTypes;

export const getTableauEmbedToken = createAction(actionTypes.GET_TABLEAU_EMBED_TOKEN);
export const setTableauEmbedConfig = createAction(actionTypes.SET_TABLEAU_EMBED_CONFIG);
export const resetTableauEmbedConfig = createAction(actionTypes.RESET_TABLEAU_EMBED_CONFIG);
export const setIsEmbedTokenFetchSuccess = createAction(actionTypes.SET_IS_EMBED_TOKEN_FETCH_SUCCESS);
