import styled, { css } from "styled-components";
import { find, isUndefined } from "lodash";
import {
    expandIcon,
    collapseIcon,
    downarrowfill,
    infoinvert,
    warning,
    arrowPointedRight,
    arrowPointedLeft,
    downloadButton,
    downloadDisabledButton,
    editorViewToggleIcon,
    refreshBorderIcon,
    StyledEditIcon,
    StyledEditIconBlue,
    searchIcon,
    toggleDisable,
    disableZoomMode,
    utilityCloseIcon,
    handIcon,
    downloadHoverButton
} from "../../app/icons";
import { StyledPrimaryButton, StyledLabel, StyledInvertButton, StyledLinkButton, StyledButton } from "../../app/styledComponents/button";
import { StyledBody, StyledClose, StyledHead, StyledModal } from "../../app/styledComponents/modal";
import { ImportanceLevelType, ImportanceColorSchemeInterface, CategoryType } from "../../app/duck/types";
import { IMPORTANCE_COLOR_SCHEME, IS_DEV_SRC } from "../../app/const";
import { StyledLegend, StyledLegendWrapper } from "../../app/styledComponents/legends";
import { StyledWrapper } from "../../app/styledComponents/input";
import { PositiveNegativeIdealDeltaValue, StyledSimulatorMultipleDropdown } from "./ducks/types";
import { StyledCheckbox } from "../../app/styledComponents/checkbox";
import { SimulatorInfoDescriptionModalPropsInterface } from "./CommonComponent/VersionSelect/SaveSimulationDescriptionModal";

// tslint:disable: no-var-requires
const correlationImg = IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/bulb-new.svg` : require(`${process.env.DEV_IMAGE_URL}/img/bulb-new.svg`);
export const StyledEditorTitlePrimaryButton = styled(StyledPrimaryButton)`
    margin-left: 10px;
    height: 30px;
`;

export const StyledContentLoader = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`;

export const StyledLoader = styled.div`
    margin-top: -74px;
`;

export const StyledEditorTitleExpandButton = styled(StyledPrimaryButton)`
    float: left;
    padding: 0;
    width: 28px;
    height: 28px;
    background-image: url(${expandIcon});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transform: ${(props: { disabled: boolean }) => (props.disabled ? "#afafaf" : "#02acce")};
    &:hover {
        background-image: url(${expandIcon});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
    }
`;

export const StyledEditorTitleExpandButtonDisable = styled(StyledEditorTitleExpandButton)`
    background-image: url(${disableZoomMode});
    &:hover {
        background-image: url(${disableZoomMode});
    }
`;

export const StyledEditorFullViewButton = styled(StyledPrimaryButton)`
    float: left;
    padding: 0;
    background-image: url("${editorViewToggleIcon}");
    background-repeat: no-repeat;
    width: 25px;
    height: 14px;
    background-size: 25px;
    background-position: center center;
    margin-top: 3px;
    background-color: transparent;
    border: 1px solid transparent;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    &:hover {
        background-color: transparent;
        border: 1px solid transparent;
    };
    &:active {
        background-color: transparent;
        border: 1px solid transparent;
    };
`;

export const StyledEditorTagViewButton = styled(StyledPrimaryButton)`
    float: left;
    padding: 0;
    background-image: url("${editorViewToggleIcon}");
    background-repeat: no-repeat;
    width: 25px;
    height: 14px;
    background-size: 25px;
    background-position: center center;
    margin-top: 3px;
    background-color: transparent;
    border: 1px solid transparent;
    &:hover {
        background-color: transparent;
        border: 1px solid transparent;
    };
    &:active {
        background-color: transparent;
        border: 1px solid transparent;
    };
`;

export const StyledDisableEditorTagViewButton = styled(StyledEditorTagViewButton)`
    background-image: url("${toggleDisable}");
    &:disabled {
        background-color: transparent;
        cursor: default;
      }
`;

export const StyledEditorTitleExpandButtonLabel = styled(StyledLabel)`
    display: none;
`;

export const StyledEditorZoomActionPrimaryButton = styled(StyledPrimaryButton)`
    float: left;
    margin-left: 10px;
    margin-top: 0px;
`;

export const StyledEditorZoomActionExpandButton = styled(StyledPrimaryButton)`
    float: left;
    padding: 0;
    width: 28px;
    height: 28px;
    background-image: url(${collapseIcon});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    &:hover {
        background-image: url(${collapseIcon});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
    }
`;

export const StyledEditorZoomActionExpandButtonLabel = styled(StyledLabel)`
    display: none;
`;

export const StyledDetailedZoomButton = styled(StyledInvertButton)`
    position: relative;
    padding: 0;
    height: 30px;
    width: 190px;
    box-sizing: border-box;
    display: block;
    float: right;
    margin-right: 15px;
    margin-top: 10px;
    background: transparent;
    border: none;
    &:hover {
        border: none;
    }
    &::after {
        content: " ";
        position: absolute;
        height: 1px;
        background: #00acce;
        left: 29px;
        width: 125px;
        bottom: 9px;
    }
`;

export const StyledDetailedZoomButtonLabel = styled(StyledLabel)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 27px;
    font-weight: 500;
    &:after {
        content: "";
        position: absolute;
        width: 28px;
        height: 28px;
        background-image: url(${expandIcon});
        background-size: 28px;
        background-position: center center;

        top: 0;
        right: 0;
    }
    &:hover:after {
    }
`;

export const StyledSimulatePerformance = styled.div`
    display: flex;
    flex-wrap: nowrap;
    height: 57px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
    background-color: #fff;
    margin: 10px 48px;
`;

export const StyledSimulatePerformanceBody = styled.div`
    padding: 20px;
    width: 25%;
`;

export const StyledSimulatePerformanceTitleButton = styled(StyledInvertButton)`
    float: right;
    height: 30px;
    margin: -6px 0px;
`;
export const StyledSimulatePerformanceText = styled.span`
    font-size: 14px;
    color: #343434;
`;
export const StyledSimulatePerformanceTraffic = styled.span`
    margin-right: 30px;
    float: left;
    font-size: 12px;
    color: #afafaf;
    margin-top: 2px;
`;
export const StyledSimulatePerformanceAsTraffic = styled.span`
    font-size: 16px;
    font-weight: bold;
    float: left;
`;
export const StyledSimulatePerformanceDeltaTraffic = styled.span`
    font-size: 12px;
    float: left;
    margin-left: 10px;
    margin-top: 3px;
`;

export const StyledSimulatePerformanceScore = styled.div`
    float: left;
    width: 35%;
    margin-top: 14px;
`;

export const StyledSimulatePerformanceTitleButtonInZoom = styled(StyledInvertButton)`
    float: right;
    height: 30px;
    margin-top: -1px;
`;

export const StyledNoDataIcon = styled.div`
    margin-top: 2px;
`;

export const StyledRightPannel = styled.div`
    width: 400px;
    width: calc(100% - 342px);
    float: left;
    margin-left: 20px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
    padding-bottom: 10px;
    box-sizing: border-box;
    margin-top: 10px;
`;

export const StyledSubCategoryParametersBody = styled.div`
    padding: 0px;
    background: #f4f3f3;
    border-bottom: 1px solid rgba(175, 175, 175, 0.5);
    &:nth-of-type(even) {
        background: #f4f3f3;
    }
`;

export const StyledButtonContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledRightPannelHeader = styled.section``;

export const StyledUpperSubheader = styled.div`
    height: 50px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-radius: 4px 4px 0 0;
`;

export const StyledSubheaderLabel = styled.span`
    font-size: 14px;
    color: #343434;
    text-transform: uppercase;
`;

export const StyledSecondaryHeader = styled.div`
    height: 50px;
    background: #f6f6f6;
    display: none;
`;


export const StyledTextInforContainer = styled.div`
    display: flex;
    width: auto;
    max-width: calc(100%-35px);
    align-items: center;
    margin-right: 10px;
    ${"#description_label_tooltip"} {
        position: relative;
        max-width: ${(props: { version?: string }) => (!isUndefined(props.version) ? "calc(100%-150px);" : "calc(100%-90px);")};
        display: block;
        white-space: nowrap;
        ${"> span"} {
            width: 100%;
        }
    }
`;
export const StyledLowerRowContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledDropdown = styled.div`
    height: 30px;
    box-sizing: border-box;
    border-radius: 3px;
    align-items: center;
    & #recommendation-box-editor {
        margin-top: 0px;
    }
    & #recommendation-box-browser {
        margin-top: 0px;
    }
`;

export const StyledEditIconModalContainer = styled.div`
    position: relative;
`;

export const StyledEditIconContainer = styled.i`
    cursor: pointer;
    width: 16px;
    height: 16px;
    background-image: url(${(props: { isActive: boolean }) => (props.isActive ? StyledEditIconBlue : StyledEditIcon)});
    background-size: 16px;
    background-position: center center;
    display: flex;
`;

export const StyledVersionTimelineContainer = styled.span`
    font-size: 12px;
    color: #343434;
    font-weight: 600;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 5px;
    flex-shrink: 0;
`;

export const StyledSimulatorDescription = styled.span`
    font-size: 12px;
    color: #343434;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    line-height: 14px;
    min-width: 15px;
`;

export const StyledMissingHeaderInfoContainer = styled.div`
    display: flex;
    margin-left: 95px;
    padding-right: 10px;
`;

export const StyledActionItemContainer = styled.div`
    float: left;
    margin: 11px 0 0 20px;
`;

export const StyledIndicatorContainer = styled.div`
    float: right;
    margin: 20px 20px 0 0;
`;

export const SubCategoryBlockTitleContainer = styled.div`
    height: 15px;
    padding: 0 20px;
    position: relative;
    padding-top: 10px;
    background: #ebecf0;
`;

export const SubCategoryBlockCol = styled.span`
    float: left;
    color: #343434;
    font-size: 14px;
    width: 814px;
    margin-top: 10px;
    font-weight: 700;
`;

export const RightNavHeadingRight = styled.span`
    float: left;
    width: 91px;
    font-size: 12px;
    color: #838793;
`;

export const RightNavHeadingSmallText = styled.span`
    font-size: 10px;
    color: #afafaf;
`;

export const StyledBodyTitle = styled.div`
    font-size: 14px;
    color: #838793;
    width: 200px;
`;
export const StyledBodyValue = styled.div`
    font-size: 16px;
    color: #343434;
`;

export const StyledBodyList = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

export const StyledScoreDeltaValue = styled.div`
    font-size: 12px;
    color: #fb764b;
    font-weight: 500;
    margin-left: 10px;
    margin-top: 1px;
`;

export const StyledScoreTitle = styled.div`
    width: 200px;
    color: ${(props: { active: boolean }) => (props.active ? "#343434" : "#838793")};
    font-size: 14px;
    text-transform: uppercase;
    font-weight: ${(props: { active: boolean }) => (props.active ? "700" : "400")};
`;

export const StyledScoreValue = styled.div`
    /* color: ${(props: { active: boolean }) => (props.active ? "#343434" : "#fff")}; */
    font-weight: 700;
    width: 35px;
    font-size: 14px;
    line-height: normal;
`;
export const StyledOverAllBodyScoreValue = styled.div`
    color: #343434;
    font-weight: 700;
    width: 35px;
    font-size: 12px;
    line-height: normal;
`;

export const StyledHeaderPrimary = styled.div`
    height: 40px;
    padding: 12px 20px;
    box-sizing: border-box;
    background: #fff;
    border-bottom: 1px solid #e2e2e2;
    border-radius: 3px;
    > div {
        padding-right: 72px;
    }
`;
export const StyledHeaderPrimaryLabel = styled.span`
    float: left;
    font-size: 12px;
    text-transform: uppercase;
    color: #343434;
    line-height: normal;
    font-weight: 500;
`;
export const StyledRightLabelContainer = styled.span`
    float: left;
    width: 91px;
`;
export const StyledHeaderPrimaryDeltaValue = styled.span`
    float: left;
    color: #fb764b;
    font-weight: 700;
    font-size: 12px;
    margin-top: 0px;
`;

export const StyledAccordionHeader = styled.div`
    height: 42px;
    cursor: auto;
    display: flex;
    border-left: ${(props: { importance: ImportanceLevelType }) => {
        const importance = find(IMPORTANCE_COLOR_SCHEME, (i: ImportanceColorSchemeInterface) => i.importance === props.importance);
        return `4px solid ${!isUndefined(importance) ? importance.hexCode : "#ffff"}`;
    }};
`;

export const StyledParameterAccordionTitleContainer = styled.div`
    width: 40%;
`;

export const StyledParameterAccordionTitle = styled.span`
    font-size: 12px;
    color: #343434;
    margin: 14px 0 0 10px;
    float: left;
`;

export const StyledChevron = styled.i`
    width: 16px;
    height: 16px;
    float: left;
    background-size: 16px;
    margin-left: 20px;
    margin-top: ${(props: { isOpen: boolean }) => (props.isOpen ? "12px" : "13px")};
    cursor: pointer;
    background: url(${downarrowfill}) no-repeat;
    transform: ${(props: { isOpen: boolean }) => (props.isOpen ? "rotate(90deg)" : "rotate(0deg)")};
`;

export const StyledSubCategoryAccordion = styled.div`
    padding: 20px !important;
    background: #ebecf0;
`;

export const StyledTabAccordionContainer = styled.div`
    &:first-child {
        border-radius: 4px 4px 0 0;
    }
`;
export const StyledParameterContainer = styled.div`
    float: left;
    width: 40%;
    ${StyledLegendWrapper} {
        float: left;
        margin: 10px 10px 0 0;
    }
    ${StyledLegend} {
    }
    ${StyledWrapper} {
        width: 71px;
        float: left;
        margin-right: 10px;
        padding: 6px 0px !important;
    }
`;

export const StyledRangeSlider = styled.div`
    width: 300px;
    margin-top: 13px;
    height: 6px;
    float: left;
    margin-right: 5px;
`;

export const StyledSwitchedComponent = styled.div`
    position: relative;
    margin-top: 11px;
    float: left;
`;
export const StyledEditorLegend = styled.div`
    float: right;
    margin: 11px 20px 0 0;
`;

export const StyledScoreNoDataIcon = styled.span`
    margin-top: 13px;
    float: left;
    margin-left: 20px;
`;
export const StyledScoreLabel = styled.span`
    color: #343434;
    width: 200px;
    font-size: 12px;
    line-height: normal;
    text-transform: uppercase;
`;
export const StyledOverAllActiveScoreLabel = styled.span`
    color: #fff;
    width: 200px;
    font-weight: 700;
    font-size: 14px;
    line-height: normal;
    text-transform: uppercase;
`;

export const StyledscoreDeltaValue = styled.span`
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
    color: ${(props: { deltaValueColor: string }) =>
        props.deltaValueColor === PositiveNegativeIdealDeltaValue.ORANGE_TEXT
            ? "#fb764b"
            : props.deltaValueColor === PositiveNegativeIdealDeltaValue.GREEN_TEXT
                ? "#53d68a"
                : "#afafaf"};
`;
export const StyledSubCategoryDetail = styled.div`
    height: auto;
    background: #fff;
    box-sizing: border-box;
    padding: 20px;
    position: relative;
    cursor: auto;
`;
export const StyledSubCategoryParamInfoTitle = styled.div`
    font-size: 12px;
    color: #343434;
    width: 58px;
    line-height: 1.5;
`;
export const StyledSubCategoryParamInfo = styled.div`
    font-size: 12px;
    color: #838793;
    margin-bottom: 20px;
    line-height: 14px;
    width: calc(100%-95px);
`;
export const StyledSubCategoryScale = styled.div`
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5;
    float: left;
`;
export const StyledCorrelationText = styled.div`
    font-size: 10px;
    color: #343434;
    background: rgba(255, 165, 1, 0.1) url(${correlationImg}) no-repeat;
    padding-left: 15px;
    height: 36px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
    display: flex;
    align-items: center;
    background-position: 10px center;
    padding-left: 30px;
`;

export const StyledFilterMainLabel = styled.h3`
    font-size: 14px;
    font-weight: 500;
    color: #343434;
    margin: 0px;
    margin-right: 20px;
`;
export const StyledFilterContainer = styled.div`
    padding: 20px;
    padding-bottom: 0px;
`;
export const StyledParameterErrorIcon = styled.span`
    margin-top: 14px;
    float: left;
`;

export const StyledInfoIcon = styled.i`
    background: url(${infoinvert});
    width: 16px;
    height: 16px;
    background-size: 16px;
    display: inline-block;
    position: relative;
    top: 4px;
`;
export const KwLevelImpactURLContainer = styled.div`
    display: block;
    ${StyledLinkButton} {
        margin-right: 5px;
        ${StyledLabel} {
            font-size: 14px;
        }
    }
    ${StyledInfoIcon} {
        /* margin-right: 10px; */
    }
`;

export const LeftPanelTrafficRankAccordionTitleContainer = styled.div`
    padding: 16px 20px;
    box-sizing: border-box;
    font-weight: 700;
`;
export const LeftPanelTrafficRankAccordionText = styled.div`
    color: ${(props: { disabled: boolean; isOpen: boolean }) => (props.disabled ? "#afafaf" : "#343434")};
    font-size: 14px;
    text-transform: uppercase;
    margin-right: 10px;
    font-weight: ${(props: { disabled: boolean; isOpen: boolean }) => (props.isOpen ? "500" : "400")};
`;
export const LeftPanelTrafficRankAccordionLoadingText = styled.div`
    color: #838793;
    font-size: 10px;
    padding: 5px 0px 0px 0px;
    line-height: 14px;
`;

export const LeftPanelTrafficRankAccrodionTitle = styled.div`
    ${LeftPanelTrafficRankAccordionText} {
        color: red;
    }
`;

export const LeftPanelTrafficRankAccordionBodylabel = styled.span`
    width: 200px;
    display: inline-block;
    color: #343434;
    font-size: 12px;
`;

export const LeftPanelTrafficRankAccordionBodyValue = styled.span`
    color: #343434;
    font-weight: 700;
    display: inline-block;
    font-size: 12px;
    width: 35px;
`;

export const StyledBannerNotificationContainer = styled.div`
    height: 36px;
    background: rgba(0, 172, 206, 0.1);
    border: 1px solid #00acce;
    margin: 20px 0px;
    border-radius: 3px;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledTechAuthNotificationBarContainer = styled(StyledBannerNotificationContainer)`
    padding: 10px 10px;
    margin: 20px 20px;
`;

export const StyledBannerLoadingStatusContainer = styled.span`
    flex-shrink: 0;
`;

export const StyledBannerNotificationText = styled.span`
    font-size: 12px;
    line-height: 16px;
    color: #343434;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
`;

export const StyledLeftPanelTrafficRankAccordionValue = styled.div`
    color: #343434;
    font-weight: 700;
    display: inline-block;
    font-size: 12px;
    width: 35px;
`;
export const StyledsLeftPanelTrafficRankScoreDeltaValue = styled.span`
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
    width: 35px;
    color: ${(props: { deltaValueColor: string }) =>
        props.deltaValueColor === "orange_text" ? "#fb764b" : props.deltaValueColor === "green_text" ? "#53d68a" : "#afafaf"};
`;

export const StyledPopUpButton = styled(StyledPrimaryButton)`
    margin: 10px 10px;
`;
export const StyledAvailableKeywordButton = styled(StyledInvertButton)`
    height: 34px;
    margin: 10px 10px;
`;

export const StyledPaginationContainer = styled.div`
    height: 42px;
`;

export const StyledModalTable = styled.table`
    width: 100%;
    padding: 0px;
    margin: 0px;
    border-collapse: collapse;
    table-layout: fixed;
    &.CONTENT {
        width: 100%;
    }
    &.AUTHORITY {
        width: auto;
    }
    &.TECHNICAL {
        width: auto;
    }
`;

export const StyledModalTableBodyTag = styled.tbody``;

export const StyledScoreValues = styled.span`
    float: left;
    font-size: 12px;
    color: #343434;
    margin-right: 5px;
    margin-top: 3px;
`;

export const StyledWarningIcon = styled.i`
    background: url(${warning}) no-repeat;
    width: 14px;
    height: 14px;
    background-size: cover;
    display: inline-block;
`;

export const StyledMissingHeaderInfo = styled.span`
    font-size: 10px;
    color: #393838;
    position: relative;
    padding-top: 16px;
`;

export const StyledSuccessText = styled.span`
    color: #838793;
    line-height: 1.33;
    margin-left: 10px;
    float: left;
    font-size: 12px;
    width: 280px;
    opacity: 0.9;
`;
export const StyleSubTableContainer = styled.div`
    position: relative;
    tr {
        td {
            background: ${(props: { id: string }) => (props.id === "kw_impact_subtable" ? "" : "#f7f7f9 !important")};
            box-shadow: none !important;
        }
    }
    tr:nth-child(1) {
        td {
            /* background: #ebecf0 !important; */
            background: ${(props: { id: string }) => (props.id === "kw_impact_subtable" ? "" : "#dfe0e5 !important")};
            z-index: 10;
        }
    }
`;

export const StyledSubTableTr = styled.tr`
    &.nestedHeader {
        background: #ebecf0;
    }
`;

export const StyledSubTableTh = styled.td`
    color: #343434;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    height: 46px;
    vertical-align: middle;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    overflow: hidden;
`;

export const StyledSubTableTd = styled.td`
    font-size: 12px;
    color: #343434;
    padding: 0px 20px;
    border-bottom: 1px solid #e2e2e2;
    height: 37px;
    box-sizing: border-box;
    vertical-align: middle;
    overflow: hidden;
    padding: 0 0 0 25px;
    background: #f7f7f9;
    font-weight: 400;
`;

export const StyledExpandButton = styled.button`
    width: 16px;
    height: 24px;
    background: #838793;
    position: absolute;
    left: 0px;
    z-index: 10;
    top: 10px;
    border-radius: 0px 3px 3px 0;
    background-image: url(${(props: { isExpanded: boolean }) => (props.isExpanded ? arrowPointedLeft : arrowPointedRight)});
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0px;
    border: none;
    box-shadow: 0 0 8px -2px rgba(0, 0, 0, 0.5);
`;

export const StyledArrowContainer = styled.div`
    padding-left: 6px;
    padding-top: 1px;
    height: 13.1px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const StyledArrowHead = styled.div`
    width: 0;
    height: 0;
    cursor: pointer;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: ${(props: { className: string }) => (props.className === "inverted" ? "" : "5px solid #343434")};
    border-top: ${(props: { className: string }) => (props.className === "inverted" ? "5px solid #343434" : "")};
`;

export const LeftPanelTrafficRankAccordionTextWrapper = styled.span`
    display: inline-block;
`;

export const LeftPanelTrafficRankAccordionWarningWrapper = styled.span`
    float: left;
    padding-right: 5px;
`;
export const LeftPanelTrafficRankAccordionProcessingText = styled.span`
    line-height: 14px;
`;
export const StyledKeywordLevelImpactLinkToolTip = styled.span`
    position: absolute;
    margin-top: 3px;
`;

export const StyledInputNoDataIcon = styled.span`
    width: 71px;
    margin-right: 10px;
    margin-top: 13px;
    float: left;
`;
/*::: Download button :::*/
export const StyledDownloadBtnContainer = styled.div`
    float: right;
    margin: ${(props: { type: string }) => (props.type === "Content" ? "-10px 0 0 0" : "-10px 32px 0 0")};
`;

export const StyledDownloadBtnSimulatorContainer = styled.div`
    display: flex;
    padding: 0;
    width: 28px;
    height: 28px;
    border: none;
    margin-right: 10px;
    align-items: center;
    &:disabled {
        border: none;
    }
`;

export const StyledRefereshIcon = styled.button`
    display: flex;
    padding: 0;
    width: 28px;
    height: 28px;
    background: #fff url(${refreshBorderIcon}) no-repeat;
    background-size: contain;
    background-position: center center;
    border: none;
    border-radius: 3px;
    cursor: ${(props: { disable?: boolean }) => (props.disable ? "not-allowed" : "pointer")};
    &:hover {
        background-color: #0198b6;
        border: none !important;
    }
`;

export const StyledRefereshIconContainer = styled.div`
    margin-right: 0px;
`;

export const StyledDownloadButton = styled(StyledPrimaryButton)`
    float: left;
    padding: 0;
    width: 28px;
    height: 28px;
    background: #fff url(${downloadButton}) no-repeat;
    background-size: contain;
    background-position: center center;
    margin-top: 10px;
    border: none;
    &:disabled {
        border: none;
    }
`;

export const StyledDownloadWithDropdownButton = styled(StyledPrimaryButton)`
    float: left;
    padding: 0;
    width: 28px;
    height: 28px;
    background: #fff url(${downloadButton}) no-repeat;
    background-size: contain;
    background-position: center center;
    margin-top: 10px;
    border: none;
    &:disabled {
        border: none;
        background: #fff url(${downloadDisabledButton}) no-repeat;
        margin-top: 14px;
    }
    &:active {
        border: none;
        background: ${(props: { disabled: boolean }) =>
        `#fff url(${props.disabled ? downloadDisabledButton : downloadHoverButton}) no-repeat`};
    }
    &:hover {
        border: none;
        background: ${(props: { disabled: boolean }) =>
        `#fff url(${props.disabled ? downloadDisabledButton : downloadHoverButton}) no-repeat`};
    }
`;

export const StyledDownloadDisableButton = styled(StyledDownloadButton)`
    cursor: ${(props: { disabled: boolean }) => `${props.disabled ? "not-allowed" : "pointer"} !important`};
    background: ${(props: { disabled: boolean }) => `#fff url(${props.disabled ? downloadDisabledButton : downloadButton}) no-repeat`};
`;

export const StyledDownloadButtonLabel = styled(StyledLabel)`
    display: none;
`;

export const StyledScoreWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledResetYesPopUpButton = styled(StyledPrimaryButton)`
    margin: 10px 10px;
`;
export const StyledResetNoKeywordButton = styled(StyledInvertButton)`
    height: 34px;
    margin: 10px 10px;
`;
export const StyledContainerWarningSection = styled.div`
    color: #838793;
    padding: 0px 0px 10px 0px;
    line-height: 14px;
`;
export const StyledContainerWarningIcon = styled.span`
    float: left;
    padding-right: 5px;
`;
export const StyledContainerWarningText = styled.span`
    line-height: 14px;
    font-size: 10px;
`;
export const StyledTagHighlighter = styled.div<{ isShowChar?: boolean; isViewOriginal?: boolean }>`
    width: ${(p) => {
        return p.isViewOriginal ? "110px" : "150px";
    }};
    height: fit-content;
    background: rgba(235, 236, 240, 0.5);
    vertical-align: top !important;
    padding: 7px 8px;
    font-size: 12px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 14px;
    display: table-cell;
    font-family: Lato;
    color: #343434;
    & .tag-highlight-visibility {
        opacity: 0.8;
    }
`;

export const StyledHTMLEditorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

// Editor toggle view button hidden --> Reuseable
export const StyledEditorViewToggle = styled.div`
    display: inline-block;
    float: left;
    margin-right: 10px;
`;

export const StyledEditorViewToggleZoom = styled.div`
    display: inline-block;
    float: left;
    margin-right: 18px;
`;

export const StyledEditorViewLabel = styled.span`
    font-family: Lato;
    font-size: 12px;
    float: left;
    margin: 0px 5px;
    margin-top: 2px;
`;

export const StyledKeywordImapctModalSearchVolumeText = styled.span`
    margin: 14px 5px;
`;

// Edit modal code

export const StyledSimulatorModalContainer = styled.div`
    width: 400px;
    height: 190px;
    background: #fff;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
    padding: 20px;
    border-radius: 3px;
    position: absolute;
    z-index: 9;
    right: ${(props: SimulatorInfoDescriptionModalPropsInterface) => (props.descriptionOffsetWidth < 350 ? "-290px" : "0")};
    top: 22px;
    box-sizing: border-box;
`;

export const StyledLabelInputContainer = styled.div`
    display: flex;
`;
export const StyledLabelContainer = styled.span`
    font-size: 12px;
    color: #838793;
    font-weight: 600;
    padding-top: 8px;
`;
export const StyledTextareaContainer = styled.textarea`
    border: 1px solid #afafaf;
    padding: 10px;
    font-size: 12px;
    color: #343434;
    resize: none;
    width: 250px;
    height: 100px;
    border-radius: 3px;
    margin-left: 20px;
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
`;

export const StyledButtonContainerModal = styled.div`
    margin: 20px 0 0 119px;
    ${StyledPrimaryButton} {
        height: 28px;
        margin-left: 20px;
    }
`;

export const StyledReseturlContainer = styled.div`
    display: none;
    margin-right: 5px;
`;

export const StyledMultipleValueDD = styled.div`
    display: flex;
    width: 98%;
    position: relative;
    z-index: 30;
    &:hover {
        z-index: 200;
    }
`;

export const StyledVersionEmpty = styled.span`
    font-size: 12px;
    width: 165px;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
`;
export const StyleddropdownMultiple = styled.div<StyledSimulatorMultipleDropdown>`
    width: ${(p: StyledSimulatorMultipleDropdown) => {
        return p.width ? p.width : "68%";
    }};
    /* background: #fff; */
    margin: 0 10px;
    box-sizing: border-box;
    border-radius: 3px;
    align-items: center;
    z-index: 2;
    flex: 1;
    #sim_version_list_dropdown > div:first-child > div > div:first-child {
        color: #343434;
    }
    &#vr_keywords_dropdown-container {
        margin-right: 0px;
        z-index: 2;
    }
`;

export const StyledVersionSaveButton = styled(StyledInvertButton)`
    height: 34px;
    margin: 10px 10px;
`;
export const StyledExpandedUpdateContainer = styled.div`
    display: none;
`;

export const StyleSimultorExpandedContainer = styled.div`
    width: calc(100%-96px);
    margin: auto;
    margin-bottom: 42px;
    margin-top: 10px;
    ${StyledLowerRowContainer} {
        // justify-content: normal;
        position: relative;
    }
    ${StyledExpandedUpdateContainer} {
        display: block;
        position: absolute;
        right: 0;
        top: 9px;
    }
`;

export const StyledEditorRecommendationContainer = styled.div<{ flowType?: string; isPanelOpen?: boolean }>`
    display: flex;
    position: sticky;
    top: 72px;
    width: 29%;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.18);
    margin-left: auto;
    z-index: 90;
    height: fit-content;
    overflow: visible;
    background: #fff;
    right: 0;

    @media (max-width: 1665px) {
        width: 36%;
    }

    ${({ flowType }) =>
        flowType === "editor" &&
        css`
            margin-top: -282px;
            margin-top: ${({ isPanelOpen }) => (isPanelOpen ? "-62px" : "-712px")};
        `}

    ${({ flowType }) =>
        flowType === "browser" &&
        css`
            position: absolute;
            margin-top: -112px;
        `}
`;

export const StyledOldVersionSectionContainer = styled.div`
    margin: 0px 48px;
    margin-top: 12px;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.18);
    background-color: #fff;
    & .main-notification-previous-version {
        margin: 0;
    }
`;

export const StyledContentOutlineFeedbackContainer = styled.div`
    background: #f4f4f4;
    display: flex;
    justify-content: end;
    padding: 5px 0;
    box-shadow: 0 -2px 5px 0 rgba(117, 117, 117, 0.3);
`;

export const StyledContentOutlineFeedback = styled.div`
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #025d92;
    margin-right: 10px;
    padding-top: 3px;
`;

export const StyledViewRecommendationToggle = styled.div`
    margin-left: 15px;
    /* font-family: SegoeUI; */
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00acce;
    text-decoration: underline;
    cursor: pointer;
    &.non_active {
        color: #afafaf;
        cursor: not-allowed;
    }
`;

export const StyledLikeContentOutline = styled.img`
    height: 18px;
    width: 18px;
    margin: 3px 6px 0 5px;
    cursor: pointer;
`;

export const StyledDislikeContentOutline = styled.img`
    height: 18px;
    width: 18px;
    margin: 3px 13px 0 6px;
    cursor: pointer;
`;

export const StyledPipeText = styled.span`
    border: solid 1px #e2e2e2;
    height: 13px;
    margin: 5px 0;
`;

export const StyledFlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100%-96px);
    margin: auto;
`;

export const StyledBackButtonContainer = styled.div`
    text-transform: uppercase;
    display: flex;
    float: none !important;
    margin-top: 0px;
    width: calc(100%-494px);
`;

export const StyledCtaFlexContainer = styled.div`
    display: flex;
    align-items: center;
`;
export const StyledValueNameDD = styled.span<StyledSimulatorMultipleDropdown>`
    font-size: 12px;
    color: #343434;
    width: ${(p: StyledSimulatorMultipleDropdown) => {
        return p.width ? p.width : "256px";
    }};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
`;

export const StyledVersionNotification = styled.div`
    flex-wrap: wrap;
`;

export const StyledAccordionBtmMargin = styled.div`
    margin-bottom: 10px;
`;

export const StyledSearchInputContianer = styled.div`
    display: flex;
    width: ${(props: { reference: string }) => (props.reference === "kw_level_impact_keyword_searchbar" ? "240px" : "225px")};
    padding-left: 20px;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 2px;
    margin-bottom: 4px;
    > div {
        > input {
            width: 16px;
            background: url(${searchIcon}) no-repeat;
            background-position: left center;
            border: none;
            background-size: 16px;
            cursor: pointer;
            transition: width 300ms ease-in-out 0s;
            height: auto;
            padding: 4px 10px;
        }
        > input:focus,
        > input:not(:placeholder-shown) {
            width: 250px;
            padding-left: 25px;
            box-sizing: border-box;
            border: none;
            background-position: left center;
            border-bottom: 1px solid #afafaf;
            border-radius: 0px;
        }
    }
`;

export const StyledNonLivePreSimText = styled.div`
    color: #838793;
    font-size: 10px;
    line-height: 14px;
`;
export const StyledNonLivePreSimTextWarningWrapper = styled.span`
    float: left;
    padding-right: 5px;
`;

export const StyledNonLivePreSimWrapperText = styled.span`
    line-height: 14px;
    color: #3a85ef;
    font-size: 12px;
`;

export const StyleLoaderDiv = styled.div`
    float: left;
`;

export const StyledResultNumber = styled.div`
    padding-top: 10px;
`;

export const StyledGoToSimModelBody = styled.div`
    font-size: 12px;
    &:before {
        content: url(${warning});
        position: absolute;
        left: 115px;
        top: 62px;
    }
`;

export const StyledLink = styled.a.attrs({ href: "mailto:alps@iquanti.com" })`
    color: #00acce;
    text-decoration: underline;
`;

export const StyledHeaderSecondary = styled.header`
    width: 100%;
    background: white;
    display: flex;
    align-items: center;
    position: sticky;
    z-index: 92;
    border-bottom: 1px solid #e2e2e2;
`;

export const StyledHeaderSecondaryBody = styled.div`
    padding: 0 7px;
    width: 100%;
`;

export const HeaderTitle = styled.span`
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #343434;
    margin-right: 10px;
    text-transform: uppercase;
    padding: 5px 0;
`;

export const HeaderTileTitle = styled.span`
    color: #838793;
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
`;

export const HeaderTileValue1 = styled.span`
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #343434;
    margin: 0 5px;
`;

// !more button modal-------->>

export const StyledMoreKeyWordModal = styled.div`
    width: 250px;
    height: 236px;
    margin: 0 0 0 1px;
    padding: 1px 10px 0px;
    border-radius: 3px;
    box-shadow: 0 0 11px 0 #b1b4be;
    background-color: #fff;
    position: absolute;
    z-index: 9;
    right: -5px;
    top: 22px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const StyledMoreKeyWordModalHeader = styled.div`
    height: 15px;
    display: flex;
    justify-content: space-between;
`;

export const StyledMoreKeyWordModalLabel = styled.span`
    color: #343434;
    width: 55px;
    height: 15px;
    margin: 0 5px 11px 0;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
`;

export const HeaderTiles = styled.ul`
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

export const HeaderTile = styled.li`
    display: flex;
    align-items: center;
    &:not(:last-child) {
        border-right: 1px solid #e2e2e2;
        margin-right: 10px;
        padding-right: 6px;
    }
`;

export const StyledMoreKeyWordModalLabelLength = styled.span`
    width: 21px;
    height: 15px;
    margin: 0 0px 11px 5px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #838793;
`;
export const StyledMoreKeyWordModalLabelListItem = styled.span`
    font-weight: 400;
    font-family: Lato;
    font-size: 12px;
    color: #343434;
    display: block;
    padding-left: 5px;
    width: 200px;
`;

export const StyledCrossButton = styled.button`
    background: none;
    border: none;
    width: 16px;
    height: 16px;
    margin: 0 0 10px 133px;
    object-fit: contain;
`;

export const StyledUL = styled.ul`
    width: 245px;
    list-style-type: initial;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #cdcdcd #fff;
    margin: 5px 0px 0px -10px;
    padding: 0px 0px 0px 5px;
    font-family: Lato;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: normal;
    color: #343434;
    border-top: solid 1px #ebecf0;
`;

export const StyledLI = styled.li`
    margin: 5px 15px;
`;

export const HeaderTileValue2 = styled.span`
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    &.positive {
        color: #53d68a;
    }
    &.negative {
        color: #fb764b;
    }
    &.normal {
        color: #363636;
    }
`;

export const SummaryHeaderDropDownButton = styled.button`
    padding: 4px;
    background: none;
    outline: none;
    margin-left: 10px;
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid #fff;
    &.active {
        border: 1px solid #00acce;
    }
    & img {
        width: 20px;
    }
    &:disabled {
        opacity: 0.6;
        pointer-events: none;
    }
`;

export const StyledSimulatorTabView = styled.div`
    background-color: #edf1f5;
`;

export const StyledSimulatorTabItems = styled.ul`
    display: flex;
    min-height: 45px;
    padding: 0px 28px;
    padding-top: 10px;
`;

export const StyledSimulatorTabItem = styled.li`
    position: relative;
    padding: 15px 20px;
    cursor: pointer;
    font-family: Lato;
    font-size: 14px;
    color: #394961;
    user-select: none;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    &.active_tab {
        font-weight: 800;
        color: #2363ac;
        background: #fff;
        &::after {
            position: absolute;
            width: 100%;
            height: 4px;
            background: #2363ac;
            content: "";
            left: 0;
            bottom: 0;
        }
    }

    &.disable_tab {
        color: #afafaf;
        pointer-events: none;
        opacity: 0.4;
    }
`;

export const StyledUserDropdown = styled.div`
    display: flex;
    align-items: center;
    &.recommendation-box-editor-common-tabs-phrases-dd_dropdown_wrapper #recommendation-box-editor-common-tabs-phrases-dd_dropdown {
        margin-top: 0px;
    }
    .tag-recommendation-dropdown__value-container > div {
        width: 114px;
    }
    .phrase-recommendation-dropdown__value-container > div {
        width: 114px;
    }
`;

export const StyledNotificationWrapper = styled.div`
    // position: relative;
`;
export const StyledNotificationWrapperInside = styled.div`
    position: absolute;
    right: 50px;
    top: 168px;
    width: 350px;
`;

export const StyledNotificationParent = styled.div`
    position: relative;
    &.no-tag-selected {
        position: absolute;
        top: 107px;
        right: 47px;
        left: 925px;
    }
`;

// ! Breif popup --------------->>
export const StyledBriefWrapper = styled.div`
    position: relative;
`;

export const StyledBreifHeader = styled.div`
    display: flex;
    height: 45px;
`;
export const StyledBreifTitle = styled.div`
    display: flex;
    width: 600px;
    height: 45px;
    background-color: #ebecf0;
`;
export const StyledBreifTitleSpan1 = styled.span`
    right: 20px;
    width: 97px;
    height: 15px;
    margin: 16px 0px 14px 20px;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #343434;
`;
export const StyledBreifTitleSpan2 = styled.span`
    left: 20px;
    width: 77px;
    height: 15px;
    margin: 16px 20px 14px 385px;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #343434;
`;

export const StyledBreifSpan = styled.span`
    width: 206px;
    height: 15px;
    margin: 16px 0px 14px 20px;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #838793;
`;
export const StyledBreifBody = styled.div`
    margin: 0px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #cdcdcd #fff;
    max-height: 655px;
`;

export const StyledDropDownOption = styled.option``;
export const StyledDropDown = styled.div`
    width: 250px;
    height: 25px;
    // margin: 10px 10px 10px 55px;
    // padding: 5px 145px 5px 10px;
    // border-radius: 3px;
    // border: solid 1px #afafaf;
    // background-color: #fff;
`;

export const StyledBriefWrapperBody = styled.div`
    position: absolute;
    width: 600px;
    height: 750px;
    z-index: 9;
    right: 5px;
    top: 65px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: -2px 4px 6px 1px rgba(0, 0, 0, 0.3);
`;
export const StyledBriefRefreshButton = styled.button`
    border: none;
    background: none;
    margin: 10px 0px 2px 72px;
`;

export const StyledBriefSiteList = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0px 20px 0x 20px;
    border-bottom: 1px solid #ebecf0;
    margin: 10px 0px;
    padding: 0px 0px 10px 0px;
`;

export const StyledBriefSiteListItemName = styled.a`
    width: 450px;
    height: 15px;
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #025d92;
    pointer-events: auto;
    margin: 0px 0px 0px 20px;
`;
export const StyledBriefSiteListItemScore = styled.span`
    width: 17px;
    height: 15px;
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #343434;
    padding-right: 20px;
`;

export const StyledLoading = styled.div`
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const StyledBriefPopupModal = styled.div`
    position: absolute;
    z-index: 2;
    top: 133px;
    width: 100vw;
    height: 747px;
    background: rgba(0, 0, 0, 0.5);
`;

export const StyledSingleValue = styled.div`
    width: 154px;
    display: flex;
`;

export const StyledNoBrief = styled.div`
    height: 20px;
    margin: 38px 0px 0;
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: #838693;
    padding: 0 14px 48px;
`;

export const StyledTD = styled.td`
    position: relative;
    &.CONTENT::after,
    &.AUTHORITY::after,
    &.TECHNICAL::after {
        width: 200%;
        height: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid #e2e2e2;
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        pointer-events: none;
        z-index: -1;
        background: white;
    }
`;

// !-----BrowserToolBar----->>

export const StyledSelectModeWrapper = styled.div`
    display: flex;
    height: 56px;
    padding: 0px 48px;
    align-items: center;
    position: relative;
`;

export const StyledSimulatorButtonsContainer = styled.div`
    width: 71%;
    @media (max-width: 1665px) {
        width: 64.5%;
    }
`;

export const StyledSimulatorButtonsWrapper = styled.div<{ isAIAssistedContent?: boolean }>`
    display: flex;
    justify-content: ${({ isAIAssistedContent }) => isAIAssistedContent && "flex-end"};
    align-content: ${({ isAIAssistedContent }) => !isAIAssistedContent && "center"};
`;

export const StyledSimulatorEditorButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const StyledBrowserToolBar = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 0 27px;
    opacity: ${(props: { iframeLoaded?: boolean }) => (props.iframeLoaded ? "1.0" : "0.5")};
    cursor: ${(props: { iframeLoaded?: boolean }) => (props.iframeLoaded ? "pointer" : "not-allowed")};
`;

export const StyledBrowserEditRadio = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100px;
    margin-top: -10px;
    margin-right: 120px;

    @media (max-width: 1440px) {
        margin-right: 100px;
    }
`;

export const StyledRadioWrapper = styled.div`
    position: relative;
    z-index: 0;
`;

export const StyledRadioLabel = styled.label`
    height: 15px;
    opacity: 0.6;
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #838793;
    cursor: ${(props: { iframeLoaded?: boolean }) => (props.iframeLoaded ? "pointer" : "not-allowed")};
    &.btn-label {
        position: absolute;
        top: 9px;
        left: 39px;
        cursor: pointer;
    }
    &.meta {
        height: 22px;
        font-size: 14px;
        opacity: 1;
    }
    &.white {
        color: white;
        z-index: 2;
        opacity: 1;
        left: 41px;
    }
    &.browser {
        left: 36px;
    }
`;

export const StyledRadioInput = styled.input`
    border: 1px solid rgb(158, 152, 152);
    padding: 0.5em;
    -webkit-appearance: none;
    width: 106px;
    height: 30px;
    border-radius: 3px;
    transition: background-color ease-in-out;
    position: absolute;
    cursor: pointer;
    &.browser {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-left: none;
    }
    &.editor {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-right: none;
    }
    &:checked {
        background-color: #00acce;
        border: solid 1px #00acce;
        color: white;
    }
`;
export const StyledSelectHeaderTitles = styled.span`
    height: 15px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #838793;
    white-space: nowrap;
    margin-right: 8px;
`;

export const StyledSelectHeaderMeta = styled.div`
    width: 138px;
    height: 35px;
    margin: 0 0px;
    border-radius: 3px;
    box-shadow: 0 0 5px 0 rgba(159, 159, 159, 0.81);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    &.glow {
        border: 1px solid #00acce;
    }
    pointer-events: ${(props: { iframeLoaded?: boolean }) => (props.iframeLoaded ? "all" : "none")};
`;

export const StyledMetaDropIcon = styled.img`
    height: 16px;
`;
export const StyledMetaButton = styled.button`
    margin-left: 8px;
    background: none;
    border: none;
    cursor: ${(props: { iframeLoaded?: boolean }) => (props.iframeLoaded ? "pointer" : "not-allowed")};
    pointer-events: ${(props: { iframeLoaded?: boolean }) => (props.iframeLoaded ? "all" : "none")};
    height: 35px;
    &.redo-undo {
        width: 50px;
    }
    &.high {
        background-color: #ebecf0;
    }
    @media (max-width: 1440px) {
        margin-left: 0;
    }
`;

export const StyledTagToolButtonLabel = styled.span`
    pointer-events: none;
    padding: 0px;
    color: #838793;
    font-size: 14px;
    height: 33px;
    margin: 0px 10px 0px 10px;
`;

export const StyledTagToolBarWrap = styled.span`
    color: #dddddd;
    font-size: 16px;
    font-weight: 600;
    background-color: white;
    border: none;
    height: 35px;
    margin: 0 0 0 10px;
    border-radius: 3px;
    box-shadow: 0 0 5px 0 rgba(159, 159, 159, 0.81);
    min-width: 325px;

    @media (max-width: 1440px) {
        min-width: 300px;
    }
`;

export const StyledRedoUndoWrap = styled.span`
    margin-left: 10px;
    margin-top: 3px;
`;
export const StyledHighlight = styled.div`
    margin-left: 30px;

    @media (max-width: 1440px) {
        margin-left: 15px;
    }
`;

export const StyledRevertToOriginalButtonBrowserMode = styled(StyledLinkButton)`
    margin-left: auto;
    @media (max-width: 1440px) {
        padding: 0 15px;
    }
`;

export const StyledVAIButtonEditorMode = styled(StyledLinkButton)`
    /* margin-left: auto; */
    margin-right: 17px;
`;

export const StyledViewOriginalButtonEditorMode = styled(StyledLinkButton)`
    margin-left: auto;
    margin-right: 17px;
`;

export const StyledGetPageContentButton = styled(StyledLinkButton)`
    margin-right: 17px;
`;

export const StyledMetaItem = styled.div`
    display: flex;
    align-items: center;
    margin-left: 20px;
    position: relative;
`;

export const StyledHighlightInput = styled.input`
    cursor: ${(props: { iframeLoaded?: boolean }) => (props.iframeLoaded ? "pointer" : "not-allowed")};
    border: 1px solid rgb(158, 152, 152);
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    transition: background-color ease-in-out;
    &.orange {
        background-color: #fbaa15;
    }
    &.blue {
        background-color: #3a85ef;
    }
    &.green {
        background-color: #b4d06f;
    }
    &.yellow {
        background-color: #f9dc5c;
    }
    &:checked {
        border: 2px solid #00acce;
    }
`;

// ! meta elements dropdown
export const StyledMetaDropdownWrap = styled.div`
    /* width: 797px; when recommendation is visible*/
    width: 560px;
    height: fit-content;
    padding: 0 0 13px 10px;
    border-radius: 3px;
    box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.39);
    background-color: #fff;
    display: flex;
    position: absolute;
    z-index: 10;
    top: 285px;
    left: 440px;
    cursor: default;
`;
export const StyledMetaDropdownWrapOverlay = styled.div`
    position: absolute;
    width: 100vw;
    height: 1060px;
    top: -235px;
    left: -360px;
    background: none;
    z-index: 10;
    cursor: auto;
`;

export const StyledMetaMainBody = styled.div`
    width: 543px;
`;

export const StyledMetaMainRow = styled.div`
    display: grid;
    grid-template-columns: 120px 1fr;
`;
export const StyledMetaMainRowLabel = styled.span`
    margin: 30px 0px 0px 10px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #838793;
    &.first {
        margin-top: 26px;
    }
    &.recommendation {
        margin: 35px 0px 0px 20px;
    }
    &.body_content {
        margin: 0 0 0 10px;
    }
    &.body_content_count {
        margin: 0 0 0 45px;
        color: #343434;
    }
`;
export const StyledMetaMainRowInput = styled.div`
    width: 400px;
    margin: 20px 20px 0px 0px;
`;
export const StyledMetaMainRowCount = styled.div`
    width: 421px;
    text-align: right;
    font-family: Lato;
    font-size: 10px;
    line-height: normal;
    letter-spacing: normal;
    color: #838793;
`;
export const StyledTextAreaMeta = styled.textarea`
    width: 400px;
    height: 14px;
    border: 1px solid #afafaf;
    border-radius: 3px;
    resize: none;
    padding: 8px 10px;
    font-family: Lato;
    font-size: 12px;
    color: #343434;
    scrollbar-width: none;
    &.description {
        height: 100px;
    }
`;

export const StyledMetaMainButtonWrap = styled.span`
    display: flex;
    margin-top: 10px;
    justify-content: center;
`;
export const StyledRecommendationWrap = styled.div`
    border-left: 1px solid #e5e5e5;
    margin-left: 30px;
    display: none; /* Remove this property when recommendation is visible */
`;
export const StyledRecommendationHeader = styled.div`
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 0px;
`;
export const StyledRecommendationBody = styled.div``;
export const StyledRecommendationData = styled.div`
    margin: 5px 20px 10px 20px;
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #343434;
`;

export const StyledSimulationPage = styled.div`
    margin-top: 50px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
`;

export const StyledContentSimMain = styled.div<{ selectedTab: CategoryType }>`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: ${({ selectedTab }) => {
        return selectedTab === CategoryType.COMP_ANALYSIS && "0";
    }};
`;

export const StyledBrowserSectionBody = styled.div`
    display: block;
    margin: 0 48px;
    margin-top: 12px;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.18);
    background-color: #fff;
    flex-grow: 1;
    min-height: 600px;
    position: relative;
`;
// Score_Board elements

export const StyledScoreBoardWrap = styled.div`
    position: fixed;
    max-height: fit-content;
    width: 220px;
    border-radius: 3px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.4);
    background-color: #fff;
    left: ${(props: { recommendationOpen: boolean }) => (props.recommendationOpen ? "calc(100vw - 300px)" : "calc(100vw - 830px)")};
    z-index: 100;
    top: calc(100vh - 70px);
`;
export const StyledScoreBoardTitle = styled.span`
    font-family: Lato;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fcfcfc;
    margin-left: 10px;
    &.launch-score {
        color: #00acce;
        text-decoration: underline;
        margin-left: 0px;
    }
    &.warning {
        margin-bottom: 20px;
        color: #343434;
    }
    &.mode_msg {
        font-weight: normal !important;
        margin: 0 50px 20px;
        text-align: center;
        line-height: 1.67;
    }
`;

export const StyledScoreBoardHeader = styled.header`
    width: 220px;
    height: 30px;
    border-radius: 3px;
    background-color: #2363ac;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const StyledScoreBoardImg = styled.img`
    margin-left: 10px;
    cursor: pointer;
    &.close {
        width: 12px;
    }
    &.minimize {
        width: 19px;
        margin-left: 60px;
    }
    &.warning {
        width: 17px;
    }
    &.add {
        margin-left: 3px;
    }
`;

export const StyledDropDownImg = styled.img`
    pointer-events: all;
    &.disabled {
        pointer-events: none;
    }
`;

// warning component
export const StyledWarningBgWrap = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledWarningBodyWrap = styled.div`
    width: 525px;
    height: 211px;
    border-radius: 3px;
    box-shadow: 0 1px 7px 0 #a3a3a3;
    background-color: #fff;
`;

export const StyledWarningBtnDiv = styled.div``;

export const StyledDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const StyledWarningHeader = styled.header`
    height: 30px;
    margin: 10px;
    display: block;
    text-align: center;
    background-color: transparent;
    &:first-child {
        float: right;
    }
`;

export const StyledBtn = styled.span`
    margin-right: 20px;
    border: none;
    background: none;
    user-select: none;
    &.add-section {
        font-family: Lato;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        margin: 0;
        width: 41px;
        height: 30px;
    }
`;

export const StyledAddDesciptionLabel = styled.div`
    font-size: 12px;
    color: #343434;
    font-family: Lato;
    font-size: 10px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #838793;
    margin-top: 3px;
    margin-bottom: 1px;
    & span {
        font-family: Lato;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #838793;
    }
`;

// ADD-SECTION
export const StyledAddCircle = styled.div`
    width: 95px;
    height: 95px;
    opacity: 0.2;
    border-radius: 47.5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
    cursor: pointer;
    position: absolute;
    &.middle {
        width: 83px;
        height: 83px;
        opacity: 0.3;
        border-radius: 41.7px;
        z-index: 1;
        box-shadow: none;
        background-color: ${(props: { isActive?: boolean }) => (props.isActive ? "#2363ac" : "#afafaf")};
    }
    &.top {
        width: 72px;
        height: 72px;
        border-radius: 35.9px;
        z-index: 2;
        opacity: 1;
        text-align: center;
        color: #fff;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background-color: ${(props: { isActive?: boolean }) => (props.isActive ? "#2363ac" : "#afafaf")};
    }
    background-color: ${(props: { isActive?: boolean }) => (props.isActive ? "#2363ac" : "#afafaf")};
`;

export const StyledAddCircleWrap = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 95px;
    height: 95px;
    &.add-button {
        position: absolute;
        bottom: 50%;
        right: 20px;
        display: block;
        z-index: 101;
    }
`;

export const StyledBrowserLoadingBody = styled.div`
    flex-grow: 1;
    min-height: 100vh;
    width: 100%;
    overflow-y: hidden;
`;

export const StyledCLoseButton = styled(StyledInvertButton)`
    height: 30px;
    margin: 10px 10px;
`;

export const StyledBrowserWrapper = styled.div`
    position: relative;
    display: none;
    &.active {
        display: block;
    }
`;

export const CustomSelectWrapper = styled.div`
    width: 1021.07px;
    height: 24px;
    background: white;
    display: flex;
    align-items: center;
    margin: 20px 50px 20px 100px;
    position: relative;
    box-sizing: border-box;
    height: 30px;
    color: #343434;
    cursor: pointer;
    border-radius: 3px;
`;

export const CustomSelectedActiveOption = styled.button`
    width: 100%;
    height: 100%;
    background: white;
    outline: none;
    border: none;
    box-sizing: border-box;
    overflow: hidden;
    padding-right: 34px;
    position: relative;
    border: 1px solid #afafaf;
    &:focus {
        border: 1px solid #00acce;
    }
`;

export const CustomSelectedOptions = styled.ul`
    width: 100%;
    background: white;
    position: absolute;
    left: 0;
    bottom: -88px;
    z-index: 10;
`;

export const StyledBodyCountContent = styled.div`
    display: flex;
`;

export const StyledDeltaContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
    text-align: left;
    background-color: #fff;
    color: #343434;
    font-family: Lato;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    width: 33px;
`;

export const StyledKSModalBodyWrapper = styled.div``;

export const StyledKSModalInfoMessage = styled.p`
    margin-left: 20px;
    margin-top: 9px;
    display: flex;
`;

export const StyledInfoIconKSModal = styled.i`
    background: url(${infoinvert});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0px 1px;
    margin-right: 5px;
    float: left;
    width: 12px;
    height: 12px;
`;

export const StyledTagSectionContainer = styled.div`
    min-height: fit-content;
    max-height: 454px;
    overflow-y: scroll;
    margin: 10px 20px 27px;
    border-radius: 3px;
    border: solid 1px #979797;
    scrollbar-width: thin;
`;

export const StyledTagSection = styled.div`
    width: 100%;
    border-bottom: 1px solid #e2e2e2;
    min-height: 43px;
    height: fit-contnet;
    display: flex;
`;

export const StyledTagSectionLeft = styled.div`
    width: 90px;
    padding: 12px 9px 6px 9px;
    background-color: #ebecf0;
`;

export const StyledTagSectionCenter = styled.div`
    flex: 1;
    padding: 9px 11px;
`;

export const StyledTagSectionRight = styled.div`
    width: 80px;
`;

export const StyledTagSectionLeftName = styled.div`
    color: #343434;
    font-size: 12px;
    margin-bottom: 2px;
`;

export const StyledTagSectionLeftCharCount = styled.div`
    font-size: 10px;
    color: #838793;
`;

export const StyledKSSkipWaringCheckboxWrapper = styled.div`
    margin-left: 20px;
    margin-top: 11px;
    height: 22px;
`;

export const StyledKSButtonContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
`;
export const StyledKSButtonWrapper = styled.div`
    width: fit-content;
    margin: auto;
`;

export const StyledIgonreAllButton = styled(StyledInvertButton)`
    height: 30px;
    margin: 0px 10px;
`;

export const StyledCheckboxLable = styled(StyledLabel)`
    font-size: 14px;
`;

export const StyledEditModalModel = styled(StyledModal)`
    min-height: 0;
    max-height: fit-content;
`;

export const StyledEditModalBody = styled(StyledBody)`
    padding: 0;
    min-height: fit-content;
    max-height: fit-content;
`;

export const StyledEditModalClose = styled(StyledClose)`
    background: url(${utilityCloseIcon}) no-repeat;
    top: 0px;
    width: 16px;
    height: 16px;
    margin-top: 10px;
`;

export const StyledTargetURLModalHead = styled(StyledHead)`
    height: 37px;
    padding: 12px 0 0 20px;
    text-transform: inherit;
    font-size: 14px;
`;

export const StyledTagSectionPhrase = styled.p`
    padding: 3px;
`;

export const StyledRunSimulationButton = styled(StyledButton)`
    margin: 0 4px;
    height: 30px;
    width: 100%;
    border-radius: 3px;
    background: linear-gradient(270deg, rgb(248, 173, 73) 0px, rgb(241, 116, 76) 100%);
    cursor: ${(props: { disabled: boolean }) => (props.disabled ? "not-allowed" : "pointer")};
    &:active {
        background-color: rgb(248, 173, 73);
    }
    &:disabled {
        background-color: #afafaf;
        cursor: default;
    }
`;

export const StyledCleanedOriginalContent = styled.div`
    display: none;
`;

export const StyledAccordianSubHeading = styled.div`
    width: 100%;
    height: 30px;
    display: flex;
    background: #e2e2e2;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    accent-color: ;
    padding-top: 10px;
`;

export const StyledHeadingMatricName = styled.div`
    width: 75%;
    padding-left: 20px;
`;

export const StyledTopThreeURL = styled.div`
    width: 25%;
    float: left;
    font: inherit;
    padding-left: 20px;
`;

export const StyledCompScore = styled.div`
    float: left;
    width: 20%;
    padding-top: 14px;
    font-size: 13px;
    color: #343434;
`;

export const StyledCompScoreNoDataIcon = styled.i`
    margin-top: 12px;
`;

export const StyledOptimizeLink = styled.div``;

export const StyledInputContextIcons = styled.div`
    padding-left: 4px;
    align-self: start;
    position: sticky;
    top: 3px;
`;

export const StyledRecomCheckBox = styled(StyledCheckbox)`
    float: left;
    position: absolute;
    left: -2px;
    z-index: 10;
    cursor: pointer;
`;

export const StyledDeltaIcon = styled.img<{ scoreTab?: boolean; colorTab?: string; marginLeft?: boolean }>`
    width: auto;
    height: ${({ scoreTab }) => (scoreTab ? "12px" : "15px")};
    margin: ${({ scoreTab, colorTab, marginLeft }) =>
        marginLeft ? "0 0 0 2px" : !scoreTab ? "35% 0 0 0" : colorTab === "green" ? "25% 0 0 2px" : "12% 0 0 2px"};
`;

export const StyledRunSimulationToolTip = styled.div`
    color: #fb764b;
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    visibility: hidden;
    display: none;
    width: 400px;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    margin: 1px;
    position: absolute;
    top: 46px;
    background: white;
    border: 1px solid #fb764b;
    z-index: 10000 !important;
    padding: 6px;
    & span {
        height: 18px;
        vertical-align: -webkit-baseline-middle;
    }
    &::after {
        content: "";
        position: absolute;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff;
        top: -6px;
        left: 50%;
        transform: translateX(-50%);
    }
    &::before {
        content: "";
        position: absolute;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #fb764b;
        top: -7px;
        left: 50%;
        transform: translateX(-50%);
    }
`;

export const StyledRunSimulationContainer = styled.div`
    z-index: 999;
    padding: 8px 20px 10px 20px;
    background: white;
    border-bottom: 1px solid #e2e2e2;
    &:hover {
        ${StyledRunSimulationToolTip} {
            visibility: visible;
            display: block;
            opacity: 1;
        }
    }
`;

export const StyledRunSimulationOverlayNonLive = styled.div`
    display: flex;
    justify-content: center;
    height: 41px;
    margin: 11px 0px 0px -20px;
    padding: 6px 76px 6px 0px;
    opacity: 0.85;
    z-index: 100000 !important;
    position: absolute;
    background-color: #838793;
    color: #fff;
    cursor: pointer;
    & span {
        padding-top: 10px;
        height: 15px;
        vertical-align: -webkit-baseline-middle;
    }
    &::before {
        content: url(${handIcon});
        padding: 3px 13px 0px 40px;
        width: 19px;
        height: 22px;
    }
`;
