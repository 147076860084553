import { AnyAction } from "redux";
import { put, takeEvery, call } from "redux-saga/effects";
import Apis from "../../app/apis";
import ActionTypes, { setUserEmailVerified, setUserEmailID } from "./actions";
import { UserEmailVerificationStatus } from "./types";

// APP SAGA APIS

// tslint:disable-next-line:typedef
export function* verifyUserEmail(action: AnyAction) {
    const payload = {
        ...action.payload
    };

    const response = yield call(Apis.postUserEmailVerification, payload);

    if (response.status === 200) {
        yield put(setUserEmailVerified(response.data.msg));
        if (response.data.msg === UserEmailVerificationStatus.LINK_EXPIRED) {
            yield put(setUserEmailID(response.data.user_id));
        }
    } else if (response.status === 400) {
        yield put(setUserEmailVerified(response.response.msg));
        if (response.response.msg === UserEmailVerificationStatus.LINK_EXPIRED) {
            yield put(setUserEmailID(response.response.user_id));
        }
    } else {
        yield put(setUserEmailVerified(UserEmailVerificationStatus.FALIURE));
    }
}

// tslint:disable-next-line:typedef
export function* reVerifyEmail(action: AnyAction) {
    const payload = {
        ...action.payload
    };

    const response = yield call(Apis.postUserEmailReVerification, payload);
    if (response.status === 200) {
        yield put(setUserEmailVerified(UserEmailVerificationStatus.RESEND_MAIL_SUCCESS));
    } else {
        yield put(setUserEmailVerified(UserEmailVerificationStatus.RESEND_MAIL_FAILED));
    }
}

// APP SAGA WATCHERS

// tslint:disable-next-line: typedef
export function* watchForUserVerification() {
    yield takeEvery(ActionTypes.CALL_TO_VERIFY_USER_EMAIL, verifyUserEmail);
}

// tslint:disable-next-line: typedef
export function* watchForReverifyEmail() {
    yield takeEvery(ActionTypes.CALL_TO_REVERIFY_EMAIL, reVerifyEmail);
}
