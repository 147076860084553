export const capitalizeFirstLetter = (input: string) => {
  const words = input.toLocaleLowerCase().split(" ");

  const text = words.map((word) => {
    return word[0].toUpperCase() + word.substring(1);
  }).join(" ");
  return text;
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isUserAuthorized = () => {
  if (document.cookie.includes(`${process.env.COOKIE_SESSION_ID}`)) {
    return true;
  }
  return false;
};
