import {
    ImportanceLevelType,
    ContextReducerInterface,
    ContentSimulatorModelScoreListTypeBeta,
    ComponentInformationType,
    BucketType,
    PerformanceType,
    ImpactBucketType,
    ALPSScoreSubCategoryType,
    SerpResponseInterface,
    UrlDomainType,
    CompetitorAndTargetURLsListInterface,
    CategoryType,
    AppliedFilterSortInterface,
    FilterSortInterface,
    InlineValidationInterface,
    MultiKeywordTrackRequestApiRequestIdsResponseInterface,
    MultiKeywordTrackRequestApiRequestIdStatusResponseInterface,
    KeywordRequestIdsInterface,
    NotifyErrorInterface,
    LocaleInterface
} from "../../../app/duck/types";
import { History } from "history";
import { OptionTypeInterface } from "../../../app/styledComponents/drop-down/types";
import { RelatedRequestIdsApiRequestInterface } from "../Components/AddModifyKeyword/ducks/types";
import { TrackRequestPayloadInterface } from "../../serp-result/ducks/types";
import { SetDomainInterface } from "../../../beta/kw-url-search/ducks/types";
import { AuthContentInterface, KWLevelIntentDataInterface, TechnicalDetailInterface } from "../Components/PageAssessment/ducks/types";
import { ContentOutlineAPIStatus } from "../../page-optimizer/ducks/types";
import {
    KeywordsDropdownInterface,
    PhraseTabDataInterface,
    UrlkeywordsScoreInterface
} from "../Components/ContentOptimization/SummaryComponent/ducks/types";

export enum AggregationTypes {
    WEIGHTED_AVG_SEARCH_VOLUME = "weighted_average_search_volume"
}

export enum ComponentsType {
    BOOLEAN = "value_boolean",
    NUMBER = "value_number",
    BUCKET = "scores_bucket",
    TABULATED_SINGLE_VALUE = "value_distribution",
    IMPACT_BUCKET = "impact_bucket",
    COUNT = "count"
}
export enum ParameterInfoDetailInfoType {
    INFO_VALUE = "info_value",
    DELTA_INFO_VALUE = "delta_info_value",
    BUCKETS = "buckets"
}

export enum ElementType {
    TOGGLE = "TOGGLE",
    NUMBER = "NUMBER",
    RANGE = "RANGE"
}
export interface BucketDetailsInterface {
    keywords: string[];
    type?: BucketType;
    count: number;
    delta_count?: number;
}
export type BucketsInterface = {
    [key in BucketType]: BucketDetailsInterface;
};

export type ImpactBucketInterface = {
    [key in ImpactBucketType]: BucketDetailsInterface;
};

export interface TextDistributionInterface {
    word: string;
    count: number;
    delta_count?: number;
}

/**
 *  Content Simulator Request payload Interface
 */
export interface PerformanceMetricsPayloadInterface extends NotifyErrorInterface {
    url: string;
    keywords: string[];
    locale: string;
    isBeta?: boolean;
}
export interface LiveNonLivePerformanceMetricsPayloadInterface extends NotifyErrorInterface {
    url: string;
    keywords: string[];
    locale: string;
    isBeta?: boolean;
    is_url_live: boolean;
}

export interface ModelScorePayloadInterface extends NotifyErrorInterface {
    url: string;
    isBeta?: boolean;
    aggregation_type?: AggregationTypes;
    keywords: string[];
    locale: string;
    category: CategoryType[];
    are_competitors_processed?: boolean;
}

export interface ModelScoreDetailsPayloadInterface extends NotifyErrorInterface {
    url: string;
    aggregation_type?: AggregationTypes;
    content_model_score_list: ContentSimulatorModelScoreListTypeBeta[];
    keywords: string[];
    locale: string;
    isBeta?: boolean;
    is_url_live: boolean;
}

export interface SimulateModelScoreDetailsPayloadInterface extends NotifyErrorInterface {
    url: string;
    simulation_type: string;
    changed_dict: OnPageElementsResponseInterface;
    keywords: string[];
    locale: string;
    isBeta?: boolean;
    is_url_live: boolean;
    sid: string;
}

export interface SimulateKeywordStuffingPayloadInterface extends NotifyErrorInterface {
    url: string;
    changed_dict: OnPageElementsResponseInterface;
}

export interface CompetitorsScorePayloadInterface extends NotifyErrorInterface {
    keywords?: string[];
    locale?: string;
    isBeta?: boolean;
    search_url?: string;
    project_id?: number;
}

export interface CompetitorsScoreInitiatePayloadInterface {
    sid?: string;
    locale?: string;
}

/**
 *  Request Payload Ends
 */

/**
 * Content Simulator response Interface
 */
export interface OrganicRankInterface {
    value: null | number;
    delta_value?: number;
    [ComponentInformationType.ORGANIC_RANK_BUCKET]: ComponentDeepInformationInterface;
}

export interface TrafficScoreInterface {
    value: null | number;
    delta_value?: number;
}
export interface ModelScoreSummaryInterface {
    variable_importance: null | ImportanceLevelType;
    value: null | number;
    delta_value?: null | number;
}

/**
 * Response Interface
 */
export type ModelScoreContentResponseInterface = {
    [key in ContentSimulatorModelScoreListTypeBeta]: ModelScoreSummaryInterface;
};

export interface ModelScoresOverallResponseInterface {
    request_id?: string;
    [CategoryType.CONTENT]: ModelScoreContentResponseInterface;
    [CategoryType.TECHNICAL]: ParameterInterface[];
    [CategoryType.AUTHORITY]: ParameterInterface[];
}

export type ModelScoreDetailsResponseInterface = {
    [key in ContentSimulatorModelScoreListTypeBeta]: ModelParameterDetailsInterface;
};

export interface PerformanceMetricsResponseInterface {
    [PerformanceType.TRAFFIC]: TrafficScoreInterface;
    [PerformanceType.ORGANIC_RANK]: OrganicRankInterface;
}

export interface ImpactedKeywordsResponseInterface {
    keyword: string;
    search_volume: number | null;
    backlink_relevence: ImpactedKeywordResponseAuthAndTechInterface[];
    [PerformanceType.TRAFFIC]: ImpactedKeywordResponseDetailsInterface;
    [ContentSimulatorModelScoreListTypeBeta.TITLE_SCORE]: ImpactedKeywordResponseDetailsInterface;
    [ContentSimulatorModelScoreListTypeBeta.URL_SCORE]: ImpactedKeywordResponseDetailsInterface;
    [ContentSimulatorModelScoreListTypeBeta.H1_SCORE]: ImpactedKeywordResponseDetailsInterface;
    [ContentSimulatorModelScoreListTypeBeta.H2_SCORE]: ImpactedKeywordResponseDetailsInterface;
    [ContentSimulatorModelScoreListTypeBeta.H3_SCORE]: ImpactedKeywordResponseDetailsInterface;
    [PerformanceType.ORGANIC_RANK]: ImpactedKeywordResponseDetailsInterface;
    [ContentSimulatorModelScoreListTypeBeta.VISIBLE_CONTENT_SCORE]: ImpactedKeywordResponseDetailsInterface;
    [ContentSimulatorModelScoreListTypeBeta.META_DESCRIPTION_SCORE]: ImpactedKeywordResponseDetailsInterface;
    [ContentSimulatorModelScoreListTypeBeta.CATEGORY_SCORES]: ImpactedKeywordAlpsScoreInterface;
    [ContentSimulatorModelScoreListTypeBeta.READABILITY_GRADE]: ImpactedKeywordAlpsScoreInterface;
}

export interface KeywordUrlListDataResponseType {
    keyword?: string;
    search_volume?: number | null;
    url: string;
    displayUrl: string;
    [PerformanceType.TRAFFIC]: ImpactedKeywordResponseDetailsInterface;
    [ContentSimulatorModelScoreListTypeBeta.TITLE_SCORE]:
    | ImpactedKeywordResponseDetailsInterface
    | CompetitorsScoreUrlDataScoreResponseInterface;
    [ContentSimulatorModelScoreListTypeBeta.URL_SCORE]: ImpactedKeywordResponseDetailsInterface;
    [ContentSimulatorModelScoreListTypeBeta.H1_SCORE]:
    | ImpactedKeywordResponseDetailsInterface
    | CompetitorsScoreUrlDataScoreResponseInterface;
    [ContentSimulatorModelScoreListTypeBeta.H2_SCORE]:
    | ImpactedKeywordResponseDetailsInterface
    | CompetitorsScoreUrlDataScoreResponseInterface;
    [ContentSimulatorModelScoreListTypeBeta.H3_SCORE]:
    | ImpactedKeywordResponseDetailsInterface
    | CompetitorsScoreUrlDataScoreResponseInterface;
    [PerformanceType.ORGANIC_RANK]: ImpactedKeywordResponseDetailsInterface;
    [ContentSimulatorModelScoreListTypeBeta.VISIBLE_CONTENT_SCORE]: ImpactedKeywordResponseDetailsInterface;
    [ContentSimulatorModelScoreListTypeBeta.READABILITY_GRADE]: ImpactedKeywordResponseDetailsInterface;
    [ContentSimulatorModelScoreListTypeBeta.META_DESCRIPTION_SCORE]:
    | ImpactedKeywordResponseDetailsInterface
    | CompetitorsScoreUrlDataScoreResponseInterface;
    [ContentSimulatorModelScoreListTypeBeta.CATEGORY_SCORES]?: ImpactedKeywordAlpsScoreInterface;
    [ALPSScoreSubCategoryType.CONTENT_SCORE]?: ImpactedKeywordResponseDetailsInterface;
    [ALPSScoreSubCategoryType.AUTHORITY_SCORE]?: ImpactedKeywordResponseDetailsInterface;
    [ALPSScoreSubCategoryType.TECHNICAL_SCORE]?: ImpactedKeywordResponseDetailsInterface;
    [CategoryType.AUTHORITY]: ImpactedKeywordResponseAuthAndTechInterface[];
    [CategoryType.TECHNICAL]: ImpactedKeywordResponseAuthAndTechInterface[];
}

export interface ImpactedKeywordResponseAuthAndTechInterface {
    category?: string;
    display_name?: string;
    value?: number | boolean | null;
    elem_type?: string;
    model_var_name?: string;
    param_disp_name?: string;
    param_sub_category?: string;
    unit?: string;
}

export interface Impactedkeywordsurllistinterface {
    [key: string]: KeywordUrlListDataResponseType[];
}

export interface KeywordDetailsResponseInterface {
    [key: string]: ImpactedKeywordsResponseInterface[];
}

/**
 * Response Interface Ends
 */

export type OnPageElementsResponseInterface = {
    [key in OnPageElementsTypeBeta]?: string[] | string;
};
export type OnPageElementsResponseInterfaceBeta = {
    [key in OnPageElementsTypeBeta]?: string[] | string;
};
/**
 *  BucketsInterface is from API response
 *  FormattedBucketInterface is interface after data transformation
 */
export interface ComponentDeepInformationInterface {
    info_type: ComponentsType;
    distribution?: TextDistributionInterface[];
    [ParameterInfoDetailInfoType.BUCKETS]: BucketsInterface | ImpactBucketInterface;
    [ParameterInfoDetailInfoType.INFO_VALUE]?: null | number | boolean;
    [ParameterInfoDetailInfoType.DELTA_INFO_VALUE]?: null | number | boolean;
}
export type ModelParameterDetailsInterface = {
    [key in ComponentInformationType]: ComponentDeepInformationInterface;
};
export interface SimulateModelScoreDetailsInterface {
    aggregated_performance_metrics: PerformanceMetricsResponseInterface;
    aggregated_model_score: ModelScoresOverallResponseInterface;
    model_score_details: ModelScoreDetailsResponseInterface;
}

export type CompetitorsScoreAggregatedScoresALPSScoreResponseType = {
    [key in ALPSScoreSubCategoryType]: number;
};

export type CompetitorsScoreAggregatedScoresModelScoreListResponseType = {
    [key in ALPSScoreSubCategoryType]: number;
};

export type CompetitorsScoreAggregatedScoresResponseType = CompetitorsScoreAggregatedScoresALPSScoreResponseType &
    CompetitorsScoreAggregatedScoresModelScoreListResponseType;

export interface CompetitorsScoreUrlDataScoreResponseInterface {
    score: number;
    elements?: string[];
}

export type CompetitorsScoreUrlDataALPSScoreResponseType = {
    [key in ALPSScoreSubCategoryType]: CompetitorsScoreUrlDataScoreResponseInterface;
};

export type CompetitorsScoreUrlDataModelScoreListResponseType = {
    [key in ContentSimulatorModelScoreListTypeBeta]: CompetitorsScoreUrlDataScoreResponseInterface;
};

export type CompetitorsScoreUrlDataResponseType = CompetitorsScoreUrlDataALPSScoreResponseType &
    CompetitorsScoreUrlDataModelScoreListResponseType & {
        url: string;
        organic_rank: number;
    };

export interface CompetitorsScoreKeywordResponseInterface {
    aggregated_scores: CompetitorsScoreAggregatedScoresResponseType;
    url_data: CompetitorsScoreUrlDataResponseType[];
}

export interface CompetitorsScoreResponseInterface {
    [key: string]: CompetitorsScoreKeywordResponseInterface;
}

export interface UrlKeywordsScoreResponseInterface {
    kw?: string;
    url_data?: UrlkeywordsScoreInterface[];
}

export interface SimulatedChangedParameterInterface {
    content: EditorElementInterfaceBeta[];
    authority: ChangedParameterInterface;
    technical: ChangedParameterInterface;
}

export interface SimulationVersionStatusInterface {
    kw_rank_data: boolean;
    kw_sv: boolean;
    request_id: string;
    url_auth_tech: boolean;
    url_content: boolean;
}

/**
 *  Respose Interface Ends
 */

export interface AddSectionButtonPosInterface {
    left: number;
    top: number;
}

export interface SimulatorReducerInterface {
    content: ContentReducerInterface;
    formattedOnPageElements: EditorElementInterfaceBeta[];
    authority: AuthorityReducerInterface;
    technical: TechnicalReducerInterface;
    impactedKeywords?: ImpactedKeywordsResponseInterface[];
    selectedKeyword?: string;
    url: string;
    simulatedChangedParameters?: SimulatedChangedParameterInterface;
    isLoading: boolean;
    isInfotainmentLoading: string | boolean;
    performance?: PerformanceMetricsResponseInterface;
    simulateScoreStatus: boolean;
    isScoreSimulated: boolean;
    isPerformanceSimulated: boolean;
    selectedCategoryType: CategoryType;
    isSimulateDone: boolean;
    isSimulationInProgress: boolean;
    isOpenViewTraffic: boolean;
    isContentFetched: boolean;
    isTechnicalScoreFetched: boolean;
    isAuthorityScoreFetched: boolean;
    isModelScoreFetched: boolean;
    isModelScoreDetailsFetched: boolean;
    forcedUpdateKeywords: boolean;
    isPartialSimulationDone: boolean;
    isKeywordLevelImpactFetechInProgress: boolean;
    isResetContentChanged: boolean;
    isFetchPerformanceScoreApiPassed?: boolean;
    versionList: OptionTypeInterface[];
    selectedVersion?: string;
    lastSelectedSimVersion?: string;
    isFirstTimeContentRendering: boolean;
    sidNext: string;
    description: string;
    simulationsVersionChangeFlag: boolean;
    userList: OptionTypeInterface[];
    defaultSelectedUser: OptionTypeInterface;
    filteredVersionList?: OptionTypeInterface[];
    sidToSaveCompetitorScore: string;
    didVersionChangeAtleastOnce: boolean;
    simulationVersionStatus: SimulationVersionStatusInterface;
    versionNotification: MainNotificationInterface;
    isRefreshButtonClicked: boolean;
    displayRefreshNotification: boolean;
    editorMode: string;
    selectedTag: string;
    selectedToolbarAction: ToolbarActionInterface;
    highlightColor: string;
    iframeLoaded: boolean;
    tagNotFound: boolean;
    isBrowserMode: boolean;
    isFetchContentOnSwitchEditorMode?: boolean;
    browserParsedHTMLForDownload?: string;
    keywordStuffingDetails: KeywordStuffingInterface;
    useThisContent?: UseThisContentInterface;
    updatedVersionDescription?: string;
    isDownloadFailed: boolean;
    isDownloadPAFailed: boolean;
    editorOldUrl?: string;
    isRecommendationOpen?: boolean;
    isFirstTimeRecoOpen?: boolean;
    editorNewUrl?: string;
    isDownload: boolean;
    bodyWordCount: number;
    isFirstTimeRecoClose: boolean;
    selectedTagValue: KeywordsDropdownInterface | string | undefined;
    CompetitorScoreSSELoading: string;
    competitorScoreSSERetriedAttempt: number;
    alertStatus?: boolean;
    originalWordCount?: number;
    revertToOriginalStatus?: boolean;
    randomVersionID?: string;
    getTopCompFetchStatus?: boolean;
    isFreshSimulation: boolean;
    getMetaInfoFetchStatus?: boolean;
    insightsSkipKW?: string[];
    insightsOrigKW?: string[];
    isKLIOpen?: boolean;
    viewOriginalStatus?: boolean;
    allKWsProcessed?: boolean;
    infotainmentLoadingStatus?: boolean;
    techDataFailed?: boolean;
    doesInputKeywordsExceedLimit: boolean;
    KWLevelIntentData?: KWLevelIntentDataInterface;
    isKwLevelIntentDataApiDone?: boolean;
    isPageAssessmentDisabled: boolean;
    paSelectedKW?: string;
    isSavedSimulationCalledBeforeTrackReqComp: boolean;
    // tslint:disable-next-line:no-any
    runSimaulationPayload: any;
    areKeywordsProcessingForFirstTime: boolean;
    isDownloadPA: boolean;
    displayImpactedKeywordsandUrls: KeywordUrlListDataResponseType[];
    impactedKeywordsandUrls?: KeywordUrlListDataResponseType[];
    filteredKeyword: string[];
    filteredUrl: string[];
    impactedKeywordsUrlList?: Impactedkeywordsurllistinterface;
    isRefreshNowClicked: boolean;
}

export interface ChangedParameterInterface {
    [key: string]: string | number | boolean | undefined;
}

export interface ParameterValidationInterface {
    [key: string]: InlineValidationInterface;
}

export interface KeywordStuffingPhraseInterface {
    is_stuffed: boolean;
    // tslint:disable:no-any
    stuffed_phrases: any;
    // tslint:enable:no-any
}

export interface StuffedKeywordObjectInterface {
    // tslint:disable:no-any
    title: any;
    h1: any;
    h2: any;
    h3: any;
    visible_content: any;
    // tslint:enable:no-any
}

export interface StuffedKeywordListObjInterface {
    title: string;
    phrases: string[];
}

export interface StuffedKeywordListInterface extends Array<StuffedKeywordListObjInterface> { }

export interface KeywordStuffingInterface {
    URL: boolean;
    TITLE: KeywordStuffingPhraseInterface[];
    META_DESCRIPTION: KeywordStuffingPhraseInterface[];
    H1: KeywordStuffingPhraseInterface[];
    H2: KeywordStuffingPhraseInterface[];
    H3: KeywordStuffingPhraseInterface[];
    BODY_CONTENT: KeywordStuffingPhraseInterface[];
}
export interface AuthorityReducerInterface {
    isDirty: boolean;
    parameters: ParameterInterface[];
    subCategories: FormattedSubCategories[];
    changed_parameters: ChangedParameterInterface;
    validation: ParameterValidationInterface;
    forced_changed_parameters: ChangedParameterInterface;
    appliedSortFilters: AppliedFilterSortInterface;
    availableFilters: string[];
    incrementalAuthParameter: ChangedParameterInterface;
    initialChangedParameters: ChangedParameterInterface;
}
export interface TechnicalReducerInterface {
    isDirty: boolean;
    parameters: ParameterInterface[];
    subCategories: FormattedSubCategories[];
    changed_parameters: ChangedParameterInterface;
    validation: ParameterValidationInterface;
    forced_changed_parameters: ChangedParameterInterface;
    appliedSortFilters: AppliedFilterSortInterface;
    availableFilters: string[];
    incrementalTechParameter: ChangedParameterInterface;
    initialChangedParameters: ChangedParameterInterface;
}
export interface ParameterInterface {
    param_disp_name: string;
    parameter: string;
    variable_importance: ImportanceLevelType;
    category: CategoryType;
    param_sub_category: string;
    param_info: string;
    unit: string | null;
    sub_category_rank: number;
    has_positive_correlation: boolean;
    elem_type: ElementType;
    range: number[] | null;
    is_active: boolean;
    value?: number | null | boolean;
    isDetailOpen: boolean;
    changed_value?: number | null | boolean;
    top_3_comp_value?: number | null;
}
export interface ParametersInterface {
    [key: string]: ParameterInterface;
}
export interface ContentReducerInterface {
    competitorsScore?: CompetitorsScoreResponseInterface;
    urlKeywordsScore?: UrlKeywordsScoreResponseInterface[];
    fetchingCompetitors: boolean;
    isEditorDirty: boolean;
    isZoomModeEnabled: boolean;
    modelScore?: ModelScoreContentResponseInterface;
    modelScoreDetails?: ModelScoreDetailsResponseInterface;
    pageContent: EditorElementInterfaceBeta[];
    aiGeneratedContent: EditorElementInterfaceBeta[];
    browserContent: EditorElementInterfaceBeta[];
    changedPageContent?: EditorElementInterfaceBeta[];
    notification: PageNotificationInterface;
    onPageElementStatusCode?: undefined | number;
    content_crawl_date?: string | null;
    incrementalChangedContent: EditorElementInterfaceBeta[];
    incrementalhtmlChangedContent: EditorElementInterfaceBeta[];
    editorMode?: string;
    cleanedOriginalContent: string;
    fetchingKeywordsScore: boolean;
    recomBulbSubHeaderScores?: RecomBulbSubHeaderScoresInterface;
}

export interface RecomBulbSubHeaderListInterface {
    [key: string]: number;
}

export interface RecomBulbSubHeaderScoresInterface {
    h2_list?: RecomBulbSubHeaderListInterface;
    h3_list?: RecomBulbSubHeaderListInterface;
}
export interface ContentSimulatorPropsInterface
    extends SimulatorReducerInterface,
    ContentReducerInterface,
    TechnicalReducerInterface,
    AuthorityReducerInterface {
    isLoadingCompleted: boolean;
    history: History;
    requestId?: string;
    isContentEditorDirty: boolean;
    competitors: CompetitorAndTargetURLsListInterface;
    serpList: SerpResponseInterface[];
    selectedUrlDomain: UrlDomainType;
    isMultiKeywordSubmitted: boolean;
    selectedKeywords: string[];
    isOnlyScoreSimulated: boolean;
    enableScoreSimulation: boolean;
    context: ContextReducerInterface;
    isScoreTransformationDone: boolean;
    isAllCompetitorProcessed: boolean;
    actions: ContentSimulatorActionsInterface;
    enableSimulatePerformance: boolean;
    isProjectContext: boolean;
    isKwProcessedForMyUrl: boolean;
    keyWordProgressStatus: MultiKeywordTrackRequestApiRequestIdsResponseInterface;
    keywordRequestIds: KeywordRequestIdsInterface;
    processedKeywords: string[];
    selectedTargetUrl: string;
    selectedMultiKeywordLocale?: string;
    is_url_live: boolean;
    match: {
        path: string;
    };
    fileUpload_requestId?: string;
    fileName?: string;
    clearFile: boolean;
    isSimulationVersionChanged: boolean;
    urlPresenceStatus: boolean;
    defaultKeyword?: string;
    isPartialKeywordReady: boolean;
    displayRefreshNotification: boolean;
    enableCompetitorApi: boolean;
    mkwTrackInitiated: boolean;
    target_urls: CompetitorAndTargetURLsListInterface;
    liveNonLiveFlow: boolean;
    editorMode: string;
    isFetchContentOnSwitchEditorMode?: boolean;
    showLoaderBar: boolean;
    originalKeywordList: OptionTypeInterface[];
    isProceedButtonClicked?: boolean;
    editorNewUrl?: string;
    isTrackRequestStart: boolean;
    isFileUploaded: boolean;
    cleanedOriginalContent: string;
    isContextRecomModalOpen?: boolean;
    isTop3CompDataForAllKWsAvailable?: boolean;
    simulationKeywords: KeywordInterface[];
    locales: LocaleInterface[];
    isPageNotCrawled?: boolean;
    isRelatedKeywordKwParamFetching: string | boolean;
    isPhrasesLoading?: boolean;
    phraseContent?: PhraseTabDataInterface[] | undefined;
    competitorsScore?: CompetitorsScoreResponseInterface;
    insightsSkipKW?: string[];
    multikeywordDomainUrl?: string;
    techData: ParameterInterface[];
    KWLevelIntentData?: KWLevelIntentDataInterface;
    isTechDataFailed?: boolean;
    isKwLevelIntentDataApiDone?: boolean;
    enableSimulateScore: boolean;
    isSavedSimulationCalledBeforeTrackReqComp: boolean;
    selectedTabRecommendation: number;
    // tslint:disable-next-line:no-any
    runSimaulationPayload: any;
    isAIAssistedContent: boolean;
    userPrompt: string;
    isAIContentFetchCompleted?: boolean;
    initialContentOutlineAPIStatus?: ContentOutlineAPIStatus;
    regenerateContentOutlineAPIStatus?: ContentOutlineAPIStatus;
    CompetitorScoreSSELoading: string;
}

export interface CallToSimulatePayloadInterface extends NotifyErrorInterface {
    isScoreSimulated: boolean;
    isPerformanceSimulated: boolean;
    isBrowserMode?: boolean;
    changedParameters: {
        content: EditorElementInterfaceBeta[];
        authority: ChangedParameterInterface;
        technical: ChangedParameterInterface;
    };
    SimulatedchangedParameters: {
        content: EditorElementInterfaceBeta[];
        authority: ChangedParameterInterface;
        technical: ChangedParameterInterface;
    };
    isEqualToPrevious: boolean;
    changedContent: EditorElementInterfaceBeta[];
    keywords: string[];
    url: string;
    newContent: EditorElementInterfaceBeta[];
    parser: { url?: string; tenantCode?: string };
    runInBackGround: boolean;
    simulateType?: SimulatePerformanceTypeInterface;
    locale?: string;
    is_url_live: boolean;
    htmlUploadContent?: EditorElementInterfaceBeta[];
    project_id?: number;
    allKeyword: string[];
    sidNext: string;
    description: string;
    isForceUpdate: boolean;
    isStale: boolean;
}

export interface CallToBrowserEditorSimulatePayloadInterface extends NotifyErrorInterface {
    isScoreSimulated: boolean;
    isPerformanceSimulated: boolean;
    isBrowserMode?: boolean;
    changedParameters: {
        content: EditorElementInterfaceBeta[];
        authority: ChangedParameterInterface;
        technical: ChangedParameterInterface;
    };
    SimulatedchangedParameters: {
        content: EditorElementInterfaceBeta[];
        authority: ChangedParameterInterface;
        technical: ChangedParameterInterface;
    };
    isEqualToPrevious: boolean;
    changedContent: EditorElementInterfaceBeta[];
    keywords: string[];
    url: string;
    newContent: EditorElementInterfaceBeta[];
    parser: { url?: string; tenantCode?: string };
    runInBackGround: boolean;
    simulateType?: SimulatePerformanceTypeInterface;
    locale?: string;
    is_url_live: boolean;
    htmlUploadContent?: EditorElementInterfaceBeta[];
    project_id?: number;
    allKeyword: string[];
    sidNext: string;
    description: string;
    isForceUpdate: boolean;
    isStale: boolean;
    editorMode: string;
}

interface CompetitorScoreRequestInterface extends NotifyErrorInterface {
    search_url: string;
    project_id?: number;
}
export interface ContentSimulatorActionsInterface {
    setHideLoader(): void;
    callSetContext(): void;
    callToGetOnPageElements(args: InitialParametersInterface | InitialParametersforFileuploadInterface): void;
    callToGetModelScore(args: InitialParametersInterface): void;
    callToGetPerformanceScore(args: InitialParametersInterface): void;
    callToGetModelScoreDetails(args: InitialParametersInterface): void;
    callToGetCompetitorsScore(args: CompetitorScoreRequestInterface): void;
    resetSlice(): void;
    setPageZoomMode(args: boolean): void;
    setChangedContent(args: CallToSimulatePayloadInterface): void;
    setSimulationNotification(args: PageNotificationInterface): void;
    setNotificationOnVersion(args: MainNotificationInterface): void;
    setSelectedKeywords(args: string[]): void;
    resetMultiKeyword(): void;
    setSelectedCategoryType(args: CategoryType): void;
    setPresimulatedData(args: boolean): void;
    setAuthorityAppliedFilter(args: FilterSortInterface): void;
    setTechnicalAppliedFilter(args: FilterSortInterface): void;
    resetChangedParameter(): void;
    resetContentChangedParam(args: EditorElementInterfaceBeta[]): void;
    setContentEditorDirty(): void;
    setInitHTMLEditorContent(str: string): void;
    callToSimulateForAllKeywords(): void;
    setRevokeLoader(): void;
    updateProcessedKeywords(): void;
    callToForcedUpdateKeywords(args: boolean): void;
    callToOpenCloseViewTraffic(args: boolean): void;
    callToGetPreSimKeywordDetails(args: { url: string; keywords?: string[]; locale?: string }): void;
    setOnlyChangedContent(args?: EditorElementInterfaceBeta[]): void;
    setActiveRouteUrl(url: string): void;
    setOnlyEditorContentChanged(): void;
    callToGetVersionList(args: VersionListInputPayloadInterface): void;
    setSelectedVersion(args: string): void;
    callScoreApiBasedOnVersion(args: SavedSimulationScoresPayloadInterface): void;
    callToSetSidNext(args: string): void;
    callContentApiBasedOnVersion(args: SavedSimulationScoresPayloadInterface): void;
    setSaveDescription(args: string): void;
    callToSaveDescription(args: UpdateDescriptionPayloadInterface): void;
    setSimulationsVersionChangeFlag(arg: boolean): void;
    setUserSelection(args: OptionTypeInterface): void;
    setKeywordUrlBoxExpandedMode(args: boolean): void;
    callToGetMultiKeywordRequestIdsApi(args: MultiKeywordRequestIdsApiRequestInterface): void;
    setFirstTimeRenderingFlag(args: boolean): void;
    getSimulationVersionStatus(args: string): void;
    setRefreshButtonClicked(args: boolean): void;
    hideRefreshNotification(): void;
    setSimulationVersionStatus(args: SimulationVersionStatusInterface): void;
    callToGetRelatedKeywordRequestIdsApi(args: RelatedRequestIdsApiRequestInterface): void;
    callToGetTrackRequest(args: TrackRequestPayloadInterface): void;
    clearOldSimulationState(): void;
    clearCompetitiveAnalysisData(): void;
    clearKeywordDetailsFromOldVersion(): void;
    setChangedBrowserEditorContent(args: CallToBrowserEditorSimulatePayloadInterface): void;
    switchEditorMode(args: string): void;
    setIsBrowserMode(args: boolean): void;
    callToGetKeywordStuffingDetails(args: SimulateKeywordStuffingPayloadInterface): void;
    setShowLoaderBar(): void;
    setEditorMode(args: string): void;
    setIsContentFetched(args: boolean): void;
    setIsProceedButtonClicked(arg: boolean): void;
    setIsEditorDirty(value: boolean): void;
    callSimulationDownloadApi(args: SimulationDownloadApiInterface): void;
    callSimulationDownloadContentOnlyApi(args: SimulationDownloadApiInterface): void;
    callSimulationDownloadContentOnlyInitiateApi(args: SimulationDownloadApiInterface): void;
    setIsDownloadFailed(args: boolean): void;
    setIsDownloadPAFailed(args: boolean): void;
    setSimulationEditorOldUrl(args: string): void;
    setSelectedTabRecommendation?(arg: number): void;
    setVersionKeywords(args: KeywordInterface[] | string[]): void;
    setIsSimulationDone(args: boolean): void;
    setSelectedRecoPhraseDD?(arg: OptionTypeInterface): void;
    setInitialKeywordsDropdownHead?(arg: undefined): void;
    setIsShowTopPagesLoader?(arg: boolean): void;
    setIsViewRecommendationOpen(args: boolean): void;
    setSelectedSID(args: string): void;
    setPhraseContent(arg: { phraseContent: PhraseTabDataInterface[] | undefined; editorContent: string | undefined }): void;
    setIsSimulationVersionChange(args: boolean): void;
    setSimulationKeywords(args: KeywordInterface[] | string[]): void;
    setPhrasesPayloadKeywords(args: KeywordInterface[] | string[]): void;
    setIsFirstTimeRecoOpen(args: boolean): void;
    setCleanedOriginalContent(args: string): void;
    setRevertToOriginalStatus(args?: boolean): void;
    setNewSimulationStatus(args: boolean): void;
    callToGetCompetitorsScoreSSEReqid(args: CompetitorsScoreInitiatePayloadInterface): void;
    callRecomBulbSubHeaderScores(args: RecomBulbSubHeaderScoresPayloadInterface): void;
    setIsKLIOpen(args: boolean): void;
    setviewOriginalStatus(args: boolean): void;
    setInfotainmentLoadingStatus(args: boolean): void;
    setAllKWsProcessed(args: boolean): void;
    markDoesInputKeywordsExceedAllowedLimit(args: boolean): void;
    setDomainInContext(url: string | SetDomainInterface): void;
    setMkwDomainUrl(url?: string): void;
    setSseRetriedAttemptKwParam(arg: number): void;
    selectedMultiKeywordLocale(arg: string): void;
    setKWLevelIntentData(arg: { apiData: KWLevelIntentDataInterface | undefined; apiStatus: boolean }): void;
    callSimulationDownloadPageAssessmentApi(arg: {
        authContent: AuthContentInterface[];
        technicalContent: TechnicalDetailInterface;
        locale?: string;
    }): void;
    setInsightsSkipKW(args: string[]): void;
    setInsightsOrigKW(args: string[]): void;
    markTop3CompDataProcessingStatusForAllKws(args: boolean): void;
    setPageAssessmentDisabled(args: boolean): void;
    callToFetchPhraseContent(arg: { editorContent: string }): void;
    // tslint:disable-next-line:no-any
    setSavedSimulationCalledBeforeTrackReqComp(arg: any): void;
    resetPageOptimizerSlice(): void;
    fetchInitialGeneratedContentOutline(args: AIGenerateParametersInterface): void;
    fetchRegeneratedContentOutline(args: AIGenerateParametersInterface): void;
    setUserPromptForContentOutline(args: UserPromptOutlineInterface): void;
    setRefreshNowClicked(args: boolean): void;
}

export interface RecomBulbKeywordsPayloadInterface {
    keyword: string;
    sv: number | null;
}

export interface RecomBulbSubHeaderScoresPayloadInterface {
    keywords: RecomBulbKeywordsPayloadInterface[];
    locale?: string;
    sub_header: {
        h2_list: string[];
        h3_list: string[];
    };
}

export interface MultiKeywordRequestIdsApiRequestInterface extends NotifyErrorInterface {
    keyword: string[];
    search_url?: string[];
    locale?: string;
    is_url_live: boolean;
    force_refresh_url_content?: boolean;
    project_id?: number | null;
    tenant_code: string;
}
export interface InitialParametersInterface extends NotifyErrorInterface {
    keywords?: string[] | undefined;
    url: string;
    locale?: string | undefined;
    is_url_live?: boolean;
    are_competitors_processed?: boolean;
}
export interface InitialParametersforFileuploadInterface extends NotifyErrorInterface {
    request_id: string;
    url: string;
}

export interface EditorElementInterfaceBeta {
    title: OnPageElementsTypeBeta;
    contents: string[] | string;
}

export interface DownloadDataInterface {
    A: string;
    B: string | number | boolean | null | undefined;
    C: string | number | boolean | null | undefined;
}

export interface ImpactedKeywordResponseScoreInterface {
    info_type: string;
    info_value: number | null;
    delta_info_value: number | null;
    grouped_info_value?: string;
}

export interface AIGenerateParametersInterface {
    keywords?: string[] | undefined;
    url: string;
    user_prompt?: string;
}

export interface UserPromptOutlineInterface {
    AIGeneratedFlag: boolean;
    userPrompt?: string;
}

export enum ImpactedKeywordDetailMetricType {
    SCORE = "score",
    IS_EXACT_MATCH = "is_exact_match",
    PHRASE_COUNT = "phrase_count",
    COMMON_WORD_COUNT = "common_word_count"
}

export interface ImpactedKeywordAlpsScoreInterface {
    [ImpactedKeywordDetailMetricType.SCORE]: ImpactedKeywordResponseScoreInterface;
    [ALPSScoreSubCategoryType.CONTENT_SCORE]: ImpactedKeywordResponseScoreInterface;
    [ALPSScoreSubCategoryType.AUTHORITY_SCORE]: ImpactedKeywordResponseScoreInterface;
    [ALPSScoreSubCategoryType.TECHNICAL_SCORE]: ImpactedKeywordResponseScoreInterface;
}

export interface ImpactedKeywordResponseDetailsInterface {
    [ImpactedKeywordDetailMetricType.SCORE]: ImpactedKeywordResponseScoreInterface;
    [ImpactedKeywordDetailMetricType.IS_EXACT_MATCH]?: boolean;
    [ImpactedKeywordDetailMetricType.PHRASE_COUNT]?: ImpactedKeywordResponseScoreInterface;
    [ImpactedKeywordDetailMetricType.COMMON_WORD_COUNT]?: ImpactedKeywordResponseScoreInterface;
    elements?: string[];
}
export interface ImpactedKeywordRankResponseDetailsInterface {
    rank: ImpactedKeywordResponseScoreInterface;
}

export interface PageNotificationInterface {
    isVisible: boolean;
    message: string;
}

export interface MainNotificationInterface {
    isVisible: boolean;
    isOlderOneMonth?: boolean;
}

export interface KeywordProgressInformationFormatedWithNameInterface {
    [key: string]: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface;
}
export enum SimulatePerformanceTypeInterface {
    PERFORMANCE = "performance",
    SCORES = "scores"
}
export interface FormattedSimulateperformanceInterface {
    traffic: null | number;
    delta_traffic?: null | number;
    organic_rank: null | number;
    delta_organic_rank?: null | number;
}
export enum SimulatePerformanceLabelType {
    ORGANIC_RANK = "RANK",
    TRAFFIC = "TRAFFIC"
}

export interface ParameterScorePropsInterface {
    parameter: ParameterInterface;
    changedParameters: ChangedParameterInterface;
    forcedchangedParameters: ChangedParameterInterface;
    isTechnicalDirty: boolean;
    isAuthorityDirty: boolean;
    selectedVersion?: string;
}

export interface CategoryParamterInterface {
    value: number | boolean;
    delta_value: number | boolean | null;
}
export interface ForcedChangedParameterInterface {
    [key: string]: CategoryParamterInterface;
}
export enum TooltipPosition {
    RIGHT = "right",
    LEFT = "left",
    TOP = "top",
    BOTTOM = "bottom",
    TOP_RIGHT = "top-right",
    BOTTOM_RIGHT = "bottom-right",
    BOTTOM_LEFT = "bottom-left"
}

export enum PositiveNegativeIdealDeltaValue {
    ORANGE_TEXT = "orange_text",
    GREEN_TEXT = "green_text",
    GREY_TEXT = "grey_text"
}

export enum ButtonTypeForPartialData {
    CALL_FOR_ALL_KEYWORD = "call_for_all_keyword",
    CALL_FOR_AVAILABLE_KEYWORD = "call_for_available_keyword"
}

export interface InterfaceForParameterScores {
    [key: string]: string | boolean | null | number | undefined;
}

export interface InterfaceForContentData {
    [key: string]: string | string[] | null | undefined;
}

export interface DownloadResponseMapInterface {
    [key: string]: string | null | number;
}

export interface HeaderTagMarginObj {
    [key: string]: number;
}
export interface VersionListInputPayloadInterface {
    url: string;
    is_url_live: boolean;
    tenantCode?: string;
    isBeta?: boolean;
}
export interface VersionScoreInterface {
    [key: string]: number;
}

export interface VersionListResponseInterface {
    version_name: string;
    user_email: string;
    model_version: string;
    sid: string;
    scores: VersionScoreInterface;
    description: string;
    is_browser_mode?: boolean;
}
export interface SavedSimulationScoresPayloadInterface {
    sid: string;
    tenantCode?: string;
    isBeta?: boolean;
    editorMode?: string;
}

export interface MetaInformationInterface {
    sid: string;
    description: string;
    url: string;
    keywords: string[];
    locale: string;
    is_url_live: boolean;
    project_id?: number;
    isBeta?: boolean;
    is_browser_mode: boolean;
    is_ai_generated_content: number;
    user_prompt: string;
}

export interface SidPayloadInterface {
    sid: string;
    isBeta?: boolean;
}

export interface GroupedOption {
    readonly label?: string;
    readonly options: readonly OptionTypeInterface[];
}
export interface CaptureTechAuthInformationPayloadInterface {
    sid: string;
    url: string;
    keywords: string[];
    is_url_live: boolean;
    locale: string;
    project_id?: number;
    changed_dict: TechAuthChangeDictPayloadInterface;
    isBeta?: boolean;
}

export interface TechAuthChangeDictPayloadInterface {
    [CategoryType.TECHNICAL]: OnPageElementsResponseInterface;
    [CategoryType.AUTHORITY]: OnPageElementsResponseInterface;
}
export interface UpdateDescriptionPayloadInterface {
    sid: string;
    project_id?: number;
    description: string;
    isBeta?: boolean;
}

export interface SidPayloadInterface {
    sid: string;
    isBeta?: boolean;
}

export interface KWlevelImpactSortIconContainerPropsInterface {
    tabname: string;
}

export interface ToolbarActionInterface {
    action: string;
    data: string;
}

export interface MetaDetailsInterface {
    keywords: string[];
    url: string;
    locale: string;
    is_url_live: boolean;
    project_id?: number;
    description?: string;
    is_browser_mode?: boolean;
    sid: string;
}
export interface SimulationDownloadApiInterface {
    sid: string;
    url: string;
    locale: string;
    keywords: string[];
    is_url_live: boolean;
    project_id?: number;
    stripped_page?: string | string[];
    is_browser_mode?: boolean;
}
export interface SimulationDownloadApiTrackInterface {
    sid: string;
    req_id: string;
}

export interface ContextualRecommendationAPIInterface {
    keywords: string[];
    context: string[];
    element: string;
    tenant_code: string;
}

export enum FeedbackType {
    LIKE = "like",
    DISLIKE = "dislike"
}

export enum KeywordProcessingStatus {
    COMPLETE = "complete",
    STILL_PROCESSING = "still_processing"
}

export interface KeywordProcessingNotificationInterface {
    message?: string;
    status?: KeywordProcessingStatus;
}

export interface SimulatorConstInterface {
    [key: string]: string;
}

export interface FormattedSubCategories {
    id: number;
    param_sub_category: string;
    isOpen: boolean;
    count?: number;
}

export interface StyledSimulatorMultipleDropdown {
    width?: string;
    isEditorMode?: boolean;
}

export interface UseThisContentInterface {
    content: string;
    tagName: string;
}

export interface KeywordInterface {
    keyword: string;
    search_volume: number;
}

export enum PAGE_ASSESSMENT_STATUS {
    COMPLETE = "complete",
    LOADING = "loading",
    FAILED = "failed"
}

export enum OnPageElementsTypeBeta {
    MISSING_H1 = "missing_h1",
    MISSING_H2 = "missing_h2",
    MISSING_H3 = "missing_h3",
    META_DESCRIPTION = "meta_description",
    TITLE = "title",
    URL = "url",
    STRIPPED_PAGE = "stripped_page"
}

export interface CompetitorScoreSseEventInterface {
    receive_db_data: string;
    receive_kw_h2_h3_scores: string;
    receive_kw_grouped_h2: string;
    receive_kw_grouped_h3: string;
    req_completed: string;
    req_failed: string;
    error: string;
}
