import React from "react";
import ModalComponent from "../../../../app/styledComponents/modal";
import { StyledSuccessPopUpWrapper, StyledUpgradeNowContainer, StyledUpgradeNowExpiryModal } from "../StyledSimulator";
import SuccessPopUp from "./success-popup";
import UpgradeNow from "./upgrade-now";

interface ExpiryUserPageRouteParams {
    tenant_code: string;
    current_plan: string;
    user_id: string;
    end_date: string;
}
interface PlanTypeInterface {
    [key: string]: string;
}

const ExpiryUserUpgradePage: React.FC<ExpiryUserPageRouteParams> = ({ tenant_code, current_plan, user_id }) => {
    const [openSuccessPopUp, setOpenSuccessPopUp] = React.useState<boolean>(false);
    const [openUpgradeNow, setOpenUpgradeNow] = React.useState<boolean>(true);
    const onCloseUpgradeNowModal = (args: boolean) => {
        setOpenUpgradeNow(args);
    };
    const planType: PlanTypeInterface = { Starter: "STA", Trial: "TRI", LFT: "LFT" };

    return (
        <>
            <ModalComponent
                id="upgrade-now"
                title={
                    <UpgradeNow
                        instanceId="upgrade-now"
                        expireUser={true}
                        tenant_code={tenant_code}
                        current_plan={planType[current_plan] as string}
                        user_id={user_id}
                        upgradeModalOpen={onCloseUpgradeNowModal}
                        successModalOpen={setOpenSuccessPopUp}
                    />
                }
                styles={{ Modal: StyledUpgradeNowExpiryModal, Head: StyledUpgradeNowContainer }}
                onCloseHandler={() => onCloseUpgradeNowModal(false)}
            />
            {openSuccessPopUp && !openUpgradeNow && (
                <ModalComponent
                    id="success-popup"
                    title={<SuccessPopUp instanceId="success-popup" />}
                    styles={{ Modal: StyledUpgradeNowExpiryModal, Head: StyledSuccessPopUpWrapper }}
                    onCloseHandler={() => null}
                />
            )}
        </>
    );
};

export default ExpiryUserUpgradePage;
