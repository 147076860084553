import * as React from "react";
import { isString, isObject } from "lodash";
import styled, { css } from "styled-components";
import { closeCross } from "../icons";
import { StyledComponentStylesType } from "../duck/types";

export interface ModalStylesPropInterface {
    Overlay?: StyledComponentStylesType;
    Modal?: StyledComponentStylesType;
    Head?: StyledComponentStylesType;
    Title?: StyledComponentStylesType;
    Close?: StyledComponentStylesType;
    Body?: StyledComponentStylesType;
}

export interface ModalPropsInterface {
    title: string | JSX.Element;
    body?: JSX.Element;
    styles?: ModalStylesPropInterface;
    id?: string;
    instanceId?: string;
    width?: string;
    isClose?: boolean;
    isHeaderShow?: boolean;
    closeOverlay?: boolean;
    onCloseHandler(): void;
}

export const StyledOverlay = styled.div`
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;
    ${(props: ModalPropsInterface) =>
        props.id === "kw_level_impact_modal"
            ? css`
                  position: static;
                  z-index: 105;
              `
            : css``}
`;

export const StyledModal = styled.div`
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    /* top: 120px; */
    border-radius: 3px;
    box-shadow: 0 1px 7px 0 #a3a3a3;
    width: ${(props: ModalPropsInterface) => props.width ? props.width : "1000px"};
    margin: auto;
    min-height: 180px;
    overflow: ${(props: ModalPropsInterface) => (props.id === "kw_level_impact_modal-container" ? "" : "auto")};
    top: 50%;
    transform: translateY(-50%);
    ${(props: ModalPropsInterface) =>
        props.id === "kw_level_impact_modal-container"
            ? css`
                  position: static;
                  width: 100%;
                  box-shadow: none;
                  margin: 0;
                  transform: none;
                  max-height: unset;
                  min-height: unset;
              `
            : css``}
`;

export const StyledHead = styled.div`
    height: 58px;
    border-bottom: 1px solid #e2e2e2;
    padding: 20px 0 0 20px;
    box-sizing: border-box;
    color: #343434;
    font-size: 16px;
    font-weight: 700;
    position: relative;
    text-transform: uppercase;
`;

export const StyledTitle = styled.span``;

export const StyledClose = styled.button`
    background: url(${closeCross}) no-repeat;
    width: 28px;
    height: 28px;
    margin-top: 5.5px;
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-position: center;
    cursor: pointer;
`;

export const StyledBody = styled.div`
    padding: 20px;
    padding-top: 10px;
    max-height: calc(500px - 58px);
    font-size: 12px;
    color: #343434;
    overflow: auto;
    box-sizing: border-box;
    ${(props: { id: string }) =>
        props.id === "kw_level_impact_modal-body" &&
        css`
            height: 800px;
            min-height: 76.4vh;
            display: flex;
            overflow: hidden;
            max-height: none;
            grid-template-columns: 0.5fr 1.1fr 2.1fr;
            grid-template-rows: 0.3fr 4.5fr 0.2fr;
            gap: 0px 0px;
            grid-template-areas:
                "Sidebar Tabs Tabs"
                "Sidebar Table Table"
                "Sidebar Table Table";
        `};
`;

const ModalComponent: React.SFC<ModalPropsInterface> = ({
    id,
    title = "",
    body = "",
    onCloseHandler,
    instanceId,
    styles = {},
    width,
    isClose = true,
    isHeaderShow = true,
    closeOverlay = true
}) => {
    const {
        Overlay = StyledOverlay,
        Modal = StyledModal,
        Head = StyledHead,
        Title = StyledTitle,
        Close = StyledClose,
        Body = StyledBody
    } = styles;

    return (
        <Overlay id={id} onClick={closeOverlay ? onCloseHandler : ""}>
            <Modal
                id={`${id}-container`}
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                }}
                width={width}
            >
                {isHeaderShow && (
                    <Head id={`${id}-head`}>
                        {isString(title) && <Title id={`${id}-head-title`}>{title}</Title>}
                        {isObject(title) && title}
                        {isClose && <Close id={`${id}-head-button`} onClick={onCloseHandler} />}
                    </Head>
                )}
                <Body id={`${id}-body`}>{body}</Body>
            </Modal>
        </Overlay>
    );
};

export default ModalComponent;
