import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import { assign, concat, isUndefined } from "lodash";
import { CategoryType } from "../../../app/duck/types";
import {
    DEFAULT_EDITOR_CONTENT_BETA,
    APPLIED_FILTERS_ON_AUTH_TECH,
    DEFAULT_SELECTED_USER,
    SIMULATION_VERSION_STATUS,
    VERSION_NOTIFICATION
} from "../../../app/const";
import { SimulatorReducerInterface, SimulatePerformanceTypeInterface, UrlKeywordsScoreResponseInterface } from "./types";
import actionTypes from "./actions";
import {
    formatOnPageElements,
    copyChangedContentToPageContent,
    collapseAll,
    collapseDetailSection,
    updateChangedParameter,
    postSimulationForcedChangedParam,
    updateValueIntechAuthAppliedFilter,
    getUniqueImportance,
    resetAppliedSortFilter,
    validateParameters,
    getInitHTMLEditorContent,
    getChangedParameters,
    formattedUserList,
    getFormattedVersionList,
    filteredVersionList,
    selectedVersionBasedOnfilter,
    getUpdatedVersionWithDescription,
    setIsSimulateDoneFlag,
    getChangedData,
    getUpdatedPageContent,
    getSelectedUrlFilterList,
    createRandomID,
    getFilteredImpactedKeywords,
} from "./utils";
import { getParameters, getSubCategories } from "./selector";
import AuthActionTypes from "../Components/ducks/actions";
import AuthTechReducer from "../Components/ducks/reducer";
import CompetitiveAnalysisReducer from "../Components/CompetitiveAnalysis/ducks/reducer";
import { composeReducers } from "../../../app/duck/utils";
import { viewRecommendationTagHeadings, SIMULATOR_CONST } from "./const";
import { COPY_VERSION_SELECT, EDITOR_MODES } from "../Components/ducks/const";

export const INIT_STATE: SimulatorReducerInterface = {
    content: {
        changedPageContent: undefined,
        competitorsScore: undefined,
        urlKeywordsScore: [],
        fetchingKeywordsScore: false,
        fetchingCompetitors: false,
        isEditorDirty: false,
        isZoomModeEnabled: false,
        modelScore: undefined,
        modelScoreDetails: undefined,
        pageContent: formatOnPageElements(DEFAULT_EDITOR_CONTENT_BETA),
        aiGeneratedContent: formatOnPageElements(DEFAULT_EDITOR_CONTENT_BETA), // variable stores AI Generated Content in Editor accepted format
        notification: {
            isVisible: false,
            message: ""
        },
        browserContent: formatOnPageElements(DEFAULT_EDITOR_CONTENT_BETA),
        onPageElementStatusCode: undefined,
        content_crawl_date: undefined,
        incrementalChangedContent: [],
        incrementalhtmlChangedContent: [],
        cleanedOriginalContent: "",
        recomBulbSubHeaderScores: undefined
    },
    // Pre-simulation and switch mode
    formattedOnPageElements: formatOnPageElements(DEFAULT_EDITOR_CONTENT_BETA),
    technical: {
        isDirty: false,
        parameters: [],
        subCategories: [],
        changed_parameters: {},
        forced_changed_parameters: {},
        appliedSortFilters: APPLIED_FILTERS_ON_AUTH_TECH,
        validation: {},
        availableFilters: [],
        incrementalTechParameter: {},
        initialChangedParameters: {}
    },
    authority: {
        isDirty: false,
        parameters: [],
        subCategories: [],
        changed_parameters: {},
        forced_changed_parameters: {},
        appliedSortFilters: APPLIED_FILTERS_ON_AUTH_TECH,
        validation: {},
        availableFilters: [],
        incrementalAuthParameter: {},
        initialChangedParameters: {}
    },
    impactedKeywords: undefined,
    performance: undefined,
    url: "",
    simulatedChangedParameters: undefined,
    simulateScoreStatus: false,
    selectedCategoryType: CategoryType.PAGE_ASSESSMENT,
    isOpenViewTraffic: false,
    isLoading: false,
    isInfotainmentLoading: false,
    isScoreSimulated: false,
    isPerformanceSimulated: false,
    isContentFetched: false,
    isTechnicalScoreFetched: false,
    isAuthorityScoreFetched: false,
    isModelScoreFetched: false,
    isModelScoreDetailsFetched: false,
    forcedUpdateKeywords: false,
    isPartialSimulationDone: false,
    isSimulateDone: false,
    isSimulationInProgress: false,
    isKeywordLevelImpactFetechInProgress: false,
    isResetContentChanged: false,
    isFetchPerformanceScoreApiPassed: true,
    versionList: [],
    selectedVersion: undefined,
    lastSelectedSimVersion: undefined,
    isFirstTimeContentRendering: false,
    sidNext: "",
    description: "",
    simulationsVersionChangeFlag: false,
    userList: [],
    defaultSelectedUser: DEFAULT_SELECTED_USER,
    filteredVersionList: undefined,
    sidToSaveCompetitorScore: "",
    didVersionChangeAtleastOnce: false,
    simulationVersionStatus: SIMULATION_VERSION_STATUS,
    versionNotification: VERSION_NOTIFICATION,
    isRefreshButtonClicked: false,
    displayRefreshNotification: true,
    editorMode: EDITOR_MODES.editor,
    selectedTag: SIMULATOR_CONST.CONTENT_SIMULATOR_EDITOR_ELEMENT_TITLE,
    highlightColor: "#f9dc5c",
    iframeLoaded: false,
    selectedToolbarAction: {
        action: "",
        data: ""
    },
    tagNotFound: false,
    isBrowserMode: false,
    isFetchContentOnSwitchEditorMode: false,
    keywordStuffingDetails: {
        URL: false,
        TITLE: [
            {
                is_stuffed: false,
                stuffed_phrases: {}
            }
        ],
        META_DESCRIPTION: [
            {
                is_stuffed: false,
                stuffed_phrases: {}
            }
        ],
        H1: [
            {
                is_stuffed: false,
                stuffed_phrases: {}
            }
        ],
        H2: [
            {
                is_stuffed: false,
                stuffed_phrases: {}
            }
        ],
        H3: [
            {
                is_stuffed: false,
                stuffed_phrases: {}
            }
        ],
        BODY_CONTENT: [
            {
                is_stuffed: false,
                stuffed_phrases: {}
            }
        ]
    },
    useThisContent: {
        content: "",
        tagName: ""
    },
    updatedVersionDescription: "",
    isDownloadFailed: false,
    isDownloadPAFailed: false,
    editorNewUrl: undefined,
    editorOldUrl: undefined,
    isRecommendationOpen: false,
    isDownload: false,
    CompetitorScoreSSELoading: "",
    competitorScoreSSERetriedAttempt: 0,
    bodyWordCount: 0,
    isFirstTimeRecoClose: false,
    selectedTagValue: viewRecommendationTagHeadings[0],
    alertStatus: false,
    originalWordCount: undefined,
    revertToOriginalStatus: false,
    randomVersionID: undefined,
    getTopCompFetchStatus: undefined,
    isFreshSimulation: false,
    getMetaInfoFetchStatus: undefined,
    insightsSkipKW: [],
    insightsOrigKW: [],
    isKLIOpen: false,
    viewOriginalStatus: false,
    allKWsProcessed: false,
    infotainmentLoadingStatus: true,
    techDataFailed: false,
    doesInputKeywordsExceedLimit: false,
    KWLevelIntentData: undefined,
    isKwLevelIntentDataApiDone: false,
    isPageAssessmentDisabled: false,
    paSelectedKW: undefined,
    isSavedSimulationCalledBeforeTrackReqComp: false,
    runSimaulationPayload: {},
    areKeywordsProcessingForFirstTime: true,
    isDownloadPA: false,
    filteredKeyword: [],
    filteredUrl: [],
    impactedKeywordsandUrls: undefined,
    impactedKeywordsUrlList: undefined,
    displayImpactedKeywordsandUrls: [],
    isRefreshNowClicked: false
};

const SimulatorReducer = handleActions(
    {
        [actionTypes.SET_SIMULATION_EDITOR_OLD_URL]: (state, action: AnyAction) => ({
            ...state,
            editorOldUrl: action.payload
        }),
        [actionTypes.SET_CHANGED_URL_IN_FILTER_URL_LIST]: (state, action: AnyAction) => ({
            ...state,
            editorNewUrl: action.payload,
            filteredUrl: getSelectedUrlFilterList(action.payload.url, action.payload.filteredUrlsList, state.editorOldUrl)
        }),
        [actionTypes.CALL_TO_GET_MODEL_SCORE_DETAILS]: (state, action: AnyAction) => ({
            ...state,
            isModelScoreDetailsFetched: false
        }),

        [actionTypes.CALL_TO_GET_KEYWORD_STUFFING_DETAILS]: (state, action: AnyAction) => ({
            ...state,
            keywordStuffingDetails: assign({}, action.payload)
        }),

        [actionTypes.SET_PERFORMANCE_SCORE]: (state, action: AnyAction) => ({
            ...state,
            performance: assign({}, action.payload)
        }),

        [actionTypes.SET_COMPETITORS_SCORE_SSE_LOADING]: (state, action: AnyAction) => ({
            ...state,
            CompetitorScoreSSELoading: action.payload
        }),

        [actionTypes.SET_COMPETITOR_SCORE_SSE_RETRIED_ATTEMPT]: (state, action: AnyAction) => ({
            ...state,
            competitorScoreSSERetriedAttempt: action.payload
        }),

        [actionTypes.SET_MODEL_SCORE]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                modelScore: assign({}, action.payload.content)
            }),
            technical: assign({}, state.technical, {
                subCategories: getSubCategories(action.payload.technical, state.technical.subCategories),
                parameters: getParameters(action.payload.technical)
            }),
            authority: assign({}, state.authority, {
                subCategories: getSubCategories(action.payload.authority, state.authority.subCategories),
                parameters: getParameters(action.payload.authority)
            }),
            isAuthorityScoreFetched: true,
            isTechnicalScoreFetched: true,
            isModelScoreFetched: true
        }),
        [actionTypes.SET_MODEL_SCORE_DETAILS]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                modelScoreDetails: assign({}, action.payload.details)
            }),
            isModelScoreDetailsFetched: true
        }),
        [actionTypes.SET_KEYWORD_STUFFING_DETAILS]: (state, action: AnyAction) => ({
            ...state,
            keywordStuffingDetails: action.payload.details
        }),
        [actionTypes.SET_CLEANED_ORIGINAL_CONTENT]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                cleanedOriginalContent: action.payload
            })
        }),
        [actionTypes.SET_ON_PAGE_ELEMENTS]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                changedPageContent: undefined,
                pageContent: action.payload
            }),
            isLoading: false,
            isInfotainmentLoading: false,
            isContentFetched: true
        }),
        [actionTypes.SET_AI_GENERATED_PAGE_CONTENT]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                changedPageContent: undefined,
                aiGeneratedContent: action.payload
            }),
            isLoading: false,
            isInfotainmentLoading: false,
            isContentFetched: true
        }),
        // Browser content
        [actionTypes.SET_ON_PAGE_BROWSER_CONTENT]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                changedPageContent: undefined,
                pageContent: action.payload
            }),
            isLoading: false,
            isContentFetched: true
        }),
        [actionTypes.SET_BROWSER_EDITOR_SIMULATED_SCORE]: (state, action: AnyAction) => ({
            ...state,
            isSimulationInProgress: action.payload.isStale ? true : false,
            content: assign({}, state.content, {
                changedPageContent: copyChangedContentToPageContent(state, "content"),
                modelScore: assign({}, state.content.modelScore, action.payload.modelScore),
                modelScoreDetails: action.payload.modelScoreDetails
            }),
            impactedKeywords: action.payload.impactedKeywords,

            performance: action.payload.performance,
            isLoading: action.payload.isStale ? true : false,
            isScoreSimulated: true,
            isPerformanceSimulated: action.payload.simulationType === SimulatePerformanceTypeInterface.PERFORMANCE,
            simulateScoreStatus: action.payload.simulateScoreStatus,
            authority: assign({}, state.authority, {
                forced_changed_parameters: postSimulationForcedChangedParam(action.payload.authorityForcedChangeParameter),
                parameters: collapseDetailSection(state.authority.parameters)
            }),
            technical: assign({}, state.technical, {
                forced_changed_parameters: postSimulationForcedChangedParam(action.payload.technicalForcedChangeParameter),

                parameters: collapseDetailSection(state.technical.parameters)
            }),
            simulatedChangedParameters:
                !isUndefined(state.simulatedChangedParameters) &&
                assign(
                    {},
                    {
                        content: state.simulatedChangedParameters.content,
                        authority: assign(
                            {},
                            state.simulatedChangedParameters.authority,
                            postSimulationForcedChangedParam(action.payload.authorityForcedChangeParameter)
                        ),
                        technical: assign(
                            {},
                            state.simulatedChangedParameters.technical,
                            postSimulationForcedChangedParam(action.payload.technicalForcedChangeParameter)
                        )
                    }
                )
        }),
        [actionTypes.SET_SIMULATED_SCORE]: (state, action: AnyAction) => ({
            ...state,
            isSimulationInProgress: action.payload.isStale ? true : false,
            content: assign({}, state.content, {
                changedPageContent: copyChangedContentToPageContent(state),
                modelScore: assign({}, state.content.modelScore, action.payload.modelScore),
                modelScoreDetails: action.payload.modelScoreDetails
            }),
            impactedKeywords: action.payload.impactedKeywords,
            performance: action.payload.performance,
            isInfotainmentLoading: false,
            isScoreSimulated: true,
            isPerformanceSimulated: action.payload.simulationType === SimulatePerformanceTypeInterface.PERFORMANCE,
            simulateScoreStatus: action.payload.simulateScoreStatus,
            authority: assign({}, state.authority, {
                forced_changed_parameters: postSimulationForcedChangedParam(action.payload.authorityForcedChangeParameter),
                parameters: collapseDetailSection(state.authority.parameters)
            }),
            technical: assign({}, state.technical, {
                forced_changed_parameters: postSimulationForcedChangedParam(action.payload.technicalForcedChangeParameter),

                parameters: collapseDetailSection(state.technical.parameters)
            }),
            simulatedChangedParameters:
                !isUndefined(state.simulatedChangedParameters) &&
                assign(
                    {},
                    {
                        content: state.simulatedChangedParameters.content,
                        authority: assign(
                            {},
                            state.simulatedChangedParameters.authority,
                            postSimulationForcedChangedParam(action.payload.authorityForcedChangeParameter)
                        ),
                        technical: assign(
                            {},
                            state.simulatedChangedParameters.technical,
                            postSimulationForcedChangedParam(action.payload.technicalForcedChangeParameter)
                        )
                    }
                )
        }),
        [actionTypes.SET_CHANGED_CONTENT]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                changedPageContent: action.payload.newContent,
                incrementalChangedContent:
                    !action.payload.runInBackGround && !action.payload.isForceUpdate
                        ? action.payload.changedParameters.content
                        : state.content.incrementalChangedContent,
                incrementalhtmlChangedContent:
                    !action.payload.runInBackGround && !action.payload.isForceUpdate
                        ? action.payload.newContent
                        : state.content.changedPageContent
            }),
            isInfotainmentLoading: action.payload.runInBackGround ? false : true,
            isSimulationInProgress: true,
            isSimulateDone: true,
            isPartialSimulationDone: isUndefined(action.payload.simulateType) ? true : false,
            simulatedChangedParameters: assign({}, action.payload.SimulatedchangedParameters),
            technical: assign({}, state.technical, {
                incrementalTechParameter:
                    !action.payload.runInBackGround && !action.payload.isForceUpdate
                        ? action.payload.changedParameters.technical
                        : state.technical.incrementalTechParameter,
                initialChangedParameters:
                    !action.payload.runInBackGround && !action.payload.isForceUpdate
                        ? action.payload.changedParameters.technical
                        : state.technical.incrementalTechParameter
            }),
            authority: assign({}, state.authority, {
                incrementalAuthParameter:
                    !action.payload.runInBackGround && !action.payload.isForceUpdate
                        ? action.payload.changedParameters.authority
                        : state.authority.incrementalAuthParameter,
                initialChangedParameters:
                    !action.payload.runInBackGround && !action.payload.isForceUpdate
                        ? action.payload.changedParameters.authority
                        : state.authority.incrementalAuthParameter
            }),
            defaultSelectedUser:
                !action.payload.runInBackGround && !action.payload.isForceUpdate ? DEFAULT_SELECTED_USER : state.defaultSelectedUser
        }),
        [actionTypes.SET_CHANGED_BROWSER_EDITOR_CONTENT]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                changedPageContent: action.payload.newContent,
                incrementalChangedContent:
                    !action.payload.runInBackGround && !action.payload.isForceUpdate
                        ? action.payload.changedParameters.content
                        : state.content.incrementalChangedContent,
                incrementalhtmlChangedContent:
                    !action.payload.runInBackGround && !action.payload.isForceUpdate
                        ? action.payload.newContent
                        : state.content.changedPageContent
            }),
            isLoading: action.payload.runInBackGround ? false : true,
            isSimulationInProgress: true,
            isSimulateDone: true,
            isPartialSimulationDone: isUndefined(action.payload.simulateType) ? true : false,
            simulatedChangedParameters: assign({}, action.payload.SimulatedchangedParameters),
            technical: assign({}, state.technical, {
                incrementalTechParameter:
                    !action.payload.runInBackGround && !action.payload.isForceUpdate
                        ? action.payload.changedParameters.technical
                        : state.technical.incrementalTechParameter,
                initialChangedParameters:
                    !action.payload.runInBackGround && !action.payload.isForceUpdate
                        ? action.payload.changedParameters.technical
                        : state.technical.incrementalTechParameter
            }),
            authority: assign({}, state.authority, {
                incrementalAuthParameter:
                    !action.payload.runInBackGround && !action.payload.isForceUpdate
                        ? action.payload.changedParameters.authority
                        : state.authority.incrementalAuthParameter,
                initialChangedParameters:
                    !action.payload.runInBackGround && !action.payload.isForceUpdate
                        ? action.payload.changedParameters.authority
                        : state.authority.incrementalAuthParameter
            }),
            defaultSelectedUser:
                !action.payload.runInBackGround && !action.payload.isForceUpdate ? DEFAULT_SELECTED_USER : state.defaultSelectedUser
        }),

        [actionTypes.SET_REVOKE_LOADER]: (state, action: AnyAction) => ({
            ...state,
            isLoading: false,
            isInfotainmentLoading: "req_failed"
        }),
        [actionTypes.CALL_TO_GET_ON_PAGE_ELEMENTS]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                pageContent: INIT_STATE.content.pageContent
            }),
            isLoading: false,
            isInfotainmentLoading: false
        }),

        [actionTypes.CONTENT_SIMULATION_RESET_SLICE]: (state, action: AnyAction) => ({
            ...INIT_STATE
        }),
        [actionTypes.SET_COMPETITORS_SCORE]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                competitorsScore: action.payload,
                fetchingCompetitors: false
            })
        }),
        [actionTypes.SET_URL_KEYWORDS_SCORE]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                urlKeywordsScore: [...(state.content.urlKeywordsScore as UrlKeywordsScoreResponseInterface[]), action.payload],
                fetchingKeywordsScore: false
            })
        }),

        [actionTypes.CLEAR_OLD_SIMULATION_STATE]: (state, action: AnyAction) => ({
            ...state,
            impactedKeywords: undefined,
            content: assign({}, state.content, {
                competitorsScore: undefined
            })
        }),
        [actionTypes.SET_IMPACTED_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            impactedKeywords: getFilteredImpactedKeywords(state, action)
        }),
        [actionTypes.CALL_TO_GET_COMPETITORS_SCORE]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                fetchingCompetitors: true
            })
        }),
        [actionTypes.SET_URL]: (state, action: AnyAction) => ({
            ...state,
            url: action.payload
        }),
        [actionTypes.SET_SIMULATION_NOTIFICATION]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                notification: assign({}, action.payload)
            })
        }),
        [AuthActionTypes.SET_SELECTED_AUTH_SUBCATEGORY]: (state, action: AnyAction) => ({
            ...state,
            authority: {
                ...state.authority,
                subCategories: action.payload
            }
        }),
        [AuthActionTypes.SET_SELECTED_TECH_SUBCATEGORY]: (state, action: AnyAction) => ({
            ...state,
            technical: {
                ...state.technical,
                subCategories: action.payload
            }
        }),
        [actionTypes.SET_SELECTED_CATEGORY_TYPE]: (state, action: AnyAction) => ({
            ...state,
            selectedCategoryType: action.payload,
            authority: {
                ...state.authority,
                subCategories: collapseAll(state.authority.subCategories),
                parameters: collapseDetailSection(state.authority.parameters),
                availableFilters: getUniqueImportance(state.authority.parameters),
                appliedSortFilters: resetAppliedSortFilter(state.authority.appliedSortFilters)
            },
            technical: {
                ...state.technical,
                subCategories: collapseAll(state.technical.subCategories),
                parameters: collapseDetailSection(state.technical.parameters),
                availableFilters: getUniqueImportance(state.technical.parameters),
                appliedSortFilters: resetAppliedSortFilter(state.technical.appliedSortFilters)
            }
        }),
        [AuthActionTypes.SET_AUTHORITY_CHANGED_PARAMETER]: (state, action: AnyAction) => ({
            ...state,
            authority: {
                ...state.authority,
                isDirty: true,
                changed_parameters: assign({}, updateChangedParameter(state.authority.changed_parameters, action.payload))
            },
            selectedVersion: undefined,
            lastSelectedSimVersion: !isUndefined(state.selectedVersion) ? state.selectedVersion : state.lastSelectedSimVersion,
            description: !isUndefined(state.selectedVersion) ? "" : state.description
        }),
        [AuthActionTypes.SET_TECHNICAL_CHANGED_PARAMETER]: (state, action: AnyAction) => ({
            ...state,
            technical: {
                ...state.technical,
                isDirty: true,
                changed_parameters: assign({}, updateChangedParameter(state.technical.changed_parameters, action.payload))
            },
            selectedVersion: undefined,
            lastSelectedSimVersion: !isUndefined(state.selectedVersion) ? state.selectedVersion : state.lastSelectedSimVersion,
            description: !isUndefined(state.selectedVersion) ? "" : state.description
        }),
        [AuthActionTypes.SET_AUTHORITY_PARAMETER]: (state, action: AnyAction) => ({
            ...state,
            authority: assign({}, state.authority, {
                parameters: action.payload
            })
        }),
        [AuthActionTypes.SET_TECHNICAL_PARAMETER]: (state, action: AnyAction) => ({
            ...state,
            technical: assign({}, state.technical, {
                parameters: action.payload
            })
        }),

        [actionTypes.SET_PRESIMULATED_DATA]: (state, action: AnyAction) => ({
            ...state
        }),

        [actionTypes.SET_PRE_SIMULATED_KEYWORD_LEVEL_DETAILS]: (state, action: AnyAction) => ({
            ...state,
            impactedKeywords: action.payload,
            isKeywordLevelImpactFetechInProgress: false
        }),
        [actionTypes.CALL_TO_GET_PRE_SIM_KEYWORD_LEVEL_DETAILS]: (state, action: AnyAction) => ({
            ...state,
            isKeywordLevelImpactFetechInProgress: true
        }),

        [actionTypes.SET_AUTHORITY_APPLIED_FILTERS]: (state, action: AnyAction) => ({
            ...state,
            authority: {
                ...state.authority,
                appliedSortFilters: updateValueIntechAuthAppliedFilter(
                    assign({}, action.payload),
                    assign({}, state.authority.appliedSortFilters)
                ),
                parameters: collapseDetailSection(state.authority.parameters)
            }
        }),
        [actionTypes.SET_TECHNICAL_APPLIED_FILTERS]: (state, action: AnyAction) => ({
            ...state,
            technical: {
                ...state.technical,
                appliedSortFilters: updateValueIntechAuthAppliedFilter(
                    assign({}, action.payload),
                    assign({}, state.technical.appliedSortFilters)
                ),
                parameters: collapseDetailSection(state.technical.parameters)
            }
        }),
        [AuthActionTypes.SET_PARAMETER_VALIDATION_MESSAGES]: (state, action: AnyAction) => ({
            ...state,
            authority: {
                ...state.authority,
                validation: assign({}, validateParameters(state.authority.changed_parameters, state.authority.parameters))
            }
        }),
        [actionTypes.RESET_CONTENT_CHANGED_PARAMETER]: (state, action: AnyAction) => ({
            ...state,
            content: {
                ...state.content,
                changedPageContent: concat([], action.payload)
            },
            isResetContentChanged: true
        }),
        [actionTypes.RESET_CHANGED_PARAMETER]: (state, action: AnyAction) => ({
            ...state,
            [state.selectedCategoryType]: {
                // @ts-ignore
                ...state[state.selectedCategoryType],
                // @ts-ignore
                parameters: collapseDetailSection(state[state.selectedCategoryType].parameters),
                changed_parameters: assign({}, {}),
                forced_changed_parameters: assign({}, {}),
                validation: assign({}, {})
            }
        }),
        [actionTypes.SET_CONTENT_EDITOR_DIRTY]: (state, action: AnyAction) => ({
            ...state,
            content: {
                ...state.content,
                isEditorDirty: true
            },
            selectedVersion: undefined,
            lastSelectedSimVersion: !isUndefined(state.selectedVersion) ? state.selectedVersion : state.lastSelectedSimVersion,
            description: !isUndefined(state.selectedVersion) ? "" : state.description
        }),
        [actionTypes.SET_INIT_HTML_EDITOR_CONTENT]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                pageContent: getInitHTMLEditorContent(state.content.pageContent, action.payload)
            }),
            isFirstTimeContentRendering: true,
            isLoading: false,
            isInfotainmentLoading: false
        }),
        [actionTypes.SET_FETCH_PROCESS_STATUS]: (state, action: AnyAction) => ({
            ...state,
            ...action.payload
        }),
        [actionTypes.CALL_TO_OPEN_CLOSE_VIEW_TRAFFIC]: (state, action: AnyAction) => ({
            ...state,
            isOpenViewTraffic: action.payload
        }),
        [actionTypes.SIMULATE_FOR_ALL_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            isLoading: true
        }),
        [actionTypes.FORCED_UPDATE_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            forcedUpdateKeywords: action.payload
        }),
        [actionTypes.RESET_IS_SIMULATE_DONE]: (state, action: AnyAction) => ({
            ...state,
            isSimulateDone: false
        }),
        [actionTypes.SET_KEYWORD_LEVEL_IMPACTED_SCORES]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                impactedKeywords: action.payload.keyword_data
            })
        }),
        [actionTypes.SET_KEYWORD_LEVEL_IMPACTED_PERCORMANCE_SCORES]: (state, action: AnyAction) => ({
            ...state,
            performance: assign({}, state.performance, {
                keyword_data: action.payload.keyword_data
            })
        }),
        [actionTypes.SETTING_ON_PAGE_STATUS_CODE]: (state, action: AnyAction) => {
            return {
                ...state,
                content: assign({}, state.content, {
                    onPageElementStatusCode: action.payload
                })
            };
        },
        [actionTypes.SET_ONLY_CHANGED_CONTENT]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                changedPageContent: action.payload
            })
        }),
        [actionTypes.SET_ONLY_BROWSER_CHANGED_CONTENT]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                changedPageContent: action.payload
            })
        }),
        [actionTypes.SET_ONLY_EDITOR_CONTENT_CHANGED]: (state, action: AnyAction) => ({
            ...state,
            isResetContentChanged: false
        }),
        [actionTypes.SET_FETCH_PERFORMANCE_SCORE_SUCCESS]: (state, action: AnyAction) => ({
            ...state,
            isFetchPerformanceScoreApiPassed: false
        }),
        [actionTypes.SET_VERSION_LIST]: (state, action: AnyAction) => ({
            ...state,
            filteredVersionList: undefined,
            versionList: getFormattedVersionList(action.payload.versionList),
            userList: formattedUserList(action.payload.versionList),
            selectedVersion: !isUndefined(action.payload.selectedVersion) ? action.payload.selectedVersion : state.selectedVersion,
            lastSelectedSimVersion: !isUndefined(action.payload.selectedVersion) ? action.payload.selectedVersion : state.selectedVersion
        }),
        [actionTypes.SELECTED_VERSION]: (state, action: AnyAction) => ({
            ...state,
            selectedVersion: action.payload ? action.payload : undefined,
            lastSelectedSimVersion: action.payload ? action.payload : undefined,
            didVersionChangeAtleastOnce: !isUndefined(action.payload)
        }),
        [actionTypes.SET_CRAWL_DATES]: (state, action: AnyAction) => {
            return {
                ...state,
                content: assign({}, state.content, {
                    content_crawl_date: action.payload
                })
            };
        },
        [actionTypes.SET_SCORE_BASED_ON_VERSION]: (state, action: AnyAction) => ({
            ...state,
            isScoreSimulated: true,
            isModelScoreFetched: true,
            content: assign({}, state.content, {
                changedPageContent: copyChangedContentToPageContent(state),
                modelScore: assign({}, state.content.modelScore, action.payload.modelScore),
                modelScoreDetails: action.payload.modelScoreDetails
            }),
            performance: action.payload.performance,
            authority: assign({}, state.authority, {
                forced_changed_parameters: postSimulationForcedChangedParam(action.payload.authorityForcedChangeParameter),
                parameters: collapseDetailSection(state.authority.parameters)
            }),
            technical: assign({}, state.technical, {
                forced_changed_parameters: postSimulationForcedChangedParam(action.payload.technicalForcedChangeParameter),
                parameters: collapseDetailSection(state.technical.parameters)
            }),
            impactedKeywords: action.payload.impactedKeywords,
            simulatedChangedParameters:
                !isUndefined(state.simulatedChangedParameters) &&
                assign(
                    {},
                    {
                        content: state.simulatedChangedParameters.content,
                        authority: assign(
                            {},
                            state.simulatedChangedParameters.authority,
                            postSimulationForcedChangedParam(action.payload.authorityForcedChangeParameter)
                        ),
                        technical: assign(
                            {},
                            state.simulatedChangedParameters.technical,
                            postSimulationForcedChangedParam(action.payload.technicalForcedChangeParameter)
                        )
                    }
                )
        }),
        [actionTypes.CALL_TO_SET_SID_NEXT]: (state, action: AnyAction) => ({
            ...state,
            sidNext: action.payload
        }),
        [actionTypes.SET_ON_PAGE_ELEMENTS_BASED_ON_VERSION]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                content_crawl_date: action.payload.crawl_date,
                pageContent: action.payload.pageContent,
                changedPageContent: !isUndefined(action.payload.changedPageContent) ? action.payload.changedPageContent : undefined
            }),
            isLoading: false,
            isInfotainmentLoading: false,
            isSimulationInProgress: false,
            isSimulateDone: setIsSimulateDoneFlag(state.isSimulateDone),
            isContentFetched: true,
            simulatedChangedParameters:
                !isUndefined(action.payload.changedPageContent) &&
                assign(
                    {},
                    {
                        content: getChangedData(action.payload.pageContent, action.payload.changedPageContent),
                        authority: assign({}, state.simulatedChangedParameters?.authority),
                        technical: assign({}, state.simulatedChangedParameters?.technical)
                    }
                )
        }),
        [actionTypes.SET_ON_BROWSER_EDITOR_BASED_ON_VERSION]: (state, action: AnyAction) => ({
            ...state,
            content: assign({}, state.content, {
                content_crawl_date: action.payload.crawl_date,
                pageContent: action.payload.pageContent,
                changedPageContent: !isUndefined(action.payload.changedPageContent) ? action.payload.changedPageContent : undefined
            }),
            isLoading: false,
            isSimulationInProgress: false,
            isSimulateDone: setIsSimulateDoneFlag(state.isSimulateDone),
            isContentFetched: true,
            simulatedChangedParameters:
                !isUndefined(action.payload.changedPageContent) &&
                assign(
                    {},
                    {
                        content: getChangedData(action.payload.pageContent, action.payload.changedPageContent),
                        authority: assign({}, state.simulatedChangedParameters?.authority),
                        technical: assign({}, state.simulatedChangedParameters?.technical)
                    }
                )
        }),
        [actionTypes.SET_DESCRIPTION]: (state, action: AnyAction) => ({
            ...state,
            description: action.payload,
            versionList: getUpdatedVersionWithDescription(action.payload, state.versionList, state.selectedVersion),
            filteredVersionList: !isUndefined(state.filteredVersionList)
                ? getUpdatedVersionWithDescription(action.payload, state.filteredVersionList, state.selectedVersion)
                : state.filteredVersionList
        }),
        [actionTypes.SET_SIMULATIONS_VERSION_CHANGE_FLAG]: (state, action: AnyAction) => ({
            ...state,
            simulationsVersionChangeFlag: action.payload
        }),
        [actionTypes.SET_AUTH_TECH_PARAM_VALUES]: (state, action: AnyAction) => ({
            ...state,
            isTechnicalScoreFetched: true,
            isAuthorityScoreFetched: true,
            technical: assign({}, state.technical, {
                subCategories: getSubCategories(action.payload.technical, state.technical.subCategories),
                parameters: getParameters(action.payload.technical),
                changed_parameters: getChangedParameters(action.payload.technical),
                initialChangedParameters: getChangedParameters(action.payload.technical),
                validation: assign(
                    {},
                    validateParameters(getChangedParameters(action.payload.technical), getParameters(action.payload.technical))
                )
            }),
            authority: assign({}, state.authority, {
                subCategories: getSubCategories(action.payload.authority, state.authority.subCategories),
                parameters: getParameters(action.payload.authority),
                changed_parameters: getChangedParameters(action.payload.authority),
                initialChangedParameters: getChangedParameters(action.payload.authority),
                validation: assign(
                    {},
                    validateParameters(getChangedParameters(action.payload.authority), getParameters(action.payload.authority))
                )
                // validation: assign({}, {})
            }),
            simulatedChangedParameters: {
                content: state.simulatedChangedParameters?.content || [],
                authority: assign({}, getChangedParameters(action.payload.authority)),
                technical: assign({}, getChangedParameters(action.payload.technical))
            }
        }),
        [actionTypes.SET_USER_SELECTION]: (state, action: AnyAction) => ({
            ...state,
            defaultSelectedUser: action.payload,
            filteredVersionList: filteredVersionList(state.versionList, action.payload),
            selectedVersion: selectedVersionBasedOnfilter(
                action.payload,
                filteredVersionList(state.versionList, action.payload),
                state.selectedVersion
            ),
            lastSelectedSimVersion: selectedVersionBasedOnfilter(
                action.payload,
                filteredVersionList(state.versionList, action.payload),
                state.selectedVersion
            ),
            ...(!state.didVersionChangeAtleastOnce && { didVersionChangeAtleastOnce: !isUndefined(state.selectedVersion) })
        }),
        [actionTypes.UPDATE_SID_TO_SAVE_COMPETITORS_SCORE]: (state, action: AnyAction) => ({
            ...state,
            sidToSaveCompetitorScore: !isUndefined(action.payload) ? action.payload : ""
        }),
        [actionTypes.SET_FIRST_TIME_RENDERING_FLAG]: (state, action: AnyAction) => ({
            ...state,
            isFirstTimeContentRendering: action.payload
        }),
        [actionTypes.RESET_TECH_AUTH_CHANGED_PARAMS]: (state, action: AnyAction) => ({
            ...state,
            authority: {
                ...state.authority,
                parameters: collapseDetailSection(state.authority.parameters),
                changed_parameters: assign({}, {}),
                forced_changed_parameters: assign({}, {}),
                validation: assign({}, {})
            },
            technical: {
                ...state.technical,
                parameters: collapseDetailSection(state.technical.parameters),
                changed_parameters: assign({}, {}),
                forced_changed_parameters: assign({}, {}),
                validation: assign({}, {})
            }
        }),
        [actionTypes.SET_REFRESH_BUTTON_CLICKED]: (state, action: AnyAction) => ({
            ...state,
            isContentFetched: false,
            selectedVersion: undefined,
            lastSelectedSimVersion: !isUndefined(state.selectedVersion) ? state.selectedVersion : state.lastSelectedSimVersion,
            isRefreshButtonClicked: action.payload
        }),
        [actionTypes.SET_SIMULATION_VERSION_STATUS]: (state, action: AnyAction) => ({
            ...state,
            simulationVersionStatus: !isUndefined(action.payload) ? action.payload : SIMULATION_VERSION_STATUS
        }),
        [actionTypes.SET_NOTIFICATION_ON_VERSION]: (state, action: AnyAction) => ({
            ...state,
            versionNotification: !isUndefined(action.payload) ? assign({}, action.payload) : VERSION_NOTIFICATION
        }),
        [actionTypes.HIDE_REFRESH_MESSAGE]: (state, action: AnyAction) => ({
            ...state,
            displayRefreshNotification: false
        }),
        [actionTypes.SWITCH_EDITOR_MODE]: (state, action: AnyAction) => ({
            ...state,
            isContentFetched: false,
            // versionList: INIT_STATE.versionList,
            performance: state.performance ? state.performance : INIT_STATE.performance,
            content: assign({}, state.content, {
                pageContent: getUpdatedPageContent(state.formattedOnPageElements, action.payload === EDITOR_MODES.browser),
                changedPageContent: "",
                isEditorDirty: false
            }),
            simulatedChangedParameters: INIT_STATE.simulatedChangedParameters,
            editorMode: action.payload,
            iframeLoaded: false // reset iframeloader,
        }),
        [actionTypes.SELECT_TAG_TO_HIGHLIGHT]: (state, action: AnyAction) => ({
            ...state,
            selectedTag: action.payload
        }),
        [actionTypes.HIGHLIGHT_COLOR_CHANGED]: (state, action: AnyAction) => ({
            ...state,
            highlightColor: action.payload
        }),
        [actionTypes.IFRAME_LOADED]: (state, action: AnyAction) => ({
            ...state,
            iframeLoaded: action.payload
        }),
        [actionTypes.SET_TOOLBAR_ACTION]: (state, action: AnyAction) => ({
            ...state,
            selectedToolbarAction: action.payload
        }),
        [actionTypes.TAG_NOT_FOUND]: (state, action: AnyAction) => ({
            ...state,
            tagNotFound: action.payload
        }),
        [actionTypes.SET_FORMATTED_ON_PAGE_ELEMENTS]: (state, action: AnyAction) => ({
            ...state,
            formattedOnPageElements: action.payload
        }),
        [actionTypes.SET_EDITOR_MODE]: (state, action: AnyAction) => ({
            ...state,
            editorMode: action.payload
        }),
        [actionTypes.SET_IS_BROWSER_MODE]: (state, action: AnyAction) => ({
            ...state,
            isBrowserMode: action.payload
        }),
        [actionTypes.SET_BROWSER_CONTENT]: (state, action: AnyAction) => ({
            ...state,
            browserContent: action.payload
        }),
        [actionTypes.SET_IS_FETCH_CONTENT_ON_SWITCH_EDITOR_MODE]: (state, action: AnyAction) => ({
            ...state,
            isFetchContentOnSwitchEditorMode: action.payload
        }),
        [actionTypes.SET_IS_CONTENT_FETCHED]: (state, action: AnyAction) => ({
            ...state,
            isContentFetched: action.payload
        }),
        [actionTypes.SET_REFRESH_BUTTON_CLICKED_BY_MULTI]: (state, action: AnyAction) => ({
            ...state,
            selectedVersion: undefined,
            lastSelectedSimVersion: !isUndefined(state.selectedVersion) ? state.selectedVersion : state.lastSelectedSimVersion,
            isRefreshButtonClicked: action.payload
        }),
        [actionTypes.SET_IS_CONTENT_EDITOR_DIRTY]: (state, action: AnyAction) => ({
            ...state,
            content: {
                ...state.content,
                isEditorDirty: action.payload
            }
        }),
        [actionTypes.SET_IS_DOWNLOAD_SIMULATION]: (state, action: AnyAction) => ({
            ...state,
            isDownload: action.payload
        }),
        [actionTypes.SET_IS_DOWNLOAD_FAILED]: (state, action: AnyAction) => ({
            ...state,
            isDownloadFailed: action.payload
        }),
        [actionTypes.SET_IS_DOWNLOAD_PA_FAILED]: (state, action: AnyAction) => ({
            ...state,
            isDownloadPAFailed: action.payload
        }),
        [AuthActionTypes.SET_UPDATED_VERSION_DESCRIPTION]: (state, action: AnyAction) => ({
            ...state,
            updatedVersionDescription: action.payload
        }),
        [actionTypes.SET_USE_THIS_CONTENT]: (state, action: AnyAction) => ({
            ...state,
            useThisContent: action.payload
        }),
        [actionTypes.SET_IS_VIEW_RECOMMENDATION_OPEN]: (state, action: AnyAction) => ({
            ...state,
            isRecommendationOpen: action.payload
        }),
        [actionTypes.SET_BODY_WORD_COUNT]: (state, action: AnyAction) => ({
            ...state,
            bodyWordCount: action.payload
        }),
        [actionTypes.SET_IS_FIRST_TIME_RECO_CLOSE]: (state, action: AnyAction) => ({
            ...state,
            isFirstTimeRecoClose: action.payload
        }),
        [actionTypes.SET_ORIGINAL_WORD_COUNT]: (state, action: AnyAction) => ({
            ...state,
            originalWordCount: action.payload
        }),
        [actionTypes.SET_SELECTED_TAG_VALUE]: (state, action: AnyAction) => ({
            ...state,
            selectedTagValue: action.payload
        }),
        [actionTypes.SET_ALERT_VIEW]: (state, action: AnyAction) => ({
            ...state,
            alertStatus: action.payload
        }),
        [actionTypes.SET_REVERT_TO_ORIGINAL_STATUS]: (state, action: AnyAction) => ({
            ...state,
            revertToOriginalStatus: action.payload
        }),
        [actionTypes.SET_DESCRIPTION_TO_ORIGINAL]: (state, action: AnyAction) => ({
            ...state,
            description: COPY_VERSION_SELECT.ORIGINAL
        }),
        [actionTypes.CALL_TO_REVERT_BROWSER_MODE]: (state, action: AnyAction) => ({
            ...state,
            selectedVersion: undefined,
            lastSelectedSimVersion: undefined,
            isContentFetched: false,
            content: assign({}, state.content, {
                pageContent: getUpdatedPageContent(state.formattedOnPageElements, action.payload === EDITOR_MODES.browser),
                changedPageContent: "",
                isEditorDirty: false,
                modelScoreDetails: undefined
            }),
            randomVersionID: `revert_browser_mode${createRandomID()}`,
            simulatedChangedParameters: INIT_STATE.simulatedChangedParameters
        }),
        [actionTypes.SET_TOP_COMP_FETCH_STATUS]: (state, action: AnyAction) => ({
            ...state,
            getTopCompFetchStatus: action.payload
        }),
        [actionTypes.SET_META_INFO_FETCH_STATUS]: (state, action: AnyAction) => ({
            ...state,
            getMetaInfoFetchStatus: action.payload
        }),
        [actionTypes.SET_NEW_SIMULATION_STATUS]: (state, action: AnyAction) => ({
            ...state,
            isFreshSimulation: action.payload
        }),
        [actionTypes.SET_RECOM_BULB_SUBHEADER_SCORES]: (state, action: AnyAction) => ({
            ...state,
            content: {
                ...state.content,
                recomBulbSubHeaderScores: action.payload
            }
        }),
        [actionTypes.RESET_RECOM_BULB_SUBHEADER_SCORES]: (state, action: AnyAction) => ({
            ...state,
            content: {
                ...state.content,
                recomBulbSubHeaderScores: undefined
            }
        }),
        [actionTypes.SET_INSIGHTS_SKIP_KW]: (state, action: AnyAction) => ({
            ...state,
            insightsSkipKW: action.payload
        }),
        [actionTypes.SET_INSIGHTS_ORIG_KW]: (state, action: AnyAction) => ({
            ...state,
            insightsOrigKW: action.payload
        }),
        [actionTypes.SET_IS_KLI_OPEN]: (state, action: AnyAction) => ({
            ...state,
            isKLIOpen: action.payload
        }),
        [actionTypes.SET_VIEW_ORIGINAL_STATUS]: (state, action: AnyAction) => ({
            ...state,
            viewOriginalStatus: action.payload
        }),
        [actionTypes.SET_INFOTAINMENT_LOADING]: (state, action: AnyAction) => ({
            ...state,
            infotainmentLoadingStatus: action.payload
        }),
        [actionTypes.SET_ALL_KWS_PROCESSED]: (state, action: AnyAction) => ({
            ...state,
            allKWsProcessed: action.payload
        }),
        [actionTypes.SET_TECH_DATA_FAILED]: (state, action: AnyAction) => ({
            ...state,
            techDataFailed: action.payload
        }),
        [actionTypes.MARK_DOES_INPUT_KEYWORDS_EXCEED_ALLOWED_LIMIT]: (state, action: AnyAction) => ({
            ...state,
            doesInputKeywordsExceedLimit: action.payload
        }),
        [actionTypes.SET_KW_LEVEL_INTENT_DATA]: (state, action: AnyAction) => ({
            ...state,
            KWLevelIntentData: action.payload.apiData,
            isKwLevelIntentDataApiDone: action.payload.apiStatus
        }),
        [actionTypes.SET_PAGE_ASSESSMENT_DISABLED]: (state, action: AnyAction) => ({
            ...state,
            isPageAssessmentDisabled: action.payload
        }),
        [actionTypes.PA_SELECTED_KW]: (state, action: AnyAction) => ({
            ...state,
            paSelectedKW: action.payload
        }),
        [actionTypes.SET_SAVE_SIMULATION_CALLED_BEFORE_TRACK_REQ]: (state, action: AnyAction) => ({
            ...state,
            isSavedSimulationCalledBeforeTrackReqComp: action.payload.status,
            runSimaulationPayload: action.payload.data
        }),
        [actionTypes.SET_KEYWORDS_PROCESSING_STATUS]: (state, action: AnyAction) => ({
            ...state,
            areKeywordsProcessingForFirstTime: action.payload
        }),
        [actionTypes.SET_IS_DOWNLOAD_PAGE_ASSESSMENT]: (state, action: AnyAction) => ({
            ...state,
            isDownloadPA: action.payload
        }),
        [actionTypes.SET_REFRESH_NOW_CLICKED]: (state, action: AnyAction) => ({
            ...state,
            isRefreshNowClicked: action.payload
        }),
    },
    INIT_STATE
);

const Reducer1 = composeReducers(SimulatorReducer, AuthTechReducer);
const Reducer = composeReducers(Reducer1, CompetitiveAnalysisReducer);
export default Reducer;
