
const TABLEAU_LOCAL_STORAGE_KEY = "tableau_auth";

export const getTableauAuthDetails = () => {
    const tableauAuthDetails = Object.assign({}, JSON.parse(window.localStorage[TABLEAU_LOCAL_STORAGE_KEY] || "{}"));
    return tableauAuthDetails;
};

export const setTableauAuthDetails = (authDetailsObj: { isAuthenticated?: boolean; authenticatedAt?: string; embedUrl?: string; isPublisher?: boolean }) => {
    const tableauAuthDetails = Object.assign({}, JSON.parse(window.localStorage[TABLEAU_LOCAL_STORAGE_KEY] || "{}"));
    localStorage.setItem(TABLEAU_LOCAL_STORAGE_KEY, JSON.stringify({ ...tableauAuthDetails, ...authDetailsObj }));
};

export const clearTableauAuthDetails = () => {
    const tableauAuthDetails = Object.assign({}, JSON.parse(window.localStorage[TABLEAU_LOCAL_STORAGE_KEY] || "{}"));
    const authDetailsObject = { isAuthenticated: false, authenticatedAt: "", embedUrl: "", isPublisher: tableauAuthDetails?.isPublisher || false };
    localStorage.setItem(TABLEAU_LOCAL_STORAGE_KEY, JSON.stringify(authDetailsObject));
};
