import { assign } from "lodash";
import { UserPlanDetailsResponseInterface } from "./types";
import { durationLeft } from "../../app/duck/utils";
import { DAY, DAYS, MONTH, MONTHS, YEAR, YEARS } from "../../app/const";

export const getSubscriptionDetails = (
    // tslint:disable-next-line:no-any
    payload: any
): UserPlanDetailsResponseInterface => {
    // tslint:disable-next-line:no-console

    return {
        customer_limit: assign({}, payload.customer_limit),
        customer_utilization: assign({}, payload.customer_utilization),
        is_subscribed_user: payload.is_subscribed_user,
        subscription_active: payload.subscription_active,
        subscription_end_date: payload.subscription_end_date,
        subscription_expiry_time: payload.subscription_expiry_time,
        subscription_plan_name: payload.subscription_plan_name,
        subscription_start_date: payload.subscription_start_date
    };
};

export const timeLeft = (startDate: string, endDate: string): string => {
    const daysDiff = durationLeft(startDate, endDate);
    let left = "";
    if (daysDiff.M === 1) {
        left = `${daysDiff.D.toString()} ${daysDiff.D === 1 ? DAY : DAYS}`;
    } else if (daysDiff.M > 1 && daysDiff.Y === 0) {
        left = `${daysDiff.M - 1} ${daysDiff.M - 1 === 1 ? MONTH : MONTHS} ${
            daysDiff.D
        } ${daysDiff.D === 1 ? DAY : DAYS}`;
    } else {
        left = `${daysDiff.Y} ${daysDiff.Y ? YEAR : YEARS} ${daysDiff.M - 1} ${
            daysDiff.M - 1 === 1 ? MONTH : MONTHS
        } ${daysDiff.D} ${daysDiff.D === 1 ? DAY : DAYS}`;
    }
    return `${left} left`;
};
