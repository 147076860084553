import * as React from "react";
import { MessageInterface, MessageInternalPropsInterface } from "./messaging-provider-hoc";

interface AppMessageContextInterface {
    messages: MessageInternalPropsInterface[];
    push(messageMetaData: MessageInterface): void;
    pop(id: number): void;
    reset(): void;
}

export const AppMessageContext = React.createContext({} as AppMessageContextInterface);
