import { ContextRecommendationSseEventInterface } from "./types";

export const ContextualRecomm = {
    ContextRecomModalHeader: "Generate Optimized Recommendations​",

    ContextRecomStaticInfoTooltip: "Please edit based on your optimization preference. Default is existing content.",
    ContextRecommEnterContextLabel: "Describe Your {{element}}",
    ContextRecommContextPlaceholder: "Your Prompt to Generate Recommendations (Optional)",

    ContextRecomSelectedKeywordLabel: "Select Target Keywords",
    ContextRecomKeywordInfoTooltip: "First keyword is selected by default, you can change based on your preference.",

    ContextRecomGenerateRecommButton: "Generate Recommendations",
    ContextualRecommendationErrorMsg: "The recommendations could not be generated, please try again.",

    ContextRecomOptimizeAnchor: "Optimize",

    ContextRecomWillAppearHereLabel: "Optimized recommendation options will appear here",
    ContextRecomAddToEditorMessage: "Select and Click \"Add to Editor\" to rewrite current {{element}}",

    ContextRecomWasItHelpful: "Are Recommendations Relevant?",
    ContextRecomNoteLabel: "Note:",
    ContextRecomNotRelevantLabel: "To get different recommendations, edit current {{element}} and select different keywords.",

    ContextRecomModalTitleLabel: "Title",
    ContextRecomModalScoreLabel: "Score",
};

export const ContextRecomElementMap: { [id: string]: string } = {
    title: "Title",
    meta_description: "Meta Desc",
    h1: "H1",
    h2: "H2",
    h3: "H3"
};

export const CONTEXT_RECOMMENDATION_SSE_EVENT_TYPES: ContextRecommendationSseEventInterface = {
    receive_recommendations: "receive_recommendations",
    receive_recommendation_score: "receive_recommendation_score",
    req_completed: "req_completed",
    req_failed: "req_failed",
    error: "error"
};
