import { handleActions } from "redux-actions";
import actionTypes from "./actions";
import { AnyAction } from "redux";
import { DodReducerInterface } from "./types";

export const INIT_STATE: DodReducerInterface = {
    isDodProcessing: false,
    isSubmissionDone: false,
    submitId: "",
    errorCode: undefined,
};

const Reducer = handleActions(
    {
        [actionTypes.IS_DOD_REQEST_PROCESSING]: (state, action: AnyAction) => ({
            ...state,
            ...INIT_STATE,
            isDodProcessing: action.payload
        }),
        [actionTypes.IS_DOD_SUBMISSION_SUCCESS]: (state, action: AnyAction) => ({
            ...state,
            isSubmissionDone: true,
            submitId: action.payload,
            errorCode: undefined
        }),
        [actionTypes.SET_DOD_ERROR_CODE]: (state, action: AnyAction) => ({
            ...state,
            isSubmissionDone: false,
            errorCode: action.payload
        })
    },
    INIT_STATE
);

export default Reducer;
